import React from "react";
import Icon from "../../assets/images/list-icon.svg";
import TD from "../TD";
import "./style.css";

function SelectMemberModalList({ data, id, handleClick, isChecked, index }) {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				borderBottom: "1px solid #E6EFF5",
				padding: "20px 30px",
				alignItems: "center",
			}}
			className="item"
		>
			<TD
				style={{
					overflow: "hidden",
					textOverflow: "ellipsis",
				}}
				width="10%"
				color="#505887"
				fontSize="16px"
			>
				<input
					type="checkbox"
					style={{ width: "40%" }}
					onChange={(e) => {
						handleClick(e, data);
					}}
					checked={isChecked}
					id={id}
				/>
			</TD>
			<TD
				style={{
					overflow: "hidden",
					textOverflow: "ellipsis",
				}}
				width="10%"
				color="#505887"
				fontSize="16px"
			>
				{index + 1}
			</TD>

			<TD
				style={{
					overflow: "hidden",
					textOverflow: "ellipsis",
				}}
				width="40%"
				color="#505887"
				fontSize="16px"
			>
				{data.name}
			</TD>
			<TD
				style={{
					overflow: "hidden",
					textOverflow: "ellipsis",
				}}
				width="40%"
				color="#505887"
				fontSize="16px"
			>
				{Math.round(data.rank)}
			</TD>
		</div>
	);
}

export default SelectMemberModalList;
