// import React, { useEffect, useState } from "react";
// import styled from "styled-components";

// function ProfilePicture({ setFile, file, fetchedImage, setFetchedImage }) {
// 	const onImageChange = (e) => {
// 		setFile(e.target.files);
// 		if (e.target.files && e.target.files[0]) {
// 			setFetchedImage(URL.createObjectURL(e.target.files[0]));
// 		}
// 	};

// 	return (
// 		<Container>
// 			<ImageContainer>
// 				{fetchedImage !== null ? (
// 					<Image src={fetchedImage} alt="Profile" />
// 				) : (
// 					<Image
// 						src={
// 							require("./../assets/images/profile-pic.svg")
// 								.default
// 						}
// 						alt="Profile"
// 					/>
// 				)}
// 			</ImageContainer>
// 			<EditDiv htmlFor="image-file">
// 				<EditIcon
// 					src={require("./../assets/images/edit-icon.svg").default}
// 					alt="icon"
// 				/>
// 			</EditDiv>
// 			<input
// 				type="file"
// 				id="image-file"
// 				accept="image/png, image/gif, image/jpeg"
// 				style={{ display: "none" }}
// 				onChange={onImageChange}
// 			/>
// 		</Container>
// 	);
// }

// export default ProfilePicture;
// const Container = styled.div`
// 	position: relative;
// 	width: fit-content;
// `;
// const ImageContainer = styled.div`
// 	height: 100px;
// 	width: 100px;
// 	border-radius: 50%;
// `;
// const Image = styled.img`
// 	height: 100%;
// 	width: 100%;
// 	border-radius: 50%;
// `;
// const EditDiv = styled.label`
// 	background-color: #8561c6;
// 	position: absolute;
// 	bottom: 0;
// 	right: 0;
// 	border-radius: 50%;
// 	height: 30px;
// 	width: 30px;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	cursor: pointer;
// `;
// const EditIcon = styled.img`
// 	display: inline-block;
// 	height: 20px;
// 	width: 20px;
// `;

import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { baseConfig } from "../utils/config";
import { Context } from "./../contexts/Store";

function ProfilePicture({
    setFile,
    file,
    fetchedImage,
    setFetchedImage,
    setCropSize,
}) {
    // 11111111

    // const onImageChange = (e) => {
    // 	setFile(e.target.files);
    // 	if (e.target.files && e.target.files[0]) {
    // 		setFetchedImage(URL.createObjectURL(e.target.files[0]));
    // 	}
    // };

    // 2222222
    const [isImageLoading, setIsImageLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [cropedImage, setCropedImage] = useState(null);
    const [crop, setCrop] = useState({
        unit: "px",
        width: 300,
        height: 300,
        x: 80,
        y: 40,
    });
    const [editModal, setEditModal] = useState(false);
    const { state } = useContext(Context);

    const handleFileChange = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));
        // setImage(e.target.files[0]);
    };

    const onImageChange = (e) => {
        e.preventDefault();
        setEditModal(true);
        setFetchedImage(e.target.files[0]);
        handleFileChange(e);
        // setCrop({ aspect: 1 / 1 });
        // if (e.target.files && e.target.files[0]) {
        // 	setFetchedImage(URL.createObjectURL(e.target.files[0]));
        // }
    };

    useEffect(() => {
        setCropedImage(fetchedImage);
    }, [fetchedImage]);

    const handleCrop = () => {
        setIsImageLoading(true);
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        formdata.append("image", fetchedImage);
        formdata.append("crop", JSON.stringify(crop));

        baseConfig
            .post(`/learn/article-image/`, formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setFile(data.image_name);
                    setCropedImage(data.data.image);
                    setEditModal(false);
                    setTimeout(() => {
                        setIsImageLoading(false);
                    }, 200);
                } else {
                    alert(
                        "Some error occured while cropping the image, please try again"
                    );
                }
            })
            .catch((error) => {
                const { StatusCode, data } = error.response.data;
                alert(data?.message);
            });
    };

    // 	const onImageChange = (e) => {
    // 		setFile(e.target.files);
    // 		if (e.target.files && e.target.files[0]) {
    // 			setFetchedImage(URL.createObjectURL(e.target.files[0]));
    // 		}
    // 	};

    // const getCroppedImg = (val, img, crop) => {

    // 	const canvas = document.getElementById("canvas");
    // 	const ctx = canvas.getContext("2d");
    // 	const imagenn = document.getElementById("source");

    // 	imagenn.addEventListener("load", (e) => {
    // 		ctx.drawImage(imagenn, 33, 71, 104, 124, 21, 20, 87, 104);
    // 	});
    // 	return;

    // 	// // const scaleX = image.naturalWidth / image.width;
    // 	// // const scaleY = image.naturalHeight / image.height;
    // 	// canvas.width = crop.width;
    // 	// canvas.height = crop.height;
    // 	// const ctx = canvas.getContext("2d");
    // 	// let image = new Image();
    // 	// image.src =
    // 	// 	"https://images.unsplash.com/photo-1593642634443-44adaa06623a?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=725&q=80";

    // 	// // ctx.drawImage(
    // 	// // 	image,
    // 	// // 	crop.x * scaleX,
    // 	// // 	crop.y * scaleY,
    // 	// // 	crop.width * scaleX,
    // 	// // 	crop.height * scaleY,
    // 	// // 	0,
    // 	// // 	0,
    // 	// // 	crop.width,
    // 	// // 	crop.height
    // 	// // );
    // 	// ctx.drawImage(image, 150, 200, 500, 300, 60, 60, 500, 300);

    // 	// // New lines to be added
    // 	// const pixelRatio = window.devicePixelRatio;
    // 	// canvas.width = crop.width * pixelRatio;
    // 	// canvas.height = crop.height * pixelRatio;
    // 	// ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    // 	// ctx.imageSmoothingQuality = "high";

    // 	// const base64Image = canvas.toDataURL("image/jpeg");
    // 	// setResult(base64Image);

    // 	// return new Promise((resolve, reject) => {
    // 	// 	canvas.toBlob(
    // 	// 		(blob) => {
    // 	// 			blob.name = fileName;
    // 	// 			resolve(blob);
    // 	// 		},
    // 	// 		"image/jpeg",
    // 	// 		1
    // 	// 	);
    // 	// });
    // };

    return (
        <MainContainer>
            {/* <canvas id="canvas"></canvas> */}
            {/* <div
				style={{
					height: "200px",
					width: "300px",
					background: "#c1c1c1",
				}}
			>
				<input
					type="file"
					accept="image/*"
					onChange={handleFileChange}
				/>
				<ReactCrop
					src={src}
					crop={crop}
					onChange={(newCrop) => setCrop(newCrop)}
				/>
			</div> */}
            {/* <div
				style={{
					height: "200px",
					width: "300px",
					background: "#c1c1c1",
				}}
			>
				<div>
					|
					<input
						type="file"
						accept="image/*"
						onChange={handleFileChange}
					/>
				</div>
				<div>
					{src && (
						<ReactCrop
							id="sample"
							src={src}
							crop={crop}
							onChange={setCrop}
							// onChange={(crop, percentCrop) => setCrop(crop)}
							// onImageLoad={setImage}
						>
							<img src={src} />
						</ReactCrop>
					)}
				</div>

				<div
					style={{
						background: "red",
						cursor: "pointer",
					}}
					onClick={(val) => {
						getCroppedImg(val, image, crop);
					}}
				>
					Crop image{" "}
				</div>

				<div>{result && <img src={result} alt="reeeeeee" />}</div>
			</div> */}
            {!isImageLoading && (
                <>
                    <ImageContainer htmlFor="image-file">
                        {cropedImage !== null ? (
                            <Image src={cropedImage} alt="Profile" />
                        ) : (
                            <Image
                                src={
                                    require("./../assets/images/profile-pic.svg")
                                        .default
                                }
                                alt="Profile"
                            />
                        )}
                    </ImageContainer>
                    <EditDiv htmlFor="image-file">
                        <EditIcon
                            src={
                                require("./../assets/images/edit-icon.svg")
                                    .default
                            }
                            alt="icon"
                        />
                    </EditDiv>
                    <input
                        type="file"
                        id="image-file"
                        accept="image/png, image/gif, image/jpeg"
                        style={{ display: "none" }}
                        onChange={onImageChange}
                        onClick={(e) => {
                            e.target.value = null;
                        }}
                    />
                </>
            )}
            {editModal && (
                <BackContainer>
                    <Overlay
                        onClick={() => {
                            setEditModal(false);
                            setCrop({
                                unit: "%",
                                width: 50,
                                height: 50,
                                x: 25,
                                y: 25,
                            });
                            // setFile(null);
                        }}
                    ></Overlay>
                    <Modal>
                        {/* <canvas id="canvas"></canvas> */}

                        <Container>
                            <ImageDiv>
                                {/* <ImageURL src={image} alt="crop" /> */}
                                {image && (
                                    <ReactCrop
                                        id="sample"
                                        src={image}
                                        crop={crop}
                                        onChange={(crop, percentCrop) => {
                                            setCrop(crop);
                                        }}
                                        aspect={1}
                                    >
                                        <UrlDiv>
                                            <ImageURL
                                                // height="400"
                                                // width="400"
                                                src={image}
                                                id="source"
                                                alt="img"
                                            />
                                        </UrlDiv>
                                    </ReactCrop>
                                )}
                            </ImageDiv>
                            <CropButtonDiv>
                                <CropButton
                                    color="#522e92"
                                    background="#fff"
                                    onClick={() => {
                                        setEditModal(false);
                                        setCrop({
                                            unit: "%",
                                            width: 50,
                                            height: 50,
                                            x: 25,
                                            y: 25,
                                        });
                                        setCropedImage(null);
                                    }}
                                >
                                    Cancel
                                </CropButton>
                                <CropButton
                                    color="#fff"
                                    background="#522e92"
                                    onClick={() => {
                                        handleCrop();
                                    }}
                                    isImageLoading={isImageLoading}
                                >
                                    {isImageLoading ? `Loading...` : "Crop"}
                                </CropButton>
                            </CropButtonDiv>
                        </Container>
                    </Modal>
                </BackContainer>
            )}
        </MainContainer>
    );
}

export default ProfilePicture;
const MainContainer = styled.div`
    position: relative;
    width: fit-content;
`;
const ImageContainer = styled.div`
    height: 100px;
    width: 100px;
    border-radius: 50%;
`;
const Image = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 50%;
`;
const EditDiv = styled.label`
    background-color: #8561c6;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
const EditIcon = styled.img`
    display: inline-block;
    height: 20px;
    width: 20px;
`;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
`;
const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    cursor: pointer;
    height: 100vh;
`;
const Modal = styled.div`
    margin: 0 auto;
    background: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 23px;
    transition: 0.5s;
    z-index: 101;
    max-width: 95%;
    /* display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0; */
    /* .ReactCrop__child-wrapper img {
        width: 100% important;
    } */
    .ReactCrop {
        overflow: hidden;
    }
`;
const Container = styled.div`
    /* width: 400px;
	height: 400px; */
    padding: 20px;

    height: 100%;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-between; */
`;
const ImageDiv = styled.div`
    height: 75vh;
    overflow: scroll;
    border-radius: 16px;
`;
const CropButtonDiv = styled.div`
    /* height: 250px;
	width: 100%; */
    /* height: 40px; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5px;
    height: 40px;
`;
const CropButton = styled.div`
    height: 100%;
    width: fit-content;
    padding: 0 15px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    background: ${(props) => (props.background ? props.background : "#fff")};
    color: ${(props) => (props.color ? props.color : "#fff")};
    border: 1 solid;
    border-color: ${(props) => (props.background ? props.background : "#fff")};
    cursor: pointer;
    margin-right: 15px;
    opacity: ${(props) => (props.isImageLoading ? 0.7 : 1)};
    :last-child {
        margin-right: 0;
    }
`;
const ImageURL = styled.img`
    /* height: 350px;
	width: 400px; */
    /* border-radius: 4px; */
    /* object-fit: contain; */
    /* height: 80vh; */
    /* width: 100%; */
    /* height: 100%; */
    /* height: 80vh; */

    border-radius: 4px;
    /* object-fit: contain;
    width: 100%; */
    height: 100%;
`;
const UrlDiv = styled.div`
    /* display: flex;
	align-items: center;
	justify-content: center; */
    /* width: 400px; */
    /* height: 400px; */
    /* height: 80vh; */
    /* width: 60%; */

    /* height: 80vh; */
    border-radius: 4px;
`;

// import { useState } from "react";
// import ReactCrop from "react-image-crop";
// import "react-image-crop/dist/ReactCrop.css";

// function ProfilePicture() {
// 	const [src, setSrc] = useState(null);
// 	// const [crop, setCrop] = useState({ aspect: 16 / 9 });
// 	// const [image, setImage] = useState(null);
// 	// const [output, setOutput] = useState(null);

// 	// const selectImage = (file) => {
// 	// 	setSrc(URL.createObjectURL(file));
// 	// };

// 	const [crop, setCrop] = useState();

// 	const selectImage = (file) => {
// 		setSrc(URL.createObjectURL(file));
// 	};

// 	// const cropImageNow = () => {
// 	// 	const canvas = document.createElement("canvas");
// 	// 	const scaleX = image.naturalWidth / image.width;
// 	// 	const scaleY = image.naturalHeight / image.height;
// 	// 	canvas.width = crop.width;
// 	// 	canvas.height = crop.height;
// 	// 	const ctx = canvas.getContext("2d");
// 	// 	// log;

// 	// 	const pixelRatio = window.devicePixelRatio;
// 	// 	canvas.width = crop.width * pixelRatio;
// 	// 	canvas.height = crop.height * pixelRatio;
// 	// 	ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
// 	// 	ctx.imageSmoothingQuality = "high";

// 	// 	ctx.drawImage(
// 	// 		image,
// 	// 		crop.x * scaleX,
// 	// 		crop.y * scaleY,
// 	// 		crop.width * scaleX,
// 	// 		crop.height * scaleY,
// 	// 		0,
// 	// 		0,
// 	// 		crop.width,
// 	// 		crop.height
// 	// 	);

// 	// 	// Converting to base64
// 	// 	// const base64Image = canvas.toDataURL("image/jpeg");
// 	// 	// setOutput(base64Image);
// 	// };

// 	return (
// 		// <div className="App">
// 		// 	<center>
// 		// 		<input
// 		// 			type="file"
// 		// 			accept="image/*"
// 		// 			onChange={(e) => {
// 		// 				selectImage(e.target.files[0]);
// 		// 			}}
// 		// 		/>
// 		// 		<br />
// 		// 		<br />
// 		// 		<div>
// 		// 			{src && (
// 		// 				<div>
// 		// 					<ReactCrop
// 		// 						src={src}
// 		// 						onImageLoaded={setImage}
// 		// 						crop={crop}
// 		// 						onChange={setCrop}
// 		// 					/>
// 		// 					<br />
// 		// 					<button onClick={cropImageNow}>Crop</button>
// 		// 					<br />
// 		// 					<br />
// 		// 				</div>
// 		// 			)}
// 		// 		</div>
// 		// 		<div>{output && <img src={output} />}</div>
// 		// 	</center>
// 		// </div>
// 		<>
// 			<input
// 				type="file"
// 				accept="image/*"
// 				onChange={(e) => {
// 					selectImage(e.target.files[0]);
// 				}}
// 			/>
// 			<ReactCrop crop={crop} aspect={1} onChange={(c) => setCrop(c)}>
// 				<img src={src} />
// 			</ReactCrop>

// 			<img style={{ background: "red", padding: "10px" }} src={crop} />
// 		</>
// 	);
// }

// export default ProfilePicture;
