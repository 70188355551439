import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import SelectMemberModalList from "../../components/leadmanager/SelectMemberModalList";
import TD from "../../components/TD";
import Text from "../../components/Text";
import Title from "../../components/Title";
import "./style.css";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";

function SelectTeamMemberModal({
    setSelectedModal,
    setMembersValue,
    salesPerson,
    teamPage,
    addMemberToTeam,
    membersValue,
    selectedTeamData,
    salesPersonSearch,
    setSalesPersonSearch,
}) {
    const { state } = useContext(Context);

    const [search, setSearch] = useState("");
    const [customCount, setCustomCount] = useState("");
    const [checkedItems, setCheckedItems] = useState([]);
    const [chooseLead, setChooseLead] = useState();
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [members, setMembers] = useState();

    const [teamLeaderList] = useState(salesPerson);

    const [bulkSelection] = useState([
        { id: 1, count: 5 },
        { id: 2, count: 10 },
        { id: 3, count: 20 },
        { id: 4, count: 50 },
    ]);

    let arrayData = checkedItems.map((item) => {
        return item.id;
    });

    useEffect(() => {
        setMembersValue(arrayData);
    }, [checkedItems]);

    const getTeamMembers = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(
                `/sales-persons/add-team-members/${selectedTeamData.id}/`,
                // {
                //     members: members,
                // },
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                    params: {
                        search: search,
                    },
                }
            )
            .then((response) => {
                let { StatusCode, message, data } = response.data;
                if (StatusCode == 6000) {
                    // history.push("/salesmanager/salesteams/");
                    // setSelectedMemberModal(false);
                    setMembers(data);
                    // getSalesPerson();
                    // getTeamList();
                } else {
                    alert("Error found");
                }
            })
            .catch((error) => {
                // alert(error);
            });
    };

    // const getSalesPersons = () => {
    //     let accessToken = state.user_details.access_token;
    //     baseConfig
    //         .get("", {
    //             headers: {
    //                 Authorization: "Bearer " + accessToken,
    //             },
    //         })
    //         .then((response) => {
    //             const { StatusCode, data } = response.data;
    //             if (StatusCode === 6000) {
    //                 setDashboardDetails(data);

    //                 setYearlyLead(data.yearly_reports);
    //                 setMonthlyLead(data.monthly_reports);
    //                 setDailylyLead(data.daily_report);

    //                 handleMaxValue(data);
    //             } else {
    //             }
    //         })
    //         .catch((error) => {});
    // };

    //custom check box
    const handleBulkelect = (value) => {
        setCheckedItems(salesPerson.slice(0, value).map((li) => li));
    };

    //select all check box
    const handleSelectAll = (e) => {
        // if (isLoading === false) {
        setIsCheckAll(!isCheckAll);
        setCheckedItems(salesPerson.map((li) => li));
        if (isCheckAll) {
            setCheckedItems([]);
        }
        // }
    };

    //single check box
    const handleClick = (e, data) => {
        const { id, checked } = e.target;
        if (!checked) {
            setCheckedItems(checkedItems.filter((item) => item.id !== id));
        } else {
            setCheckedItems([...checkedItems, data]);
        }
    };
    useEffect(() => {
        // if (teamPage) {
        // setMembers([...members, ...checkedItems]);
    }, [checkedItems]);

    useEffect(() => {
        getTeamMembers();
    }, [search]);

    return (
        <BackContainer className="totalLeads">
            <Overlay
                onClick={() => {
                    setSelectedModal(false);
                }}
            ></Overlay>
            <Modal>
                <ModalContainer>
                    <div>
                        <Title fontSize="28px">Add Team Members</Title>
                        <div
                            style={{
                                height: "20px",
                            }}
                        >
                            {/* <MemberDiv>
								<Heading color="#7B6294">
									no sales persons selected
								</Heading>
							</MemberDiv> */}
                        </div>
                    </div>
                    <Top>
                        <Left>
                            <Heading>
                                Choose no. of sales persons to the team
                            </Heading>
                            <CustomInputDiv>
                                <CustomInput
                                    placeholder="Custom"
                                    onChange={(e) =>
                                        // setCustomCount(e.target.value)
                                        setChooseLead(e.target.value)
                                    }
                                />
                                <ButtonDiv
                                    bordeColor="#522E92"
                                    background="#522E92"
                                    onClick={(e) => {
                                        handleBulkelect(chooseLead);
                                    }}
                                >
                                    <ButtonText color="#fff">Done</ButtonText>
                                </ButtonDiv>
                            </CustomInputDiv>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "10px",
                                }}
                            >
                                {bulkSelection.map((list) => (
                                    <div
                                        key={list.id}
                                        onClick={() => {
                                            setChooseLead(list.count);
                                            handleBulkelect(list.count);
                                        }}
                                        className={
                                            chooseLead == list.count
                                                ? "active"
                                                : "chooseLeads"
                                        }
                                    >
                                        <Text fontSize="18px" color="#808080">
                                            {list.count}
                                        </Text>
                                    </div>
                                ))}
                            </div>
                        </Left>
                        <Right>
                            <SearchDiv>
                                <Form className="search-container">
                                    <SearchIcon
                                        src={
                                            require("./../../assets/images/search.svg")
                                                .default
                                        }
                                    />
                                    <SearchField
                                        className="input-box"
                                        type="text"
                                        name="search"
                                        placeholder="Search for something"
                                        value={salesPersonSearch}
                                        onChange={(e) =>
                                            setSalesPersonSearch(e.target.value)
                                        }
                                    />
                                </Form>
                            </SearchDiv>
                        </Right>
                    </Top>
                    <Bottom>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #E6EFF5",
                                padding: "0 30px 20px",
                            }}
                            className="table-head"
                        >
                            <TD
                                width="10%"
                                color="#808080"
                                fontWeight="500"
                                fontSize="18px"
                            >
                                <input
                                    type="checkbox"
                                    style={{ width: "40%" }}
                                    checked={isCheckAll}
                                    onChange={handleSelectAll}
                                    id="selectAll"
                                />
                            </TD>
                            <TD
                                width="10%"
                                color="#808080"
                                fontWeight="500"
                                fontSize="18px"
                            >
                                Sl No.
                            </TD>
                            <TD
                                width="40%"
                                color="#808080"
                                fontWeight="500"
                                fontSize="18px"
                            >
                                Admission Consultant
                            </TD>
                            <TD
                                width="40%"
                                color="#808080"
                                fontWeight="500"
                                fontSize="18px"
                            >
                                Rank
                            </TD>
                        </div>
                        <div>
                            {salesPerson.map((item, i) => {
                                return (
                                    <SelectMemberModalList
                                        id={item.id}
                                        key={i}
                                        isChecked={checkedItems.includes(item)}
                                        handleClick={handleClick}
                                        data={item}
                                        index={i}
                                    />
                                );
                            })}
                        </div>
                        <ActionDiv>
                            <ButtonDiv
                                bordeColor="#fff"
                                background="#fff"
                                onClick={() => {
                                    setSelectedModal(false);
                                }}
                            >
                                <ButtonText color="#FF0048">Cancel</ButtonText>
                            </ButtonDiv>
                            <ButtonDiv
                                // setMembers
                                onClick={() => {
                                    if (teamPage) {
                                        addMemberToTeam();
                                    } else {
                                        setSelectedModal(false);
                                        // setMembers(checkedItems);
                                    }
                                }}
                                bordeColor="#522E92"
                                background="#522E92"
                            >
                                <ButtonText color="#fff">Save</ButtonText>
                            </ButtonDiv>
                        </ActionDiv>
                    </Bottom>
                </ModalContainer>
            </Modal>
        </BackContainer>
    );
}
export default SelectTeamMemberModal;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
`;
const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    cursor: pointer;
    height: 100vh;
`;
const Modal = styled.div`
    /* margin: 0 auto; */
    // background: #fff;
    width: 75%;
    max-height: 80vh;
    z-index: 101;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    overflow: scroll;
    ::-webkit-scrollbar {
        display: none;
    }

    ::-webkit-scrollbar-track {
        display: none;
    }

    ::-webkit-scrollbar-thumb {
        display: none;
    }
    scrollbar-width: none;
`;

const ModalContainer = styled.div`
    background: #fff;
    border-radius: 12px;
    position: relative;
    padding: 40px;
`;
const MemberDiv = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`;
const Heading = styled.label`
    color: ${(props) => (props.color ? props.color : "#808080")};
    font-size: 16px;
    cursor: pointer;
    user-select: none;
    display: block;
`;
const IndicatorText = styled(Heading)`
    margin-left: 15px;
`;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 30px;
`;

const Left = styled.div``;

const Right = styled.div``;

const SearchDiv = styled.div`
    /* margin-right: 30px; */
    width: 400px;
`;
const Form = styled.form`
    font-size: 15px;
    box-sizing: border-box;
    background: #f5f7fa;
    border-radius: 8px;
    /* width: 400px; */
    border: 1px solid #f5f7fa;
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    /* width: 100%; */
`;
const SearchIcon = styled.img`
    margin-right: 10px;
`;
const SearchField = styled.input`
    width: 100%;
    height: 95%;
    &:focus-visible {
        outline: none;
        background: #f5f7fa;
        border: 0px solid #2d60ff;
    }
`;
const Bottom = styled.div`
    margin-top: 15px;
`;
const RankDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const RankText = styled.span`
    color: #505887;
    font-size: 16px;
    font-weight: 600;
`;
const SelectButtton = styled.div``;
const ButtonDiv = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid;
    border-color: ${(props) => (props.bordeColor ? props.bordeColor : "#fff")};
    border-radius: 5px;
    height: 40px;
    padding: 0 15px;
    margin-right: 15px;
    cursor: pointer;
    user-select: none;
    width: fit-content;
    background-color: ${(props) =>
        props.background ? props.background : "#eeeaf4"};

    &:last-child {
        margin-right: 0;
    }
`;
const ButtonIcon = styled.img`
    margin-right: 4px;
`;
const ButtonText = styled.span`
    color: ${(props) => (props.color ? props.color : "#fff")};
`;
const ActionDiv = styled.div`
    display: flex;
    align-items: center;
    margin-top: 40px;
`;
const CustomInputDiv = styled.div`
    display: flex;
    align-items: center;
    margin-top: 12px;
`;
const CustomInput = styled.input`
    width: 100%;
    height: 45px;
    border-radius: 8px;
    border: 1px solid #dfeaf2;
    padding: 0 15px;
    box-sizing: border-box;
    font-family: "Inter";
    font-size: 16px;
    margin-right: 15px;
    :focus-visible {
        outline: none;
        background: #fbfbfb;
        border: 1px solid #2d60ff;
    }
    ::placeholder {
        color: #cbcbcb;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #cbcbcb;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #cbcbcb;
    }
`;
