import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import React, { useEffect, useState, useContext } from "react";
import { Context } from "./../../contexts/Store";
import Text from "../../components/Text";
import Container from "../../components/Container";
import styled from "styled-components";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import UploadInput from "../../components/UploadInput/UploadInput";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import { baseConfig } from "./../../utils/config";
import { validatePassword } from "./../../helpers/Functions";

function AddStaff() {
	const [file, setFile] = useState(null);
	const history = useHistory();
	const { state } = useContext(Context);
	const [departmentList, setDepartmentList] = useState([]);
	const [selectedDepartment, setSelectedDepartment] = useState("");

	const [id, setId] = useState("");
	const [isError, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const [name, setName] = useState("");
	const [age, setAge] = useState("");
	const [gender, setGender] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [address, setAddress] = useState("");
	const [date, setDate] = useState("");
	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [fieldName, setFieldName] = useState("");
	const [emailValidation, setEmailValidation] = useState(false);

	//password
	const [passwordVisible, setPasswordVisible] = useState(true);
	const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(true);
	const [passwordValidate, setPasswordValidate] = useState({});

	useEffect(() => {
		setPasswordValidate(validatePassword(password));
	}, [password]);

	const criteria = passwordValidate.is_strong_password;

	const validate = (text) => {
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
		if (reg.test(text) === false) {
			setEmailValidation(false);
			setEmail(text);
			return false;
		} else {
			setEmail(text);
			setEmailValidation(true);
		}
	};

	const getStaffId = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/campus/get-staff-id/", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setId(data.staff_id);
				} else {
				}
			})
			.catch((error) => {});
	};

	const getDepartment = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/campus/departments/", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setDepartmentList(data.data);
				} else {
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		getStaffId();
		getDepartment();
	}, []);

	const handleSubmitForm = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				"/campus/staffs/",
				{
					staff_id: id,
					name: name,
					age: age,
					gender: gender,
					phone: phone,
					email: email,
					address: address,
					joining_date: date,
					department: selectedDepartment,
					username: userName,
					password: password,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				let { StatusCode, message } = response.data;
				if (StatusCode == 6000) {
					getStaffId();
					setName("");
					setAge("");
					setGender("");
					setPhone("");
					setEmail("");
					setAddress("");
					setDate("");
					setUserName("");
					setPassword("");
					setConfirmPassword("");
					setSelectedDepartment("");
				} else {
				}
			})
			.catch((error) => {
				alert(error);
			});
	};
	return (
		<Wrapper>
			<Top>
				<TopLeft>
					<Title fontSize="35px">Staff</Title>
					<Text
						fontSize="20px"
						fontWeight="400"
						color="#000"
						style={{ marginTop: "10px" }}
					>
						Staff / Add Staff
					</Text>
				</TopLeft>
				<TopRight
					onClick={() => {
						getStaffId();
						setName("");
						setAge("");
						setGender("");
						setPhone("");
						setEmail("");
						setAddress("");
						setDate("");
						setUserName("");
						setPassword("");
						setConfirmPassword("");
						setSelectedDepartment("");
					}}
				>
					<Text
						fontSize="16px"
						fontWeight="400"
						color="red"
						style={{ marginLeft: "5px" }}
					>
						Clear Form
					</Text>
				</TopRight>
			</Top>
			<div
				style={
					{
						// display: "flex",
						// justifyContent: "space-between",
					}
				}
			>
				<CardDiv>
					<CardTitle>Personal Details</CardTitle>
				</CardDiv>
				<Container
					borderRadius="0"
					style={{ flexWrap: "wrap " }}
					width="100%"
					margin="0"
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<Input
							type="text"
							name="Staff ID (auto generated)"
							value={id}
							disabled
						/>
						<Input
							fieldName={fieldName}
							onChange={(value) => {
								setName(value);
								setError(false);
							}}
							type="text"
							name="Staff Name"
							isError={isError}
							errorMessage={errorMessage}
							value={name}
						/>
					</div>

					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginTop: "20px",
						}}
					>
						<Input
							fieldName={fieldName}
							isError={isError}
							errorMessage={errorMessage}
							onChange={(value) => {
								setAge(value);
								setError(false);
							}}
							type="text"
							name="Age"
							value={age}
						/>
						<Input
							fieldName={fieldName}
							isError={isError}
							errorMessage={errorMessage}
							onChange={(value) => {
								setGender(value);
								setError(false);
							}}
							type="text"
							name="Gender"
							value={gender}
						/>
					</div>

					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginTop: "20px",
						}}
					>
						<Input
							fieldName={fieldName}
							isError={isError}
							errorMessage={errorMessage}
							onChange={(value) => {
								setPhone(value);
								setError(false);
							}}
							type="text"
							name="Phone No*"
							value={phone}
						/>
						<Input
							fieldName={fieldName}
							isError={isError}
							errorMessage={errorMessage}
							onChange={(value) => {
								validate(value);
								setError(false);
							}}
							type="text"
							name="Mail ID"
							value={email}
						/>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginTop: "20px",
						}}
					>
						<Input
							fieldName={fieldName}
							isError={isError}
							errorMessage={errorMessage}
							onChange={(value) => {
								setAddress(value);
								setError(false);
							}}
							type="text"
							name="Address"
							value={address}
						/>
					</div>
				</Container>
				<CardDiv>
					<CardTitle>Campus Details</CardTitle>
				</CardDiv>
				<Container
					borderRadius="0"
					style={{ flexWrap: "wrap " }}
					width="100%"
					margin="0"
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<Input
							fieldName={fieldName}
							onChange={(value) => {
								setDate(value);
								setError(false);
							}}
							type="text"
							name="Join Date"
							isError={isError}
							errorMessage={errorMessage}
							placeholder="Select date"
							onFocus={(e) => {
								e.currentTarget.type = "date";
								e.currentTarget.focus();
							}}
							value={date}
						/>
						<SelectInput
							name="Choose department"
							type="text"
							options={departmentList}
							selectName={"name"}
							selectValue={"id"}
							placeholder="Choose department"
							onChange={(value) => {
								setSelectedDepartment(value);
								setError(false);
							}}
							isError={isError}
							errorMessage={errorMessage}
							fieldName={fieldName}
							defaultValue={"Choose department"}
							value={selectedDepartment}
						/>
					</div>
				</Container>

				<CardDiv>
					<CardTitle>Staff Admin</CardTitle>
				</CardDiv>
				<Container
					borderRadius="0"
					style={{ flexWrap: "wrap " }}
					width="100%"
					margin="0"
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
						}}
					>
						<Input
							fieldName={fieldName}
							// onChange={(value) => {
							// 	(value);
							// 	setError(false);
							// }}
							type="text"
							name="Admin Mail ID"
							isError={isError}
							errorMessage={errorMessage}
							value={email}
						/>

						<Input
							fieldName={fieldName}
							onChange={(value) => {
								setUserName(value);
								setError(false);
							}}
							type="text"
							name="User Name"
							isError={isError}
							errorMessage={errorMessage}
							value={userName}
						/>
					</div>

					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginTop: "20px",
						}}
					>
						<Input
							fieldName={fieldName}
							isError={isError}
							errorMessage={errorMessage}
							onChange={(value) => {
								setPassword(value);
								setError(false);
							}}
							name="Admin Password"
							type={passwordVisible ? "password" : "text"}
							onClickVisible={() =>
								setPasswordVisible(!passwordVisible)
							}
							value={password}
						/>
						<Input
							fieldName={fieldName}
							isError={isError}
							errorMessage={errorMessage}
							onChange={(value) => {
								setConfirmPassword(value);
								setError(false);
							}}
							type="text"
							name="Confirm Admin Password"
							type={confirmPasswordVisible ? "password" : "text"}
							onClickVisible={() =>
								setConfirmPasswordVisible(
									!confirmPasswordVisible
								)
							}
							value={confirmPassword}
						/>
					</div>

					<div
						style={{
							display: "flex",
							marginTop: "30px",
						}}
					>
						<Button
							bacerkgroundColor="#5A2D8F"
							fontSize="15px"
							padding="21px 90px"
							fontWeight="500"
							onClick={(e) => {
								if (name === "") {
									setError(true);
									setFieldName("Staff Name");
									setErrorMessage("This field is required");
								} else if (age === "") {
									setError(true);
									setFieldName("Age");
									setErrorMessage("Enter your age");
								} else if (gender === "") {
									setError(true);
									setFieldName("Gender");
									setErrorMessage("Enter your gender");
								} else if (phone === "") {
									setError(true);
									setFieldName("Phone No*");
									setErrorMessage("Enter your phone number");
								} else if (emailValidation === false) {
									setError(true);
									setFieldName("Mail ID");
									setErrorMessage(
										"Please enter a valid email"
									);
								} else if (address === "") {
									setError(true);
									setFieldName("Address");
									setErrorMessage("Enter your address");
								} else if (date === "") {
									setError(true);
									setFieldName("Join Date");
									setErrorMessage("Add your join date");
								} else if (
									selectedDepartment === "" ||
									selectedDepartment === "Choose department"
								) {
									setError(true);
									setFieldName("Choose department");
									setErrorMessage(
										"Please select a department"
									);
								} else if (userName === "") {
									setError(true);
									setFieldName("User Name");
									setErrorMessage("Enter username");
								} else if (password.length < 8) {
									setError(true);
									setFieldName("Admin Password");
									setErrorMessage(
										"Be a minimum of 8 characters"
									);
								} else if (criteria === false) {
									setError(true);
									setFieldName("Admin Password");
									setErrorMessage(
										"Strong passwords consist of a combination of uppercase and lowercase letters, numbers and special symbols"
									);
								} else if (password !== confirmPassword) {
									setError(true);
									setFieldName("Confirm Admin Password");
									setErrorMessage(
										"Both password must be same"
									);
								} else {
									setError(false);
									e.preventDefault();
									handleSubmitForm();
								}
							}}
						>
							<Text color="white" fontSize="20px">
								Add
							</Text>
						</Button>
						<Button
							backgroundColor="#F4F4F4"
							style={{ marginLeft: "15px" }}
							onClick={() => {
								history.push("/campus-admin/all-staff/");
							}}
						>
							<Text color="#522e92" fontSize="20px">
								Cancel
							</Text>
						</Button>
					</div>
				</Container>
			</div>
		</Wrapper>
	);
}

export default AddStaff;

const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
`;
const TopLeft = styled.div``;
const TopRight = styled.div`
	display: flex;
	align-items: center;
	background-color: #fff;
	height: 50px;
	border-radius: 3px;
	padding: 0 20px;
	cursor: pointer;
`;
const CardDiv = styled.div`
	background: #f1f8fe;
	height: 50px;
	display: flex;
	align-items: center;
	padding: 0 30px;
	border: 1px solid #cce7ff; ;
`;
const CardTitle = styled.h4`
	color: #808080;
`;
