import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";

// import Icon from "../../assets/images/list-icon.svg";
import TD from "../TD";
import Line from "../../assets/images/Line.svg";
import CustomizedDialogs from "./popup";
import styled from "styled-components";
import FollowUpModal from "./FollowUpModal";
import Edit from "../../assets/images/edit.svg";

function MeetingList({
    status,
    item,
    // statusValue,
    setselectedLead,
    selectedLead,
    // setSelectedStatus,
    // setRemarkText,
    // submitFollowUp,
    getData1,
}) {
    const history = useHistory();

    const [statusLabel, setStatusLabel] = useState("Not Intrested");
    const [statusBackground, setStatusBackground] = useState("#FF5B5B26");
    const [statusColor, setStatusColor] = useState("#FF5B5B");

    const [isModal, setIsModal] = useState(false);

    useEffect(() => {
        if (status === "Not interested") {
            setStatusLabel("Not Intrested");
            setStatusBackground("#FF5B5B26");
            setStatusColor("#FF5B5B");
        } else if (status === "Converted" || status === "Need Followup") {
            setStatusLabel(status);
            setStatusBackground("#FFBB5426");
            setStatusColor("#FFBB54");
        } else if (status === "Interested" || status === "Approved" || status === "Student") {
            setStatusLabel(status);
            setStatusBackground("#2ED6A326");
            setStatusColor("#00A389");
        } else {
            setStatusLabel(status);
            setStatusBackground("#FF5B5B26");
            setStatusColor("#FF5B5B");
        }
    }, [status]);

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #E6EFF5",
                    padding: "10px 20px",
                    alignItems: "center",
                }}
            >
                <TD
                    style={{}}
                    // width="8%"
                    color="#505887"
                    fontSize="14px"
                >
                    #{item.lead_id}
                </TD>

                <TD
                    style={{}}
                    //  width="13%"
                    color="#505887"
                    fontSize="14px"
                >
                    {item.name}
                </TD>
                <TD
                    // width="14%"
                    color="#505887"
                    fontSize="14px"
                    style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                >
                    {item.phone}
                </TD>
                {/* <TD
				width="15%"
				color="#505887"
				fontSize="14px"
				style={{ textOverflow: "ellipsis", overflow: "hidden" }}
			>
				{course}
			</TD> */}
                {/* <TD
				width="15%"
				color="#505887"
				fontSize="14px"
				style={{ textOverflow: "ellipsis", overflow: "hidden" }}
			>
				{country}
			</TD> */}
                {/* <TD
					//  width="18%"
					color="#505887"
					fontSize="14px"
				>
					{item.email}
				</TD> */}

                {/* <TD
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          width="16%"
          color="#505887"
          fontSize="14px"
        >
          {item.country_name}
        </TD> */}
                <TD
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    // width="12%"
                    color="#505887"
                    fontSize="14px"
                >
                    {item?.enq_date}
                </TD>
                <TD
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    // width="11%"
                    color="#505887"
                    fontSize="14px"
                >
                    {item?.lead_age?.total_days} days
                </TD>

                {/* <TD width="15%" fontSize="14px">
                    <StatusText
                        color={statusColor}
                        background={statusBackground}
                    >
                        {statusLabel}
                    </StatusText>
                </TD> */}
                {/* <TD
					// width="14%"
					fontSize="14px"
					color="#505887"
					style={{ overflow: "hidden", textOverflow: "ellipsis" }}
				>
					{item.lead_stage}
				</TD> */}
                <TD
                    width="18%"
                    fontSize="14px"
                    color="#505887"
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                    {item?.university}
                </TD>
                <TD
                    // width="18%"
                    fontSize="14px"
                    color="#505887"
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                    {item?.meeting_type}
                </TD>
                <TD
                    // width="18%"
                    fontSize="14px"
                    color="#505887"
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                    {item?.date_time}
                </TD>
                <TD
                    // width="18%"
                    fontSize="14px"
                    color="#505887"
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                    {item?.remark}
                </TD>
                {/* <TLLD>
					<FollowUpButton
						onClick={() => {
							setIsModal(true);
							setselectedLead(item.id);
						}}
					>
						Follow Up
					</FollowUpButton>
					<IconContainer
						onClick={(e) => {
							e.preventDefault();

							history.push(`/admission-consultant/edit-lead/${item.id}/`);
						}}
						style={{ cursor: "pointer" }}
					>
						<Icon src={Edit} alt="Icon" />
					</IconContainer>
				</TLLD> */}
            </div>
            {/* {isModal && (
				<FollowUpModal
					isModal={isModal}
					setIsModal={setIsModal}
					selectedLead={selectedLead}
					getData1={getData1}
				/>
			)} */}
        </>
    );
}

export default MeetingList;

const StatusText = styled.p`
    color: ${(props) => (props.color ? props.color : "#000")};
    background: ${(props) => (props.background ? props.background : "#000")};
    width: 75%;
    border-radius: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const FollowUpButton = styled.div`
    width: 85px;
    margin-right: 10px;
    height: 37px;
    color: rgb(255, 255, 255);
    text-transform: none;
    background-color: rgb(82, 46, 146);
    border-radius: 10px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
const TLLD = styled.div`
    display: flex;
    width: 153px;
    align-items: center;
`;
const IconContainer = styled.div`
    width: 22px;
    height: 16px;
    margin-right: 8px;
    &:last-child {
        margin-right: 0;
    }
`;
const Icon = styled.img`
    width: 80%;
    display: block;
    object-fit: cover;
`;
