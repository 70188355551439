import React, { useState, useEffect, useContext } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Button from "../../components/Button";
import AddIcon from "../../assets/images/addbutton.svg";
import Table from "../../components/Table";
import TD from "../../components/TD";
import styled from "styled-components";
import SalesTeamList from "./SalesTeamList";
import { useHistory, useParams } from "react-router-dom";
import { baseConfig } from "./../../utils/config";
import { Context } from "../../contexts/Store";
import Text from "../../components/Text";
import Progress_bar from "../../components/progressBar/progress";
import Container from "../../components/Container";
import Profile from "../../assets/images/profile-icon.svg";
import DeleteModal from "../../components/includes/DeleteModal";

function SalesTeamSingle() {
    const history = useHistory();
    const { state } = useContext(Context);
    const [isLoading, setLoading] = useState(true);
    const [salesPerformance, setSalesPerformance] = useState([]);
    const [teamsDetails, setTeamDetails] = useState({});
    const { id } = useParams();
    const [salesPersonId, setSalesPersonId] = useState();
    const [isModal, setModal] = useState(false);

    // const getSalesPerformance = () => {
    // 	setLoading(true);
    // 	let accessToken = state.user_details.access_token;
    // 	baseConfig
    // 		.get("/sales-persons/sales-persons/", {
    // 			headers: {
    // 				Authorization: "Bearer " + accessToken,
    // 			},
    // 		})
    // 		.then((response) => {
    // 			const { StatusCode, data } = response.data;
    // 			if (StatusCode === 6000) {
    // 				setSalesPerformance(data.data);
    // 				setLoading(false);
    // 			} else {
    // 				setLoading(false);
    // 			}
    // 		})
    // 		.catch((error) => {});
    // };

    const getSingleTeam = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/sales-persons/team/${id}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setTeamDetails(data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };

    const deleteTeamMember = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        formdata.append("member", salesPersonId);

        baseConfig
            .post(`/sales-persons/remove-team-member/${id}/`, formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode == 6000) {
                    // setModal(false);
                    // getDesignation();
                    // getTeamList();
                    // alert("removed");
                    getSingleTeam();
                    setSalesPersonId("");
                    setModal(false);
                } else if (StatusCode == 6001) {
                    alert(data.data.message);
                    getSingleTeam();
                    setSalesPersonId("");
                    setModal(false);

                    // getDesignation();
                    // getTeamList();
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        // getSalesPerformance();
        getSingleTeam();
    }, []);

    return (
        !isLoading && (
            <Wrapper className="assigned">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "30px",
                    }}
                >
                    <Title fontSize="35px">{teamsDetails?.name}</Title>
                    {/* <Title fontSize="35px">{teamsDetails.name}</Title> */}
                </div>
                <TopContainer>
                    <Left>
                        <RankCard>
                            <ProfileIcon
                                src={teamsDetails?.leader_data?.image ? teamsDetails.leader_data.image : Profile}
                                alt="icon"
                            />
                            <Count fontSize="32px">{teamsDetails?.rank}</Count>
                            <ContentText>Team Ranking</ContentText>
                        </RankCard>
                        <SmallContainer>
                            <SmallCard>
                                <SmallTop>
                                    <Count>{teamsDetails?.total_members}</Count>
                                    <RankIcon
                                        src={require("./../../assets/images/sales-icon.svg").default}
                                        alt="icon"
                                    />
                                </SmallTop>
                                <SmallBottom>
                                    <ContentText>All sales persons</ContentText>
                                </SmallBottom>
                            </SmallCard>
                            <SmallCard>
                                <SmallTop>
                                    <Count>{teamsDetails?.total_lead_assigned}</Count>
                                    <RankIcon
                                        src={require("./../../assets/images/sales-icon.svg").default}
                                        alt="icon"
                                    />
                                </SmallTop>
                                <SmallBottom>
                                    <ContentText>Total Leads</ContentText>
                                </SmallBottom>
                            </SmallCard>
                            <SmallCard>
                                <SmallTop>
                                    <Count>{Math.round(teamsDetails?.success_rate)}%</Count>
                                    <RankIcon
                                        src={require("./../../assets/images/sales-icon.svg").default}
                                        alt="icon"
                                    />
                                </SmallTop>
                                <SmallBottom>
                                    <ContentText>Success Rate</ContentText>
                                </SmallBottom>
                            </SmallCard>
                            <SmallCard>
                                <SmallTop>
                                    <Count>{teamsDetails?.total_lead_admission}</Count>
                                    <RankIcon
                                        src={require("./../../assets/images/sales-icon.svg").default}
                                        alt="icon"
                                    />
                                </SmallTop>
                                <SmallBottom>
                                    <ContentText>Total Converted Leads</ContentText>
                                </SmallBottom>
                            </SmallCard>
                        </SmallContainer>
                    </Left>
                    <Right></Right>
                </TopContainer>
                <BottomContainer>
                    <ContentText fontSize="18px">Team members</ContentText>
                    <BottomDiv>
                        {teamsDetails?.members_data?.map((data) => (
                            <Container
                                style={{
                                    flexWrap: "wrap ",
                                    // borderRadius: "23px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    // padding: "0px 52px",
                                    // marginBottom: "20px",
                                }}
                                width="auto"
                                padding="20px"
                                margin="0 0 20px"
                            >
                                {/* <div style={{ display: "flex" }}> */}
                                <div
                                    style={{
                                        display: "flex",
                                        width: "49%",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        //
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {data.image === null ? (
                                            <ProfileImg style={{ marginBottom: "0px" }} src={Profile} alt="Logo" />
                                        ) : (
                                            <ProfileImg style={{ marginBottom: "0px" }} src={data.image} alt="Logo" />
                                        )}
                                        <div style={{ marginLeft: "12px" }}>
                                            <Text color="black" fontSize="18px">
                                                {data.rank}
                                            </Text>
                                            <Text color="black" fontSize="16px">
                                                {data.name}
                                            </Text>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            width: "50%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Text fontSize="15px" color="black" style={{ marginBottom: "8px" }}>
                                                Target Achieved
                                            </Text>
                                            <Text fontSize="15px" color="black">
                                                &nbsp;
                                                {data.target_achieved}%
                                            </Text>
                                        </div>
                                        <Progress_bar
                                            width="100%"
                                            bgcolor=" #522E92"
                                            progress={data.target_achieved}
                                            height={10}
                                        />
                                    </div>
                                </div>
                                <hr />
                                <div
                                    style={{
                                        width: "49%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            // margin: "50px 30px",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Text
                                            // style={{ margin: "0px 30px" }}
                                            style={{
                                                width: "18%",
                                                textAlign: "center",
                                            }}
                                            color="#808080"
                                            fontSize="15px"
                                        >
                                            Total Leads
                                        </Text>
                                        <Text
                                            // style={{ margin: "0px 30px" }}
                                            style={{
                                                width: "18%",
                                                textAlign: "center",
                                            }}
                                            color="#808080"
                                            fontSize="15px"
                                        >
                                            Introduction
                                        </Text>
                                        <Text
                                            // style={{ margin: "0px 30px" }}
                                            style={{
                                                width: "18%",
                                                textAlign: "center",
                                            }}
                                            color="#808080"
                                            fontSize="15px"
                                        >
                                            Meeting
                                        </Text>
                                        <Text
                                            // style={{ margin: "0px 30px" }}
                                            style={{
                                                width: "18%",
                                                textAlign: "center",
                                            }}
                                            color="#808080"
                                            fontSize="15px"
                                        >
                                            Admission
                                        </Text>
                                        <Text
                                            color="#808080"
                                            fontSize="15px"
                                            style={{
                                                width: "18%",
                                                textAlign: "center",
                                            }}
                                        >
                                            Dead
                                        </Text>
                                        <Text
                                            color="#808080"
                                            fontSize="15px"
                                            style={{
                                                width: "18%",
                                                textAlign: "center",
                                            }}
                                        ></Text>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            // margin: "0px 30px",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Text
                                            // style={{ margin: "0px 60px" }}
                                            style={{
                                                width: "18%",
                                                textAlign: "center",
                                            }}
                                            color="black"
                                            fontSize="15px"
                                        >
                                            {data.total_lead_assigned}
                                        </Text>
                                        <Text
                                            // style={{ margin: "0px 60px" }}
                                            style={{
                                                width: "18%",
                                                textAlign: "center",
                                            }}
                                            color="black"
                                            fontSize="15px"
                                        >
                                            {data.total_lead_introduction}
                                        </Text>
                                        <Text
                                            // style={{ margin: "0px 60px" }}
                                            style={{
                                                width: "18%",
                                                textAlign: "center",
                                            }}
                                            color="black"
                                            fontSize="15px"
                                        >
                                            {data.total_lead_meeting}
                                        </Text>
                                        <Text
                                            // style={{ margin: "0px 60px" }}
                                            style={{
                                                width: "18%",
                                                textAlign: "center",
                                            }}
                                            color="black"
                                            fontSize="15px"
                                        >
                                            {data.total_lead_admission}
                                        </Text>
                                        <Text
                                            // style={{ margin: "0px 20px" }}
                                            style={{
                                                width: "18%",
                                                textAlign: "center",
                                            }}
                                            color="black"
                                            fontSize="15px"
                                        >
                                            {data.total_lead_dead}
                                        </Text>
                                        <Text
                                            // style={{ margin: "0px 20px" }}
                                            style={{
                                                width: "18%",
                                                textAlign: "center",
                                                fontWeight: 400,
                                                marginBottom: "30px",
                                                cursor: "pointer",
                                            }}
                                            color="red"
                                            fontSize="15px"
                                            onClick={() => {
                                                setSalesPersonId(data.id);
                                                // deleteTeamMember(data.id);
                                                setModal(true);
                                            }}
                                        >
                                            Remove
                                        </Text>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </Container>
                        ))}
                    </BottomDiv>
                </BottomContainer>
                {isModal && (
                    <DeleteModal
                        isModal={isModal}
                        setModal={setModal}
                        // deleteId={id}
                        handleDeleteData={deleteTeamMember}
                    />
                )}
            </Wrapper>
        )
    );
}

export default SalesTeamSingle;

const TopContainer = styled.div`
    margin-bottom: 30px;
`;
const Left = styled.div`
    width: 48%;
    /* display: flex;
	align-items: center; */
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    grid-gap: 20px;
`;

const RankCard = styled.div`
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    /* height: 200px; */
    /* width: 31%; */
`;
const RankIcon = styled.img`
    /* margin-bottom: 20px; */
`;
const ProfileIcon = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    object-fit: cover;
`;
const Count = styled.h3`
    font-size: ${(props) => (props.fontSize ? props.fontSize : "24px")};
    color: #8561c6;
    margin-bottom: 5px;
    font-weight: 500;
`;
const ContentText = styled.p`
    font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
    color: #000;
`;
const Right = styled.div`
    width: 48%;
`;
const SmallContainer = styled.div`
    /* display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap; */
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
`;
const SmallCard = styled.div`
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    /* width: 31%; */
`;
const SmallTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
`;
const SmallBottom = styled.div``;

const BottomContainer = styled.div``;

const BottomDiv = styled.div`
    margin-top: 12px;
`;
const ProfileImg = styled.img`
    height: 50px;
    width: 50px;
    border-radius: 50%;
`;
