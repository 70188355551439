import ApiService from "../utils/fetch";

export const ListCampusAdminService = async (data) => {
	let response = await ApiService.get(`/campus/campus-admin-list/`, data);
	return response;
};

export const GetCampusAdminService = async (id, data) => {
	let response = await ApiService.get(
		`/campus/campus-admin-details/${id}/`,
		data
	);
	return response;
};

export const AddCampusAdminService = async (data) => {
	let state = data.state.user_details.access_token;
	var formdata = new FormData();
	formdata.append("campus", data.campusName);
	formdata.append("email", data.email);
	formdata.append("username", data.userName);
	formdata.append("password", data.password);

	let response = await ApiService.post(`/campus/campus-admin-list/`, {
		body: formdata,
		state: state,
	});
	return response;
};

export const UpdateCampusAdminService = async (data) => {
	var formdata = new FormData();
	formdata.append("campus", data.campus);
	formdata.append("email", data.email);
	formdata.append("username", data.username);
	formdata.append("password", data.password);
	let response = await ApiService.put(
		`/campus/campus-admin-details/${data.id}/`,
		{ body: formdata }
	);
	return response;
};

export const DeleteCampusAdminService = async (id, data) => {
	let response = await ApiService.delete(
		`/campus/campus-admin-details/${id}/`,
		data
	);
	return response;
};
