import React, { useEffect, useState, useContext } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import visible from "../../assets/images/visible.svg";
import { useHistory } from "react-router-dom";
import { AddCampusAdminService } from "../../service/campusAdmin";
import { ListCampusService } from "../../service/campus";
import { validatePassword } from "./../../helpers/Functions";
import { baseConfig } from "./../../utils/config";
import { Context } from "../../contexts/Store";

function AddStudent() {
	const history = useHistory();
	const [isError, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [fieldName, setFieldName] = useState("");

	const [firstName, setFirstName] = useState("");
	const [middleName, setMiddleName] = useState("");
	const [lastName, setLastName] = useState("");
	const [department, setDepartment] = useState("");
	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const [passwordValidate, setPasswordValidate] = useState({});
	const [joiningDate, setJoiningDate] = useState("");
	const [DOB, setDOB] = useState("");
	const [departmentList, setDepartmentList] = useState([]);
	const { state } = useContext(Context);

	useEffect(() => {
		setPasswordValidate(validatePassword(password));
	}, [password]);

	const criteria = passwordValidate.is_strong_password;

	const [form, setFormValues] = useState({
		passwordShow: false,
		confirmPasswordShow: false,
	});

	const getDepartment = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/campus/departments/", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setDepartmentList(data.data);
				} else {
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		getDepartment();
	}, []);

	const handleChangeValue = (key, value) => {
		setFormValues({ ...form, [key]: value });
	};

	const handleSubmitForm = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				"/students/student/",
				{
					first_name: firstName,
					middle_name: middleName,
					last_name: lastName,
					department: department,
					joining_date: joiningDate,
					date_of_birth: DOB,
					username: userName,
					password: password,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				let { StatusCode, message } = response.data;
				if (StatusCode == 6000) {
					setFirstName("");
					setMiddleName("");
					setLastName("");
					setDepartment("");
					setUserName("");
					setPassword("");
					setJoiningDate("");
					setDOB("");
				} else {
				}
			})
			.catch((error) => {
				alert(error);
			});
	};

	return (
		<Wrapper>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					marginBottom: "30px",
				}}
			>
				<div style={{ display: "flex", margin: "0 12px" }}>
					<Title fontSize="35x">Add Student</Title>
				</div>

				<div>
					<Button
						href="#"
						style={{
							display: "flex",
							alignItems: "center",
							margin: "0 22px",
						}}
						backgroundColor="white"
						fontSize="15px"
						padding="18px 57px"
						onClick={(e) => {
							e.preventDefault();
							const form = document.getElementById("form");
							form.reset();
							setFormValues({});
							setPassword("");
							setUserName("");
						}}
					>
						<Text color="  #EE1451" fontSize="15px">
							Clear Form
						</Text>
					</Button>
				</div>
			</div>

			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<Container style={{ flexWrap: "wrap " }} width="100%">
					<form id="form">
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								// marginTop: "20px",
							}}
						>
							<Input
								onChange={(value) => {
									setFirstName(value);
									setError(false);
								}}
								type="name"
								name="First Name"
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
								value={firstName}
							/>

							<Input
								onChange={(value) => {
									setMiddleName(value);
									setError(false);
								}}
								type="type"
								name="Middle Name"
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
								value={middleName}
							/>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: "20px",
							}}
						>
							<Input
								onChange={(value) => {
									setLastName(value);
									setError(false);
								}}
								type="name"
								name="Last Name"
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
								value={lastName}
							/>

							<SelectInput
								name="Choose Department"
								type="text"
								placeholder="Choose Department"
								onChange={(value) => {
									{
										setDepartment(value);
										setError(false);
									}
								}}
								options={departmentList ? departmentList : []}
								selectName={"name"}
								selectValue={"id"}
								value={department}
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
								defaultValue="Select a campus"
								// value={department}
							/>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: "20px",
							}}
						>
							<Input
								fieldName={fieldName}
								onChange={(value) => {
									setJoiningDate(value);
									setError(false);
								}}
								type="text"
								name="Join Date"
								isError={isError}
								errorMessage={errorMessage}
								placeholder="Select date"
								onFocus={(e) => {
									e.currentTarget.type = "date";
									e.currentTarget.focus();
								}}
								value={joiningDate}
							/>

							<Input
								fieldName={fieldName}
								onChange={(value) => {
									setDOB(value);
									setError(false);
								}}
								type="text"
								name="Join Date"
								isError={isError}
								errorMessage={errorMessage}
								placeholder="Select date"
								onFocus={(e) => {
									e.currentTarget.type = "date";
									e.currentTarget.focus();
								}}
								value={DOB}
							/>
						</div>
						<div
							style={{
								display: "flex",
								marginTop: "20px",
								justifyContent: "space-between ",
							}}
						>
							<Input
								onChange={(value) => {
									setUserName(value);
									setError(false);
								}}
								type="name"
								name="UserName"
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
								value={userName}
							/>

							<Input
								onClickVisible={() =>
									handleChangeValue(
										"passwordShow",
										!form?.passwordShow
									)
								}
								onChange={(value) => {
									setPassword(value);
									setError(false);
								}}
								type={form?.passwordShow ? "text" : "password"}
								name="Password"
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
								value={password}
							/>
							{/* <Input
								onClickVisible={() =>
									handleChangeValue(
										"confirmPasswordShow",
										!form?.confirmPasswordShow
									)
								}
								onChange={(value) => {
									setConfirmPassword(value);
									setError(false);
								}}
								type={
									form?.confirmPasswordShow
										? "text"
										: "password"
								}
								name="Confirm Admin Password"
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
							/> */}
						</div>
						<div style={{ display: "flex", marginTop: "21px" }}>
							<Button
								href="#"
								style={{
									display: "flex",
									alignItems: "center",
								}}
								bacerkgroundColor="#5A2D8F"
								fontSize="15px"
								padding="21px 70px"
								onClick={(e) => {
									if (firstName === "") {
										setError(true);
										setFieldName("First Name");
										setErrorMessage(
											"Please your first name"
										);
									} else if (
										department === "" ||
										department === "Select a department"
									) {
										setError(true);
										setFieldName("Choose Department");
										setErrorMessage("Choose Department");
									} else if (userName === "") {
										setError(true);
										setFieldName("Username");
										setErrorMessage(
											"Please enter username"
										);
									} else if (password.length < 8) {
										setError(true);
										setFieldName("Password");
										setErrorMessage(
											"Be a minimum of 8 characters"
										);
									} else if (criteria === false) {
										setError(true);
										setFieldName("Password");
										setErrorMessage(
											"Strong passwords consist of a combination of uppercase and lowercase letters, numbers and special symbols"
										);
									} else {
										e.preventDefault();
										handleSubmitForm();
									}
								}}
							>
								<Text color="white" fontSize="15px">
									Submit
								</Text>
							</Button>

							<Button
								onClick={() => {
									history.push("/superadmin/campus-admins/");
								}}
								style={{
									display: "flex",
									alignItems: "center",
									margin: "0 22px",
								}}
								backgroundColor="#F4F4F4"
								fontSize="15px"
								padding="21px 70px"
							>
								<Text color=" #522e92" fontSize="15px">
									Cancel
								</Text>
							</Button>
						</div>
					</form>
				</Container>
			</div>
		</Wrapper>
	);
}

export default AddStudent;
