import Header from "../../components/header/header";
import NavBar from "../../components/navBar/navBar";
import navBarData from "./navBarData";
import MainDashboard from "./mainDashboard";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./style.css";
import { useState } from "react";
import AllUniversity from "./AllUniversity";
import AddUniversity from "./AddUniversity";
import AllInstitution from "./AllInstitution";
import AddInstitution from "./AddInstitution";
import AllDepartment from "./AllDepartment";
import AddDepartment from "./AddDepartment";
import AllSubDepartment from "./AllSubDepartment";
import AddSubDepartment from "./AddSubDepartment";
import AllDesignation from "./AllDesignation";
import AddDesignation from "./AddDesignation";
import AllStaff from "./AllStaff";
import AddStaff from "./AddStaff";

function CampusAdmin() {
	const [openNav, setOpenNav] = useState(false);
	return (
		<div className="body">
			<NavBar
				openNav={openNav}
				data={navBarData}
				setOpenNav={setOpenNav}
			/>
			<div className="body-container">
				<Header setOpenNav={setOpenNav} />
				<Switch>
					<Route
						path="/campus-admin/"
						exact
						component={MainDashboard}
					/>
					<Route
						path="/campus-admin/all-university/"
						exact
						component={AllUniversity}
					/>
					<Route
						path="/campus-admin/add-university/"
						exact
						component={AddUniversity}
					/>
					{/* <Route
						path="/campus-admin/all-institution/"
						exact
						component={AllInstitution}
					/>
					<Route
						path="/campus-admin/add-institution/"
						exact
						component={AddInstitution}
					/> */}
					<Route
						path="/campus-admin/all-department/"
						exact
						component={AllDepartment}
					/>
					<Route
						path="/campus-admin/add-department/"
						exact
						component={AddDepartment}
					/>
					{/* <Route
						path="/campus-admin/all-sub-department/"
						exact
						component={AllSubDepartment}
					/>
					<Route
						path="/campus-admin/add-sub-department/"
						exact
						component={AddSubDepartment}
					/> */}
					<Route
						path="/campus-admin/all-designation/"
						exact
						component={AllDesignation}
					/>
					<Route
						path="/campus-admin/add-designation/"
						exact
						component={AddDesignation}
					/>
					<Route
						path="/campus-admin/all-staff/"
						exact
						component={AllStaff}
					/>
					<Route
						path="/campus-admin/add-staff/"
						exact
						component={AddStaff}
					/>
				</Switch>
			</div>
		</div>
	);
}

export default CampusAdmin;
