import Title from "../../components/Title";
import React, { useEffect, useState, useContext } from "react";
import add from "../../assets/images/add.svg";
import Wrapper from "../../components/Wrapper";
import Text from "../../components/Text";
import Button from "../../components/Button";
import filter from "../../assets/images/filter.svg";
import buttonplus from "../../assets/images/buttonplus.svg";
import menudown from "../../assets/images/menu-right.svg";
import Datefilter from "../../assets/images/datefilter.svg";
import radioactive from "../../assets/images/radioactive.svg";
import radionotactive from "../../assets/images/radionotactive.svg";
import Ticked from "../../assets/images/Tickticked.svg";
import Unticked from "../../assets/images/Unticked.svg";
import warning from "../../assets/images/warningsort.svg";
import ascdes from "../../assets/images/ascdes.svg";
import Table from "../../components/Table";
import TD from "../../components/TD";
import Line from "../../assets/images/Line.svg";
import Pagination from "../../components/pagination/pagination";
import assignedListData from "../../components/salesPerson/assignedListData";
import AssignedListItem from "../../components/salesPerson/assignedListItem";
import {
    AddSourceService,
    ListLeadsService,
    ListSourceService,
} from "../../service/leads";
import { Context } from "./../../contexts/Store";
import styled from "styled-components";
import { baseConfig } from "./../../utils/config";
import MainPagination from "../../components/includes/MainPagination";
import loadingGif from "../../assets/lottie/loading.gif";
import { Popover } from "@mui/material";

function FollowDup() {
    const [pageCount, setPageCount] = useState("");
    const [count, setCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    // const [status, setStatus] = useState("all");
    const [leads, setLeads] = useState([]);
    const [data, setData] = useState(leads);
    const { state } = useContext(Context);
    const [selectedLead, setselectedLead] = useState("");
    const [isLoading, setLoading] = useState(true);
    const [search, setSearch] = useState();
    const [paginationData, setPaginationData] = useState({});
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [universities, setUniversities] = useState([]);
    const [courses, setCourses] = useState([]);
    const [radiodate, setRadiodate] = useState("enq");
    const [radiofilter, setRadioFilter] = useState("");
    const [pick, setPick] = useState(false);
    const [selection, setSelection] = useState("");
    const [head, setHead] = useState("");
    const [addHead, setAddHead] = useState([
        "id",
        "name",
        "phone",
        "country",
        "enq_date",
        "lead_status",
        "nfd",
        "lfd",
        "remark",
    ]);
    const [countList, setCountList] = useState([]);
    const [all, setAll] = useState("");
    const [today, setToday] = useState("");
    const [favorite, setFavorite] = useState("");
    const [missed, setMissed] = useState("");
    const [location, setLocation] = useState([]);
    const [sourceData, setSourceData] = useState([]);
    const [enqsort, setEnqSort] = useState("");
    const [nfdsort, setNfdSort] = useState("");
    const [lfdsort, setLfdSort] = useState("");
    const [apply, setApply] = useState([]);
    const [nfdStart, setNfdStart] = useState("");
    const [lfdStart, setLfdStart] = useState("");
    const [nfdEnd, setNfdEnd] = useState("");
    const [lfdEnd, setLfdEnd] = useState("");
    const [selectedDashboard, setSelectedDashboard] =
        useState("Lead Dashboard");
    const [status, setStatus] = useState("followup");
    const [listStatus, setListStatus] = useState([]);
    useEffect(() => {
        setListStatus([
            {
                id: 1,
                title: "All followup",
                value: "followup",
                count: all,
            },
            {
                id: 2,
                title: "Today's Followup",
                value: "today followup",
                count: today,
            },
            {
                id: 3,
                title: "Favorite",
                value: "favorite",
                count: favorite,
            },
            {
                id: 4,
                title: "Missed Followup",
                value: "missed followup",
                count: missed,
            },
        ]);
    }, [all, today, favorite, missed]);
    console.log(addHead, "headdddddddd");
    const selectColumn = [
        {
            id: 0,
            title: "Lead Id",
            value: "id",
            default: "id",
        },
        {
            id: 1,
            title: "Name",
            value: "name",
            default: "name",
        },
        {
            id: 2,
            title: "Phone 2",
            value: "phone_2",
        },
        {
            id: 3,
            title: "Email",
            value: "email",
        },
        {
            id: 4,
            title: "Country",
            value: "country",
            default: "country",
        },
        {
            id: 5,
            title: "Nationality",
            value: "nationality",
        },
        {
            id: 6,
            title: "Lead source",
            value: "lead_source",
        },
        {
            id: 7,
            title: "Lead Campaign",
            value: "lead_campaign",
        },
        {
            id: 8,
            title: "Status",
            value: "lead_status",
            default: "lead_status",
        },
        {
            id: 9,
            title: "Consultant",
            value: "consultant_choice",
        },
        {
            id: 10,
            title: "Lead Stage",
            value: "lead_stage",
        },
        {
            id: 11,
            title: "Remark",
            value: "remark",
            default: "remark",
        },
        {
            id: 12,
            title: "University",
            value: "university",
        },
        {
            id: 13,
            title: "Course",
            value: "course",
        },
        {
            id: 14,
            title: "Specialization",
            value: "specialization",
        },
        {
            id: 15,
            title: "Enquiry Date",
            value: "enquiry_date",
            default: "enquiry_date",
        },
        {
            id: 16,
            title: "Lead Age",
            value: "lead_age",
        },
        {
            id: 17,
            title: "Assigned to",
            value: "assign_to",
        },
        {
            id: 18,
            title: "NFD",
            value: "nfd",
            default: "nfd",
        },
        {
            id: 19,
            title: "LFD",
            value: "lfd",
            default: "lfd",
        },
        {
            id: 20,
            title: "Phone",
            value: "phone",
            default: "phone",
        },
    ];

    useEffect(() => {
        setAll(countList.all_leads_count);
        setToday(countList.today_followup_leads_count);
        setFavorite(countList.favorite_leads_count);
        setMissed(countList.missed_followup_leads_count);
    }, [countList]);
    const interested = [];
    const not_interested = [];
    const status_pending = [];
    const follow_up = [];
    const converted = [];
    //searching location
    const [query, setQuery] = useState("");
    const [filteredResults, setFilteredResults] = useState([]);
    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setQuery(inputValue);

        // Perform filtering logic here (e.g., from an API call or local data)
        // For simplicity, let's assume you have a list of items to filter.
        const filteredItems = location.filter((item) =>
            item.name.toLowerCase().includes(inputValue.toLowerCase())
        );

        setFilteredResults(filteredItems);
    };
    // Enquiry list sorting
    const handleEnqSorting = () => {
        if (enqsort === "") {
            setEnqSort("asc");
            setLfdSort("asc");
            setNfdSort("asc");
        } else {
            setEnqSort("");
            setLfdSort("");
            setNfdSort("");
        }
    };
    const handleLfdSorting = () => {
        if (lfdsort === "") {
            setLfdSort("asc");
            setEnqSort("asc");
            setNfdSort("asc");
        } else {
            setLfdSort("");
            setNfdSort("");
            setEnqSort("");
        }
    };
    const handleNfdSorting = () => {
        if (nfdsort === "") {
            setNfdSort("asc");
            setLfdSort("asc");
            setEnqSort("asc");
        } else {
            setNfdSort("");
            setLfdSort("");
            setEnqSort("");
        }
    };
    // const getData = (item) => {
    //   if (item.status === "not_intrested") {
    //     not_interested.push(item);
    //   }
    //   if (item.status === "converted") {
    //     converted.push(item);
    //   }
    //   if (item.status === "interested") {
    //     interested.push(item);
    //   }
    //   if (item.status === "status_pending") {
    //     status_pending.push(item);
    //   }
    //   if (item.follow_up === "follow_up") {
    //     follow_up.push(item);
    //   }
    //   if (item.converted === "converted") {
    //     converted.push(item);
    //   }
    // };
    // assignedListData.map(getData);
    // Get current posts
    const indexOfLastPost = currentPage * count;
    const indexOfFirstPost = indexOfLastPost - count;
    const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= Math.ceil(data.length / count)) {
            setCurrentPage(pageNumber);
        }
    };
    const handlePopup = () => {
        setPick(!pick);
    };
    const settingFromDate = (e) => {
        if (radiodate === "enq") {
            setFromDate(e.target.value);
        } else if (radiodate === "lfd") {
            setLfdStart(e.target.value);
        } else {
            setNfdStart(e.target.value);
        }
    };
    const settingEndDate = (e) => {
        if (radiodate === "enq") {
            setToDate(e.target.value);
        } else if (radiodate === "lfd") {
            setLfdEnd(e.target.value);
        } else {
            setNfdEnd(e.target.value);
        }
    };
    // For Filter Purpose
    const [sortValue, setSortValue] = useState("");
    const [universityValues, setUniversityValues] = useState("");
    const [courseValues, setCourseValues] = useState("");
    const [locationValues, setLocationValues] = useState("");
    const [sourceValues, setSourceValues] = useState("");
    const [selectedStage, setSelectedStage] = useState("");

    const [dateOrder, setDateOrder] = useState("");

    const dateOrders = [
        { name: "New to old", value: "new" },
        { name: "Old to new", value: "old" },
    ];
    const [stages] = useState([
        { id: 1, value: "hot", name: "Hot" },
        { id: 1, value: "cold", name: "Cold" },
        { id: 1, value: "warm", name: "Warm" },
    ]);
    const [statusFilter] = useState(status, selectedStage);
    // edit popover onClick states and functions on filter
    const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
    const handleClickFilter = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };
    const handleCloseFilter = () => {
        setFilterAnchorEl(null);
    };
    const openFilter = Boolean(filterAnchorEl);
    const filterAnchorId = openFilter ? "simple-popover" : undefined;
    // end of filter
    // edit popover onClick states and functions on date filter
    const [dateAnchorEl, setDateAnchorEl] = React.useState(null);
    const handleClickDate = (event) => {
        setDateAnchorEl(event.currentTarget);
    };
    const handleCloseDate = () => {
        setDateAnchorEl(null);
    };
    const openDate = Boolean(dateAnchorEl);
    const dateAnchorId = openDate ? "simple-popover" : undefined;
    // end of date filter
    // edit popover onClick states and functions on selection filter
    const [plusAnchorEl, setplusAnchorEl] = React.useState(null);
    const handleClickPlus = (event) => {
        setplusAnchorEl(event.currentTarget);
    };
    const handleClosePlus = () => {
        setplusAnchorEl(null);
    };
    const openPlus = Boolean(plusAnchorEl);
    const plusAnchorId = openPlus ? "simple-popover" : undefined;
    // end of selection filter
    //radio functioning in filter
    const [stageRadio, setStageRadio] = useState(false);
    const [universityRadio, setUniversityRadio] = useState(false);
    const [courseRadio, setCourseRadio] = useState(false);
    const [locationRadio, setLocationRadio] = useState(false);
    const [sourceRadio, setSourceRadio] = useState(false);

    const handleStageRadio = () => {
        setUniversityRadio(false);
        setCourseRadio(false);
        setLocationRadio(false);
        setSourceRadio(false);
        setStageRadio(!stageRadio);
    };
    const handleUniversityRadio = () => {
        setStageRadio(false);
        setCourseRadio(false);
        setLocationRadio(false);
        setSourceRadio(false);
        setUniversityRadio(!courseRadio);
    };
    const handleCourseRadio = () => {
        setStageRadio(false);
        setUniversityRadio(false);
        setLocationRadio(false);
        setSourceRadio(false);
        setCourseRadio(!courseRadio);
    };
    const handleLocationRadio = () => {
        setStageRadio(false);
        setCourseRadio(false);
        setUniversityRadio(false);
        setSourceRadio(false);
        setLocationRadio(!locationRadio);
    };
    const handleSourceRadio = () => {
        setStageRadio(false);
        setCourseRadio(false);
        setLocationRadio(false);
        setUniversityRadio(false);
        setSourceRadio(!sourceRadio);
    };
    //end of radio filter functioning

    const setTickDefault = (item) => {
        selectColumn[item].default = "new";
    };
    //adding selected heads
    const addHeadings = (item) => {
        if (addHead.includes(item)) {
            setAddHead(addHead.filter((data) => data !== item));
        } else {
            setAddHead([...addHead, item]);
        }
    };
    const setApplyFilter = (addHead) => {
        if (addHead.length > 5 && addHead.length <= 10) {
            getData1();
            setApply(addHead);
            handleClosePlus();
        } else {
        }
    };
    // const toggleImage = (item) => {
    //     if (addHead.includes(item.value)) {
    //       return Unticked; // If the item is already selected, show Unticked
    //     } else {
    //       return Ticked; // Show Ticked for items without default and not selected
    //     }
    //   };
    ///end
    const getData1 = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/leads/followup-leads/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    items: pageCount,
                    page: currentPage,
                    enq_start: fromDate,
                    enquiry_end: toDate,
                    nfd_start: nfdStart,
                    nfd_end: nfdEnd,
                    lfd_start: lfdStart,
                    lfd_end: lfdEnd,
                    status: selectedStage,
                    section: status,
                    search: search,
                    ordering: sortValue,
                    university: universityValues,
                    country: locationValues,
                    lead_source: sourceValues,
                    course: courseValues,
                    date_order: dateOrder,
                    stage: selectedStage,
                    lfd_order: lfdsort,
                    nfd_order: nfdsort,
                    enq_date_order: enqsort,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setCountList(data);
                    setLeads(data.data);
                    setPaginationData(data.paginator);
                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };
    const getUniversities = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/university-list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setUniversities(data);
                } else {
                }
            })
            .catch((error) => {});
    };
    const getLocation = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/countries/list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLocation(data);
                } else {
                }
            })
            .catch((error) => {});
    };
    const getSources = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/leads/lead-source-data/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setSourceData(data);
                } else {
                }
            })
            .catch((error) => {});
    };
    const getCourses = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/courses/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    university: universityValues,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setCourses(data.data);
                } else {
                }
            })
            .catch((error) => {});
    };
    useEffect(() => {
        getData1();
    }, [
        status,
        currentPage,
        search,
        sortValue,
        pageCount,
        dateOrder,
        enqsort,
        nfdsort,
        lfdsort,
    ]);
    // useEffect(() => {
    //     if (toDate || nfdEnd || lfdEnd) {
    //         getData1();
    //     }
    // },[toDate, nfdEnd,lfdEnd]);
    useEffect(() => {
        getUniversities();
    }, []);
    useEffect(() => {
        getLocation();
        getSources();
        getCourses();
    }, [locationValues, sourceValues, universityValues]);
    useEffect(() => {
        setCourseValues("");
    }, [universityValues]);

    return (
        <Wrapper className="assigned">
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "15px",
                }}
            >
                <div>
                    <Title fontSize="30px">Follow ups</Title>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <CustomDateContainer>
                        <EmptyDiv
                            className={openDate ? "pop" : ""}
                            aria-describedby={dateAnchorId}
                            variant="contained"
                            // onClick={handleClickFilter}
                            onClick={handleClickDate}
                        >
                            <DateContainer>
                                <img
                                    style={{
                                        width: "18px",
                                        // filter: "brightness(0.5)",
                                    }}
                                    src={Datefilter}
                                    alt="Logo"
                                />
                                <TextDate
                                    style={{
                                        marginLeft: "12px",
                                        fontWeight: "500",
                                    }}
                                    color="#5A2D8F"
                                    fontSize="16px"
                                >
                                    Date
                                </TextDate>
                            </DateContainer>
                            <img
                                style={{
                                    filter: "invert(1)",
                                    transform: openDate
                                        ? "rotate(-90deg)"
                                        : "rotate(90deg)",
                                }}
                                src={menudown}
                                alt="Icon"
                            />
                        </EmptyDiv>
                        <Popover
                            id={dateAnchorId}
                            open={openDate}
                            anchorEl={dateAnchorEl}
                            onClose={handleCloseDate}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <ContainerDate>
                                <Radio>
                                    <RadioInput
                                        onClick={() => {
                                            setRadiodate("enq");
                                        }}
                                        style={{
                                            background:
                                                radiodate === "enq"
                                                    ? "#EDF5FE"
                                                    : "",
                                        }}
                                    >
                                        <RadioImage
                                            src={
                                                radiodate === "enq"
                                                    ? radioactive
                                                    : radionotactive
                                            }
                                        />
                                        <TitleRadio
                                            style={{
                                                color:
                                                    radiodate === "enq"
                                                        ? "#010101"
                                                        : "#9D9D9D",
                                            }}
                                        >
                                            Enq Date
                                        </TitleRadio>
                                    </RadioInput>
                                    <RadioInput
                                        onClick={() => {
                                            setRadiodate("nfd");
                                        }}
                                        style={{
                                            background:
                                                radiodate === "nfd"
                                                    ? "#EDF5FE"
                                                    : "",
                                        }}
                                    >
                                        <RadioImage
                                            src={
                                                radiodate === "nfd"
                                                    ? radioactive
                                                    : radionotactive
                                            }
                                        />
                                        <TitleRadio
                                            style={{
                                                color:
                                                    radiodate === "nfd"
                                                        ? "#010101"
                                                        : "#9D9D9D",
                                            }}
                                        >
                                            NFD
                                        </TitleRadio>
                                    </RadioInput>
                                    <RadioInput
                                        onClick={() => {
                                            setRadiodate("lfd");
                                        }}
                                        style={{
                                            background:
                                                radiodate === "lfd"
                                                    ? "#EDF5FE"
                                                    : "",
                                        }}
                                    >
                                        <RadioImage
                                            src={
                                                radiodate === "lfd"
                                                    ? radioactive
                                                    : radionotactive
                                            }
                                        />
                                        <TitleRadio
                                            style={{
                                                color:
                                                    radiodate === "lfd"
                                                        ? "#010101"
                                                        : "#9D9D9D",
                                            }}
                                        >
                                            LFD
                                        </TitleRadio>
                                    </RadioInput>
                                </Radio>
                                {/* <SearchDivDate>
                    <FormDate className="search-container">
                        <SearchIconDate
                            src={
                                require("./../../assets/images/search.svg")
                                    .default
                            }
                        />
                        <SearchFieldDate
                            className="input-box"
                            type="text"
                            name="search"
                            placeholder="Search for date"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </FormDate>
                </SearchDivDate> */}
                                <DateFieldBox>
                                    <DateFieldText>From</DateFieldText>
                                    <DateInput
                                        onChange={(e) => {
                                            settingFromDate(e);
                                        }}
                                        type="date"
                                        name="From"
                                        // min={
                                        //   toDate ? moment(minFromDateValue).format("yyyy-MM-DD") : null
                                        // }
                                        max={
                                            toDate
                                                ? toDate
                                                : nfdStart
                                                ? nfdStart
                                                : lfdStart
                                                ? lfdStart
                                                : new Date().toLocaleDateString(
                                                      "en-CA"
                                                  )
                                        }
                                        value={
                                            radiodate === "enq"
                                                ? fromDate
                                                : radiodate === "nfd"
                                                ? nfdStart
                                                : lfdStart
                                        }
                                    />
                                </DateFieldBox>
                                <DateFieldBox>
                                    <DateFieldText>To</DateFieldText>
                                    <DateInput
                                        onChange={(e) => {
                                            settingEndDate(e);
                                        }}
                                        type="date"
                                        name="To"
                                        min={
                                            radiodate === "enq"
                                                ? fromDate
                                                : radiodate === "nfd"
                                                ? nfdStart
                                                : lfdStart
                                        }
                                        // max={moment(maxToDateValue).format("yyyy-MM-DD")}
                                        max={new Date().toLocaleDateString(
                                            "en-CA"
                                        )}
                                        disabled={
                                            fromDate || nfdStart || lfdStart
                                                ? false
                                                : true
                                        }
                                        value={
                                            radiodate === "enq"
                                                ? toDate
                                                : radiodate === "nfd"
                                                ? nfdEnd
                                                : lfdEnd
                                        }
                                    />
                                </DateFieldBox>
                                {/* {fromDate &&
                            toDate &&
                            fromDate !== "dd/mm/yyyy" &&
                            toDate !== "dd/mm/yyyy" && (
                                <ClearFilterText
                                    onClick={() => {
                                        setFromDate("dd/mm/yyyy");
                                        setToDate("dd/mm/yyyy");
                                        // setFromDate("");
                                        // setToDate("");
                                        getData1();
                                    }}
                                >
                                    Apply Filter
                                </ClearFilterText>
                            )} */}
                                <ApplyButtonDate
                                    onClick={() => {
                                        getData1();
                                        handleCloseDate();
                                    }}
                                >
                                    Apply Filters
                                </ApplyButtonDate>
                            </ContainerDate>
                        </Popover>
                        <CustomDateFields></CustomDateFields>
                    </CustomDateContainer>
                    <FilterButtonWrapper>
                        <Button
                            style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "0px 20px",
                                height: "45px",
                                marginLeft: "10px",
                                width: "140px",
                                borderRadius: "9px",
                            }}
                            backgroundColor="#5A2D8F"
                            fontSize="15px"
                            aria-describedby={filterAnchorId}
                            variant="contained"
                            // onClick={handleClickFilter}
                            onClick={handleClickFilter}
                            // href="/superadmin/add-campus"
                        >
                            <img
                                style={{
                                    width: "18px",
                                    // filter: "brightness(0.5)",
                                }}
                                src={filter}
                                alt="Logo"
                            />
                            <Text
                                style={{
                                    marginLeft: "12px",
                                    fontWeight: "500",
                                }}
                                color="#fff"
                                fontSize="16px"
                            >
                                Filter
                            </Text>
                        </Button>
                        <Popover
                            id={filterAnchorId}
                            open={openFilter}
                            anchorEl={filterAnchorEl}
                            onClose={handleCloseFilter}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <Container>
                                <Div>
                                    <Title fontSize="20px">Filter by</Title>
                                    <img
                                        style={{
                                            cursor: "pointer",
                                            width: "16px",
                                        }}
                                        onClick={handleCloseFilter}
                                        src={
                                            require("./../../assets/images/close.svg")
                                                .default
                                        }
                                        alt="icon"
                                    />
                                </Div>
                                <MainCont
                                    style={{
                                        minHeight: "250px",
                                        maxHeight: "90vh",
                                        overflow: "scroll",
                                    }}
                                >
                                    <Body>
                                        <Heading
                                            onClick={() => handleStageRadio()}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {/* <RadioImage 
                                            src={stageRadio && !locationRadio && !universityRadio && !courseRadio && !sourceRadio 
                                            ? radioactive : radionotactive
                                            }/> */}
                                                <TitleRadio>Status</TitleRadio>
                                            </div>
                                            <img
                                                style={{
                                                    filter: "invert(1)",
                                                    transform:
                                                        stageRadio &&
                                                        !locationRadio &&
                                                        !universityRadio &&
                                                        !courseRadio &&
                                                        !sourceRadio
                                                            ? "rotate(-90deg)"
                                                            : "rotate(90deg)",
                                                }}
                                                src={menudown}
                                                alt="Icon"
                                            />
                                        </Heading>
                                        {stageRadio ? (
                                            <div>
                                                {stages.map((item) => (
                                                    <div
                                                        onClick={() => {
                                                            setSelectedStage(
                                                                item.value
                                                            );
                                                            if (
                                                                selectedStage ===
                                                                item.value
                                                            ) {
                                                                setSelectedStage(
                                                                    ""
                                                                );
                                                            }
                                                        }}
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            padding:
                                                                "10px 20px 0px 20px",
                                                            cursor: "pointer",
                                                        }}
                                                        key={item.id}
                                                    >
                                                        <RadioImage
                                                            src={
                                                                selectedStage ===
                                                                item.value
                                                                    ? radioactive
                                                                    : radionotactive
                                                            }
                                                        />
                                                        <SubHeading
                                                            style={{
                                                                color:
                                                                    selectedStage ===
                                                                    item.value
                                                                        ? "#552E91"
                                                                        : "#515151",
                                                            }}
                                                        >
                                                            {item.name}
                                                        </SubHeading>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </Body>
                                    <Body>
                                        <Select>
                                            <Heading
                                                onClick={() =>
                                                    handleUniversityRadio()
                                                }
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {/* <RadioImage 
                                            src={universityRadio && !stageRadio && !courseRadio && !locationRadio && !sourceRadio
                                                 ? radioactive : radionotactive}/> */}
                                                    <TitleRadio>
                                                        University
                                                    </TitleRadio>
                                                </div>
                                                <img
                                                    style={{
                                                        filter: "invert(1)",
                                                        transform:
                                                            universityRadio &&
                                                            !stageRadio &&
                                                            !courseRadio &&
                                                            !locationRadio &&
                                                            !sourceRadio
                                                                ? "rotate(-90deg)"
                                                                : "rotate(90deg)",
                                                    }}
                                                    src={menudown}
                                                    alt="Icon"
                                                />
                                            </Heading>
                                            {universityRadio ? (
                                                <OptionUni>
                                                    {universities.map(
                                                        (data) => (
                                                            <div
                                                                onClick={() => {
                                                                    setUniversityValues(
                                                                        data.id
                                                                    );
                                                                    if (
                                                                        universityValues ===
                                                                        data.id
                                                                    ) {
                                                                        setUniversityValues(
                                                                            ""
                                                                        );
                                                                    }
                                                                }}
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                    padding:
                                                                        "10px 20px 0px 20px",
                                                                    cursor: "pointer",
                                                                }}
                                                                key={data.id}
                                                            >
                                                                <RadioImage
                                                                    src={
                                                                        universityValues ===
                                                                        data.id
                                                                            ? radioactive
                                                                            : radionotactive
                                                                    }
                                                                />

                                                                <SubHeading
                                                                    style={{
                                                                        color:
                                                                            universityValues ===
                                                                            data.id
                                                                                ? "#552E91"
                                                                                : "#515151",
                                                                    }}
                                                                >
                                                                    {data.name}
                                                                </SubHeading>
                                                            </div>
                                                        )
                                                    )}
                                                </OptionUni>
                                            ) : (
                                                ""
                                            )}
                                        </Select>
                                    </Body>
                                    {courses && (
                                        <Body>
                                            <Heading
                                                onClick={() =>
                                                    handleCourseRadio()
                                                }
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-start",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {/* <RadioImage 
                                                src={courseRadio ? radioactive : radionotactive}/> */}
                                                    <TitleRadio>
                                                        Courses
                                                    </TitleRadio>
                                                </div>
                                                <img
                                                    style={{
                                                        filter: "invert(1)",
                                                        transform: courseRadio
                                                            ? "rotate(-90deg)"
                                                            : "rotate(90deg)",
                                                    }}
                                                    src={menudown}
                                                    alt="Icon"
                                                />
                                            </Heading>
                                            {courseRadio ? (
                                                <div>
                                                    {courses.map((data) => (
                                                        <div
                                                            onClick={() => {
                                                                setCourseValues(
                                                                    data.id
                                                                );
                                                                if (
                                                                    courseValues ===
                                                                    data.id
                                                                ) {
                                                                    setCourseValues(
                                                                        ""
                                                                    );
                                                                }
                                                            }}
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                padding:
                                                                    "10px 20px 0px 20px",
                                                                cursor: "pointer",
                                                            }}
                                                            key={data.id}
                                                        >
                                                            <RadioImage
                                                                src={
                                                                    courseValues ===
                                                                    data.id
                                                                        ? radioactive
                                                                        : radionotactive
                                                                }
                                                            />
                                                            <SubHeading
                                                                style={{
                                                                    color:
                                                                        courseValues ===
                                                                        data.id
                                                                            ? "#552E91"
                                                                            : "#515151",
                                                                }}
                                                            >
                                                                {data.name}
                                                            </SubHeading>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </Body>
                                    )}
                                    <Body>
                                        <Heading
                                            onClick={() =>
                                                handleLocationRadio()
                                            }
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {/* <RadioImage src={locationRadio ? radioactive : radionotactive}/> */}
                                                <TitleRadio>
                                                    Location
                                                </TitleRadio>
                                            </div>
                                            <img
                                                style={{
                                                    filter: "invert(1)",
                                                    transform: locationRadio
                                                        ? "rotate(-90deg)"
                                                        : "rotate(90deg)",
                                                }}
                                                src={menudown}
                                                alt="Icon"
                                            />
                                        </Heading>
                                        {locationRadio ? (
                                            <div>
                                                <SearchDivDate>
                                                    <FormDate className="search-container">
                                                        <SearchIconDate
                                                            src={
                                                                require("./../../assets/images/search.svg")
                                                                    .default
                                                            }
                                                        />
                                                        <SearchFieldDate
                                                            className="input-box"
                                                            type="text"
                                                            name="search"
                                                            value={query}
                                                            placeholder="Search for something"
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                    </FormDate>
                                                </SearchDivDate>
                                                {filteredResults.map((data) => (
                                                    <div
                                                        onClick={() => {
                                                            setLocationValues(
                                                                data.id
                                                            );
                                                            if (
                                                                locationValues ===
                                                                data.id
                                                            ) {
                                                                setLocationValues(
                                                                    ""
                                                                );
                                                            }
                                                        }}
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            padding:
                                                                "10px 20px 0px 20px",
                                                            cursor: "pointer",
                                                        }}
                                                        key={data.id}
                                                    >
                                                        <RadioImage
                                                            src={
                                                                locationValues ===
                                                                data.id
                                                                    ? radioactive
                                                                    : radionotactive
                                                            }
                                                        />
                                                        <SubHeading
                                                            style={{
                                                                color:
                                                                    locationValues ===
                                                                    data.id
                                                                        ? "#552E91"
                                                                        : "#515151",
                                                            }}
                                                        >
                                                            {data.name}
                                                        </SubHeading>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </Body>
                                    <Body>
                                        <Heading
                                            onClick={() => handleSourceRadio()}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {/* <RadioImage src={sourceRadio ? radioactive : radionotactive}/> */}
                                                <TitleRadio>Source</TitleRadio>
                                            </div>
                                            <img
                                                style={{
                                                    filter: "invert(1)",
                                                    transform: sourceRadio
                                                        ? "rotate(-90deg)"
                                                        : "rotate(90deg)",
                                                }}
                                                src={menudown}
                                                alt="Icon"
                                            />
                                        </Heading>
                                        {sourceRadio && (
                                            <div>
                                                {sourceData.map((item) => (
                                                    <div
                                                        onClick={() => {
                                                            setSourceValues(
                                                                item.id
                                                            );
                                                            if (
                                                                sourceValues ===
                                                                item.id
                                                            ) {
                                                                setSourceValues(
                                                                    ""
                                                                );
                                                            }
                                                        }}
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            padding:
                                                                "10px 20px 0px 20px",
                                                            cursor: "pointer",
                                                        }}
                                                        key={item.id}
                                                    >
                                                        <RadioImage
                                                            src={
                                                                sourceValues ===
                                                                item.id
                                                                    ? radioactive
                                                                    : radionotactive
                                                            }
                                                        />
                                                        <SubHeading
                                                            style={{
                                                                color:
                                                                    sourceValues ===
                                                                    item.id
                                                                        ? "#552E91"
                                                                        : "#515151",
                                                            }}
                                                        >
                                                            {item.name}
                                                        </SubHeading>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </Body>
                                    <ButtonsBody>
                                        <ApplyButtonDiv
                                            onClick={() => {
                                                getData1();
                                                handleCloseFilter();
                                            }}
                                        >
                                            Apply Filters
                                        </ApplyButtonDiv>
                                        {/* <CloseButton
                                        onClick={() => {
                                            
                                        }}
                                    >
                                        Apply
                                    </CloseButton> */}
                                    </ButtonsBody>
                                </MainCont>
                            </Container>
                        </Popover>
                    </FilterButtonWrapper>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "10px 0px 30px",
                }}
            >
                {listStatus.length !== 0 && (
                    <ButtonNav>
                        {listStatus.map((item, index) => (
                            <a
                                href="#"
                                className={
                                    item.value == status
                                        ? "menu-status"
                                        : "active"
                                }
                                onClick={() => {
                                    setStatus(item.value);
                                }}
                                key={item.id}
                            >
                                <Text
                                    fontSize="16px"
                                    fontWeight="400"
                                    color="#000"
                                >
                                    {item.title}
                                </Text>
                                <Count
                                    className={
                                        item.count >= 1000
                                            ? "fourdigit"
                                            : item.count >= 100
                                            ? "threedigit"
                                            : "activecount"
                                    }
                                >
                                    {item.count}
                                </Count>
                            </a>
                        ))}
                    </ButtonNav>
                )}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <SearchDiv>
                        <Form className="search-container">
                            <SearchIcon
                                src={
                                    require("./../../assets/images/search.svg")
                                        .default
                                }
                            />
                            <SearchField
                                className="input-box"
                                type="text"
                                name="search"
                                placeholder="Search for something"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </Form>
                    </SearchDiv>
                    <SelectionButton
                        className={openPlus ? "pop" : ""}
                        aria-describedby={plusAnchorId}
                        variant="contained"
                        // onClick={handleClickFilter}
                        onClick={handleClickPlus}
                    >
                        <img src={buttonplus} alt="Icon" />
                    </SelectionButton>
                    <Popover
                        id={plusAnchorId}
                        open={openPlus}
                        anchorEl={plusAnchorEl}
                        onClose={handleClosePlus}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        <ContainerColumn>
                            <TopHead>Add to Column</TopHead>
                            <MiddleSelector>
                                {selectColumn.map((item) => (
                                    <OptionTag
                                        onClick={() => {
                                            addHeadings(item.value);
                                        }}
                                        key={item.id}
                                    >
                                        <RadioImage
                                            src={
                                                addHead.includes(item.value)
                                                    ? Ticked
                                                    : Unticked
                                            }
                                        />
                                        <TitleOption>{item.title}</TitleOption>
                                    </OptionTag>
                                ))}
                            </MiddleSelector>
                            <BottomContainer>
                                <Warning>
                                    <WarningImage src={warning} />
                                    <WarningText>
                                        Select minimum 5 and maximum 9
                                    </WarningText>
                                </Warning>
                                <ApplyButton
                                    onClick={() => setApplyFilter(addHead)}
                                >
                                    Apply
                                </ApplyButton>
                                <span
                                    style={{
                                        padding: "10px",
                                    }}
                                ></span>
                            </BottomContainer>
                        </ContainerColumn>
                    </Popover>
                </div>
            </div>

            <div style={{ marginBottom: "10px" }}>
                {!isLoading ? (
                    <>
                        {leads.length > 0 ? (
                            <Tablee>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        borderBottom: "1px solid #E6EFF5",
                                        padding: "20px 20px 20px 20px",
                                        background: "#EDF5FE",
                                    }}
                                    className="table-head"
                                >
                                    {apply.length === 0 || addHead ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="15px"
                                            width="7.5%"
                                        >
                                            ID No.
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.length === 0 || addHead ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="11%"
                                        >
                                            Lead Name
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.includes("university") ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="11%"
                                            style={{
                                                // marginRight: "0.5%",
                                                position: "relative",
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                            }}
                                        >
                                            University
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.includes("course") ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="8%"
                                        >
                                            Course
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.includes("location") ||
                                    apply.length === 0 ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="10%"
                                        >
                                            Location
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.includes("phone") ||
                                    apply.length === 0 ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="11%"
                                        >
                                            Phone
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.includes("enquiry_date") ||
                                    apply.length === 0 ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="12%"
                                            onClick={() => handleEnqSorting()}
                                        >
                                            Enq. Date
                                            <img src={ascdes} alt="Icon" />
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.includes("lead_source") ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="12%"
                                        >
                                            Source
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.includes("assign_to") ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="11%"
                                        >
                                            Assigned to
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.includes("consultant_choice") ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="18%"
                                        >
                                            Consultant Choice
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.includes("country") ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="11%"
                                        >
                                            Country
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.includes("lead_campaign") ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="15%"
                                        >
                                            Lead Campaign
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.includes("lead_stage") ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="11%"
                                        >
                                            Lead Stage
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.includes("nationality") ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="11%"
                                        >
                                            Nationality
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.includes("phone_2") ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="9%"
                                        >
                                            Phone 2
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.includes("email") ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="11%"
                                        >
                                            Email
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.includes("specialization") ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="12%"
                                        >
                                            Specialization
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.includes("lead_status") ||
                                    apply.length === 0 ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="10%"
                                        >
                                            Status
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.includes("nfd") ||
                                    apply.length === 0 ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="8%"
                                            style={
                                                {
                                                    // marginRight: "0.5%",
                                                }
                                            }
                                            onClick={() => handleNfdSorting()}
                                        >
                                            NFD
                                            <img src={ascdes} alt="Icon" />
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.includes("lfd") ||
                                    apply.length === 0 ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="8%"
                                            style={
                                                {
                                                    // marginRight: "0.5%",
                                                }
                                            }
                                            onClick={() => handleLfdSorting()}
                                        >
                                            LFD
                                            <img src={ascdes} alt="Icon" />
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    {apply.includes("remark") ||
                                    apply.length === 0 ? (
                                        <TD
                                            color="#808080"
                                            fontWeight="500"
                                            fontSize="16px"
                                            width="12%"
                                        >
                                            Remarks
                                        </TD>
                                    ) : (
                                        ""
                                    )}
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        width="140px"
                                    >
                                        Follow up
                                    </TD>
                                    {/* <div style={{ width: "5%" }}></div> */}
                                </div>
                                <div
                                    style={{ padding: "0 0px" }}
                                    className="table-body"
                                >
                                    {leads?.map((item) => {
                                        return (
                                            <AssignedListItem
                                                status={item.status}
                                                statusType={status}
                                                item={item}
                                                state={state}
                                                apply={apply}
                                                selection={selection}
                                                // statusValue={statusValue}
                                                setselectedLead={
                                                    setselectedLead
                                                }
                                                selectedLead={selectedLead}
                                                // setSelectedStatus={setSelectedStatus}
                                                // setRemarkText={setRemarkText}
                                                // submitFollowUp={submitFollowUp}
                                                getData1={getData1}
                                            />
                                        );
                                    })}
                                </div>
                            </Tablee>
                        ) : (
                            <LoaderContainer>
                                <EmptyTextDiv>No leads Found</EmptyTextDiv>
                            </LoaderContainer>
                        )}
                        <div style={{ marginTop: "10px" }}>
                            <MainPagination
                                pageCount={pageCount}
                                setPageCount={setPageCount}
                                isCountSelector={true}
                                paginationData={paginationData}
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                            />
                        </div>
                    </>
                ) : (
                    <LoaderContainer>
                        <LoadingIcon src={loadingGif} alt="icon" />
                    </LoaderContainer>
                )}
            </div>
        </Wrapper>
    );
}

export default FollowDup;

const Tablee = styled.div`
    background: #fff;
    border-radius: 0px 30px 0 0;
`;

const DashboardButtonDiv = styled.div`
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 5px;
    border-radius: 12px;
    justify-content: space-between;
`;
const DashboardButton = styled.div`
    background-color: ${(props) =>
        props.background ? props.background : "#fff"};
    padding: 10px 30px;
    border-radius: 12px;
    cursor: pointer;
`;
const ButtonName = styled.h3`
    color: ${(props) => (props.color ? props.color : "#000")};
    font-size: 15px;
    font-weight: 500;
    padding: 0 10px;
`;
const LoaderContainer = styled.div`
    /* border: 1px solid #000; */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 220px);
`;
const LoadingIcon = styled.img`
    width: 65px;
    display: block;
    object-fit: cover;
`;
const CustomDateFields = styled.div`
    display: flex;
    align-items: center;
`;
const DateInput = styled.input`
    /* display: inline-block !important;
    width: 200px !important;
    height: 42px;
    font-size: 15px;
    box-sizing: border-box;
    padding: 6px;
    border-radius: 8px !important; */
    width: 280px !important;
    padding: 4px 10px;
    height: 50px;
    margin-bottom: 12px;
    font-size: 18px;
    letter-spacing: 0.2px;
    line-height: 24px;
    font-weight: 400;
    color: #404040;
    -webkit-text-fill-color: #000;
    box-sizing: border-box;
    /* border: 1px solid #dfeaf2; */
    border: 1px solid rgb(102, 102, 102);
    border-radius: 8px;
    cursor: pointer;
    &:hover {
        background: #fbfbfb;
    }
    &:focus-visible {
        outline: none;
        background: #fbfbfb;
        border: 1px solid #2d60ff;
    }
`;
const DateFieldBox = styled.div`
    margin-right: 12px;
    &:last-child {
        margin-right: 0;
    }
`;
const DateFieldText = styled.span`
    display: block;
    margin-bottom: 8px;
    color: #515151;
    line-height: 25.6px;
    font-size: 16px;
`;
const CustomDateContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const EmptyDiv = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 20px;
    height: 45px;
    background-color: #fff;
    font-size: 15px;
    width: 140px;
    border-radius: 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &.pop {
        border: 1px solid #5d2d8f;
    }
`;
const DateContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
const TextDate = styled.div``;
const ClearFilterText = styled.div`
    align-self: flex-end;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #522e92;
    width: 100px;
    text-align: center;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
`;
const EmptyTextDiv = styled.div`
    width: 100%;
    padding: 20px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #808080;
`;
const SelectionButton = styled.div`
    width: 45px;
    cursor: pointer;
    & img {
        width: 100%;
        display: block;
    }
`;
const SearchDiv = styled.div`
    /* margin-right: 20px; */
    width: 308px;
    margin-right: 15px;
`;
const SearchDivDate = styled.div`
    /* margin-right: 20px; */
    width: 270px !important;
    margin: 12px 0px;
`;
const Form = styled.form`
    font-size: 15px;
    box-sizing: border-box;
    background: #f5f7fa;
    border-radius: 8px;
    width: 308px !important;
    border: 1px solid #aea7fd;
    height: 45px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    /* width: 100%; */
`;
const FormDate = styled.form`
    font-size: 15px;
    box-sizing: border-box;
    background: #f5f7fa;
    border-radius: 8px;
    width: 270px !important;
    border: 1px solid #aea7fd;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 7px;
    /* width: 100%; */
`;
const SearchIcon = styled.img`
    margin-right: 10px;
`;
const SearchField = styled.input`
    width: 100%;
    height: 90%;
    &:focus-visible {
        outline: none;
        background: #f5f7fa;
        border: 0px solid #2d60ff;
    }
`;
const SearchIconDate = styled.img`
    margin-right: 10px;
    width: 18px;
`;
const SearchFieldDate = styled.input`
    width: 100%;
    height: 90%;
    font-size: 15px;
    &:focus-visible {
        outline: none;
        background: #f5f7fa;
        border: 0px solid #2d60ff;
    }
`;
const FilterButtonWrapper = styled.div``;
const ButtonNav = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    height: 45px;
    width: 65%;
    padding: 0px 10px;
    background: #fff;
    border-radius: 10px;
    & a {
        background: #fff;
        height: 40px;
        min-width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
const Count = styled.span`
    display: none;
    &.activecount {
        padding: 6px;
        border-radius: 14px;
        background: #522e92;
        color: #fff;
        display: block;
        margin-left: 10px;
        font-size: 12px;
        height: 11px;
        width: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.fourdigit {
        padding: 8px;
        border-radius: 14px;
        background: #522e92;
        color: #fff;
        display: block;
        margin-left: 10px;
        font-size: 9px;
        height: 13px;
        width: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.fivedigit {
        padding: 6px;
        border-radius: 14px;
        background: #522e92;
        color: #fff;
        display: block;
        margin-left: 10px;
        font-size: 10px;
        height: 13px;
        width: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
const Container = styled.div`
    // height: 130px;
    width: 300px;
    max-height: 360px;
    // padding: 30px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const ContainerColumn = styled.div`
    // height: 130px;
    width: 325px;
    max-height: 780px;
    position: relative;

    // padding: 30px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const TopHead = styled.div`
    background: #edf5fe;
    padding: 10px;
    color: #808080;
    font-size: 20px;
`;
const MiddleSelector = styled.div`
    padding: 16px;
    overflow: scroll;
`;
const BottomContainer = styled.div`
    position: sticky;
    bottom: 0;
    right: 0;
    background: #fff;
`;
const OptionTag = styled.div`
    background: #edf5fe;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
    padding: 8px;
    cursor: pointer;
`;
const TitleOption = styled.p`
    color: #9d9d9d;
    font-size: 16px;
`;
const ApplyButtonDiv = styled.div`
    height: 50px;
    background: #522e92;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    margin: 10px 16px;
    width: 100%;
`;
const ApplyButtonDate = styled.div`
    height: 50px;
    background: #522e92;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    margin: 10px 0px;
    width: 100%;
`;
const Warning = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 16px;
    margin-top: 10px;
`;
const WarningImage = styled.img`
    width: 15px;
    margin-right: 10px;
`;
const WarningText = styled.p`
    font-size: 18px;
    color: #808080;
`;
const ContainerDate = styled.div`
    // height: 130px;
    width: 280px;
    max-height: 360px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const Radio = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-bottom: 12px;
`;
const RadioInput = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
`;
const RadioImage = styled.img`
    margin-right: 5px;
`;
const TitleRadio = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: #9d9d9d;
`;
// const Title = styled.h4`
//     font-size: 20px;
//     color: #808080;
// `;
const Div = styled.div`
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    background-color: #f1f8fe;
    padding: 15px 15px;
    margin-bottom: 5px;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #cce7ff;
`;
const MainCont = styled.div`
    min-height: 250px;
    max-height: 90vh;
    overflow: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;
const Body = styled.div`
    background-color: #fff;
    padding: 5px 10px;
    /* max-height: 70vh; */
    // border-radius 0 0 5px 5px;
    /* overflow: scroll; */
    border-bottom: ${(props) =>
        props.borderBottom ? props.borderBottom : null};
`;
const Heading = styled.h4`
    font-size: 16px;
    color: #515151;
    font-weight: 600;
    background: #edf5fe;
    display: flex;
    height: 35px;
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;
    padding: 8px 20px;
`;
const Select = styled.div``;
const OptionUni = styled.div``;
const SubHeading = styled.h4`
    font-size: 13px;
    color: "#515151";
    margin: 0 10px;
`;
const FButton = styled.div`
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : "#522e92"};
    font-size: ${(props) => (props.fontSize ? props.fontSize : "25px")};
    color: ${(props) => (props.color ? props.color : "white")};
    // padding: ${(props) => (props.padding ? props.padding : "15px 40px")};
    border: none;
    border-radius: 8px;
    text-align: center;
    display: inline-block;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 200px;
    cursor: pointer;
    // margin-top: 20px;
`;
const ButtonText = styled.h4`
    font-family: "Inter";
    font-size: ${(props) => (props.fontSize ? props.fontSize : "25px")};
    color: ${(props) => (props.color ? props.color : "#505887")};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "600")};
    text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
`;
const ApplyButton = styled.div`
    width: 80%;
    padding: 15px;
    display: flex;
    margin: 10px 10px 0px 10px;
    justify-content: center;
    align-items: center;
    /* background: #80669d; */
    background: #522e92;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
    &:hover {
        opacity: 0.9;
    }
`;
const ButtonsBody = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    /* max-height: 70vh; */
    // border-radius 0 0 5px 5px;
    /* overflow: scroll; */
    border-bottom: ${(props) =>
        props.borderBottom ? props.borderBottom : null};
`;
const CloseButton = styled.div`
    width: 38%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #80669d; */
    background: #ededed;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    color: #522e92;
    &:hover {
        /* opacity: 0.7; */
        background: #efefef;
    }
`;
const Add = styled.p`
    font-size: 16px;
    font-weight: 500;
    color: #808080;
`;
const Popup = styled.div`
    display: none;
    &.pop {
        display: block;
        position: absolute;
        bottom: -70px;
        right: -100px;
        z-index: 100;
        background: #fff;
        padding: 5px;
        border-radius: 6px;
        width: 150px;
    }
`;
// const Option = styled.div`
//     padding: 5px;
//     cursor: pointer;
//     :hover{
//         background: #daf5ff;
//     }
// `;
