import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Title from "../Title";
import Wrapper from "../Wrapper";
import Select from "./Select";

import calendarIcon from "../../assets/images/custom-calendar-icon.svg";
import { ReactComponent as CalendarIcon } from "../../assets/images/custom-calendar-icon.svg";
import downloadIcon from "../../assets/images/download-icon.svg";
import unselectedIcon from "../../assets/images/checkbox-unselected.svg";
import { Popover } from "@material-ui/core";
import ReportPageTable from "./ReportPageTable";
import { Context } from "../../contexts/Store";
import { baseConfig } from "../../utils/config";
import DropDownPopOver from "./DropDownPopOver";
import MainPagination from "./MainPagination";
import loadingGif from "../../assets/lottie/loading.gif";
import ReportTable from "./ReportTable";
import moment from "moment";

export default function ReportPage() {
    const { state } = useContext(Context);

    let userRole = state.user_details.role;
    const [isLoading, setLoading] = useState(false);

    // For handling date-picker package
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const fromDatePickerRef = useRef();
    const handleFromCalendarIconClick = () => {
        fromDatePickerRef.current.setOpen(true);
    };
    const toDatePickerRef = useRef();
    const handleToCalendarIconClick = () => {
        toDatePickerRef.current.setOpen(true);
    };
    const todayClass = {
        backgroundColor: "#917afd !important",
        color: "black",
        fontWeight: "bold",
    };

    const alwaysSelectedFields = ["lead_id", "name"];
    const defaultFields = [
        "lead_id",
        "name",
        "email",
        "lead_source",
        "lead_stage",
        "status",
        "sales_person",
        "enquiry_date",
        "lead_age",
        "eligibility",
    ];
    const [selectedFields, setSelectedFields] = useState(defaultFields);
    const [isAllFieldsSelected, setIsAllFieldsSelected] = useState(false);
    const [isDefaultFieldsSelected, setIsDefaultFieldsSelected] =
        useState(false);
    const [selectedCriteria, setSelectedCriteria] = useState("");
    const [search, setSearch] = useState("");
    const [generatedLeads, setGeneratedLeads] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsCount, setItemsCount] = useState(20);
    const [paginationData, setPaginationData] = useState({});
    const [downloadLink, setDownloadLink] = useState("");
    //All items arrays
    const [universities, setUniversities] = useState([]);
    const [courses, setCourses] = useState([]);
    const [specializations, setSpecializations] = useState([]);
    const [consultants, setConsultants] = useState([]);
    const [teams, setTeams] = useState([]);
    const [countries, setCountries] = useState([]);
    const [leadGenerators, setLeadGenerators] = useState([]);
    const [leadStages, setLeadStages] = useState([]);
    const [leadSources, setLeadSources] = useState([]);

    //Selected multiple items arrays
    const [selectedUniversities, setSelectedUniversities] = useState([]);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [selectedSpecializations, setSelectedSpecializations] = useState([]);
    const [selectedConsultants, setSelectedConsultants] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedNationalities, setSelectedNationalities] = useState([]);
    const [selectedLeadGenerators, setSelectedLeadGenerators] = useState([]);
    const [selectedLeadStages, setSelectedLeadStages] = useState([]);
    const [selectedLeadSources, setSelectedLeadSources] = useState([]);

    // Temporary - Selected Single item states
    const [selectedUniversity, setSelectedUniversity] = useState({});
    const [selectedCourse, setSelectedCourse] = useState({});
    const [selectedSpecialization, setSelectedSpecialization] = useState({});
    const [selectedTeam, setSelectedTeam] = useState({});
    const [selectedCountry, setSelectedCountry] = useState({});
    const [selectedNationality, setSelectedNationality] = useState({});
    const [selectedLeadGenerator, setSelectedLeadGenerator] = useState({});

    // popover
    const [anchorEl, setAnchorEl] = useState(null);
    const popoverStyle = {
        position: "absolute",
        top: 55,
        // left: -128,
    };
    const handleClick = (event, fieldValue) => {
        setAnchorEl(event.currentTarget);
        setSelectedCriteria(fieldValue);
        const maxWidth = Array.from(parentRef.current.children).reduce(
            (acc, child) => {
                const childWidth = child.getBoundingClientRect().width;
                return childWidth > acc ? childWidth : acc;
            },
            0
        );
        setPopoverWidth(maxWidth);

        // // Get the distance of the click from the top and bottom edges of the viewport
        // const distanceFromTop = event.clientY;
        // const distanceFromBottom = window.innerHeight - event.clientY;
        // // Calculate the height of the Paper component based on the click position
        // const height =
        //     distanceFromTop > distanceFromBottom
        //         ? distanceFromBottom * 0.4
        //         : distanceFromTop * 0.4;

        // Get the distance of the click from the top of the viewport
        const distanceFromTop = event.clientY;
        // Calculate the height of the Paper component based on the click position
        const height = `calc(90vh - ${distanceFromTop}px)`;
        setPopoverHeight(height);
    };
    const parentRef = useRef();
    const [popoverWidth, setPopoverWidth] = useState(0);
    const [popoverHeight, setPopoverHeight] = useState(0);

    const selectFields = [
        // {
        //     id: 0,
        //     name: "Team Manager",
        //     value: "team-manager",
        // },
        {
            id: 1,
            name: "Team",
            value: "team",
            type: "single",
        },
        {
            id: 2,
            name: "Consultant",
            value: "consultant",
            type: "multiple",
        },
        {
            id: 3,
            name: "Lead Source",
            value: "lead-source",
            type: "multiple",
        },
        // {
        //     id: 4,
        //     name: "Lead Campaign",
        //     value: "lead-campaign",
        // },
        {
            id: 4,
            name: "Lead Generator",
            value: "lead-generator",
            type: "single",
        },
        {
            id: 5,
            name: "Lead Stage",
            value: "lead-stage",
            type: "multiple",
        },
        {
            id: 6,
            name: "Country",
            value: "country",
            type: "single",
        },
        {
            id: 7,
            name: "Nationality",
            value: "nationality",
            type: "single",
        },
        {
            id: 8,
            name: "University",
            value: "university",
            type: "single",
        },
        {
            id: 9,
            name: "Course",
            value: "course",
            type: "single",
        },
        {
            id: 10,
            name: "Specialization",
            value: "specialization",
            type: "single",
        },
    ];

    const checkItems = [
        {
            id: 1,
            name: "Lead Id",
            value: "lead_id",
        },
        {
            id: 2,
            name: "Name",
            value: "name",
        },
        {
            id: 3,
            name: "Phone",
            value: "phone",
        },
        {
            id: 4,
            name: "Age",
            value: "age",
        },
        {
            id: 5,
            name: "Email",
            value: "email",
        },
        {
            id: 6,
            name: "Country",
            value: "country",
        },
        {
            id: 7,
            name: "Nationality",
            value: "nationality",
        },
        {
            id: 8,
            name: "Description",
            value: "description",
        },
        {
            id: 9,
            name: "University",
            value: "university",
        },
        {
            id: 10,
            name: "Course",
            value: "course",
        },

        {
            id: 11,
            name: "Specialization",
            value: "specialization",
        },

        {
            id: 12,
            name: "Lead Source",
            value: "lead_source",
        },
        {
            id: 13,
            name: "Lead Campaign",
            value: "lead_campaign",
        },
        {
            id: 14,
            name: "Lead Stage",
            value: "lead_stage",
        },
        {
            id: 15,
            name: "Status",
            value: "status",
        },
        {
            id: 16,
            name: "Remark",
            value: "remark",
        },
        {
            id: 17,
            name: "Lead Generator",
            value: "lead_generator",
        },
        {
            id: 18,
            name: "Sales Team",
            value: "sales_team",
        },
        {
            id: 19,
            name: "Consultant",
            value: "sales_person",
        },
        {
            id: 20,
            name: "Fee Amount",
            value: "fee_amount",
        },
        {
            id: 21,
            name: "Employer",
            value: "employer",
        },
        {
            id: 22,
            name: "Designation",
            value: "designation",
        },
        {
            id: 23,
            name: "Enquiry date",
            value: "enquiry_date",
        },
        {
            id: 24,
            name: "admission date",
            value: "admission_date",
        },
        {
            id: 25,
            name: "CV",
            value: "cv",
        },
        {
            id: 26,
            name: "Duplicate Lead",
            value: "duplicate_lead",
        },
        {
            id: 27,
            name: "Eligibility",
            value: "eligibility",
        },
        {
            id: 28,
            name: "Affordability",
            value: "affordability",
        },
        {
            id: 29,
            name: "Need",
            value: "need",
        },
        {
            id: 30,
            name: "Intend",
            value: "intend",
        },
        {
            id: 31,
            name: "Lead Age",
            value: "lead_age",
        },
        {
            id: 32,
            name: "Quality Lead",
            value: "quality_lead",
        },
        // {
        //     id: 33,
        //     name: "Consultants",
        //     value: "Consultants",
        // },
        // {
        //     id: 34,
        //     name: "Sale Team",
        //     value: "Sale Team",
        // },
        // {
        //     id: 35,
        //     name: "Generator",
        //     value: "Generator",
        // },
    ];

    // const handleFieldSelection = (field) => {
    //     if (selectedFields.includes(field)) {
    //         setSelectedFields(selectedFields.filter((f) => f !== field));
    //     } else {
    //         setSelectedFields([...selectedFields, field]);
    //     }
    // };
    const handleFieldSelection = (field) => {
        if (alwaysSelectedFields.includes(field)) {
            // Field is always selected, do not remove it
            if (!selectedFields.includes(field)) {
                setSelectedFields([...selectedFields, field]);
            }
        } else {
            // Field is not always selected, toggle its selection
            if (selectedFields.includes(field)) {
                setSelectedFields(selectedFields.filter((f) => f !== field));
            } else {
                setSelectedFields([...selectedFields, field]);
            }
        }
    };

    const checkDefaultFieldsSelected = () => {
        const defaultFieldsSelected =
            selectedFields.length === defaultFields.length &&
            selectedFields.every((selectedItem) =>
                defaultFields.includes(selectedItem)
            );

        if (defaultFieldsSelected) {
            setIsDefaultFieldsSelected(true);
        } else {
            setIsDefaultFieldsSelected(false);
        }
    };
    const handleDefaultFieldsSelection = () => {
        if (isDefaultFieldsSelected) {
            removeDefaultFieldsFromSelectedFields();
        } else {
            setSelectedFields(
                checkItems
                    .filter((item) => defaultFields.includes(item.value))
                    .map((item) => item.value)
            );
        }
    };

    // function removeDefaultFieldsFromSelectedFields() {
    //     const filteredFields = selectedFields.filter(
    //         (field) => !defaultFields.includes(field)
    //     );
    //     setSelectedFields(filteredFields);
    // }
    function removeDefaultFieldsFromSelectedFields() {
        const filteredFields = selectedFields.filter(
            (field) =>
                !defaultFields.includes(field) ||
                alwaysSelectedFields.includes(field)
        );
        setSelectedFields(filteredFields);
    }
    // const checkAllFieldsSelected = () => {

    //     const allFieldsSelected = checkItems.every((checkItem) =>
    //         selectedFields.includes(checkItem.value)
    //     );

    //     if (allFieldsSelected && checkItems.length > 0) {
    //         setIsAllFieldsSelected(true);
    //     } else {
    //         setIsAllFieldsSelected(false);
    //     }
    // };

    const checkAllFieldsSelected = () => {
        let itemsToCheck = checkItems;
        if (userRole !== "lead_manager") {
            itemsToCheck = itemsToCheck.filter(
                (item) => item.value !== "sales_team"
            );
        }

        const allFieldsSelected = itemsToCheck.every((checkItem) =>
            selectedFields.includes(checkItem.value)
        );

        if (allFieldsSelected && itemsToCheck.length > 0) {
            setIsAllFieldsSelected(true);
        } else {
            setIsAllFieldsSelected(false);
        }
    };

    // Api's
    const getUniversities = () => {
        let accessToken = state.user_details.access_token;

        baseConfig
            .get("/campus/university-list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: search,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setUniversities(data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const getCourses = () => {
        let accessToken = state.user_details.access_token;

        baseConfig
            .get("/campus/course-list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: search,
                    university: selectedUniversity.id,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setCourses(data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const getSpecializations = () => {
        let accessToken = state.user_details.access_token;

        baseConfig
            .get("/campus/select-specializations/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: search,
                    course: selectedCourse.id,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setSpecializations(data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const getConsultants = () => {
        let accessToken = state.user_details.access_token;

        baseConfig
            .get("/sales-persons/list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: search,
                    team: userRole === "lead_manager" ? selectedTeam.id : null,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setConsultants(data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const getTeams = () => {
        let accessToken = state.user_details.access_token;

        baseConfig
            .get("sales-persons/sales-team-list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: search,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setTeams(data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const getCountries = () => {
        let accessToken = state.user_details.access_token;

        baseConfig
            .get("/countries/list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: search,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setCountries(data);
                } else {
                }
            })
            .catch((error) => {});
    };
    

    const getLeadGenerators = () => {
        let accessToken = state.user_details.access_token;

        baseConfig
            .get("/leads/lead-generator-data/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: search,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLeadGenerators(data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const getLeadStages = () => {
        let accessToken = state.user_details.access_token;

        baseConfig
            .get("/leads/lead-stages/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: search,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLeadStages(data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const getLeadSources = () => {
        let accessToken = state.user_details.access_token;

        baseConfig
            .get("/leads/lead-source-data/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: search,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLeadSources(data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const generateLeads = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/leads/lead-report-data/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    items: itemsCount,
                    start_date: fromDate
                        ? moment(fromDate).format("YYYY-MM-DD")
                        : "",
                    end_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
                    page: currentPage,
                    country: selectedCountry.id,
                    nationality: selectedNationality.id,
                    university: selectedUniversity.id,
                    course: selectedCourse.id,
                    specialization: selectedSpecialization.id,
                    team: selectedTeam.id,
                    stages: JSON.stringify(
                        selectedLeadStages.map((stage) => stage.id)
                    ),
                    sources: JSON.stringify(
                        selectedLeadSources.map((source) => source.id)
                    ),
                    consultants: JSON.stringify(
                        selectedConsultants.map((consultant) => consultant.id)
                    ),
                    // fields:JSON.stringify(
                    //     selectedFields.map((field) => consultant.id)
                    // ),
                    fields: JSON.stringify(selectedFields),
                },
            })

            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setGeneratedLeads(data.data);
                    setDownloadLink(data.download_link);
                    setPaginationData(data.paginator);

                    setTimeout(() => {
                        setLoading(false);
                    }, 50);
                } else {
                    alert("Some error occured,please try again later");
                    setLoading(false);
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    useEffect(() => {
        checkAllFieldsSelected();
    }, [selectedFields, checkItems]);
    useEffect(() => {
        checkDefaultFieldsSelected();
    }, [selectedFields, checkItems]);

    useEffect(() => {
        getUniversities();
        getCountries();
        getLeadGenerators();
        getLeadStages();
        getLeadSources();
    }, []);

    useEffect(() => {
        if (Object.keys(selectedUniversity).length > 0) {
            getCourses();
        }
        if (Object.keys(selectedCourse).length > 0) {
            getSpecializations();
        }
    }, [selectedUniversity, selectedCourse]);

    useEffect(() => {
        if (userRole === "lead_manager") {
            getTeams();
        }
    }, [userRole]);

    // check and emptying consultants array if no team leader is selected
    useEffect(() => {
        if (userRole === "lead_manager") {
            if (Object.keys(selectedTeam).length == 0) {
                if (consultants.length > 0) {
                    setConsultants([]);
                }
            }
        }
    }, [userRole, selectedConsultants, selectedTeam]);

    // check and emptying selectedConsultants when selected team leader changes
    useEffect(() => {
        if (userRole === "lead_manager") {
            if (selectedConsultants.length > 0) {
                setSelectedConsultants([]);
            }
        }
    }, [userRole, selectedTeam]);

    useEffect(() => {
        if (userRole === "lead_manager") {
            if (Object.keys(selectedTeam).length > 0) {
                getConsultants();
            }
        } else {
            getConsultants();
        }
        // if (Object.keys(selectedCourse).length > 0) {
        //     getSpecializations();
        // }
    }, [userRole, selectedTeam]);

    useEffect(() => {
        generateLeads();
    }, [currentPage, itemsCount]);

    useEffect(() => {
        // if (search === "" || search !== "") {
        if (selectedCriteria.value === "university") {
            getUniversities();
        } else if (selectedCriteria.value === "course") {
            getCourses();
        } else if (selectedCriteria.value === "specialization") {
            getSpecializations();
        } else if (selectedCriteria.value === "consultant") {
            if (userRole === "lead_manager") {
                if (Object.keys(selectedTeam).length > 0) {
                    getConsultants();
                }
            } else {
                getConsultants();
            }
        } else if (selectedCriteria.value === "team") {
            getTeams();
        } else if (selectedCriteria.value === "lead-generator") {
            getLeadGenerators();
        } else if (selectedCriteria.value === "lead-stage") {
            getLeadStages();
        } else if (selectedCriteria.value === "lead-source") {
            getLeadSources();
        } else if (selectedCriteria.value === "country") {
            getCountries();
        } else if (selectedCriteria.value === "nationality") {
            getCountries();
        }
        // else if (selectedCriteria.value === "lead-stage") {
        //     getLeadStages();
        // }
        // }
    }, [search, selectedCriteria]);

    return (
        <MainContainer>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                    // background: "#FFFFFF",
                    // boxShadow: "0px 5px 14px rgba(243, 243, 243, 0.15)",
                }}
            >
                <Title fontSize="30px" fontWeight="700">
                    Sales Report
                </Title>
            </div>
            <SkyBlueContainer>
                <WhiteBox>
                    <SectionTitleContainer>
                        <SectionTitle>Select Search Criteria</SectionTitle>
                        <DateInputsWrapper>
                            {/* <DateInput
                                type="date"
                                value={fromDate}
                                placeholder="From"
                                className="custom-date-input"
                            /> */}
                            <div className="datepicker-wrapper">
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="From"
                                    selected={
                                        fromDate ? new Date(fromDate) : null
                                    }
                                    onChange={(date) => setFromDate(date)}
                                    className="custom-datepicker"
                                    calendarClassName="datepicker-calendar"
                                    ref={fromDatePickerRef}
                                    todayClassName="custom-today"
                                    todayButton="Today"
                                    highlightDates={[new Date()]}
                                    dayClassName={(date) =>
                                        date.getTime() === new Date().getTime()
                                            ? "today"
                                            : ""
                                    }
                                    showTimeSelect={false}
                                    timeFormat={undefined}
                                    // showYearDropdown={true}
                                />
                                <span className="datepicker-icon">
                                    <CalendarIcon
                                        style={{ width: "18px" }}
                                        onClick={handleFromCalendarIconClick}
                                    />
                                </span>
                            </div>
                            <div
                                className="datepicker-wrapper"
                                style={{ marginLeft: "12px" }}
                            >
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="To"
                                    selected={toDate}
                                    onChange={(date) => setToDate(date)}
                                    className="custom-datepicker"
                                    calendarClassName="datepicker-calendar"
                                    ref={toDatePickerRef}
                                    todayClassName="custom-today"
                                    todayButton="Today"
                                    highlightDates={[new Date()]}
                                    dayClassName={(date) =>
                                        date.getTime() === new Date().getTime()
                                            ? "today"
                                            : ""
                                    }
                                    todayButtonProps={{
                                        className: "custom-today-button",
                                        style: todayClass,
                                    }}
                                    showTimeSelect={false}
                                    // showYearDropdown={true}
                                    timeFormat={undefined}
                                />
                                <span className="datepicker-icon">
                                    <CalendarIcon
                                        style={{ width: "18px" }}
                                        onClick={handleToCalendarIconClick}
                                    />
                                </span>
                            </div>
                            {/* <DatePicker
                                label="Select Date"
                                format="MM/dd/yyyy"
                                value={fromDate}
                                // onChange={handleDateChange}
                            /> */}
                            {/* <DateInput
                                type="date"
                                value={toDate}
                                placeholder="From"
                                className="custom-date-input"
                            /> */}
                        </DateInputsWrapper>
                    </SectionTitleContainer>
                    <InnerSkyBlueContainer style={{ margin: "12px 0 20px 0" }}>
                        <SelectBoxesContainer ref={parentRef}>
                            {selectFields.map((selectField) => {
                                if (
                                    userRole !== "lead_manager" &&
                                    selectField.value === "team"
                                ) {
                                    return null; // exclude item from rendering
                                }
                                return (
                                    <SelectBoxContainer key={selectField.id}>
                                        <SelectTitle>
                                            {selectField.name}
                                        </SelectTitle>
                                        <Select
                                            handleClick={handleClick}
                                            field={selectField}
                                            selectedCriteria={selectedCriteria}
                                            selectedTeam={selectedTeam}
                                            selectedConsultants={
                                                selectedConsultants
                                            }
                                            selectedLeadSources={
                                                selectedLeadSources
                                            }
                                            selectedLeadGenerator={
                                                selectedLeadGenerator
                                            }
                                            selectedLeadStages={
                                                selectedLeadStages
                                            }
                                            selectedCountry={selectedCountry}
                                            selectedNationality={
                                                selectedNationality
                                            }
                                            selectedUniversity={
                                                selectedUniversity
                                            }
                                            selectedCourse={selectedCourse}
                                            selectedSpecialization={
                                                selectedSpecialization
                                            }
                                        />
                                    </SelectBoxContainer>
                                );
                            })}
                        </SelectBoxesContainer>
                        <Popover
                            style={popoverStyle}
                            PaperProps={{
                                style: {
                                    backgroundColor: "transparent",
                                    boxShadow: "none",
                                    borderRadius: "8px",
                                    background: "#fff",
                                    border: "1px solid #e7e7e7",
                                    borderRadius: "12px",
                                    boxShadow:
                                        "8px 16px 44px rgba(0, 0, 0, 0.25)",
                                },
                            }}
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={() => {
                                setAnchorEl(null);
                                if (search) {
                                    setSearch("");
                                }
                            }}
                        >
                            <PopOverContainer
                                style={{
                                    width: popoverWidth,
                                    maxHeight: popoverHeight,
                                    minHeight: "100px",
                                }}
                            >
                                {selectedCriteria.value === "university" ? (
                                    <DropDownPopOver
                                        setAnchorEl={setAnchorEl}
                                        selectedCriteria={selectedCriteria}
                                        search={search}
                                        setSearch={setSearch}
                                        sectionName="Universities"
                                        allItems={universities}
                                        selectedItem={selectedUniversity}
                                        setSelectedItem={setSelectedUniversity}
                                        // selectedItems={selectedUniversities}
                                        // setSelectedItems={
                                        //     setSelectedUniversities
                                        // }
                                    />
                                ) : selectedCriteria.value === "course" ? (
                                    <DropDownPopOver
                                        setAnchorEl={setAnchorEl}
                                        selectedCriteria={selectedCriteria}
                                        search={search}
                                        setSearch={setSearch}
                                        sectionName="Courses"
                                        allItems={courses}
                                        selectedItem={selectedCourse}
                                        setSelectedItem={setSelectedCourse}
                                        // selectedItems={selectedCourses}
                                        // setSelectedItems={setSelectedCourses}
                                    />
                                ) : selectedCriteria.value ===
                                  "specialization" ? (
                                    <DropDownPopOver
                                        setAnchorEl={setAnchorEl}
                                        selectedCriteria={selectedCriteria}
                                        search={search}
                                        setSearch={setSearch}
                                        sectionName="Specializations"
                                        allItems={specializations}
                                        selectedItem={selectedSpecialization}
                                        setSelectedItem={
                                            setSelectedSpecialization
                                        }
                                        // selectedItems={selectedSpecializations}
                                        // setSelectedItems={
                                        //     setSelectedSpecializations
                                        // }
                                    />
                                ) : selectedCriteria.value === "consultant" ? (
                                    <DropDownPopOver
                                        type="multiple"
                                        setAnchorEl={setAnchorEl}
                                        selectedCriteria={selectedCriteria}
                                        search={search}
                                        setSearch={setSearch}
                                        sectionName="Consultants"
                                        allItems={consultants}
                                        selectedItems={selectedConsultants}
                                        setSelectedItems={
                                            setSelectedConsultants
                                        }
                                    />
                                ) : selectedCriteria.value === "team" ? (
                                    <DropDownPopOver
                                        setAnchorEl={setAnchorEl}
                                        selectedCriteria={selectedCriteria}
                                        search={search}
                                        setSearch={setSearch}
                                        sectionName="Teams"
                                        allItems={teams}
                                        selectedItem={selectedTeam}
                                        setSelectedItem={setSelectedTeam}
                                        // selectedItems={selectedTeams}
                                        // setSelectedItems={setSelectedTeams}
                                    />
                                ) : selectedCriteria.value ===
                                  "lead-generator" ? (
                                    <DropDownPopOver
                                        setAnchorEl={setAnchorEl}
                                        selectedCriteria={selectedCriteria}
                                        search={search}
                                        setSearch={setSearch}
                                        sectionName="Lead Generators"
                                        allItems={leadGenerators}
                                        selectedItem={selectedLeadGenerator}
                                        setSelectedItem={
                                            setSelectedLeadGenerator
                                        }
                                        // selectedItems={selectedLeadGenerators}
                                        // setSelectedItems={
                                        //     setSelectedLeadGenerators
                                        // }
                                    />
                                ) : selectedCriteria.value === "country" ? (
                                    <DropDownPopOver
                                        setAnchorEl={setAnchorEl}
                                        selectedCriteria={selectedCriteria}
                                        search={search}
                                        setSearch={setSearch}
                                        sectionName="Countries"
                                        allItems={countries}
                                        selectedItem={selectedCountry}
                                        setSelectedItem={setSelectedCountry}
                                        // selectedItems={selectedLeadGenerators}
                                        // setSelectedItems={
                                        //     setSelectedLeadGenerators
                                        // }
                                    />
                                ) : selectedCriteria.value === "nationality" ? (
                                    <DropDownPopOver
                                        setAnchorEl={setAnchorEl}
                                        selectedCriteria={selectedCriteria}
                                        search={search}
                                        setSearch={setSearch}
                                        sectionName="Nationalities"
                                        allItems={countries}
                                        selectedItem={selectedNationality}
                                        setSelectedItem={setSelectedNationality}
                                        // selectedItems={selectedLeadGenerators}
                                        // setSelectedItems={
                                        //     setSelectedLeadGenerators
                                        // }
                                    />
                                ) : selectedCriteria.value === "lead-stage" ? (
                                    <DropDownPopOver
                                        type="multiple"
                                        setAnchorEl={setAnchorEl}
                                        selectedCriteria={selectedCriteria}
                                        search={search}
                                        setSearch={setSearch}
                                        sectionName="Lead Stages"
                                        allItems={leadStages}
                                        selectedItems={selectedLeadStages}
                                        setSelectedItems={setSelectedLeadStages}
                                    />
                                ) : selectedCriteria.value === "lead-source" ? (
                                    <DropDownPopOver
                                        type="multiple"
                                        setAnchorEl={setAnchorEl}
                                        selectedCriteria={selectedCriteria}
                                        search={search}
                                        setSearch={setSearch}
                                        sectionName="Lead Sources"
                                        allItems={leadSources}
                                        selectedItems={selectedLeadSources}
                                        setSelectedItems={
                                            setSelectedLeadSources
                                        }
                                    />
                                ) : (
                                    <h5>hii</h5>
                                )}
                            </PopOverContainer>
                        </Popover>
                    </InnerSkyBlueContainer>
                    <SectionTitleContainer>
                        <SectionTitle>Select Display Field</SectionTitle>
                    </SectionTitleContainer>
                    <InnerSkyBlueContainer style={{ margin: "20px 0 0 0" }}>
                        <CheckAllSection>
                            <CheckAllItem>
                                {/* <CheckBox>&#10004;</CheckBox> */}
                                <CheckBox
                                    // onClick={() => {
                                    //     if (isAllFieldsSelected) {
                                    //         setSelectedFields([]);
                                    //     } else {
                                    //         setSelectedFields(
                                    //             checkItems.map(
                                    //                 (checkItem) =>
                                    //                     checkItem.value
                                    //             )
                                    //         );
                                    //     }
                                    // }}
                                    onClick={() => {
                                        if (isAllFieldsSelected) {
                                            const selected =
                                                selectedFields.filter((field) =>
                                                    alwaysSelectedFields.includes(
                                                        field
                                                    )
                                                );
                                            setSelectedFields(selected);
                                        } else {
                                            // setSelectedFields(
                                            //     checkItems.map(
                                            //         (checkItem) =>
                                            //             checkItem.value
                                            //     )
                                            // );
                                            if (userRole !== "lead_manager") {
                                                setSelectedFields(
                                                    checkItems
                                                        .filter(
                                                            (item) =>
                                                                item.value !==
                                                                "sales_team"
                                                        )
                                                        .map(
                                                            (checkItem) =>
                                                                checkItem.value
                                                        )
                                                );
                                            } else {
                                                setSelectedFields(
                                                    checkItems.map(
                                                        (checkItem) =>
                                                            checkItem.value
                                                    )
                                                );
                                            }
                                        }
                                    }}
                                >
                                    {isAllFieldsSelected && "\u2713"}
                                </CheckBox>
                                <CheckItemText>Check all</CheckItemText>
                            </CheckAllItem>
                            <CheckAllItem>
                                <CheckBox
                                    onClick={() => {
                                        handleDefaultFieldsSelection();
                                    }}
                                >
                                    {isDefaultFieldsSelected && "\u2713"}
                                </CheckBox>
                                <CheckItemText>Check Default</CheckItemText>
                            </CheckAllItem>
                        </CheckAllSection>
                        <CheckItemsContainer>
                            {checkItems.map((item) => {
                                if (
                                    userRole !== "lead_manager" &&
                                    item.value === "sales_team"
                                ) {
                                    return null; // exclude item from rendering
                                }
                                return (
                                    <CheckItem key={item.id}>
                                        <CheckBox
                                            onClick={() => {
                                                handleFieldSelection(
                                                    item.value
                                                );
                                            }}
                                            noAction={
                                                item.value == "lead_id" ||
                                                item.value == "name"
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {selectedFields.includes(
                                                item.value
                                            ) && "\u2713"}
                                        </CheckBox>
                                        <CheckItemText>
                                            {item.name}
                                        </CheckItemText>
                                    </CheckItem>
                                );
                            })}
                        </CheckItemsContainer>
                    </InnerSkyBlueContainer>
                </WhiteBox>
                <GenerateButton
                    onClick={() => {
                        generateLeads();
                    }}
                >
                    Generate
                </GenerateButton>
            </SkyBlueContainer>
            <SkyBlueContainer style={{ marginTop: "40px" }}>
                {!isLoading ? (
                    <>
                        {generatedLeads.length > 0 ? (
                            <>
                                <ReportTitleBox>
                                    <SectionTitle>Report</SectionTitle>
                                    <DownloadButton
                                        onClick={() => {
                                            if (downloadLink) {
                                                window.open(downloadLink);
                                            } else {
                                                alert(
                                                    "Some error occured,Please try later"
                                                );
                                            }
                                        }}
                                    >
                                        <DownloadIcon
                                            src={downloadIcon}
                                            alt="icon"
                                        />
                                        <DownloadText>Download</DownloadText>
                                    </DownloadButton>
                                </ReportTitleBox>
                                <TableContainer>
                                    <ReportPageTable
                                        data={generatedLeads}
                                        selectedFields={selectedFields}
                                    />
                                    {/* <ReportTable
                                        data={generatedLeads}
                                        selectedFields={selectedFields}
                                    /> */}
                                </TableContainer>
                                <div style={{ marginTop: "20px" }}>
                                    <MainPagination
                                        marginRight="0px"
                                        paginationData={paginationData}
                                        setCurrentPage={setCurrentPage}
                                        currentPage={currentPage}
                                        isCountSelector={true}
                                        itemsCount={itemsCount}
                                        setItemsCount={setItemsCount}
                                        currentItemsCount={
                                            generatedLeads.length
                                        }
                                    />
                                </div>
                            </>
                        ) : (
                            <LoaderContainer>
                                <NoResultsFoundText>
                                    No Results Found
                                </NoResultsFoundText>
                            </LoaderContainer>
                        )}
                    </>
                ) : (
                    <LoaderContainer>
                        <LoaderIcon src={loadingGif} alt="icon" />
                    </LoaderContainer>
                )}
            </SkyBlueContainer>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    padding: 20px;
    background: ${(props) => (props.background ? props.background : "#f5f7fa")};
    /* background: #fff; */
    height: 100%;
    overflow: auto;
    margin-top: 80px;
    @media (max-width: 1080px) {
        padding: 20px;
    }
`;
const LoaderIcon = styled.img`
    width: 45px;
    display: block;
    object-fit: cover;
`;
const PageTitle = styled.div`
    color: #000000;
    font-size: 24px;
    font-weight: 600;
    /* line-height: 39px; */
    color: #000000;
`;
const SkyBlueContainer = styled.div`
    background: #ecf8ff;
    border-radius: 16px;
    padding: 24px;
`;
const WhiteBox = styled.div`
    padding: 20px;
    background: #ffffff;
    border-radius: 16px;
`;
const SectionTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const SectionTitle = styled.span`
    /* font-weight: 400; */
    display: block;
    font-size: 18px;
    text-transform: capitalize;
    color: #000000;
`;
const DateInputsWrapper = styled.div`
    display: flex;
    align-items: center;
    .custom-date-input::-webkit-calendar-picker-indicator {
        background-image: url(${calendarIcon});
        background-size: contain;
        background-repeat: no-repeat;
        width: 1.5em;
        height: 1.5em;
        margin-left: 0.5em;
        cursor: pointer;
    }
    .datepicker-wrapper {
        position: relative;
        display: inline-block;
    }
    .custom-datepicker {
        /* width: 200px;
        height: 40px;
        border-radius: 8px;
        border: 1px solid #ccc;
        padding: 8px;
        font-size: 16px;
        color: #333; */
        background: #ffffff;
        /* Dark/100 */
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        border: 1px solid #e7e7e7;
        border-radius: 8px;
        padding: 4px 8px;
        width: 201px;
        height: 40px;
        box-sizing: border-box;
    }
    .datepicker-icon {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .datepicker-calendar {
        margin-top: 10px;
    }

    .react-datepicker__day--highlighted,
    .react-datepicker__month-text--highlighted,
    .react-datepicker__quarter-text--highlighted,
    .react-datepicker__year-text--highlighted {
        background-color: #917afd !important;
    }

    /* input[type="date"] {
        text-align: right;
    }

    input[type="date"]:before {
        color: lightgrey;
        content: attr(placeholder) !important;
        margin-right: 0.5em;
    }
    input[type="date"]:before {
        color: lightgrey;
        content: attr(placeholder) !important;
        margin-right: 0.5em;
    }
    input[type="date"]:focus:before {
        content: "" !important;
    } */
`;
const DateInput = styled.input`
    background: #ffffff;
    /* Dark/100 */
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    padding: 4px 6px;
    width: 201px;
    height: 40px;
    box-sizing: border-box;
    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
`;
const InnerSkyBlueContainer = styled.div`
    background: #ecf8ff;
    border-radius: 16px;
    padding: 16px;
    /* margin: 12px 0; */
`;
const SelectBoxesContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
`;
const SelectBoxContainer = styled.div``;
const SelectTitle = styled.span`
    display: block;
    font-weight: 400;
    font-size: 15px;
    text-transform: capitalize;
    color: #010101;
    margin-bottom: 6px;
`;
const CheckAllSection = styled.div`
    background: #ffffff;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 20px;
`;
const CheckAllItem = styled.div`
    display: flex;
    align-items: center;
    &:last-child {
        margin-right: 0;
    }
`;
const CheckItem = styled.div`
    display: flex;
    align-items: center;
    width: 11%;
    &:last-child {
        margin-right: 0;
    }
    @media only screen and (max-width: 1800px) {
        width: 12.5%;
    }
    @media only screen and (max-width: 1380px) {
        width: 15%;
    }
    @media only screen and (max-width: 1280px) {
        width: 17.5%;
    }
    @media only screen and (max-width: 1080px) {
        width: fit-content;
    }
`;
const CheckBox = styled.div`
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #917afd;
    font-size: 16px;
    cursor: pointer;
    cursor: ${(props) => (props.noAction ? "not-allowed" : "pointer")};
    user-select: none;
`;
const CheckItemText = styled.span`
    display: block;
    color: #010101;
    font-size: 15px;
    text-transform: capitalize;
    margin-left: 8px;
`;
const CheckItemsContainer = styled.div`
    margin-left: 4px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    gap: 20px 10px;

    /* display: grid; */
`;
const GenerateButton = styled.span`
    display: block;
    background: #917afd;
    border-radius: 8px;
    padding: 12px 24px;
    width: fit-content;
    height: 46px;
    box-sizing: border-box;
    letter-spacing: 0.04em;
    color: #ffffff;
    font-size: 16px;
    margin-top: 24px;
    cursor: pointer;
`;
const ReportTitleBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border-radius: 8px;
    padding: 12px 16px;
`;
const DownloadButton = styled.div`
    background: #fff;
    display: flex;
    align-items: center;
    height: 38px;
    padding: 8px 12px;
    width: fit-content;
    box-sizing: border-box;
    border: 1px solid #917afd;
    border-radius: 8px;
    cursor: pointer;
`;
const DownloadIcon = styled.img`
    display: block;
    width: 16px;
    margin-right: 10px;
`;
const DownloadText = styled.span`
    display: block;
    color: #917afd;
    font-size: 15px;
`;
const TableContainer = styled.div`
    margin-top: 20px;
    overflow-x: auto;

    /* .table {
        min-width: 100%;
        width: auto;
        table-layout: fixed;
        padding: 0;
        margin: 0;
        border-spacing: 0;
    } */
`;

const PopOverContainer = styled.div`
    /* max-height: 60vh; */
    box-sizing: border-box;
    border-radius: 8px;
    /* overflow-y: scroll; */
    background-color: transparent;
    /* margin-bottom: 20px; */
    /* margin-bottom: 40px; */
`;
const LoaderContainer = styled.div`
    width: 100%;
    /* background: #000; */
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const NoResultsFoundText = styled.span`
    display: inline-block;
    font-size: 24px;
    /* color: #aaaaaa; */
    color: #696969;
`;
// const CalendarIcon = styled.img``;

// const SelectBoxContainer = styled.div``;
// const SelectBoxContainer = styled.div``;
// const SelectBoxContainer = styled.div``;
// const SelectBoxContainer = styled.div``;
// const SelectBoxContainer = styled.div``;
