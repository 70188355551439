import ApiService from "../utils/fetch";

export const ListLeadsService = async (data, query) => {
	let url = query ? `/leads/lead?${query}` : `/leads/lead/`;
	let response = await ApiService.get(url, data);
	return response;
};

export const GetLeadsService = async (id) => {
	let response = await ApiService.get(`/leads/lead/${id}/`);
	return response;
};

export const AddLeadsService = async (data) => {
	let state = data.state.user_details.access_token;
	var formdata = new FormData();
	formdata.append("name", data.leadName);
	formdata.append("phone", data.phone);
	formdata.append("email", data.email);
	formdata.append("description", data.description);
	formdata.append("address_line", data.address);
	formdata.append("lead_source", data.selectedSource);
	formdata.append("gender", data.gender);
	formdata.append("qualification", data.education);
	formdata.append("country", data.country);
	if (data.selectedUniversity) {
		formdata.append("university", data.selectedUniversity);
	}
	if (data.selectedCourse) {
		formdata.append("course", data.selectedCourse);
	}

	let response = await ApiService.post(`/leads/lead/`, {
		body: formdata,
		state: state,
	});
	return response;
};

export const UpdateLeadsService = async (data) => {
	var formdata = new FormData();
	formdata.append("name", data.name);
	formdata.append("phone", data.phone);
	formdata.append("email", data.email);
	formdata.append("description", data.description);
	formdata.append("address_line", data.address_line);
	formdata.append("course", data.course);
	formdata.append("university", data.university);
	formdata.append("lead_source", data.lead_source);
	formdata.append("gender", data.gender);
	formdata.append("qualification", data.qualification);
	formdata.append("country", data.country);
	let response = await ApiService.put(`/leads/lead/${data.id}/`, {
		body: formdata,
	});
	return response;
};

export const DeleteLeadsService = async (id) => {
	let response = await ApiService.delete(`/leads/lead/${id}/`);
	return response;
};
