import { useEffect, useState, useContext } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import UploadInput from "../../components/UploadInput/UploadInput";
import { GetCampusService, UpdateCampusService } from "../../service/campus";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { baseConfig } from "./../../utils/config";
import { Context } from "../../contexts/Store";

function UpdateCampus() {
	const history = useHistory();
	const { params } = useParams();
	const location = useLocation();
	const [form, setFormValues] = useState(location?.state);
	const [file, setFile] = useState(null);
	const [isError, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [secondaryPhone, setSecondaryPhone] = useState("");
	const [address1, setAddress1] = useState("");
	const [address2, setAddress2] = useState("");
	const [city, setCity] = useState("");
	const [states, setStates] = useState("");
	const [country, setCountry] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [image, setImage] = useState(null);
	const [fieldName, setFieldName] = useState("");
	const [emailValidation, setEmailValidation] = useState(false);
	const [isLoading, setLoading] = useState(true);
	const [imageUrl, setImageUrl] = useState(null);

	const { state } = useContext(Context);

	const validate = (text) => {
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
		if (reg.test(text) === false) {
			setEmailValidation(false);
			setEmail(text);
			return false;
		} else {
			setEmail(text);
			setEmailValidation(true);
		}
	};

	const getInitialData = async () => {
		setLoading(true);
		let data = await GetCampusService(params, state);
		if (data.StatusCode === 6000) {
			setLoading(false);
			let details = data.data.data;
			setName(details.name);
			validate(details.email);
			setPhone(details.phone);
			setSecondaryPhone(details.secondary_phone);
			setAddress1(details.address_line_1);
			setAddress2(details.address_line_2);
			setCity(details.city);
			setStates(details.state);
			setCountry(details.country);
			setZipCode(details.zip_code);
			setImageUrl(details.image);
		} else {
			alert(data.data.message);
		}
	};
	useEffect(() => {
		getInitialData();
	}, []);

	const handleSubmitForm = () => {
		let accessToken = state.user_details.access_token;
		var formdata = new FormData();
		formdata.append("name", name);
		formdata.append("email", email);
		formdata.append("phone", phone);
		formdata.append("secondary_phone", secondaryPhone);
		formdata.append("address_line_1", address1);
		formdata.append("address_line_2", address2);
		formdata.append("city", city);
		formdata.append("state", states);
		formdata.append("country", country);
		formdata.append("zip_code", zipCode);
		if (file !== null) {
			formdata.append("image", file[0]);
		}

		baseConfig
			.put(`/campus/campus-details/${form.id}/`, formdata, {
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				let { StatusCode, data, message } = response.data;
				if (StatusCode == 6000) {
					history.push("/superadmin/list-campus/");
				} else {
					alert(data.data.message);
				}
			})
			.catch((error) => {
				alert(error.response.data.data);
			});
	};

	return (
		<Wrapper>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					marginBottom: "30px",
					alignItems: "center",
				}}
			>
				<div style={{ display: "flex", margin: "0 12px" }}>
					<Title fontSize="35x">Edit Campus</Title>
				</div>

				{/* <div>
					<Button
						href="#"
						style={{
							display: "flex",
							alignItems: "center",
							margin: "0 22px",
						}}
						backgroundColor="white"
						fontSize="15px"
						padding="18px 57px"
						onClick={(e) => {
							e.preventDefault();
							const form = document.getElementById("form");
							form.reset();
							setFormValues({});
							setFile(null);
							setName("");
							setEmail("");
							setPhone("");
							setSecondaryPhone("");
							setAddress1("");
							setAddress2("");
							setCity("");
							setStates("");
							setCountry("");
							setZipCode("");
							setFile(null);
						}}
					>
						<Text color="  #EE1451" fontSize="15px">
							Clear Form
						</Text>
					</Button>
				</div> */}
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				{isLoading === false ? (
					<Container style={{ flexWrap: "wrap " }} width="100%">
						<form id="form">
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<Input
									type="text"
									name="Campus ID (Auto generated)"
									value={form?.campus_id}
									disabled
								/>
								<Input
									// value={form?.name}

									value={name}
									onChange={(value) =>
										// handleChangeValue("name", value)
										{
											setName(value);
											setError(false);
										}
									}
									type="text"
									name="Campus Name"
									isError={isError}
									errorMessage={errorMessage}
									fieldName={fieldName}
								/>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									marginTop: "20px",
								}}
							>
								<Input
									// value={form?.email}
									onChange={(value) =>
										// handleChangeValue("email", value)
										{
											// validate(value);
											setEmail(value);
											setError(false);
										}
									}
									type="email"
									name="Email ID"
									fieldName={fieldName}
									isError={isError}
									errorMessage={errorMessage}
									value={email}
								/>

								<Input
									fieldName={fieldName}
									isError={isError}
									errorMessage={errorMessage}
									// value={form?.phone}
									onChange={(value) => {
										setPhone(value);
										setError(false);
									}}
									type="text"
									name="Phone Number"
									value={phone}
								/>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									marginTop: "20px",
								}}
							>
								<Input
									fieldName={fieldName}
									isError={isError}
									errorMessage={errorMessage}
									value={form?.secondary_phone}
									onChange={(value) => {
										setSecondaryPhone(value);
										setError(false);
									}}
									type="text"
									name="Phone No2"
									value={secondaryPhone}
								/>
								<Input
									fieldName={fieldName}
									isError={isError}
									errorMessage={errorMessage}
									value={form?.address_line_1}
									onChange={(value) => {
										setAddress1(value);
										setError(false);
									}}
									type="text"
									name="Address line1"
									value={address1}
								/>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									marginTop: "20px",
								}}
							>
								<Input
									fieldName={fieldName}
									isError={isError}
									errorMessage={errorMessage}
									value={form?.address_line_2}
									onChange={(value) => {
										setAddress2(value);
										setError(false);
									}}
									type="text"
									name="Address line2"
									value={address2}
								/>
								<Input
									fieldName={fieldName}
									isError={isError}
									errorMessage={errorMessage}
									value={form?.city}
									onChange={(value) => {
										setCity(value);
										setError(false);
									}}
									type="text"
									name="City"
									value={city}
								/>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									marginTop: "20px",
								}}
							>
								<Input
									fieldName={fieldName}
									isError={isError}
									errorMessage={errorMessage}
									value={form?.state}
									onChange={(value) => {
										setStates(value);
										setError(false);
									}}
									type="text"
									name="State"
									value={states}
								/>
								<Input
									fieldName={fieldName}
									isError={isError}
									errorMessage={errorMessage}
									value={form?.country}
									onChange={(value) => {
										setCountry(value);
										setError(false);
									}}
									type="text"
									name="Country"
									value={country}
								/>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									marginTop: "20px",
								}}
							>
								<Input
									fieldName={fieldName}
									isError={isError}
									errorMessage={errorMessage}
									value={form?.zip_code}
									onChange={(value) => {
										setZipCode(value);
										setError(false);
									}}
									type="text"
									name="ZIP Code"
									value={zipCode}
								/>
								<UploadInput
									file={file}
									setFile={setFile}
									image={file}
									isError={isError}
									fieldName={fieldName}
									name="Upload Image"
									isEdit={file === null ? true : false}
									imageUrl={imageUrl}
								/>
							</div>
							<div
								style={{
									display: "flex",
									marginTop: "30px",
									justifyContent: "flex-end",
								}}
							>
								<Button
									href="#"
									bacerkgroundColor="#5A2D8F"
									fontSize="15px"
									padding="21px 90px"
									fontWeight="500"
									onClick={(e) => {
										if (name === "") {
											setError(true);
											setFieldName("Campus Name");
											setErrorMessage(
												"This field is required"
											);
										} else if (email === "") {
											setError(true);
											setFieldName("Email ID");
											setErrorMessage(
												"Please enter a valid email"
											);
										} else if (emailValidation === false) {
											setError(true);
											setFieldName("Email ID");
											setErrorMessage(
												"Please enter a valid email"
											);
										} else if (phone === "") {
											setError(true);
											setFieldName("Phone Number");
											setErrorMessage(
												"Enter your phone number"
											);
										} else if (secondaryPhone === "") {
											setError(true);
											setFieldName("Phone No2");
											setErrorMessage(
												"Enter your phone number"
											);
										} else if (address1 === "") {
											setError(true);
											setFieldName("Address line1");
											setErrorMessage(
												"Enter your address"
											);
										} else if (address2 === "") {
											setError(true);
											setFieldName("Address line2");
											setErrorMessage(
												"Enter your address"
											);
										} else if (city === "") {
											setError(true);
											setFieldName("City");
											setErrorMessage("Enter your city");
										} else if (states === "") {
											setError(true);
											setFieldName("State");
											setErrorMessage("Enter your state");
										} else if (country === "") {
											setError(true);
											setFieldName("Country");
											setErrorMessage(
												"Enter your country"
											);
										} else if (zipCode === "") {
											setError(true);
											setFieldName("ZIP Code");
											setErrorMessage(
												"Enter your ZIP Code"
											);
										} else {
											e.preventDefault();
											handleSubmitForm();
										}
									}}
								>
									<Text color="white" fontSize="20px">
										Save
									</Text>
								</Button>
							</div>
						</form>
					</Container>
				) : null}
			</div>
		</Wrapper>
	);
}

export default UpdateCampus;
