import React, { useEffect, useState, useContext } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import visible from "../../assets/images/visible.svg";
import { useHistory } from "react-router-dom";
import { AddSalesPersonService } from "../../service/salesPerson";
import { validatePassword, capitalizeString } from "./../../helpers/Functions";
import { baseConfig } from "./../../utils/config";
import { Context } from "../../contexts/Store";
import ProfilePicture from "../../components/ProfilePicture";
import openedEye from "../../assets/images/eye-open.svg";
import closedEye from "../../assets/images/eye-close.svg";
import AlertBox from "../../components/includes/AlertBox";
import SweetAlert from "../../components/includes/SweetAlert";
function AddSalesPersonAdmin() {
    const history = useHistory();
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [fieldName, setFieldName] = useState("");
    const [id, setId] = useState("");
    const [profile, setProfile] = useState(null);
    const [fetchedImage, setFetchedImage] = useState(null);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [country, setCountry] = useState("");

    const [target, setTarget] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [employeeID, setEmployeeID] = useState("");

    const [salesManagers, setSalesManagers] = useState([]);

    const [salesManagerName, setSalesManagerName] = useState("");

    const [confirmPassword, setConfirmPassword] = useState("");
    const [emailValidation, setEmailValidation] = useState(false);
    const [passwordValidate, setPasswordValidate] = useState({});
    const [userNameValidation, setUserNameValidation] = useState(false);
    const { state } = useContext(Context);
    const [cropSize, setCropSize] = useState({});

    // sweetalert states and functions
    const [isSweetAlert, setIsSweetAlert] = useState(false);
    const [sweetAlertContent, setSweetAlertContent] = useState({
        title: "",
        message: "",
        icon: "",
        confirmButtonText: "",
        isCancelButton: false,
    });
    const handleSweetAlertConfirm = () => {
        history.push("/superadmin/admission-consultant/");
    };
    // end of sweetalert

    // alert mui message
    const [alertMessage, setAlertMessage] = useState(null);
    // Track the message state

    useEffect(() => {
        setPasswordValidate(validatePassword(password));
    }, [password]);
    const criteria = passwordValidate.is_strong_password;

    const validate = (text) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(text) === false) {
            setEmailValidation(false);
            setEmail(text);
            return false;
        } else {
            setEmail(text);
            setEmailValidation(true);
        }
    };

    const validateUser = (text) => {
        var usernameRegex = /^[a-zA-Z0-9]+$/;
        if (usernameRegex.test(text) === false) {
            setUserNameValidation(true);
            setUserName(text);
        } else {
            setUserNameValidation(false);
            setUserName(text);
        }
    };

    const [form, setFormValues] = useState({});

    const handleChangeValue = (key, value) => {
        setFormValues({ ...form, [key]: value });
    };

    const handleSubmit = async () => {
        if (form["password"] !== form["confirmPassword"]) {
            alert("Incorrect password");
        }
        let data = await AddSalesPersonService({
            name,
            email,
            userName,
            password,
            country,
            phone,
            state,
            profile,
            employeeID,
            target,
            salesManagerName,
            // cropSize,
        });

        if (data.StatusCode === 6000) {
            // history.push("/superadmin/admission-consultant/");
            setSweetAlertContent({
                title: "Success",
                message: "Admission Consultant added successfully",
                icon: "success",
                confirmButtonText: "Ok",
                isCancelButton: false,
            });
            setIsSweetAlert(true);
        } else {
            setAlertMessage(data.data.message);
        }
    };

    const getLeadManagerList = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/leads/lead-manager/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode === 6000) {
                    setSalesManagers(data.data);
                } else {
                    setAlertMessage(message);
                }
            })
            .catch((error) => {
                setAlertMessage(error);
            });
    };

    useEffect(() => {
        getLeadManagerList();
    }, []);

    const getAdminId = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/sales-persons/get-sales-person-id/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setId(data.lead_manager_id);
                } else {
                    setAlertMessage(data.data.message);
                }
            })
            .catch((error) => {
                setAlertMessage(error);
            });
    };

    useEffect(() => {
        getAdminId();
    }, []);

    useEffect(() => {
        if (name.length === 0) {
            setTimeout(() => {
                setUserName("");
                setPassword("");
            }, 200);
        }
        setUserName(`${name.replace(/\s+/g, "")}@LMS`);
        setPassword(`${name.replace(/\s+/g, "")}${id}@LMS`);
    }, [name]);

    return (
        <Wrapper>
            <AlertBox
                message={alertMessage}
                setMessage={setAlertMessage}
                severity={
                    alertMessage && alertMessage.includes("successful")
                        ? "success"
                        : "error"
                }
                autoClose={true}
                onClose={() => setAlertMessage(null)}
            />
            <SweetAlert
                sweetAlertContent={sweetAlertContent}
                setSweetAlertContent={setSweetAlertContent}
                isSweetAlert={isSweetAlert}
                setIsSweetAlert={setIsSweetAlert}
                onConfirm={handleSweetAlertConfirm}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "0 12px",
                    }}
                >
                    <Title fontSize="35x">Add Admission Consultant</Title>
                    {/* <Text
						style={{
							marginTop: "5px",
							fontFamily: "Inter",
							fontWeight: "500",
						}}
						color="grey"
						fontSize="18px"
					>
						Admins / Add Admission Consultant
					</Text> */}
                </div>

                <div>
                    <Button
                        style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0 22px",
                        }}
                        backgroundColor="white"
                        fontSize="15px"
                        padding="18px 57px"
                        onClick={(e) => {
                            e.preventDefault();
                            const form = document.getElementById("form");
                            form.reset();
                            setFormValues({});
                            setEmail("");
                            setName("");
                            setPassword("");
                            // setConfirmPassword("");
                            setUserName("");
                            setPhone("");
                            setCountry("");
                            setTarget("");
                            setSalesManagerName("");
                        }}
                    >
                        <Text color="  #EE1451" fontSize="15px">
                            Clear Form
                        </Text>
                    </Button>
                </div>
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Container style={{ flexWrap: "wrap " }} width="100%">
                    <form id="form">
                        <div
                            style={{
                                marginBottom: "20px",
                            }}
                        >
                            <ProfilePicture
                                setFile={setProfile}
                                file={profile}
                                fetchedImage={fetchedImage}
                                setFetchedImage={setFetchedImage}
                                setCropSize={setCropSize}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Input
                                type="email"
                                name="Admin ID  (auto generated)"
                                value={id}
                                disabled
                            />
                            <Input
                                onChange={(value) => {
                                    setName(capitalizeString(value));
                                    setError(false);
                                }}
                                type="text"
                                name="Name"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={name}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Input
                                onChange={(value) => {
                                    validate(value);
                                    setError(false);
                                }}
                                type="name"
                                name="Email"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                            />
                            <Input
                                onChange={(value) => {
                                    setEmployeeID(value);
                                    setError(false);
                                }}
                                type="name"
                                name="Employee ID"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={employeeID}
                            />

                            {/* <Input
								onChange={(value) => {
									setError(false);
									setUserName(value);
								}}
								type="type"
								name="Username"
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
							/> */}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "20px",
                                justifyContent: "space-between ",
                            }}
                        >
                            <Input
                                onChange={(value) => {
                                    setCountry(value);
                                    setError(false);
                                }}
                                type="text"
                                name="Country"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={country}
                            />
                            <Input
                                onChange={(value) => {
                                    setPhone(value);
                                    setError(false);
                                }}
                                type="number"
                                name="Phone"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "20px",
                                justifyContent: "space-between ",
                            }}
                        >
                            <Input
                                onChange={(value) => {
                                    setTarget(value);
                                    setError(false);
                                }}
                                type="number"
                                name="Target (UAE)"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={target}
                            />
                            <SelectInput
                                name="Choose sales manager"
                                type="text"
                                placeholder="Choose sales manager"
                                onChange={(value) => {
                                    setSalesManagerName(value);
                                    setError(false);
                                }}
                                options={salesManagers ? salesManagers : []}
                                selectName={"name"}
                                selectValue={"id"}
                                value={form?.salesManager}
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                defaultValue="Select a sales manager"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "20px",
                                justifyContent: "space-between ",
                            }}
                        >
                            <Input
                                onChange={(value) => {
                                    setError(false);
                                    setUserName(value);
                                }}
                                type="type"
                                name="Username"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={userName}
                            />
                            <Input
                                onClickVisible={() =>
                                    handleChangeValue(
                                        "passwordShow",
                                        !form?.passwordShow
                                    )
                                }
                                onChange={(value) => {
                                    setPassword(value);
                                    setError(false);
                                }}
                                type={form?.passwordShow ? "text" : "password"}
                                icon={
                                    form?.passwordShow ? openedEye : closedEye
                                }
                                name="Password"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={password}
                            />
                            {/* <Input
								onClickVisible={() =>
									handleChangeValue(
										"confirmPasswordShow",
										!form?.confirmPasswordShow
									)
								}
								onChange={(value) => {
									setConfirmPassword(value);
									setError(false);
								}}
								type={
									form?.confirmPasswordShow
										? "text"
										: "password"
								}
								name="Confirm Password"
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
							/> */}
                        </div>

                        <div style={{ display: "flex", marginTop: "21px" }}>
                            <Button
                                href="#"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                bacerkgroundColor="#5A2D8F"
                                fontSize="15px"
                                padding="21px 70px"
                                onClick={(e) => {
                                    if (name === "") {
                                        setError(true);
                                        setFieldName("Name");
                                        setErrorMessage("Enter name");
                                    } else if (emailValidation === false) {
                                        setError(true);
                                        setFieldName("Email");
                                        setErrorMessage(
                                            "Please enter a valid email"
                                        );
                                    } else if (userName === "") {
                                        setError(true);
                                        setFieldName("Username");
                                        setErrorMessage(
                                            "Please enter username"
                                        );
                                    }
                                    //  else if (userNameValidation === false) {
                                    // 	setError(true);
                                    // 	setFieldName("Username");
                                    // 	setErrorMessage(
                                    // 		"Please enter a valid username with include (!,@,#,%,$)"
                                    // 	);
                                    // }
                                    else if (country === "") {
                                        setError(true);
                                        setFieldName("Country");
                                        setErrorMessage(
                                            "Please enter your country"
                                        );
                                    } else if (phone === "") {
                                        setError(true);
                                        setFieldName("Phone");
                                        setErrorMessage(
                                            "Please enter your number"
                                        );
                                    } else if (target === "") {
                                        setError(true);
                                        setFieldName("Target (UAE)");
                                        setErrorMessage(
                                            "Please enter your target"
                                        );
                                    } else if (
                                        salesManagerName === "" ||
                                        salesManagerName ===
                                            "Select a sales manager"
                                    ) {
                                        setError(true);
                                        setFieldName("Choose sales manager");
                                        setErrorMessage("Choose sales manager");
                                    } else if (password.length < 8) {
                                        setError(true);
                                        setFieldName("Password");
                                        setErrorMessage(
                                            "Be a minimum of 8 characters"
                                        );
                                    } else if (criteria === false) {
                                        setError(true);
                                        setFieldName("Password");
                                        setErrorMessage(
                                            "Strong passwords consist of a combination of uppercase and lowercase letters, numbers and special symbols"
                                        );
                                    }
                                    // else if (password !== confirmPassword) {
                                    // 	setError(true);
                                    // 	setFieldName("Confirm Password");
                                    // 	setErrorMessage(
                                    // 		"Both password must be same"
                                    // 	);
                                    // }
                                    else {
                                        e.preventDefault();
                                        handleSubmit();
                                    }
                                }}
                            >
                                <Text color="white" fontSize="15px">
                                    Submit
                                </Text>
                            </Button>

                            <Button
                                onClick={() => {
                                    history.push(
                                        "/superadmin/admission-consultant/"
                                    );
                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 22px",
                                }}
                                backgroundColor="#F4F4F4"
                                fontSize="15px"
                                padding="21px 70px"
                            >
                                <Text color=" #522e92" fontSize="15px">
                                    Cancel
                                </Text>
                            </Button>
                        </div>
                    </form>
                </Container>
            </div>
        </Wrapper>
    );
}

export default AddSalesPersonAdmin;
