import React, { useEffect, useState } from "react";
import styled from "styled-components";

export default function ReportTable({ data, selectedFields }) {
    return (
        <TableWrapper>
            <Table className="table">
                <Thead>
                    <THR>
                        {Object.keys(data[0]).map((key) => (
                            <TH key={key}>
                                {key !== "id" &&
                                    key
                                        .replace(/[_-]/g, " ")
                                        .replace(/\b\w/g, (c) =>
                                            c.toUpperCase()
                                        )}
                            </TH>
                        ))}
                    </THR>
                </Thead>
                <Tbody>
                    {data.map((item, index) => (
                        <TDR key={index}>
                            {Object.keys(data[0]).map((key) => (
                                <TD
                                    key={`${index}-${key}`}
                                    isEmailField={key == "email" ? true : false}
                                    // style={{ width: columnWidths[key] }}
                                    // style={{ maxWidth: "300px" }}
                                    // className={key == "remark" || "course" ? "short" : ""}
                                >
                                    {/* <TDText>{item[key] ? item[key] : `---`}</TDText> */}
                                    {item[key] ? item[key] : `---`}
                                </TD>
                            ))}
                        </TDR>
                    ))}
                </Tbody>
            </Table>
        </TableWrapper>
    );
}
const TableWrapper = styled.div`
    border: 1px solid #e7e7e7;
    border-radius: 16px;
    overflow: auto;
`;
const Table = styled.table`
    min-width: 100%;
    width: auto;
    /* table-layout: fixed; */
    padding: 0;
    margin: 0;
    border-spacing: 0;
    /* border-radius: 16px; */
    text-transform: capitalize;

    th,
    td {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        padding: 16px 12px;
        text-align: left;
        max-width: 300px !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;
const Thead = styled.thead`
    background-color: #edf5fe;
`;
const THR = styled.tr`
    /* background-color: #edf5fe; */
    /* background-color: #edf5fe; */
`;
const TH = styled.th`
    /* font-family: "Inter", sans-serif;
    background-color: #edf5fe;
    border-top: 1px solid #e7e7e7;
    text-align: left;
    color: #404040;
    font-weight: 600;
    &:first-child {
        border-top-left-radius: 16px;
        border-left: 1px solid #e7e7e7;
    }
    &:last-child {
        border-top-right-radius: 16px;
        border-right: 1px solid #e7e7e7;
    } */

    font-family: "Inter", sans-serif;
    background-color: #edf5fe;
    /* border-top: 1px solid #e7e7e7; */
    text-align: left;
    color: #404040;
    font-weight: 600;
    /* &:first-child {
        border-top-left-radius: 16px;
    }
    &:last-child {
        border-top-right-radius: 16px;
    } */

    &:first-child {
        /* border-top-left-radius: 16px;
        border-left: 1px solid #e7e7e7; */
        position: sticky;
        left: 0;
        z-index: 1;
    }
    /* &:nth-child(2) {
        position: sticky;
        left: 0;
        z-index: 1;
    } */
    &:last-child {
        /* border-top-right-radius: 16px;
        border-right: 1px solid #e7e7e7; */
    }
`;
const Tbody = styled.tbody``;
const TDR = styled.tr`
    &:nth-child(odd) > td {
        background: #ffffff;
    }
    &:nth-child(even) > td {
        background: #fbfbfb;
    }

    &:last-child > td {
        /* border-bottom: 1px solid #e7e7e7; */
        &:first-child {
            /* border-bottom-left-radius: 16px; */
        }
        &:last-child {
            /* border-bottom-right-radius: 16px; */
        }
    }
`;
const TD = styled.td`
    /* &.short{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 200px;
    } */
    /* font-family: "Inter", sans-serif;

    &:first-child {
        border-left: 1px solid #e7e7e7;
    }
    &:last-child {
        border-right: 1px solid #e7e7e7;
    } */
    text-transform: ${(props) =>
        props.isEmailField ? "lowercase" : "capitalize"};

    font-family: "Inter", sans-serif;
    /* border-top: 1px solid #e7e7e7;
    &:first-child {
        border-left: 1px solid #e7e7e7;
    }
    &:last-child {
        border-right: 1px solid #e7e7e7;
    } */

    &:first-child {
        /* border-left: 1px solid #e7e7e7; */
        position: sticky;
        left: 0;
        z-index: 1;
        /* background-color: #edf5fe; */
    }
    &:nth-child(2) {
        position: sticky;
        left: 200px;
        z-index: 1;
    }
    &:last-child {
        /* border-right: 1px solid #e7e7e7; */
    }
`;
const TDText = styled.p`
    font-family: "Inter", sans-serif;
    font-size: 15px;
    /* padding: 16px 8px; */
    /* text-align: left; */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

// ------------------------------
// Old Styles Here
// ------------------------------
// const Table = styled.table`
//     min-width: 100%;
//     width: auto;
//     /* table-layout: fixed; */
//     padding: 0;
//     margin: 0;
//     border-spacing: 0;
//     border-radius: 16px;
//     text-transform: capitalize;

//     th,
//     td {
//         font-family: "Inter", sans-serif;
//         font-size: 15px;
//         padding: 16px 8px;
//         text-align: left;
//         max-width: 300px !important;
//         overflow: hidden;
//         white-space: nowrap;
//         text-overflow: ellipsis;
//     }
// `;
// const Thead = styled.thead`
//     background-color: #edf5fe;
// `;
// const THR = styled.tr`
//     /* background-color: #edf5fe; */
//     background-color: #edf5fe;
// `;
// const TH = styled.th`
//     font-family: "Inter", sans-serif;
//     background-color: #edf5fe;
//     border-top: 1px solid #e7e7e7;
//     text-align: left;
//     color: #404040;
//     font-weight: 600;
//     &:first-child {
//         border-top-left-radius: 16px;
//         border-left: 1px solid #e7e7e7;
//     }
//     &:last-child {
//         border-top-right-radius: 16px;
//         border-right: 1px solid #e7e7e7;
//     }
//     /* &:first-child {
//         border-top-left-radius: 16px;
//         border-left: 1px solid #e7e7e7;
//         position: sticky;
//         left: 0;
//     }
//     &:last-child {
//         border-top-right-radius: 16px;
//         border-right: 1px solid #e7e7e7;
//     } */
// `;
// const Tbody = styled.tbody``;
// const TDR = styled.tr`
//     &:nth-child(odd) > td {
//         background: #ffffff;
//     }
//     &:nth-child(even) > td {
//         background: #fbfbfb;
//     }

//     &:last-child > td {
//         border-bottom: 1px solid #e7e7e7;
//         &:first-child {
//             border-bottom-left-radius: 16px;
//         }
//         &:last-child {
//             border-bottom-right-radius: 16px;
//         }
//     }
// `;
// const TD = styled.td`
//     font-family: "Inter", sans-serif;

//     &:first-child {
//         border-left: 1px solid #e7e7e7;
//     }
//     &:last-child {
//         border-right: 1px solid #e7e7e7;
//     }

//     /* &:first-child {
//         border-left: 1px solid #e7e7e7;
//         position: sticky;
//         left: 0;
//         z-index: 1;
//         background-color: #edf5fe;
//     }
//     &:last-child {
//         border-right: 1px solid #e7e7e7;
//     } */
// `;
// const TDText = styled.p`
//     font-family: "Inter", sans-serif;
//     font-size: 15px;
//     /* padding: 16px 8px; */
//     /* text-align: left; */
//     overflow: hidden;
//     white-space: nowrap;
//     text-overflow: ellipsis;
// `;
// ------------------------------
// Old Styles Here
// ------------------------------
