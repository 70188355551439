import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import addbutton from "../../assets/images/addbutton.svg";
import search from "../../assets/images/header-search.svg";
// import editIcon from "../../../../assets/icons/lesson-edit-icon.svg";
// import deleteIcon from "../../../../assets/icons/lesson-delete-icon.svg";
// import docIcon from "../../../../assets/icons/doc-icon-green.svg";
// import uploadIcon from "../../../../assets/icons/upload-file-icon.svg";
// import WarningModal from "../../../screens/exam/WarningModal";
import { baseConfig } from "./../../utils/config";
import { Context } from "./../../contexts/Store";
// import Text from "../../components/Text";
// import AddLive from "./AddLive";
// import LiveEmpty from "./LiveEmpty";
// import AssignmentEmpty from "./AssignmentEmpty";
// import AddAssignment from "./AddAssignment";

import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useHistory, useParams } from "react-router-dom";

export default function AddWebinar({}) {
    const [title, setTitle] = useState("");
    const [liveURL, setLiveURL] = useState("");
    const { state } = useContext(Context);
    const [date, setDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [eventType, setEventType] = useState("students");

    // university state
    const [selectedUniversity, setSelectedUniversity] = useState([]);
    const [listUniversity, setListUniversity] = useState([]);
    const [universityID, setUniversityID] = useState([]);

    //image
    const [imageFile, setImageFile] = useState(null);
    const [blobImageUrl, setBlobImageUrl] = useState(null);

    const [webinarStatus, setWebinarStatus] = useState("");
    const [completedUrl, setCompletedUrl] = useState("");

    // route
    const history = useHistory();
    const { id } = useParams();

    //get university list api
    const getUniversity = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/university/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setListUniversity(data.data);
                } else {
                }
            })
            .catch((error) => {});
    };

    //get single webinar  api
    const getSingleWebinar = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/learn/webinar/${id}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setTitle(data.data.title);
                    setLiveURL(data.data.zoom_link);
                    setDate(data.data.date);
                    setStartTime(data.data.start_time);
                    setEndTime(data.data.end_time);
                    setEventType(data.data.event_type);
                    setWebinarStatus(data.data.status);
                    if (data.data.video_link) {
                        setCompletedUrl(data.data.video_link);
                    }
                    setBlobImageUrl(data.data.image);

                    // listUniversity.map((item) =>
                    //     data.data.universities.map((university) => {
                    //         if (item.id == university) {
                    //             setSelectedUniversity([
                    //                 ...selectedUniversity,
                    //                 item,
                    //             ]);
                    //         }
                    //     })
                    // );
                    setSelectedUniversity(
                        listUniversity.filter((item) =>
                            data.data.universities.includes(item.id)
                        )
                    );
                } else {
                }
            })
            .catch((error) => {});
    };

    // Add webinar api
    const handleSubmit = () => {
        let accessToken = state.user_details.access_token;

        var formdata = new FormData();

        formdata.append("title", title);
        formdata.append("zoom_link", liveURL);
        formdata.append("date", date);
        formdata.append("start_time", startTime);
        formdata.append("end_time", endTime);
        formdata.append("event_type", eventType);
        formdata.append(
            "universities",
            JSON.stringify(
                selectedUniversity.map((university) => university.id)
            )
        );

        if (webinarStatus === "Completed") {
            formdata.append("video_link", completedUrl);
        }

        // formdata.append("universities", universityID);

        var method = id ? baseConfig.put : baseConfig.post;
        var URL = id ? `/learn/webinar/${id}/` : `/learn/webinars/`;

        method(URL, formdata, {
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                let { StatusCode, message, data } = response.data;
                if (StatusCode == 6000) {
                    history.push("/superadmin/webinar/");
                } else {
                }
            })
            .catch((error) => {});
    };

    const onImageChange = (e) => {
        setImageFile(e.target.files[0]);
        setBlobImageUrl(URL.createObjectURL(e.target.files[0]));
    };

    useEffect(() => {
        getUniversity();
    }, []);

    useEffect(() => {
        if (id) {
            getSingleWebinar();
        }
    }, [listUniversity]);

    const updateUniversityArray = () => {
        let newArray = selectedUniversity.map((data) => data.id);
        setUniversityID(newArray);
    };

    useEffect(() => {
        updateUniversityArray();
    }, [selectedUniversity]);

    return (
        <Wrapper>
            <Title fontSize="35px">Webinar</Title>

            <AddAssignmentBox>
                <ImageContainer htmlFor="file3">
                    <UploadImage
                        type="file"
                        accept="image/*"
                        id="file3"
                        onChange={(e) => {
                            // setImageFile(e.target.files[0]);
                            onImageChange(e);
                        }}
                    />
                    <ImageCard>
                        {blobImageUrl === null ? (
                            <LiveImage
                                src={
                                    require("./../../assets/images/live-bg.jpg")
                                        .default
                                }
                                alt="image"
                            />
                        ) : (
                            <LiveImage src={blobImageUrl} alt="image" />
                        )}
                    </ImageCard>
                    <ImageTextDiv>
                        <TextBox>
                            <ImageText>Upload image</ImageText>
                        </TextBox>
                    </ImageTextDiv>
                </ImageContainer>
                <BottomContentWrapper>
                    <LeftPart>
                        <ContentText>Webinar Title :</ContentText>
                    </LeftPart>
                    <RightPart>
                        <InputField
                            type="text"
                            placeholder="Webinar Title"
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                            value={title}
                        />
                    </RightPart>
                </BottomContentWrapper>
                {webinarStatus === "Completed" ? (
                    <BottomContentWrapper>
                        <LeftPart>
                            <ContentText>Completed URL :</ContentText>
                        </LeftPart>
                        <RightPart>
                            <InputField
                                type="text"
                                placeholder="Completed URL"
                                onChange={(e) => {
                                    setCompletedUrl(e.target.value);
                                }}
                                value={completedUrl}
                            />
                        </RightPart>
                    </BottomContentWrapper>
                ) : (
                    <BottomContentWrapper>
                        <LeftPart>
                            <ContentText>Webinar URL :</ContentText>
                        </LeftPart>
                        <RightPart>
                            <InputField
                                type="text"
                                placeholder="Webinar URL"
                                onChange={(e) => {
                                    setLiveURL(e.target.value);
                                }}
                                value={liveURL}
                            />
                        </RightPart>
                    </BottomContentWrapper>
                )}
                <InputDiv>
                    <InputList>
                        <Left>
                            <LabelText htmlFor="date">Date :</LabelText>
                        </Left>
                        <Right>
                            <Input
                                id="date"
                                onChange={(e) => {
                                    setDate(e.target.value);
                                }}
                                value={date}
                                type="date"
                            />
                        </Right>
                    </InputList>

                    <InputList className="adjust">
                        <Left className="no-width">
                            <LabelText htmlFor="start-time">
                                Start Time : &nbsp;
                            </LabelText>
                        </Left>
                        <Right>
                            <Input
                                id="start-time"
                                onChange={(e) => {
                                    setStartTime(e.target.value);
                                }}
                                value={startTime}
                                type="time"
                            />
                        </Right>
                    </InputList>

                    <InputList className="adjust">
                        <Left className="no-width">
                            <LabelText htmlFor="end-time">
                                End Time : &nbsp;
                            </LabelText>
                        </Left>
                        <Right>
                            <Input
                                id="end-time"
                                onChange={(e) => {
                                    setEndTime(e.target.value);
                                }}
                                value={endTime}
                                type="time"
                            />
                        </Right>
                    </InputList>
                </InputDiv>
                <BottomContentWrapper>
                    <LeftPart>
                        <ContentText>Webinar Type :</ContentText>
                    </LeftPart>
                    <RightPart>
                        {/* <InputField
							type="selct"
							placeholder="Webinar Type"
							onChange={(e) => {
								// setLiveURL(e.target.value);
							}}
							// value={liveURL}
						/> */}
                        <Select
                            onChange={(e) => {
                                setEventType(e.target.value);
                            }}
                            // className="Select-input"
                            value={eventType}
                        >
                            <option value="students">Students</option>
                            <option value="public">Public</option>
                        </Select>
                    </RightPart>
                </BottomContentWrapper>
                <BottomContentWrapper>
                    <LeftPart>
                        <ContentText>University :</ContentText>
                    </LeftPart>
                    <RightPart>
                        {/* <InputField
							type="selct"
							placeholder="Webinar Type"
							onChange={(e) => {
								// setLiveURL(e.target.value);
							}}
							// value={liveURL}
						/> */}
                        {/* <Select
							onChange={(e) => {
								setSelectedUniversity(e.target.value);
							}}
							// className="Select-input"
							value={selectedUniversity}
						>
							{listUniversity.map((data) => (
								<option value={data.id}>{data.name}</option>
							))}
						</Select> */}
                        <Stack spacing={3} sx={{ width: "100%" }}>
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                getOptionLabel={(option) => option.name}
                                University
                                value={selectedUniversity}
                                options={listUniversity}
                                onChange={(e, newValue, value) => {
                                    setSelectedUniversity(newValue);
                                }}
                                // defaultValue={[listUniversity[13]]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // variant="standard"
                                        label="University"
                                        placeholder="Choose university"
                                    />
                                )}
                            />
                        </Stack>
                    </RightPart>
                </BottomContentWrapper>
                <ButtonsContainer>
                    <LeftEmptyBox></LeftEmptyBox>
                    <RightBox>
                        <CancelButton
                            onClick={() => {
                                history.push("/superadmin/webinar/");
                            }}
                        >
                            Cancel
                        </CancelButton>
                        <SaveButton
                            onClick={() => {
                                if (title === "") {
                                    alert("Enter Webinar Title");
                                } else if (liveURL === "") {
                                    alert("Enter URL of webinar");
                                } else if (date === "") {
                                    alert("Select Date of webinar");
                                } else if (startTime === "") {
                                    alert("Select start time");
                                } else if (endTime === "") {
                                    alert("Select end time");
                                } else if (selectedUniversity.length < 1) {
                                    alert("Select Universities");
                                } else {
                                    handleSubmit();
                                }
                            }}
                        >
                            Save
                        </SaveButton>
                    </RightBox>
                </ButtonsContainer>
            </AddAssignmentBox>
        </Wrapper>
    );
}

const AddAssignmentBox = styled.form`
    background: #ffffff;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    margin-top: 10px;
`;

const ImageContainer = styled.label`
    /* position: relative; */
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-bottom: 10px;
`;

const UploadImage = styled.input`
    display: none;
`;

const ImageCard = styled.div`
    /* padding: 20px; */
    height: 200px;
    width: 300px;
    border-radius: 6px;
    display: inline-block;
`;
const LiveImage = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 6px;
`;

const ImageTextDiv = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

const TextBox = styled.div`
    background: #8561c6;
    padding: 0 10px;
    border-radius: 6px;
    /* position: absolute; */
    /* background: #8561c6; */
    /* left: 50%;

	/* margin-left: auto; */
    /* margin-right: auto; */
    /* transform: translate(-50%, -50%); */
`;
const ImageText = styled.h4`
    color: #fff;
`;
const BottomContentWrapper = styled.div`
    display: flex;
    align-items: start;
    transition: 0.5s;
    animation: slideInBottom 0.4s ease-in-out forwards;
    margin-bottom: 15px;
`;
const LeftPart = styled.div`
    width: 155px;
    padding-top: 10px;
`;
const ContentText = styled.h6`
    font-size: 1rem;
    color: #000000;
    opacity: 0.5;
`;
const RightPart = styled.div`
    width: calc(100% - 110px);
    display: flex;
    flex-direction: column;
`;
const InputField = styled.input`
    /* display: inline-block; */
    height: 40px;
    opacity: 0.7;
    border: 1px solid #adadad;
    box-sizing: border-box;
    border-radius: 6px !important;
    /* padding: 10px 5px; */
    padding: 0px 10px;
    font-size: 0.85rem;
    line-height: 2.5em;
    &:focus {
        outline: none;
        background: #fbfbfb;
        border: 1px solid #2d60ff;
    }

    outline: none;
`;
const InputDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
`;
const InputList = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background: red; */
    width: 100%;
    &.adjust {
        justify-content: flex-end;
    }
`;
const Left = styled.div`
    /* width: 150px; */
    /* &.no-width {
		width: auto;
	} */
`;
const LabelText = styled.label`
    /* display: inline-block; */
    font-size: 1rem;
    font-weight: 500;
    color: #000000;
    opacity: 0.5;
    cursor: pointer;
    user-select: none;
`;

const Right = styled.div`
    width: calc(100% - 150px);
    /* width: 100%; */
`;
const Input = styled.input`
    width: 100%;
    border: 1px solid #c4c4c4;
    padding: 0px 10px;
    box-sizing: border-box;
    border-radius: 4px !important;
    height: 35px;
    color: #313131 !important;
    font-size: 0.9rem;
    opacity: 0.9 !important;
    &::placeholder {
        color: #313131 !important;
        opacity: 0.9 !important;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
`;
const LeftEmptyBox = styled.div``;
const RightBox = styled.div`
    display: flex;
    align-items: center;
`;
const CancelButton = styled.div`
    color: red;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 8px 10px;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
        box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
        transform: translateY(-0.1em);
    }
`;
const SaveButton = styled.div`
    background: #522f91;
    border-radius: 6px;
    color: #fff;
    margin-left: 15px;
    padding: 8px 12px;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
        box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
        transform: translateY(-0.1em);
    }
`;
const Select = styled.select`
    width: 100%;
    border: 1px solid #c4c4c4;
    padding: 0px 10px;
    box-sizing: border-box;
    border-radius: 4px !important;
    height: 35px;
    color: #313131 !important;
    font-size: 0.9rem;
    opacity: 0.9 !important;
    &::placeholder {
        color: #313131 !important;
        opacity: 0.9 !important;
    }
`;
