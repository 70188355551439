import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";

import Wrapper from "../../components/Wrapper";
import Title from "../../components/Title";
import TD from "../../components/TD";
import Table from "../../components/Table";
import loadingGif from "../../assets/lottie/loading.gif";
import MainPagination from "../../components/includes/MainPagination";
import InactiveACListItem from "./InactiveACListItem";

export default function DisabledConsultants() {
    const { state } = useContext(Context);

    const [search, setSearch] = useState();
    const [paginationData, setPaginationData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setLoading] = useState(false);

    const [inactiveConsultants, setInactiveConsultants] = useState([]);

    const getInactiveConsultants = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/sales-persons/disabled-sales-persons/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: search,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setInactiveConsultants(data.data);
                    setPaginationData(data.paginator);
                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                alert(error);
                setLoading(false);
            });
    };

    useEffect(() => {
        getInactiveConsultants();
    }, [search]);

    return (
        <Wrapper>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Title fontSize="30px" fontWeight="700">
                    Inactive Adminssion Consultants
                </Title>
                <SearchDiv>
                    <Form className="search-container">
                        <SearchIcon
                            src={
                                require("./../../assets/images/search.svg")
                                    .default
                            }
                        />
                        <SearchField
                            className="input-box"
                            type="text"
                            name="search"
                            placeholder="Search for something"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </Form>
                </SearchDiv>
            </div>

            {!isLoading ? (
                <>
                    {inactiveConsultants.length > 0 ? (
                        <>
                            {/* <Text
                        fontSize="20px"
                        fontWeight="400"
                        color="#000"
                        style={{ marginTop: "10px" }}
                    ></Text> */}
                            <Table
                                margin="30px 0"
                                borderRadius="30px"
                                padding="30px 0"
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        borderBottom: "1px solid #E6EFF5",
                                        padding: "0 20px 20px 20px",
                                    }}
                                    className="table-head"
                                >
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="105px"
                                        // width="10%"
                                    >
                                        ID No.
                                    </TD>
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="175px"
                                        // width="15%"
                                    >
                                        Name
                                    </TD>
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="150px"
                                        // width="15%"
                                    >
                                        Email
                                    </TD>
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="150px"
                                        // width="15%"
                                    >
                                        Phone
                                    </TD>
                                    {/* <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                    >
                                        Target
                                    </TD> */}

                                    {/* <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                    >
                                        Revenue Generated
                                    </TD> */}
                                    {/* <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="175px"
                                        // width="17%"
                                    >
                                        No. of leads
                                    </TD> */}

                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="175px"
                                        // width="17%"
                                    >
                                        No. of Admissions
                                    </TD>
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="175px"
                                        // width="17%"
                                    >
                                        No. of invalid leads
                                    </TD>
                                    <TD
                                        color="#808080"
                                        fontWeight="500"
                                        fontSize="16px"
                                        // width="175px"
                                        // width="17%"
                                    >
                                        No. of dead leads
                                    </TD>
                                </div>
                                <div className="table-body">
                                    {inactiveConsultants.map((item) => {
                                        return (
                                            <InactiveACListItem item={item} />
                                        );
                                    })}
                                </div>
                            </Table>
                        </>
                    ) : (
                        <LoaderWrapper>
                            <EmptyText>No data found</EmptyText>
                        </LoaderWrapper>
                    )}
                </>
            ) : (
                <LoaderWrapper>
                    <LoadingIcon src={loadingGif} alt="icon" />
                </LoaderWrapper>
            )}
            {/* <Pagination
				postsPerPage={count}
				totalPosts={leadListData.length}
				paginate={paginate}
				currentPage={currentPage}
				currentPosts={currentPosts}
			/> */}
            <MainPagination
                paginationData={paginationData}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
            />
        </Wrapper>
    );
}

const SearchDiv = styled.div`
    /* margin-right: 20px; */
    width: 400px;
`;
const Form = styled.form`
    font-size: 15px;
    box-sizing: border-box;
    background: #f5f7fa;
    border-radius: 8px;
    /* width: 400px; */
    border: 1px solid #aea7fd;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    /* width: 100%; */
`;
const SearchIcon = styled.img`
    margin-right: 10px;
`;
const SearchField = styled.input`
    width: 100%;
    height: 90%;
    &:focus-visible {
        outline: none;
        background: #f5f7fa;
        border: 0px solid #2d60ff;
    }
`;

const EmptyDiv = styled.div``;
const LoaderWrapper = styled.div`
    /* border: 1px solid #000; */
    height: calc(100vh - 230px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LoadingIcon = styled.img`
    width: 70px;
    display: block;
    object-fit: cover;
`;
const EmptyText = styled.div`
    font-size: 26px;
    opacity: 0.6;
    font-weight: 500;
`;
