import React from "react";
import styled from "styled-components";
import Title from "../../../components/Title";

function EditModal({ editModal, setEditModal }) {
    return (
        <>
            {editModal && (
                <BackContainer>
                    <Overlay onClick={() => setEditModal(false)}></Overlay>

                    <MainContainer>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "20px",
                            }}
                        >
                            <Title color="#000" fontSize="14px">
                                Edit
                            </Title>
                            <ClearForm>Clear Form</ClearForm>
                        </div>

                        <Bottom>
                            <Title fontSize="12px" color="#000">
                                Payment
                            </Title>
                            <input
                                type="number"
                                style={{
                                    width: "97%",
                                    margin: "10px 0",
                                    border: "1px solid #e7e7e7",
                                    padding: "5px",
                                    outline: "none",
                                }}
                            />
                            <Title fontSize="12px" color="#000">
                                Remark
                            </Title>
                            <textarea
                                type="textarea"
                                style={{
                                    width: "97%",
                                    margin: "10px 0",
                                    border: "1px solid #e7e7e7",
                                    padding: "5px",
                                    outline: "none",
                                    resize: "none",
                                    rows: "15",
                                }}
                            />
                            <Title fontSize="12px" color="#000">
                                Due Date
                            </Title>
                            <input
                                type="date"
                                style={{
                                    width: "97%",
                                    margin: "10px 0",
                                    border: "1px solid #e7e7e7",
                                    padding: "5px",
                                    outline: "none",
                                }}
                            />
                            <Title fontSize="12px" color="#000">
                                Follow up Date
                            </Title>
                            <input
                                type="date"
                                style={{
                                    width: "97%",
                                    margin: "10px 0",
                                    border: "1px solid #e7e7e7",
                                    padding: "5px",
                                    outline: "none",
                                }}
                            />
                        </Bottom>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                margin: "20px 0",
                            }}
                        >
                            <Title color="red" fontSize="14px">
                                Cancel
                            </Title>
                            <Save>Save</Save>
                        </div>
                    </MainContainer>
                </BackContainer>
            )}
        </>
    );
}

export default EditModal;
const MainContainer = styled.div`
    width: 550px;
    height: 400px;
    background-color: white;
    position: fixed;
    left: 32%;

    top: 20%;
    border-radius: 12px;
    padding: 20px;
`;
const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0);
`;
const Overlay = styled.div`
    position: fixed;
    width: 100%;
    cursor: pointer;
    height: 100vh;
    background: #000;
    opacity: 0.3;
`;
const ClearForm = styled.p`
    font-size: 12px;
    color: red;
`;
const Bottom = styled.div`
    /* height: 85%; */
    background-color: #f8fafc;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    padding: 20px;
`;
const Save = styled.button`
    background-color: #917afd;
    color: #ffff;
    border-radius: 8px;
    padding: 10px 12px;
    border: none;
`;
