import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import EMIstep1 from "./EMIstep1";
import EMIStep2 from "./EMIStep2";

function EMIModal({
    setModal,
    programId,
    getSingleStudentDetails,
    emiNotAddedCourses,
    selectedStudentProgram,
    setSelectedStudentProgram,
    handleCloseEmiModal,
    handleCloseEmiModalWIthoutConfirmation,
}) {
    return (
        <>
            {/* // <BackContainer> */}
            {/* <Overlay
                onClick={() => {
                    setModal(false);
                }}
            ></Overlay> */}
            <Modal>
                <Container>
                    <div>
                        <EMIstep1
                            emiNotAddedCourses={emiNotAddedCourses}
                            selectedStudentProgram={selectedStudentProgram}
                            setSelectedStudentProgram={setSelectedStudentProgram}
                            setModal={setModal}
                            handleCloseEmiModal={handleCloseEmiModal}
                            programId={programId}
                            getSingleStudentDetails={getSingleStudentDetails}
                            handleCloseEmiModalWIthoutConfirmation={
                                handleCloseEmiModalWIthoutConfirmation
                            }
                        />
                        {/* <EMIStep2 /> */}
                    </div>
                </Container>
            </Modal>

            {/* // </BackContainer> */}
        </>
    );
}
export default EMIModal;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
`;
const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    cursor: pointer;
    height: 100vh;
`;
const Modal = styled.div`
    position: absolute;
    /* margin: 0 auto; */
    background: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1280px;
    border-radius: 23px;
    /* min-height: 80vh; */
    max-height: 80vh;
    overflow-y: scroll;
    box-shadow: 24px;

    /* transition: 0.5s;
    z-index: 101; */
`;
const Container = styled.div`
    /* width: 500px; */
    /* padding: 15px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* overflow: scroll; */
    /* max-height: calc(100vh - 50px); */
`;
