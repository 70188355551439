import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import React, { useEffect, useState, useContext } from "react";
import { Context } from "./../../contexts/Store";
import Text from "../../components/Text";
import Container from "../../components/Container";
import styled from "styled-components";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import UploadInput from "../../components/UploadInput/UploadInput";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import { baseConfig } from "./../../utils/config";

function AddUniversity() {
    const [file, setFile] = useState(null);
    const history = useHistory();
    const { state } = useContext(Context);

    const [id, setId] = useState("");
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [secondaryPhone, setSecondaryPhone] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [fieldName, setFieldName] = useState("");
    const [emailValidation, setEmailValidation] = useState(false);

    const validate = (text) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(text) === false) {
            setEmailValidation(false);
            setEmail(text);
            return false;
        } else {
            setEmail(text);
            setEmailValidation(true);
        }
    };

    const getUniversityId = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/get-university-id/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setId(data.university_id);
                } else {
                }
            })
            .catch((error) => {});
    };

    const handleSubmitForm = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();

        formdata.append("name", name);
        formdata.append("email", email);
        formdata.append("phone", phone);
        formdata.append("phone_2", secondaryPhone);
        formdata.append("address", address1);
        formdata.append("address_2", address2);
        if (file !== null) {
            formdata.append("logo", file[0]);
        }

        baseConfig
            .post("/campus/university/", formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                let { StatusCode, message } = response.data;
                if (StatusCode == 6000) {
                    getUniversityId();
                    setName("");
                    setFile(null);
                    setEmail("");
                    setPhone("");
                    setSecondaryPhone("");
                    setAddress1("");
                    setAddress2("");
                    setFieldName("");
                } else {
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    useEffect(() => {
        getUniversityId();
    }, []);

    return (
        <Wrapper>
            <Top>
                <TopLeft>
                    <Title fontSize="35px">Add University</Title>
                    {/* <Text
                        fontSize="20px"
                        fontWeight="400"
                        color="#000"
                        style={{ marginTop: "10px" }}
                    >
                        University / Add University
                    </Text> */}
                </TopLeft>
                <TopRight
                    onClick={() => {
                        getUniversityId();
                        setName("");
                        setFile(null);
                        setEmail("");
                        setPhone("");
                        setSecondaryPhone("");
                        setAddress1("");
                        setAddress2("");
                        setFieldName("");
                    }}
                >
                    <Text
                        fontSize="16px"
                        fontWeight="400"
                        color="red"
                        style={{ marginLeft: "5px" }}
                    >
                        Clear Form
                    </Text>
                </TopRight>
            </Top>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Container style={{ flexWrap: "wrap " }} width="100%">
                    <form id="form">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Input
                                type="text"
                                name="University ID (auto generated)"
                                value={id}
                                disabled
                            />
                            <Input
                                fieldName={fieldName}
                                onChange={(value) => {
                                    setName(value);
                                    setError(false);
                                }}
                                type="text"
                                name="University Name"
                                isError={isError}
                                errorMessage={errorMessage}
                                value={name}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Input
                                fieldName={fieldName}
                                isError={isError}
                                errorMessage={errorMessage}
                                onChange={(value) => {
                                    validate(value);
                                    setError(false);
                                }}
                                type="email"
                                name="Email Id*"
                                value={email}
                            />

                            <Input
                                fieldName={fieldName}
                                isError={isError}
                                errorMessage={errorMessage}
                                onChange={(value) => {
                                    setPhone(value);
                                    setError(false);
                                }}
                                type="text"
                                name="Phone No*"
                                value={phone}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Input
                                fieldName={fieldName}
                                isError={isError}
                                errorMessage={errorMessage}
                                onChange={(value) => {
                                    setSecondaryPhone(value);
                                    setError(false);
                                }}
                                type="text"
                                name="Phone No 2"
                                value={secondaryPhone}
                            />
                            <Input
                                fieldName={fieldName}
                                isError={isError}
                                errorMessage={errorMessage}
                                onChange={(value) => {
                                    setAddress1(value);
                                    setError(false);
                                }}
                                type="text"
                                name="Address line1"
                                value={address1}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Input
                                fieldName={fieldName}
                                isError={isError}
                                errorMessage={errorMessage}
                                onChange={(value) => {
                                    setAddress2(value);
                                    setError(false);
                                }}
                                type="text"
                                name="Address line2"
                                value={address2}
                            />
                            <UploadInput
                                file={file}
                                setFile={setFile}
                                isError={isError}
                                fieldName={fieldName}
                                name="University Logo"
                                errorMessage={errorMessage}
                                setError={setError}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                marginTop: "30px",
                            }}
                        >
                            <Button
                                bacerkgroundColor="#5A2D8F"
                                fontSize="15px"
                                padding="21px 90px"
                                fontWeight="500"
                                onClick={(e) => {
                                    if (name === "") {
                                        setError(true);
                                        setFieldName("University Name");
                                        setErrorMessage(
                                            "This field is required"
                                        );
                                    } else if (emailValidation === false) {
                                        setError(true);
                                        setFieldName("Email Id*");
                                        setErrorMessage(
                                            "Please enter a valid email"
                                        );
                                    } else if (phone === "") {
                                        setError(true);
                                        setFieldName("Phone No*");
                                        setErrorMessage(
                                            "Enter your phone number"
                                        );
                                    } else if (secondaryPhone === "") {
                                        setError(true);
                                        setFieldName("Phone No 2");
                                        setErrorMessage(
                                            "Enter your phone number"
                                        );
                                    } else if (address1 === "") {
                                        setError(true);
                                        setFieldName("Address line1");
                                        setErrorMessage("Enter your address");
                                    } else if (address2 === "") {
                                        setError(true);
                                        setFieldName("Address line2");
                                        setErrorMessage("Enter your address");
                                    } else if (file === null) {
                                        setError(true);
                                        setFieldName("University Logo");
                                        setErrorMessage("Add university logo");
                                    } else {
                                        setError(false);
                                        e.preventDefault();
                                        handleSubmitForm();
                                    }
                                }}
                            >
                                <Text color="white" fontSize="20px">
                                    Add
                                </Text>
                            </Button>
                            <Button
                                backgroundColor="#F4F4F4"
                                style={{ marginLeft: "15px" }}
                                onClick={() => {
                                    history.push(
                                        "/campus-admin/all-university/"
                                    );
                                }}
                            >
                                <Text color="#522e92" fontSize="20px">
                                    Cancel
                                </Text>
                            </Button>
                        </div>
                    </form>
                </Container>
            </div>
        </Wrapper>
    );
}

export default AddUniversity;

const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const TopLeft = styled.div``;
const TopRight = styled.div`
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 50px;
    border-radius: 3px;
    padding: 0 20px;
    cursor: pointer;
`;
