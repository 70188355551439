import React, { useState } from "react";
import styled from "styled-components";
// import { Link } from "react-router-dom";

function ExcelFileModal({ modalClose, setExcelFile, handleExcelFile }) {
	return (
		<BackContainer>
			<Overlay
				onClick={() => {
					modalClose(false);
				}}
			></Overlay>
			<Modal>
				<Container>
					<Title>Upload Excel File</Title>
					<Input
						type="file"
						// accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						accept=".xlsx, .xls, .csv"
						onChange={(e) => {
							setExcelFile(e.target.files);
						}}
					/>
					<Div>
						<Button
							onClick={() => {
								modalClose(false);
							}}
							style={{
								display: "flex",
								alignItems: "center",
								margin: "0 20px",
							}}
							backgroundColor="#F4F4F4"
							fontSize="15px"
						>
							<Text color="#522e92" fontSize="15px">
								Cancel
							</Text>
						</Button>
						<Button
							onClick={() => {
								handleExcelFile();
							}}
							style={{
								display: "flex",
								alignItems: "center",
							}}
							backgroundColor="#5A2D8F"
							fontSize="15px"
						>
							<Text color="fff" fontSize="15px">
								Upload
							</Text>
						</Button>
					</Div>
				</Container>
			</Modal>
		</BackContainer>
	);
}
export default ExcelFileModal;

const BackContainer = styled.div`
	position: fixed;
	transition: 0.3s;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(2px);
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	cursor: pointer;
	height: 100vh;
`;
const Modal = styled.div`
	margin: 0 auto;
	background: #fff;
	left: 50%;
	top: 45%;
	transform: translate(-50%, -50%);
	position: absolute;
	border-radius: 23px;
	transition: 0.5s;
	z-index: 101;
`;
const Container = styled.div`
	width: 500px;
	padding: 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
const Title = styled.h4`
	text-align: center;
	font-size: 18px;
	margin-bottom: 20px;
`;
const Input = styled.input`
	border: 1px solid #efefef;
	height: 50px;
	padding: 15px;
	border-radius: 4px;
`;
const Div = styled.div`
	text-align: center;
	font-size: 18px;
	display: flex;
	justify-content: flex-end;
	margin-top: 40px;
`;

const Button = styled.a`
	background-color: ${(props) =>
		props.backgroundColor ? props.backgroundColor : "#522e92"};
	font-size: ${(props) => (props.fontSize ? props.fontSize : "25px")};
	color: ${(props) => (props.color ? props.color : "white")};
	padding: ${(props) => (props.padding ? props.padding : "0 40px")};
	border: none;
	border-radius: 8px;
	text-align: center;
	display: inline-block;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	/* width: 200px; */
	cursor: pointer;
	user-select: none;
`;
const Text = styled.h4`
	font-size: "15px";
	color: ${(props) => (props.color ? props.color : "white")}; ;
`;
