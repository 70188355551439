import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import Wrapper from "../../components/Wrapper";
import { Context } from "../../contexts/Store";
import { baseConfig } from "../../utils/config";
import loadingIcon from "../../assets/lottie/loading.gif";

// mui imports
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FinanceCard from "./includes/FinanceCard";
import StudentCard from "./includes/StudentCard";
import FilterBox from "../financeManager/includes/FilterBox";

function MainDashboard() {
    const { state, dispatch } = useContext(Context);

    const [selectedFinanceFilter, setSelectedFinanceFilter] =
        useState("Monthly");
    const filterValues = ["Monthly", "Yearly"];
    const [isLoading, setIsLoading] = useState(false);

    const [dashboardData, setDashboardData] = useState();
    const [monthlyData, setMonthlyData] = useState();
    const [yearlyData, setYearlyData] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    // const [financeData, setFinanceData] = useState(monthlyData);

    const handleFinanceFilter = (event) => {
        setSelectedFinanceFilter(event.target.value);
    };

    const getDashboardData = () => {
        setIsLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/finance/finance-executive-dashboard/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    from_date: fromDate,
                    to_date: toDate,
                },
            })

            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setDashboardData(data);
                    // setMonthlyData(data.monthly);
                    // setYearlyData(data.yearly);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    alert("Some error occured.Please try again later");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                alert(error);
            });
    };

    useEffect(() => {
        getDashboardData();
    }, [fromDate, toDate]);

    // useEffect(() => {
    //     if (selectedFinanceFilter == "Monthly") {
    //         setFinanceData(monthlyData);
    //     } else if (setFinanceData === "Yearly") {
    //         setFinanceData(yearlyData);
    //     }
    // }, [selectedFinanceFilter]);

    console.log(dashboardData,"dashboarddata")
    return (
        <>
            {!isLoading ? (
                <Wrapper
                // background="#fff"
                >
                    <FinanceSection>
                        <TitleBox>
                            <TitleText>Finance</TitleText>
                            <FilterBox
                                setFromDate={setFromDate}
                                setToDate={setToDate}
                            />
                        </TitleBox>
                        <CardsWrapper>
                            <FinanceCard
                                type="receivables"
                                // data={
                                //     selectedFinanceFilter === "Monthly"
                                //         ? monthlyData?.outstanding_data
                                //         : yearlyData?.outstanding_data
                                // }
                                data={dashboardData?.outstanding_data}
                                // pieChartData={
                                //     selectedFinanceFilter === "Monthly"
                                //         ? monthlyData?.outstanding_data?.data
                                //         : yearlyData?.outstanding_data?.data
                                // }
                            />
                            <FinanceCard
                                type="fee received"
                                // data={
                                //     selectedFinanceFilter === "Monthly"
                                //         ? monthlyData?.recieved_data
                                //         : yearlyData?.recieved_data
                                // }
                                // pieChartData={
                                //     selectedFinanceFilter === "Monthly"
                                //         ? monthlyData?.recieved_data?.data
                                //         : yearlyData?.recieved_data?.data
                                // }
                                data={dashboardData?.recieved_data}
                            />
                            <FinanceCard
                                type="balance fee"
                                // data={
                                //     selectedFinanceFilter === "Monthly"
                                //         ? monthlyData?.balance_data
                                //         : yearlyData?.balance_data
                                // }
                                // pieChartData={
                                //     selectedFinanceFilter === "Monthly"
                                //         ? monthlyData?.balance_data?.data
                                //         : yearlyData?.balance_data?.data
                                // }
                                data={dashboardData?.balance_data}
                            />
                            <FinanceCard
                                type="pending due"
                                data={dashboardData?.due_amount}
                            />
                            {/* <FinanceCard
                                type="received"
                                data={
                                    selectedFinanceFilter === "Monthly"
                                        ? monthlyData?.recieved_data
                                        : yearlyData?.recieved_data
                                }
                            /> */}
                            {/* <FinanceCard
                                type="balance"
                                data={
                                    selectedFinanceFilter === "Monthly"
                                        ? monthlyData?.balance_data
                                        : yearlyData?.balance_data
                                }
                            /> */}
                        </CardsWrapper>
                    </FinanceSection>
                    <StudentsSecttion>
                        <TitleBox>
                            <TitleText>Student Info</TitleText>
                            {/* <DropdownDiv>
                        <FilterText>Filter By:</FilterText>
                        <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
                            <InputLabel id="demo-simple-select-label"></InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                style={{ fontSize: "14px", fontWeight: "500" }}
                                value={selectedFinanceFilter}
                                label=""
                                variant="outlined"
                                onChange={handleFinanceFilter}
                            >
                                {filterValues?.map((item) => (
                                    <MenuItem
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                        }}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DropdownDiv> */}
                        </TitleBox>
                        <CardsWrapper
                            style={{
                                gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                            }}
                        >
                            <StudentCard
                                type="active"
                                data={dashboardData?.active_students}
                                barChartData={dashboardData?.active_students?.data.slice(
                                    0,
                                    4
                                )}
                            />
                            <StudentCard
                                type="On Break"
                                data={dashboardData?.onbreak_students}
                                barChartData={dashboardData?.dropout_students?.data.slice(
                                    0,
                                    4
                                )}
                            />
                            <StudentCard
                                type="On Hold"
                                data={dashboardData?.onhold_students}
                                barChartData={dashboardData?.dropout_students?.data.slice(
                                    0,
                                    4
                                )}
                            />
                            <StudentCard
                                type="completed"
                                data={dashboardData?.completed_students}
                                barChartData={dashboardData?.completed_students?.data.slice(
                                    0,
                                    4
                                )}
                            />
                            <StudentCard
                                type="dropout"
                                data={dashboardData?.dropout_students}
                                barChartData={dashboardData?.dropout_students?.data.slice(
                                    0,
                                    4
                                )}
                            />
                             
                        </CardsWrapper>
                    </StudentsSecttion>
                </Wrapper>
            ) : (
                <LoaderContainer>
                    <LoadingGif src={loadingIcon} alt="icon" />
                </LoaderContainer>
            )}
        </>
    );
}

export default MainDashboard;

const FinanceSection = styled.div`
    margin-bottom: 15px;
`;
const StudentsSecttion = styled.div``;
const TitleBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* align-items: flex-end; */
    margin-bottom: 10px;
`;
const TitleText = styled.h6`
    /* font-size: 22px; */
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    /* identical to box height */

    /* dark/black */

    color: #000000;
`;
const DropdownDiv = styled.div`
    display: flex;
    align-items: center;
`;
const FilterText = styled.p`
    /* font-weight: 500; */
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-transform: capitalize;

    /* Dark/500 */

    color: #010101;
`;
const CardsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 16px;
`;
const LoaderContainer = styled.div`
    height: calc(100vh - 120px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LoadingGif = styled.img`
    width: 60px;
    display: block;
    object-fit: cover;
`;
// const FinanceSection=styled.div``;
// const FinanceSection=styled.div``;
// const FinanceSection=styled.div``;
// const FinanceSection=styled.div``;
// const FinanceSection=styled.div``;
