import React, { useState } from "react";
import { useEffect } from "react";
import Title from "../Title";

export default function Welcome() {
    const myDate = new Date();
    const hrs = myDate.getHours();
    const [greet, setGreet] = useState("");

    useEffect(() => {
        if (hrs < 12) {
            setGreet("Good Morning");
        } else if (hrs >= 12 && hrs < 16) {
            setGreet("Good Afternoon");
        } else if (hrs >= 16 && hrs <= 24) {
            setGreet("Good Evening");
        }
    }, []);

    return (
        <Title fontSize="21px" fontWeight="300">
            {greet},{" "}
        </Title>
    );
}
