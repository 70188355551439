import { useEffect, useState, useContext } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import UploadInput from "../../components/UploadInput/UploadInput";
import { AddCampusService } from "../../service/campus";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { baseConfig } from "./../../utils/config";
import { Context } from "../../contexts/Store";

function AddCampus() {
	const [file, setFile] = useState(null);
	const history = useHistory();
	const [focusElement, setFocusElement] = useState("");
	const [isError, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [secondaryPhone, setSecondaryPhone] = useState("");
	const [address1, setAddress1] = useState("");
	const [address2, setAddress2] = useState("");
	const [city, setCity] = useState("");
	const [states, setStates] = useState("");
	const [country, setCountry] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [image, setImage] = useState(null);
	const [fieldName, setFieldName] = useState("");
	const [emailValidation, setEmailValidation] = useState(false);
	const [id, setId] = useState("");

	const [form, setFormValues] = useState({});
	const { state } = useContext(Context);

	const validate = (text) => {
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
		if (reg.test(text) === false) {
			setEmailValidation(false);
			setEmail(text);
			return false;
		} else {
			setEmail(text);
			setEmailValidation(true);
		}
	};

	const handleChangeValue = (key, value) => {
		setFormValues({ ...form, [key]: value });
	};

	const handleSubmit = async () => {
		let data = await AddCampusService({
			...form,
			image: file ? file[0] : "",
		});
		if (data.StatusCode === 6000) {
			history.push("/superadmin/list-campus");
		} else {
			// alert(data.data.message);
		}
	};

	const handleSubmitForm = () => {
		let accessToken = state.user_details.access_token;
		var formdata = new FormData();
		formdata.append("name", name);
		formdata.append("email", email);
		formdata.append("phone", phone);
		formdata.append("secondary_phone", secondaryPhone);
		formdata.append("address_line_1", address1);
		formdata.append("address_line_2", address2);
		formdata.append("city", city);
		formdata.append("state", states);
		formdata.append("country", country);
		formdata.append("zip_code", zipCode);
		if (file !== null) {
			formdata.append("image", file[0]);
		}

		baseConfig
			.post("/campus/campus-list/", formdata, {
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				let { StatusCode, data, message } = response.data;
				if (StatusCode == 6000) {
					history.push("/superadmin/list-campus/");
				} else {
					alert(data.data.message);
				}
			})
			.catch((error) => {
				alert(error.response.data.data.message);
			});
	};

	const getCampusId = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/campus/get-campus-id/", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setId(data.campus_id);
				} else {
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		getCampusId();
	}, []);

	return (
		<Wrapper>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					marginBottom: "30px",
					alignItems: "center",
				}}
			>
				<div style={{ display: "flex", margin: "0 12px" }}>
					<Title fontSize="35x">Add Campus</Title>
					{/* <Text
            style={{
              margin: "12px 23px",
              fontFamily: "Inter",
              fontWeight: "500",
            }}
            color="grey"
            fontSize="18px"
          >
            Admins / Add campus admin
          </Text> */}
				</div>

				<div>
					<Button
						href="#"
						style={{
							display: "flex",
							alignItems: "center",
							margin: "0 22px",
						}}
						backgroundColor="white"
						fontSize="15px"
						padding="18px 57px"
						onClick={(e) => {
							e.preventDefault();
							const form = document.getElementById("form");
							form.reset();
							setFormValues({});
							setFile(null);
						}}
					>
						<Text color="#EE1451" fontSize="15px">
							Clear Form
						</Text>
					</Button>
				</div>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<Container style={{ flexWrap: "wrap " }} width="100%">
					<form id="form">
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<Input
								type="text"
								name="Campus ID (Auto generated)"
								value={id}
								disabled
							/>
							<Input
								fieldName={fieldName}
								onChange={(value) =>
									// handleChangeValue("name", value)
									{
										setName(value);
										setError(false);
									}
								}
								type="text"
								name="Campus Name"
								isError={isError}
								errorMessage={errorMessage}
								// style={{
								// 	borderColors:
								// 		focusElement === "username"
								// 			? "#ff6f54"
								// 			: "#c1c1c1",
								// }}
								// onFocus={() => {
								// 	setFocusElement("username");
								// }}
							/>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: "20px",
							}}
						>
							<Input
								fieldName={fieldName}
								isError={isError}
								errorMessage={errorMessage}
								onChange={(value) =>
									// handleChangeValue("email", value)
									{
										validate(value);
										setError(false);
									}
								}
								type="email"
								name="Email ID"
								// invalid
								// width="49%"
							/>

							<Input
								fieldName={fieldName}
								isError={isError}
								errorMessage={errorMessage}
								onChange={(value) =>
									// handleChangeValue("phone", value)
									{
										setPhone(value);
										setError(false);
									}
								}
								type="text"
								name="Phone Number"
							/>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: "20px",
							}}
						>
							<Input
								fieldName={fieldName}
								isError={isError}
								errorMessage={errorMessage}
								onChange={(value) =>
									// handleChangeValue("secondary_phone", value)
									{
										setSecondaryPhone(value);
										setError(false);
									}
								}
								type="text"
								name="Phone No2"
							/>
							<Input
								fieldName={fieldName}
								isError={isError}
								errorMessage={errorMessage}
								onChange={(value) =>
									// handleChangeValue("address_line_1", value)
									{
										setAddress1(value);
										setError(false);
									}
								}
								type="text"
								name="Address line1"
							/>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: "20px",
							}}
						>
							<Input
								fieldName={fieldName}
								isError={isError}
								errorMessage={errorMessage}
								onChange={(value) =>
									// handleChangeValue("address_line_2", value)
									{
										setAddress2(value);
										setError(false);
									}
								}
								type="text"
								name="Address line2"
							/>
							<Input
								fieldName={fieldName}
								isError={isError}
								errorMessage={errorMessage}
								onChange={(value) =>
									// handleChangeValue("city", value)
									{
										setCity(value);
										setError(false);
									}
								}
								type="text"
								name="City"
							/>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: "20px",
							}}
						>
							<Input
								fieldName={fieldName}
								isError={isError}
								errorMessage={errorMessage}
								onChange={(value) =>
									// handleChangeValue("state", value)
									{
										setStates(value);
										setError(false);
									}
								}
								type="text"
								name="State"
							/>
							<Input
								fieldName={fieldName}
								isError={isError}
								errorMessage={errorMessage}
								onChange={(value) =>
									// handleChangeValue("country", value)
									{
										setCountry(value);
										setError(false);
									}
								}
								type="text"
								name="Country"
							/>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: "20px",
							}}
						>
							<Input
								fieldName={fieldName}
								isError={isError}
								errorMessage={errorMessage}
								onChange={(value) =>
									// handleChangeValue("zip_code", value)
									{
										setZipCode(value);
										setError(false);
									}
								}
								type="text"
								name="ZIP Code"
							/>
							<UploadInput
								file={file}
								setFile={setFile}
								isError={isError}
								fieldName={fieldName}
								name="Upload Image"
							/>
						</div>
						<div
							style={{
								display: "flex",
								marginTop: "30px",
								justifyContent: "flex-end",
							}}
						>
							<Button
								href="#"
								bacerkgroundColor="#5A2D8F"
								fontSize="15px"
								padding="21px 90px"
								fontWeight="500"
								onClick={(e) => {
									if (name === "") {
										setError(true);
										setFieldName("Campus Name");
										setErrorMessage(
											"This field is required"
										);
									} else if (emailValidation === false) {
										setError(true);
										setFieldName("Email ID");
										setErrorMessage(
											"Please enter a valid email"
										);
									} else if (phone === "") {
										setError(true);
										setFieldName("Phone Number");
										setErrorMessage(
											"Enter your phone number"
										);
									} else if (secondaryPhone === "") {
										setError(true);
										setFieldName("Phone No2");
										setErrorMessage(
											"Enter your phone number"
										);
									} else if (address1 === "") {
										setError(true);
										setFieldName("Address line1");
										setErrorMessage("Enter your address");
									} else if (address2 === "") {
										setError(true);
										setFieldName("Address line2");
										setErrorMessage("Enter your address");
									} else if (city === "") {
										setError(true);
										setFieldName("City");
										setErrorMessage("Enter your city");
									} else if (states === "") {
										setError(true);
										setFieldName("State");
										setErrorMessage("Enter your state");
									} else if (country === "") {
										setError(true);
										setFieldName("Country");
										setErrorMessage("Enter your country");
									} else if (zipCode === "") {
										setError(true);
										setFieldName("ZIP Code");
										setErrorMessage("Enter your ZIP Code");
									} else if (file === null) {
										setError(true);
										setFieldName("Upload Image");
									} else {
										setError(false);
										e.preventDefault();
										handleSubmitForm();
									}
								}}
							>
								<Text color="white" fontSize="20px">
									Save
								</Text>
							</Button>
						</div>
					</form>
				</Container>
			</div>
		</Wrapper>
	);
}

export default AddCampus;
