import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import React, { useEffect, useState, useContext } from "react";
import { Context } from "./../../contexts/Store";
import Text from "../../components/Text";
import Container from "../../components/Container";
import styled from "styled-components";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import UploadInput from "../../components/UploadInput/UploadInput";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import { baseConfig } from "./../../utils/config";

function AddDesignation() {
	const [file, setFile] = useState(null);
	const history = useHistory();
	const { state } = useContext(Context);
	const [departmentList, setDepartmentList] = useState([]);
	const [selectedDepartment, setSelectedDepartment] = useState("");

	const [id, setId] = useState("");
	const [isError, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [secondaryPhone, setSecondaryPhone] = useState("");
	const [address1, setAddress1] = useState("");
	const [address2, setAddress2] = useState("");
	const [city, setCity] = useState("");
	const [states, setStates] = useState("");
	const [country, setCountry] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [fieldName, setFieldName] = useState("");
	const [emailValidation, setEmailValidation] = useState(false);

	const validate = (text) => {
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
		if (reg.test(text) === false) {
			setEmailValidation(false);
			setEmail(text);
			return false;
		} else {
			setEmail(text);
			setEmailValidation(true);
		}
	};

	const getDesignationId = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/campus/get-designation-id/", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setId(data.designation_id);
				} else {
				}
			})
			.catch((error) => {});
	};

	const getDepartment = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/campus/departments/", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setDepartmentList(data.data);
				} else {
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		getDesignationId();
		getDepartment();
	}, []);

	const handleSubmitForm = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.post(
				"/campus/designations/",
				{
					name: name,
					department: selectedDepartment,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				let { StatusCode, message } = response.data;
				if (StatusCode == 6000) {
					getDesignationId();
					setName("");
					setSelectedDepartment("");
				} else {
				}
			})
			.catch((error) => {
				alert(error);
			});
	};

	return (
		<Wrapper>
			<Top>
				<TopLeft>
					<Title fontSize="35px">Add Designations</Title>
					<Text
						fontSize="20px"
						fontWeight="400"
						color="#000"
						style={{ marginTop: "10px" }}
					>
						Designations / Add Designations
					</Text>
				</TopLeft>
				<TopRight
					onClick={() => {
						getDesignationId();
						setName("");
						setSelectedDepartment("");
					}}
				>
					<Text
						fontSize="16px"
						fontWeight="400"
						color="red"
						style={{ marginLeft: "5px" }}
					>
						Clear Form
					</Text>
				</TopRight>
			</Top>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<Container
					style={{ flexWrap: "wrap " }}
					width="100%"
					margin="10px 0"
				>
					<form id="form">
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<Input
								type="text"
								name="Designation ID (auto generated)"
								value={id}
								disabled
							/>
							<Input
								fieldName={fieldName}
								onChange={(value) => {
									setName(value);
									setError(false);
								}}
								type="text"
								name="Designation Name"
								isError={isError}
								errorMessage={errorMessage}
								value={name}
							/>
						</div>

						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: "20px",
							}}
						>
							<SelectInput
								name="Choose department"
								type="text"
								options={departmentList}
								selectName={"name"}
								selectValue={"id"}
								placeholder="Choose department"
								onChange={(value) => {
									setSelectedDepartment(value);
									setError(false);
								}}
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
								defaultValue={"Choose department"}
								value={selectedDepartment}
							/>
							{/* <Input
								fieldName={fieldName}
								isError={isError}
								errorMessage={errorMessage}
								onChange={(value) => {
									setAddress1(value);
									setError(false);
								}}
								type="text"
								name="Choose Sub-department"
							/> */}
						</div>

						<div
							style={{
								display: "flex",
								marginTop: "30px",
							}}
						>
							<Button
								bacerkgroundColor="#5A2D8F"
								fontSize="15px"
								padding="21px 90px"
								fontWeight="500"
								onClick={(e) => {
									if (name === "") {
										setError(true);
										setFieldName("Designation Name");
										setErrorMessage(
											"This field is required"
										);
									} else if (
										selectedDepartment === "" ||
										selectedDepartment ===
											"Choose department"
									) {
										setError(true);
										setFieldName("Choose department");
										setErrorMessage(
											"Please select a department"
										);
									} else {
										setError(false);
										e.preventDefault();
										handleSubmitForm();
									}
								}}
							>
								<Text color="white" fontSize="20px">
									Add
								</Text>
							</Button>
							<Button
								backgroundColor="#F4F4F4"
								style={{ marginLeft: "15px" }}
								onClick={() => {
									history.push(
										"/campus-admin/all-designation/"
									);
								}}
							>
								<Text color="#522e92" fontSize="20px">
									Cancel
								</Text>
							</Button>
						</div>
					</form>
				</Container>
			</div>
		</Wrapper>
	);
}

export default AddDesignation;

const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const TopLeft = styled.div``;
const TopRight = styled.div`
	display: flex;
	align-items: center;
	background-color: #fff;
	height: 50px;
	border-radius: 3px;
	padding: 0 20px;
	cursor: pointer;
`;
