import React, { useState, useRef } from "react";
import styled from "styled-components";
import EMIStep1Form from "./EMIStep1Form";
import EMIStep2 from "./EMIStep2";
import selectedRadioIcon from "../../../assets/images/radio-s2.svg";
import notSelectedRadioIcon from "../../../assets/images/radio-n-s2.svg";

export default function EMIstep1({
	setModal,
	programId,
	getSingleStudentDetails,
    emiNotAddedCourses,
    selectedStudentProgram,
    setSelectedStudentProgram,
    handleCloseEmiModal,
    handleCloseEmiModalWIthoutConfirmation,
}) {
	const [stepOneValue, setStepOneValue] = useState({
		registration_fee: 0,
		total_installment_amount: 0,
		starting_date: "",
		total_period: 1,
		course_fee: selectedStudentProgram.course_fee ? selectedStudentProgram.course_fee : 0,
		vat: 5,
	});

	const [step, setStep] = useState(1);
	const emiRef = useRef(null);
	const [alert, setAlert] = useState("");
	const alertRef = useRef(null);

	const clearForm = () => {
		setStepOneValue({
			registration_fee: "",
			total_installment_amount: "",
			starting_date: "",
			total_period: "",
		});
	};

	const handleAlert = (message) => {
		setAlert(message);
		if (alertRef.current) {
			alertRef.current.focus();
		}
		setTimeout(() => {
			setAlert("");
		}, 3000);
	};

	const areTruthy = Object.values(stepOneValue).every(value => value !== undefined && value !== null && value !== "" && value !== NaN);

	return (
		<MainContainer>
			<TopContainer>
				<Title>Add installment</Title>
				{step === 1 && (
					<ClearButton onClick={clearForm}>Clear Form</ClearButton>
				)}
			</TopContainer>
			<StepContainer>
				<Line1 active={step === 2}>
					<Round1 className={step > 1 ? "active" : ""}>Step 1</Round1>
				</Line1>
				<Line2>
					<Round2 className={step === 2 ? "active" : ""}>Step 2</Round2>
				</Line2>
			</StepContainer>
			<div ref={alertRef} tabIndex={-1}>
				{alert && (
						<Alert>
							<span>{alert}</span>
							<CloseButton onClick={() => setAlert("")}>&times;</CloseButton>
						</Alert>
				)}
			</div>
            {emiNotAddedCourses?.length > 0 && step == 1 && (
                <RadioItemsContainer style={{ width: "fit-content" }}>
                    {emiNotAddedCourses?.map((item, index) => (
                        <RadioItem
                            key={index}
                            selected={
                                selectedStudentProgram.id === item.id ? true : false
                            }
                            onClick={() => {
                                setSelectedStudentProgram(item);
                            }}
                        >
                            <RadioIcon
                                src={
                                    selectedStudentProgram.id === item.id
                                        ? selectedRadioIcon
                                        : notSelectedRadioIcon
                                }
                                alt="icon"
                            />
                            <RadioItemText>{item.course}</RadioItemText>
                        </RadioItem>
                    ))}
                </RadioItemsContainer>
            )}
			<div style={{ padding: "10px 0" }}>
				{step === 1 ? (
					<EMIStep1Form
						setStepOneValue={setStepOneValue}
						stepOneValue={stepOneValue}
						setAlert={handleAlert}
					/>
				) : (
					<EMIStep2
						stepOneValue={stepOneValue}
						ref={emiRef}
						programId={selectedStudentProgram.id}
						setModal={setModal}
                        handleCloseEmiModal={handleCloseEmiModal}
						courseFee={selectedStudentProgram.course_fee}
						getSingleStudentDetails={getSingleStudentDetails}
						setAlert={handleAlert}
                        handleCloseEmiModalWIthoutConfirmation={
                            handleCloseEmiModalWIthoutConfirmation
                        }
					/>
				)}
			</div>
			<Bottom>
				<CancelButton onClick={() => handleCloseEmiModal()}>Cancel</CancelButton>
				<RightButtons>
					{step > 1 && (
						<PreviousButton onClick={() => {
							setStep(step - 1);
							setAlert("");
						}}>
							{"<"} Previous{" "}
						</PreviousButton>
					)}
					{step === 1 ? (
						<NextButton
							onClick={() => {
								if (!stepOneValue.course_fee || parseFloat(stepOneValue.course_fee) === 0) {
									handleAlert("Please enter correct 'Total Fee'.");
								}
								else if (areTruthy) {
									setStep(step + 1);
									setAlert("");
								}
								else {
									handleAlert("Please fill the required fields.");
								}
							}}
						>
							Next {">"}
						</NextButton>
					) : (
						<NextButton onClick={() => emiRef.current.handleEMI()}>
							Save
						</NextButton>
					)}
				</RightButtons>
			</Bottom>
		</MainContainer>
	);
}

const MainContainer = styled.div`
	padding: 30px;
`;

const TopContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const Title = styled.p`
	color: #000000;
	font-weight: 500;
	letter-spacing: 0.01em;
	font-size: 24px;
`;

const ClearButton = styled.button`
	letter-spacing: 0.04em;
	color: #f71f1f;
	font-size: 16px;
	line-height: 160%;
	cursor: pointer;
	font-weight: 500;
	font-size: 16px;
`;

const StepContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 20px;
`;

const Round1 = styled.p`
	border-radius: 29px;
	padding: 5px 12px;
	color: #010101;
	border: 1px solid #917afd;
	font-size: 16px;
	width: 120px;
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-content: center;
	&.active {
		background: #917afd;
		color: #fff;
		border: 1px solid #917afd;
	}
`;

const Round2 = styled.p`
	border-radius: 29px;
	padding: 5px 12px;
	color: #010101;
	border: 1px solid #917afd;
	font-size: 16px;
	width: 120px;
	font-weight: 400;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #010101;
	border: 1px solid #e7e7e7;
	background: #fff;
	&.active {
		border: 1px solid #917afd;
		background: #917afd;
		color: #fff;
	}
`;

const Line1 = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	&::after {
		content: "";
		border: 1px solid #e7e7e7;
		border-color: ${(props) => (props.active ? "#917AFD" : "#e7e7e7")};
		width: 88px;
	}
	&::before {
		content: "";
		border: 1px solid #917afd;
		width: 100%;
	}
`;

const Line2 = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	&::after {
		content: "";
		border: 1px solid #e7e7e7;
		width: 100%;
	}
`;

const Bottom = styled.div`
    bottom: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const CancelButton = styled.div`
	color: #f71f1f;
	font-weight: 500;
	cursor: pointer;
	font-size: 16px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const RightButtons = styled.div`
	display: flex;
	align-items: center;
`;

const PreviousButton = styled.div`
	background: #fff;
	border-radius: 8px;
	border: 1px solid #917afd;
	color: #000;
	font-weight: 500;
	height: 40px;
	font-size: 18px;
	padding: 3px 12px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
`;

const NextButton = styled.div`
	background: #917afd;
	border: 1px solid #917afd;
	border-radius: 8px;
	color: #ffffff;
	font-weight: 500;
	height: 48px;
	box-sizing: border-box;
	font-size: 18px;
	padding: 12px 24px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const RadioItemsContainer = styled.div`
	border: 1px solid #d7d7d7;
	border-radius: 12px;
	margin-bottom: 20px;
	padding: 18px;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	align-items: center;
	background: #f8fafc;
`;

const RadioItem = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
	cursor: pointer;
`;

const RadioIcon = styled.img`
	width: 18px;
`;

const RadioItemText = styled.span`
	display: block;
	font-size: 16px;
`;
const Alert = styled.div`
	background-color: #f8d7da;
	color: #721c24;
	padding: 10px;
	border: 1px solid #f5c6cb;
	border-radius: 4px;
	margin-bottom: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const CloseButton = styled.span`
	cursor: pointer;
	font-weight: bold;
	font-size: 20px;
	margin-left: 10px;
`;
