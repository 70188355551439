import React, { useEffect, useState, useContext } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import visible from "../../assets/images/visible.svg";
import { useHistory } from "react-router-dom";
import { AddCampusAdminService } from "../../service/campusAdmin";
import { ListCampusService } from "../../service/campus";
import { validatePassword } from "./../../helpers/Functions";
import { baseConfig } from "./../../utils/config";
import { Context } from "../../contexts/Store";

function AddCampusAdmin() {
    const history = useHistory();
    const [campuses, setCampuses] = useState([]);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [fieldName, setFieldName] = useState("");
    const [id, setId] = useState("");

    const [campusName, setCampusName] = useState("");
    const [email, setEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [emailValidation, setEmailValidation] = useState(false);
    const [passwordValidate, setPasswordValidate] = useState({});
    const { state } = useContext(Context);

    useEffect(() => {
        setPasswordValidate(validatePassword(password));
    }, [password]);

    const criteria = passwordValidate.is_strong_password;

    const [form, setFormValues] = useState({
        passwordShow: false,
        confirmPasswordShow: false,
    });

    const validate = (text) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(text) === false) {
            setEmailValidation(false);
            setEmail(text);
            return false;
        } else {
            setEmail(text);
            setEmailValidation(true);
        }
    };

    const getCampusList = async () => {
        let data = await ListCampusService(state);
        if (data.StatusCode === 6000) {
            setCampuses(data.data?.data);
        } else {
            alert(data.data.message);
        }
    };

    useEffect(() => {
        getCampusList();
    }, []);

    const handleChangeValue = (key, value) => {
        setFormValues({ ...form, [key]: value });
    };

    const handleSubmit = async () => {
        if (form["password"] !== form["confirmPassword"]) {
            alert("Incorrect password");
        }
        let data = await AddCampusAdminService({
            campusName,
            email,
            userName,
            password,
            state,
        });
        if (data.StatusCode === 6000) {
            history.push("/superadmin/campus-admins");
        } else if (data.StatusCode === 6001) {
            alert(data.data.message);
        }
    };

    const getAdminId = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/get-campus-admin-id/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setId(data.admin_id);
                } else {
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        getAdminId();
    }, []);

    return (
        <Wrapper>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "0 12px",
                    }}
                >
                    <Title fontSize="35x">Add Campus Admin</Title>
                    {/* <Text
                        style={{
                            marginTop: "5px",
                            fontFamily: "Inter",
                            fontWeight: "500",
                        }}
                        color="grey"
                        fontSize="18px"
                    >
                        Admins / Add campus admin
                    </Text> */}
                </div>

                <div>
                    <Button
                        href="#"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0 22px",
                        }}
                        backgroundColor="white"
                        fontSize="15px"
                        padding="18px 57px"
                        onClick={(e) => {
                            e.preventDefault();
                            const form = document.getElementById("form");
                            form.reset();
                            setFormValues({});
                            setCampusName("");
                            setEmail("");
                            setPassword("");
                            setConfirmPassword("");
                            setUserName("");
                        }}
                    >
                        <Text color="  #EE1451" fontSize="15px">
                            Clear Form
                        </Text>
                    </Button>
                </div>
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Container style={{ flexWrap: "wrap " }} width="100%">
                    <form id="form">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Input
                                onChange={(value) => setId(value)}
                                type="email"
                                name="Admin ID  (auto generated)"
                                value={id}
                                disabled
                            />
                            <SelectInput
                                name="Choose Campus"
                                type="text"
                                placeholder="Choose Campus"
                                onChange={(value) => {
                                    {
                                        setCampusName(value);
                                        setError(false);
                                    }
                                }}
                                options={campuses ? campuses : []}
                                selectName={"name"}
                                selectValue={"id"}
                                value={form?.campus}
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                defaultValue="Select a campus"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Input
                                onChange={(value) => {
                                    validate(value);
                                    setError(false);
                                }}
                                type="name"
                                name="Admin Mail ID"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                            />

                            <Input
                                onChange={(value) => {
                                    setUserName(value);
                                    setError(false);
                                }}
                                type="type"
                                name="Username"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "20px",
                                justifyContent: "space-between ",
                            }}
                        >
                            <Input
                                onClickVisible={() =>
                                    handleChangeValue(
                                        "passwordShow",
                                        !form?.passwordShow
                                    )
                                }
                                onChange={(value) => {
                                    setPassword(value);
                                    setError(false);
                                }}
                                type={form?.passwordShow ? "text" : "password"}
                                name="Admin Password"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                            />
                            <Input
                                onClickVisible={() =>
                                    handleChangeValue(
                                        "confirmPasswordShow",
                                        !form?.confirmPasswordShow
                                    )
                                }
                                onChange={(value) => {
                                    setConfirmPassword(value);
                                    setError(false);
                                }}
                                type={
                                    form?.confirmPasswordShow
                                        ? "text"
                                        : "password"
                                }
                                name="Confirm Admin Password"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                            />
                        </div>
                        <div style={{ display: "flex", marginTop: "21px" }}>
                            <Button
                                href="#"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                bacerkgroundColor="#5A2D8F"
                                fontSize="15px"
                                padding="21px 70px"
                                onClick={(e) => {
                                    if (
                                        campusName === "" ||
                                        campusName === "Select a campus"
                                    ) {
                                        setError(true);
                                        setFieldName("Choose Campus");
                                        setErrorMessage("Choose a campus");
                                    } else if (emailValidation === false) {
                                        setError(true);
                                        setFieldName("Admin Mail ID");
                                        setErrorMessage(
                                            "Please enter a valid email"
                                        );
                                    } else if (userName === "") {
                                        setError(true);
                                        setFieldName("Username");
                                        setErrorMessage(
                                            "Please enter username"
                                        );
                                    } else if (password.length < 8) {
                                        setError(true);
                                        setFieldName("Admin Password");
                                        setErrorMessage(
                                            "Be a minimum of 8 characters"
                                        );
                                    } else if (criteria === false) {
                                        setError(true);
                                        setFieldName("Admin Password");
                                        setErrorMessage(
                                            "Strong passwords consist of a combination of uppercase and lowercase letters, numbers and special symbols"
                                        );
                                    } else if (password !== confirmPassword) {
                                        setError(true);
                                        setFieldName("Confirm Admin Password");
                                        setErrorMessage(
                                            "Both password must be same"
                                        );
                                    } else {
                                        e.preventDefault();
                                        handleSubmit();
                                    }
                                }}
                            >
                                <Text color="white" fontSize="15px">
                                    Submit
                                </Text>
                            </Button>

                            <Button
                                onClick={() => {
                                    history.push("/superadmin/campus-admins/");
                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 22px",
                                }}
                                backgroundColor="#F4F4F4"
                                fontSize="15px"
                                padding="21px 70px"
                            >
                                <Text color=" #522e92" fontSize="15px">
                                    Cancel
                                </Text>
                            </Button>
                        </div>
                    </form>
                </Container>
            </div>
        </Wrapper>
    );
}

export default AddCampusAdmin;
