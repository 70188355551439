import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import React, { useEffect, useState, useContext } from "react";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import styled from "styled-components";
import { SettingsSystemDaydreamSharp } from "@material-ui/icons";
import loadingIcon from "../../assets/lottie/loading.gif";
import expandPic from "../../assets/images/fullscreen.png";
import {
	BarChart,
	Bar,
	CartesianGrid,
	XAxis,
	YAxis,
	ResponsiveContainer,
	Legend,
	LabelList,
} from "recharts";
import ExpandModal from "./ExpandModal";
import Welcome from "../../components/includes/Welcome";
import WelcomeMessage from "../../components/includes/WelcomeMessage";

function MainDashboard() {
	const { state, dispatch } = useContext(Context);
	const [dashboardDetails, setDashboardDetails] = useState({});
	const [teams, setTeams] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [isExpandModal, setIsExpandModal] = useState(false);

	const getDashboard = () => {
		setIsLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/general/sales-team-leader-dashboard/", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setDashboardDetails(data.data);
					setTeams(data.teams);

					const user_details = {
						// is_verified: true,
						// access_token: response.access,
						// role: response.userrole,
						team_Id: data?.data?.id,
					};

					dispatch({
						type: "UPDATE_USER_DETAILS",
						user_details,
					});
					setTimeout(() => {
						setIsLoading(false);
					}, 200);
				} else {
					setIsLoading(false);
					alert("Some error occured.Please try again later");
				}
			})
			.catch((error) => {
				setIsLoading(false);
				alert(error);
			});
	};

	useEffect(() => {
		getDashboard();
	}, []);

	const data = (canvas) => {
		const ctx = canvas.getContext("2d");
		return {
			labels: dashboardDetails?.teams?.map((item) => {
				return item?.name;
			}),
			datasets: [
				{
					label: "# of Votes",
					data: dashboardDetails?.teams?.map((item) => {
						return item?.target_achieved;
					}),
					backgroundColor: ["#aaa3f5"],
					// barPercentage: 0.5,
					// barThickness: 15,
					// maxBarThickness: 13,
					// minBarLength: 8,
				},
			],
		};
	};

	const options = {
		maintainAspectRatio: false,
		indexAxis: "y",
		responsive: true,
		scales: {
			x: {
				grid: {
					display: true,
					borderDash: [5, 10],
					color: "#DADADA",
				},
				ticks: {
					crossAlign: "near",
				},
			},
			y: {
				grid: {
					display: true,
				},
				ticks: {
					padding: 20,
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltips: {
				enabled: false,
			},
		},
	};

	const digit = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

	const DetailCard = ({ text, icon, count }) => {
		return (
			<SmallCard background="#f9f9f9">
				<SmallTop>
					<Count className="card">{count}</Count>
					<div style={{ height: "24px", width: "24px" }}>
						<img
							style={{ height: "100%", width: "100%" }}
							src={icon}
							alt="icon"
						/>
					</div>
				</SmallTop>
				<SmallBottom>
					<ContentText fontSize="13px">{text}</ContentText>
				</SmallBottom>
			</SmallCard>
		);
	};

	return (
		<>
			{!isLoading ? (
				<Wrapper className="assigned">
					{/* <TitleWrapper>
                        <Welcome />
                        <Title
                            fontSize="32px"
                            fontWeight="500"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                // marginBottom: "20px",
                                marginLeft: "6px",
                            }}
                        >
                            {dashboardDetails?.name}
                        </Title>
                    </TitleWrapper>
                    <WelcomeMessage /> */}

					<TopContainer>
						<Left>
							<RankCard>
								<ProfileDp
									src={
										dashboardDetails?.leader_data?.image
											? dashboardDetails?.leader_data
													?.image
											: require("./../../assets/images/rank-box.svg")
													.default
									}
									alt="icon"
								/>
								<Count fontSize="32px">
									{dashboardDetails.rank}
								</Count>
								<ContentText>Team Ranking</ContentText>
							</RankCard>
							<SmallContainer>
								<SmallCard>
									<SmallTop>
										<Count>
											{/* {dashboardDetails.target_achieved} % */}

											{`${
												Math.round(
													dashboardDetails.target_achieved *
														100
												) / 100
											}%`}
										</Count>
										<IconDiv>
											<RankIcon
												src={
													require("./../../assets/images/goal.svg")
														.default
												}
												alt="icon"
											/>
										</IconDiv>
									</SmallTop>
									<SmallBottom>
										<ContentText>
											Target Achieved
										</ContentText>
									</SmallBottom>
								</SmallCard>

								<SmallCard>
									<SmallTop>
										<Count>
											AED {dashboardDetails.target}
										</Count>
										<IconDiv>
											<RankIcon
												src={
													require("./../../assets/images/new-target.svg")
														.default
												}
												alt="icon"
											/>
										</IconDiv>
									</SmallTop>
									<SmallBottom>
										<ContentText>Target</ContentText>
									</SmallBottom>
								</SmallCard>
								<SmallCard>
									<SmallTop>
										<Count>
											{
												dashboardDetails.total_lead_assigned
											}
										</Count>
										<IconDiv>
											<RankIcon
												src={
													require("./../../assets/images/leads-icon.svg")
														.default
												}
												alt="icon"
											/>
										</IconDiv>
									</SmallTop>
									<SmallBottom>
										<ContentText>Total Leads</ContentText>
									</SmallBottom>
								</SmallCard>
								<SmallCard>
									<SmallTop>
										<Count>
											AED{" "}
											{dashboardDetails.revenue_generated}
										</Count>
										<IconDiv>
											<RankIcon
												src={
													require("./../../assets/images/admission-icon.svg")
														.default
												}
												alt="icon"
											/>
										</IconDiv>
									</SmallTop>
									<SmallBottom>
										<ContentText>
											Revenue Generated
										</ContentText>
									</SmallBottom>
								</SmallCard>
								{/* <SmallCard>
							<SmallTop>
								<Count>
									{dashboardDetails.total_lead_need_followup}
								</Count>
								<IconDiv>
									<RankIcon
										src={
											require("./../../assets/images/follow-icon.svg")
												.default
										}
										alt="icon"
									/>
								</IconDiv>
							</SmallTop>
							<SmallBottom>
								<ContentText>Leads in followup</ContentText>
							</SmallBottom>
						</SmallCard> */}
							</SmallContainer>
						</Left>
						<Right>
							<TitleBar>
								<TitleLeft>
									<TitleText>Team Performance</TitleText>
								</TitleLeft>
								<TitleRight>
									<ExpandIcon
										src={expandPic}
										alt="icon"
										onClick={() => {
											setIsExpandModal(true);
										}}
									/>
								</TitleRight>
							</TitleBar>

							<ResponsiveContainer width="98%" height={300}>
								<BarChart
									data={teams}
									margin={{
										top: 20,
										right: 0,
										left: 0,
										bottom: 60,
									}}
								>
									<XAxis
										dataKey="name"
										tick={{ fontSize: 12 }}
										interval={0}
										angle={-90}
										textAnchor="end"
									/>
									<YAxis
										// dataKey="count"
										tick={{ fontSize: 12 }}
										domain={[0, 100]}
									/>
									<Legend
										wrapperStyle={{
											top: 0,
											left: 25,
										}}
									/>
									<Bar
										dataKey="target_achieved"
										fill="#794BD1"
										isAnimationActive={false}
										barSize={30}
										barGap={2}
										// barCategoryGap={2}
										minPointSize={5}
										name="Target Achieved"
									>
										<LabelList
											dataKey="target_achieved"
											// dataKey={
											//     Math.round(
											//         dashboardDetails.target_achieved *
											//             100
											//     ) / 100
											// }
											position="top"
											style={{
												fill: "#000",
												fontSize: 15,
											}}
										/>
									</Bar>
								</BarChart>
							</ResponsiveContainer>
						</Right>
					</TopContainer>
					<MiddleContainer>
						<TitleText
							style={{ width: "100%", marginBottom: "10px" }}
						>
							Admission Consultant Performance
						</TitleText>
						<ResponsiveContainer width="98%" height={480}>
							<BarChart
								data={dashboardDetails?.members_data}
								margin={{
									top: 20,
									right: 0,
									left: 0,
									bottom: 70,
								}}
							>
								<XAxis
									dataKey="name"
									tick={{ fontSize: 13 }}
									interval={0}
									angle={-90}
									textAnchor="end"
								/>
								<YAxis
									// dataKey="count"
									tick={{ fontSize: 13 }}
									domain={[0, 100]}
								/>
								<Legend
									wrapperStyle={{
										top: 0,
										left: 25,
									}}
								/>
								<Bar
									dataKey="target_achieved"
									fill="#794BD1"
									isAnimationActive={false}
									barSize={30}
									barGap={2}
									// barCategoryGap={2}
									minPointSize={5}
									name="Target Achieved"
								>
									<LabelList
										dataKey="target_achieved"
										// dataKey={
										//     Math.round(
										//         dashboardDetails.target_achieved *
										//             100
										//     ) / 100
										// }
										position="top"
										style={{
											fill: "#000",
											fontSize: 15,
										}}
									/>
								</Bar>
							</BarChart>
						</ResponsiveContainer>
					</MiddleContainer>
					<BottomContainer>
						<ContentText fontSize="18px">Team Members</ContentText>
						{dashboardDetails?.members_data?.map((data) => (
							<CardContainer>
								<CardLeft>
									<ProfileDiv>
										{data.image === null ? (
											<ProfileImg
												src={
													require("./../../assets/images/profile-icon.svg")
														.default
												}
												alt="image"
											/>
										) : (
											<ProfileImg
												src={data.image}
												alt="image"
											/>
										)}
										<ProfileDetails>
											<ProfileTop>
												<Rank>{data.rank}</Rank>
												<RankText>Rank</RankText>
											</ProfileTop>
											<ProfileBottom>
												<Name>{data.name}</Name>
											</ProfileBottom>
										</ProfileDetails>
									</ProfileDiv>
								</CardLeft>
								<CardRight>
									<DetailCard
										text="Target Achieved"
										icon={
											require("./../../assets/images/goal.svg")
												.default
										}
										count={`${
											Math.round(
												data.target_achieved * 100
											) / 100
										}%`}
									/>
									<DetailCard
										text="Revenue Generated"
										icon={
											require("./../../assets/images/goal.svg")
												.default
										}
										// count={`${
										//     Math.round(
										//         data.target_achieved * 100
										//     ) / 100
										// }%`}
										count={`AED ${data.revenue_generated}`}
									/>
									<DetailCard
										text="Target"
										icon={
											require("./../../assets/images/new-target.svg")
												.default
										}
										count={`AED ${data.target}`}
									/>
									<DetailCard
										text="Admissions"
										icon={
											require("./../../assets/images/admission-icon.svg")
												.default
										}
										count={data.total_lead_admission}
									/>
									<DetailCard
										text="Leads"
										icon={
											require("./../../assets/images/leads-icon.svg")
												.default
										}
										count={data.total_lead_assigned}
									/>
									{/* <DetailCard
								text="Network Leads"
								icon={
									require("./../../assets/images/leads-icon.svg")
										.default
								}
								count={data.network_leads}
							/> */}
									<DetailCard
										text="Network Leads"
										icon={
											require("./../../assets/images/network-lead.svg")
												.default
										}
										count={data.network_leads}
									/>
									<DetailCard
										text="Meetings"
										icon={
											require("./../../assets/images/meeting.svg")
												.default
										}
										count={data.total_meetings}
									/>
									<DetailCard
										text="Invalid Leads"
										icon={
											require("./../../assets/images/invalid-lead-1.svg")
												.default
										}
										count={data.total_lead_invalid}
									/>
								</CardRight>
							</CardContainer>
						))}
					</BottomContainer>
				</Wrapper>
			) : (
				<LoaderContainer>
					<LoadingGif src={loadingIcon} alt="icon" />
				</LoaderContainer>
			)}
			{isExpandModal && (
				<ExpandModal
					teams={teams}
					setIsExpandModal={setIsExpandModal}
				/>
			)}
		</>
	);
}

export default MainDashboard;

const TopContainer = styled.div`
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const Left = styled.div`
	width: 48%;
	/* display: flex;
	align-items: center; */
	display: grid;
	grid-template-columns: 1fr 2.5fr;
	grid-gap: 20px;
	height: 350px;
`;

const RankCard = styled.div`
	background: #fff;
	padding: 20px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`;
const IconDiv = styled.div`
	height: 25px;
	width: 25px;
	background: #ebf5ff;
	border-radius: 50%;
	padding: 10px;
`;
const RankIcon = styled.img`
	height: 100%;
	width: 100%;
`;
const Count = styled.h3`
	font-size: ${(props) => (props.fontSize ? props.fontSize : "22px")};
	color: #8561c6;
	margin-bottom: 5px;
	font-weight: 500;

	&.card {
		font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
	}
`;
const ContentText = styled.p`
	font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
	color: #000;
`;
const Right = styled.div`
	width: 48%;
	background: #fff;
	border-radius: 8px;
	padding: 10px;
	height: 350px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
`;
const TitleBar = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const TitleLeft = styled.div``;
const TitleText = styled.div``;
const TitleRight = styled.div`
	display: flex;
	align-items: center;
	font-size: 20px;
	font-weight: 600;
	/* width: 100%; */
`;
const RoundBox = styled.div`
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #a574ff;
	margin-right: 6px;
`;
const TitleTextSub = styled.div``;
const RightContainer = styled.div`
	padding: 0 20px 0px 0;
	height: 200px;
	overflow-y: scroll;
`;
const SingleItems = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
`;
const ColorSpan = styled.div`
	background: #a574ff;
	height: 8px;
	border-radius: 8px;
	width: ${(props) => (props.width ? props.width : "0%")};
`;
const SingleName = styled.div`
	width: 20%;
	text-align: right;
	color: #787878;
	overflow: hidden;
	font-size: 14px;
`;
const DigitBox = styled.div`
	display: flex;
	align-items: center;
	/* justify-content: space-around; */
	margin-left: 10px;
`;
const DigitSingle = styled.h4`
	width: 10%;
	text-align: right;
	color: #787878;
`;
const SingleItemRight = styled.div`
	width: 75%;
	&.bar {
		display: flex;
		align-items: center;
	}
`;

const Percentage = styled.span`
	color: #808080;
	font-size: 13px;
	margin-left: 3px;
`;
const SmallContainer = styled.div`
	/* display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap; */
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 1fr 1fr;
`;
const SmallCard = styled.div`
	background: ${(props) => (props.background ? props.background : "#fff")};
	padding: 15px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* width: 31%; */
`;
const SmallTop = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 5px;
`;
const SmallBottom = styled.div``;
const MiddleContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	background: #fff;
	margin-bottom: 20px;
	border-radius: 8px;
`;
const BottomContainer = styled.div``;

const CardContainer = styled.div`
	padding: 10px;
	background: #ffffff;
	border: 1px solid #d7d7d7;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
`;
const CardLeft = styled.div`
	width: 160px;
`;
const CardRight = styled.div`
	/* width: 80%; */
	width: calc(100% - 170px);
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-gap: 10px;

	/* display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-auto-flow: column;
    grid-auto-columns: minmax(160px, 1fr);
    overflow-x: auto; */
`;
const ProfileDiv = styled.div`
	display: flex;
	align-items: center;
`;
const ProfileImg = styled.img`
	height: 50px;
	width: 50px;
	border-radius: 50%;
	object-fit: cover;
`;
const ProfileDetails = styled.div`
	margin-left: 10px;
`;
const ProfileTop = styled.div`
	margin-bottom: 15px;
`;
const Rank = styled.span`
	color: #4caa02;
	font-size: 16px;
	background: #eaffda;
	border-radius: 17px;
	padding: 8px;
	margin-right: 10px;
`;
const RankText = styled.span`
	color: #999999;
	font-size: 16px;
`;
const ProfileBottom = styled.div``;
const Name = styled.h4`
	color: #000000;
	font-size: 18px;
	font-weight: 500;
`;
const ProfileDp = styled.img`
	width: 105px;
	height: 105px;
	border-radius: 50%;
	display: block;
	object-fit: cover;
`;
const LoaderContainer = styled.div`
	height: calc(100vh - 120px);
	display: flex;
	justify-content: center;
	align-items: center;
`;
const LoadingGif = styled.img`
	width: 60px;
	display: block;
	object-fit: cover;
`;
const ExpandIcon = styled.img`
	display: block;
	width: 16px;
	object-fit: cover;
	cursor: pointer;
`;
const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
`;
