import styled from "styled-components";

export default styled.h3`
    font-family: "Inter";
    font-size: ${(props) => (props.fontSize ? props.fontSize : "25px")};
    color: ${(props) => (props.color ? props.color : "#505887")};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "600")};
    text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};

    @media only screen and (max-width: 1280px) {
        font-size: ${(props) =>
            (props.type === "buttonText" && "16px") ||
            (props.type === "dashboardItemNumber" && "22px")};
    }

    /* @media only screen and (max-width: 1440px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  } */
`;
