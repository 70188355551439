import React from "react";
import styled from "styled-components";
import checked from "../../assets/images/checked-modal.svg";
import failed from "../../assets/images/failed-modal.svg";

export default function PopupMessageHandler({
    handleMessagePopup,
    status,
    title,
    message,
}) {
    return (
        <MainContainer className={status === "success" ? "" : "failed"}>
            {/* <CloseContainer onClick={handleMessagePopup}>
                <CloseImage src={closeIcon} alt="Close" />
            </CloseContainer> */}
            <MessageContainer>
                <LeftContainer>
                    <ResponseImage src={status === "success" ? checked : failed} alt={status} />
                </LeftContainer>
                <RightContainer>
                    <Message>{title}</Message>
                    <Description>{message}</Description>
                </RightContainer>
            </MessageContainer>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    position: fixed;
    top: 40px;
    right: 20px;
    width: 400px;
    max-height: 150px;
    background: #fff;
    border-left: 8px solid #522E92;
    border-radius: 10px;
    padding: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: opacity 0.3s, transform 0.3s;
    opacity: 1;
    transform: translateY(0);
    &.failed {
        border-left: 8px solid #FD7A7A;
    }
`;

const CloseContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    cursor: pointer;
`;

const CloseImage = styled.img`
    width: 20px;
    height: 20px;
`;

const MessageContainer = styled.div`
    display: flex;
    align-items: center;
`;

const LeftContainer = styled.div`
    width: 50px;
    height: 50px;
    margin-right: 15px;
`;

const ResponseImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const RightContainer = styled.div`
    flex: 1;
`;

const Message = styled.span`
    color: #010101;
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
`;

const Description = styled.span`
    font-size: 14px;
    color: #9D9D9D;
`;
