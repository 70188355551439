import dashboard from "../../assets/images/dashboard-menu.svg";
import lead from "../../assets/images/menu-leads.svg";
import admin from "../../assets/images/admin-menu.svg";
import sources from "../../assets/images/menu-sources.svg";

const navBarData = [
	{
		img: dashboard,
		text: "Dashboard",
		link: "/leadgenerator/",
	},
	{
		img: lead,
		text: "Leads",
		inner: [
			{
				text: "List of Leads",
				link: "/leadgenerator/list-leads/",
			},
			{
				text: "Add Leads",
				link: "/leadgenerator/add-leads/",
			},
			{
				text: "Duplicate Leads",
				link: "/leadgenerator/duplicate-leads/",
			},
		],
	},
	// {
	// 	img: sources,
	// 	text: "Sources",
	// 	link: "/leadgenerator/sources/",
	// },
];
export default navBarData;
