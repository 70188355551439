import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Context } from "../../../contexts/Store";
import { baseConfig } from "../../../utils/config";

export default function AddEmiModal({
    emiId,
    handleCloseModal,
    getEmiData,
    lastEmiNo,
    vatPercentage,
}) {
    const { state, dispatch } = useContext(Context);

    const [instalmetFee, setInstalmentFee] = useState("");
    const [period, setPeriod] = useState("");
    const [date, setDate] = useState("");
    const [instalmentName, setInstalmentName] = useState("");
    const [isEditable, setEditable] = useState(false);
    const [editIndex, setEditIndex] = useState();

    //totalValue
    const [totalFee, setTotalFee] = useState(0);
    const [totalVAT, setTotalVAT] = useState(0);
    const [totalInstallment, setTotalInstalment] = useState(0);

    const register_fee_vat = (totalValue) => {
        // return totalValue / (100 * 5);
        return (totalValue / 100) * vatPercentage;
    };

    const [allInstallment, setAllInstallment] = useState([]);

    const default_state = () => {
        setInstalmentFee("");
        setPeriod("");
        setDate("");
        setEditable(false);
        setInstalmentName("");
    };
    const edited_emi = (data, i) => {
        setInstalmentFee(data.fee);
        setPeriod(data.period);
        setDate(data.starting_date);
        setEditable(true);
        setEditIndex(i);
        setInstalmentName(data.name);
    };

    const [array, setArray] = useState([
        // {
        // 	id: 1,
        // },
        // {
        // 	id: 2,
        // },
        // {
        // 	id: 3,
        // },
    ]);

    const [groupOfArray, setGroupOfArray] = useState([]);
    const [itemLength, setItemLength] = useState(0);

    let lengthArr = 1;
    const groupArrayLength = (i, index) => {
        let x = lengthArr;

        if (i == 0 && index == 0) {
            x = 1;
            return x;
        } else {
            lengthArr += 1;
            x = lengthArr;
            return x;
        }
    };

    const convert_emi = () => {
        // let startCount = lastEmiNo ? lastEmiNo : 0;
        let startCount = 0;

        array.length = period - 1;
        let newArr = [...array];
        let emiDate = date;
        let length = itemLength;
        // let length = lastEmiNo;

        let updateDate = () => {
            var now = new Date(emiDate);
            // if (now.getMonth() == 12) {
            //     var current = new Date(now.getFullYear() + 1, 0, 1);

            //     emiDate = current;
            //     return moment(emiDate).format("YYYY-MM-DD");
            // }
            // else {
            //     var current = new Date(
            //         now.getFullYear(),
            //         now.getMonth() + 1,
            //         1
            //     );

            //     emiDate = current;
            //     return moment(emiDate).format("YYYY-MM-DD");
            // }

            if (now.getMonth() == 12) {
                var nextMonth = new Date(
                    now.getFullYear() + 1,
                    0,
                    now.getDate()
                );

                emiDate = nextMonth;
                return moment(now).format("YYYY-MM-DD");
            } else {
                var nextMonth = moment(now).add(1, "months");
                let convertType = moment(nextMonth).format("YYYY-MM-DD");
                emiDate = convertType;

                return moment(now).format("YYYY-MM-DD");
            }
        };

        for (array[startCount]; startCount < period; startCount++) {
            let newValue = startCount;
            newArr[startCount] = {
                id: newValue + 1,
                instalment: instalmentName ? instalmentName : `instalment`,
                due_date: updateDate(),
                amount: parseInt(instalmetFee),
                vat: Math.round(register_fee_vat(instalmetFee)),
                total:
                    parseInt(instalmetFee) +
                    parseInt(Math.round(register_fee_vat(instalmetFee))),
            };
            setArray(newArr);
            length = length + 1;
        }
        setItemLength(length);
        setGroupOfArray([
            ...groupOfArray,
            {
                newArr,
                starting_date: date,
                name: instalmentName ? instalmentName : null,
                fee: instalmetFee,
                period: period,
                id: groupOfArray.length + 1,
            },
        ]);
        setTimeout(() => {
            default_state();
            setArray([]);
        }, 200);
    };

    const remove_emi = (idToRemove) => {
        const deleted_array = groupOfArray.filter(
            (item) => item.id !== idToRemove
        );
        setGroupOfArray(deleted_array);
    };

    const edit_emi = () => {
        let all_data = groupOfArray;

        // let startCount = lastEmiNo ? lastEmiNo : 0;
        let startCount = 0;

        array.length = period - 1;
        let newArr = [...array];
        let emiDate = date;
        // let length = lastEmiNo;
        let length = itemLength;

        let updateDate = () => {
            var now = new Date(emiDate);
            // if (now.getMonth() == 11) {
            //     var current = new Date(now.getFullYear() + 1, 0, 1);

            //     emiDate = current;
            //     return moment(emiDate).format("YYYY-MM-DD");
            // } else {
            //     var current = new Date(
            //         now.getFullYear(),
            //         now.getMonth() + 1,
            //         1
            //     );

            //     emiDate = current;
            //     return moment(emiDate).format("YYYY-MM-DD");
            // }
            if (now.getMonth() == 12) {
                var nextMonth = new Date(
                    now.getFullYear() + 1,
                    0,
                    now.getDate()
                );

                emiDate = nextMonth;
                return moment(now).format("YYYY-MM-DD");
            } else {
                var nextMonth = moment(now).add(1, "months");
                let convertType = moment(nextMonth).format("YYYY-MM-DD");
                emiDate = convertType;

                return moment(now).format("YYYY-MM-DD");
            }
        };

        for (array[startCount]; startCount < period; startCount++) {
            let newValue = startCount;
            newArr[startCount] = {
                id: newValue + 1,
                // instalment: `instalment ${length + 1}`,
                instalment: instalmentName ? instalmentName : `instalment`,
                due_date: updateDate(),
                amount: parseInt(instalmetFee),
                vat: Math.round(register_fee_vat(instalmetFee)),
                total:
                    parseInt(instalmetFee) +
                    parseInt(Math.round(register_fee_vat(instalmetFee))),
            };
            length = length + 1;
            setArray(newArr);
        }
        setItemLength(length);
        all_data.map((list, index) => {
            if (index == editIndex) {
                list.period = period;
                list.fee = instalmetFee;
                list.starting_date = date;
                list.name = instalmentName ? instalmentName : null;
                list.newArr = newArr;
            }

            return list;
        });
        setGroupOfArray([...all_data]);
        setTimeout(() => {
            default_state();
            setArray([]);
        }, 200);
    };

    useEffect(() => {
        const updateArray = groupOfArray.map((data) => {
            return data.newArr;
        });

        // const found =
        //     updateArray.length > 0
        //         ? updateArray[0].some((el) => el.id === 0)
        //         : true;
        // if (!found) {
        //     updateArray[0].splice(0, 0, {
        //         id: 0,
        //         instalment: "registration fee",
        //         due_date: props.stepOneValue.starting_date,
        //         amount: parseInt(props.stepOneValue.registration_fee),
        //         vat: register_fee_vat(props.stepOneValue.registration_fee),
        //         total:
        //             parseInt(props.stepOneValue.registration_fee) +
        //             register_fee_vat(props.stepOneValue.registration_fee),
        //     });
        // }

        setAllInstallment(updateArray.flat());
    }, [array]);

    // To handle add emi
    const handleSubmiEmi = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        formdata.append("student_emis", JSON.stringify(allInstallment));

        baseConfig
            .post(`finance/add-student-emi/${emiId}/`, formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    alert("Successfully added");
                    handleCloseModal();
                    getEmiData();
                } else {
                    alert("Some error occured");
                }
            })
            .catch((error, response) => {
            });
    };

    useEffect(() => {
        setTotalFee(0);
        setTotalVAT(0);
        setTotalInstalment(0);

        groupOfArray.forEach((element) => {
            element.newArr.forEach((item) => {
                setTotalFee((prev) => (prev += item.total));
                setTotalVAT((prev) => (prev += item.vat));
                setTotalInstalment((prev) => (prev += item.amount));
            });
        });
    }, [groupOfArray]);

    return (
        <>
            <AddInstallment>
                <InstallmentContainer>
                    <BalaceTable>
                        <TextTag>INSTALMENT FEES & PERIOD</TextTag>
                        {/* <LeftTable>
                <BalacnceTag>Bal : 15000</BalacnceTag>
                <BalanceHideTag>
                    /{props.stepOneValue.total_installment_amount}
                </BalanceHideTag>
            </LeftTable> */}
                    </BalaceTable>

                    <Instalmentfeeperiod>
                        {groupOfArray.map((item, i) => (
                            <BalanceCard>
                                <BalanceCardDetails>
                                    <Cardtitle>Instalment Fee</Cardtitle>
                                    <Carddata>{item.fee}</Carddata>
                                </BalanceCardDetails>
                                <BalanceCardDetails>
                                    <Cardtitle>Period</Cardtitle>
                                    <Carddata>{item.period}</Carddata>
                                </BalanceCardDetails>
                                <BalanceCardDetails>
                                    <Cardtitle>Start Month</Cardtitle>
                                    <Carddata>{item.starting_date}</Carddata>
                                </BalanceCardDetails>
                                <BalanceCardButtons>
                                    <RemoveButton
                                        onClick={() => {
                                            remove_emi(item.id);
                                        }}
                                    >
                                        Remove
                                    </RemoveButton>
                                    <EditButton
                                        onClick={() => {
                                            edited_emi(item, i);
                                        }}
                                    >
                                        Edit
                                    </EditButton>
                                </BalanceCardButtons>
                            </BalanceCard>
                        ))}
                    </Instalmentfeeperiod>

                    <BottomContainer>
                        <TimePeriodLeft>
                            <First>
                                <Name htmlFor="fee">Instalment Fee</Name>
                                <FormInput
                                    type="number"
                                    id="fee"
                                    onChange={(e) => {
                                        setInstalmentFee(e.target.value);
                                    }}
                                    value={instalmetFee}
                                    onWheel={(e) => {
                                        e.target.blur();
                                    }}
                                />
                            </First>
                            <First className="border">
                                <Name htmlFor="period">Period</Name>
                                <FormInput
                                    type="text"
                                    id="period"
                                    onChange={(e) => {
                                        setPeriod(e.target.value);
                                    }}
                                    value={period}
                                />
                            </First>
                            <First>
                                <Name htmlFor="date">Start Date</Name>
                                {/* <DateDiv placeholder="Choose" id="date" /> */}
                                <FormInput
                                    type="date"
                                    id="date"
                                    onChange={(e) => {
                                        setDate(e.target.value);
                                    }}
                                    value={date}
                                />
                            </First>
                            <First>
                                <Name htmlFor="name">Instalment Name</Name>
                                <FormInput
                                    type="text"
                                    id="name"
                                    onChange={(e) => {
                                        setInstalmentName(e.target.value);
                                    }}
                                    onWheel={(e) => {
                                        e.target.blur();
                                    }}
                                    value={instalmentName}
                                />
                            </First>
                        </TimePeriodLeft>
                        <EmiButton>
                            {isEditable && (
                                <CancelButton
                                    onClick={() => {
                                        setEditable(false);
                                        default_state();
                                    }}
                                >
                                    Cancel
                                </CancelButton>
                            )}
                            <SetButton
                                width={isEditable ? "40%" : "100%"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (instalmetFee === "") {
                                        alert("Add Instalment fee");
                                    } else if (period === "") {
                                        alert("Enter the EMI period");
                                    } else if (date === "") {
                                        alert("Enter starting date");
                                    } else {
                                        if (isEditable) {
                                            edit_emi();
                                        } else {
                                            convert_emi();
                                        }
                                    }
                                }}
                            >
                                {isEditable ? `Edit EMI` : `Set EMI`}
                            </SetButton>
                        </EmiButton>
                    </BottomContainer>
                </InstallmentContainer>
                <TimePeriodContainer>
                    <TitleName>Installment</TitleName>
                    <TotelContainer>
                        <TotelI>
                            <RightBoxTititle>Total Instalment</RightBoxTititle>
                            <DivValue>
                                {/* {props.stepOneValue.total_installment_amount} */}
                            </DivValue>
                        </TotelI>
                        <TotelP>
                            <RightBoxTititle>Total Period</RightBoxTititle>
                            <DivValue>
                                {/* {props.stepOneValue.total_period} months */}
                            </DivValue>
                        </TotelP>
                        <TotelD>
                            <RightBoxTititle>Start Date</RightBoxTititle>
                            <DivValue>
                                {/* {props.stepOneValue.starting_date} */}
                            </DivValue>
                        </TotelD>
                    </TotelContainer>
                    {/* <TableList className="App">
            <Table>
                <Tr>
                    <Th>Instalment</Th>
                    <Th>Due Date</Th>
                    <Th>Amount(AED)</Th>
                    <Th>VAT(5%)</Th>
                    <Th>Total Amount</Th>
                </Tr>
                <Tr1>
                    <Td>Registration Fee</Td>
                    <Td>12 / 04 / 2022</Td>
                    <Td>15000</Td>
                    <Td>750</Td>
                    <Td>15750</Td>
                </Tr1>
            </Table>
        </TableList> */}

                    <TableContainer>
                        <HeadingDiv>
                            <TH width="5%">No.</TH>
                            <TH>Instalment</TH>
                            <TH>Due Date</TH>
                            <TH>Amount(AED)</TH>
                            <TH>VAT({vatPercentage}%)</TH>
                            <TH>Total Amount</TH>
                        </HeadingDiv>
                        {/* <HeadingDiv style={{ background: "#FBFBFB" }}>
                <TD>Registration Fee</TD>
                <TD>{props.stepOneValue.starting_date}</TD>
                <TD>{props.stepOneValue.registration_fee}</TD>
                <TD>
                    {register_fee_vat(
                        props.stepOneValue.registration_fee
                    )}
                </TD>
                <TD>
                    {parseInt(props.stepOneValue.registration_fee) +
                        parseInt(
                            register_fee_vat(
                                props.stepOneValue.registration_fee
                            )
                        )}
                </TD>
            </HeadingDiv> */}
                        {groupOfArray.map((item, i) => (
                            <div key={i}>
                                {item.newArr.map((list, index) => (
                                    <HeadingDiv key={index}>
                                        <TD width="5%">
                                            {/* {index + 1} */}
                                            {groupArrayLength(i, index)}
                                        </TD>

                                        <TD
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {list.instalment}
                                        </TD>
                                        <TD>{list.due_date}</TD>
                                        <TD>{list.amount}</TD>
                                        <TD>{list.vat}</TD>
                                        <TD>{list.total}</TD>
                                    </HeadingDiv>
                                ))}
                            </div>
                        ))}
                    </TableContainer>
                    {groupOfArray.length > 0 && (
                        <TotalContainer>
                            <HeadingDiv>
                                <TD width="5%" />
                                <TD width="25%" />
                                <TD />
                                <TD>{totalInstallment}</TD>
                                <TD>{totalVAT}</TD>
                                <TD>{totalFee}</TD>
                            </HeadingDiv>
                        </TotalContainer>
                    )}
                </TimePeriodContainer>
            </AddInstallment>
            <Bottom>
                <CancelButtn
                    onClick={() => {
                        handleCloseModal();
                    }}
                >
                    Cancel
                </CancelButtn>
                <RightButtons>
                    <Nextbutton
                        onClick={() => {
                            if (allInstallment.length < 1) {
                                alert("Set emi first");
                            } else {
                                handleSubmiEmi();
                            }
                        }}
                    >
                        Submit
                    </Nextbutton>
                </RightButtons>
            </Bottom>
        </>
    );
}

const AddInstallment = styled.div`
    /* display: flex;
	justify-content: space-between; */
    /* padding: 30px; */
    /* width: 100%; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    /* width: 100%; */
    max-height: 80vh;
    overflow-y: scroll;
`;
const InstallmentContainer = styled.div`
    /* width: calc(100% - 610px) !important; */
    width: 460px;
    box-sizing: border-box;
`;
const Div1 = styled.div``;
const BalaceTable = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
`;
const TextTag = styled.h4`
    font-family: "poppinsregular";
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #9d9d9d;
`;
const LeftTable = styled.div`
    display: flex;
    text-align: center;
`;
const BalacnceTag = styled.h4``;
const BalanceHideTag = styled.h4`
    color: #9d9d9d;
`;

// Instalmentfeeperiod
const Instalmentfeeperiod = styled.div``;
const BalanceCard = styled.form`
    background: #ffffff;
    padding: 16px;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 10px;
`;
const BalanceCardDetails = styled.div`
    display: flex;
    flex-direction: column;
`;
const Cardtitle = styled.p`
    font-family: "poppinsregular";
    font-weight: 400;
    font-size: 14px;
    color: #404040;
    margin-bottom: 4px;
    opacity: 0.5;
`;
const Carddata = styled.p`
    font-family: "poppinsregular";
    font-weight: 400;
    font-size: 14px;
    color: #404040;
    letter-spacing: 0.01em;
`;
const BalanceCardButtons = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
`;
const RemoveButton = styled.div`
    display: flex;
    align-items: center;
    height: 30px;
    font-family: "poppins-medium";
    color: #ea1752;
    font-weight: 500;
    font-size: 14px;
    padding: 4px 12px;
    box-sizing: border-box;
    background: rgba(234, 23, 82, 0.1);
    border-radius: 8px;
    cursor: pointer;
`;
const EditButton = styled.div`
    display: flex;
    align-items: center;
    height: 30px;
    font-family: "poppins-medium";
    color: #917afd;
    padding: 0px 12px;
    background: rgba(145, 122, 253, 0.2);
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    box-sizing: border-box;
`;
// const Instalmentfeeperiod = styled.div``
// const Instalmentfeeperiod = styled.div``

const BottomContainer = styled.div`
    background: #f5f5f5;
    padding: 16px;
    border-radius: 8px;
    /* width: 534px; */
`;
const TimePeriodLeft = styled.ul`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
`;
const First = styled.li``;
const FormInput = styled.input`
    display: inline-block;
    border: 1px solid #e2e2e2;
    border-radius: 8px !important;
    padding: 0 10px;
    background: #ffffff;
    font-size: 15px;
    color: #000000;
    height: 48px;
    width: 100%;
    box-sizing: border-box;
    /* width: 155px; */
`;
const Name = styled.label`
    color: #9d9d9d;
    cursor: pointer;
    font-family: "poppinsregular";
    font-size: 14px;
    line-height: 150%;
`;
const DateDiv = styled.div`
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    background: #ffffff;
    padding: 0 10px;
    /* width: 164px; */
    height: 48px;
`;
const SetButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 16px;
    width: 100%;
    height: 40px;
    width: ${(props) => (props.width ? props.width : "100%")};
    background: #917afd;
    border-radius: 8px;
    cursor: pointer;
    color: #ffffff;
`;
const TimePeriodContainer = styled.div`
    border-radius: 8px;
    box-sizing: border-box;
    background: #edf5fe;
    padding: 12px;
    width: 640px;
`;
const TitleName = styled.div`
    text-align: center;
    border-bottom: 1px solid #c1c1c1;
`;
const TotelContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 12px;
`;
const TotelI = styled.div``;
const TotelP = styled.div``;
const TotelD = styled.div``;
const RightBoxTititle = styled.p`
    color: #9d9d9d;
    font-size: 14px;
    font-family: "poppinsregular";
`;
const DivValue = styled.p`
    color: #000;
    font-size: 16px;
    font-family: "poppinsregular";
`;
const TableList = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Table = styled.table`
    padding: 16px;
    border-radius: 1rem;
    width: 100%;
    height: 147px;
    border-collapse: collapse;
    overflow: hidden;
    box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px,
        rgb(0 0 0 / 8%) 0px 0px 0px 1px;
`;
const Tr = styled.tr`
    background: #ffffff;
`;
const Tr1 = styled.tr`
    background: #fbfbfb;
`;
const Th = styled.th`
    border-bottom: 1px solid black;
    color: #b4b4b4;
`;
const Td = styled.td`
    text-align: center;
`;

//new table

const TableContainer = styled.div`
    border: 1px solid #acacac;
    border-radius: 4px;
    padding: 0 10px;
    background-color: #fff;
    margin-bottom: 15px;
    overflow-y: scroll;
    min-height: 200px;
`;
const HeadingDiv = styled.div`
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: space-between;
    &:nth-child(2n) {
        background: #fbfbfb;
    }
`;
const TH = styled.div`
    width: ${(props) => (props.width ? props.width : "19%")};
    color: #b4b4b4;
    /* padding: 0 5px; */
    font-size: 14px;
    :last-child {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.center-align {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const TD = styled.div`
    width: ${(props) => (props.width ? props.width : "19%")};
    color: #000;
    font-size: 13px;
    padding: 0 5px;
    :last-child {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.center-align {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const CancelButton = styled.div`
    background: #fff;
    border-radius: 8px;
    border: 1px solid #917afd;
    color: #000;
    font-family: "poppins-medium";
    font-weight: 500;
    height: 40px;
    width: 40%;
    box-sizing: border-box;
    font-size: 18px;
    padding: 12px 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-right: 10px; */
`;
const EmiButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const CancelButtn = styled.div`
    color: #f71f1f;
    font-family: "poppins-medium";
    font-weight: 500;
    cursor: pointer;
    font-size: 16px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const RightButtons = styled.div`
    display: flex;
    align-items: center;
`;
const PreviousButton = styled.div`
    background: #fff;
    border-radius: 8px;
    border: 1px solid #917afd;
    color: #000;
    font-family: "poppins-medium";
    font-weight: 500;
    height: 38px;
    box-sizing: border-box;
    font-size: 18px;
    padding: 12px 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
`;
const Nextbutton = styled.div`
    background: #917afd;
    border: 1px solid #917afd;
    border-radius: 8px;
    color: #ffffff;
    font-family: "poppins-medium";
    font-weight: 500;
    height: 38px;
    box-sizing: border-box;
    font-size: 18px;
    padding: 12px 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const TotalContainer = styled.div`
    padding: 0 10px;
    background-color: #fff;
    /* margin-bottom: 15px; */
    /* overflow-y: scroll; */
`;
