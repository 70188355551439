import React, { useEffect, useState, useContext } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import visible from "../../assets/images/visible.svg";
import openedEye from "../../assets/images/eye-open.svg";
import closedEye from "../../assets/images/eye-close.svg";
import { useHistory, useParams } from "react-router-dom";
import { AddCampusAdminService } from "../../service/campusAdmin";
import { ListCampusService } from "../../service/campus";
import { validatePassword } from "./../../helpers/Functions";
import { baseConfig } from "./../../utils/config";
import { Context } from "../../contexts/Store";
import SweetAlert from "../../components/includes/SweetAlert";
import AlertBox from "../../components/includes/AlertBox";

function AddUniversityAdmin() {
    const history = useHistory();
    const { universityId } = useParams();
    const [university, setUniversity] = useState([]);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [fieldName, setFieldName] = useState("");
    const [id, setId] = useState("");

    const [name, setName] = useState("");
    const [universityName, setUniversityName] = useState("");
    const [email, setEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [emailValidation, setEmailValidation] = useState(false);
    const [passwordValidate, setPasswordValidate] = useState({});
    const { state } = useContext(Context);

    // sweetalert states and functions
    const [isSweetAlert, setIsSweetAlert] = useState(false);
    const [sweetAlertContent, setSweetAlertContent] = useState({
        title: "",
        message: "",
        icon: "",
        confirmButtonText: "",
        isCancelButton: false,
    });
    const handleSweetAlertConfirm = () => {
        history.push("/superadmin/university-admin/");
    };
    // end of sweetalert

    // alert mui message
    const [alertMessage, setAlertMessage] = useState(null); // Track the message state

    useEffect(() => {
        setPasswordValidate(validatePassword(password));
    }, [password]);

    const criteria = passwordValidate.is_strong_password;

    const [form, setFormValues] = useState({
        passwordShow: false,
        confirmPasswordShow: false,
    });

    const validate = (text) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(text) === false) {
            setEmailValidation(false);
            setEmail(text);
            return false;
        } else {
            setEmail(text);
            setEmailValidation(true);
        }
    };

    const getUniversity = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/university/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setUniversity(data.data);
                } else {
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        getUniversity();
    }, []);

    const handleChangeValue = (key, value) => {
        setFormValues({ ...form, [key]: value });
    };

    // const handleSubmit = async () => {
    // 	if (form["password"] !== form["confirmPassword"]) {
    // 		alert("Incorrect password");
    // 	}
    // 	let data = await AddCampusAdminService({
    // 		universityName,
    // 		email,
    // 		userName,
    // 		password,
    // 		state,
    // 	});
    // 	if (data.StatusCode === 6000) {
    // 		history.push("/superadmin/campus-admins");
    // 	} else if (data.StatusCode === 6001) {
    // 		alert(data.data.message);
    // 	}
    // };

    const getAdminId = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/get-university-admin-id/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setId(data.admin_id);
                } else {
                }
            })
            .catch((error) => {});
    };

    const getSingleAdmins = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/campus/university-admin/${universityId}`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    //   setSelectedBatch({
                    //       batch_name: data?.data?.batch_name,
                    //       id: data?.data?.batch,
                    // setUniversityName();
                    validate(data?.data.user_data?.email);
                    setPassword(data?.data?.password);
                    setConfirmPassword(data?.data?.password);
                    setUserName(data?.data.user_data?.username);
                    setName(data?.data?.name);
                    setUniversityName(data?.data?.university);
                    // setEmailValidation(true);
                } else {
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        getAdminId();
    }, []);

    useEffect(() => {
        if (universityId) {
            getSingleAdmins();
        }
    }, [universityId]);

    const handleSubmit = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .post(
                "/campus/university-admin/",
                {
                    university: universityName,
                    username: userName,
                    password: password,
                    email: email,
                    name: name,
                },
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                }
            )
            .then((response) => {
                let { StatusCode, message, data } = response.data;
                if (StatusCode == 6000) {
                    getAdminId();
                    setFormValues({});
                    setUniversityName("");
                    setEmail("");
                    setPassword("");
                    setConfirmPassword("");
                    setUserName("");
                    setName("");
                    setSweetAlertContent({
                        title: "Success",
                        message: "University admin successfully created",
                        icon: "success",
                        confirmButtonText: "Ok",
                        isCancelButton: false,
                    });
                    setIsSweetAlert(true);
                } else {
                    setAlertMessage(data?.message);
                }
            })
            .catch((error) => {
                setAlertMessage(error.response.data.data.message);
            });
    };

    const editUniversity = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .put(
                `/campus/university-admin/${universityId}/`,
                {
                    university: universityName,
                    username: userName,
                    password: password,
                    email: email,
                    name: name,
                },
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                }
            )
            .then((response) => {
                let { StatusCode, message, data } = response.data;
                if (StatusCode === 6000) {
                    setSweetAlertContent({
                        title: "Success",
                        message: "University admin successfully edited",
                        icon: "success",
                        confirmButtonText: "Ok",
                        isCancelButton: false,
                    });
                    setIsSweetAlert(true);
                    // getAdminId();
                    // setFormValues({});
                    // setUniversityName("");
                    // setEmail("");
                    // setPassword("");
                    // setConfirmPassword("");
                    // setUserName("");
                    // setName("");
                    // history.push("/superadmin/university-admin/");
                } else {
                    setAlertMessage(data?.message);
                }
            })
            .catch((error) => {
                setAlertMessage(error.response.data.data.message);
            });
    };

    return (
        <Wrapper>
            <AlertBox
                message={alertMessage}
                setMessage={setAlertMessage}
                severity={
                    alertMessage && alertMessage.includes("successful")
                        ? "success"
                        : "error"
                }
                autoClose={true}
                onClose={() => setAlertMessage(null)}
            />
            <SweetAlert
                sweetAlertContent={sweetAlertContent}
                setSweetAlertContent={setSweetAlertContent}
                isSweetAlert={isSweetAlert}
                setIsSweetAlert={setIsSweetAlert}
                onConfirm={handleSweetAlertConfirm}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        margin: "0 12px",
                        flexDirection: "column",
                    }}
                >
                    {universityId ? (
                        <Title fontSize="35px">Edit University Admin</Title>
                    ) : (
                        <Title fontSize="35px">Add University Admin</Title>
                    )}
                    {/* {universityId ? (
						<Text
							style={{
								marginTop: "5px",
								fontFamily: "Inter",
								fontWeight: "500",
							}}
							color="grey"
							fontSize="18px"
						>
							Admins / Edit campus admin
						</Text>
					) : (
						<Text
							style={{
								marginTop: "5px",
								fontFamily: "Inter",
								fontWeight: "500",
							}}
							color="grey"
							fontSize="18px"
						>
							Admins / Add campus admin
						</Text>
					)} */}
                </div>
                {!universityId && (
                    <div>
                        <Button
                            href="#"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                margin: "0 22px",
                            }}
                            backgroundColor="white"
                            fontSize="15px"
                            padding="18px 57px"
                            onClick={(e) => {
                                e.preventDefault();
                                const form = document.getElementById("form");
                                form.reset();
                                setFormValues({});
                                setUniversityName("");
                                setEmail("");
                                setPassword("");
                                setConfirmPassword("");
                                setUserName("");
                                setName("");
                            }}
                        >
                            <Text color="  #EE1451" fontSize="15px">
                                Clear Form
                            </Text>
                        </Button>
                    </div>
                )}
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Container style={{ flexWrap: "wrap " }} width="100%">
                    <form id="form">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Input
                                onChange={(value) => setId(value)}
                                name="Admin ID  (auto generated)"
                                value={id}
                                disabled
                            />
                            {/* <SelectInput
								name="Choose University"
								type="text"
								placeholder="Choose University"
								onChange={(value) => {
									{
										setUniversityName(value);
										setError(false);
									}
								}}
								options={university ? university : []}
								selectName={"name"}
								selectValue={"id"}
								value={form?.campus}
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
								defaultValue="Select a university"
							/> */}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Input
                                onChange={(value) => {
                                    setName(value);
                                    setError(false);
                                }}
                                name="Name"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={name}
                            />
                            <SelectInput
                                name="Choose University"
                                type="text"
                                placeholder="Choose University"
                                onChange={(value) => {
                                    {
                                        setUniversityName(value);
                                        setError(false);
                                    }
                                }}
                                options={university ? university : []}
                                selectName={"name"}
                                selectValue={"id"}
                                // value={form?.campus}
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                defaultValue="Select a university"
                                value={universityName}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Input
                                onChange={(value) => {
                                    validate(value);
                                    setError(false);
                                }}
                                type="email"
                                name="Admin Mail ID"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={email}
                            />

                            <Input
                                onChange={(value) => {
                                    setUserName(value);
                                    setError(false);
                                }}
                                name="Username"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={userName}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "20px",
                                justifyContent: "space-between ",
                            }}
                        >
                            <Input
                                onClickVisible={() =>
                                    handleChangeValue(
                                        "passwordShow",
                                        !form?.passwordShow
                                    )
                                }
                                onChange={(value) => {
                                    setPassword(value);
                                    setError(false);
                                }}
                                type={form?.passwordShow ? "text" : "password"}
                                icon={
                                    form?.passwordShow ? openedEye : closedEye
                                }
                                name="Admin Password"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={password}
                            />
                            <Input
                                onClickVisible={() =>
                                    handleChangeValue(
                                        "confirmPasswordShow",
                                        !form?.confirmPasswordShow
                                    )
                                }
                                onChange={(value) => {
                                    setConfirmPassword(value);
                                    setError(false);
                                }}
                                type={
                                    form?.confirmPasswordShow
                                        ? "text"
                                        : "password"
                                }
                                name="Confirm Admin Password"
                                icon={
                                    form?.confirmPasswordShow
                                        ? openedEye
                                        : closedEye
                                }
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={confirmPassword}
                            />
                        </div>
                        <div style={{ display: "flex", marginTop: "21px" }}>
                            <Button
                                href="#"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                bacerkgroundColor="#5A2D8F"
                                fontSize="15px"
                                padding="21px 70px"
                                onClick={(e) => {
                                    if (name === "") {
                                        setError(true);
                                        setFieldName("Name");
                                        setErrorMessage("Please enter name");
                                    } else if (
                                        universityName === "" ||
                                        universityName === "Select a university"
                                    ) {
                                        setError(true);
                                        setFieldName("Choose University");
                                        setErrorMessage("Choose a campus");
                                    } else if (emailValidation === false) {
                                        setError(true);
                                        setFieldName("Admin Mail ID");
                                        setErrorMessage(
                                            "Please enter a valid email"
                                        );
                                    } else if (userName === "") {
                                        setError(true);
                                        setFieldName("Username");
                                        setErrorMessage(
                                            "Please enter Username"
                                        );
                                    } else if (password.length < 8) {
                                        setError(true);
                                        setFieldName("Admin Password");
                                        setErrorMessage(
                                            "Be a minimum of 8 characters"
                                        );
                                    } else if (criteria === false) {
                                        setError(true);
                                        setFieldName("Admin Password");
                                        setErrorMessage(
                                            "Strong passwords consist of a combination of uppercase and lowercase letters, numbers and special symbols"
                                        );
                                    } else if (password !== confirmPassword) {
                                        setError(true);
                                        setFieldName("Confirm Admin Password");
                                        setErrorMessage(
                                            "Both password must be same"
                                        );
                                    } else {
                                        e.preventDefault();
                                        if (universityId) {
                                            editUniversity();
                                        } else {
                                            handleSubmit();
                                        }
                                    }
                                }}
                            >
                                <Text color="white" fontSize="15px">
                                    Submit
                                </Text>
                            </Button>

                            <Button
                                onClick={() => {
                                    history.push(
                                        "/superadmin/university-admin/"
                                    );
                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 22px",
                                }}
                                backgroundColor="#F4F4F4"
                                fontSize="15px"
                                padding="21px 70px"
                            >
                                <Text color=" #522e92" fontSize="15px">
                                    Cancel
                                </Text>
                            </Button>
                        </div>
                    </form>
                </Container>
            </div>
        </Wrapper>
    );
}

export default AddUniversityAdmin;
