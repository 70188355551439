import moment from "moment";
import React, {
	useState,
	useEffect,
	useContext,
	useImperativeHandle,
	forwardRef,
    useRef,
} from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { baseConfig } from "../../../utils/config";
import { Context } from "../../../contexts/Store";
import PopupMessageHandler from "../../../components/Popup/popupMessageHandler";

// export default function EMIStep2({ stepOneValue }) {
const EMIStep2 = (props, ref) => {
    const history = useHistory();
	const { state } = useContext(Context);
	
	const roundAmount = (amount) => {
		let roundedAmount = Math.round(amount * 100) / 100;
		return roundedAmount;
	};

	const [period, setPeriod] = useState(props.stepOneValue.registration_fee > 0 ? parseInt(props.stepOneValue.total_period) - 1 : props.stepOneValue.total_period);
	const [instalmentFee, setInstalmentFee] = useState(
		(
			roundAmount(parseFloat(props.stepOneValue.total_installment_amount /
			period))
		)
	);
	const [instalmentCount, setInstalmentCount] = useState(1);
	// const [normalInstalment, setNormalInstalment] = useState(false);
	const [date, setDate] = useState(props.stepOneValue.starting_date);
	const [VAT, setVAT] = useState(props.stepOneValue.vat);
    const [instalmentName, setInstalmentName] = useState("Instalment");
    const [isEditable, setEditable] = useState(false);
    const [editIndex, setEditIndex] = useState();
    const [isMessagePopup, setIsMessagePopup] = useState(false);
    const successRef = useRef(null);


    const [totalFee, setTotalFee] = useState(0);
    const [totalVAT, setTotalVAT] = useState(0);
    const [totalInstallment, setTotalInstalment] = useState(0);

    const register_fee_vat = (totalValue) => {
		return (totalValue / 100) * VAT;
    };

	const totalPeriod = props.stepOneValue.total_period
    const [allInstallment, setAllInstallment] = useState([]);

    const default_state = () => {
        setInstalmentFee("");
        setPeriod(1);
        setEditable(false);
        setInstalmentName("Instalment");
    };

    const edited_emi = (data, i) => {
        setInstalmentFee(data.fee);
        setPeriod(data.period);
        setDate(data.starting_date);
        setEditable(true);
        setEditIndex(i);
        setInstalmentName(data.name);
    };

    const handleMessagePopup = () => {
        setIsMessagePopup(!isMessagePopup);
    };

    let lengthArr = 1;

    const groupArrayLength = (i, index) => {
        let x = lengthArr;
        if (i == 0 && index == 0) {
            x = 1;
            return x;
        } else {
            lengthArr += 1;
            x = lengthArr;
            return x;
        }
    };

    const [array, setArray] = useState([]);
    const [groupOfArray, setGroupOfArray] = useState([]);

	useEffect (() => {
		if (props.stepOneValue.registration_fee > 0){
			setGroupOfArray([
				{
					newArr: [
						{
							id: 0,
							instalment: "Registration Fee",
							due_date: props.stepOneValue.starting_date,
							amount: parseInt(props.stepOneValue.registration_fee),
							vat: register_fee_vat(props.stepOneValue.registration_fee),
							total:
								parseInt(props.stepOneValue.registration_fee) +
								register_fee_vat(props.stepOneValue.registration_fee),
						}
					],
					id: 0,
					starting_date: props.stepOneValue.starting_date,
					name: "Registration Fee",
					fee: parseInt(props.stepOneValue.registration_fee),
					period: 1,
				},
			])
		}
	}, [])
    const [itemLength, setItemLength] = useState(0);

    const updateDate = (emiDate) => {
        var now = new Date(emiDate);
        if (now.getMonth() == 11) {
            var nextMonth = new Date(now.getFullYear() + 1, 0, now.getDate());
            emiDate = moment(nextMonth).format("YYYY-MM-DD");
            setDate(emiDate);
            return emiDate;
        } else {
            var nextMonth = moment(now).add(1, "months").toDate();
            emiDate = moment(nextMonth).format("YYYY-MM-DD");
            setDate(emiDate);
            return emiDate;
        }
    };

    const convert_emi = () => {
        let startCount = 0;
        array.length = period - 1;
        let newArr = [...array];
        let emiDate = date;
        let length = itemLength;

        for (array[startCount]; startCount < period; startCount++) {
            let newValue = startCount;
            let amount = roundAmount(instalmentFee);
            let vat = roundAmount(register_fee_vat(instalmentFee));
            newArr[startCount] = {
                id: newValue + 1,
                instalment: instalmentName ? instalmentName : `Instalment`,
                due_date: emiDate,
                amount: parseFloat(instalmentFee),
                vat: roundAmount(parseFloat(register_fee_vat(instalmentFee))),
                total: roundAmount(amount + vat),
            };
            emiDate = updateDate(emiDate);
            setArray(newArr);
            length = length + 1;
        }
        setItemLength(length);
        setGroupOfArray([
            ...groupOfArray,
            {
                newArr,
                starting_date: date,
                name: instalmentName ? instalmentName : null,
                fee: instalmentFee,
                period: period,
                id: groupOfArray.length + 1,
            },
        ]);
        setTimeout(() => {
            default_state();
            setArray([]);
        }, 200);
    };

    const remove_emi = (idToRemove) => {
        const deleted_array = groupOfArray.filter(
            (item) => item.id !== idToRemove
        );
        setGroupOfArray(deleted_array);
    };

    const edit_emi = () => {
        let all_data = groupOfArray;

        let startCount = 0;
        array.length = period - 1;
        let newArr = [...array];
        let emiDate = date;
        let length = itemLength;

        for (array[startCount]; startCount < period; startCount++) {
            let newValue = startCount;
            newArr[startCount] = {
                id: newValue + 1,
                instalment: instalmentName ? instalmentName : `instalment`,
                due_date: emiDate,
                amount: roundAmount(parseFloat(instalmentFee)),
                vat: roundAmount(parseFloat(register_fee_vat(instalmentFee))),
                total: roundAmount(
                    parseFloat(instalmentFee) + parseFloat(register_fee_vat(instalmentFee))
                ),
            };
            emiDate = updateDate(emiDate);
            length = length + 1;
            setArray(newArr);
        }
        setItemLength(length);
        all_data.map((list, index) => {
            if (index == editIndex) {
                list.period = period;
                list.fee = instalmentFee;
                list.starting_date = date;
                list.name = instalmentName ? instalmentName : null;
                list.newArr = newArr;
            }
            return list;
        });
        setGroupOfArray([...all_data]);
        setTimeout(() => {
            default_state();
            setArray([]);
        }, 200);
    };

    useEffect(() => {
        const updateArray = groupOfArray.map((data) => {
            return data.newArr;
        });
        setAllInstallment(updateArray.flat());
    }, [array]);

	const handleEMI = () => {

        if (Math.round(props.stepOneValue.course_fee) != Math.round(totalInstallment)) {
            // Generate Custom Alert Here with proper message
			props.setAlert(`Added Instalment amount does not match the total course fee. The defference amount is ${props.stepOneValue.course_fee - totalInstallment}`)
        }
        else {
            let accessToken = state.user_details.access_token;
            var formdata = new FormData();

            // if (file !== null) {
            // 	formdata.append("image", file);
            // }
            formdata.append(
                "registration_fee",
                props.stepOneValue.registration_fee
            );
            formdata.append("total_period", props.stepOneValue.total_period);
			formdata.append("total_fee", props.stepOneValue.course_fee);
            formdata.append("start_date", props.stepOneValue.starting_date);
            formdata.append("vat", props.stepOneValue.vat);
            formdata.append("student_emis", JSON.stringify(allInstallment));

            baseConfig
                .post(`students/student-emi-list/${props.programId}/`, formdata, {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    const { StatusCode, message, data } = response.data;
                    if (StatusCode === 6000) {
                        if (successRef.current) {
                            successRef.current.focus();
                        }
                        handleMessagePopup();
                        setTimeout(() => {
                            setIsMessagePopup(false);
                            props.handleCloseEmiModalWIthoutConfirmation();
                            props.getSingleStudentDetails();
                            // history.push("/finance-manager/not-assigned-students/");
                        }, 3000);
                    } else {
                        props.setAlert(data.message);
                    }
                })
                .catch((error, response) => {
                    if (error.response.status === 400) {
                        props.setAlert(error?.response?.data?.data?.message);
                    }
                });
        }
    };

    useEffect(() => {
        setTotalFee(0);
        setTotalVAT(0);
        setTotalInstalment(0);

        groupOfArray.forEach((element) => {
            element.newArr.forEach((item) => {
                setTotalFee((prev) => roundAmount((prev += parseFloat(item.total))));
                setTotalVAT((prev) =>
                    parseFloat(roundAmount((prev += parseFloat(item.vat))))
                );
                setTotalInstalment((prev) =>
                    parseFloat(roundAmount((prev += parseFloat(item.amount))))
                );
            });
        });
    }, [groupOfArray]);

	useImperativeHandle(ref, () => ({
		handleEMI,
	}));

    return (
        <>
            <AddInstallment>
                <InstallmentContainer>
					<BalaceTable ref={successRef} tabIndex={-1}>
                        <TextTag>INSTALMENT FEES & PERIOD</TextTag>
					</BalaceTable>

					<Instalmentfeeperiod>
						{groupOfArray.map((item, i) => (
							<BalanceCard key={`${i}-${item.fee}`}>
								<BalanceCardDetails>
									<Cardtitle>{item.name === "Instalment" ? "Instalment Fee": item.name}</Cardtitle>
									<Carddata>{item.fee}</Carddata>
								</BalanceCardDetails>
								<BalanceCardDetails>
									<Cardtitle>Period</Cardtitle>
									<Carddata>{item.period}</Carddata>
								</BalanceCardDetails>
								<BalanceCardDetails>
									<Cardtitle>Start Month</Cardtitle>
									<Carddata>
										{moment(item.starting_date).format(
											"DD-MM-YYYY"
										)}
									</Carddata>
								</BalanceCardDetails>

								<BalanceCardButtons>
									<RemoveButton
										onClick={() => {
											remove_emi(item.id);
										}}
									>
										Remove
									</RemoveButton>
									<EditButton
										onClick={() => {
											edited_emi(item, i);
										}}
									>
										Edit
									</EditButton>
								</BalanceCardButtons>
							</BalanceCard>
						))}
					</Instalmentfeeperiod>

					<BottomContainer>
						<TimePeriodLeft>
							<First>
								<Name htmlFor="fee">Instalment Fee</Name><Star>*</Star>
								<FormInput
                                        type="number"
									id="fee"
									max={99}
									onChange={(e) => {
                                        (e.target.value.toString().length <= 6 && e.target.value > -1 && setInstalmentFee(parseFloat(e.target.value)));
									}}
                                        value={instalmentFee}
									onWheel={(e) => {
										e.target.blur();
									}}
                                    />
							</First>
							<First className="border">
								<Name htmlFor="period">Period</Name><Star>*</Star>
								<FormInput
                                        type="number"
									id="period"
									onChange={(e) => {(e.target.value <= 99 && e.target.value > -1 &&
										setPeriod(parseInt(e.target.value)));
									}}
                                        value={period}
								/>
							</First>
							<First>
								<Name htmlFor="date">Start Date</Name><Star>*</Star>
								{/* <DateDiv placeholder="Choose" id="date" /> */}
								<FormInput
                                        type="date"
									id="date"
									onChange={(e) => {
										setDate(e.target.value);
									}}
                                        value={date}
								/>
							</First>
							<First>
								<Name htmlFor="name">Instalment Name</Name><Star>*</Star>
								<FormInput
                                        type="text"
									id="name"
                                    maxLength={99}
									onChange={(e) => {
										setInstalmentName(e.target.value);
									}}
									onWheel={(e) => {
										e.target.blur();
									}}
                                        value={instalmentName}
								/>
							</First>
						</TimePeriodLeft>
						<EmiButton>
							{isEditable && (
								<CancelButton
									onClick={() => {
										setEditable(false);
										default_state();
									}}
								>
									Cancel
								</CancelButton>
							)}
							<SetButton
								width={isEditable ? "40%" : "100%"}
								onClick={(e) => {
									e.preventDefault();
									if (instalmentFee === "" || period === "" || date === "" || instalmentName === "") {
										props.setAlert("Please fill blank fields")
									} else if (instalmentFee <= 0 || !typeof(instalmentFee) === 'number') {
										props.setAlert("Please enter correct 'Intalment Fee'");
									} else if (!period || !typeof(period) === 'number' || period <= 0 || period >= 99) {
										props.setAlert("Please enter correct 'Period 1-99'");
									} else {
										if (isEditable) {
											edit_emi();
										} else {
											convert_emi();
										}
									}
								}}
							>
								{isEditable ? `Edit EMI` : `Set EMI`}
							</SetButton>
						</EmiButton>
					</BottomContainer>
                </InstallmentContainer>
				<TimePeriodContainer>
					<TitleName>Added Installment Fee</TitleName>
					<TotelContainer>
						<TotelI>
							<RightBoxTititle>Total Fee</RightBoxTititle>
							<DivValue>
								{props.stepOneValue.course_fee}
							</DivValue>
						</TotelI>
						<TotelP>
							<RightBoxTititle>Total Period</RightBoxTititle>
							<DivValue>
								{totalPeriod} months
							</DivValue>
						</TotelP>
						<TotelD>
							<RightBoxTititle>Start Date</RightBoxTititle>
							<DivValue>
								{moment(props.stepOneValue.starting_date).format(
									"DD-MM-YYYY"
								)}
							</DivValue>
						</TotelD>
					</TotelContainer>

					<TableContainer>
						<HeadingDiv>
							<TH width="5%">No.</TH>
							<TH width="25%">Instalment</TH>
							<TH>Due Date</TH>
							<TH>
								Amount{" "}
								<span style={{ fontSize: "12px" }}>(AED)</span>
							</TH>
							<TH>
								VAT
								<span style={{ fontSize: "12px" }}>({VAT}%)</span>
							</TH>
							<TH>Total Amount</TH>
						</HeadingDiv>
						{groupOfArray.map((item, i) => (
							<div key={i}>
								{item.newArr.map((list, index) => (
									<>
										{/* {groupArrayLength(i, index)} */}
										<HeadingDiv key={`${index}-${item.amount}`}>
											<TD width="5%">
												{/* {groupLength} */}
												{/* ----- {index + 1  } */}
												{groupArrayLength(i, index)}
											</TD>
											<TD
												style={{
													textTransform: "capitalize",
												}}
												width="25%"
											>
												{list.instalment}
											</TD>
											<TD>
												{moment(list.due_date).format(
													"DD-MM-YYYY"
												)}
											</TD>
											<TD>{list.amount}</TD>
											<TD>{list.vat}</TD>
											<TD>{list.total}</TD>
										</HeadingDiv>
									</>
								))}
							</div>
						))}
					</TableContainer>
					{groupOfArray.length > 0 && (
						<TotalContainer>
							<HeadingDiv>
								<TD width="5%" />
								<TD width="25%" />
								<TD />
								<TD>{totalInstallment}</TD>
								<TD>{totalVAT}</TD>
								<TD>{totalFee}</TD>
							</HeadingDiv>
						</TotalContainer>
					)}
				</TimePeriodContainer>
			</AddInstallment>
            {isMessagePopup &&
            <PopupMessageHandler
                handleMessagePopup={handleMessagePopup}
                status="success"
                title="Installment Process Completed"
                message="The student's course EMIs have been successfully added."
            />
            }
        </>
    );
};

export default forwardRef(EMIStep2);

const AddInstallment = styled.div`
	/* display: flex;
	justify-content: space-between; */
	/* padding: 30px; */
	/* width: 100%; */
	display: grid;
    grid-template-columns: 4fr 8fr;
	grid-gap: 30px;

    /* max-height: 600px;
    overflow-y: scroll; */
`;
const InstallmentContainer = styled.div`
	width: 500px;
`;
const Div1 = styled.div``;
const BalaceTable = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
`;
const TextTag = styled.h4`
    /* font-family: "poppinsregular"; */
	font-size: 14px;
	letter-spacing: 0.01em;
	color: #9d9d9d;
`;
const LeftTable = styled.div`
	display: flex;
	text-align: center;
`;
const BalacnceTag = styled.h4``;
const BalanceHideTag = styled.h4`
	color: #9d9d9d;
`;

// Instalmentfeeperiod
const Instalmentfeeperiod = styled.div``;
const BalanceCard = styled.form`
	background: #ffffff;
	padding: 16px;
	border: 1px solid #e7e7e7;
	border-radius: 8px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
	margin-bottom: 10px;
`;
const BalanceCardDetails = styled.div`
	display: flex;
	flex-direction: column;
`;
const Cardtitle = styled.p`
    /* font-family: "poppinsregular"; */
	font-weight: 400;
	font-size: 14px;
	color: #404040;
	margin-bottom: 4px;
	opacity: 0.5;
`;
const Carddata = styled.p`
    /* font-family: "poppinsregular"; */
	font-weight: 400;
	font-size: 14px;
	color: #404040;
	letter-spacing: 0.01em;
`;
const BalanceCardButtons = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
`;
const RemoveButton = styled.div`
	display: flex;
	align-items: center;
	height: 30px;
    /* font-family: "poppins-medium"; */
	color: #ea1752;
	font-weight: 500;
	font-size: 14px;
    padding: 0px 10px;
	background: rgba(234, 23, 82, 0.1);
	border-radius: 8px;
	cursor: pointer;
`;
const EditButton = styled.div`
	display: flex;
	align-items: center;
	height: 30px;
    /* font-family: "poppins-medium"; */
	color: #917afd;
	padding: 0px 12px;
	background: rgba(145, 122, 253, 0.2);
	border-radius: 8px;
	font-weight: 500;
	font-size: 14px;
	cursor: pointer;
`;
// const Instalmentfeeperiod = styled.div``
// const Instalmentfeeperiod = styled.div``

const BottomContainer = styled.div`
	background: #f5f5f5;
	padding: 16px;
	border-radius: 8px;
	/* width: 534px; */
`;
const TimePeriodLeft = styled.ul`
	display: grid;
	grid-gap: 15px;
	grid-template-columns: 1fr 1fr;
	margin-bottom: 20px;
`;
const First = styled.li``;
const FormInput = styled.input`
	border: 1px solid #e2e2e2;
	border-radius: 8px !important;
	padding: 0 10px;
	background: #ffffff;
	font-size: 15px;
	color: #000000;
	height: 48px;
    width: 92%;
	/* width: 155px; */
`;
const Name = styled.label`
	color: #9d9d9d;
	cursor: pointer;
    /* font-family: "poppinsregular"; */
	font-size: 14px;
	line-height: 150%;
`;
const DateDiv = styled.div`
	border: 1px solid #e2e2e2;
	border-radius: 8px;
	background: #ffffff;
	padding: 0 10px;
	/* width: 164px; */
	height: 48px;
`;
const SetButton = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 24px;
    font-size: 16px;
	gap: 16px;
	width: 100%;
	height: 40px;
    box-sizing: border-box;
    width: ${(props) => (props.width ? props.width : "100%")};
	background: #917afd;
	border-radius: 8px;
	cursor: pointer;
	color: #ffffff;
`;
const TimePeriodContainer = styled.div`
	border-radius: 8px;
	background: #edf5fe;
	padding: 12px;
    // height: calc(80vh - 300px);
    // overflow-y: scroll;
    /* width: 600px;
    overflow-y: scroll;
    max-height: calc(100% - 200px); */
    /* height: calc(80vh - 300px);
    overflow-y: scroll; */
`;
const TitleName = styled.div`
	text-align: center;
	border-bottom: 1px solid #c1c1c1;
`;
const TotelContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 12px;
`;
const TotelI = styled.div``;
const TotelP = styled.div``;
const TotelD = styled.div``;
const RightBoxTititle = styled.p`
	color: #9d9d9d;
	font-size: 14px;
    /* font-family: "poppinsregular"; */
`;
const DivValue = styled.p`
	color: #000;
	font-size: 16px;
    /* font-family: "poppinsregular"; */
`;
const TableList = styled.div`
    width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Table = styled.table`
	padding: 16px;
	border-radius: 1rem;
	width: 100%;
	height: 147px;
	border-collapse: collapse;
	overflow: hidden;
	box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px,
		rgb(0 0 0 / 8%) 0px 0px 0px 1px;
`;
const Tr = styled.tr`
	background: #ffffff;
`;
const Tr1 = styled.tr`
	background: #fbfbfb;
`;
const Th = styled.th`
	border-bottom: 1px solid black;
	color: #b4b4b4;
`;
const Td = styled.td`
	text-align: center;
`;

//new table

const TableContainer = styled.div`
	border: 1px solid #acacac;
    border-radius: 4px;
	padding: 0 10px;
	background-color: #fff;
	margin-bottom: 15px;

	min-height: 200px;
`;
const HeadingDiv = styled.div`
	display: flex;
	align-items: center;
	height: 50px;
	justify-content: space-between;
	&:nth-child(2n) {
		background: #fbfbfb;
	}
`;
const TH = styled.div`
    width: ${(props) => (props.width ? props.width : "19%")};
	color: #b4b4b4;
    padding: 0 5px;
	font-size: 14px;
	:last-child {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&.center-align {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

const TD = styled.div`
    width: ${(props) => (props.width ? props.width : "19%")};
	color: #000;
	font-size: 13px;
	padding: 0 5px;
	:last-child {
    display: flex;
		align-items: center;
		justify-content: center;
	}
	&.center-align {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
const TotalContainer = styled.div`
	padding: 0 10px;
	background-color: #fff;
	/* margin-bottom: 15px; */
	/* overflow-y: scroll; */
`;
const CancelButton = styled.div`
	background: #fff;
	border-radius: 8px;
	border: 1px solid #917afd;
	color: #000;
    /* font-family: "poppins-medium"; */
	font-weight: 500;
	height: 40px;
	width: 40%;
    font-size: 15px;
	padding: 12px 24px;
    box-sizing: border-box;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	/* margin-right: 10px; */
`;
const EmiButton = styled.div`
	display: flex;
	align-items: center;
    justify-content: space-between;
`;
const Star = styled.span`
	color: red;
	font-size: 16px;
	margin-left: 5px;
`;