import { useState, useEffect, useContext } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Facebook from "../../assets/images/facebook.svg";
import Instagram from "../../assets/images/instagram.svg";
import Glob from "../../assets/images/glob.svg";
import Education from "../../assets/images/Education.svg";
import Friends from "../../assets/images/friends.svg";
import whatsapp from "../../assets/images/whatsapp.svg";
import Text from "../../components/Text";
import Input from "../../components/Input/input";
import UploadInputLead from "../../components/UploadInput/UploadInputLead";
import Button from "../../components/Button";
import SocialMedia from "../leadGeneratorAdmin/socialMedia/socialMedia";
import styled from "styled-components";
import { Context } from "../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import DeleteModal from "../../components/includes/DeleteModal";
import { Modal } from "@material-ui/core";
import defaultPic from "../../assets/images/live-bg.jpg";

function Source() {
    const [file, setFile] = useState(null);
    const { state } = useContext(Context);
    const [leadSource, setLeadSource] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [sourceName, setSourceName] = useState("");
    const [editId, setEditId] = useState("");
    const [deleteId, setDeleteId] = useState("");
    const [isModal, setModal] = useState(false);
    const [checked, setChecked] = useState(false);
    const handleClick = () => setChecked(!checked);
    const [imageFile, setImageFile] = useState(null);
    const [blobImageUrl, setBlobImageUrl] = useState(null);

    const onImageChange = (e) => {
        setImageFile(e.target.files[0]);
        setBlobImageUrl(URL.createObjectURL(e.target.files[0]));
    };

    // mui modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        if (sourceName) {
            setSourceName("");
        }
        if (blobImageUrl) {
            setBlobImageUrl(null);
        }
        if (checked) {
            setChecked(false);
        }
        if (editId) {
            setEditId("");
        }
    };

    const getLeadSources = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/leads/lead-source/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLeadSource(data.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };
    const getSingleSource = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/leads/lead-source/${editId}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setBlobImageUrl(data.data.image);
                    setSourceName(data.data.name);
                    setChecked(data.data.is_quality);
                } else {
                }
            })
            .catch((error) => {});
    };

    const handleSubmit = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        formdata.append("name", sourceName);
        formdata.append("is_quality", checked);

        if (imageFile !== null) {
            formdata.append("image", imageFile);
        }

        var method = editId ? baseConfig.put : baseConfig.post;
        var URL = editId
            ? `/leads/lead-source/${editId}/`
            : `/leads/lead-source/`;

        method(URL, formdata, {
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode == 6000) {
                    // history.push("/superadmin/list-campus/");
                    getLeadSources();
                    handleClose();
                    setSourceName("");
                    setBlobImageUrl(null);
                    setChecked("");
                    if (editId) {
                        setEditId("");
                    }
                } else if (6001) {
                    alert(data.data.message);
                }
            })
            .catch((error) => {
                alert(error.response.data.data.message);
            });
    };

    const handleDeleteData = () => {
        let accessToken = state.user_details.access_token;

        baseConfig
            .delete(`/leads/lead-source/${deleteId}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode == 6000) {
                    setModal(false);
                    getLeadSources();
                } else if (StatusCode == 6001) {
                    alert(data.data.message);
                    getLeadSources();
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        getLeadSources();
    }, []);
    useEffect(() => {
        if (editId) {
            getSingleSource();
        }
    }, [editId]);

    // const handleSubmit = () => {
    //     let accessToken = state.user_details.access_token;
    //     var formdata = new FormData();
    //     formdata.append("name", sourceName);
    //     formdata.append("is_quality", checked);

    //     if (file !== null) {
    //         formdata.append("image", file[0]);
    //     }

    //     baseConfig
    //         .post("/leads/lead-source/", formdata, {
    //             headers: {
    //                 Authorization: "Bearer " + accessToken,
    //                 "Content-Type": "multipart/form-data",
    //             },
    //         })
    //         .then((response) => {
    //             let { StatusCode, data, message } = response.data;
    //             if (StatusCode == 6000) {
    //                 // history.push("/superadmin/list-campus/");
    //                 getLeadSources();
    //                 handleClose();
    //                 setSourceName("");
    //                 setFile(null);
    //                 setChecked("");
    //             } else if (6001) {
    //                 alert(data.data.message);
    //             }
    //         })
    //         .catch((error) => {
    //             alert(error.response.data.data.message);
    //         });
    // };

    return (
        <Wrapper>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "30px",
                }}
            >
                <div style={{ display: "flex", margin: "0 12px" }}>
                    <Title fontSize="35x">Sources</Title>
                </div>
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        handleOpen();
                        // handleSubmit();
                        // document.getElementById("form").submit();
                    }}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100px",
                    }}
                    bacerkgroundColor="#5A2D8F"
                    fontSize="15px"
                    padding="15px 50px"
                >
                    <Text color="white" fontSize="15px">
                        Add Source
                    </Text>
                </Button>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                {!isLoading ? (
                    <Container
                        style={{
                            // flexWrap: "wrap",
                            // borderRadius: "23px",
                            // display: "flex",
                            // // height: "10% ",
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr 1fr",
                            gridGap: "30px",
                        }}
                        width="100%"
                    >
                        {leadSource?.map((source) => {
                            return (
                                <SocialMedia
                                    data={source}
                                    // width="30%"
                                    fontSize="18px"
                                    setDeleteId={setDeleteId}
                                    setModal={setModal}
                                    setEditId={setEditId}
                                    handleOpen={handleOpen}
                                />
                            );
                        })}
                    </Container>
                ) : (
                    <Div>
                        <LoadingGif
                            src={
                                require("./../../assets/images/loading-buffering.gif")
                                    .default
                            }
                            alt="Loading"
                        />
                    </Div>
                )}
            </div>
            {isModal && (
                <DeleteModal
                    isModal={isModal}
                    setModal={setModal}
                    deleteId={deleteId}
                    handleDeleteData={handleDeleteData}
                />
            )}
            <Modal open={open} onClose={handleClose}>
                <ModalContainer>
                    <Container
                        style={{ flexWrap: "wrap ", borderRadius: "23px" }}
                        width="100%"
                    >
                        <form id="form">
                            <div>
                                <Title
                                    style={{ marginBottom: "10px" }}
                                    fontSize="20px"
                                >
                                    Add new sources
                                </Title>
                                {/* <Text
                                    style={{
                                        color: "#808080",
                                        fontSize: "14px",
                                        marginTop: "3%",
                                    }}
                                >
                                    Upload source image
                                </Text> */}
                                {/* <UploadInputLead
                                    file={file}
                                    setFile={setFile}
                                    accept=".png, .jpg, .jpeg"
                                /> */}
                                <ImageContainer htmlFor="file1">
                                    <UploadImage
                                        type="file"
                                        accept=".png, .jpg, .jpeg"
                                        id="file1"
                                        onChange={(e) => {
                                            // setImageFile(e.target.files[0]);
                                            onImageChange(e);
                                        }}
                                    />
                                    <ImageCard>
                                        {blobImageUrl === null ? (
                                            // <LiveImage
                                            //     // src={require("../../assets/images/live-bg.jpg")}
                                            //     src={defaultPic}
                                            //     alt="image"
                                            // />
                                            <div></div>
                                        ) : (
                                            <LiveImage
                                                src={blobImageUrl}
                                                alt="image"
                                            />
                                        )}
                                    </ImageCard>
                                    <ImageTextDiv>
                                        <TextBox>
                                            <ImageText>
                                                {blobImageUrl
                                                    ? `Edit image`
                                                    : `Upload image`}
                                            </ImageText>
                                        </TextBox>
                                    </ImageTextDiv>
                                </ImageContainer>
                                <Text
                                    color="#808080"
                                    fontSize="15px"
                                    style={{ marginTop: "20px" }}
                                >
                                    Source Name
                                </Text>
                                <Input
                                    value={sourceName}
                                    onChange={(value) => setSourceName(value)}
                                    width="100%"
                                    type="name"
                                    placeholder="Enter Source name here"
                                />
                                <IsQualityContainer>
                                    <input
                                        style={{
                                            marginRight: "10px",
                                            width: "15px",
                                            height: "15px",
                                        }}
                                        onClick={() => {
                                            handleClick();
                                        }}
                                        checked={checked}
                                        // width="100%"
                                        type="checkbox"
                                    />
                                    <Text
                                        color="#5d5a59"
                                        fontSize="15px"
                                        style={{}}
                                    >
                                        Is Quality
                                    </Text>
                                </IsQualityContainer>
                            </div>
                            {/* <div style={{ display: "flex", marginTop: "2%" }}>
                        <Input onChange={(value) => handleChangeValue("isQuality", value)} width="26px" type="checkbox" />

                        <Text style={{ marginTop: "2%" }} color="#7B6294" fontSize="15px">Quality Source</Text>

                    </div> */}
                            <div
                                style={{
                                    display: "flex",
                                    marginTop: "21px",
                                    justifyContent: "space-between",
                                    gap: "10px",
                                }}
                            >
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSubmit();
                                        // document.getElementById("form").submit();
                                    }}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "40%",
                                    }}
                                    bacerkgroundColor="#5A2D8F"
                                    fontSize="15px"
                                    padding="15px 50px"
                                >
                                    <Text color="white" fontSize="15px">
                                        Submit
                                    </Text>
                                </Button>

                                <Button
                                    onClick={() => {
                                        handleClose();
                                    }}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "40%",
                                    }}
                                    backgroundColor="#F4F4F4"
                                    fontSize="15px"
                                    padding="15px 50px"
                                >
                                    <Text color=" #522e92" fontSize="15px">
                                        Cancel
                                    </Text>
                                </Button>
                            </div>
                        </form>
                    </Container>
                </ModalContainer>
            </Modal>
        </Wrapper>
    );
}

export default Source;

const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
`;
const LoadingGif = styled.img`
    display: inline-block;
    width: 50px;
    margin-left: 500px;
    @media (max-width: 980px) {
        width: 90px;
    }
`;
const IsQualityContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;
    justify-content: start;
`;
const ModalContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    max-height: 85vh;
    min-height: 40vh;
    overflow-y: scroll;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 12px;
    box-shadow: 24px;
    padding: 20px;
`;
const UploadImage = styled.input`
    display: none;
`;
const ImageCard = styled.div`
    /* padding: 20px; */
    height: 200px;
    width: 300px;
    border-radius: 6px;
    display: inline-block;
`;
const LiveImage = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 6px;
`;
const ImageContainer = styled.label`
    /* position: relative; */
    display: inline-block;
    position: relative;
    cursor: pointer;
`;
const ImageTextDiv = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;
const TextBox = styled.div`
    background: #8561c6;
    padding: 0 10px;
    border-radius: 6px;
    /* position: absolute; */
    /* background: #8561c6; */
    /* left: 50%;

	/* margin-left: auto; */
    /* margin-right: auto; */
    /* transform: translate(-50%, -50%); */
`;
const ImageText = styled.h4`
    color: #fff;
`;
