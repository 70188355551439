import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import addbutton from "../../assets/images/addbutton.svg";
import search from "../../assets/images/header-search.svg";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/delete.svg";
import { baseConfig } from "./../../utils/config";
import { Context } from "./../../contexts/Store";
import Button from "../../components/Button";
import Text from "../../components/Text";
import { useHistory } from "react-router-dom";
import DeleteModal from "../../components/includes/DeleteModal";
import moment from "moment";
import "./style.css";

export default function ListWebinar({}) {
    const [uploadedFile, setUploadedFile] = useState(null);
    const [warningModal, setWarningModal] = useState("");
    const [isLoading, setLoading] = useState(true);
    const { state } = useContext(Context);
    const [webinarClassList, setWebinarClassList] = useState([]);
    const [searchItem, setSearchItem] = useState("");
    const [isModal, setModal] = useState(false);
    const [deleteId, setDeleteId] = useState("");

    const history = useHistory();

    // grt Assigments
    const getWebinarClasses = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/learn/webinars/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: searchItem,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setWebinarClassList(data.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getWebinarClasses();
    }, [searchItem]);

    // delete webinar class
    const deleteWebinarClass = (id) => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .delete(`/learn/webinar/${id}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode } = response.data;
                if (StatusCode === 6000) {
                    getWebinarClasses();
                    setModal(false);
                } else {
                }
            })
            .catch((error) => {});
    };

    return (
        <Wrapper>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                }}
                className="top-title-wrapper"
            >
                <div>
                    <Title fontSize="35px">Webinar</Title>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                    className="top-title-large-right"
                >
                    <div
                        style={{
                            marginRight: "32px",
                        }}
                    >
                        <form className="search-container">
                            <img src={search} alt="search" />
                            <input
                                className="input-box"
                                type="text"
                                name="search"
                                placeholder="Search for something"
                                onChange={(e) => {
                                    setSearchItem(e.target.value);
                                }}
                            />
                        </form>
                    </div>

                    <Button
                        onClick={(e) => {
                            history.push("/superadmin/add-webinar/");
                        }}
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                        bacerkgroundColor="#5A2D8F"
                        fontSize="15px"
                    >
                        <img src={addbutton} alt="Logo" />
                        <Text
                            style={{ marginLeft: "12px" }}
                            color="white"
                            fontSize="15px"
                        >
                            Add Webinar
                        </Text>
                    </Button>
                </div>
            </div>
            {isLoading === false ? (
                <MainContainer>
                    {webinarClassList.length > 0 ? (
                        <>
                            {webinarClassList.map((data) => (
                                <FirstLine>
                                    <LeftDiv>
                                        <TitleText>{data.title}</TitleText>
                                        <DateDiv>
                                            <TitleLabel>Date : </TitleLabel>
                                            <LabelText>
                                                &nbsp;{data.date}
                                            </LabelText>
                                        </DateDiv>

                                        <DateDiv>
                                            <TitleLabel>
                                                Start Time :{" "}
                                            </TitleLabel>
                                            <LabelText>
                                                &nbsp;
                                                {moment(data.start_time, [
                                                    "h:mm A",
                                                ]).format("hh:mm A")}
                                            </LabelText>
                                        </DateDiv>

                                        <DateDiv>
                                            <TitleLabel>Status : </TitleLabel>
                                            <StatusDiv
                                                background={
                                                    data.status === "Live"
                                                        ? "#2ebfd426"
                                                        : data.status ===
                                                          "Upcoming"
                                                        ? "#eab33b26"
                                                        : "#FDF0EE"
                                                }
                                            >
                                                <StatusText
                                                    color={
                                                        data.status === "Live"
                                                            ? "#2EBFD4"
                                                            : data.status ===
                                                              "Upcoming"
                                                            ? "#EAB33B"
                                                            : "#FF6B53"
                                                    }
                                                >
                                                    {data.status}
                                                </StatusText>
                                            </StatusDiv>
                                        </DateDiv>
                                    </LeftDiv>
                                    <Right>
                                        <IconContainer
                                            onClick={() => {
                                                // setContainer(
                                                // 	"editLive"
                                                // );
                                                // setSelectedData(data);

                                                history.push(
                                                    `/superadmin/edit-webinar/${data.id}`
                                                );
                                            }}
                                        >
                                            <Icon src={editIcon} alt="icon" />
                                        </IconContainer>
                                        <IconContainer
                                            onClick={() => {
                                                setModal(true);
                                                setDeleteId(data.id);
                                                // setSelectedData(data);
                                            }}
                                        >
                                            <Icon src={deleteIcon} alt="icon" />
                                        </IconContainer>
                                    </Right>
                                </FirstLine>
                            ))}
                        </>
                    ) : (
                        // <div className="no-result">
                        <Div>
                            <text className="no-result-text">
                                No results found{" "}
                            </text>
                        </Div>
                        // </div>
                    )}
                </MainContainer>
            ) : (
                <Div>
                    <LoadingGif
                        src={
                            require("./../../assets/images/loading-buffering.gif")
                                .default
                        }
                        alt="Loading"
                    />
                </Div>
            )}

            {isModal && (
                <DeleteModal
                    isModal={isModal}
                    setModal={setModal}
                    deleteId={deleteId}
                    handleDeleteData={deleteWebinarClass}
                />
            )}
        </Wrapper>
    );
}

const MainContainer = styled.div`
    /* background: #ffffff; */
    border-radius: 8px;
    /* padding: 15px; */
    margin-top: 10px;
    margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const FirstLine = styled.div`
    display: flex;
    align-items: flex-start;
    /* align-items: center; */
    justify-content: space-between;
    margin-bottom: 15px;
    background: #ffffff;
    border-radius: 8px;
    padding: 15px;
`;
const LeftDiv = styled.div``;
const DateDiv = styled.div`
    display: flex;
    align-items: center;
    margin-top: 5px;
`;
const TitleLabel = styled.h5`
    color: #000;
`;
const LabelText = styled.h5`
    color: #808080;
`;
const StatusDiv = styled.div`
    margin-left: 10px;
    display: flex;
    align-items: center;
    /* justify-content: flex-start; */
    background: ${(props) => (props.background ? props.background : null)};
    padding: 0 15px;
    height: 30px;
    border-radius: 16px;
    width: fit-content;
`;
const StatusText = styled.h5`
    color: ${(props) => (props.color ? props.color : null)};
    font-size: 15px;
`;
const Right = styled.div`
    display: flex;
    align-items: center;
`;
const IconContainer = styled.div`
    width: 17px;
    cursor: pointer;
    margin-left: 15px;
    /* display: none; */
`;
const Icon = styled.img`
    display: block;
    width: 100%;
    transition: 0.5s;
    &:hover {
        box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
        transform: translateY(-0.1em);
    }
`;
const TitleText = styled.div`
    color: #313131;
    font-size: 1rem;
    font-weight: 500;
`;
const UploadedContainer = styled.div`
    background: rgba(121, 163, 88, 0.1);
    border-radius: 6px;
    /* width: 250px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 10px;
    width: fit-content;
`;
const LeftPart = styled.div`
    display: flex;
    align-items: center;
`;
const DocIcon = styled.img`
    width: 17px;
    display: block;
    object-fit: cover;
`;
const UploadedText = styled.span`
    font-size: 0.8rem;
    color: #79a358;
    margin-left: 8px;
`;
const ViewText = styled.span`
    font-size: 0.75rem;
    color: #522f91;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
`;
// const MainContainer = styled.div``;
// const MainContainer = styled.div``;
// const MainContainer = styled.div``;

const UploadFileButton = styled.label`
    display: flex;
    background: #79a358;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    width: 150px;
    box-sizing: border-box;
    height: 37px;
    margin-bottom: 15px;
    cursor: pointer;
`;
const UploadIcon = styled.img`
    width: 18px;
    height: 13px;
    display: block;
    object-fit: contain;
    margin-right: 8px;
`;
const UploadText = styled.div`
    color: #ffffff;
    font-size: 0.83rem;
`;

const AddLessonGrButtonWrapper = styled.div`
    width: 100%;
    border: 1px dashed #79a258;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    margin-top: 25px;
`;
const AddLessonGreenButton = styled.button`
    background: #79a358;
    border-radius: 8px;
    padding: 10px 14px;
    font-size: 0.9rem;
    color: #fff;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
        box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
        transform: translateY(-0.1em);
    }
`;
const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
`;
const LoadingGif = styled.img`
    display: inline-block;
    width: 50px;
    @media (max-width: 980px) {
        width: 90px;
    }
`;
