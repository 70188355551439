import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Button from "../../components/Button";
import Text from "../../components/Text";
import addbutton from "../../assets/images/addbutton.svg";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import { AddLeadsService } from "../../service/leads";
import { useHistory, useParams } from "react-router-dom";
import { AddSourceService, ListSourceService } from "../../service/sources";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import styled from "styled-components";
import loadingGif from "../../assets/lottie/loading.gif";
export default function ViewMoreModal({ setIsViewMore, selectedLead }) {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const { state } = useContext(Context);

    const [leadData, setLeadData] = useState(false);
    const getSingleLead = () => {
        setIsLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/leads/lead/${selectedLead?.id}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLeadData(data.data);
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 200);
                } else {
                }
            })
            .catch((error) => {});
    };
    useEffect(() => {
        getSingleLead();
    }, []);
    return (
        <BackContainer>
            <Overlay
                onClick={() => {
                    setIsViewMore(false);
                }}
            ></Overlay>
            <Modal>
                {!isLoading ? (
                    <>
                        <SectionBox>
                            {/* <SectionTitle>BasicLeadInfo</SectionTitle> */}
                            {/* <FirstLine>
                                <SectionDetail>
                                    <SectionDetailTitle>
                                        ID No:
                                    </SectionDetailTitle>
                                    <SectionDetailDetailed>
                                        {leadData.lead_id}
                                    </SectionDetailDetailed>
                                </SectionDetail>
                                <SectionDetail>
                                    <SectionDetailTitle>
                                        Enquiry Date:
                                    </SectionDetailTitle>
                                    <SectionDetailDetailed>
                                        {leadData.enquiry_date}
                                    </SectionDetailDetailed>
                                </SectionDetail>
                                <SectionDetail>
                                    <SectionDetailTitle>
                                        Lead Age:
                                    </SectionDetailTitle>
                                    <SectionDetailDetailed>
                                        {leadData?.lead_age?.total_days} days
                                    </SectionDetailDetailed>
                                </SectionDetail>
                            </FirstLine> */}
                            <SectionDetailsBox>
                                <SectionDetail>
                                    <SectionDetailTitle>
                                        Name:
                                    </SectionDetailTitle>
                                    <SectionDetailDetailed>
                                        {leadData.name}
                                    </SectionDetailDetailed>
                                </SectionDetail>
                                <SectionDetail>
                                    <SectionDetailTitle>
                                        ID No:
                                    </SectionDetailTitle>
                                    <SectionDetailDetailed>
                                        {leadData.lead_id}
                                    </SectionDetailDetailed>
                                </SectionDetail>
                                <SectionDetail>
                                    <SectionDetailTitle>
                                        Enquiry Date:
                                    </SectionDetailTitle>
                                    <SectionDetailDetailed>
                                        {leadData.enquiry_date}
                                    </SectionDetailDetailed>
                                </SectionDetail>
                                <SectionDetail>
                                    <SectionDetailTitle>
                                        Lead Age:
                                    </SectionDetailTitle>
                                    <SectionDetailDetailed>
                                        {leadData?.lead_age?.total_days} days
                                    </SectionDetailDetailed>
                                </SectionDetail>
                                <SectionDetail>
                                    <SectionDetailTitle>
                                        Phone No:
                                    </SectionDetailTitle>
                                    <SectionDetailDetailed>
                                        {leadData.phone}
                                    </SectionDetailDetailed>
                                </SectionDetail>
                                <SectionDetail>
                                    <SectionDetailTitle>
                                        Email ID:
                                    </SectionDetailTitle>
                                    <SectionDetailDetailed>
                                        {leadData.email}
                                    </SectionDetailDetailed>
                                </SectionDetail>
                                <SectionDetail>
                                    <SectionDetailTitle>
                                        University:
                                    </SectionDetailTitle>
                                    <SectionDetailDetailed>
                                        {leadData.university_name}
                                    </SectionDetailDetailed>
                                </SectionDetail>
                                <SectionDetail>
                                    <SectionDetailTitle>
                                        Program:
                                    </SectionDetailTitle>
                                    <SectionDetailDetailed>
                                        {leadData.course_name}
                                    </SectionDetailDetailed>
                                </SectionDetail>
                                <SectionDetail>
                                    <SectionDetailTitle>
                                        Country:
                                    </SectionDetailTitle>
                                    <SectionDetailDetailed>
                                        {leadData.country_name}
                                    </SectionDetailDetailed>
                                </SectionDetail>
                                {/* <SectionDetail>
                    <SectionDetailTitle>Nationality:</SectionDetailTitle>
                    <SectionDetailDetailed>
                      {leadData.nationality}
                    </SectionDetailDetailed>
                  </SectionDetail> */}
                                {/* <SectionDetail>
											<SectionDetailTitle>
												Nationality
											</SectionDetailTitle>
											<SectionDetailDetailed>
												{leadData?.source_name}
											</SectionDetailDetailed>
										</SectionDetail> */}
                                <SectionDetail>
                                    <SectionDetailTitle>
                                        Lead Source:
                                    </SectionDetailTitle>
                                    <SectionDetailDetailed>
                                        {leadData?.source_name}
                                    </SectionDetailDetailed>
                                </SectionDetail>
                                {leadData.specialization && (
                                    <SectionDetail>
                                        <SectionDetailTitle>
                                            Specialization:
                                        </SectionDetailTitle>
                                        <SectionDetailDetailed>
                                            {leadData.specialization}
                                        </SectionDetailDetailed>
                                    </SectionDetail>
                                )}
                                {leadData.stage_name && (
                                    <SectionDetail>
                                        <SectionDetailTitle>
                                            Lead Stage:
                                        </SectionDetailTitle>
                                        <SectionDetailDetailed>
                                            {leadData.stage_name}
                                        </SectionDetailDetailed>
                                    </SectionDetail>
                                )}
                            </SectionDetailsBox>
                            {leadData.description && (
                                <LastLine>
                                    <SectionDetail>
                                        <SectionDetailTitle>
                                            Description:
                                        </SectionDetailTitle>
                                        <SectionDetailDetailed>
                                            {leadData.description}
                                        </SectionDetailDetailed>
                                    </SectionDetail>
                                </LastLine>
                            )}
                        </SectionBox>
                    </>
                ) : (
                    <LoaderContainer>
                        <LoaderIcon src={loadingGif} alt="icon" />
                    </LoaderContainer>
                )}
            </Modal>
        </BackContainer>
    );
}

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.2);
    // backdrop-filter: blur(2px);
`;
const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    cursor: pointer;
    height: 100vh;
`;
const Modal = styled.div`
    margin: 0 auto;
    background: #fff;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 23px;
    transition: 0.5s;
    z-index: 101;
    width: 780px;
    max-height: 80vh;
    min-height: 300px;
    padding: 25px;
    box-sizing: border-box;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
`;
const NameContainer = styled.div``;
const OtherDetailsWrapper = styled.div``;
const LoaderContainer = styled.div`
    box-sizing: border-box;
    /* height: 300px; */
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LoaderIcon = styled.img`
    width: 60px;
    display: block;
    object-fit: cover;
`;
const SectionBox = styled.div``;
const SectionTitle = styled.div`
    font-size: 18px;
    color: #000;
    font-weight: 500;
    padding: 10px 15px;
    background: #cee1f2;
    margin-bottom: 10px;
`;
const FirstLine = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    margin-bottom: 30px;
    padding: 0 15px;
`;
const SectionDetailsBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    padding: 0 15px;
`;
const SectionDetail = styled.div`
    display: flex;
    align-items: center;
`;
const SectionDetailTitle = styled.div`
    color: #808080;
    font-size: 15px;
    /* margin-bottom: 4px; */
    margin-right: 8px;
`;
const SectionDetailDetailed = styled.div`
    color: #7b6294;
    font-size: 16px;
`;
const LastLine = styled.div`
    margin-top: 20px;
    padding: 0 15px;
`;
// const DetailedRemarksWrapper
const DetailedRemarksWrapper = styled.div`
    padding: 0 15px;
`;
