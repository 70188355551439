import React, { useContext, useEffect, useRef, useState } from "react";
import Wrapper from "../../components/Wrapper";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Title from "../../components/Title";
import searchIcon from "../../assets/images/finance-search-icon.svg";
import filterIcon from "../../assets/images/finance-filter-icon.svg";
import selectedIcon from "../../assets/images/checkbox-selected.svg";
import unSelectedIcon from "../../assets/images/checkbox-unselected.svg";
import selectedRadioIcon from "../../assets/images/radio-s2.svg";
import unSelectedRadioIcon from "../../assets/images/radio-n-s2.svg";
import profileIcon from "../../assets/images/profile.png";
import addIcon from "../../assets/images/plus-icon.svg";
import loadingIcon from "../../assets/lottie/loading.gif";
import downIcon from "../../assets/images/filter-down-arrow.svg";
import viewIcon from "../../assets/images/view-icon.svg";
import { Context } from "../../contexts/Store";
import { baseConfig } from "../../utils/config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FormControl, MenuItem, Modal, Popover, Select } from "@mui/material";
import MainPagination from "../../components/includes/MainPagination";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";

export default function AssignedStudents() {
    const [search, setSearch] = useState();
    const { state, dispatch } = useContext(Context);
    const history = useHistory();
    const [studentsData, setAssignedStudentsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectAllCurrentPage, setSelectAllCurrentPage] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState([]);

    const [itemsCount, setItemsCount] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationData, setPaginationData] = useState({});
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [executives, setExecutives] = useState([]);
    const [selectedExecutive, setSelectedExecutive] = useState("");
    const [programs, setPrograms] = useState([]);
    const [selectedProgram, setSelectedProgram] = useState("");
    const [universities, setUniversities] = useState([]);
    const [selectedUniversity, setSelectedUniversity] = useState("");
    const [selectedProgramId, setSelectedProgramId] = useState("");
    const [selectedRemark, setSelectedRemark] = useState("");
    const [selectedFilterPopover, setSelectedFilter] = useState("");
    const [filterClearAction, setFilterClearAction] = useState(false);

    const [selectedUniversityFilterOption, setSelectedUniversityFilterOption] =
        useState("university");
    // Filter popover onClick states and functions
    const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
    const handleClickFilter = (event) => {
        setFilterAnchorEl(event.currentTarget);
        // setSelectedFilter(fieldName);
    };
    const handleCloseFilter = () => {
        setFilterAnchorEl(null);
    };
    const openFilter = Boolean(filterAnchorEl);
    const filterAnchorId = openFilter ? "simple-popover" : undefined;
    // end of filter

    const selectOptions = [
        {
            id: 1,
            name: "All Students",
            value: "",
        },
        {
            id: 2,
            name: "Active",
            value: "Active",
        },
        {
            id: 3,
            name: "On Break",
            value: "On Break",
        },
        {
            id: 4,
            name: "On Hold",
            value: "On Hold",
        },
        {
            id: 5,
            name: "Completed",
            value: "Completed",
        },
        {
            id: 6,
            name: "Dropout",
            value: "Dropout",
        },
    ];
    const [selectedOption, setSelectedOption] = useState(
        selectOptions[0].value
    );

    // table filter popover onClick
    const [tableFilterAnchorEl, setTableFilterAnchorEl] = useState(null);
    const tableFilterButtonRef = useRef(null);
    console.log(tableFilterButtonRef,"name")
    const handleClickTableFilter = (event, fieldName) => {
        setTableFilterAnchorEl(tableFilterButtonRef.current);
        setSelectedFilter(fieldName);
    };
    const handleCloseTableFilter = () => {
        setTableFilterAnchorEl(null);
    };
    const openTableFilter = Boolean(tableFilterAnchorEl);
    const tableFilterPopoverStyle = {
        position: "absolute",
        top: 45,
        left: -183,
    };
    // end of table filter popover

    // mui Detailed remark popover on hover
    const [remarkAnchorEl, setRemarkAnchorEl] = React.useState(null);
    const handleRemarkPopoverOpen = (event, remark) => {
        setRemarkAnchorEl(event.currentTarget);
        setSelectedRemark(remark);
    };
    const handleRemarkPopoverClose = () => {
        setRemarkAnchorEl(null);
    };
    const remarkOpen = Boolean(remarkAnchorEl);
    // end of Detailed remark course popover

    //  for assign executive mui modal
    const [openExecutiveModal, setOpenExecutiveModal] = useState(false);
    const handleOpenExecutiveModal = () => setOpenExecutiveModal(true);
    const handleCloseExecutiveModal = () => {
        setOpenExecutiveModal(false);
    };
    // end of assign executive modal
    // sweetalert states and functions
    // const [isSweetAlert, setIsSweetAlert] = useState(false);
    // const [sweetAlertContent, setSweetAlertContent] = useState({
    //     title: "",
    //     message: "",
    //     icon: "",
    //     confirmButtonText: "",
    //     isCancelButton: false,
    // });
    // const handleSweetAlertConfirm = () => {
    //     history.push("/superadmin/lead-generator");
    // };
    // end of sweetalert

    // alert mui message

    const [alertStatus, setAlertStatus] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [isAlert, setIsAlert] = useState(false);
    const handleClose = () => {
        setIsAlert(false);
        setAlertStatus("");
    };
    const AlertBox = ({ status }) => {
        return (
            <Stack spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                    open={isAlert}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert
                        onClose={handleClose}
                        severity={status}
                        sx={{ width: "100%" }}
                    >
                        {alertMessage}
                    </Alert>
                </Snackbar>
            </Stack>
        );
    };
    // Track the message state
    // mui Extra Course popover on hover
    const [courseAnchorEl, setcourseAnchorEl] = React.useState(null);
    const handlePopoverOpen = (event, courses) => {
        setcourseAnchorEl(event.currentTarget);
        setSelectedCourses(courses);
    };
    const handlePopoverClose = () => {
        setcourseAnchorEl(null);
    };
    const courseOpen = Boolean(courseAnchorEl);
    // end of extra course popover

    // function to check if all students are selected
    const checkSelectedAllCurrentPage = () => {
        const allStudentsSelected = studentsData.every((student) =>
            selectedStudents.includes(student.id)
        );

        // const allStudentsSelected = studentsData.every((student) =>
        //     student.can_assign ? selectedStudents.includes(student.id) : true
        // );

        if (allStudentsSelected && studentsData.length > 0) {
            setSelectAllCurrentPage(true);
        } else {
            setSelectAllCurrentPage(false);
        }
    };

    const getUniversities = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/university-list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setUniversities(data);
                } else {
                    alert(
                        "Some error occured while loading categories. Please try again later"
                    );
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    // get executives
    const getExecutives = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/finance/finance-executive-list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setExecutives(data);
                } else {
                    alert(
                        "Some error occured while loading categories.Please try again later"
                    );
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    const getPrograms = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/course-list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    university: selectedUniversity,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setPrograms(data);
                } else {
                    alert(
                        "Some error occured while loading categories.Please try again later"
                    );
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    // get all assigned students
    const getStudentsData = async () => {
        setIsLoading(true);
        let accessToken = state.user_details.access_token;
        try {
            const response = await baseConfig.get(
                "/finance/assigned-students/",
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                    params: {
                        search: search,
                        university: selectedUniversity,
                        course: selectedProgram,
                        page: currentPage,
                        items: itemsCount,
                        status: selectedOption,
                        finance_executive: selectedExecutive,
                    },
                }
            );

            const { StatusCode, data } = response.data;
            if (StatusCode === 6000) {
                setAssignedStudentsData(data.data);
                setPaginationData(data.paginator);
            } else {
                alert("Some error occurred. Please try again later");
            }
        } catch (error) {
            alert(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAssignExecutive = () => {
        let accessToken = state.user_details.access_token;
        // var formdata = new FormData();
        // formdata.append("students_id", JSON.stringify(selectedStudents));
        // formdata.append("executive_id", selectedExecutive);

        //    jsondata
        let formData = {
            students_id: selectedStudents,
            executive_id: selectedExecutive,
        };

        baseConfig
            .post(`/finance/students-assign-executive/`, formData, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    handleCloseExecutiveModal();
                    getStudentsData();
                    setSelectedStudents([]);
                    setSelectedExecutive("");
                } else {
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    useEffect(() => {
        getStudentsData();
    }, [search, selectedOption, itemsCount, currentPage]);

    useEffect(() => {
        getExecutives();
        getUniversities();
    }, []);
    useEffect(() => {
        if (selectedUniversity) {
            getPrograms();
        }
    }, [selectedUniversity]);

    useEffect(() => {
        if (studentsData) {
            checkSelectedAllCurrentPage();
        }
    }, [studentsData, selectedStudents]);

    useEffect(() => {
        if (!selectedUniversity) {
            setSelectedProgram("");
            setSelectedUniversityFilterOption("university");
        }
    }, [selectedUniversity, selectedProgram]);

    useEffect(() => {
        getStudentsData();
    }, [filterClearAction]);

    return (
        <Wrapper isScrollBar={!isLoading ? true : false}>
            <AlertBox status={alertStatus} />
            {/* <SweetAlert
                sweetAlertContent={sweetAlertContent}
                setSweetAlertContent={setSweetAlertContent}
                isSweetAlert={isSweetAlert}
                setIsSweetAlert={setIsSweetAlert}
                onConfirm={handleSweetAlertConfirm}
            /> */}
            <TitleBox>
                <TitleTextWrapper>
                    <TitleTextCaps>Assigned Students</TitleTextCaps>
                </TitleTextWrapper>
            </TitleBox>
            {/* <TitleBottomLine>
                <GreyText to="/finance-manager/assigned-students/">
                    Students /{" "}
                </GreyText>
                <AnchorText>{""} Assigned</AnchorText>
            </TitleBottomLine> */}
            <FilterLine>
                <FilterLeft>
                    <SelectDiv>
                        {selectOptions.map((item, index) => (
                            <SelectItem
                                key={index}
                                selected={
                                    selectedOption === item.value ? true : false
                                }
                                onClick={() => {
                                    setSelectedOption(item.value);
                                }}
                            >
                                {item.name}
                            </SelectItem>
                        ))}
                    </SelectDiv>
                </FilterLeft>
                <FilterRight>
                    <SearchBoxDiv>
                        <label htmlFor="search">
                            <SearchIcon
                                id="search"
                                src={searchIcon}
                                alt="icon"
                            />
                        </label>
                        <SearchInput
                            type="text"
                            placeholder="Search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </SearchBoxDiv>

                    {studentsData?.length > 0 && (
                        <AssignButton
                            onClick={() => {
                                handleOpenExecutiveModal();
                            }}
                            disabled={
                                selectedStudents.length == 0 ? true : false
                            }
                        >
                            Reassign
                        </AssignButton>
                    )}

                    {(selectedUniversity ||
                        selectedProgram ||
                        selectedExecutive) && (
                        <ClearAllFiltersButton
                            onClick={() => {
                                setSearch("");
                                setSelectedUniversity("");
                                setSelectedProgram("");
                                setSelectedExecutive("");
                                setFilterClearAction(!filterClearAction)
                            }}
                        >
                            Clear All Filters
                        </ClearAllFiltersButton>
                    )}

                    {/* <FilterWrapper>
                        <FilterButton
                            aria-describedby={filterAnchorId}
                            variant="contained"
                            onClick={handleClickFilter}
                            // filterActivated={
                            //     courseValues || specializationValue
                            //         ? true
                            //         : false
                            // }
                        >
                            <FilterIcon src={filterIcon} alt="icon" />
                            Filter
                        </FilterButton>
                        <Popover
                            id={filterAnchorId}
                            open={openFilter}
                            anchorEl={filterAnchorEl}
                            onClose={handleCloseFilter}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <Container>
                                <Div>
                                    <Title fontSize="20px">Filter by</Title>
                                    <img
                                        style={{
                                            cursor: "pointer",
                                            width: "16px",
                                        }}
                                        onClick={handleCloseFilter}
                                        src={
                                            require("./../../assets/images/close.svg")
                                                .default
                                        }
                                        alt="icon"
                                    />
                                </Div>
                                <div
                                    style={{
                                        minHeight: "250px",
                                        maxHeight: "70vh",
                                        overflow: "scroll",
                                    }}
                                >
                                    <ButtonsBody>
                                        <ResetButton
                                        // onClick={() => {

                                        // }}
                                        >
                                            Reset
                                        </ResetButton>
                                        <CloseButton
                                            onClick={() => {
                                                handleCloseFilter();
                                            }}
                                        >
                                            Close
                                        </CloseButton>
                                    </ButtonsBody>
                                </div>
                            </Container>
                        </Popover>
                    </FilterWrapper> */}
                </FilterRight>
            </FilterLine>
            {!isLoading ? (
                <DetailedSection>
                    <TableWrapper>
                        <Table className="table">
                            <Thead>
                                <THR>
                                    <TH>
                                        <HeaderDataContainer>
                                            <CheckBoxIcon
                                                src={
                                                    selectAllCurrentPage
                                                        ? selectedIcon
                                                        : unSelectedIcon
                                                }
                                                alt="icon"
                                                onClick={() => {
                                                    if (selectAllCurrentPage) {
                                                        setSelectedStudents([]);
                                                    } else {
                                                        setSelectedStudents(
                                                            studentsData.map(
                                                                (student) =>
                                                                    student.id
                                                            )
                                                        );
                                                        // setSelectedStudents(
                                                        //     studentsData
                                                        //         .filter(
                                                        //             (student) =>
                                                        //                 student.can_assign
                                                        //         )
                                                        //         .map(
                                                        //             (student) =>
                                                        //                 student.id
                                                        //         )
                                                        // );
                                                    }
                                                }}
                                            />

                                            <THText>Ad. No</THText>
                                        </HeaderDataContainer>
                                    </TH>
                                    <TH>
                                        <HeaderDataContainer>
                                            <THText>Student Name</THText>
                                        </HeaderDataContainer>
                                    </TH>
                                    {/* <TH>
                                        <HeaderDataContainer>
                                            <THText>University</THText>
                                        </HeaderDataContainer>
                                    </TH> */}
                                    <TH>
                                        <HeaderDataContainer>
                                            <THText>Course</THText>
                                            <DownArrow
                                                src={downIcon}
                                                alt="icon"
                                                ref={tableFilterButtonRef}
                                                onClick={(event) => {
                                                    handleClickTableFilter(
                                                        event,
                                                        "course"
                                                    );
                                                }}
                                            />
                                        </HeaderDataContainer>
                                    </TH>
                                    <TH>
                                        <HeaderDataContainer>
                                            <THText> Consultant</THText>
                                        </HeaderDataContainer>
                                    </TH>
                                    <TH>
                                        <HeaderDataContainer>
                                            <THText> Total Fee</THText>
                                        </HeaderDataContainer> 
                                    </TH>
                                    <TH>
                                        <HeaderDataContainer>
                                            <THText>Fee Paid</THText>
                                        </HeaderDataContainer>
                                    </TH>
                                    <TH>
                                        <HeaderDataContainer>
                                            <THText>Overdue</THText>
                                        </HeaderDataContainer>
                                    </TH>
                                    <TH>
                                        <HeaderDataContainer>
                                            <THText>Balance</THText>
                                        </HeaderDataContainer>
                                    </TH>
                                    <TH>
                                        <HeaderDataContainer>
                                            <THText>Due Date</THText>
                                        </HeaderDataContainer>
                                    </TH>
                                    <TH>
                                        <HeaderDataContainer>
                                            <THText>Followup Date</THText>
                                        </HeaderDataContainer>
                                    </TH>
                                    <TH>
                                        <HeaderDataContainer>
                                            <THText>Paid Date</THText>
                                        </HeaderDataContainer>
                                    </TH>
                                    {/* <TH>
                                        <HeaderDataContainer>
                                            <THText>Next Due Date</THText>
                                        </HeaderDataContainer>
                                    </TH> */}
                                    <TH>
                                        <HeaderDataContainer>
                                            <THText>EMI Amount</THText>
                                        </HeaderDataContainer>
                                    </TH>
                                    <TH>
                                        <HeaderDataContainer>
                                            <THText>Executive</THText>
                                            <DownArrow
                                                src={downIcon}
                                                alt="icon"
                                                ref={tableFilterButtonRef}
                                                onClick={(event) => {
                                                    handleClickTableFilter(
                                                        event,
                                                        "executive"
                                                    );
                                                }}
                                            />
                                        </HeaderDataContainer>
                                    </TH>
                                    <TH>
                                        <HeaderDataContainer>
                                            <THText>Remarks</THText>
                                        </HeaderDataContainer>
                                    </TH>

                                    {/* <TH></TH> */}
                                </THR>
                                <Popover
                                    style={tableFilterPopoverStyle}
                                    open={openTableFilter}
                                    anchorEl={tableFilterAnchorEl}
                                    onClose={handleCloseTableFilter}
                                >
                                    {selectedFilterPopover === "course" && (
                                        <MainBox>
                                            <PopoverSelectDiv>
                                                <PopoverSelectItem
                                                    key={"university"}
                                                    selected={
                                                        selectedUniversityFilterOption ===
                                                        "university"
                                                    }
                                                    onClick={() => {
                                                        setSelectedUniversityFilterOption(
                                                            "university"
                                                        );
                                                    }}
                                                >
                                                    Universities
                                                </PopoverSelectItem>
                                                <PopoverSelectItem
                                                    key={"Program"}
                                                    selected={
                                                        selectedUniversityFilterOption ===
                                                        "Program"
                                                    }
                                                    disabled={
                                                        selectedUniversity !==
                                                        ""
                                                            ? false
                                                            : true
                                                    }
                                                    onClick={() => {
                                                        if (
                                                            selectedUniversity !==
                                                            ""
                                                        ) {
                                                            setSelectedUniversityFilterOption(
                                                                "Program"
                                                            );
                                                        } else {
                                                            setAlertStatus(
                                                                "error"
                                                            );
                                                            setIsAlert(true);
                                                            setAlertMessage(
                                                                "Select a university"
                                                            );
                                                        }
                                                    }}
                                                >
                                                    Courses
                                                </PopoverSelectItem>
                                            </PopoverSelectDiv>
                                            {selectedUniversityFilterOption ==
                                                "university" && (
                                                <ProgramCards>
                                                    {universities?.map(
                                                        (singleUniversity) => (
                                                            <ProgramCard
                                                                key={
                                                                    singleUniversity.id
                                                                }
                                                                onClick={() => {
                                                                    if (
                                                                        selectedUniversity ==
                                                                        singleUniversity.id
                                                                    ) {
                                                                        setSelectedUniversity(
                                                                            ""
                                                                        ); // Deselect the current program
                                                                    } else {
                                                                        setSelectedUniversity(
                                                                            singleUniversity.id
                                                                        ); // Select the clicked program
                                                                    }
                                                                }}
                                                            >
                                                                <RadioButtonIcon
                                                                    src={
                                                                        selectedUniversity ==
                                                                        singleUniversity.id
                                                                            ? selectedRadioIcon
                                                                            : unSelectedRadioIcon
                                                                    }
                                                                    alt="icon"
                                                                />
                                                                <ProgramName>
                                                                    {
                                                                        singleUniversity?.name
                                                                    }
                                                                </ProgramName>
                                                            </ProgramCard>
                                                        )
                                                    )}
                                                </ProgramCards>
                                            )}

                                            {selectedUniversityFilterOption ==
                                                "Program" && (
                                                <ProgramCards>
                                                    {programs?.map(
                                                        (singleProgram) => (
                                                            <ProgramCard
                                                                key={
                                                                    singleProgram.id
                                                                }
                                                                onClick={() => {
                                                                    if (
                                                                        selectedProgram ==
                                                                        singleProgram.id
                                                                    ) {
                                                                        setSelectedProgram(
                                                                            ""
                                                                        ); // Deselect the current program
                                                                    } else {
                                                                        setSelectedProgram(
                                                                            singleProgram.id
                                                                        ); // Select the clicked program
                                                                    }
                                                                }}
                                                            >
                                                                <RadioButtonIcon
                                                                    src={
                                                                        selectedProgram ==
                                                                        singleProgram.id
                                                                            ? selectedRadioIcon
                                                                            : unSelectedRadioIcon
                                                                    }
                                                                    alt="icon"
                                                                />
                                                                <ProgramName>
                                                                    {
                                                                        singleProgram?.name
                                                                    }
                                                                </ProgramName>
                                                            </ProgramCard>
                                                        )
                                                    )}
                                                </ProgramCards>
                                            )}
                                            <ButtonsContainer>
                                                <CancelPopoverButton
                                                    onClick={() => {
                                                        handleCloseTableFilter();
                                                    }}
                                                >
                                                    Cancel
                                                </CancelPopoverButton>
                                                <ApplyPopoverButton
                                                    onClick={() => {
                                                        getStudentsData();
                                                        handleCloseTableFilter();
                                                    }}
                                                >
                                                    Apply
                                                </ApplyPopoverButton>
                                            </ButtonsContainer>
                                        </MainBox>
                                    )}
                                    {selectedFilterPopover === "executive" && (
                                        <MainBox>
                                            <SubTitle>
                                                {executives?.length} Executives
                                            </SubTitle>
                                            <ProgramCards>
                                                {executives?.map(
                                                    (executive) => (
                                                        <ProgramCard
                                                            key={executive.id}
                                                            onClick={() => {
                                                                if (
                                                                    selectedExecutive ==
                                                                    executive.id
                                                                ) {
                                                                    setSelectedExecutive(
                                                                        ""
                                                                    ); // Deselect the current program
                                                                } else {
                                                                    setSelectedExecutive(
                                                                        executive.id
                                                                    ); // Select the clicked program
                                                                }
                                                            }}
                                                        >
                                                            <RadioButtonIcon
                                                                src={
                                                                    selectedExecutive ==
                                                                    executive.id
                                                                        ? selectedRadioIcon
                                                                        : unSelectedRadioIcon
                                                                }
                                                                alt="icon"
                                                            />
                                                            <ProgramName>
                                                                {
                                                                    executive?.name
                                                                }
                                                            </ProgramName>
                                                        </ProgramCard>
                                                    )
                                                )}
                                            </ProgramCards>

                                            <ButtonsContainer>
                                                <CancelPopoverButton
                                                    onClick={() => {
                                                        handleCloseTableFilter();
                                                    }}
                                                >
                                                    Cancel
                                                </CancelPopoverButton>
                                                <ApplyPopoverButton
                                                    onClick={() => {
                                                        getStudentsData();
                                                        handleCloseTableFilter();
                                                    }}
                                                >
                                                    Apply
                                                </ApplyPopoverButton>
                                            </ButtonsContainer>
                                        </MainBox>
                                    )}
                                </Popover>
                            </Thead>
                            {studentsData?.length > 0 ? (
                                <Tbody>
                                    {studentsData?.map((student) => (
                                        <TDR key={student.id}>
                                            <TD>
                                                <BodyDataContainer>
                                                    <CheckBoxIcon
                                                        src={
                                                            selectedStudents.includes(
                                                                student.id
                                                            )
                                                                ? selectedIcon
                                                                : unSelectedIcon
                                                        }
                                                        onClick={() => {
                                                            if (
                                                                selectedStudents.includes(
                                                                    student.id
                                                                )
                                                            ) {
                                                                setSelectedStudents(
                                                                    selectedStudents.filter(
                                                                        (id) =>
                                                                            id !==
                                                                            student.id
                                                                    )
                                                                );
                                                            } else {
                                                                setSelectedStudents(
                                                                    [
                                                                        ...selectedStudents,
                                                                        student.id,
                                                                    ]
                                                                );
                                                            }
                                                        }}
                                                        alt="icon"
                                                    />
                                                    <TDText
                                                        onClick={() => {
                                                            history.push(
                                                                `/finance-manager/assigned-student/${student.id}`
                                                            );
                                                        }}
                                                        className="link-td-text"
                                                    >
                                                        {
                                                            student.admission_number
                                                        }
                                                    </TDText>
                                                </BodyDataContainer>
                                            </TD>
                                            <TD>
                                                <BodyDataContainer>
                                                    <ProfilePic
                                                        src={
                                                            student.image
                                                                ? student.image
                                                                : profileIcon
                                                        }
                                                        alt="icon"
                                                    />
                                                    <NameContainer>
                                                        <TDText>
                                                            {student.name}
                                                        </TDText>
                                                        {/* <TDText
                                                            style={{
                                                                marginTop:
                                                                    "4px",
                                                            }}
                                                        >
                                                            {student.phone}
                                                        </TDText> */}
                                                    </NameContainer>
                                                </BodyDataContainer>
                                            </TD>
                                            {/* <TD>
                                                <TDText>SSM</TDText>
                                            </TD> */}
                                            <TD>
                                                <TDText>
                                                    {student.courses[0]}
                                                    {student.courses?.length >
                                                        1 && (
                                                        <ExtraCourse
                                                            onMouseEnter={(
                                                                e
                                                            ) => {
                                                                handlePopoverOpen(
                                                                    e,
                                                                    student.courses
                                                                );
                                                            }}
                                                            onMouseLeave={
                                                                handlePopoverClose
                                                            }
                                                        >
                                                            (+
                                                            {student.courses
                                                                .length - 1}
                                                            )
                                                        </ExtraCourse>
                                                    )}

                                                    <Popover
                                                        id="mouse-over-popover"
                                                        // sx={{
                                                        //     pointerEvents: "none",
                                                        // }}
                                                        open={courseOpen}
                                                        anchorEl={
                                                            courseAnchorEl
                                                        }
                                                        anchorOrigin={{
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        }}
                                                        transformOrigin={{
                                                            vertical: "top",
                                                            horizontal: "left",
                                                        }}
                                                        sx={{
                                                            pointerEvents:
                                                                "none",

                                                            "&& .MuiPopover-paper":
                                                                {
                                                                    boxShadow:
                                                                        "rgba(0, 0, 0, 0.03) 0px 2px 4px",
                                                                    // boxShadow:
                                                                    //     "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                                                                    // boxShadow:
                                                                    //     "none",
                                                                },
                                                        }}
                                                        onClose={
                                                            handlePopoverClose
                                                        }
                                                        disableRestoreFocus
                                                    >
                                                        <div>
                                                            {selectedCourses.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <DetailLine
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <DetailText>
                                                                            {
                                                                                item
                                                                            }
                                                                        </DetailText>
                                                                        {/* <VisibleIcon src={eyeIcon} alt="icon" /> */}
                                                                    </DetailLine>
                                                                )
                                                            )}
                                                        </div>
                                                    </Popover>
                                                </TDText>
                                            </TD>
                                            <TD>
                                                <TDText>
                                                    {" "}
                                                    {student.consultant
                                                        ? student.consultant
                                                        : `---`}
                                                </TDText>
                                            </TD>
                                            <TD>
                                                <TDText>
                                                    {new Intl.NumberFormat(
                                                        "en-US",
                                                        {
                                                            style: "currency",
                                                            currency: "AED",
                                                        }
                                                    ).format(
                                                        student.emi_details
                                                            .total_course_fees
                                                    )}
                                                    {/* {
                                                        student.emi_details
                                                            .total_course_fees
                                                    } */}
                                                </TDText>
                                            </TD>
                                            <TD>
                                                <TDText>
                                                    {new Intl.NumberFormat(
                                                        "en-US",
                                                        {
                                                            style: "currency",
                                                            currency: "AED",
                                                        }
                                                    ).format(
                                                        student.emi_details
                                                            .total_fee_paid
                                                    )}
                                                    {/* {
                                                        student.emi_details
                                                            .total_fee_paid
                                                    } */}
                                                </TDText>
                                            </TD>
                                            <TD>
                                                <TDText>
                                                    {new Intl.NumberFormat(
                                                        "en-US",
                                                        {
                                                            style: "currency",
                                                            currency: "AED",
                                                        }
                                                    ).format(
                                                        student.emi_details
                                                            .total_due_amount
                                                    )}
                                                    {/* {
                                                        student.emi_details
                                                            .total_due_amount
                                                    } */}
                                                </TDText>
                                            </TD>
                                            <TD>
                                                <TDText>
                                                    {new Intl.NumberFormat(
                                                        "en-US",
                                                        {
                                                            style: "currency",
                                                            currency: "AED",
                                                        }
                                                    ).format(
                                                        student.emi_details
                                                            .total_balance_amount
                                                    )}
                                                    {/* {
                                                        student.emi_details
                                                            .total_balance_amount
                                                    } */}
                                                </TDText>
                                            </TD>
                                            <TD>
                                                <TDText>
                                                    {" "}
                                                    {
                                                        student.emi_details
                                                            .due_date
                                                    }
                                                </TDText>
                                            </TD>
                                            <TD>
                                                <TDText>
                                                    {student.next_followup
                                                        ? student.next_followup
                                                        : "---"}
                                                </TDText>
                                            </TD>
                                            <TD>
                                                <TDText>
                                                    {/* {student.executive} */}
                                                    Last Pay Date
                                                </TDText>
                                            </TD>
                                            {/* <TD>
                                                <TDText>
                                                    Next Due Date
                                                </TDText>
                                            </TD> */}
                                            <TD>
                                                <TDText>
                                                    {/* {student.executive} */}
                                                    {new Intl.NumberFormat(
                                                        "en-US",
                                                        {
                                                            style: "currency",
                                                            currency: "AED",
                                                        }
                                                    ).format(
                                                        student.emi_details
                                                            .emi_amount
                                                    )}
                                                    {/* {
                                                        student.emi_details
                                                            .emi_amount
                                                    } */}
                                                </TDText>
                                            </TD>
                                            <TD>
                                                <TDText>
                                                    {student.executive}
                                                </TDText>
                                            </TD>
                                            <TD style={{ maxWidth: "130px" }}>
                                                <TDText
                                                    style={{
                                                        maxWidth: "130px",
                                                        overflow: "hidden",
                                                        cursor: "context-menu",
                                                        textOverflow:
                                                            "ellipsis",
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        handleRemarkPopoverOpen(
                                                            e,
                                                            student.remark
                                                        );
                                                    }}
                                                    onMouseLeave={
                                                        handleRemarkPopoverClose
                                                    }
                                                >
                                                    {student.remark}
                                                </TDText>
                                                <Popover
                                                    id="mouse-over-remark-popover"
                                                    open={remarkOpen}
                                                    anchorEl={remarkAnchorEl}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "left",
                                                    }}
                                                    sx={{
                                                        pointerEvents: "none",
                                                        "&& .MuiPopover-paper":
                                                            {
                                                                boxShadow:
                                                                    "rgba(0, 0, 0, 0.05) 0px 3px 8px",
                                                                // boxShadow: "none",
                                                            },
                                                    }}
                                                    onClose={
                                                        handleRemarkPopoverClose
                                                    }
                                                    disableRestoreFocus
                                                >
                                                    <DetailedRemarkDiv>
                                                        <DetailedRemarkText>
                                                            {selectedRemark}
                                                        </DetailedRemarkText>
                                                    </DetailedRemarkDiv>
                                                </Popover>
                                            </TD>
                                            {/* <TD>
                                                <ViewIcon
                                                    src={viewIcon}
                                                    onClick={() => {
                                                        history.push(
                                                            `/finance-manager/assigned-student/${student.id}`
                                                        );
                                                    }}
                                                    alt="icon"
                                                />
                                            </TD> */}
                                        </TDR>
                                    ))}
                                </Tbody>
                            ) : (
                                <Tbody>
                                    <TDR>
                                        <TD
                                            className="no-data-cell"
                                            colSpan="13"
                                            // colSpan={
                                            //     selectedTab == "Evaluated"
                                            //         ? "10"
                                            //         : "9"
                                            // }
                                        >
                                            No data found
                                        </TD>
                                    </TDR>
                                </Tbody>
                            )}
                        </Table>
                    </TableWrapper>
                    <div style={{ marginTop: "20px" }}>
                        <MainPagination
                            paginationData={paginationData}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            marginRight="0"
                            isCountSelector={true}
                            itemsCount={itemsCount}
                            setItemsCount={setItemsCount}
                            currentItemsCount={studentsData?.length}
                        />
                    </div>
                </DetailedSection>
            ) : (
                <LoaderContainer>
                    <LoadingGif src={loadingIcon} alt="icon" />
                </LoaderContainer>
            )}
            {/* Assign Executive Modal */}
            <Modal
                open={openExecutiveModal}
                onClose={handleCloseExecutiveModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ExecutiveModalContainer>
                    <ModalTitle>Assign Finance Executive</ModalTitle>
                    <Href></Href>
                    <LightContainer>
                        <FormInput>
                            <FormInputTitle>Executive</FormInputTitle>
                            <FormControl
                                // sx={{ m: 1, minWidth: 120 }}
                                fullWidth
                                // size="small"
                            >
                                <Select
                                    sx={{
                                        height: 50,
                                        fontSize: "16px",
                                        background: "#fff",
                                    }}
                                    id="cr-type"
                                    onChange={(e) => {
                                        setSelectedExecutive(e.target.value);
                                    }}
                                    value={selectedExecutive}
                                    displayEmpty
                                    placeholder="Choose Category"
                                    disabled={
                                        executives.length < 1 ? true : false
                                    }
                                    // disabled={props.isTopup ? false : true}
                                >
                                    <MenuItem
                                        style={{ display: "none" }}
                                        value=""
                                        disabled
                                    >
                                        Choose Executive
                                    </MenuItem>
                                    {executives?.map((data) => (
                                        <MenuItem key={data.id} value={data.id}>
                                            {data.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FormInput>
                    </LightContainer>
                    <ButtonsWrapper>
                        <CancelButton
                            onClick={() => {
                                handleCloseExecutiveModal();
                                if (selectedExecutive) {
                                    setSelectedExecutive("");
                                }
                            }}
                        >
                            Cancel
                        </CancelButton>
                        <ApplyButton
                            onClick={() => {
                                if (
                                    selectedStudents.length == 0 &&
                                    selectedExecutive == ""
                                ) {
                                    alert(
                                        "Select Students and Executive to assign"
                                    );
                                } else if (selectedExecutive == "") {
                                    alert("Select an Executive");
                                } else if (selectedStudents.length == 0) {
                                    alert("Select atleast one Student");
                                } else {
                                    handleAssignExecutive();
                                }
                            }}
                        >
                            Save
                        </ApplyButton>
                    </ButtonsWrapper>
                </ExecutiveModalContainer>
            </Modal>
            {/* End of assign executive modal */}
        </Wrapper>
    );
}

const LoaderContainer = styled.div`
    height: calc(100vh - 210px);
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #0f0; */
`;
const LoadingGif = styled.img`
    width: 60px;
    display: block;
    object-fit: cover;
`;
const TitleBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 20px; */
`;
const TitleTextWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const TitleTextCaps = styled.h6`
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    color: #000000;
    text-transform: capitalize;
    margin-right: 12px;
`;
const TitleText = styled.h6`
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    color: #000000;
    text-transform: capitalize;
`;
const TitleBottomLine = styled.div`
    display: flex;
    align-items: center;
    margin-top: 4px;
`;
const GreyText = styled(Link)`
    color: #9d9d9d;
    font-size: 16px;
`;
const AnchorText = styled.span`
    display: inline-block;
    color: #010101;
    font-size: 16px;
    margin-left: 3px;
`;
const FilterLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
`;
const FilterLeft = styled.div``;
const SelectDiv = styled.div`
    height: 42px;
    box-sizing: border-box;
    padding: 4px;
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #e7e7e785;
    border-radius: 12px;
`;
const SelectItem = styled.div`
    padding: 8px 10px;
    background: ${(props) => (props.selected ? "rgb(237, 245, 254)" : "#fff")};
    border-radius: 8px;
    font-size: 15px;
    cursor: pointer;
`;
const FilterRight = styled.div`
    display: flex;
    align-items: center;
`;
const SearchBoxDiv = styled.div`
    width: 270px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 8px;
    width: 270px;
    height: 42px;

    /* background: #f5f5f5; */
    background: #fff;
    border-radius: 8px;
    /* border: 1px solid #f5f5f5; */
    border: 1px solid #e7e7e785;

    input[type="text"] {
        border: none; /* Remove default borders */
        padding: 4px 8px;
        border-radius: 12px; /* Sadly outline does not round! therefore...*/
    }
    input[type="text"]:focus {
        outline: none; /* Remove default outline and use border or box-shadow */
        /* box-shadow: 0 0 0 2px orange; */
        /* Full freedom. (works also with border-radius) */
    }
`;
const SearchIcon = styled.img`
    width: 18px;
    display: block;
    object-fit: cover;
    margin-right: 0px;
`;
const SearchInput = styled.input`
    display: inline-block;
    width: calc(270px - 18px);
    height: 100%;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    border: none;
    color: #404040;

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #b6b6b6;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #b6b6b6;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #b6b6b6;
    }
`;
const FilterWrapper = styled.div`
    margin-left: 15px;
    position: relative;
`;
const FilterButton = styled.div`
    width: 95px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;

    /* main/white */

    background: #ffffff;
    /* background: ${(props) =>
        props.filterActivated ? "#8561c6" : "#ffffff"}; */
    /* main/secondary */

    border: 1px solid #8561c6;
    /* border: ${(props) =>
        props.filterActivated ? "1px solid #fff" : "1px solid #8561c6"}; */
    border-radius: 8px;
    font-weight: 500;
    font-size: 15px;
    line-height: 87%;
    /* or 14px */

    /* main/secondary */

    color: #8561c6;
    /* color: ${(props) => (props.filterActivated ? "#ffffff" : "#8561c6")}; */
`;
const FilterIcon = styled.img`
    width: 15px;
    margin-right: 10px;
`;
const Container = styled.div`
    // height: 130px;
    width: 280px;
    max-height: 360px;
    // padding: 30px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const Div = styled.div`
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    background-color: #f1f8fe;
    padding: 15px 15px;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #cce7ff;
`;
const ButtonsBody = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    /* max-height: 70vh; */
    // border-radius 0 0 5px 5px;
    overflow: scroll;
    border-bottom: ${(props) =>
        props.borderBottom ? props.borderBottom : null};
`;
const ResetButton = styled.div`
    width: 60%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #80669d; */
    background: #522e92;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
    &:hover {
        opacity: 0.9;
    }
`;
const CloseButton = styled.div`
    width: 38%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #80669d; */
    background: #ededed;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    color: #522e92;
    &:hover {
        /* opacity: 0.7; */
        background: #efefef;
    }
`;
const DetailedSection = styled.div`
    margin: 20px 0 0 0;
`;

// Additional styles for text within cells
const HeaderText = styled.span`
    display: inline-block;
    color: #9d9d9d;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
`;

const DataText = styled.span`
    display: inline-block;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
`;

const HeaderDataContainer = styled.div`
    display: flex;
    align-items: center;
`;
const BodyDataContainer = styled.div`
    display: flex;
    align-items: center;
`;

const CheckBoxIcon = styled.img`
    width: 17px;
    cursor: pointer;
    /* display: block; */
    margin-right: 8px;
    margin-top: 2px;
`;

const TableWrapper = styled.div`
    border: 1px solid #e7e7e750;
    border-right: none;
    border-bottom: none;
    border-radius: 12px;
    overflow: auto;
    max-height: calc(100vh - 300px);
    scrollbar-width: thin;
    /* Scrollbar styles for WebKit-based browsers (Chrome, Safari) */
    /* Scrollbar styles for WebKit-based browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
        display: block !important;
        border-radius: 12px;
        background: #fbfbfb;
        border: 1px solid #e7e7e750;
        /* overflow: hidden; */
    }

    &::-webkit-scrollbar:vertical {
        width: 10px;
        border-top-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
        border-bottom: none;
    }
    &::-webkit-scrollbar:horizontal {
        height: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top: none;
        border-right: none;
    }

    &::-webkit-scrollbar-corner {
        /* display: none; */
        background-color: #fbfbfb;
        border-bottom-right-radius: 16px;
        border: 1px solid #e7e7e750;
        border-top: none;
        border-left: none;
    }

    &::-webkit-scrollbar-track:horizontal {
        border: none;
        width: 100%;
    }
    &::-webkit-scrollbar-track:vertical {
        border: none;
        height: 100%;
    }

    &::-webkit-scrollbar-thumb {
        background: #e7e7e7;
        border-radius: 12px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    &::-webkit-scrollbar-button {
        display: none !important;
        background-color: #f00;
    }

    /* .container::-webkit-scrollbar-button:vertical:decrement {
        display: none;
    }

    .container::-webkit-scrollbar-button:vertical:increment {
        display: none;
    } */

    /* Define the button style when being hovered over */
    /* &::-webkit-scrollbar-button:hover {
    } */

    /* &::-webkit-scrollbar-track-piece:vertical:start {
    }

    &::-webkit-scrollbar-track-piece:vertical:end {
    } */
`;
const Table = styled.table`
    min-width: 100%;
    width: auto;
    background: #fff;
    /* table-layout: fixed; */
    padding: 0;
    margin: 0;
    border-spacing: 0;
    /* border-radius: 16px; */
    text-transform: capitalize;

    th {
        color: #9d9d9d;
        font-weight: 500;
        font-size: 15px;
        text-transform: capitalize;

        padding: 18px 16px;
        text-align: left;
        max-width: 300px !important;
        /* min-width: 250px !important; */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        background:rgb(237, 245, 254);;
    }
    td {
        /* font-size: 15px; */
        font-size: 14.5px;
        font-weight: 500;
        padding: 18px 16px;
        text-align: left;
        max-width: 300px !important;
        /* min-width: 250px !important; */

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    td,
    th {
        vertical-align: middle;
    }
    .no-data-cell {
        color: #404040;
        font-size: 22px;
        /* font-weight: 500; */
        text-align: center; /* Center-align text horizontally */
        vertical-align: middle; /* Center-align text vertically */
        height: calc(
            100vh - 405px
        ); /* Set a fixed height to vertically center the content if needed */
    }
    .pagination-cell {
        text-align: right;
    }
`;
const Thead = styled.thead`
    position: sticky;
    top: 0;
    z-index: 1;
    /* box-shadow: 0 0 15px rgb(0 0 0 / 7%);
    clip-path: inset(-15px 0px 0px 0px); */
    // /* background-color: #edf5fe; */
`;
const THR = styled.tr``;
const TH = styled.th`
    background-color: #fff;
    box-shadow: 0 0 15px rgb(0 0 0 / 3%);
    clip-path: inset(0px 0px -15px 0px);

    &:first-child {
        position: sticky;
        left: 0;
        z-index: 10;
        padding-right: 24px;
        box-shadow: 0 0 15px rgb(0 0 0 / 3%);
        clip-path: inset(0px -15px -15px 0px);
    }

    /* &:last-child {
        position: sticky;
        right: 0;
        z-index: 1;
    } */
`;
const Tbody = styled.tbody`
    background: #fbfbfb;
    position: sticky;
`;
const TDR = styled.tr`
    &:nth-child(odd) > td {
        /* background: #ffffff; */
    }
    &:nth-child(even) > td {
        background: #fff;
    }

    &:last-child > td {
        &:first-child {
        }
        &:last-child {
        }
    }
`;
const TD = styled.td`
    background: #fbfbfb;
    text-transform: ${(props) =>
        props.isEmailField ? "lowercase" : "capitalize"};

    font-family: "Inter", sans-serif;
    /* &:nth-child(even){
    background: #fbfbfb;

    } */

    &:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
        padding-right: 24px;
        box-shadow: 0 0 15px rgb(0 0 0 / 5%);
        clip-path: inset(0px -15px 0px 0px);
    }

    /* &:last-child {
        position: sticky;
        right: 0;
        z-index: 1;
    } */
    .link-td-text {
        color: #8561c6;
        cursor: pointer;
        &:hover {
            /* color: blue; */
            text-decoration: underline;
        }
    }
`;
const TDText = styled.span`
    font-family: "Inter", sans-serif;
    font-size: 15px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
const THText = styled.span`
    display: inline-block;
`;
const ProfilePic = styled.img`
    display: block;
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 6px;
`;
const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const AddEmiButton = styled.button`
    display: flex;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 4px;
    border: 1px solid #917afd;
    background: #fff;
    color: #917afd;
    font-size: 13px;
`;
const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: right;
`;
const ViewIcon = styled.img`
    width: 18px;
    display: block;
    object-fit: cover;
    cursor: pointer;
`;
const AssignButton = styled.button`
    background: #917afd;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    height: 42px;
    box-sizing: border-box;
    padding: 8px 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin-left: 15px;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    opacity: ${(props) => (props.disabled ? "0.7" : "1")};
`;
const PopoverSelectDiv = styled.div`
    height: 42px;
    box-sizing: border-box;
    padding: 4px;
    display: flex;
    align-items: center;
    width: 100%;
    background: #ffffff;

    border: 1px solid #d7d7d7;
    border-radius: 12px;
`;
const PopoverSelectItem = styled.div`
    width: 50%;
    padding: 8px 10px;
    background: ${(props) => (props.selected ? "#EAEFF5" : "#fff")};
    border-radius: 8px;
    font-size: 15px;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    opacity: ${(props) => (props.disabled ? "0.3" : "1")};
`;
const CancelPopoverButton = styled.button`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #010101;
    letter-spacing: 0.04em;
    font-size: 14px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid #917afd;
    border-radius: 8px;
    cursor: pointer;
`;
const ApplyPopoverButton = styled.button`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #010101;
    letter-spacing: 0.04em;
    font-size: 14px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid #917afd;
    border-radius: 8px;
    background: #917afd;
    color: #fff;
    cursor: pointer;
`;
const ClearAllFiltersButton = styled.div`
    height: 44px;
    background: #fff;
    padding: 6px;
    height: 44px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #d7d7d7;
    margin-left: 10px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
const MainBox = styled.div`
    width: 250px;
    padding: 10px;
    max-height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
`;
const DownArrow = styled.img`
    cursor: pointer;
    width: 13px;
    margin-left: 20px;
    /* margin-right: 15px; */
`;
const RadioButtonIcon = styled.img`
    width: 21px;
    cursor: pointer;
    display: block;
    margin-right: 8px;
`;
const ProgramCards = styled.div`
    margin-top: 8px;
`;
const ProgramCard = styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 6px;
    cursor: pointer;
    &:hover {
        background: #f5f5f5;
    }
`;
const ProgramName = styled.div`
    text-transform: capitalize;
    font-size: 14.6px;
    font-weight: 400;
    color: #010101;
    margin-top: 4px;
`;
const ButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    margin-top: 10px;
`;
const ExtraCourse = styled.span`
    display: inline-block;
    cursor: pointer;
    margin-left: 2px;
    color: #9c9c9c;
    font-size: 16px;
`;
const DetailLine = styled.div`
    padding: 6px 10px;
    min-width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0px;
    }
`;
const DetailText = styled.span`
    font-weight: 400;
    font-size: 14px;
    /* line-height: 17px; */
    text-align: right;

    color: #000000;
`;
const DetailedRemarkDiv = styled.div`
    max-width: 260px;
    padding: 6px;
`;
const DetailedRemarkText = styled.p`
    /* max-width: 240px; */
    padding: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    box-sizing: border-box;
    /* or 150% */

    /* dark/black */

    color: #000000;
    /* margin-top: 10px; */
`;
const ExecutiveModalContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 680px;
    max-height: 85vh;
    /* min-height: 40vh; */
    overflow-y: scroll;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 12px;
    box-shadow: 24px;
    padding: 26px;
`;
const FormInput = styled.div``;
const FormInputTitle = styled.div`
    font-size: 18px;
    margin-bottom: 12px;
`;
const ModalTitle = styled.h6`
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
`;
const Href = styled.div`
    width: 100%;
    height: 1px;
    margin: 20px 0;
    /* border-color: #e7e7e7 !important ; */
    background-color: #e7e7e7;
`;
const LightContainer = styled.div`
    border-radius: 8px;
    border: 1px solid #e7e7e750;
    background: #f8fafc;
    padding: 20px;
`;
const CancelButton = styled.button`
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f61f1f;
    letter-spacing: 0.1em;
    font-size: 16px;
    height: 40px;
    padding: 10px 16px;
    box-sizing: border-box;
    background: none;
    border: none;
    /* border: 1px solid #917afd; */
    border-radius: 8px;
    cursor: pointer;
`;
const ApplyButton = styled.button`
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #010101;
    letter-spacing: 0.08em;
    font-size: 16px;
    height: 44px;
    padding: 10px 20px;
    box-sizing: border-box;
    border: 1px solid #917afd;
    border-radius: 8px;
    background: #917afd;
    color: #fff;
    cursor: pointer;
`;
const ButtonsWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const SubTitle = styled.span`
    /* margin-bottom: 6px; */
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: #404040;
    text-transform: capitalize;
`;
