import React, { useEffect, useState, useContext, useRef } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import visible from "../../assets/images/visible.svg";
import { useHistory, useParams } from "react-router-dom";
import { AddLeadGeneratorService } from "../../service/leadGenerator";
import { validatePassword, capitalizeString } from "../../helpers/Functions";
import { baseConfig } from "../../utils/config";
import { Context } from "../../contexts/Store";
import ProfilePicture from "../../components/ProfilePicture";
import styled from "styled-components";
import deleteIcon from "../../assets/images/action-delete.svg";
import hideIcon from "../../assets/images/eye-close.svg";
import visibleIcon from "../../assets/images/eye-open.svg";

// material ui
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AlertBox from "../../components/includes/AlertBox";
import SweetAlert from "../../components/includes/SweetAlert";
// import AlertBox from "../../../includes/alert/AlertBox";
// import CountryCodeModal from "../../../includes/CountryCodeModal";

export default function AddFinanceManager() {
    const { state } = useContext(Context);
    const { editId } = useParams();

    //ref
    const usernameRef = useRef(null);
    const passwordRef = useRef(null);

    const history = useHistory();
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [fieldName, setFieldName] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [id, setId] = useState("");
    const [countries, setCountries] = useState([]);
    const [emailValidation, setEmailValidation] = useState(false);
    const [passwordValidate, setPasswordValidate] = useState({});

    const [errorField, setErrorField] = useState("");

    // Form fields
    const [profile, setProfile] = useState(null);
    const [fetchedImage, setFetchedImage] = useState(null);
    const [name, setName] = useState("");
    const [employeeId, setEmployeeId] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const [uploadedCV, setUploadedCV] = useState(null);
    const [salesManagers, setSalesManagers] = useState([]);
    const [salesManagerName, setSalesManagerName] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    // sweetalert states and functions
    const [isSweetAlert, setIsSweetAlert] = useState(false);
    const [sweetAlertContent, setSweetAlertContent] = useState({
        title: "",
        message: "",
        icon: "",
        confirmButtonText: "",
        isCancelButton: false,
    });
    const handleSweetAlertConfirm = () => {
        history.push("/superadmin/finance-manager/");
    };
    // end of sweetalert

    // alert mui message
    const [alertMessage, setAlertMessage] = useState(null);
    // Track the message state

    useEffect(() => {
        setPasswordValidate(validatePassword(password));
    }, [password]);

    const validate = (text) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(text) === false) {
            setEmailValidation(false);
            setEmail(text);
            return false;
        } else {
            setEmail(text);
            setEmailValidation(true);
        }
    };
    useEffect(() => {
        validate(email);
    }, [email]);

    const getFinanceAdmin = () => {
        setLoading(false);
        let accessToken = state.user_details.access_token;

        baseConfig
            .get(`/finance/finance-admin/${editId}/ `, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    // search: searchItem,
                },
            })
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode == 6000) {
                    updatePersonalDetails(data.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };

    const handleSubmit = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();

        formdata.append("image", profile);
        formdata.append("name", name);
        formdata.append("employee_id", employeeId);
        formdata.append("country", selectedCountry);
        formdata.append("phone", phone);
        formdata.append("email", email);
        formdata.append("username", userName);
        formdata.append("password", password);
        // formdata.append("email", employeeId);

        var method = editId ? baseConfig.put : baseConfig.post;
        var URL = editId
            ? `/finance/finance-manager/${editId}/`
            : `/finance/finance-managers/`;

        method(URL, formdata, {
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
                // "Content-Type": "application/json",
            },
        })
            .then((response) => {
                const { StatusCode, message, data } = response.data;
                if (StatusCode === 6000) {
                    // setDefaultValues();
                    // history.push("/superadmin/finance-admins/");
                    setSweetAlertContent({
                        title: "Success",
                        message: "Finance Manager added successfully",
                        icon: "success",
                        confirmButtonText: "Ok",
                        isCancelButton: false,
                    });
                    setIsSweetAlert(true);
                } else {
                    setAlertMessage("message");
                }
            })
            .catch((error, response) => {
                if (error.response.status === 400) {
                    setAlertMessage(error?.response?.data?.data?.message);
                }
            });
    };

    const getAdminId = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/leads/get-lead-generator-id/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setId(data.lead_generator_id);
                } else {
                }
            })
            .catch((error) => {});
    };

    //get country code api
    const getCountries = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/countries/list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    // setPrograms(data.data);
                    setCountries(data);
                } else {
                }
            })
            .catch((error) => {});
    };
    const setDefaultValues = () => {
        setProfile(null);
        setFetchedImage(null);
        setName("");
        setEmployeeId("");
        setSelectedCountry("");
        setPhone("");
        setEmail("");
        setUserName("");
        setPassword("");
    };
    const updatePersonalDetails = async (data) => {
        setProfile(data.image);
        setFetchedImage(data.image);
        setName(data.name);
        setEmployeeId(data.employee_id);
        setSelectedCountry(data.country);
        setPhone(data.phone);
        setEmail(data.email);
        setUserName(data.user_data.username);
        setPassword(data.password);
    };

    useEffect(() => {
        getAdminId();
        getCountries();
    }, []);

    useEffect(() => {
        if (editId) {
            getFinanceAdmin();
        }
        if (!editId) {
            setDefaultValues();
        }
    }, [editId]);

    useEffect(() => {
        // if (!editId) {
        if (name.length === 0) {
            setTimeout(() => {
                setUserName("");
                setPassword("");
            }, 200);
        } else {
            setTimeout(() => {
                setUserName(`${name.replace(/\s+/g, "")}@LMS`);
                setPassword(`${name.replace(/\s+/g, "")}${id}@LMS`);
            }, 200);
        }
        // } else {
        //     if (!isLoading) {
        //         setUserName(`${name.replace(/\s+/g, "")}@LMS`);
        //         setPassword(`${name.replace(/\s+/g, "")}${id}@LMS`);
        //     }
        // }
    }, [name]);

    useEffect(() => {
        if (errorField !== "") {
            setTimeout(() => {
                setErrorField("");
            }, 6000);
        }
    }, [errorField]);

    return (
        <Wrapper>
            <AlertBox
                message={alertMessage}
                setMessage={setAlertMessage}
                severity={
                    alertMessage && alertMessage.includes("successful")
                        ? "success"
                        : "error"
                }
                autoClose={true}
                onClose={() => setAlertMessage(null)}
            />
            <SweetAlert
                sweetAlertContent={sweetAlertContent}
                setSweetAlertContent={setSweetAlertContent}
                isSweetAlert={isSweetAlert}
                setIsSweetAlert={setIsSweetAlert}
                onConfirm={handleSweetAlertConfirm}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "0 12px",
                    }}
                >
                    <Title fontSize="28px">Add Finance Manager</Title>
                    {/* <Text
						style={{
							marginTop: "5px",
							fontFamily: "Inter",
							fontWeight: "500",
						}}
						color="grey"
						fontSize="16px"
					>
						Admins / Add Finance Admin
					</Text> */}
                </div>

                <div>
                    <Button
                        href="#"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "22px",
                        }}
                        backgroundColor="white"
                        fontSize="15px"
                        padding="18px 20px"
                        onClick={(e) => {
                            e.preventDefault();
                            setDefaultValues();
                        }}
                    >
                        <Text color="#EE1451" fontSize="15px">
                            Clear Form
                        </Text>
                    </Button>
                </div>
            </div>
            <div
                style={{
                    marginBottom: "20px",
                }}
            >
                <ProfilePicture
                    setFile={setProfile}
                    file={profile}
                    fetchedImage={fetchedImage}
                    setFetchedImage={setFetchedImage}
                />
                {errorField == "profile" && fetchedImage == null && (
                    <ErrorMessage>Add profile picture</ErrorMessage>
                )}
            </div>
            {!isLoading ? (
                <>
                    <FormDiv>
                        {/* <Detail>
                    <TitleText>Admin ID (auto generated)</TitleText>
                    <InputField disabled value={id} />
                </Detail> */}

                        {/* <div></div>
                <div></div> */}
                        <Detail>
                            <TitleText>Name</TitleText>
                            <InputField
                                onChange={(value) => {
                                    setName(
                                        capitalizeString(value.target.value)
                                    );
                                }}
                                value={name}
                            />
                            {errorField == "name" && name == "" && (
                                <ErrorMessage>Enter name</ErrorMessage>
                            )}
                        </Detail>
                        <Detail>
                            <TitleText>Employee Id</TitleText>
                            <InputField
                                onChange={(value) => {
                                    setEmployeeId(
                                        capitalizeString(value.target.value)
                                    );
                                }}
                                value={employeeId}
                            />
                            {errorField == "eid" && employeeId == "" && (
                                <ErrorMessage>Enter employee ID</ErrorMessage>
                            )}
                        </Detail>
                        <Detail>
                            <TitleText>Country</TitleText>
                            <div style={{ height: "50px" }}>
                                <Box>
                                    <FormControl fullWidth>
                                        {/* <InputLabel id="demo-simple-select-label">
                                            Batch
                                        </InputLabel> */}
                                        <Select
                                            defaultValue=""
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            sx={{
                                                height: 50,
                                            }}
                                            style={{
                                                background: "#fff",
                                                borderRadius: "12px",
                                            }}
                                            value={selectedCountry}
                                            // label="Batch"
                                            onChange={(e) => {
                                                setSelectedCountry(
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            {/* {programs.map((data) => ( */}
                                            {countries?.map((data) => (
                                                <MenuItem
                                                    key={data.id}
                                                    value={data.id}
                                                >
                                                    {data.name}
                                                </MenuItem>
                                            ))}
                                            {/* ))} */}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>
                            {errorField == "country" &&
                                selectedCountry == "" && (
                                    <ErrorMessage>Select Country</ErrorMessage>
                                )}
                        </Detail>
                        <Detail>
                            <TitleText>Phone Number</TitleText>
                            <InputField
                                type="number"
                                onChange={(value) => {
                                    setPhone(value.target.value);
                                }}
                                value={phone}
                            />
                            {errorField == "phone" && phone == "" && (
                                <ErrorMessage>Enter phone number</ErrorMessage>
                            )}
                        </Detail>
                        <Detail>
                            <TitleText>Mail ID</TitleText>
                            <InputField
                                type="email"
                                onChange={(value) => {
                                    // validate(value.target.value);
                                    setEmail(value.target.value);
                                }}
                                value={email}
                            />
                            {errorField == "email" &&
                                (email == "" ? (
                                    <ErrorMessage>Enter email id</ErrorMessage>
                                ) : (
                                    emailValidation === false && (
                                        <ErrorMessage>
                                            Please enter a valid email
                                        </ErrorMessage>
                                    )
                                ))}
                        </Detail>
                        {/* <Detail>
                    <TitleText>CV</TitleText>
                    {uploadedCV !== null ? (
                        <UploadedContainer>
                            <UploadedBox>Uploaded</UploadedBox>
                            <DeleteIconBox
                                onClick={() => {
                                    setUploadedCV(null);
                                }}
                            >
                                <DeleteImg src={deleteIcon} alt="icon" />
                            </DeleteIconBox>
                        </UploadedContainer>
                    ) : (
                        <UploadFileButton htmlFor="IdFile">
                            <UploadIcon src={addProgramIcon} alt="icon" />
                            Upload File
                            <input
                                name="myIdDoc"
                                accept="application/pdf,image/jpeg,image/jpg,image/png,.doc,.docx"
                                type="file"
                                id="IdFile"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                    setUploadedCV(e.target.files[0]);
                                }}
                            />
                        </UploadFileButton>
                    )}
                </Detail> */}
                        <Detail>
                            <TitleText>Username</TitleText>
                            <InputField
                                ref={usernameRef}
                                type="email"
                                onChange={(value) => {
                                    setUserName(value.target.value);
                                }}
                                value={userName}
                            />
                            {errorField == "username" && userName == "" && (
                                <ErrorMessage>Enter username</ErrorMessage>
                            )}
                        </Detail>
                        <Detail>
                            <TitleText>Password</TitleText>
                            <InputField
                                ref={passwordRef}
                                type="text"
                                onChange={(value) => {
                                    setPassword(value.target.value);
                                }}
                                value={password}
                            />
                            {errorField == "password" &&
                                (password == "" ? (
                                    <ErrorMessage>Enter password</ErrorMessage>
                                ) : (
                                    passwordValidate.is_strong_password ===
                                        false && (
                                        <ErrorMessage>
                                            Strong passwords consist of a
                                            combination of uppercase and
                                            lowercase letters, numbers and
                                            special symbols
                                        </ErrorMessage>
                                    )
                                ))}
                        </Detail>
                    </FormDiv>
                    <ButtonsWrapper>
                        <CancelButton
                            onClick={(e) => {
                                e.preventDefault();
                                history.push("/superadmin/finance-manager/");
                            }}
                        >
                            Cancel
                        </CancelButton>
                        <SubmitButton
                            onClick={(e) => {
                                e.preventDefault();
                                if (fetchedImage === null) {
                                    setErrorField("profile");
                                    // alert("Select profile picture");
                                } else if (name === "") {
                                    setErrorField("name");
                                } else if (employeeId === "") {
                                    setErrorField("eid");
                                } else if (selectedCountry === "") {
                                    setErrorField("country");
                                } else if (phone === "") {
                                    setErrorField("phone");
                                } else if (email === "") {
                                    setErrorField("email");
                                } else if (emailValidation === false) {
                                    setErrorField("email");
                                } else if (userName === "") {
                                    setErrorField("username");
                                } else if (password === "") {
                                    setErrorField("password");
                                } else if (
                                    passwordValidate.is_strong_password ===
                                    false
                                ) {
                                    setErrorField("password");
                                    // alert(
                                    //     "Strong passwords consist of a combination of uppercase and lowercase letters, numbers and special symbols"
                                    // );
                                } else {
                                    handleSubmit();
                                }
                            }}
                        >
                            Submit
                        </SubmitButton>
                    </ButtonsWrapper>
                </>
            ) : (
                <h1>Loading...</h1>
            )}
        </Wrapper>
    );
}

const FormDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
`;
const Detail = styled.div`
    min-height: 0px;
`;
const TitleText = styled.h5`
    font-size: 15px;
    color: #8561c6;
    margin-bottom: 10px;
    text-transform: capitalize;
`;

const InputField = styled.input`
    background: #fff;
    border-radius: 12px;
    padding: 12px;
    width: 100%;
    border: 1px solid #0000003b;
    height: 50px;
    box-sizing: border-box;
    /* margin-bottom: -5px; */
    font-size: 14px;
    opacity: ${(props) => (props.disabled ? `0.6` : `1.0`)};
    :hover {
        border-color: ${(props) => (props.disabled ? `#0000003b` : `#000`)};
    }
    :focus {
        background: #fff;
        border: 1px solid #0048d9;
    }
`;
const PasswordInputField = styled.input`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const UploadFileButton = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #79a358;
    border-radius: 8px;
    width: 130px;
    height: 50px;
    font-size: 00.87rem;
    color: #ffffff;
    /* font-weight: 500; */
    cursor: pointer;
    &:hover {
        opacity: 0.95;
    }
`;
const UploadIcon = styled.img`
    width: 20px;
    display: block;
    object-fit: cover;
    margin-right: 6px;
`;
const UploadedContainer = styled.div`
    width: 130px;
    height: 43px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const UploadedBox = styled.div`
    background: rgba(121, 163, 88, 0.1);
    border-radius: 8px;
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    color: #79a358;
`;
const DeleteIconBox = styled.div`
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
const DeleteImg = styled.img`
    width: 22px;
    height: 22px;
    display: block;
    object-fit: cover;
`;
const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: end;
`;
const CancelButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 15px;
    padding: 8px;
    background: #f4f4f4;
    width: 110px;
    height: 40px;
    margin-left: 8px;
    box-sizing: border-box;
    color: #522e92;
    border-radius: 8px;
    border: none !important;
`;
const SubmitButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 15px;
    padding: 8px;
    background: #522e92;
    width: 110px;
    height: 40px;
    margin-left: 8px;
    box-sizing: border-box;
    color: #fff;
    border-radius: 8px;
    border: none !important;
`;
const EyeIcon = styled.img``;
const ErrorMessage = styled.p`
    margin-top: 8px;
    color: red;
    font-size: 14px;
`;
