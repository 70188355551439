import React, { useContext } from "react";
import LoginPage from "../../pages/login";
import { Route, Switch, Redirect } from "react-router-dom";
import SuperAdmin from "../../pages/superAdmin/index";
import LeadGeneratorAdmin from "../../pages/leadGeneratorAdmin/index";
import LeadManagerAdmin from "../../pages/leadManagerAdmin/index";
import SalesPerson from "../../pages/salesPerson/index";
import CampusAdmin from "../../pages/campus/index";
import TeamLeader from "../../pages/teamLeader/index";
import FinanceExecutiveIndex from "../../pages/financeExecutive/index";
import FinanceManagerIndex from "../../pages/financeManager/index";

import { TeamLeaderRoute } from "./../routes/TeamLeaderRoute";
import { LeadManagerRoute } from "../routes/LeadManagerRoute";
import { LeadGeneratorRoute } from "../routes/LeadGeneratorRoute";
import { SuperAdminRoute } from "../routes/SuperAdminRoute";
import { SalesPersonRoute } from "../routes/SalesPersonRoute";
import { Context } from "../../contexts/Store";
import { CampusAdminRoute } from "../routes/CampusAdminRoute";
import { FinanceExecutiveRoute } from "../routes/FinanceExecutiveRoute";
import { FinanceManagerRoute } from "../routes/FinanceManagerRoute";

function AppRouter() {
  const { state } = useContext(Context);
  const handleRedirect = () => {
    if (state.user_details.role === "superuser") {
      return (
        <Redirect
          to={{
            pathname: "/superadmin/",
          }}
        />
      );
    } else if (state.user_details.role === "lead_manager") {
      return (
        <Redirect
          to={{
            pathname: "/salesmanager/",
          }}
        />
      );
    } else if (state.user_details.role === "lead_generator") {
      return (
        <Redirect
          to={{
            pathname: "/leadgenerator/",
          }}
        />
      );
    } else if (state.user_details.role === "sales_person") {
      return (
        <Redirect
          to={{
            pathname: "/admission-consultant/",
          }}
        />
      );
    } else if (state.user_details.role === "campus_admin") {
      return (
        <Redirect
          to={{
            pathname: "/campus-admin/",
          }}
        />
      );
    } else if (state.user_details.role === "sales_team_leader") {
      return (
        <Redirect
          to={{
            pathname: "/team-leader/",
          }}
        />
      );
    } else if (state.user_details.role === "finance_executive") {
      return (
        <Redirect
          to={{
            pathname: "/finance-executive/",
          }}
        />
      );
    } else if (state.user_details.role === "finance_manager") {
      return (
        <Redirect
          to={{
            pathname: "/finance-manager/",
          }}
        />
      );
    }
  };

  return (
    <Switch>
      <Route exact path="/">
        {handleRedirect()}
      </Route>

      <SuperAdminRoute path="/superadmin/" component={SuperAdmin} />
      <LeadGeneratorRoute
        path="/leadgenerator/"
        component={LeadGeneratorAdmin}
      />
      <LeadManagerRoute path="/salesmanager/" component={LeadManagerAdmin} />
      <SalesPersonRoute path="/admission-consultant/" component={SalesPerson} />
      <CampusAdminRoute path="/campus-admin/" component={CampusAdmin} />

      <TeamLeaderRoute path="/team-leader/" component={TeamLeader} />
      <FinanceExecutiveRoute
        path="/finance-executive/"
        component={FinanceExecutiveIndex}
      />
      <FinanceManagerRoute
        path="/finance-manager/"
        component={FinanceManagerIndex}
      />
    </Switch>
  );
}

export default AppRouter;
