import React from "react";
import styled from "styled-components";
import checked from "../../../assets/images/checked-modal.svg"
import failed from "../../../assets/images/checked-modal.svg"

export default function SucessFullModal({
    handleResponseModal,
    result,
    student,
    isDeleted,
    modalContent,
}) {
    return(
        <MainContainer>
            <CloseContainer>
                <CloseImage 
                    onClick={()=>handleResponseModal()}
                    src={require("../../../assets/images/close.svg").default}
                />
            </CloseContainer>
            <MessageContainer>
                <LeftContainer>
                    <ResponseImage src={checked}/>
                </LeftContainer>
                <RightContainer>
                    <Message>{isDeleted ? "Successfully Deleted" : "Successfully Edited"}</Message>
                    <Description>{isDeleted  ? "Installment is Successfully Deleted" : "Installment is Successfully Edited"}</Description>
                </RightContainer>
            </MessageContainer>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    position: absolute;
    top: 11%;
    right: 5%;
    width: 470px;
    max-height: 150px;
    background: #fff;
    border-left: 8px solid #522E92;
    border-radius: 10px;
    padding: 8px 8px 28px 15px;
    box-shadow: 4px 4px 40px 0px #00000026;
    z-index: 1000;
    &.failed{
        border-left: 8px solid #FD7A7A;
    }
`;
const CloseContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
    margin-right: 5px;
`;
const CloseImage = styled.img`
    width: 15px;
    display: block;
    cursor: pointer;
`;
const MessageContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const LeftContainer = styled.div`
    width: 55px;
    height: 45px;
    margin-right: 15px;
`;
const ResponseImage = styled.img`
    width: 100%;
    display: block;
`;
const RightContainer = styled.div`
    width: 83%;
`;
const Message = styled.span`
    color: #010101;
    font-size: 18px;
    font-family: 'poppinsregular';
    display: block;
`;
const Description = styled.span`
    font-size: 14px;
    color: #9D9D9D;
`;