import React, { PureComponent } from "react";
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList,
} from "recharts";

const customData = [
    {
        name: "Page A",
        uv: 1000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: "Page B",
        uv: 1000,
        pv: 1398,
        amt: 810,
    },
    {
        name: "Page C",
        uv: 1000,
        pv: 9800,
        amt: 1290,
    },
    // {
    //     name: "Page D",
    //     uv: 2780,
    //     pv: 3908,
    //     amt: 2000,
    // },
    // {
    //     name: "Page E",
    //     uv: 1890,
    //     pv: 4800,
    //     amt: 2181,
    // },
    // {
    //     name: "Page F",
    //     uv: 2390,
    //     pv: 3800,
    //     amt: 2500,
    // },
    // {
    //     name: "Page G",
    //     uv: 3490,
    //     pv: 4300,
    //     amt: 2100,
    // },
];

const BarChartComponent = ({ data, height, colors }) => {
    // barSize is for thickness of each bar
    // height is for Responsive Container height
    // data is BarChartData
    // barDataKey is the dataKey for each bar

    return (
        <>
            {/* {data && ( */}
            <ResponsiveContainer width="100%" height={height ? height : 100}>
                <BarChart width={30} height={40} data={data}>
                    {/* <Bar
                        barSize={30}
                        dataKey="amt"
                        // fill="#8884d8"
                        fill={COLORS[index % COLORS.length]}
                    /> */}

                    <Bar dataKey="students" barSize={30} radius={[5, 5, 5, 5]}>
                        {/* <LabelList dataKey="name" position="top" /> */}
                        {data?.map((entry, index) => (
                            // <Cell
                            //     key={`cell-${index}`}
                            //     fill={COLORS[index % COLORS.length]}
                            // />
                            <Cell
                                key={index}
                                // dataKey="amt"
                                // fill="#8884d8"
                                fill={colors[index % 20]}
                                stroke={colors[index]}
                                // strokeWidth={index === 2 ? 4 : 1}
                            />
                        ))}
                    </Bar>
                    {/* <Legend
                        layout="vertical"
                        verticalAlign="middle"
                        align="right"
                        iconType="circle"
                    /> */}
                </BarChart>
            </ResponsiveContainer>
            {/* )} */}
        </>
    );
};

export default BarChartComponent;
