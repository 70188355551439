import React from "react";
import styled from "styled-components";
import Button from "../../../components/Button";
import Text from "../../../components/Text";

export default function WarningModal({
    isModal,
    setModal,
    deleteId,
    handleFunction,
    archive,
}) {
    return (
        <BackContainer>
            <Overlay
                onClick={() => {
                    setModal(false);
                }}
            ></Overlay>
            <Modal>
                <Container>
                    <Title>Are you sure to change status</Title>
                    <Div>
                        <Button
                            onClick={() => {
                                setModal(false);
                            }}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                width: "45%",
                                height: "50px",
                                padding: 0,
                            }}
                            backgroundColor="#F4F4F4"
                            fontSize="15px"
                            className="delete-modal-btn"
                        >
                            <Text color=" #522e92" fontSize="15px">
                                Cancel
                            </Text>
                        </Button>

                        <Button
                            onClick={() => {
                                handleFunction();
                            }}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                width: "45%",
                                height: "50px",
                                padding: 0,
                            }}
                            backgroundColor="#5A2D8F"
                            fontSize="15px"
                            className="delete-modal-btn"
                        >
                            <Text color=" white" fontSize="15px">
                                Yes
                            </Text>
                        </Button>
                    </Div>
                </Container>
            </Modal>
        </BackContainer>
    );
}

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
`;
const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    cursor: pointer;
    height: 100vh;
`;
const Modal = styled.div`
    margin: 0 auto;
    background: #fff;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 23px;
    transition: 0.5s;
    z-index: 101;
    @media all and (max-width: 1440px) {
        width: 550px;
    }
    @media all and (max-width: 1280px) {
        width: 500px;
    }
    @media all and (max-width: 980px) {
        width: 500px;
    }
    @media all and (max-width: 768px) {
        width: 450px;
    }
    @media all and (max-width: 640px) {
        width: 350px;
    }
    @media all and (max-width: 480px) {
        width: 300px;
    }
    @media all and (max-width: 360px) {
        width: 250px;
    }
`;
const Container = styled.div`
    height: 130px;
    /* width: 500px; */
    width: 90%;
    margin: 0 auto;
    padding: 30px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: 1280px) {
        width: 90%;
        margin: 0 auto;
    }
`;
const Title = styled.h4`
    text-align: center;
    font-size: 18px;
`;
const Div = styled.div`
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
`;
