import Header from "../../components/header/header";
import NavBar from "../../components/navBar/navBar";
// import { navBarData } from "./navBarData";

import { Route, Switch } from "react-router-dom";
import "./style.css";
import { useState, useContext, useEffect } from "react";
import MainDashboard from "./mainDashboard";
import Students from "./Students";
import StudentSinglePage from "./StudentSinglePage";
import { Context } from "../../contexts/Store";
import { baseConfig } from "../../utils/config";
import reportIcon from "../../assets/images/report-nav-icon.svg";

//icon
import dashboard from "../../assets/images/dashboard-menu.svg";
import studentsIcon from "../../assets/images/students-icon.svg";
import ReportPage from "../financeManager/ReportPage";

function LeadManagerAdmin() {
    const [openNav, setOpenNav] = useState(false);

    const { state } = useContext(Context);

    const [universities, setUniversities] = useState([]);
    const [innerData, setInnerData] = useState([]);

    console.log(innerData,"innerdata");
    const getUniversities = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/university/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setUniversities(data.data);
                } else {
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        getUniversities();
    }, []);

    useEffect(() => {
        let data = universities.map((data, i) => {
            let value = {
                id: data.id,
                text: data.name,
                link: `/finance-executive/students/${data.name}/${data.id}`,
            };
            return value;
        });
        setInnerData([{
            id: 1,
            text: "All Students",
            link: `/finance-executive/students/all/1/`,
        }, ...data]);
    }, [universities]);

    const navBarData = [
        {
            img: dashboard,
            text: "Dashboard",
            link: "/finance-executive/",
        },
        {
            img: studentsIcon,
            text: "Students",
            inner: innerData,
        },
        // {
        //     img: studentsIcon,
        //     text: "All Students",
        //     // inner: innerData,
        // },
        {
            img: reportIcon,
            text: "Reports",
            link: "/finance-executive/reports",
        },
    ];

    return (
        <div className="body">
            <NavBar
                openNav={openNav}
                data={navBarData}
                setOpenNav={setOpenNav}
            />
            <div className="body-container">
                <Header setOpenNav={setOpenNav} />
                <Switch>
                    <Route
                        path="/finance-executive/"
                        exact
                        component={MainDashboard}
                    />
                    {/* <Route
                        path="/students/:university/"
                        exact
                        component={Students}
                    /> */}
                    <Route
                        path="/finance-executive/students/:university_name/:university"
                        // exact
                        component={Students}
                    />
                    <Route
                        path="/finance-executive/student/:id"
                        // exact
                        component={StudentSinglePage}
                    />
                    <Route
                        path="/finance-executive/reports/"
                        component={ReportPage}
                    />

                    {/* <Route
						path="/team-leader/new-leads/"
						component={NewLeads}
					/>
					
					<Route
						path="/team-leader/:tabStatus/"
						component={FollowUp}
					/> */}
                </Switch>
            </div>
        </div>
    );
}

export default LeadManagerAdmin;
