import React, { useEffect, useState } from "react";
import Container from "../Container";
import uploadImage from "../../assets/images/upload-image.svg";
import Image from "../Image";
import Text from "../Text";
import styled from "styled-components";

function UploadInput({
    file,
    setFile,
    isError,
    fieldName,
    name,
    errorMessage,
    setError,
    isEdit,
    imageUrl,
}) {
    useEffect(() => {
        const dropContainer = document.getElementById("upload");
        const fileInput = document.getElementById("file1");
        dropContainer.ondragover = dropContainer.ondragenter = function (evt) {
            evt.preventDefault();
        };
        dropContainer.ondrop = function (evt) {
            // pretty simple -- but not for IE :(
            fileInput.files = evt.dataTransfer.files;

            setFile(fileInput.files);

            evt.preventDefault();
        };
    }, []);

    return (
        <div id="upload" style={{ width: "49%" }}>
            <Text color="#7B6294" fontSize="15px">
                {name}
            </Text>
            <Container
                style={{
                    border:
                        isError === true && fieldName === name
                            ? "1px solid red"
                            : "1px dashed #EAEAEA",
                    textAlign: "center",
                }}
                width="auto"
                margin="12px 0 0"
                background="#FAFAFA"
            >
                {isEdit ? (
                    <Image
                        width="80px"
                        height="80px"
                        src={imageUrl}
                        alt="upload"
                    />
                ) : (
                    <Image
                        width="80px"
                        height="80px"
                        src={uploadImage}
                        alt="upload"
                    />
                )}
                <input
                    type="file"
                    id="file1"
                    style={{ display: "none" }}
                    onChange={(e) => {
                        // setFile(e.target.files);
                        const selectedFile = e.target.files[0];
                        if (selectedFile) {
                            setFile(e.target.files);
                        }
                    }}
                />
                {file ? (
                    <Text
                        style={{ marginTop: "20px" }}
                        textAlign="center"
                        fontSize="16px"
                        fontWeight="400"
                    >
                        {file[0].name}
                    </Text>
                ) : null}
                <Text
                    style={{ marginTop: "20px" }}
                    textAlign="center"
                    fontSize="16px"
                    fontWeight="400"
                >
                    Drop your image here,{" "}
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            const input = document.getElementById("file1");
                            input.click();
                            // isError(false);
                        }}
                    >
                        or browse
                    </a>
                </Text>
            </Container>
            {isError === true && fieldName === name && (
                <ErrorText>{errorMessage}</ErrorText>
            )}
        </div>
    );
}

export default UploadInput;

const ErrorText = styled.p`
    color: red;
`;
