const LeadListData = [
  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",
    university: "Sngnya",


  },

  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },
  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },
  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },
  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },
  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },
  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },
  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },

  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },
  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },
  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },
  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },
  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },
  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },
  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },
  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },

  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },
  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },
  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },
  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },
  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },
  {
    studentId: "001",
    name: "Jomana Ah",
    email: "jomanaaahma@gmail.com",
    phone: "+96 979797 9797",
    assigned: "232",
    converted: "123",
    sucsess: "67%",


  },






];
export default LeadListData;
