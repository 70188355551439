import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import React, { useEffect, useState, useContext } from "react";
import { Context } from "./../../contexts/Store";
import Text from "../../components/Text";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { baseConfig } from "./../../utils/config";

//svg
import Add from "./../../assets/images/addbutton.svg";
import Profiles from "./../../assets/images/profiles.svg";

function AllDepartment() {
	const history = useHistory();
	const { state } = useContext(Context);
	const [isLoading, setLoading] = useState(true);
	const [departmentList, setDepartmentList] = useState([]);

	const getDepartment = () => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/campus/departments/", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setDepartmentList(data.data);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		getDepartment();
	}, []);

	return (
		<Wrapper>
			<Top>
				<TopLeft>
					<Title fontSize="35px">Departments</Title>
					<Text
						fontSize="20px"
						fontWeight="400"
						color="#000"
						style={{ marginTop: "10px" }}
					>
						Departments / All Departments
					</Text>
				</TopLeft>
				<TopRight
					onClick={() => {
						history.push("/campus-admin/add-department/");
					}}
				>
					<img src={Add} alt="icon" />
					<Text
						fontSize="16px"
						fontWeight="400"
						color="#fff"
						style={{ marginLeft: "5px" }}
					>
						Add Department
					</Text>
				</TopRight>
			</Top>
			{isLoading == false ? (
				<CardContainer>
					{departmentList.map((item) => (
						<Card>
							<CardTop>
								<CardLeft>
									<img
										src={Profiles}
										alt="icon"
										style={{
											height: "100%",
											width: "100%",
										}}
									/>
								</CardLeft>
								<CardRight>
									<Option>
										<img
											src={
												require("./../../assets/images/action-delete.svg")
													.default
											}
											alt="icon"
										/>
									</Option>
									{/* <TopRight
									onClick={() => {
										history.push(
											"/campus-admin/add-university/"
										);
									}}
								>
									<Text
										fontSize="16px"
										fontWeight="400"
										color="#fff"
										style={{ marginLeft: "5px" }}
									>
										Update
									</Text>
								</TopRight> */}
								</CardRight>
							</CardTop>
							<CardBottom>
								<SubDiv>
									<SubText>#{item.department_id}</SubText>
								</SubDiv>
								<SubDiv>
									<MainText>{item.name}</MainText>
								</SubDiv>
								{/* <SubDiv>
									<SubHeading>Sub-departments</SubHeading>
								</SubDiv>
								{item.course_data.map((dept) => (
									<SubDiv>
										<IDText>{dept.course_id}</IDText>
										<SubText style={{ marginLeft: "10px" }}>
											{dept.name}
										</SubText>
									</SubDiv>
								))} */}
							</CardBottom>
						</Card>
					))}
				</CardContainer>
			) : null}
		</Wrapper>
	);
}

export default AllDepartment;

const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const TopLeft = styled.div``;
const TopRight = styled.div`
	display: flex;
	align-items: center;
	background-color: #522e92;
	height: 50px;
	border-radius: 3px;
	padding: 0 20px;
	cursor: pointer;
`;
const CardContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 30px;
	margin-top: 20px;
`;
const Card = styled.div`
	background-color: #fff;
	padding: 30px;
	border-radius: 8px;
`;
const CardTop = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const CardLeft = styled.div`
	height: 100px;
	width: 100px;
`;
const CardRight = styled.div`
	display: flex;
	flex-direction: column;
	height: 150px;
	justify-content: space-between;
	align-items: end;
`;
const Option = styled.div``;
const CardBottom = styled.div``;
const SubDiv = styled.h5`
	margin-top: 5px;
	display: flex;
	align-items: center;
`;
const MainText = styled.h4`
	color: #7b6294;
	font-weight: 600;
	font-size: 18px;
`;
const SubHeading = styled.h5`
	color: #808080;
	font-size: 16px;
	font-weight: 500;
`;
const IDText = styled.h4`
	color: #7b6294;
	font-weight: 500;
	font-size: 16px;
`;
const SubText = styled.h5`
	color: #808080;
	font-size: 15px;
`;
