import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useParams, Link } from "react-router-dom";
import { Context } from "../../contexts/Store";
import { baseConfig } from "../../utils/config";
import defaultProfile from "../../assets/images/profile-icon.svg";
import Wrapper from "../../components/Wrapper";
import profileIcon from "../../assets/images/profile-icon.svg";
import editIcon from "../../assets/images/finance-edit-icon.svg";
import downArrow from "../../assets/images/down-arrow.svg";
import loadingIcon from "../../assets/lottie/loading.gif";
import StudentFeeTable from "./includes/StudentFeeTable";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Popover,
    Select,
    Modal,
} from "@mui/material";

import WarningModal from "./includes/WarningModal";
import { Box } from "@mui/system";
import AddEmiModal from "./includes/AddEmiModal";
import moment from "moment";
import MainPagination from "../../components/includes/MainPagination";
import AddRemarkModal from "../financeExecutive/includes/AddRemarkModal";
// import EmiPreviewModal from "./includes/EmiPreviewModal";
// import EmiEditModal from "./includes/EmiEditModal";
import DeleteWarning from "./includes/DeleteWarning";
import SucessFullModal from "./includes/SucessFullModal";
import WarningModalNew from "./includes/WarningModalNew";
// import { Link } from "@material-ui/core";

export default function AssignedSinglePage() {
    const [isHide, setIsHide] = useState(false);
    const { id } = useParams();
    const { state, dispatch } = useContext(Context);
    const [isLoading, setIsLoading] = useState();
    const [emiLoading, setEmiLoading] = useState(false);
    const [selectedEmiId, setSelectedEmiId] = useState("");
    const [openedEmiId, setOpenedEmiId] = useState("");
    const [feePaid, setFeePaid] = useState();
    const [studentData, setStudentData] = useState();
    const [programId, setProgramId] = useState("");
    const [emiData, setEmiData] = useState([]);
    const [emiModal, setEmiModal] = useState(false);
    const [editedRemark, setEditedRemark] = useState("");
    const [followupsData,setFollowUpsData] = useState([])
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [paginationData, setPaginationData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsCount, setItemsCount] = useState(4);
    const [pageCount, setPageCount] = useState("");
    const [dateAnchorEl, setDateAnchorEl] = React.useState(null);
    const openDate = Boolean(dateAnchorEl);
    const dateAnchorId = openDate ? "simple-popover" : undefined;
    const [remark,setRemark] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const RemarkOpenModal = () => setOpenModal(true);
    const RemarkCloseModal = () => setOpenModal(false);
    const [previewModal,setPreviewModal] = useState(false)
    const PreviewCloseModal = () => setPreviewModal(false)
    const [editModal,setEditModal] = useState(false)
    const EditCloseModal = () => setEditModal(false)
    const [deleteWarning, setDeleteWarning] = useState(false)
    const [deleteEmiId, setDeleteEmiId] = useState("")
    const [isDeleted, setDeleted] = useState(false)
    const initialDate = studentData?.student_data?.next_follow_up;
    const [fupDate, setFupDate] = useState(initialDate);


    // for main status update
    const mainStatuses = [
        {
            id: 1,
            name: "Dropout",
            value: "Dropout",
        },
        {
            id: 2,
            name: "Active",
            value: "Active",
        },
        {
            id: 3,
            name: "Completed",
            value: "Completed",
        },
        {
            id: 4,
            name: "On Hold",
            value: "On Hold",
        },
        {
            id: 5,
            name: "On Break",
            value: "On Break",
        },
    ];
    const [selectedMainStatus, setSelectedMainStatus] = useState("");

    // mui Modal style and states
    const [selectedAddEmiId, setSelectedAddEmiId] = useState("");
    const [vatPercentage, setVatPercentage] = useState();
    const [pendingInstalmentFee, setPendingInstalmentFee] = useState();
    const [open, setOpen] = useState(false);
    const [responseModal, setResponseModal] = useState(false);
    const handleOpenModal = () => setOpen(true);
    const handleCloseModal = () => {
        setOpen(false);
        setSelectedAddEmiId("");
    };
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        boxShadow: 24,
        background: "#fff",
        borderRadius: "24px",
        width: "1120px",
        p: 2,
    };

    // for emi status update
    const [selectedStatusEmiId, setSelectedStatusEmiId] = useState("");
    const [selectedEmiStatus, setSelectedEmiStatus] = useState("");
    const [isWarningModal, setWarningModal] = useState(false);

    // for delete emis of a program
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [studentFeeId, setStudentFeeId] = useState("")

    // due-date popover onClick
    const [dueDateanchorEl, setdueDateAnchorEl] = React.useState(null);
    const handleClickdueDate = (event) => {
        setdueDateAnchorEl(event.currentTarget);
    };
    const handleClosedueDate = () => {
        setdueDateAnchorEl(null);
    };
    const opendueDate = Boolean(dueDateanchorEl);
    const dueDateId = opendueDate ? "simple-popover" : undefined;

    // status popover onClick
    const [statusEditanchorEl, setStatusEditAnchorEl] = React.useState(null);
    const handleClickStatusEdit = (event) => {
        setStatusEditAnchorEl(event.currentTarget);
    };
    const handleCloseStatusEdit = () => {
        setStatusEditAnchorEl(null);
    };
    const openStatusEdit = Boolean(statusEditanchorEl);
    const statusEditId = openStatusEdit ? "simple-popover" : undefined;

    // remarks edit popover onClick states and functions
    const [remarkEditanchorEl, setRemarkEditAnchorEl] = React.useState(null);
    const handleClickRemarkEdit = (event) => {
        setRemarkEditAnchorEl(event.currentTarget);
    };
    const handleCloseRemarkEdit = () => {
        setRemarkEditAnchorEl(null);
    };
    const openRemarkEdit = Boolean(remarkEditanchorEl);
    const remarkEditId = openRemarkEdit ? "simple-popover" : undefined;
    // const getStudentData = () => {
    //     setIsLoading(true);
    //     let accessToken = state.user_details.access_token;
    //     baseConfig
    //         .get(`/finance/student-emi-data/${id}`, {
    //             headers: {
    //                 Authorization: "Bearer " + accessToken,
    //             },
    //             params: {
    //                 // emi_stage: selectedOption,
    //                 // university: university,
    //             },
    //         })
    //         .then((response) => {
    //             const { StatusCode, data } = response.data;
    //             if (StatusCode === 6000) {
    //                 setStudentData(data);
    //                 setProgramId(data.data.student);
    //                 setTimeout(() => {
    //                     setIsLoading(false);
    //                 }, 3000);
    //             } else {
    //                 setIsLoading(false);
    //                 alert("Some error occured.Please try again later");
    //             }
    //         })
    //         .catch((error) => {
    //             setIsLoading(false);
    //             alert(error);
    //         });
    // };
    const getEmiData = () => {
        setEmiLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/students/student-emis/${id}`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    // emi_stage: selectedOption,
                    // university: university,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setStudentData(data);
                    setEmiData(data.data);
                    setEditedRemark(data?.student_data.remark)
                    setEmiLoading(false);
                    // setTimeout(() => {
                    // }, 200);
                } else {
                    setEmiLoading(false);
                    alert("Some error occured.Please try again later");
                }
            })
            .catch((error) => {
                setEmiLoading(false);
                alert(error);
            });
    };

    //delete emi
    const deleteInstallment = (id) => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.delete(`/students/student-emi/${deleteEmiId}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode } = response.data;
				if (StatusCode === 6000) {
					setDeleteWarning(false);
                    handleResponseModal()
                    setDeleted(true)
					getEmiData();
				} else {
				}
			})
			.catch((error) => {});
	};

    // To update emi status
    const handleStatusUpdate = (type) => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        if (type === "main") {
            formdata.append("status", selectedMainStatus);
        } else {
            if (selectedStatusEmiId !== null && selectedStatusEmiId !== "") {
                formdata.append("emi", selectedStatusEmiId);
            }
            if (selectedEmiStatus !== "") {
                formdata.append("status", selectedEmiStatus);
            }
        }

        baseConfig
            .post(`/finance/emi-status-update/${id}/`, formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    // getStudentData();
                    getEmiData();
                    setWarningModal(false);
                    handleCloseStatusEdit();
                } else {
                    alert("Some error occured");
                }
            })
            .catch((error, response) => {});
    };

    // to handle remark and fupDate change
    const handleChange = (type) => {
        let accessToken = state.user_details.access_token;

        var formdata = new FormData();
        // formdata.append("remark", editedRemark);
        if (type === "fupDate") {
            formdata.append("next_follow_up", fupDate);
        } else if (type === "remark") {
            formdata.append("remark", editedRemark);
        }

        baseConfig
            .post(`/finance/student-finance-update/${id}/`, formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    // getStudentData();
                    getEmiData();
                    if (type === "remark") {
                        handleCloseRemarkEdit();
                        setEditedRemark("");
                    }
                    if (type === "fupDate") {
                        handleClosedueDate();
                    }
                } else {
                    alert("Some error occured");
                }
            })
            .catch((error, response) => {
                alert(error);
            });
    };

    useEffect(() => {
        if (openedEmiId === "") {
            if (emiData.length > 0) {
                setOpenedEmiId(emiData[0].id);
            }
        }
    }, [emiData]);
    const selectOptions = [
        {
            id: 1,
            name: "Payment Details",
            value: "PaymentDetails",
        },
        {
            id: 2,
            name: "Follow Up History",
            value: "Followup",
        },
    ];
    const [selectedOption, setSelectedOption] = useState(
        "PaymentDetails"
    );
    //for get student followups
    const getFollowups = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/finance/student-followups/${id}`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params:{
                    start_date: fromDate,
                    end_date: toDate,
                    items: itemsCount,
                    page: currentPage,
                }
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setFollowUpsData(data.data)
                    setPaginationData(data.paginator);
                } else {
                    alert("Some error occured.Please try again later");
                }
            })
            .catch((error) => {
                setEmiLoading(false);
                alert(error);
            });
    
    };
    // for delete emis of a program
    const deleteEmiAll =(id)=>{
        let accessToken = state.user_details.access_token;
		baseConfig
			.delete(`/students/delete-emis-by-student-fee/${id}`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				}
			})
			.then((response) => {
				const { StatusCode } = response.data;
				if (StatusCode === 6000) {
					setDeleteConfirm(false);
                    getEmiData();
				} else {
				}
			})
			.catch((error) => {});   
    }

    //handle date filter
     const handleClickDate = (event) => {
         setDateAnchorEl(event.currentTarget);
     };
     const handleCloseDate = () => {
         setDateAnchorEl(null);
     };
     const settingFromDate=(e)=>{
        setFromDate(e.target.value);
    }
    const settingEndDate = (e)=>{
        setToDate(e.target.value);
    }
    const handleResponseModal = () => {
        setResponseModal(!responseModal);
    }
    setTimeout(() => {
        setResponseModal(false)
    }, 4000);
    useEffect(() => {
        getEmiData();
    }, []);
    useEffect(() => {
        getFollowups()
    }, [selectedOption]);
    return (
        <Wrapper>
            {!emiLoading ? (
                <>
                    <BreadcrumbsLine>
                        <PathBack to="/finance-manager/assigned-students/">
                            Assigned students /{" "}
                        </PathBack>
                        <BlackText> {studentData?.student_data.name}</BlackText>
                    </BreadcrumbsLine>
                    <MainBox>
                        <MainTop>
                            <MainTopLeft>
                                <ProfilePicWrapper>
                                    <ProfilePicDiv>
                                        <ProfilePic
                                            src={
                                                studentData?.student_data.image
                                                    ? studentData.student_data
                                                          .image
                                                    : profileIcon
                                            }
                                            alt="icon"
                                        />
                                    </ProfilePicDiv>
                                </ProfilePicWrapper>
                                <StudentDetails>
                                    <NameStatusLine>
                                        <StudentName>
                                            {studentData?.student_data.name}
                                        </StudentName>
                                        <StatusWrapper>
                                            <StatusContainer>
                                                {
                                                    studentData?.student_data
                                                        .status
                                                }
                                            </StatusContainer>
                                            <EditIcon
                                                src={editIcon}
                                                alt="icon"
                                                onClick={(e) => {
                                                    handleClickStatusEdit(e);
                                                }}
                                                style={{ marginLeft: "8px" }}
                                            />
                                            <Popover
                                                id={statusEditId}
                                                open={openStatusEdit}
                                                anchorEl={statusEditanchorEl}
                                                onClose={handleCloseStatusEdit}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                }}
                                            >
                                                <DetailedStatusDiv>
                                                    <FormControl
                                                        sx={{
                                                            mb: 2,
                                                            width: "100%",
                                                        }}
                                                        size="small"
                                                    >
                                                        <InputLabel id="demo-select-small">
                                                            {studentData?.student_data.status}
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-select-small"
                                                            id="demo-select-small"
                                                            value={
                                                                selectedMainStatus
                                                            }
                                                            label="Status"
                                                            style={{
                                                                fontSize:
                                                                    "14px",
                                                                fontWeight:
                                                                    "500",
                                                                paddingLeft:
                                                                    "4px",
                                                            }}
                                                            // onChange={handleMopChange}
                                                            onChange={(e) => {
                                                                setSelectedMainStatus(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        >
                                                            {mainStatuses.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <MenuItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            item.value
                                                                        }
                                                                        style={{
                                                                            fontSize:
                                                                                "14px",
                                                                            fontWeight:
                                                                                "500",
                                                                        }}
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "flex-end",
                                                        }}
                                                    >
                                                        <SubmitButton
                                                            onClick={() => {
                                                                if (
                                                                    selectedMainStatus ===
                                                                    ""
                                                                ) {
                                                                    alert(
                                                                        "Select status"
                                                                    );
                                                                } else {
                                                                    handleStatusUpdate(
                                                                        "main"
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            Submit
                                                        </SubmitButton>
                                                    </div>
                                                </DetailedStatusDiv>
                                            </Popover>
                                        </StatusWrapper>
                                    </NameStatusLine>

                                    {/* <InfoLine>
                                        <GreyInfoLeftText>
                                            Batch ID:
                                        </GreyInfoLeftText>
                                        <BlackInfoText>
                                            2020-2023 Batch
                                        </BlackInfoText>
                                    </InfoLine> */}
                                    <InfoLine>
                                        <GreyInfoLeftText>
                                            Email ID:
                                        </GreyInfoLeftText>
                                        <BlackInfoText
                                            style={{
                                                textTransform: "none",
                                            }}
                                        >
                                            {studentData?.student_data.email}
                                        </BlackInfoText>
                                    </InfoLine>
                                    <InfoLine>
                                        <GreyInfoLeftText>
                                            Mobile Number:
                                        </GreyInfoLeftText>
                                        <BlackInfoText>
                                            {studentData?.student_data.mobile}
                                        </BlackInfoText>
                                    </InfoLine>
                                    <InfoLine>
                                        <GreyInfoLeftText>
                                            Admission No:
                                        </GreyInfoLeftText>
                                        <BlackInfoText
                                            style={{ textTransform: "none" }}
                                        >
                                            {/* {student?.date_of_birth} */}
                                            {
                                                studentData?.student_data
                                                    .admission_number
                                            }
                                        </BlackInfoText>
                                    </InfoLine>
                                    <InfoLine>
                                        <GreyInfoLeftText>
                                            Consultant:
                                        </GreyInfoLeftText>
                                        <BlackInfoText>
                                        {
                                            studentData?.student_data?.consultant
                                        }
                                        </BlackInfoText>
                                    </InfoLine>
                                </StudentDetails>
                            </MainTopLeft>
                            <MainTopRight>
                            <InfoLine>
                                <GreyInfoText>
                                    Registered month:
                                </GreyInfoText>
                                <BlackInfoText>
                                    {
                                    emiData[0]?.registered_month
                                    }
                                </BlackInfoText>
                            </InfoLine>
                                {/* <InfoLine>
                            <GreyInfoText>Contact :</GreyInfoText>
                            <BlackInfoText>{student?.phone}</BlackInfoText>
                        </InfoLine> */}
                                {/* <InfoLine>
                            <GreyInfoText>Email ID :</GreyInfoText>
                            <BlackInfoText
                                style={{ textTransform: "lowercase" }}
                            >
                                {student?.mail}
                            </BlackInfoText>
                        </InfoLine> */}
                                <InfoLine>
                                    <GreyInfoText>Date Of Join :</GreyInfoText>
                                    <BlackInfoText>
                                        {studentData?.student_data.joining_date
                                            ? moment(
                                                  studentData?.student_data
                                                      .joining_date
                                              ).format("DD-MMM-YYYY")
                                            : ""}
                                        {/* 26-08-1998 */}
                                    </BlackInfoText>
                                </InfoLine>
                                {/* <InfoLine>
                            <GreyInfoText>Next Due Date :</GreyInfoText>
                            <BlackInfoText>
                                27-07-2020
                            </BlackInfoText>
                        </InfoLine> */}
                                {/* <InfoLine>
                                    <GreyInfoText>Next Due Date :</GreyInfoText>
                                    <BlackInfoFlexContainer>
                                        <BlackInfoText>
                                            {moment(
                                                studentData?.student_data
                                                    .next_follow_up
                                            ).format("MM-DD-YYYY")}
                                        </BlackInfoText>

                                        
                                    </BlackInfoFlexContainer>
                                    
                                </InfoLine> */}
                                <InfoLine>
                                    <GreyInfoText>
                                        Next Followup Date :
                                    </GreyInfoText>
                                    <BlackInfoFlexContainer>
                                        <BlackInfoText>
                                            {studentData?.student_data
                                                .next_follow_up
                                                ? moment(
                                                      studentData?.student_data
                                                          .next_follow_up
                                                  ).format("DD-MMM-YYYY")
                                                : ""}
                                        </BlackInfoText>

                                        <EditIcon
                                            src={editIcon}
                                            alt="icon"
                                            onClick={handleClickdueDate}
                                        />
                                    </BlackInfoFlexContainer>
                                    <Popover
                                        id={dueDateId}
                                        open={opendueDate}
                                        anchorEl={dueDateanchorEl}
                                        onClose={handleClosedueDate}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                    >
                                        <DetailedDateInputDiv>
                                            <DateInput
                                                type="date"
                                                value={fupDate}
                                                onChange={(e) => {
                                                    setFupDate(e.target.value);
                                                }}
                                                defaultValue={studentData?.student_data
                                                    .next_follow_up}
                                            />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <SubmitButton
                                                    onClick={() => {
                                                        if (fupDate === "") {
                                                            alert(
                                                                "Select Date"
                                                            );
                                                        } else {
                                                            handleChange(
                                                                "fupDate"
                                                            );
                                                        }
                                                    }}
                                                >
                                                    Submit
                                                </SubmitButton>
                                            </div>
                                        </DetailedDateInputDiv>
                                    </Popover>
                                </InfoLine>
                                <InfoLine>
                                    <GreyInfoText>Remarks :</GreyInfoText>
                                    <BlackInfoFlexContainer>
                                        <BlackInfoText
                                            style={{ whiteSpace: "normal" }}
                                        >
                                            {studentData?.student_data.remark}
                                        </BlackInfoText>
                                        <EditIcon
                                            src={editIcon}
                                            aria-describedby={remarkEditId}
                                            variant="contained"
                                            // onClick={handleClickRemarkEdit}
                                            onClick={()=>{
                                                RemarkOpenModal()
                                            }}
                                        />
                                    </BlackInfoFlexContainer>
                                    <Popover
                                        id={remarkEditId}
                                        open={openRemarkEdit}
                                        anchorEl={remarkEditanchorEl}
                                        onClose={handleCloseRemarkEdit}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }}
                                    >
                                        <DetailedRemarkInputDiv>
                                            <TopLine>
                                                <RemarkTitle>
                                                    {" "}
                                                    Edit Remark
                                                </RemarkTitle>
                                                {/* <EditIcon
                                        src={editIcon}
                                        alt="icon"
                                        
                                    /> */}
                                            </TopLine>
                                            <label className="textarea-label">
                                                <TextArea
                                                    placeholder="Enter remark "
                                                    onChange={(e) => {
                                                        setEditedRemark(
                                                            e.target.value
                                                        );
                                                    }}
                                                    value={editedRemark}
                                                ></TextArea>
                                            </label>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    marginTop: "8px",
                                                }}
                                            >
                                                <SubmitButton
                                                    onClick={() => {
                                                        if (
                                                            editedRemark === ""
                                                        ) {
                                                            alert(
                                                                "Remark field cannot be empty"
                                                            );
                                                        } else {
                                                            handleChange(
                                                                "remark"
                                                            );
                                                        }
                                                    }}
                                                >
                                                    Submit
                                                </SubmitButton>
                                            </div>
                                        </DetailedRemarkInputDiv>
                                    </Popover>
                                </InfoLine>
                            </MainTopRight>
                        </MainTop>
                    </MainBox>
                    <SelectDiv>
                    {selectOptions.map((item, index) => (
                        <SelectItem
                            key={index}
                            selected={
                                selectedOption === item.value ? true : false
                            }
                            onClick={() => {
                                setSelectedOption(item.value);
                                setFromDate("")
                                setToDate("")
                            }}
                        >
                            {item.name}
                        </SelectItem>
                    ))}
                    </SelectDiv>
                {
                    selectedOption === "PaymentDetails" ?
                    <>
                    <DiscountSection>
                        <DiscountsTitle 
                        >Discounts</DiscountsTitle>
                        <NormalDiscountSection>
                            <NormalDiscountBox>
                                Normal Discount
                            </NormalDiscountBox>
                            <DiscountInfoBox>
                                <GreyInfoTextDiscount>
                                    Total Discount Added:
                                </GreyInfoTextDiscount>
                                <BlackInfoTextDiscount>
                                    {studentData?.total_normal_discount} AED
                                </BlackInfoTextDiscount>
                            </DiscountInfoBox>
                        </NormalDiscountSection>
                        <ReferenceDiscountSection>
                            <TopBox>
                                <ReferenceDiscountBox>
                                    Reference Discount
                                </ReferenceDiscountBox>
                                <DiscountInfoBox>
                                    <GreyInfoTextDiscount>
                                        Total Amount:
                                    </GreyInfoTextDiscount>
                                    <BlackInfoTextDiscount>
                                        {studentData?.total_reference_amount}{" "}
                                        AED
                                    </BlackInfoTextDiscount>
                                </DiscountInfoBox>
                                <DiscountInfoBox>
                                    <GreyInfoTextDiscount>
                                        Added Amount:
                                    </GreyInfoTextDiscount>
                                    <BlackInfoTextDiscount>
                                        {studentData?.added_reference_discount}{" "}
                                        AED
                                    </BlackInfoTextDiscount>
                                </DiscountInfoBox>
                                <DiscountInfoBox>
                                    <GreyInfoTextDiscount>
                                        Balance Amount:
                                    </GreyInfoTextDiscount>
                                    <BlackInfoTextDiscount>
                                        {
                                            studentData?.balance_reference_discount
                                        }{" "}
                                        AED
                                    </BlackInfoTextDiscount>
                                </DiscountInfoBox>
                            </TopBox>
                            {studentData?.referred_students.length > 0 && (
                                <ReferenceDetailedSection>
                                    <ReferenceTable>
                                        <TableHeader>
                                            <TH width="120px">No</TH>
                                            <TH width="20%">Name</TH>
                                            <TH width="15%">Admission No</TH>
                                            <TH width="15%">Email</TH>
                                            <TH width="15%">Amount</TH>
                                            <TH width="15%">Total emi Paid</TH>

                                            <div></div>
                                        </TableHeader>
                                        {studentData?.referred_students.map(
                                            (referedStudent, index) => (
                                                <TR key={referedStudent.id}>
                                                    <TD
                                                        className="td-common-styles"
                                                        width="120px"
                                                    >
                                                        {index + 1}
                                                    </TD>
                                                    <TPD width="20%">
                                                        <ProfileImage
                                                            src={
                                                                referedStudent.image
                                                                    ? referedStudent.image
                                                                    : defaultProfile
                                                            }
                                                            alt="image"
                                                        />
                                                        <NameText>
                                                            {
                                                                referedStudent.name
                                                            }
                                                        </NameText>
                                                    </TPD>
                                                    <TD
                                                        className="td-common-styles"
                                                        width="15%"
                                                    >
                                                        {
                                                            referedStudent.admission_number
                                                        }
                                                    </TD>
                                                    <TD
                                                        className="td-common-styles"
                                                        width="15%"
                                                        style={{
                                                            textTransform:
                                                                "lowercase",
                                                        }}
                                                    >
                                                        {referedStudent.mail}
                                                    </TD>
                                                    <TD
                                                        className="td-common-styles"
                                                        width="15%"
                                                    >
                                                        {
                                                            referedStudent.reference_amount
                                                        }{" "}
                                                        AED
                                                    </TD>
                                                    <TD
                                                        className="td-common-styles"
                                                        width="15%"
                                                    >
                                                        {
                                                            referedStudent.total_emi_paid
                                                        }{" "}
                                                        AED
                                                    </TD>
                                                    <div></div>
                                                </TR>
                                            )
                                        )}
                                    </ReferenceTable>
                                </ReferenceDetailedSection>
                            )}
                        </ReferenceDiscountSection>
                    </DiscountSection>

                    <InstallmentButtonWrapper>
                        <InstallmentDetailsText>
                            Installment Details
                        </InstallmentDetailsText>
                        {/* <AssignLeadsButton>Assign Leads</AssignLeadsButton> */}
                    </InstallmentButtonWrapper>
                    {emiData.length > 0 ? (
                        emiData.map((singleEmi, index) => (
                            <DetailedSection key={index}>
                                <TopDiv>
                                    <CourseDiv>
                                        <CourseIconWrapper>
                                            {singleEmi?.course[0]}
                                        </CourseIconWrapper>
                                        <CourseNameWrapper>
                                            <CourseName>
                                                {singleEmi?.course}
                                            </CourseName>
                                            <UniversityName>
                                                {singleEmi?.university}
                                            </UniversityName>
                                        </CourseNameWrapper>
                                    </CourseDiv>
                                    <ButtonsWrapper>
                                    <DeletIconAll onClick={()=>{
                                            setStudentFeeId(singleEmi?.id)
                                            setDeleteConfirm(true)
                                        }}>
                                            <p>Delete All Emis</p>
                                            <DeleteAll>
                                            <img src={require("../../assets/images/delete.svg").default} alt="Icon"/>
                                            </DeleteAll>
                                        </DeletIconAll>
                                        <AddEmiButton
											disabled={singleEmi?.instalment_pending_amount <= 0}
											onClick={() => {
												if (singleEmi?.instalment_pending_amount > 0 ) {
												setSelectedAddEmiId(singleEmi.id);
												setVatPercentage(singleEmi.vat);
												setPendingInstalmentFee(singleEmi.instalment_pending_amount);
												handleOpenModal();
											}}}>
											+ Add Emi
											{singleEmi?.instalment_pending_amount <= 0 && <Tooltip disabled={singleEmi?.instalment_pending_amount <= 0}>Check course fee & Pending instalment Amount</Tooltip>}
										</AddEmiButton>
                                        <HideShowButton
                                            onClick={() => {
                                                if (
                                                    openedEmiId !== singleEmi.id
                                                ) {
                                                    setIsHide(true);
                                                    setOpenedEmiId(
                                                        singleEmi.id
                                                    );
                                                } else {
                                                    setIsHide(false);
                                                    setOpenedEmiId("");
                                                }
                                            }}
                                        >
                                            <HideText>
                                                {openedEmiId !== singleEmi.id
                                                    ? `View Details`
                                                    : `Hide Details`}
                                            </HideText>
                                            <HideIcon
                                                hidden={
                                                    openedEmiId !== singleEmi.id
                                                        ? true
                                                        : false
                                                }
                                                src={downArrow}
                                                alt="icon"
                                            />
                                        </HideShowButton>
                                    
                                    </ButtonsWrapper>
                                </TopDiv>
                                <div style={{ 
                                    display :"flex",
                                    justifyContent:"space-between",
                                    alignItems:"center",
                                    margin: "30px 0",
                                    width:"90%",
                                }}>
                                    <div>
                                        <p style={{
                                            color: "#9c9c9c",
                                        }}      
                                        >Fee Details :</p>
                                    </div>
                                    <DetailDiv>
                                        <GreyDetailText>
                                            Course Fee
                                        </GreyDetailText>
                                        <BlackDetailText>
                                            AED {singleEmi?.total_fee}
                                        </BlackDetailText>
                                    </DetailDiv>
                                    <DetailDiv>
                                        <GreyDetailText>
                                            VAT
                                        </GreyDetailText>
                                        <BlackDetailText>
                                            AED {singleEmi?.total_vat}
                                        </BlackDetailText>
                                    </DetailDiv>
                                    <DetailDiv>
                                        <GreyDetailText>
                                            Discount
                                        </GreyDetailText>
                                        <BlackDetailText>
                                            AED {singleEmi?.total_discount}
                                        </BlackDetailText>
                                    </DetailDiv>
                                    <DetailDiv>
                                        <GreyDetailText>
                                            Total Course Fee
                                        </GreyDetailText>
                                        <BlackDetailText>
                                            AED {singleEmi?.total_course_fee}
                                        </BlackDetailText>
                                    </DetailDiv>
                                    <DetailDiv>
                                        <GreyDetailText>
                                            Total Paid
                                        </GreyDetailText>
                                        <BlackDetailText>
                                            AED {singleEmi?.total_paid}
                                        </BlackDetailText>
                                    </DetailDiv>
                                    {/* <DetailDiv>
                                        <GreyDetailText>
                                            Total Balance
                                        </GreyDetailText>
                                        <BlackDetailText>
                                            AED {singleEmi?.total_due}
                                        </BlackDetailText>
                                    </DetailDiv> */}
                                    {/* <DetailDiv>
                                        <GreyDetailText>
                                            Pending EMI
                                        </GreyDetailText>
                                        <BlackDetailText>
                                            AED {singleEmi?.pending_emi_amount}
                                        </BlackDetailText>
                                    </DetailDiv> */}
                                    <DetailDiv>
                                        <GreyDetailText>
                                            Fee payable
                                        </GreyDetailText>
                                        <BlackDetailText>
                                            AED {singleEmi?.fee_payable}
                                        </BlackDetailText>
                                    </DetailDiv>
                                    <DetailDiv>
                                        <GreyDetailText>
                                            Pending Due
                                        </GreyDetailText>
                                        <BlackDetailText>
                                            AED {singleEmi?.pending_due_amount}
                                        </BlackDetailText>
                                    </DetailDiv> 
                                </div>
                                {openedEmiId === singleEmi.id && (
                                    <TableContainer>
                                        <StudentFeeTable
                                            feePaid={feePaid}
                                            setFeePaid={setFeePaid}
                                            selectedEmiId={selectedEmiId}
                                            setSelectedEmiId={setSelectedEmiId}
                                            getEmiData={getEmiData}
                                            setSelectedStatusEmiId={
                                                setSelectedStatusEmiId
                                            }
                                            setSelectedEmiStatus={
                                                setSelectedEmiStatus
                                            }
                                            setWarningModal={setWarningModal}
                                            setDeleteWarning={setDeleteWarning}
                                            setDeleteEmiId={setDeleteEmiId}
                                            handleResponseModal={handleResponseModal}
                                            isDeleted={isDeleted}
                                            setDeleted={setDeleted}
                                            selectedEmiData={singleEmi}
                                            allowedReferenceDiscount={studentData.balance_reference_discount}
                                        />
                                    </TableContainer>
                                )}
                            </DetailedSection>
                        ))
                    ) : (
                        <EmiLoaderContainer>
                            <h3>No EMI's Found.</h3>
                        </EmiLoaderContainer>
                    )}
                    </>
                    :<>
                    <RemarkHistoryDiv>
                        <div style={{
                            display : "flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            borderBottom : "1px solid #E7E7E7",
                            paddingBottom:"20px",
                        }}>
                        <Title>Remark History</Title>
                        <CustomDateContainer>
                        <EmptyDiv 
                            className={openDate ? "pop" : ""}
                            aria-describedby={dateAnchorId}
                            variant="contained"
                            onClick={handleClickDate}
                        > 
                        <img src={require("../../assets/images/filter-exe.svg").default} alt="Image"/>
                        </EmptyDiv>
                        <Popover
                            id={dateAnchorId}
                            open={openDate}
                            anchorEl={dateAnchorEl}
                            onClose={handleCloseDate}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <ContainerDate>
                            <DateFieldBox>
                                <DateFieldText>From</DateFieldText>
                                <DateInput
                                    onChange={(e) => {
                                        settingFromDate(e);
                                    }}
                                    type="date"
                                    name="From"
                                    // max={
                                    //      new Date().toLocaleDateString("en-CA")
                                    // }
                                    // max={new Date().toISOString().split('T')[0]}
                                    value={fromDate}
                                />
                            </DateFieldBox>
                            <DateFieldBox>
                                <DateFieldText>To</DateFieldText>
                                <DateInput
                                    onChange={(e) => {
                                        settingEndDate(e);
                                    }}
                                    type="date"
                                    name="To"
                                    min={fromDate }
                                    // max={new Date().toLocaleDateString("en-CA")}
                                    // max={new Date().toISOString().split('T')[0]}
                                    // disabled={fromDate  ? false : true}
                                    value={toDate}
                                />
                            </DateFieldBox>
                            <ApplyButtonDate
                                onClick={()=>{getFollowups();handleCloseDate()}}
                            >
                                Apply Filters
                            </ApplyButtonDate>
                            </ContainerDate>
                        </Popover>
                        <CustomDateFields>
                        </CustomDateFields>
                        </CustomDateContainer>
                        </div>
                        {
                            followupsData.length === 0 ? 
                            <div
                            style={{
                                height: "calc(100vh - 350px)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}> No data found </div>
                            :followupsData.map((data)=>(
                                <RemarkBox>
                                <Remark>{data.remark}</Remark>
                                <BottomRemark>
                                    <Author>{data.creator}</Author>
                                    <Date>{data.date_added}</Date>
                                </BottomRemark>
                            </RemarkBox>
                            ))
                        }
                    </RemarkHistoryDiv>
                    <div style={{ marginTop: "20px" }}>
                        <MainPagination
                            paginationData={paginationData}
                            pageCount={pageCount}
                            setPageCount={setPageCount}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            marginRight="0"
                            isCountSelector={true}
                            itemsCount={itemsCount}
                            setItemsCount={setItemsCount}
                            currentItemsCount={followupsData?.length}
                        />
                    </div>
                    </> 
                }
                </>
            ) : (
                <LoaderContainer>
                    <LoadingGif src={loadingIcon} alt="icon" />
                </LoaderContainer>
            )}
            {isWarningModal && (
                <WarningModal
                    isModal={isWarningModal}
                    setModal={setWarningModal}
                    handleFunction={handleStatusUpdate}
                />
            )}
            {deleteConfirm && (
                <WarningModalNew
                    isModal={deleteConfirm}
                    setModal={setDeleteConfirm}
                    handleFunction={deleteEmiAll}
                    title="Are you sure you want to delete all EMIs?"
                    deleteId={studentFeeId}
                />
            )
            }

            <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <AddEmiModal
                        emiId={selectedAddEmiId}
                        handleCloseModal={handleCloseModal}
                        getEmiData={getEmiData}
                        vatPercentage={vatPercentage}
                        pendingInstalmentFee={pendingInstalmentFee}
                    />
                </Box>
            </Modal>
            <Modal
                open={openModal}
                onClose={RemarkCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                   <AddRemarkModal
                        remark={remark}
                        setRemark={setRemark}
                        getEmiData={getEmiData}
                        handleCloseModal={RemarkCloseModal}
                        handleClosedueDate={handleClosedueDate}
                        editedRemark ={editedRemark} 
                        setEditedRemark ={setEditedRemark}
                        getFollowups={getFollowups}

                   />
            </Modal>

            {deleteWarning && (
				<DeleteWarning
					setWarningModal={setDeleteWarning}
					deleteStudent={deleteInstallment}
					deleteStudentId={deleteEmiId}
				/>
			)}
            {responseModal &&
                <SucessFullModal
                    handleResponseModal={handleResponseModal}
                    isDeleted={isDeleted}
                />
            }
        </Wrapper>
    );
}

const BreadcrumbsLine = styled.div`
    font-size: 15px;
    line-height: 24px;
    font-style: normal;
    font-weight: 400;
    color: #939393;
    display: flex;
    align-items: center;
`;
const PathBack = styled(Link)`
    font-size: 15px;
    line-height: 24px;
    font-style: normal;
    font-weight: 400;
    color: #939393;
    display: flex;
    align-items: center;
    cursor: pointer;
`;
const BlackText = styled.div`
    font-size: 15px;
    line-height: 24px;
    font-style: normal;
    font-weight: 400;
    color: #000;
    margin-left: 4px;
`;
const MainBox = styled.div`
    /* padding: 20px; */
    background: #ffffff;
    border: 1px solid #e7e7e7;
    border-radius: 10px 10px 0 0;
    margin-top: 20px;
    /* margin-bottom: 20px; */
`;
const MainTop = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid #e7e7e7;
    padding: 30px;
    @media (max-width: 1080px) {
        grid-template-columns: 1fr;
    }
`;
const MainTopLeft = styled.div`
    /* border-right: 1px dashed rgba(0, 0, 0, 0.5); */
    border-right: 1px dashed #e5e5e5;

    display: flex;
    padding-right: 35px;
    @media (max-width: 1080px) {
        border-right: none;
        margin-bottom: 25px;
    }
    /* align-items: center; */
`;
const ProfilePicWrapper = styled.div`
    /* display: none; */
    @media (max-width: 1080px) {
        display: block;
        width: 165px;
        display: flex;
        /* justify-content: center; */
        align-items: center;
    }
`;
const ProfilePicDiv = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50%;
`;
const ProfilePic = styled.img`
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 50%;
`;
const StudentDetails = styled.div`
    margin-left: 30px;
    @media (max-width: 1080px) {
        margin-left: 0;
    }
`;
const NameStatusLine = styled.div`
    display: flex;
    align-items: center;
`;
const StudentName = styled.h6`
    color: #000000;
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 10px;
    white-space: nowrap;
    text-transform: capitalize;
`;
const StatusWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    margin-left: 12px;
`;
const StatusContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 101px;
    height: 36px;
    background: rgba(121, 163, 88, 0.1);
    border-radius: 20px;
    color: #79a358;
    font-size: 0.95rem;
    font-weight: 500;
`;
const GreyText = styled.span`
    color: #000000;
    font-weight: 500;
    opacity: 0.5;
    font-size: 1rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    letter-spacing: 0.1px;
`;

const GreyCapitalizedText = styled.span`
    text-transform: capitalize;
    font-size: 1rem;
    display: block;
    white-space: nowrap;
    letter-spacing: 0.1px;
`;
const MainTopRight = styled.div`
    padding-left: 35px;
    @media (max-width: 1080px) {
        padding-left: 165px;
    }
`;
const InfoLine = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 13px;
    &:last-child {
        margin-bottom: 0px;
    }
`;
const GreyInfoText = styled.span`
    color: #000000;
    font-weight: 400;
    opacity: 0.5;
    font-size: 15px;
    display: block;
    width: 195px;
    white-space: nowrap;
    letter-spacing: 0.1px;
    box-sizing: border-box;
`;
const GreyInfoLeftText = styled.span`
    color: #000000;
    font-weight: 400;
    opacity: 0.5;
    font-size: 15px;
    display: block;
    width: 150px;
    white-space: nowrap;
    letter-spacing: 0.1px;
    box-sizing: border-box;
`;
const BlackInfoText = styled.span`
    display: block;
    color: #000000;
    font-weight: 400;
    font-size: 15px;
    white-space: nowrap;
    letter-spacing: 0.1px;
    text-transform: capitalize;
`;
const QualificationTileWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const QualificationTile = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 80px;
    height: 40px;
    background: #f8fafc;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 0 10px;
    font-size: 0.96rem;
    margin-right: 12px;
    cursor: pointer;
    &:last-child {
        margin-right: 0;
    }
`;
const MainBottom = styled.div`
    padding: 15px;
    display: flex;
    align-items: center;
`;
const CategoryButton = styled.div`
    padding: 10px 15px;
    height: 39px;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    background: ${(props) => (props.selected ? " #eaeff5" : "#fff")};
    border-radius: 8px;
    margin-right: 15px;
    cursor: pointer;
    &:last-child {
        margin-right: 0;
    }
`;
const BlackInfoFlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 100%; */
    width: calc(100% - 195px);
`;
const EditIcon = styled.img`
    width: 16px;
    object-fit: cover;
    display: block;
    margin-left: 2px;
    cursor: pointer;
`;
const InstallmentDetailsText = styled.span`
    display: inline-block;
    background: #eaeff5;
    border-radius: 8px;
    padding: 10px 16px;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;

    /* dark/black */

    color: #000000;
    /* margin-top: 15px; */
`;
const DetailedSection = styled.div`
    background: #ffffff;
    padding: 20px;
    border: 1px solid #e7e7e7;
    border-radius: 16px;
    margin-top: 15px;

    /* transition: 0.5s; */
    animation: slideInBottom2 0.3s ease-in-out forwards;
    transform-origin: top center;

    /* transition-duration: 0.6s; */
    @media (max-width: 989px) {
        overflow: scroll;
    }
`;
const TopDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const CourseDiv = styled.div`
    display: flex;
    align-items: center;
    width: 375px;
`;
const CourseIconWrapper = styled.div`
    width: 44px;
    height: 44px;
    /* padding: 4px; */
    border-radius: 50%;
    background: #f5f7fa;
    color: #522e92;
    font-size: 22px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    text-transform: uppercase;
`;
const CourseNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const CourseName = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    color: #522e92;
`;
const UniversityName = styled.div`
    font-size: 14px;
    font-weight: 500;
`;
const DetailDiv = styled.div`
    border-right: 1px solid #9c9c9c;
    padding-right:50px;
    &:last-child {
    border-right: none;
    }
`;
const GreyDetailText = styled.div`
    /* font-weight: 500; */
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 19px;
    color: #9c9c9c;
`;
const BlackDetailText = styled.div`
    font-size: 14px;
    line-height: 19px;
`;
const HideShowButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f8fafc;
    width: 110px;
    height: 30px;
    border: 1px solid #917afd;
    border-radius: 44px;
    box-sizing: border-box;
`;
const HideText = styled.h6`
    text-transform: capitalize;

    font-size: 12px;
    color: #010101;
    margin-right: 6px;
    transition-duration: 0.3s;
`;
const HideIcon = styled.img`
    width: 11px;
    display: block;
    object-fit: cover;
    transform: ${(props) => (props.hidden ? null : "rotate(180deg)")};
    transition-duration: 0.3s;
`;
const TableContainer = styled.div`
    /* animation: growDown 0.1s ease-in-out forwards; */
    transition-duration: 0.3s;
    /* transform-origin: top center; */
    /* background: #ffffff;
    padding: 8px;

    border: 1px solid #e7e7e7;
    border-radius: 16px;
    margin-top: 20px;
    transition-duration: 0.5s; */
`;
// const DateInput = styled.input`
//     display: inline-block;
//     width: 100%;
//     height: 30px;
//     font-size: 13px;
//     padding: 6px;
//     box-sizing: border-box;
//     border: 1px solid #d8d8d8 !important;
// `;
const DetailedDateInputDiv = styled.div`
    width: 300px;
    font-size: 13px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
`;
const SubmitButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 13px;
    padding: 4px;
    background: #917afd;
    width: 70px;
    height: 30px;
    box-sizing: border-box;
    margin-left: 8px;
    box-sizing: border-box;
    color: #fff;
    border-radius: 4px;
`;
const DetailedRemarkInputDiv = styled.div`
    width: 300px;
    padding: 6px;

    .textarea-label {
        display: block;
        border: 1px solid #d8d8d8;
        padding: 4px;
        /* border, padding, and other styles go here,
  don't set the height, and don't use floats or positioning */
    }
`;
const TopLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 10px; */
`;
const RemarkTitle = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 6px;

    color: #9c9c9c;
`;
const DetailedRemarkText = styled.p`
    /* max-width: 240px; */
    padding: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    box-sizing: border-box;
    /* or 150% */

    /* dark/black */

    color: #000000;
    /* margin-top: 10px; */
`;
const RemarkText = styled.div`
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /* dark/black */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #000000;
`;
const TextArea = styled.textarea`
    /* padding: 10px; */
    /* border: none; */
    resize: vertical;
    /* height: 100%; */
    min-height: 50px;
    max-height: 100px;
    margin-right: -6px;
    border: 0 none;
    margin: 0;
    outline: 0 none;
    padding: 0;
    width: 100%;
`;

const DiscountSection = styled.div`
    background: #edf5fe;
    border: 1px solid #e7e7e7;
    border-radius: 0px 0px 16px 16px;
    padding: 16px;
`;
const DiscountsTitle = styled.div`
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    color: #000000;
    margin-bottom: 8px;
`;
const NormalDiscountSection = styled.div`
    padding: 8px;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    border-radius: 12px;
    display: flex;
    align-items: center;
`;
const NormalDiscountBox = styled.div`
    background: #eaeff5;
    border-radius: 8px;
    padding: 8px 14px;
    font-size: 13px;
`;
const DiscountInfoBox = styled.div`
    display: flex;
    align-items: center;
    margin-left: 30px;
`;

const ReferenceDiscountSection = styled.div`
    padding: 8px;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    border-radius: 12px;
    margin-top: 15px;
`;
const ReferenceDiscountBox = styled.div`
    background: #eaeff5;
    border-radius: 8px;
    padding: 8px 14px;
    font-size: 13px;
`;
const GreyInfoTextDiscount = styled.span`
    color: #000000;
    font-weight: 400;
    opacity: 0.5;
    font-size: 13px;
    display: block;
`;
const BlackInfoTextDiscount = styled.span`
    font-size: 13px;
    line-height: 24px;
    font-style: normal;
    font-weight: 400;
    color: #000;
    margin-left: 4px;
`;
const TopBox = styled.div`
    display: flex;
    align-items: center;
    /* padding-bottom: 8px; */
`;

const TableHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 8px;
`;
const TH = styled.div`
    width: ${(props) => (props.width ? props.width : null)};
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #9c9c9c;
    margin-right: 3px;
    &:last-child {
        margin-right: 0;
    }
`;
const ReferenceDetailedSection = styled.div`
    margin-top: 8px;
    border-top: 1px solid #d7d7d7;
    .td-common-styles {
        margin-right: 2px;
        &:last-child {
            margin-right: 0;
        }
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 150% */

        /* dark/black */
        /* white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;*/

        color: #000000;
    }
`;
const ReferenceTable = styled.div``;
const TR = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 8px;
    &:nth-child(even) {
        background: #fbfbfb;
    }
`;
const TD = styled.div`
    text-transform: capitalize;
    width: ${(props) => (props.width ? props.width : null)};
`;
const TPD = styled.div`
    width: ${(props) => (props.width ? props.width : null)};

    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /* dark/black */

    color: #000000;
`;
const ProfileImage = styled.img`
    display: block;
    object-fit: cover;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 6px;
`;
const InstallmentButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
`;
const AssignLeadsButton = styled.div`
    background: #522f91;
    border-radius: 8px;
    padding: 8px 14px;
    font-size: 13px;
    font-weight: 400;
    color: #fff;
    cursor: pointer;
    user-select: none;
    &:hover {
        opacity: 0.9;
    }
`;
const NameText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
`;
const AddEmiButton = styled.div`
	width: 70px;
	background: ${({ disabled }) => (disabled ? '#ccc' : '#917afd')};
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 32px;
	box-sizing: border-box;
	font-size: 12px;
	color: #fff;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	margin-right: 10px;
	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
	&:hover {
		opacity: ${({ disabled }) => (disabled ? 0.5 : 0.9)};
	}
	position: relative;
`;

const Tooltip = styled.div`
	visibility: hidden;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 4px;
	padding: 5px;
	position: absolute;
	z-index: 1;
	bottom: 100%;
	left: 50%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
	width: 120px;
	${AddEmiButton}:hover & {
		visibility: ${({ disabled }) => (disabled ? 'visible' : 'hidden')};
		opacity: ${({ disabled }) => (disabled ? 1 : 0)};
	}
`;
const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content-space-between;
`;
const DeletIconAll = styled.button`
    display:flex;
    align-items:center;
    justify-content:space-between;
    cursor:pointer;
    border: 1px solid #917afd;
    border-radius: 4px;
    padding:7px;
    margin-right:10px;
    width: 125px;
`;
const DeleteAll = styled.div`
    width:15px;
    img{
        display:block;
        width:100%;
    }
`;
const LoaderContainer = styled.div`
    height: calc(100vh - 120px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LoadingGif = styled.img`
    width: 60px;
    display: block;
    object-fit: cover;
`;

const EmiLoaderContainer = styled.div`
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const DetailedStatusDiv = styled.div`
    width: 250px;
    box-sizing: border-box;
    font-size: 13px;
    padding: 8px;
    background: #fff;
`;
const ModalMainContainer = styled.div`
    /* background: #fff;
    border-radius: 24px;
    width: 980px; */
    padding: 15px;
`;
const SelectDiv = styled.div`
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    background: #ffffff;
    border: 1px solid #E7E7E7;
    border-radius: 12px;
    width:22%;
    margin:10px 0;
`;
const SelectItem = styled.div`
    padding: 8px 10px;
    background: ${(props) => (props.selected ? "#EAEFF5" : "#fff")};
    border-radius: 8px;
    font-size: 15px;
    cursor: pointer;
    color: ${(props) => (props.selected ? "#000" : "#9D9D9D")};
`;
const RemarkHistoryDiv = styled.div`
    padding:20px;
    background:#fff;
    max-height:500px;
    min-height:500px;
`;
const Title = styled.p`
    font-size:18px;
`;
const RemarkBox = styled.div`
    background:#F8FAFC;
    color:#9D9D9D;
    font-size:14px;
    padding:20px;
    border: 1px solid #E7E7E7;
    border-radius:4px;
    margin-top:20px;
`;
const Remark = styled.p``;
const BottomRemark = styled.div`
    display:flex;
    justify-content:space-between;
    color:#000;
    margin-top:8px;
`;
const Author = styled.p``;
const Date = styled.p``;
const CustomDateContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const EmptyDiv = styled.div`
    // display: flex;
    // align-items: center;
    // padding: 0px 20px;
    // height: 45px;
    // background-color: #000;
    // font-size: 15px;
    width: 20px;
    // border-radius: 9px;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // cursor: pointer;
    // &.pop{
    //     border: 1px solid #5D2D8F;
    // }
    img{
        dispaly:flex;
        width:100%;
        // filter: invert(1);
    }

`;
const DateContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
const TextDate = styled.div`
    color: #522E92;
    font-family: "poppins-medium";
    margin-top: 2px;
`;
const ContainerDate = styled.div`
    width: 280px;
    max-height: 360px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const DateFieldBox = styled.div`
    margin-right: 12px;
    &:last-child {
        margin-right: 0;
    }
`;
const DateFieldText = styled.span`
    display: block;
    margin-bottom: 8px;
    color: #515151;
    line-height: 25.6px;
    font-size: 16px;
`;
const DateInput = styled.input`
    width: 245px !important;
    padding: 4px 10px;
    height: 50px;
    margin-bottom: 12px;
    font-size: 18px;
    letter-spacing: 0.2px;
    line-height: 24px;
    font-weight: 400;
    color: #404040;
    -webkit-text-fill-color: #000;
    box-sizing: border-box;
    border: 1px solid rgb(102, 102, 102);
    border-radius: 8px;
    cursor: pointer;
    &:hover {
        background: #fbfbfb;
    }
    &:focus-visible {
        outline: none;
        background: #fbfbfb;
        border: 1px solid #2d60ff;
    }
`;
const ApplyButtonDate = styled.div`
    height: 50px;
    background: #522e92;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    margin: 10px 0px;
    width: 100%;
`;
const CustomDateFields = styled.div`
    display: flex;
    align-items: center;
`;