import ApiService from "../utils/fetch";

export const ListCampusService = async (data) => {
	let response = await ApiService.get(`/campus/campus-list/`, data);
	return response;
};

export const GetCampusService = async (id, data) => {
	let response = await ApiService.get(`/campus/campus-details/${id}/`, data);
	return response;
};

export const AddCampusService = async (data) => {
	var formdata = new FormData();
	formdata.append("name", data.name);
	formdata.append("image", data.image);
	formdata.append("email", data.email);
	formdata.append("phone", data.phone);
	formdata.append("secondary_phone", data.secondary_phone);
	formdata.append("address_line_1", data.address_line_1);
	formdata.append("address_line_2", data.address_line_2);
	formdata.append("city", data.city);
	formdata.append("state", data.state);
	formdata.append("country", data.country);
	formdata.append("zip_code", data.zip_code);

	let response = await ApiService.post(`/campus/campus-list/`, {
		body: formdata,
	});
	return response;
};

export const UpdateCampusService = async (data) => {
	var formdata = new FormData();
	formdata.append("name", data.name);
	formdata.append("email", data.email);
	formdata.append("phone", data.phone);
	formdata.append("secondary_phone", data.secondary_phone);
	formdata.append("address_line_1", data.address_line_1);
	formdata.append("address_line_2", data.address_line_2);
	formdata.append("city", data.city);
	formdata.append("state", data.state);
	formdata.append("country", data.country);
	formdata.append("zip_code", data.zip_code);
	if (data.image) {
		formdata.append("image", data.image);
	}
	let response = await ApiService.put(`/campus/campus-details/${data.id}/`, {
		body: formdata,
	});
	return response;
};

export const DeleteCampusService = async (id) => {
	let response = await ApiService.delete(`/campus/campus-details/${id}/`);
	return response;
};
