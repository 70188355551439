import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import logo from "../../assets/images/logo.png";
import NavBarItem from "./navBarItem";
import useScrollBlock from "../includes/useScrollBlock";

function NavBar({ data }) {
	const [link, setLink] = useState(window.location.pathname);
	const [isMouseOver, setIsMouseOver] = useState(false);

	const handleMouseOver = () => {
		setIsMouseOver(true);
	};

	const handleMouseOut = () => {
		setIsMouseOver(false);
	};
	const [blockScroll, allowScroll] = useScrollBlock();
	useEffect(() => {
		if (isMouseOver) {
		  blockScroll()
		} else {
		  allowScroll()
		}
	  }, [isMouseOver]);
	// const { state } = useContext(Context);

	// const [universities, setUniversities] = useState([]);
	// const [innerData, setInnerData] = useState([]);
	// const getUniversities = () => {
	// 	let accessToken = state.user_details.access_token;
	// 	baseConfig
	// 		.get("/campus/university/", {
	// 			headers: {
	// 				Authorization: "Bearer " + accessToken,
	// 			},
	// 		})
	// 		.then((response) => {
	// 			const { StatusCode, data } = response.data;
	// 			if (StatusCode === 6000) {
	// 				setUniversities(data.data);
	// 			} else {
	// 			}
	// 		})
	// 		.catch((error) => {});
	// };

	// useEffect(() => {
	// 	getUniversities();
	// }, []);

	// useEffect(() => {
	// 	let data = universities.map((data, i) => {
	// 		let value = {
	// 			id: data.id,
	// 			text: data.name,
	// 			link: `/finance/students/${data.id}`,
	// 		};

	// 		return value;
	// 	});
	// 	setInnerData([...data]);
	// }, [universities]);

	// const navBarData = [
	// 	{
	// 		img: dashboard,
	// 		text: "Dashboard",
	// 		link: "/finance/",
	// 	},
	// 	{
	// 		img: studentsIcon,
	// 		text: "Students",
	// 		inner: innerData,
	// 		// inner: [
	// 		// 	{
	// 		// 		text: "SSM",
	// 		// 		link: "/finance/students/ssm",
	// 		// 	},
	// 		// 	{
	// 		// 		text: "Singhania",
	// 		// 		link: "/finance/students/singhania",
	// 		// 	},
	// 		// 	{
	// 		// 		text: "OTHM",
	// 		// 		link: "/finance/students/othm",
	// 		// 	},
	// 		// ],
	// 	},
	// ];

	return (
		<div className="navBar" 
			onMouseOver={handleMouseOver}
			onMouseOut={handleMouseOut}
		>
			<div className="top">
				<img src={logo} alt="logo" />
			</div>
			<div className="bottom">
				<ul>
					{data?.map((item) => {
						let index = null;
						if (item?.inner) {
							item.inner.forEach((item, num) => {
								if (
									link === item.link ||
									link === item.addLink
								) {
									index = num;
								}
							});
						}
						return (
							<>
								{item.inner ? (
									<NavBarItem
										item={item}
										key={item.name}
										active={index}
										setLink={setLink}
									/>
								) : (
									<NavBarItem
										item={item}
										key={item.name}
										active={
											link === item.link ||
											`${link}/` === item.link
												? "true"
												: "false"
										}
										setLink={setLink}
									/>
								)}
							</>
						);
					})}
				</ul>
			</div>
		</div>
	);
}

export default NavBar;
