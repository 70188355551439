import React, { useEffect, useState, useContext } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import DeleteImage from "../../assets/images/delete.svg";
import Button from "../../components/Button";
import addbutton from "../../assets/images/addbutton.svg";
import Text from "../../components/Text";
import edit from "../../assets/images/edit.svg";
import visible from "../../assets/images/visible.svg";
import searchBtn from "./../../assets/images/header-search.svg";
import "./style.css";
import styled from "styled-components";
import {
    DeleteCampusAdminService,
    ListCampusAdminService,
} from "../../service/campusAdmin";
import { useHistory } from "react-router-dom";
import { Context } from "../../contexts/Store";
import DeleteModal from "./../../components/includes/DeleteModal";
import { baseConfig } from "./../../utils/config";
import AdminsListContainer from "./AdminsListContainer";
import AdminListCard from "./AdminListCard";
import MainPagination from "../../components/includes/MainPagination";

function UniversityAdmin() {
    const [campusAdmins, setCampusAdmins] = useState([]);
    const history = useHistory();
    const [searchItem, setSearchItem] = useState("");
    const [isLoading, setLoading] = useState(true);
    const { state } = useContext(Context);
    const [isModal, setModal] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [paginationData, setPaginationData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const getUniversityAdmin = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("campus/university-admin/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    page: currentPage,
                    search: searchItem,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setCampusAdmins(data?.data);
                    setPaginationData(data?.paginator);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        getUniversityAdmin();
    }, [currentPage, searchItem]);

    const handleDeleteData = () => {
        let accessToken = state.user_details.access_token;

        baseConfig
            .delete(`/campus/university-admin/${deleteId}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode == 6000) {
                    setModal(false);
                    getUniversityAdmin();
                } else if (StatusCode == 6001) {
                    alert(data.data.message);
                    getUniversityAdmin();
                }
            })
            .catch((error) => {});
    };

    return (
        <>
            <Wrapper>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "30px",
                    }}
                    className="top-title-wrapper"
                >
                    <div>
                        <Title className="top-title-large" fontSize="35px">
                            University Admins
                        </Title>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                        className="top-title-large-right"
                    >
                        <div
                            style={{
                                marginRight: "32px",
                            }}
                        >
                            <form className="search-container">
                                <img src={searchBtn} alt="search" />
                                <input
                                    className="input-box"
                                    type="text"
                                    name="search"
                                    placeholder="Search for something"
                                    onChange={(e) =>
                                        setSearchItem(e.target.value)
                                    }
                                />
                            </form>
                        </div>

                        <Button
                            // href="/superadmin/add-campus-admin/"
                            onClick={() =>
                                history.push(
                                    "/superadmin/add-university-admin/"
                                )
                            }
                            style={{
                                display: "flex",
                                alignItems: "center",
                                // padding: "2% 2%",
                            }}
                            backgroundColor="#5A2D8F"
                            fontSize="15px"
                        >
                            <img src={addbutton} alt="Logo" />
                            <Text
                                style={{ marginLeft: "12px" }}
                                color="white"
                                fontSize="15px"
                            >
                                Add Admin
                            </Text>
                        </Button>

                        <Button
                            className="refresh-button-large"
                            onClick={() => getUniversityAdmin()}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                margin: "0 22px",
                                cursor: "pointer",
                            }}
                            backgroundColor="white"
                            fontSize="15px"
                        >
                            <Text color=" #522e92" fontSize="15px">
                                Refresh
                            </Text>
                        </Button>
                    </div>
                </div>

                {isLoading === false ? (
                    <>
                        {campusAdmins.length !== 0 ? (
                            <AdminsListContainer
                            // style={{
                            // 	display: "flex",
                            // 	justifyContent: "space-between",
                            // 	flexWrap: "wrap ",
                            // }}
                            // className="university-admin-container-wrapper"
                            >
                                {campusAdmins?.map((admin) => {
                                    return (
                                        <AdminListCard
                                        // width="48%"
                                        // className="university-admin-container"
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <div>
                                                    <Title fontSize="27px ">
                                                        {admin.name}
                                                    </Title>
                                                </div>
                                                <div
                                                    style={{
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            width: "100%",
                                                            cursor: "pointer",
                                                        }}
                                                        src={DeleteImage}
                                                        alt="Logo"
                                                        onClick={() => {
                                                            setModal(true);
                                                            setDeleteId(
                                                                admin?.id
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ marginTop: "20px" }}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    <Text fontSize="15px">
                                                        Mail ID
                                                    </Text>
                                                    <h3
                                                        style={{
                                                            fontSize: "14px",
                                                            color: "grey",
                                                            marginLeft: "10px",
                                                            marginTop: "2px",
                                                        }}
                                                    >
                                                        {
                                                            admin?.user_data
                                                                ?.email
                                                        }
                                                    </h3>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    <Text fontSize="15px">
                                                        Username
                                                    </Text>
                                                    <h3
                                                        style={{
                                                            fontSize: "14px",
                                                            color: "grey",
                                                            marginLeft: "10px",
                                                            marginTop: "2px",
                                                        }}
                                                    >
                                                        {
                                                            admin?.user_data
                                                                ?.username
                                                        }
                                                    </h3>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            marginTop: "10px",
                                                        }}
                                                    >
                                                        <Text fontSize="15px">
                                                            Password
                                                        </Text>
                                                        <h3
                                                            style={{
                                                                fontSize:
                                                                    "14px",
                                                                color: "grey",
                                                                marginLeft:
                                                                    "10px",
                                                                marginTop:
                                                                    "4px",
                                                            }}
                                                        >
                                                            {(admin?.password)
                                                                .split("")
                                                                ?.map((e) => {
                                                                    return "*";
                                                                })}
                                                        </h3>
                                                    </div>
                                                    <Button
                                                        onClick={() => {
                                                            history.push(
                                                                `/superadmin/edit-university-admin/${admin.id}`
                                                            );
                                                        }}
                                                        backgroundColor="#5A2D8F"
                                                        fontSize="15px"
                                                        preventDefault
                                                        className="university-admin-update-button"
                                                    >
                                                        Update
                                                    </Button>
                                                </div>
                                            </div>
                                        </AdminListCard>
                                    );
                                })}
                            </AdminsListContainer>
                        ) : (
                            <div className="no-result">
                                <text className="no-result-text">
                                    No results found{" "}
                                </text>
                            </div>
                        )}

                        <MainPagination
                            paginationData={paginationData}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                        />
                    </>
                ) : (
                    <Div>
                        <LoadingGif
                            src={
                                require("./../../assets/images/loading-buffering.gif")
                                    .default
                            }
                            alt="Loading"
                        />
                    </Div>
                )}
            </Wrapper>
            {isModal && (
                <DeleteModal
                    isModal={isModal}
                    setModal={setModal}
                    deleteId={deleteId}
                    handleDeleteData={handleDeleteData}
                />
            )}
        </>
    );
}

const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
`;
const LoadingGif = styled.img`
    display: inline-block;
    width: 50px;
    @media (max-width: 980px) {
        width: 90px;
    }
`;
export default UniversityAdmin;
