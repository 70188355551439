import { Checkbox } from "@mui/material";
import React from "react";
import styled from "styled-components";
// import searchIcon from "../../../../assets/icons/finance-search-icon.svg";q
// import selectedIcon from "../../../../assets/icons/checkbox-selected.svg";
// import unSelectedIcon from "../../../../assets/icons/checkbox-unselected.svg";
import selectedIcon from "../../../assets/images/radio-s2.svg";
import unSelectedIcon from "../../../assets/images/radio-n-s2.svg";

export default function ProgramFilterPopover({
    handleCloseProgramFilter,
    programs,
    searchProgram,
    setSearchProgram,
    getApiData,
    selectedProgram,
    setSelectedProgram,
}) {
    return (
        <MainBox>
            {/* <SearchBoxDiv>
                <label htmlFor="search">
                    <SearchIcon id="search" src={searchIcon} alt="icon" />
                </label>
                <SearchInput
                    type="text"
                    placeholder="Search"
                    value={searchProgram}
                    onChange={(e) => setSearchProgram(e.target.value)}
                />
            </SearchBoxDiv> */}
            {/* <SubTitle>{programs.length} Programs</SubTitle> */}

            <ProgramCards>
                {/* {programs?.map((program, i) => (
					<ProgramCard
						key={program?.id}
						onClick={() => {
							if (selectedPrograms.includes(program.id)) {
								setSelectedPrograms(
									selectedPrograms.filter((p) => p !== program.id)
								);
							} else {
								setSelectedPrograms([...selectedPrograms, program.id]);
							}
						}}> */}
                {/* <CheckBoxIcon
							src={
								selectedPrograms.includes(program.id)
									? selectedIcon
									: unSelectedIcon
							}
							alt="icon"
						/> */}
                {programs?.map((program, i) => (
                    <ProgramCard
                        key={program?.id}
                        onClick={() => {
                            if (selectedProgram == program.id) {
                                setSelectedProgram(""); // Deselect the current program
                            } else {
                                setSelectedProgram(program.id); // Select the clicked program
                            }
                        }}
                    >
                        <RadioButtonIcon
                            src={
                                selectedProgram == program.id
                                    ? selectedIcon
                                    : unSelectedIcon
                            }
                            alt="icon"
                            // onClick={() => {
                            // 	getApiData();
                            // 	handleCloseProgramFilter();
                            // }}
                        />

                        <ProgramName>{program?.name}</ProgramName>
                    </ProgramCard>
                ))}
            </ProgramCards>

            {/* <FilterSection>
                <SubTitle>Filter By</SubTitle>
                <FilterItem>
                    <Round>
                        <InnerRound selected={true}></InnerRound>{" "}
                    </Round>
                    <ProgramName>A to Z</ProgramName>
                </FilterItem>
                <FilterItem>
                    <Round></Round>
                    <ProgramName selected={false}>Z to A</ProgramName>
                </FilterItem>
            </FilterSection> */}
            <ButtonsContainer>
                <CancelButton
                    onClick={() => {
                        handleCloseProgramFilter();
                    }}
                >
                    Cancel
                </CancelButton>
                <ApplyButton
                    onClick={() => {
                        getApiData();
                        handleCloseProgramFilter();
                    }}
                >
                    Apply
                </ApplyButton>
            </ButtonsContainer>
        </MainBox>
    );
}
const MainBox = styled.div`
    width: 250px;
    padding: 10px;
    max-height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
`;
const SearchBoxDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 8px;
    height: 36px;
    margin-right: 8px;
    /* background: #f5f5f5; */
    background: #fff;
    border-radius: 8px;
    /* border: 1px solid #f5f5f5; */
    border: 1px solid #d7d7d7;

    input[type="text"] {
        border: none; /* Remove default borders */
        padding: 4px 8px;
        border-radius: 12px; /* Sadly outline does not round! therefore...*/
    }
    input[type="text"]:focus {
        background: #fff;
        outline: none; /* Remove default outline and use border or box-shadow */
        /* box-shadow: 0 0 0 2px orange; */
        /* Full freedom. (works also with border-radius) */
    }
`;
const SearchIcon = styled.img`
    width: 18px;
    display: block;
    object-fit: cover;
    margin-right: 0px;
`;
const SearchInput = styled.input`
    display: inline-block;
    width: calc(210px - 18px);
    height: 34px;
    box-sizing: border-box;
    /* padding: 5px 0; */
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    border: none;
    color: #404040;

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #b6b6b6;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #b6b6b6;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #b6b6b6;
    }
`;
const SubTitle = styled.span`
    margin-top: 10px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: #404040;
`;
const ProgramCards = styled.div``;
const ProgramCard = styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 6px;
    cursor: pointer;
    &:hover {
        background: #f5f5f5;
    }
`;

// const CheckBoxIcon = styled.img`
// 	width: 21px;
// 	cursor: pointer;
// 	display: block;
// 	margin-right: 8px;
// `;
const RadioButtonIcon = styled.img`
    width: 21px;
    cursor: pointer;
    display: block;
    margin-right: 8px;
`;
const ProgramName = styled.div`
    text-transform: capitalize;
    font-size: 14.6px;
    font-weight: 400;
    color: #010101;
    margin-top: 4px;
`;
const FilterSection = styled.div``;
const FilterItem = styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 6px;
    cursor: pointer;
    &:hover {
        background: #f5f5f5;
    }
`;
const Round = styled.div`
    width: 19px;
    height: 19px;
    box-sizing: border-box;
    padding: 2px;
    margin-right: 8px;
    border-radius: 50%;
    border: 1px solid #9d9d9d;
`;
const InnerRound = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: ${(props) => (props.selected ? "#917AFD" : "#fff")};
`;
const ButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    margin-top: 10px;
`;
const CancelButton = styled.button`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #010101;
    letter-spacing: 0.04em;
    font-size: 14px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid #917afd;
    border-radius: 8px;
    cursor: pointer;
`;
const ApplyButton = styled.button`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #010101;
    letter-spacing: 0.04em;
    font-size: 14px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid #917afd;
    border-radius: 8px;
    background: #917afd;
    color: #fff;
    cursor: pointer;
`;
// const MainBox=styled.div``;
