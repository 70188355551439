import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import React, { useEffect, useState, useContext } from "react";
import { Context } from "./../../contexts/Store";
import Text from "../../components/Text";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { baseConfig } from "./../../utils/config";
import DeleteModal from "./../../components/includes/DeleteModal";

//svg
import Add from "./../../assets/images/addbutton.svg";
import University from "./../../assets/images/dashboard-school.svg";
import Pagination from "../../components/pagination/pagination";
import MainPagination from "../../components/includes/MainPagination";

function ListUniversity() {
    const history = useHistory();
    const { state } = useContext(Context);
    const [universityList, setUniversityList] = useState([]);
    const [isModal, setModal] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [paginationData, setPaginationData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setLoading] = useState(false);

    const getUniversity = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/university/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    page: currentPage,
                    // search: search,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setUniversityList(data.data);
                    setPaginationData(data.paginator);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const handleDeleteData = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .delete(`/campus/university/${deleteId}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    // alert("University deleted successfully");
                    setModal(false);
                    getUniversity();
                } else if (StatusCode == 6001) {
                    alert(data.data.message);
                    getUniversity();
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        getUniversity();
    }, [currentPage]);

    return (
        <>
            <Wrapper>
                <Top>
                    <TopLeft>
                        <Title fontSize="35px">Universities</Title>
                        {/* <Text
							fontSize="20px"
							fontWeight="400"
							color="#000"
							style={{ marginTop: "10px" }}
							type="buttonText"
						>
							University / All Universities
						</Text> */}
                    </TopLeft>
                    <TopRight
                        onClick={() => {
                            history.push("/superadmin/add-university/");
                        }}
                    >
                        <img src={Add} alt="icon" />
                        <Text
                            fontSize="16px"
                            fontWeight="400"
                            color="#fff"
                            style={{ marginLeft: "5px" }}
                        >
                            Add University
                        </Text>
                    </TopRight>
                </Top>
                {isLoading === false ? (
                    <>
                        <CardContainer>
                            {universityList.map((item, index) => (
                                <Card>
                                    <CardTop>
                                        <CardLeft>
                                            <img
                                                src={item.logo}
                                                alt="icon"
                                                style={{
                                                    display: "block",
                                                    height: "100%",
                                                    width: "100%",
                                                }}
                                            />
                                        </CardLeft>
                                        <CardRight>
                                            <Option>
                                                <img
                                                    src={
                                                        require("./../../assets/images/action-delete.svg")
                                                            .default
                                                    }
                                                    alt="icon"
                                                    onClick={() => {
                                                        setModal(true);
                                                        setDeleteId(item.id);
                                                        // deleteUniversity(item.id);
                                                    }}
                                                />
                                            </Option>
                                            <TopRight
                                                onClick={() => {
                                                    history.push(
                                                        `/superadmin/edit-university/${item.id}`
                                                    );
                                                }}
                                            >
                                                <Text
                                                    fontSize="16px"
                                                    fontWeight="400"
                                                    color="#fff"
                                                    style={{
                                                        marginLeft: "5px",
                                                    }}
                                                >
                                                    Update
                                                </Text>
                                            </TopRight>
                                        </CardRight>
                                    </CardTop>
                                    <CardBottom>
                                        <SubDiv>
                                            {/* <SubText>#{index + 1}</SubText> */}
                                            <SubText>
                                                #
                                                {(currentPage - 1) * 20 +
                                                    index +
                                                    1}
                                            </SubText>
                                        </SubDiv>
                                        <SubDiv>
                                            <MainText>{item.name}</MainText>
                                        </SubDiv>
                                        <SubDiv>
                                            <SubText>{item.address}</SubText>
                                        </SubDiv>
                                        <SubDiv>
                                            <MainText>Mail ID :</MainText>
                                            <SubText
                                                style={{ marginLeft: "10px" }}
                                            >
                                                {item.email}
                                            </SubText>
                                        </SubDiv>
                                        <SubDiv>
                                            <MainText>Mobile No :</MainText>
                                            <SubText
                                                style={{ marginLeft: "10px" }}
                                            >
                                                {item.phone}
                                            </SubText>
                                        </SubDiv>
                                    </CardBottom>
                                </Card>
                            ))}
                        </CardContainer>
                        <div style={{ marginTop: "10px" }}>
                            <MainPagination
                                paginationData={paginationData}
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                            />
                        </div>
                    </>
                ) : (
                    <Div>
                        <LoadingGif
                            src={
                                require("./../../assets/images/loading-buffering.gif")
                                    .default
                            }
                            alt="Loading"
                        />
                    </Div>
                )}
            </Wrapper>
            {isModal && (
                <DeleteModal
                    isModal={isModal}
                    setModal={setModal}
                    deleteId={deleteId}
                    handleDeleteData={handleDeleteData}
                />
            )}
        </>
    );
}

export default ListUniversity;

const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
`;
const LoadingGif = styled.img`
    display: inline-block;
    width: 50px;
    @media (max-width: 980px) {
        width: 90px;
    }
`;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const TopLeft = styled.div``;
const TopRight = styled.div`
    display: flex;
    align-items: center;
    background-color: #522e92;
    border-radius: 3px;
    padding: 12px 20px;
    cursor: pointer;
    @media (max-width: 768px) {
        padding: 12px 16px;
    }
`;
const CardContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    margin-top: 20px;
    /* min-height: calc(100vh - 200px); */
    @media (max-width: 1180px) {
        grid-template-columns: 1fr 1fr;
    }
`;
const Card = styled.div`
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
`;
const CardTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const CardLeft = styled.div`
    height: 150px;
    width: 150px;
    /* @media (max-width: 1180px) {
        width: 130px;
        height: 130px;
    }
    @media (max-width: 980px) {
        width: 150px;
        height: 150px;
    } */
`;
const CardRight = styled.div`
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: space-between;
    align-items: end;
    /* @media (max-width: 1180px) {
        height: 130px;
    }
    @media (max-width: 980px) {
        height: 150px;
    } */
`;
const Option = styled.div`
    cursor: pointer;
`;
const CardBottom = styled.div``;
const SubDiv = styled.h5`
    margin-top: 20px;
    display: flex;
    align-items: center;
`;
const MainText = styled.h4`
    color: #522e92;
    font-weight: 600;
    font-size: 18px;
`;
const SubText = styled.h5`
    color: #808080;
    font-size: 15px;
`;
