import ApiService from "../utils/fetch";

export const Login = async (data) => {
    var formdata = new FormData();
    formdata.append("username", data.username);
    formdata.append("password", data.password);
    // formdata.append("g-recaptcha-response", data.token);
    // formdata.append("captcha_token", data.recaptchaValue);

    let response = await ApiService.post(`/auth/token/`, {
        body: formdata,
        isLogin: true,
    });
    return response;
};
