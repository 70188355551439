import Header from "../../components/header/header";
import NavBar from "../../components/navBar/navBar";
import navBarData from "./navBarData";
import MainDashboard from "./mainDashboard";
import CampusAdmin from "./campusAdmin";
import AddCampusAdmin from "./addCampusAdmin";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./style.css";
import { useState } from "react";
import ListCampus from "./ListCampus";
import Leadmanagementadmin from "./leadManagerAdmin";
import LeadGenerateadmin from "./leadGenerateAdmin";
import LeadSalesAdmin from "./leadSalesAdmin";
import AddCampus from "./addCampus";
import UpdateCampus from "./updateCampus";
import UpdateCampusAdmin from "./updateCampusAdmin";
import AddLeadManagerAdmin from "./addLeadManagerAdmin";
import AddLeadGeneratorAdmin from "./addLeadGeneratorAdmin";
import AddSalesAdmin from "./addSalesAdmin";
import UpdateLeadManagerAdmin from "./updateLeadManager";
import UpdateLeadGeneratorAdmin from "./updateLeadGenerator";
import UpdateSalesPersonAdmin from "./updateSalesPerson";
import ArchivedCampus from "./ArchivedCampus";
import ListStudents from "./ListStudents";
import AddStudent from "./AddStudent";
import ListUniversity from "./ListUniversity";
import AddUniversity from "./AddUniversity";
import AddUniversityAdmin from "./AddUniversityAdmin";
import UniversityAdmin from "./UniversityAdmin";
import ListWebinar from "./ListWebinar";
import AddWebinar from "./AddWebinar";
import Faq from "./Faq";
// import FinanceAdmins from "./FinanceAdmins";
// import AddFinanceAdmin from "./AddFinanceManager";
// import UpdateFinanceAdmin from "./UpdateFinanceAdmin";
import FinanceManager from "./FinanceManager";
import UpdateFinanceManager from "./UpdateFinanceManager";
import AddFinanceManager from "./AddFinanceManager";
import FinanceExecutives from "./FinanceExecutives";
import AddFinanceExecutives from "./AddFinanceExecutives";
import UpdateFinanceExecutives from "./UpdateFinanceExecutives";

function SuperAdmin() {
    const [openNav, setOpenNav] = useState(false);
    return (
        <div className="body">
            <NavBar
                openNav={openNav}
                data={navBarData}
                setOpenNav={setOpenNav}
            />
            <div className="body-container">
                <Header setOpenNav={setOpenNav} />
                <Switch>
                    <Route
                        path="/superadmin/"
                        exact
                        component={MainDashboard}
                    />
                    <Route
                        path="/superadmin/campus-admins/"
                        component={CampusAdmin}
                    />
                    <Route
                        path="/superadmin/add-campus/"
                        component={AddCampus}
                    />
                    <Route
                        path="/superadmin/edit-campus/:params/"
                        component={UpdateCampus}
                    />
                    <Route
                        path="/superadmin/edit-campus-admin/:params/"
                        component={UpdateCampusAdmin}
                    />
                    <Route
                        path="/superadmin/edit-lead-manager/:params/"
                        component={UpdateLeadManagerAdmin}
                    />
                    <Route
                        path="/superadmin/edit-lead-generator/:params/"
                        component={UpdateLeadGeneratorAdmin}
                    />
                    <Route
                        path="/superadmin/edit-admission-consultant/"
                        component={UpdateSalesPersonAdmin}
                    />
                    <Route
                        path="/superadmin/add-campus-admin/"
                        component={AddCampusAdmin}
                    />

                    <Route
                        path="/superadmin/add-sales-manager/"
                        component={AddLeadManagerAdmin}
                    />
                    <Route
                        path="/superadmin/add-lead-generator/"
                        component={AddLeadGeneratorAdmin}
                    />
                    <Route
                        path="/superadmin/add-admission-consultant/"
                        component={AddSalesAdmin}
                    />

                    <Route
                        path="/superadmin/list-campus/"
                        component={ListCampus}
                    />
                    <Route
                        path="/superadmin/sales-manager/"
                        component={Leadmanagementadmin}
                    />
                    <Route
                        path="/superadmin/lead-generator/"
                        component={LeadGenerateadmin}
                    />
                    <Route
                        path="/superadmin/admission-consultant/"
                        component={LeadSalesAdmin}
                    />
                    <Route
                        path="/superadmin/archived-campus/"
                        component={ArchivedCampus}
                    />
                    {/* <Route
						path="/superadmin/add-student/"
						component={AddStudent}
					/>
					<Route
						path="/superadmin/list-students/"
						component={ListStudents}
					/> */}
                    <Route
                        path="/superadmin/list-university/"
                        component={ListUniversity}
                    />
                    <Route
                        path="/superadmin/add-university/"
                        // component={AddUniversity}
                        component={(props) => <AddUniversity {...props} />}
                        // forceRefresh={true}
                        // render={(props) => window.location.reload()}
                    />
                    <Route
                        path="/superadmin/edit-university/:ids"
                        // component={AddUniversity}
                        component={(props) => <AddUniversity {...props} />}
                    />
                    <Route
                        path="/superadmin/add-university-admin/"
                        component={AddUniversityAdmin}
                    />
                    <Route
                        path="/superadmin/edit-university-admin/:universityId"
                        component={AddUniversityAdmin}
                    />
                    <Route
                        path="/superadmin/university-admin/"
                        component={UniversityAdmin}
                    />
                    <Route
                        path="/superadmin/webinar/"
                        component={ListWebinar}
                    />
                    <Route
                        path="/superadmin/add-webinar/"
                        component={AddWebinar}
                    />
                    <Route
                        path="/superadmin/edit-webinar/:id/"
                        component={AddWebinar}
                    />
                    <Route path="/superadmin/faq/" component={Faq} />
                    <Route
                        path="/superadmin/finance-manager/"
                        component={FinanceManager}
                    />
                    <Route
                        path="/superadmin/add-finance-manager/"
                        component={AddFinanceManager}
                    />
                    <Route
                        path="/superadmin/edit-finance-manager/:editId/"
                        component={UpdateFinanceManager}
                    />
                    <Route
                        path="/superadmin/finance-executives/"
                        component={FinanceExecutives}
                    />
                    <Route
                        path="/superadmin/add-finance-executive/"
                        component={AddFinanceExecutives}
                    />
                    <Route
                        path="/superadmin/edit-finance-executive/:editId/"
                        component={UpdateFinanceExecutives}
                    />
                </Switch>
            </div>
        </div>
    );
}

export default SuperAdmin;
