import React, { useState } from "react";
import styled from "styled-components";

export default function EMIStep1Form({ stepOneValue, setStepOneValue, setAlert }) {
	const blockInvalidChar = (e) =>
		["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

	const handlePaymentData = (field, value) => {
		let amount = value ? parseFloat(value) : 0;
		value = value ? value : "";
		if (field === "course_fee") {
			setStepOneValue({
				...stepOneValue,
				course_fee: value,
				total_installment_amount: 0,
				registration_fee: 0
			});
		}
		else {
			let registration_fee = stepOneValue.registration_fee ? parseFloat(stepOneValue.registration_fee) : 0;
			let instalment_fee = stepOneValue.total_installment_amount ? parseFloat(stepOneValue.total_installment_amount) : 0;
			let course_fee = stepOneValue.course_fee ? parseFloat(stepOneValue.course_fee) : 0;
			let maxAllowAmount = 0;

			if (field === "registration_fee") {
				registration_fee = amount;
				maxAllowAmount = course_fee - instalment_fee;
			} else if (field === "total_installment_amount") {
				instalment_fee = amount;
				maxAllowAmount = course_fee - registration_fee;
			}
			let total_fee = registration_fee + instalment_fee;
			console.log(total_fee, "123----------------");
	
			if (course_fee >= total_fee) {
				setStepOneValue({
					...stepOneValue,
					[field]: value,
				});
			} else if (course_fee <= 0) {
                setAlert(`Please enter correct 'Total Fee'. ${course_fee} not allowed.`);
            }
            else {
                setAlert(`Amount added is too high. Maximum allowed is ${maxAllowAmount}.`);
			}
		}
	};

	return (
		<>
			<FormContainer>
				<SingleForm>
					<FormLabel htmlFor="Course_Fee">
						Total Fee (Excl. VAT) <Star>*</Star>
					</FormLabel>
					<FormInput
						type="number"
						name="Course_Fee"
						id="Course_Fee"
						required
						onChange={(e) => handlePaymentData("course_fee", e.target.value)}
						value={stepOneValue.course_fee}
						onKeyDown={blockInvalidChar}
						onWheel={(event) => event.currentTarget.blur()}
					/>
				</SingleForm>
				<SingleForm>
					<FormLabel htmlFor="Registration_Fee">
						Registration Fee <Star>*</Star>
					</FormLabel>
					<FormInput
						type="number"
						name="Registration_Fee"
						id="Registration_Fee"
						required
						onChange={(e) => handlePaymentData("registration_fee", e.target.value)}
						value={stepOneValue.registration_fee}
						onKeyDown={blockInvalidChar}
						onWheel={(event) => event.currentTarget.blur()}
					/>
				</SingleForm>
				<SingleForm>
					<FormLabel htmlFor="Total_Installment_Amount">
						Total Installment Amount <Star>*</Star>
					</FormLabel>
					<FormInput
						type="number"
						id="Total_Installment_Amount"
						required
						onChange={(e) => handlePaymentData("total_installment_amount", e.target.value)}
						value={stepOneValue.total_installment_amount}
						onKeyDown={blockInvalidChar}
						onWheel={(event) => event.currentTarget.blur()}
					/>
				</SingleForm>
				<SingleForm>
					<FormLabel htmlFor="Start_Date">
						Start Date <Star>*</Star>
					</FormLabel>
					<FormInput
						id="Start_Date"
						type="date"
						required
						onChange={(e) => setStepOneValue({ ...stepOneValue, starting_date: e.target.value })}
						value={stepOneValue.starting_date}
					/>
				</SingleForm>
				<SingleForm>
					<FormLabel htmlFor="vat">
						VAT <Star>*</Star>
					</FormLabel>
					<FormInput
						type="number"
						id="vat"
						required
						onChange={(e) => setStepOneValue({ ...stepOneValue, vat: e.target.value })}
						value={stepOneValue.vat}
						onKeyDown={blockInvalidChar}
						onWheel={(event) => event.currentTarget.blur()}
					/>
				</SingleForm>
				<SingleForm>
					<FormLabel htmlFor="Total_Period">
						Total Period (Months) <Star>*</Star>
					</FormLabel>
					<FormInput
						type="number"
						id="Total_Period"
						required
						onChange={(e) => setStepOneValue({ ...stepOneValue, total_period: e.target.value })}
						value={stepOneValue.total_period}
						onKeyDown={blockInvalidChar}
						onWheel={(event) => event.currentTarget.blur()}
					/>
				</SingleForm>
			</FormContainer>
		</>
	);
}

const FormContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
    grid-gap: 20px 30px;
	background: #f8fafc;
	padding: 18px;
	border: 1px solid #e7e7e7;
	border-radius: 8px;
	margin-bottom: 28px;
`;

const SingleForm = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const FormLabel = styled.label`
    /* font-family: "Poppins"; */
	font-style: normal;
	font-weight: 400;
    font-size: 15px;
	color: #010101;
	margin-bottom: 8px;
    /* cursor: pointer; */
    /* font-family: "poppinsregular"; */
`;

const FormInput = styled.input`
	background: #ffffff;
	border: 1px solid #e2e2e2;
    /* font-family: "poppinsregular"; */
	border-radius: 4px;
	height: 48px;
	padding: 0 12px;
    font-size: 15px;
`;

const Star = styled.span`
	color: red;
	font-size: 18px;
	margin-left: 5px;
`;
