import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../contexts/Store";
import { baseConfig } from "../../utils/config";
import loadingIcon from "../../assets/lottie/loading.gif";

import Wrapper from "../../components/Wrapper";
import searchIcon from "../../assets/images/finance-search-icon.svg";
import filterIcon from "../../assets/images/finance-filter-icon.svg";
import StudentTable from "./includes/StudentTable";
import Title from "../../components/Title";
import { Popover } from "@mui/material";
import MainPagination from "../../components/includes/MainPagination";
export default function Students() {
    const { university, university_name } = useParams();
    const { state, dispatch } = useContext(Context);
    const [studentsData, setStudentsData] = useState();
    const [search, setSearch] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const [paginationData, setPaginationData] = useState({});
    const [statusCount,setStatusCount] = useState("");

    const selectOptions = [
        { name: "All", value: "", count:statusCount.all_count },
        { name: "Active", value: "Active", count:statusCount.active_count },
        { name: "On Hold", value: "On Hold", count:statusCount.onhold_count },
        { name: "On Break", value: "On Break", count:statusCount.onbreak_count },
        { name: "Completed", value: "Completed", count:statusCount.completed_count },
        { name: "Dropout", value: "Dropout", count:statusCount.dropout_count },
        // { name: "On-Due", value: "On-Due" },
        // { name: "Up-to-Date", value: "Up-To-Date" },
    ];
    const [selectedOption, setSelectedOption] = useState(
        selectOptions[0].value
    );
    const [isLoading, setIsLoading] = useState(false);

    // For Filter Purpose
    const [courses, setCourses] = useState([]);
    const [specializations, setSpecializations] = useState([]);
    const [sortValue, setSortValue] = useState("");
    const [universityValues, setUniversityValues] = useState("");
    const [dateOrder, setDateOrder] = useState("");
    const [courseValues, setCourseValues] = useState("");
    const [specializationValue, setSpecializationValue] = useState("");
    const dateOrders = [
        { name: "New to old", value: "new" },
        { name: "Old to new", value: "old" },
    ];
    // Filter popover onClick states and functions
    const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
    const handleClickFilter = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };
    const handleCloseFilter = () => {
        setFilterAnchorEl(null);
    };
    const openFilter = Boolean(filterAnchorEl);
    const filterAnchorId = openFilter ? "simple-popover" : undefined;
    // end of filter

    const getStudentsData = () => {
        setIsLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/finance/assigned-students/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    page: currentPage,
                    status: selectedOption,
                    university: university,
                    search: search,
                    // course: courseValues,
                    // specialization: specializationValue,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setStudentsData(data.data);
                    setPaginationData(data.paginator);
                    // setMonthlyData(data.monthly);
                    // setYearlyData(data.yearly);
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 300);
                } else {
                    setIsLoading(false);
                    alert("Some error occured.Please try again later");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                alert(error);
            });
    };

    const getCourses = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/courses/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    university: university,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setCourses(data.data);
                } else {
                }
            })
            .catch((error) => {});
    };
    const getSpecializations = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/select-specializations/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    course: courseValues,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setSpecializations(data);
                } else {
                }
            })
            .catch((error) => {});
    };
    useEffect(() => {
        getStudentsData();
    }, [
        selectedOption,
        university,
        search,
        // courseValues,
        // specializationValue,
        currentPage,
    ]);

    useEffect(() => {
        getCourses();
    }, [university]);

    useEffect(() => {
        getSpecializations();
    }, [courseValues]);

    const getStatusCount=()=>{
    console.log("Hereeeee")
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/finance/student-status-count/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params :{
                    university : university,
                }
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setStatusCount(data)
                }
            })
            .catch((error) => {});
    }
    useEffect(()=>{getStatusCount()},[studentsData])


    return (
        <Wrapper>
            <TitleBox>
                <TitleTextWrapper>
                    {/* {studentsData?.length > 0 && ( */}
                    <TitleTextCaps>
                        {/* {studentsData[0].university} */}
                        {university_name}
                    </TitleTextCaps>
                    {/* )} */}
                    <TitleText>Students</TitleText>
                </TitleTextWrapper>
            </TitleBox>
            <FilterLine>
                <FilterLeft>
                    <SelectDiv>
                        {selectOptions.map((item, index) => (
                            <>
                            <SelectItem
                                key={index}
                                selected={
                                    selectedOption === item.value ? true : false
                                }
                                onClick={() => {
                                    setSelectedOption(item.value);
                                }}
                            >
                                {item.name}
                            <span>({item.count})</span>
                            </SelectItem>
                            </>
                        ))}
                    </SelectDiv>
                </FilterLeft>
                <FilterRight>
                    <SearchBoxDiv>
                        <label htmlFor="search">
                            <SearchIcon
                                id="search"
                                src={searchIcon}
                                alt="icon"
                            />
                        </label>
                        <SearchInput
                            type="text"
                            placeholder="Search"
                            maxLength="100"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </SearchBoxDiv>

                    <FilterWrapper>
                        {/* <FilterButton
                            aria-describedby={filterAnchorId}
                            variant="contained"
                            onClick={handleClickFilter}
                            filterActivated={
                                courseValues || specializationValue
                                    ? true
                                    : false
                            }
                        >
                            <FilterIcon src={filterIcon} alt="icon" />
                            Filter
                        </FilterButton> */}
                        <Popover
                            id={filterAnchorId}
                            open={openFilter}
                            anchorEl={filterAnchorEl}
                            onClose={handleCloseFilter}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <Container>
                                <Div>
                                    <Title fontSize="20px">Filter by</Title>
                                    <img
                                        style={{
                                            cursor: "pointer",
                                            width: "16px",
                                        }}
                                        onClick={handleCloseFilter}
                                        src={
                                            require("./../../assets/images/close.svg")
                                                .default
                                        }
                                        alt="icon"
                                    />
                                </Div>
                                <div
                                    style={{
                                        minHeight: "250px",
                                        maxHeight: "70vh",
                                        overflow: "scroll",
                                    }}
                                >
                                    {/* <Body borderBottom="6px solid #f2f2f2">
                                        <Heading>Name</Heading>
                                        <div
                                            onClick={() => {
                                                if (sortValue === "name") {
                                                    setSortValue("");
                                                } else {
                                                    setSortValue("name");
                                                }
                                            }}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "10px 0 0",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <img
                                                src={
                                                    require("./../../assets/images/a-z.svg")
                                                        .default
                                                }
                                                alt="icon"
                                            />
                                            <SubHeading
                                                style={{
                                                    color:
                                                        sortValue === "name"
                                                            ? "#552E91"
                                                            : "#515151",
                                                }}
                                            >
                                                From A to Z
                                            </SubHeading>
                                            {sortValue === "name" && (
                                                <img
                                                    src={
                                                        require("./../../assets/images/tick.svg")
                                                            .default
                                                    }
                                                    alt="icon"
                                                />
                                            )}
                                        </div>
                                        <div
                                            onClick={() => {
                                                if (sortValue === "-name") {
                                                    setSortValue("");
                                                } else {
                                                    setSortValue("-name");
                                                }
                                            }}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "10px 0 0",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <img
                                                src={
                                                    require("./../../assets/images/z-a.svg")
                                                        .default
                                                }
                                                alt="icon"
                                            />
                                            <SubHeading
                                                style={{
                                                    color:
                                                        sortValue === "-name"
                                                            ? "#552E91"
                                                            : "#515151",
                                                }}
                                            >
                                                From Z to A
                                            </SubHeading>
                                            {sortValue === "-name" && (
                                                <img
                                                    src={
                                                        require("./../../assets/images/tick.svg")
                                                            .default
                                                    }
                                                    alt="icon"
                                                />
                                            )}
                                        </div>
                                    </Body> */}
                                    {/* <Body borderBottom="6px solid #f2f2f2">
                                        <Heading>Date</Heading>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            {dateOrders.map((item) => (
                                                <div
                                                    onClick={() => {
                                                        setDateOrder(
                                                            item.value
                                                        );
                                                        if (
                                                            dateOrder ===
                                                            item.value
                                                        ) {
                                                            setDateOrder("");
                                                        }
                                                    }}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "10px 0 0",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <SubHeading
                                                        style={{
                                                            color:
                                                                dateOrder ===
                                                                item.value
                                                                    ? "#552E91"
                                                                    : "#515151",
                                                        }}
                                                    >
                                                        {item.name}
                                                    </SubHeading>
                                                    {dateOrder ===
                                                        item.value && (
                                                        <img
                                                            src={
                                                                require("./../../assets/images/tick.svg")
                                                                    .default
                                                            }
                                                            alt="icon"
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </Body> */}
                                    {courses && (
                                        <Body borderBottom="6px solid #f2f2f2">
                                            <Heading>Course</Heading>
                                            {courses.map((data, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => {
                                                        setCourseValues(
                                                            data.id
                                                        );
                                                        if (
                                                            courseValues ===
                                                            data.id
                                                        ) {
                                                            setCourseValues("");
                                                            setSpecializationValue(
                                                                ""
                                                            );
                                                        }
                                                    }}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: "10px 0 0",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <SubHeading
                                                        style={{
                                                            color:
                                                                courseValues ===
                                                                data.id
                                                                    ? "#552E91"
                                                                    : "#515151",
                                                        }}
                                                    >
                                                        {data.name}
                                                    </SubHeading>
                                                    {courseValues ===
                                                        data.id && (
                                                        <img
                                                            src={
                                                                require("./../../assets/images/tick.svg")
                                                                    .default
                                                            }
                                                            alt="icon"
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                        </Body>
                                    )}
                                    {courseValues && specializations && (
                                        <Body borderBottom="6px solid #f2f2f2">
                                            <Heading>Specializations</Heading>
                                            {specializations.map(
                                                (data, index) => (
                                                    <div
                                                        key={index}
                                                        onClick={() => {
                                                            setSpecializationValue(
                                                                data.id
                                                            );
                                                            if (
                                                                specializationValue ===
                                                                data.id
                                                            ) {
                                                                setSpecializationValue(
                                                                    ""
                                                                );
                                                            }
                                                        }}
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            padding: "10px 0 0",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <SubHeading
                                                            style={{
                                                                color:
                                                                    specializationValue ===
                                                                    data.id
                                                                        ? "#552E91"
                                                                        : "#515151",
                                                            }}
                                                        >
                                                            {data.name}
                                                        </SubHeading>
                                                        {specializationValue ===
                                                            data.id && (
                                                            <img
                                                                src={
                                                                    require("./../../assets/images/tick.svg")
                                                                        .default
                                                                }
                                                                alt="icon"
                                                            />
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </Body>
                                    )}
                                    <ButtonsBody>
                                        <ResetButton
                                            onClick={() => {
                                                setCourseValues("");
                                                setSpecializationValue("");

                                                // handleCloseFilter();
                                            }}
                                        >
                                            Reset
                                        </ResetButton>
                                        <CloseButton
                                            onClick={() => {
                                                handleCloseFilter();
                                            }}
                                        >
                                            Close
                                        </CloseButton>
                                    </ButtonsBody>
                                </div>
                            </Container>
                        </Popover>
                    </FilterWrapper>
                </FilterRight>
            </FilterLine>
            {!isLoading ? (
                studentsData?.length > 0 ? (
                    <TableAndPaginatorContainer>
                        <StudentTableWrapper>
                            <StudentTable
                                data={studentsData}
                                currentPage={currentPage}
                            />
                        </StudentTableWrapper>
                        <div style={{ marginTop: "auto" }}>
                            <MainPagination
                                paginationData={paginationData}
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                            />
                        </div>
                    </TableAndPaginatorContainer>
                ) : (
                    <LoaderContainer>
                        <h3 style={{ opacity: "0.6" }}>No Students Found</h3>
                    </LoaderContainer>
                )
            ) : (
                <LoaderContainer>
                    <LoadingGif src={loadingIcon} alt="icon" />
                </LoaderContainer>
            )}
        </Wrapper>
    );
}

const TitleBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;
const TitleTextWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const TitleTextCaps = styled.h6`
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    color: #000000;
    text-transform: capitalize;
    margin-right: 12px;
`;
const TitleText = styled.h6`
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    color: #000000;
    text-transform: capitalize;
`;
const FilterLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;
const FilterLeft = styled.div``;
const SelectDiv = styled.div`
    height: 42px;
    box-sizing: border-box;
    padding: 4px;
    display: flex;
    align-items: center;

    background: #ffffff;

    border: 1px solid #d7d7d7;
    border-radius: 12px;
`;
const SelectItem = styled.div`
    padding: 8px 10px;
    background: ${(props) => (props.selected ? "rgb(237, 245, 254)" : "#fff")};
    border-radius: 8px;
    font-size: 15px;
    cursor: pointer;
`;
const FilterRight = styled.div`
    display: flex;
    align-items: center;
`;
const SearchBoxDiv = styled.div`
    width: 270px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 8px;
    width: 270px;
    height: 42px;

    /* background: #f5f5f5; */
    background: #fff;
    border-radius: 8px;
    /* border: 1px solid #f5f5f5; */
    border: 1px solid #d7d7d7;

    input[type="text"] {
        border: none; /* Remove default borders */
        padding: 4px 8px;
        border-radius: 12px; /* Sadly outline does not round! therefore...*/
    }
    input[type="text"]:focus {
        outline: none; /* Remove default outline and use border or box-shadow */
        /* box-shadow: 0 0 0 2px orange; */
        /* Full freedom. (works also with border-radius) */
    }
`;
const SearchIcon = styled.img`
    width: 18px;
    display: block;
    object-fit: cover;
    margin-right: 0px;
`;
const SearchInput = styled.input`
    display: inline-block;
    width: calc(270px - 18px);
    height: 100%;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    border: none;
    color: #404040;

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #b6b6b6;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #b6b6b6;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #b6b6b6;
    }
`;
const FilterWrapper = styled.div`
    margin-left: 15px;
    position: relative;
`;
const FilterButton = styled.div`
    width: 95px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;

    /* main/white */

    background: #ffffff;
    /* background: ${(props) =>
        props.filterActivated ? "#8561c6" : "#ffffff"}; */
    /* main/secondary */

    border: 1px solid #8561c6;
    /* border: ${(props) =>
        props.filterActivated ? "1px solid #fff" : "1px solid #8561c6"}; */
    border-radius: 8px;
    font-weight: 500;
    font-size: 15px;
    line-height: 87%;
    /* or 14px */

    /* main/secondary */

    color: #8561c6;
    /* color: ${(props) => (props.filterActivated ? "#ffffff" : "#8561c6")}; */
`;
const FilterIcon = styled.img`
    width: 15px;
    margin-right: 10px;
`;
const Container = styled.div`
    // height: 130px;
    width: 280px;
    max-height: 360px;
    // padding: 30px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const Div = styled.div`
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    background-color: #f1f8fe;
    padding: 15px 15px;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #cce7ff;
`;
const Body = styled.div`
    background-color: #fff;
    padding: 15px;
    /* max-height: 70vh; */
    // border-radius 0 0 5px 5px;
    overflow: scroll;
    border-bottom: ${(props) =>
        props.borderBottom ? props.borderBottom : null};
`;
const Heading = styled.h4`
    font-size: 16px;
    color: #515151;
    font-weight: 600;
`;
const SubHeading = styled.h4`
    font-size: 13px;
    color: "#515151";
    margin: 0 10px;
`;
const TableAndPaginatorContainer = styled.div`
    /* height: calc(100vh - 210px); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* overflow-y: scroll; */
`;
const StudentTableWrapper = styled.div`
    margin-bottom: 8px;
`;
const FilterModal = styled.div``;

const LoaderContainer = styled.div`
    height: calc(100vh - 210px);
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #0f0; */
`;
const LoadingGif = styled.img`
    width: 60px;
    display: block;
    object-fit: cover;
`;
const ButtonsBody = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    /* max-height: 70vh; */
    // border-radius 0 0 5px 5px;
    overflow: scroll;
    border-bottom: ${(props) =>
        props.borderBottom ? props.borderBottom : null};
`;
const ResetButton = styled.div`
    width: 60%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #80669d; */
    background: #522e92;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
    &:hover {
        opacity: 0.9;
    }
`;
const CloseButton = styled.div`
    width: 38%;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #80669d; */
    background: #ededed;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    color: #522e92;
    &:hover {
        /* opacity: 0.7; */
        background: #efefef;
    }
`;
// const TitleText = styled.div``;
// const TitleText = styled.div``;
