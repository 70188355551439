import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import React, { useEffect, useState, useContext } from "react";
import { Context } from "./../../contexts/Store";
import Text from "../../components/Text";
import Container from "../../components/Container";
import styled from "styled-components";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import UploadInput from "../../components/UploadInput/UploadInput";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../components/Button";
import { baseConfig } from "./../../utils/config";
import SweetAlert from "../../components/includes/SweetAlert";
import AlertBox from "../../components/includes/AlertBox";

function AddUniversity() {
    const { ids } = useParams();
    const [file, setFile] = useState(null);
    const history = useHistory();
    const { state } = useContext(Context);

    const [id, setId] = useState("");
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [secondaryPhone, setSecondaryPhone] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [fieldName, setFieldName] = useState("");
    const [emailValidation, setEmailValidation] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [universityModal, seyUniversityModal] = useState("");
    const [universityModalList, setUniversityModalList] = useState([
        {
            id: 1,
            value: "examination model",
            name: "Examination Model",
        },
        {
            id: 2,
            value: "assignment model",
            name: "Assignment Model",
        },
    ]);
    // sweetalert states and functions
    const [isSweetAlert, setIsSweetAlert] = useState(false);
    const [sweetAlertContent, setSweetAlertContent] = useState({
        title: "",
        message: "",
        icon: "",
        confirmButtonText: "",
        isCancelButton: false,
    });
    const handleSweetAlertConfirm = () => {
        history.push("/superadmin/list-university/");
    };
    // end of sweetalert

    // alert mui message
    const [alertMessage, setAlertMessage] = useState(null); // Track the message state

    const validate = (text) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(text) === false) {
            setEmailValidation(false);
            setEmail(text);
            return false;
        } else {
            setEmail(text);
            setEmailValidation(true);
        }
    };

    const getUniversityId = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/get-university-id/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setId(data.university_id);
                } else {
                }
            })
            .catch((error) => {});
    };

    const getSingleUniversity = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/campus/university/${ids}`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setId(data?.data?.university_id);
                    setName(data?.data?.name);
                    setImageUrl(data?.data?.logo);
                    // setEmail(data?.data?.email);
                    setPhone(data?.data?.phone);
                    setSecondaryPhone(data?.data?.phone_2);
                    setAddress1(data?.data?.address);
                    setAddress2(data?.data?.address_2);
                    validate(data?.data?.email);
                    seyUniversityModal(data?.data?.university_model);
                } else {
                }
            })
            .catch((error) => {});
    };

    const handleSubmitForm = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();

        formdata.append("name", name);
        formdata.append("email", email);
        formdata.append("phone", phone);
        formdata.append("phone_2", secondaryPhone);
        formdata.append("address", address1);
        formdata.append("address_2", address2);
        formdata.append("university_model", universityModal);
        if (file !== null) {
            formdata.append("logo", file[0]);
        }

        baseConfig
            .post("/campus/university/", formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                let { StatusCode, message } = response.data;
                if (StatusCode == 6000) {
                    // history.push("/superadmin/list-university/");
                    // getUniversityId();
                    setSweetAlertContent({
                        title: "Success",
                        message: "University successfully added",
                        icon: "success",
                        confirmButtonText: "Ok",
                        isCancelButton: false,
                    });
                    setIsSweetAlert(true);
                    setName("");
                    setFile(null);
                    setEmail("");
                    setPhone("");
                    setSecondaryPhone("");
                    setAddress1("");
                    setAddress2("");
                    setFieldName("");
                    seyUniversityModal("");
                } else {
                    setAlertMessage(message);
                }
            })
            .catch((error) => {
                setAlertMessage(error.response.data.data.message);
            });
    };

    const editSubmitForm = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();

        formdata.append("name", name);
        formdata.append("email", email);
        formdata.append("phone", phone);
        formdata.append("phone_2", secondaryPhone);
        formdata.append("address", address1);
        formdata.append("address_2", address2);
        formdata.append("university_model", universityModal);
        if (file !== null) {
            formdata.append("logo", file[0]);
        }

        baseConfig
            .put(`/campus/university/${ids}/`, formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                let { StatusCode, message } = response.data;
                if (StatusCode == 6000) {
                    setSweetAlertContent({
                        title: "Success",
                        message: "University successfully edited",
                        icon: "success",
                        confirmButtonText: "Ok",
                        isCancelButton: false,
                    });
                    setIsSweetAlert(true);
                    setName("");
                    setFile(null);
                    setEmail("");
                    setPhone("");
                    setSecondaryPhone("");
                    setAddress1("");
                    setAddress2("");
                    setFieldName("");
                    seyUniversityModal("");
                } else {
                    setAlertMessage(message);
                }
            })
            .catch((error) => {
                setAlertMessage(error.response.data.data.message);
            });
    };

    useEffect(() => {
        if (ids) {
            getSingleUniversity();
        } else {
            getUniversityId();
        }
    }, [ids]);

    return (
        <Wrapper>
            <AlertBox
                message={alertMessage}
                setMessage={setAlertMessage}
                severity={
                    alertMessage && alertMessage.includes("successful")
                        ? "success"
                        : "error"
                }
                autoClose={true}
                onClose={() => setAlertMessage(null)}
            />
            <SweetAlert
                sweetAlertContent={sweetAlertContent}
                setSweetAlertContent={setSweetAlertContent}
                isSweetAlert={isSweetAlert}
                setIsSweetAlert={setIsSweetAlert}
                onConfirm={handleSweetAlertConfirm}
            />
            <Top>
                <TopLeft>
                    {ids ? (
                        <Title fontSize="35px">Edit University</Title>
                    ) : (
                        <Title fontSize="35px">Add University</Title>
                    )}
                    {/* {ids ? (
						<Text
							fontSize="20px"
							fontWeight="400"
							color="#000"
							style={{ marginTop: "10px" }}
						>
							University / Edit University
						</Text>
					) : (
						<Text
							fontSize="20px"
							fontWeight="400"
							color="#000"
							style={{ marginTop: "10px" }}
						>
							University / Add University
						</Text>
					)} */}
                </TopLeft>
                {!ids && (
                    <TopRight
                        onClick={() => {
                            getUniversityId();
                            setName("");
                            setFile(null);
                            setEmail("");
                            setPhone("");
                            setSecondaryPhone("");
                            setAddress1("");
                            setAddress2("");
                            setFieldName("");
                        }}
                    >
                        <Text
                            fontSize="16px"
                            fontWeight="400"
                            color="red"
                            style={{ marginLeft: "5px" }}
                        >
                            Clear Form
                        </Text>
                    </TopRight>
                )}
            </Top>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Container style={{ flexWrap: "wrap " }} width="100%">
                    <form id="form">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Input
                                type="text"
                                name="University ID (auto generated)"
                                value={id}
                                disabled
                            />
                            <Input
                                fieldName={fieldName}
                                onChange={(value) => {
                                    setName(value);
                                    setError(false);
                                }}
                                type="text"
                                name="University Name"
                                isError={isError}
                                errorMessage={errorMessage}
                                value={name}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Input
                                fieldName={fieldName}
                                isError={isError}
                                errorMessage={errorMessage}
                                onChange={(value) => {
                                    validate(value);
                                    setError(false);
                                }}
                                type="email"
                                name="Email Id"
                                value={email}
                            />

                            <Input
                                fieldName={fieldName}
                                isError={isError}
                                errorMessage={errorMessage}
                                onChange={(value) => {
                                    setPhone(value);
                                    setError(false);
                                }}
                                type="number"
                                name="Phone No"
                                value={phone}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Input
                                fieldName={fieldName}
                                isError={isError}
                                errorMessage={errorMessage}
                                onChange={(value) => {
                                    setSecondaryPhone(value);
                                    setError(false);
                                }}
                                type="number"
                                name="Phone No 2"
                                value={secondaryPhone}
                            />
                            <Input
                                fieldName={fieldName}
                                isError={isError}
                                errorMessage={errorMessage}
                                onChange={(value) => {
                                    setAddress1(value);
                                    setError(false);
                                }}
                                type="text"
                                name="Address line1"
                                value={address1}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Input
                                fieldName={fieldName}
                                isError={isError}
                                errorMessage={errorMessage}
                                onChange={(value) => {
                                    setAddress2(value);
                                    setError(false);
                                }}
                                type="text"
                                name="Address line2"
                                value={address2}
                            />
                            <SelectInput
                                name="University Modal"
                                type="text"
                                placeholder="University Modal"
                                onChange={(value) => {
                                    seyUniversityModal(value);
                                    setError(false);
                                }}
                                options={
                                    universityModalList
                                        ? universityModalList
                                        : []
                                }
                                selectName={"name"}
                                selectValue={"value"}
                                value={universityModal}
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                defaultValue="Select a university modal"
                            />
                            {/* <UploadInput
								file={file}
								setFile={setFile}
								isError={isError}
								fieldName={fieldName}
								name="University Logo"
								errorMessage={errorMessage}
								setError={setError}
								isEdit={file === null && ids ? true : false}
								imageUrl={imageUrl}
							/> */}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            {/* <Input
								fieldName={fieldName}
								isError={isError}
								errorMessage={errorMessage}
								onChange={(value) => {
									setAddress2(value);
									setError(false);
								}}
								type="text"
								name="Address line2"
								value={address2}
							/> */}
                            <UploadInput
                                file={file}
                                setFile={setFile}
                                isError={isError}
                                fieldName={fieldName}
                                name="University Logo"
                                errorMessage={errorMessage}
                                setError={setError}
                                isEdit={file === null && ids ? true : false}
                                imageUrl={imageUrl}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "30px",
                            }}
                        >
                            <Button
                                bacerkgroundColor="#5A2D8F"
                                fontSize="15px"
                                padding="21px 90px"
                                fontWeight="500"
                                onClick={(e) => {
                                    // if (ids) {
                                    if (name === "") {
                                        setError(true);
                                        setFieldName("University Name");
                                        setErrorMessage(
                                            "This field is required"
                                        );
                                    } else if (emailValidation === false) {
                                        setError(true);
                                        setFieldName("Email Id");
                                        setErrorMessage(
                                            "Please enter a valid email"
                                        );
                                    }
                                    // else if (phone === "") {
                                    // 	setError(true);
                                    // 	setFieldName("Phone No");
                                    // 	setErrorMessage(
                                    // 		"Enter your phone number"
                                    // 	);
                                    // }
                                    // else if (secondaryPhone === "") {
                                    // 	setError(true);
                                    // 	setFieldName("Phone No 2");
                                    // 	setErrorMessage(
                                    // 		"Enter your phone number"
                                    // 	);
                                    // }
                                    else if (address1 === "") {
                                        setError(true);
                                        setFieldName("Address line1");
                                        setErrorMessage("Enter your address");
                                    }
                                    //  else if (address2 === "") {
                                    // 	setError(true);
                                    // 	setFieldName("Address line2");
                                    // 	setErrorMessage("Enter your address");
                                    // }
                                    else if (
                                        universityModal === "" ||
                                        universityModal ===
                                            "Select a university modal"
                                    ) {
                                        setError(true);
                                        setFieldName("University Modal");
                                        setErrorMessage("University Modal");
                                    } else {
                                        setError(false);
                                        e.preventDefault();
                                        if (ids) {
                                            editSubmitForm();
                                        } else {
                                            if (file === null) {
                                                setError(true);
                                                setFieldName("University Logo");
                                                setErrorMessage(
                                                    "Add university logo"
                                                );
                                            } else {
                                                handleSubmitForm();
                                            }
                                        }
                                    }
                                    // } else {
                                    // if (name === "") {
                                    // 	setError(true);
                                    // 	setFieldName("University Name");
                                    // 	setErrorMessage(
                                    // 		"This field is required"
                                    // 	);
                                    // } else if (emailValidation === false) {
                                    // 	setError(true);
                                    // 	setFieldName("Email Id");
                                    // 	setErrorMessage(
                                    // 		"Please enter a valid email"
                                    // 	);
                                    // } else if (phone === "") {
                                    // 	setError(true);
                                    // 	setFieldName("Phone No");
                                    // 	setErrorMessage(
                                    // 		"Enter your phone number"
                                    // 	);
                                    // } else if (secondaryPhone === "") {
                                    // 	setError(true);
                                    // 	setFieldName("Phone No 2");
                                    // 	setErrorMessage(
                                    // 		"Enter your phone number"
                                    // 	);
                                    // } else if (address1 === "") {
                                    // 	setError(true);
                                    // 	setFieldName("Address line1");
                                    // 	setErrorMessage(
                                    // 		"Enter your address"
                                    // 	);
                                    // } else if (address2 === "") {
                                    // 	setError(true);
                                    // 	setFieldName("Address line2");
                                    // 	setErrorMessage(
                                    // 		"Enter your address"
                                    // 	);
                                    // } else if (file === null) {
                                    // 	setError(true);
                                    // 	setFieldName("University Logo");
                                    // 	setErrorMessage(
                                    // 		"Add university logo"
                                    // 	);
                                    // } else {
                                    // 	setError(false);
                                    // 	e.preventDefault();
                                    // 	handleSubmitForm();
                                    // }
                                    // }
                                }}
                            >
                                <Text color="white" fontSize="20px">
                                    {ids ? "Update" : "Add"}
                                </Text>
                            </Button>
                            <Button
                                backgroundColor="#F4F4F4"
                                style={{ marginLeft: "15px" }}
                                onClick={() => {
                                    history.push(
                                        "/superadmin/list-university/"
                                    );
                                }}
                            >
                                <Text color="#522e92" fontSize="20px">
                                    Cancel
                                </Text>
                            </Button>
                        </div>
                    </form>
                </Container>
            </div>
        </Wrapper>
    );
}

export default AddUniversity;

const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const TopLeft = styled.div``;
const TopRight = styled.div`
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 50px;
    border-radius: 3px;
    padding: 0 20px;
    cursor: pointer;
`;
