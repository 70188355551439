import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import profileIcon from "../../../assets/images/profile-icon-finance.svg";
import eyeIcon from "../../../assets/images/visible-icon.svg";
import editIcon from "../../../assets/images/finance-edit-icon.svg";
import defaultProfile from "../../../assets/images/profile-icon.svg";
// mui
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Box } from "@material-ui/core";
import { shadows } from "@material-ui/system";
import moment from "moment";

export default function StudentTable({ data, currentPage }) {
    const students = ["Ajmal", "Navas", "Mithun", "Aamir"];
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [selectedRemark, setSelectedRemark] = useState("");
    const [isRemarkEdit, setIsRemarkEdit] = useState(false);
    const history = useHistory();

    // mui Extra Course popover on hover
    const [courseAnchorEl, setcourseAnchorEl] = React.useState(null);
    const handlePopoverOpen = (event, courses) => {
        setcourseAnchorEl(event.currentTarget);
        setSelectedCourses(courses);
    };
    const handlePopoverClose = () => {
        setcourseAnchorEl(null);
    };
    const courseOpen = Boolean(courseAnchorEl);
    // course popover onClick states and functions
    // const [courseAnchorEl, setcourseAnchorEl] = React.useState(null);
    // const handleCoursePopOverClick = (event) => {
    //     setcourseAnchorEl(event.currentTarget);
    // };
    // const handleCoursePopOverClose = () => {
    //     setcourseAnchorEl(null);
    // };
    // const courseOpen = Boolean(courseAnchorEl);
    // const coursePopOverId = courseOpen ? "simple-popover" : undefined;

    // mui Detailed remark popover on hover
    const [remarkAnchorEl, setRemarkAnchorEl] = React.useState(null);
    const handleRemarkPopoverOpen = (event, remark) => {
        setRemarkAnchorEl(event.currentTarget);
        setSelectedRemark(remark);
    };
    const handleRemarkPopoverClose = () => {
        setRemarkAnchorEl(null);
    };
    const remarkOpen = Boolean(remarkAnchorEl);

    // remarks edit popover onClick states and functions
    const [remarkEditanchorEl, setRemarkEditAnchorEl] = React.useState(null);

    const handleClickRemarkEdit = (event) => {
        setRemarkEditAnchorEl(event.currentTarget);
    };

    const handleCloseRemarkEdit = () => {
        setRemarkEditAnchorEl(null);
    };

    const openRemarkEdit = Boolean(remarkEditanchorEl);
    const remarkEditId = openRemarkEdit ? "simple-popover" : undefined;

    return (
        <MainDiv>
            <TableHeader>
                <TH width="4%">Sl.No</TH>
                <TH width="10%">Student Name</TH>
                <TH width="13%">Course</TH>
                {/* <TH>Consultant</TH> */}
                <TH width="6%">Total Fee</TH>
                <TH width="6%">Fee Paid</TH>
                <TH width="7%">Total Due</TH>
                <TH width="7%">Next F-up</TH>
                <TH width="8%">Mobile No.</TH>
                <TH width="14%">Remarks</TH>
            </TableHeader>
            {data?.map((student, index) => (
                <TR className="data-row" key={student.id}>
                    <TD width="4%">
                        {(currentPage - 1) * 20 + (index + 1)}
                        {/* {index + 1} */}
                    </TD>
                    <TPD width="10%">
                        <ProfileImage
                            src={student.image ? student.image : defaultProfile}
                            alt="image"
                        />
                        {student.name}
                    </TPD>
                    <TCD width="13%">
                        {student.courses[0]}
                        {/* {student.emi_details.total_courses > 1 && (
							<ExtraCourse
								
								onMouseEnter={(e) => {
									handlePopoverOpen(
										e,
										student.emi_courses.course_data
									);
								}}
								onMouseLeave={handlePopoverClose}
							>
								(+{student.emi_courses.total_courses - 1})
							</ExtraCourse>
						)} */}

                        <Popover
                            id="mouse-over-popover"
                            open={courseOpen}
                            anchorEl={courseAnchorEl}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            sx={{
                                pointerEvents: "none",

                                "&& .MuiPopover-paper": {
                                    boxShadow:
                                        "rgba(0, 0, 0, 0.05) 0px 3px 8px",
                                },
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            <div>
                                {selectedCourses.map((item, index) => (
                                    <DetailLine key={index}>
                                        <DetailText>
                                            {item.course_short}
                                        </DetailText>
                                    </DetailLine>
                                ))}
                            </div>
                        </Popover>
                    </TCD>

                    {/* <TCD width="7%">
                        MBA
                        <ExtraCourse
                        // aria-describedby={coursePopOverId}
                        // variant="contained"
                        // onClick={handleCoursePopOverClick}
                        >
                            (+1)
                        </ExtraCourse>
                        <CourseVisibleIcon
                            src={eyeIcon}
                            alt="icon"
                            aria-describedby={coursePopOverId}
                            variant="contained"
                            onClick={handleCoursePopOverClick}
                        />
                        <Popover
                            id={coursePopOverId}
                            open={courseOpen}
                            anchorEl={courseAnchorEl}
                            onClose={handleCoursePopOverClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <AllCourseDiv>
                                <DetailLine>
                                    <DetailText>MBA</DetailText>
                                    <VisibleIcon src={eyeIcon} alt="icon" />
                                </DetailLine>
                                <DetailLine>
                                    <DetailText>MBA</DetailText>
                                    <VisibleIcon src={eyeIcon} alt="icon" />
                                </DetailLine>
                            </AllCourseDiv>
                        </Popover>
                    </TCD> */}
                    {/* <TD>Marilyn Mango</TD> */}
                    <TD width="6%">{student.emi_details.total_course_fees}</TD>
                    <TD width="6%">{student.emi_details.total_fee_paid}</TD>
                    <TD width="7%">{student.emi_details.total_due_amount}</TD>
                    <TD width="7%">
                        {student.next_follow_up
                            ? moment(student.next_follow_up).format(
                                  "DD MMM YYYY"
                              )
                            : "-- --- ----"}
                    </TD>
                    <TD width="8%">{student.phone}</TD>
                    <TRD width="14%">
                        <TD width="calc (100% - 22px)">
                            <RemarkText
                                // onMouseEnter={handleRemarkPopoverOpen}
                                onMouseEnter={(e) => {
                                    {
                                        student.remark &&
                                            handleRemarkPopoverOpen(
                                                e,
                                                student.remark
                                            );
                                    }
                                }}
                                onMouseLeave={handleRemarkPopoverClose}
                            >
                                {student.remark ? student.remark : "---"}
                            </RemarkText>
                        </TD>
                        {/* <EditIcon
                            src={editIcon}
                            alt="icon"
                            aria-describedby={remarkEditId}
                            variant="contained"
                            onClick={handleClickRemarkEdit}
                        /> */}
                        <VisibleIcon
                            src={eyeIcon}
                            alt="icon"
                            onClick={() => {
                                history.push(
                                    `/finance-executive/student/${student.id}`
                                );
                            }}
                        />
                        {/* <Popover
                            id={remarkEditId}
                            open={openRemarkEdit}
                            anchorEl={remarkEditanchorEl}
                            onClose={handleCloseRemarkEdit}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <DetailedRemarkInputDiv>
                                <TopLine>
                                    <RemarkTitle> Edit Remark</RemarkTitle>
                                </TopLine>
                                <label class="textarea-label">
                                    <TextArea placeholder="Enter remarks "></TextArea>
                                </label>
                                <SubmitButton>Submit</SubmitButton>
                            </DetailedRemarkInputDiv>
                        </Popover> */}
                        <Popover
                            id="mouse-over-remark-popover"
                            open={remarkOpen}
                            anchorEl={remarkAnchorEl}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            // anchorReference="anchorPosition"
                            // anchorPosition={{
                            //     top: 0,
                            //     left: 300
                            // }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            sx={{
                                pointerEvents: "none",
                                "&& .MuiPopover-paper": {
                                    boxShadow:
                                        "rgba(0, 0, 0, 0.05) 0px 3px 8px",
                                    // boxShadow: "none",
                                },
                            }}
                            onClose={handleRemarkPopoverClose}
                            disableRestoreFocus
                        >
                            <DetailedRemarkDiv>
                                <DetailedRemarkText>
                                    {selectedRemark}
                                </DetailedRemarkText>
                            </DetailedRemarkDiv>
                        </Popover>
                    </TRD>
                </TR>
            ))}
        </MainDiv>
    );
}

const MainDiv = styled.div`
    background: #ffffff;
    /* dark/grey 300 */

    border: 1px solid #d7d7d7;
    border-radius: 12px;
    height: calc(100vh - 250px);
    overflow-y: scroll;
`;
const TableHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 8px;
    position: sticky;
    top: 0;
    background: var( --secondary-background);
`;
const TH = styled.div`
    width: ${(props) => (props.width ? props.width : "10%")};
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: #9c9c9c;
    margin-right: 2px;
    &:last-child {
        margin-right: 0;
    }
`;
const TR = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 8px;
    min-height: 70px;
    /* &:nth-child(even) {
        background: #fbfbfb;
    } */
`;
const TD = styled.div`
    width: ${(props) => (props.width ? props.width : "10%")};
    margin-right: 2px;
    &:last-child {
        margin-right: 0;
    }
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /* dark/black */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #000000;
`;
const TPD = styled.div`
    width: ${(props) => (props.width ? props.width : "10%")};

    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /* dark/black */

    color: #000000;
`;
const ProfileImage = styled.img`
    display: block;
    object-fit: cover;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 6px;
`;
const ExtraCourse = styled.div`
    cursor: pointer;
    margin-left: 2px;
    color: #9c9c9c;
    font-size: 16px;
`;
const TCD = styled.div`
    width: ${(props) => (props.width ? props.width : "10%")};
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
`;
const TRD = styled.div`
    width: ${(props) => (props.width ? props.width : "10%")};
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
`;
const VisibleIcon = styled.img`
    width: 18px;
    display: block;
    object-fit: cover;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 6px;
`;
const CourseVisibleIcon = styled.img`
    width: 18px;
    display: block;
    object-fit: cover;
    cursor: pointer;
    margin-left: 6px;
`;
const AllCourseDiv = styled.div``;
const DetailLine = styled.div`
    padding: 6px 10px;
    min-width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0px;
    }
`;
const DetailText = styled.span`
    font-weight: 400;
    font-size: 14px;
    /* line-height: 17px; */
    text-align: right;

    color: #000000;
`;
const DetailedRemarkDiv = styled.div`
    max-width: 150px;
    padding: 6px;
`;
const DetailedRemarkInputDiv = styled.div`
    width: 300px;
    padding: 6px;

    .textarea-label {
        display: block;
        border: 1px solid #d8d8d8;
        padding: 4px;
        /* border, padding, and other styles go here,
  don't set the height, and don't use floats or positioning */
    }
`;
const TopLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 10px; */
`;
const RemarkTitle = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 6px;

    color: #9c9c9c;
`;
const EditIcon = styled.img`
    width: 16px;
    object-fit: cover;
    display: block;
    margin-left: 2px;
    cursor: pointer;
`;
const DetailedRemarkText = styled.p`
    /* max-width: 240px; */
    padding: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    box-sizing: border-box;
    /* or 150% */

    /* dark/black */

    color: #000000;
    /* margin-top: 10px; */
`;
const RemarkText = styled.div`
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /* dark/black */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #000000;
`;
const TextArea = styled.textarea`
    /* padding: 10px; */
    /* border: none; */
    resize: vertical;
    /* height: 100%; */
    min-height: 50px;
    max-height: 100px;
    margin-right: -6px;
    border: 0 none;
    margin: 0;
    outline: 0 none;
    padding: 0;
    width: 100%;
`;
const SubmitButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 12px;
    padding: 4px;
    background: #917afd;
    width: 50px;
    box-sizing: border-box;
    margin-top: 6px;
`;
// const MainDiv = styled.div``;
// const MainDiv = styled.div``;
// const MainDiv = styled.div``;
// const MainDiv = styled.div``;
