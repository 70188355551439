import Title from "../../components/Title";
import React, { useState, useContext, useEffect } from "react";
import add from "../../assets/images/add.svg";
import Wrapper from "../../components/Wrapper";
import Text from "../../components/Text";
import Button from "../../components/Button";
import filter from "../../assets/images/filter.svg";
import Table from "../../components/Table";
import TD from "../../components/TD";
import Line from "../../assets/images/Line-white.svg";
// import Pagination from "../../components/pagination/pagination";
import unassignedListData from "../../components/leadmanager/unassignedListData";
import UnassignedListItem from "../../components/leadmanager/unassignedListItem";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import styled from "styled-components";
import FilterBox from "../../components/includes/FilterBox";
import AssignModal from "./AssignModal";

import { useHistory, Link } from "react-router-dom";
import MainPagination from "../../components/includes/MainPagination";
import NoDataFound from "../../components/noRecords/NoDataFound";

function UnAssigned() {
    const [count, setCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const { state } = useContext(Context);
    const [isLoading, setLoading] = useState(true);
    const [isFilterBox, setFilterBox] = useState(false);
    const [universityList, setUniversityList] = useState();
    const [sortValue, setSortValue] = useState();
    const [universityValues, setUniversityValues] = useState();
    const history = useHistory();
    const [salesPerson, setSalesPerson] = useState([]);
    const [option, setOption] = useState("0");
    const [isCheck, setIsCheck] = useState([]);
    const [selectedLead, setSlectedLead] = useState({});
    const [paginationData, setPaginationData] = useState({});

    // Get current posts
    // const indexOfLastPost = currentPage * count;
    // const indexOfFirstPost = indexOfLastPost - count;
    // const currentPosts = unassignedListData.slice(
    // 	indexOfFirstPost,
    // 	indexOfLastPost
    // );

    const [isAssignModal, setAssignModal] = useState(false);

    const getAssignedLeads = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/leads/lead/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    assigned: false,
                    ordering: sortValue,
                    university: universityValues,
                    page: currentPage,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setData(data.data);
                    setPaginationData(data.paginator);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };

    const getUniversity = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/campus/university/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setUniversityList(data.data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const getSalesPerson = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/sales-persons/sales-persons-list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    choice: "assign",
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setSalesPerson(data.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };

    const assignLead = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .post(
                "/leads/assign-sales-person/",
                {
                    sales_person: option,
                    leads: isCheck,
                },
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                }
            )
            .then((response) => {
                let { StatusCode, message, data } = response.data;
                if (StatusCode == 6000) {
                    getSalesPerson();
                    getAssignedLeads();
                    setOption("0");
                    setAssignModal(false);
                    setIsCheck([]);
                } else {
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    useEffect(() => {
        getAssignedLeads();
        getUniversity();
        getSalesPerson();
    }, [currentPage]);

    return (
        <>
            <Wrapper>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "30px",
                    }}
                >
                    <div>
                        <Title fontSize="35px">Unassigned Leads</Title>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            onClick={() => {
                                history.push("/salesmanager/totalleads/");
                            }}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                width: "auto",
                                marginRight: "10px",
                            }}
                            backgroundColor="#5A2D8F"
                            fontSize="15px"
                        >
                            <Text color="#fff" fontSize="15px">
                                Assign Leads to Admission Consultant
                            </Text>
                            <img style={{ marginLeft: "12px" }} src={Line} alt="Logo" />
                        </Button>
                        {/* <Text
							style={{
								marginTop: "10px",
								margin: "auto 30px",
							}}
							fontSize="15px"
						>
							Show
						</Text>
						<div className="number">
							<Text
								fontSize="18px"
								fontWeight="400"
								color="#522E92"
							>
								{count}
							</Text>
							<div>
								<img
									onClick={() => {
										setCount(count + 1);
									}}
									src={add}
									alt="incerement"
								/>
								<img
									onClick={() => {
										if (count > 1) {
											setCount(count - 1);
										}
									}}
									src={add}
									alt="decrement"
									className="decrement"
								/>
							</div>
						</div>{" "}
						<Text
							style={{
								margin: "auto 30px",
							}}
							fontSize="15px"
						>
							Results
						</Text> */}
                        <Button
                            onClick={() => {
                                setFilterBox(true);
                            }}
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                            bacerkgroundColor="#5A2D8F"
                            fontSize="15px"
                        >
                            <img style={{ width: "20px" }} src={filter} alt="Logo" />
                            <Text style={{ marginLeft: "12px" }} color="white" fontSize="15px">
                                Filter
                            </Text>
                        </Button>
                    </div>
                </div>

                <div style={{ marginBottom: "30px" }}>
                    <Table margin="0" borderRadius="30px" padding="30px 0">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                borderBottom: "1px solid #E6EFF5",
                                padding: "0 30px 20px",
                            }}
                            className="table-head"
                        >
                            <TD width="10%" color="#808080" fontWeight="500" fontSize="18px">
                                ID No.
                            </TD>
                            <TD width="15%" color="#808080" fontWeight="500" fontSize="18px">
                                Lead Name{" "}
                            </TD>
                            <TD width="20%" color="#808080" fontWeight="500" fontSize="18px">
                                Email
                            </TD>
                            <TD width="15%" color="#808080" fontWeight="500" fontSize="18px">
                                Phone
                            </TD>
                            <TD width="15%" color="#808080" fontWeight="500" fontSize="18px">
                                Source
                            </TD>
                            <TD width="15%" color="#808080" fontWeight="500" fontSize="18px">
                                Lead Age
                            </TD>

                            <TD width="10%" color="#808080" fontWeight="500" fontSize="18px">
                                Assign To
                            </TD>
                            {/* <div style={{ width: "5%" }} /> */}
                        </div>
                        {isLoading === false ? (
                            data.length > 0 ? (
                                <div style={{ padding: "0 30px" }} className="table-body">
                                    {data.map((item) => {
                                        return (
                                            <UnassignedListItem
                                                key={item.id}
                                                setAssignModal={setAssignModal}
                                                setIsCheck={setIsCheck}
                                                item={item}
                                                setSlectedLead={setSlectedLead}
                                            />
                                        );
                                    })}
                                </div>
                            ) : (
                                <NoDataFound />
                            )
                        ) : (
                            <Div>
                                <LoadingGif
                                    src={require("./../../assets/images/loading-buffering.gif").default}
                                    alt="Loading"
                                />
                            </Div>
                        )}
                    </Table>

                    {isFilterBox === true && (
                        <FilterBox
                            isFilterBox={isFilterBox}
                            setFilterBox={setFilterBox}
                            universityList={universityList}
                            setSortValue={setSortValue}
                            sortValue={sortValue}
                            getAssignedLeads={getAssignedLeads}
                            universityValues={universityValues}
                            setUniversityValues={setUniversityValues}
                        />
                    )}
                </div>
                <MainPagination
                    paginationData={paginationData}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                />
                {isAssignModal && (
                    <AssignModal
                        isAssignModal={isAssignModal}
                        setAssignModal={setAssignModal}
                        salesPerson={salesPerson}
                        setOption={setOption}
                        assignLead={assignLead}
                        option={option}
                        selectedLead={selectedLead}
                    />
                )}
            </Wrapper>
        </>
    );
}

export default UnAssigned;

const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
`;
const LoadingGif = styled.img`
    display: inline-block;
    width: 50px;
    @media (max-width: 980px) {
        width: 90px;
    }
`;
