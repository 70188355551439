import React from "react";
import styled from "styled-components";
import activeIcon from "../../../assets/images/active-icon.svg";
import onholdIcon from "../../../assets/images/onhold-icon.svg";
import dropoutIcon from "../../../assets/images/dropout-icon.svg";
import BarChartComponent from "./BarChartComponent";

export default function StudentCard({ type, data, barChartData }) {
    // const data = [
    //     { name: "SSM", value: 400 },
    //     { name: "Singhania", value: 300 },
    //     { name: "OTHM", value: 300 },
    //     // { name: "Water", value: 200 },
    // ];
    const colors = ["#E27900", "#ED9F45", "#F5C68F", "#C49E72"];
    return (
        <MainContainer>
            <TotalBox>
                <TotalLeft>
                    <TitleText>{type} Students</TitleText>
                    <AmountText>{data?.total}</AmountText>
                </TotalLeft>
                {/* <TotalRight>
                    {type && (
                        <CardIcon
                            src={
                                type === "active"
                                    ? activeIcon
                                    : type === "completed"
                                    ? onholdIcon
                                    : type === "dropout"
                                    ? dropoutIcon
                                    : null
                            }
                        />
                    )}
                </TotalRight> */}
            </TotalBox>
            <GraphContainer>
                {/* <GraphLeft>
                    <BarChartComponent data={barChartData} colors={colors} />
                </GraphLeft> */}
                <GraphRight>
                    {data?.data?.map((item, index) => (
                        <DetailLine key={index}>
                            <DetailLeft>
                                {/* <Round bg={colors[index]}></Round> */}
                                <GreyText>{item.university}</GreyText>
                            </DetailLeft>
                            <DetailRight>{item.students}</DetailRight>
                        </DetailLine>
                    ))}
                </GraphRight>
            </GraphContainer>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    background: #ffffff;
    border: 1px solid #c8ecf1;
    border-radius: 16px;
    padding: 15px;
`;
const TotalBox = styled.div`
    /* background: #eff3ff; */
    background: #ffead2;

    border: 1px solid #ffffff;
    box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 1px solid #ffffff;
    box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;
const TotalLeft = styled.div``;
const TitleText = styled.h6`
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    text-transform: uppercase;
    color: #404040;
    margin-bottom: 4px;
`;
const AmountText = styled.p`
    font-weight: 600;
    font-size: 24px;
    line-height: 39px;
    /* identical to box height */

    color: #000000;
`;
const TotalRight = styled.div``;
const CardIcon = styled.img`
    width: 58px;
    object-fit: cover;
`;
const GraphContainer = styled.div`
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
    /* grid-gap: 10px; */
`;
const GraphLeft = styled.div`
    padding-right: 10%;
`;
const GraphRight = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px 0%;
    margin-left: 10px;
`;
const DetailLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
const DetailLeft = styled.div`
    display: flex;
    align-items: center;
`;
const Round = styled.div`
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 8px;
    background: ${(props) => (props.bg ? props.bg : null)};
`;
const GreyText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height, or 119% */
    margin-bottom: 8px;
    /* Dark/400 */

    color: #404040;
`;
const DetailRight = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: right;

    color: #000000;
`;
// const DetailLine = styled.div``;
// const DetailLine = styled.div``;
// const DetailLine = styled.div``;
