import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Text from "../../components/Text";
import { Bar, Pie } from "react-chartjs-2";
import React, { useEffect, useState, useContext } from "react";
import SocialMedia from "./socialMedia/socialMedia";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";

import Facebook from "../../assets/images/facebook.svg";
import Instagram from "../../assets/images/instagram.svg";
import Glob from "../../assets/images/glob.svg";
import Education from "../../assets/images/Education.svg";
import Friends from "../../assets/images/friends.svg";
import addSource from "../../assets/images/add-source.svg";
import styled from "styled-components";
import LeadDashboard from "./LeadDashboard";
import PerformanceDashboard from "./PerformanceDashboard";
import Welcome from "../../components/includes/Welcome";
import WelcomeMessage from "../../components/includes/WelcomeMessage";

function MainDashboard() {
    const [monthType, setMonthType] = useState(true);
    const [selectedDashboard, setSelectedDashboard] = useState("Lead Dashboard");
    const { state } = useContext(Context);

    const [data, setData] = useState({});

    const [isLoading, setLoading] = useState(true);

    const getSalesPerson = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/general/sales-person-dashboard/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setLoading(false);
                    setData(data);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        getSalesPerson();
    }, []);

    const dataBar = (canvas) => {
        const ctx = canvas.getContext("2d");
        const g = ctx.createLinearGradient(0, 0, 0, 400);
        g.addColorStop(1, "rgba(71,10,245,0)");
        g.addColorStop(0, "rgba(71,10,245,1)");
        return {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets: [
                {
                    label: "# of Reports",
                    data: [250, 250, 250, 600, 850, 250, 250, 250, 600, 700],
                    backgroundColor: g,
                    border: "none",
                    width: "100px",
                },
            ],
        };
    };
    const yearData = (canvas) => {
        const ctx = canvas.getContext("2d");
        const g = ctx.createLinearGradient(0, 0, 0, 400);
        g.addColorStop(1, "rgba(71,10,245,0)");
        g.addColorStop(0, "rgba(71,10,245,1)");
        return {
            labels: ["2016", "2017", "2018", "2019", "2020", "2021"],
            datasets: [
                {
                    label: "# of Reports",
                    data: [250, 250, 250, 600, 850, 250],
                    backgroundColor: g,
                    border: "none",
                    width: "100px",
                },
            ],
        };
    };
    const pieData = (canvas) => {
        const ctx = canvas.getContext("2d");
        const g1 = ctx.createLinearGradient(0, 0, 0, 400);
        g1.addColorStop(1, "rgba(199, 206, 255, 1)");
        g1.addColorStop(0, "rgba(199, 206, 255, 0)");
        const g2 = ctx.createLinearGradient(0, 0, 0, 400);
        g2.addColorStop(1, "rgba(90, 106, 207, 1)");
        g2.addColorStop(0, "rgba(90, 106, 207, 0)");
        return {
            datasets: [
                {
                    label: "# of Contribution",
                    data: [65, 35],
                    backgroundColor: [g1, g2],
                    border: "none",
                },
            ],
        };
    };
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
        },
        scales: {
            y: {
                ticks: {
                    min: 200,
                    stepSize: 200,
                    max: 1000,
                    showLabelBackdrop: false,
                    beginAtZero: false,
                },
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
    };
    return (
        <Wrapper>
            <TopContainer>
                <div>
                    {/* <TitleWrapper>
                        <Welcome />
                        <Title
                            fontSize="32px"
                            fontWeight="500"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                // marginBottom: "20px",
                                marginLeft: "6px",
                            }}
                        >
                            {data?.admin_name}
                        </Title>
                    </TitleWrapper>
                    <WelcomeMessage /> */}
                </div>
                {/* <Title fontSize="35px">Dashboard</Title> */}

                <DashboardButtonDiv>
                    <DashboardButton
                        background={selectedDashboard === "Lead Dashboard" ? "#522E92" : "#fff"}
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                            setSelectedDashboard("Lead Dashboard");
                        }}
                    >
                        <ButtonName color={selectedDashboard === "Lead Dashboard" ? "#fff" : "#000"}>
                            Lead Dashboard
                        </ButtonName>
                    </DashboardButton>
                    <DashboardButton
                        background={selectedDashboard === "Performance Dashboard" ? "#522E92" : "#fff"}
                        onClick={() => {
                            setSelectedDashboard("Performance Dashboard");
                        }}
                    >
                        <ButtonName color={selectedDashboard === "Performance Dashboard" ? "#fff" : "#000"}>
                            Performance Dashboard
                        </ButtonName>
                    </DashboardButton>
                </DashboardButtonDiv>
            </TopContainer>
            {selectedDashboard === "Lead Dashboard" ? (
                <LeadDashboard data={data} isLoading={isLoading} />
            ) : (
                <PerformanceDashboard />
            )}
        </Wrapper>
    );
}

export default MainDashboard;

const TopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const DashboardButtonDiv = styled.div`
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 5px;
    border-radius: 12px;
    justify-content: space-between;
`;
const DashboardButton = styled.div`
    background-color: ${(props) => (props.background ? props.background : "#fff")};
    padding: 10px;
    border-radius: 12px;
    cursor: pointer;
`;
const ButtonName = styled.h3`
    color: ${(props) => (props.color ? props.color : "#000")};
    font-size: 15px;
    font-weight: 500;
    padding: 0 10px;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
`;
