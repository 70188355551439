import React, { useContext, useRef, useState } from "react";
import Input from "../components/Input/input";
import Container from "../components/Container";
import Title from "../components/Title";
// import Button from "../components/Button";
import Image from "../components/Image";
import loginImage from "../assets/images/login.png";
import { Login } from "../service/auth";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Context } from "./../contexts/Store";
import openEye from "../assets/images/eye-open.svg";
import closedeye from "../assets/images/eye-close.svg";
// import ReCAPTCHA from "react-google-recaptcha";

function LoginPage() {
    const history = useHistory();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { dispatch } = useContext(Context);
    // const [passwordShown,setPasswordShown] = useState(false);

    // ReCaptcha
    // const [recaptchaValue, setRecaptchaValue] = useState(null);
    // const recaptchaRef = React.createRef();
    // const siteKey = "6Ld-w3QoAAAAAPm5vBYibhCLNOYrCoTsA3qFJDu5";

    const togglePassword = () => {
        setPasswordVisible(!passwordVisible);
    };

    // const handleRecaptchaChange = (value) => {
    //     setRecaptchaValue(value);
    //     console.log("reCAPTCHA Value:", value);
    // };

    const handleSubmit = async () => {
        // if (recaptchaValue) {
        //     // const token = await recaptchaRef.current.executeAsync();
        //     const response = await Login({
        //         username,
        //         password,
        //         // token,
        //         // recaptchaValue,
        //     });
        // }
        const response = await Login({ username, password });

        if (response.userrole) {
            if (response.userrole == "superuser") {
                history.push("/superadmin/");
            }
            if (response.userrole == "campus_admin") {
                history.push("/campus-admin/");
            }
            if (response.userrole == "lead_manager") {
                history.push("/salesmanager/");
            }
            if (response.userrole == "lead_generator") {
                history.push("/leadgenerator/");
            }
            if (response.userrole == "sales_person") {
                history.push("/admission-consultant/");
            }
            if (response.userrole == "sales_team_leader") {
                history.push("/team-leader/");
            }
            if (response.userrole == "finance_executive") {
                history.push("/finance-executive/");
            }
            if (response.userrole == "finance_manager") {
                history.push("/finance-manager/");
            }

            const user_details = {
                is_verified: true,
                access_token: response.access,
                role: response.userrole,
            };
            if (
                response.userrole == "superuser" ||
                response.userrole == "campus_admin" ||
                response.userrole == "lead_manager" ||
                response.userrole == "lead_generator" ||
                response.userrole == "sales_person" ||
                response.userrole == "sales_team_leader" ||
                response.userrole == "finance_executive" ||
                response.userrole == "finance_manager"
            ) {
                dispatch({
                    type: "UPDATE_USER_DETAILS",
                    user_details,
                });
            }
            // alert(
            // 	"Username and Password does not belongs to this dashboard"
            // );
            else setError(true);
            setErrorMessage(
                "Username and Password does not belongs to this dashboard"
            );
        } else {
            setError(true);
            setErrorMessage(response.detail);
        }

        // recaptchaRef.current.reset();
        // localStorage.setItem("token", JSON.stringify(response));
    };
    const handleEnter = (event) => {
        if (event.key.toLowerCase() === "enter") {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    };
    return (
        // <div
        // 	style={{
        // 		display: "flex",
        // 		justifyContent: "space-around",
        // 		overflow: "hidden",
        // 	}}
        // >
        // 	<div style={{ width: "50vw" }}>
        // 		<Image
        // 			width="100%"
        // 			height={"80%"}
        // 			src={loginImage}
        // 			alt="edit"
        // 		/>
        // 	</div>
        // 	<Container
        // 		style={{
        // 			width: "50vw",
        // 			margin: 30,
        // 			display: "flex",
        // 			flexDirection: "column",
        // 			padding: 100,
        // 		}}
        // 	>
        // 		<Title
        // 			style={{
        // 				marginTop: 30,
        // 				width: "100%",
        // 				alignSelf: "center",
        // 				color: "black",
        // 				textAlign: "center",
        // 				fontSize: "28px",
        // 			}}
        // 		>
        // 			LMS ADMIN LOGIN
        // 		</Title>

        // 		<Title style={{ marginTop: 30, width: "100%" }}>
        // 			Email Address
        // 		</Title>

        // 		<Div>
        // 			<Email
        // 				type="text"
        // 				onChange={(value) => {
        // 					setUsername(value.target.value);

        // 					setError(false);
        // 				}}
        // 			/>
        // 		</Div>

        // 		<Title style={{ marginTop: 30, width: "100%" }}>Password</Title>
        // 		<Div>
        // 			<Password
        // 				type={passwordVisible ? "password" : "text"}
        // 				onChange={(value) => {
        // 					setPassword(value.target.value);
        // 					setError(false);
        // 				}}
        // 			/>
        // 			<Eye
        // 				src={
        // 					passwordVisible
        // 						? require("./../assets/images/eye-close.svg")
        // 								.default
        // 						: require("./../assets/images/eye-open.svg")
        // 								.default
        // 				}
        // 				onClick={() => {
        // 					togglePassword();
        // 				}}
        // 			/>
        // 		</Div>
        // 		{isError && <Text>{errorMessage}</Text>}
        // 		<Button
        // 			style={{ marginTop: 40, cursor: "pointer", width: "100%" }}
        // 			onClick={() => {
        // 				if (username === "") {
        // 					setError(true);
        // 					setErrorMessage("Enter username");
        // 				} else if (password === "") {
        // 					setError(true);
        // 					setErrorMessage("Enter password");
        // 				} else {
        // 					handleSubmit();
        // 				}
        // 			}}
        // 		>
        // 			Sign In
        // 		</Button>
        // 	</Container>
        // </div>
        <>
            <MainContainer>
                <Oval src={require("./../assets/images/Oval.svg").default} />
                <ArrowTop
                    src={require("./../assets/images/Shape.svg").default}
                />
                <ArrowBottom
                    src={require("./../assets/images/Shape.svg").default}
                />
                <FormDiv>
                    <SubDiv>
                        <LogoDiv>
                            <Logo
                                src={
                                    require("./../assets/images/logo.png")
                                        .default
                                }
                            />
                        </LogoDiv>
                        <LoginForm>
                            <InputField>
                                <Heading>Username</Heading>
                            </InputField>
                            <UserName
                                onChange={(value) => {
                                    setUsername(value.target.value);
                                    setError(false);
                                }}
                                onKeyDown={handleEnter}
                                autoFocus
                            />
                        </LoginForm>
                        <LoginForm>
                            <InputField style={{ marginTop: "20px" }}>
                                <Heading>Password</Heading>
                            </InputField>
                            <PasswordInputDiv>
                                <UserNName
                                    type={passwordVisible ? "text" : "password"}
                                    onChange={(value) => {
                                        setPassword(value.target.value);
                                        setError(false);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key.toLowerCase() === "enter") {
                                            e.preventDefault();
                                            if (username === "" && password === "") {
                                                setError(true);
                                                setErrorMessage(
                                                    "Enter username and password"
                                                );
                                            }
                                            else if (username === "") {
                                                setError(true);
                                                setErrorMessage(
                                                    "Enter username"
                                                );
                                            } else if (password === "") {
                                                setError(true);
                                                setErrorMessage(
                                                    "Enter password"
                                                );
                                            } else {
                                                handleSubmit();
                                            }
                                        }
                                    }}
                                />
                                <EyeImage
                                    onClick={() => {
                                        togglePassword();
                                    }}
                                    src={passwordVisible ? openEye : closedeye}
                                />
                            </PasswordInputDiv>
                        </LoginForm>
                        {/* <ReCAPTCHA
                            ref={recaptchaRef}
                            //This ref can be used to call captcha related functions in case you need.
                            sitekey="6Ld-w3QoAAAAAPm5vBYibhCLNOYrCoTsA3qFJDu5"
                            onChange={handleRecaptchaChange}
                            // size="invisible"
                            // badge="bottomleft"
                        /> */}
                        <ErrorDiv>
                            {isError && <Text>{errorMessage}</Text>}
                        </ErrorDiv>

                        <Button
                            onClick={() => {
                                if (username === "" && password === "") {
                                    setError(true);
                                    setErrorMessage("Enter username and password");
                                }
                                else if (username === "") {
                                    setError(true);
                                    setErrorMessage("Enter username");
                                } else if (password === "") {
                                    setError(true);
                                    setErrorMessage("Enter password");
                                } else {
                                    handleSubmit();
                                }
                            }}
                        >
                            Sign In
                        </Button>
                    </SubDiv>
                </FormDiv>
            </MainContainer>
        </>
    );
}

// const Div = styled.div`
// 	display: flex;
// 	align-items: center;
// 	justify-content: space-between;
// 	height: 60px;
// 	border-radius: 15px;
// 	border: 1px solid rgb(239, 239, 239);
// 	padding: 0 15px;
// 	margin-top: 10px;
// `;

// const Password = styled.input`
// 	height: 90%;
// 	border-color: #fff;
// 	width: 90%;
// 	outline: none;
// 	border: none;
// 	background-image: none;
// 	background-color: transparent;
// 	-webkit-box-shadow: none;
// 	-moz-box-shadow: none;
// 	box-shadow: none;
// 	font-family: "Inter";
// 	font-size: 15px;
// `;
// const Email = styled(Password)`
// 	width: 100%;
// `;
// const Eye = styled.img`
// 	cursor: pointer;
// `;
// const Text = styled.h4`
// 	color: red;
// 	margin-top: 5px;
// `;

const MainContainer = styled.div`
    background: #522f91;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
`;
const Oval = styled.img`
    position: absolute;
    left: 0px;
    height: 50vh;
`;
const ArrowBottom = styled.img`
    position: absolute;
    right: 0px;
    bottom: -100px;
    height: 300px;
    width: 30%;
`;
const ArrowTop = styled.img`
    position: absolute;
    right: 0px;
    bottom: 50px;
    height: 300px;
    width: 30%;
`;
const FormDiv = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
`;
const SubDiv = styled.div`
    width: 25%;
    z-index: 100;
    @media (max-width: 980px) {
        width: 30%;
    }
    @media (max-width: 780px) {
        width: 35%;
    }
`;
const LogoDiv = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
`;
const Logo = styled.img`
    height: 60px;
    width: 250px;
`;
const LoginForm = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;
const InputField = styled.div`
    margin-bottom: 8px;
`;
const Heading = styled.h4`
    color: #fff;
    font-size: 15px;
    font-family: "Inter";
    font-weight: 500;
`;
const UserName = styled.input`
    // height: 80px;
    // width: 300px;
    background: #6c4b9d;
    height: 60px;
    // width: 100%;
    outline: none;
    border: 1px solid #6c4b9d;
    border-radius: 5px;
    font-size: 15px;
    color: #fff;
    padding: 0 15px;

    &:focus {
        border: 1px solid #c2c2c2;
    }
`;
const UserNName = styled.input`
    // height: 80px;
    // width: 300px;
    background: #6c4b9d;
    height: 60px;
    // width: 100%;
    outline: none;
    border: 1px solid #6c4b9d;
    border-radius: 5px;
    font-size: 15px;
    color: #fff;
    padding: 0 15px;
    width: 100%;

    &:focus {
        border: 1px solid #c2c2c2;
    }
`;

const PasswordField = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
// const Password = styled(UserName)`
// 	// position: relative;
// `;
const PasswordInputDiv = styled.div`
    position: relative;
    width: 100%;
    display: flex; ;
`;
const EyeImage = styled.img`
    cursor: pointer;
    position: absolute;
    right: 5%;
    /* top:50%; */
    bottom: 35%;
`;
const ErrorDiv = styled.div`
    height: 30px;
    margin-top: 10px;
`;
const Button = styled.div`
    background: #fff;
    height: 60px;
    // width: 100%;
    outline: none;
    border-radius: 5px;
    font-size: 15px;
    color: #522f91;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    margin-top: 20px;
    cursor: pointer;
`;
const Text = styled.h4`
    color: #ff3333;
`;
export default LoginPage;
