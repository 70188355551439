import { Popover } from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import downArrow from "../../assets/images/select-arrow.svg";

export default function MainPagination({
    paginationData,
    setCurrentPage,
    currentPage,
    marginRight,
    isCountSelector,
    itemsCount,
    setItemsCount,
    currentItemsCount,
    setPageCount,
    pageCount,
}) {
    const itemsCounts = [10, 20, 30, 40, 50];
    const [anchorEl, setAnchorEl] = useState(null);
    const popoverStyle = {
        // position: "absolute",
        top: "-75px",

        // top: -55,
        // left: -128,
    };
    const handleDropDownClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <MainContainer>
            <LeftDiv>
                {isCountSelector &&
                    paginationData.count >= 10 &&
                    itemsCounts.filter((item) => item <= paginationData.count)
                        .length >= 1 && (
                        <>
                            <TotalCountsText style={{ marginRight: "12px" }}>
                                Showing
                            </TotalCountsText>
                            <CountSelectorContainer
                                onClick={handleDropDownClick}
                                isFocus={Boolean(anchorEl) ? true : false}
                            >
                                <ItemsCountText>
                                    {currentItemsCount <= paginationData.count
                                        ? currentItemsCount
                                        : paginationData.count}
                                    {/* {itemsCount} */}
                                </ItemsCountText>
                                <DownArrow src={downArrow} alt="icon" />
                            </CountSelectorContainer>
                            <TotalCountsText style={{ marginLeft: "12px" }}>
                                of {paginationData.count} Results
                            </TotalCountsText>
                            {itemsCounts.filter(
                                (item) => item <= paginationData.count
                            ).length > 1 && (
                                <Popover
                                    style={popoverStyle}
                                    PaperProps={{
                                        style: {
                                            backgroundColor: "transparent",
                                            // boxShadow: "none",
                                            borderRadius: "8px",
                                            background: "#fff",
                                            border: "1px solid #e7e7e7",
                                            borderRadius: "12px",
                                            boxShadow:
                                                "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
                                            // boxShadow:
                                            //     "8px 16px 44px rgba(0, 0, 0, 0.25)",
                                        },
                                    }}
                                    open={Boolean(anchorEl)}
                                    anchorEl={anchorEl}
                                    onClose={() => {
                                        setAnchorEl(null);
                                    }}
                                >
                                    <SelectionContainer>
                                        {itemsCounts.map(
                                            (item) =>
                                                item <=
                                                    paginationData?.count && (
                                                    <SelectItem
                                                        key={item}
                                                        onClick={() => {
                                                            setItemsCount(item);
                                                            // setPageCount(item);
                                                            setAnchorEl(null);
                                                        }}
                                                    >
                                                        {item}
                                                    </SelectItem>
                                                )
                                        )}
                                    </SelectionContainer>
                                </Popover>
                            )}
                        </>
                    )}
            </LeftDiv>
            <PaginationDiv marginRight={marginRight ? marginRight : "60px"}>
                {paginationData?.has_previous && (
                    <Button
                        onClick={() => {
                            if (paginationData.has_previous) {
                                setCurrentPage(
                                    paginationData?.previous_page_number
                                );
                            }
                        }}
                    >
                        <Arrow
                            src={
                                require("./../../assets/images/arrow.svg")
                                    .default
                            }
                        />
                        {/* <ButtonText
                            style={{ marginLeft: "8px", marginBottom: "0" }}
                        >
                            
                        </ButtonText> */}
                    </Button>
                )}
                {paginationData.has_previous && (
                    <Count
                        onClick={() => {
                            setCurrentPage(
                                paginationData?.previous_page_number
                            );
                        }}
                    >
                        {paginationData?.previous_page_number}
                    </Count>
                )}
                {paginationData?.has_other_pages && (
                    <Count background={"#522f91"} color={"#fff"}>
                        {currentPage}
                    </Count>
                )}
                {paginationData.has_next && (
                    <Count
                        onClick={() => {
                            setCurrentPage(paginationData?.next_page_number);
                        }}
                    >
                        {paginationData?.next_page_number}
                    </Count>
                )}
                {/* <Count>2</Count> */}
                {paginationData?.has_next && (
                    <Button
                        onClick={() => {
                            if (paginationData.has_next) {
                                setCurrentPage(paginationData.next_page_number);
                            }
                        }}
                    >
                        {/* <ButtonText
                            style={{
                                marginRight: "8px",
                                marginLeft: "10px",
                                marginBottom: "0",
                            }}
                        >
                            
                        </ButtonText> */}
                        <Arrow
                            style={{
                                transform: "rotate(180deg)",
                            }}
                            src={
                                require("./../../assets/images/arrow.svg")
                                    .default
                            }
                        />
                    </Button>
                )}
            </PaginationDiv>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
`;
const LeftDiv = styled.div`
    display: flex;
    align-items: center;
`;
const PaginationDiv = styled.div`
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    margin-right: ${(props) =>
        props.marginRight ? props.marginRight : "60px"};
`;
const Button = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #522e92;
    padding: 12px 14px;
    border-radius: 8px;
    margin-left:10px;
`;
const ButtonText = styled.h4`
    margin-bottom: -3px;
    color: #522f91;
    font-size: 16px;
`;
const Count = styled.h4`
    margin-left: 10px;
    font-size: 16px;
    background: ${(props) => (props.background ? props.background : "#f5f7fa")};
    color: ${(props) => (props.color ? props.color : "#522f91")};
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
`;
const Arrow = styled.img`
    /* margin-right: 8px; */
`;
const CountSelectorContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    /* Light/300 */
    width: 76px;
    height: 42px;
    border: ${(props) =>
        props.isFocus ? "1px solid #bcbcbc" : "1px solid #e2e2e2"};
    border-radius: 12px;
    padding: 10px 14px;
    gap: 10px;
    box-sizing: border-box;
    cursor: pointer;

    transition: 0.3s;
    &:hover {
        border: 1px solid #bcbcbc;
    }
`;
const ItemsCountText = styled.span`
    display: inline-block;
    color: #333333;
    font-size: 15px;
`;
const DownArrow = styled.img`
    width: 11px;
`;
const SelectionContainer = styled.div`
    width: 76px;
    max-height: 300px;
    overflow-y: scroll;
    background: #ffffff;
    box-sizing: border-box;
    overflow: hidden;
`;
const SelectItem = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 32px;
    width: 100%;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    border-bottom: 0.5px solid #fcfcfc;
    &:hover {
        /* background: #f5f7fa; */
        background: #fbfbfb;

        opacity: 0.9;
    }

    &:nth-child(even) {
        /* background: #fbfbfb; */
    }
`;
const TotalCountsText = styled.span`
    display: block;
    /* margin-left: 12px; */
    font-weight: 400;
    font-size: 14px;

    color: #aaaaaa;
`;
