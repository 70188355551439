import React, { useState } from "react";
import styled from "styled-components";
import {
    PieChart,
    Pie,
    Legend,
    Sector,
    Cell,
    ResponsiveContainer,
} from "recharts";

const PieChartComponent = ({ colors, data }) => {
    const RADIAN = Math.PI / 180;
    // const renderCustomizedLabel = ({
    //     cx,
    //     cy,
    //     midAngle,
    //     innerRadius,
    //     outerRadius,
    //     percent,
    //     index,
    // }) => {
    //     const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    //     const x = cx + radius * Math.cos(-midAngle * RADIAN);
    //     const y = cy + radius * Math.sin(-midAngle * RADIAN);

    //     return (
    //         <text
    //             x={x}
    //             y={y}
    //             fill="white"
    //             textAnchor={x > cx ? "start" : "end"}
    //             dominantBaseline="central"
    //         >
    //             {`${(percent * 100).toFixed(0)}%`}
    //         </text>
    //     );
    // };
    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        const perc = (percent * 100).toFixed(0);

        return perc > 0 ? (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor="middle"
                dominantBaseline="central"
                fontSize="12px"
            >
                {`${perc} %`}
            </text>
        ) : (
            <></>
        );
    };

    return (
        <MainDiv>
            <ResponsiveContainer
                width="100%"
                height={140}
                // className="text-center"
            >
                <PieChart height={140}>
                    <Pie
                        data={data}
                        cx="50%"
                        // align="left"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={60}
                        innerRadius={20}
                        fill="#8884d8"
                        dataKey="percentage"
                    >
                        {data?.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={colors[index % colors.length]}
                            />
                        ))}
                    </Pie>
                    {/* <Legend
                        layout="vertical"
                        verticalAlign="middle"
                        align="right"
                        iconType="circle"
                    /> */}
                </PieChart>
            </ResponsiveContainer>
        </MainDiv>
    );
};
export default PieChartComponent;

const MainDiv = styled.div`
    .recharts-legend-wrapper {
        min-width: 100px;
    }
    .recharts-legend-item {
        margin-bottom: 15px;
    }
    .recharts-legend-item:last-child {
        margin-bottom: 0px;
    }
    .recharts-legend-item-text {
        color: #9d9d9d !important;
    }
    .recharts-legend-item .recharts-surface {
        width: 17px !important;
        height: 17px !important;
        border-radius: 50% !important;
        margin-right: 15px !important;
        overflow: visible !important;
    }
    .recharts-legend-item .recharts-surface .recharts-legend-icon {
        width: 15px !important;
        height: 15px !important;
        border-radius: 50% !important;

        /* border-radius: 50% !important; */
        /* margin-right: 10px !important; */
        /* overflow: visible !important; */
    }
`;
