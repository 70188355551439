import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import React, { useEffect, useState, useContext } from "react";
import { Context } from "./../../contexts/Store";
import Text from "../../components/Text";
import Container from "../../components/Container";
import styled from "styled-components";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import UploadInput from "../../components/UploadInput/UploadInput";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";

function AddSubDepartment() {
	const [file, setFile] = useState(null);
	const history = useHistory();
	const { state } = useContext(Context);

	const [id, setId] = useState("");
	const [isError, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [secondaryPhone, setSecondaryPhone] = useState("");
	const [address1, setAddress1] = useState("");
	const [address2, setAddress2] = useState("");
	const [city, setCity] = useState("");
	const [states, setStates] = useState("");
	const [country, setCountry] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [fieldName, setFieldName] = useState("");
	const [emailValidation, setEmailValidation] = useState(false);

	const validate = (text) => {
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
		if (reg.test(text) === false) {
			setEmailValidation(false);
			setEmail(text);
			return false;
		} else {
			setEmail(text);
			setEmailValidation(true);
		}
	};

	return (
		<Wrapper>
			<Top>
				<TopLeft>
					<Title fontSize="35px">Add Sub-departments</Title>
					<Text
						fontSize="20px"
						fontWeight="400"
						color="#000"
						style={{ marginTop: "10px" }}
					>
						Departments / Add Subdepartments
					</Text>
				</TopLeft>
				<TopRight onClick={() => {}}>
					<Text
						fontSize="16px"
						fontWeight="400"
						color="red"
						style={{ marginLeft: "5px" }}
					>
						Clear Form
					</Text>
				</TopRight>
			</Top>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<Container
					style={{ flexWrap: "wrap " }}
					width="100%"
					margin="10px 0"
				>
					<form id="form">
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<Input
								type="text"
								name="Sub-department ID (auto generated)"
								value={id}
								disabled
							/>
							<Input
								fieldName={fieldName}
								onChange={(value) => {
									setName(value);
									setError(false);
								}}
								type="text"
								name="Sub-department Name"
								isError={isError}
								errorMessage={errorMessage}
							/>
						</div>

						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: "20px",
							}}
						>
							<Input
								fieldName={fieldName}
								isError={isError}
								errorMessage={errorMessage}
								onChange={(value) => {
									setSecondaryPhone(value);
									setError(false);
								}}
								type="text"
								name="Sub-department short name"
							/>
							<Input
								fieldName={fieldName}
								isError={isError}
								errorMessage={errorMessage}
								onChange={(value) => {
									setAddress1(value);
									setError(false);
								}}
								type="text"
								name="Department under"
							/>
						</div>

						<div
							style={{
								display: "flex",
								marginTop: "30px",
							}}
						>
							<Button
								bacerkgroundColor="#5A2D8F"
								fontSize="15px"
								padding="21px 90px"
								fontWeight="500"
								onClick={(e) => {
									if (name === "") {
										setError(true);
										setFieldName("Sub-department Name");
										setErrorMessage(
											"This field is required"
										);
									} else if (emailValidation === false) {
										setError(true);
										setFieldName("Email Id*");
										setErrorMessage(
											"Please enter a valid email"
										);
									} else if (phone === "") {
										setError(true);
										setFieldName("Phone No*");
										setErrorMessage(
											"Enter your phone number"
										);
									} else if (secondaryPhone === "") {
										setError(true);
										setFieldName(
											"Sub-department short name"
										);
										setErrorMessage(
											"Enter your phone number"
										);
									} else if (address1 === "") {
										setError(true);
										setFieldName("Department under");
										setErrorMessage("Enter your address");
									} else if (address2 === "") {
										setError(true);
										setFieldName("Address line2");
										setErrorMessage("Enter your address");
									} else if (city === "") {
										setError(true);
										setFieldName("Country");
										setErrorMessage("Enter your city");
									} else if (states === "") {
										setError(true);
										setFieldName("State");
										setErrorMessage("Enter your state");
									} else if (country === "") {
										setError(true);
										setFieldName("Country");
										setErrorMessage("Enter your country");
									} else if (zipCode === "") {
										setError(true);
										setFieldName("ZIP Code");
										setErrorMessage("Enter your ZIP Code");
									} else if (file === null) {
										setError(true);
										setFieldName("University Logo");
									} else {
										setError(false);
										e.preventDefault();
										// handleSubmitForm();
									}
								}}
							>
								<Text color="white" fontSize="20px">
									Add
								</Text>
							</Button>
							<Button
								backgroundColor="#F4F4F4"
								style={{ marginLeft: "15px" }}
								onClick={() => {
									history.push(
										"/campus-admin/all-sub-department/"
									);
								}}
							>
								<Text color="#522e92" fontSize="20px">
									Cancel
								</Text>
							</Button>
						</div>
					</form>
				</Container>
			</div>
		</Wrapper>
	);
}

export default AddSubDepartment;

const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const TopLeft = styled.div``;
const TopRight = styled.div`
	display: flex;
	align-items: center;
	background-color: #fff;
	height: 50px;
	border-radius: 3px;
	padding: 0 20px;
	cursor: pointer;
`;
