import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams, Link } from "react-router-dom";

import Wrapper from "../../components/Wrapper";
import Title from "../../components/Title";
import styled from "styled-components";
import { Context } from "../../contexts/Store";
import { baseConfig } from "../../utils/config";
import FinanceCard from "../financeExecutive/includes/FinanceCard";
import StudentCard from "../financeExecutive/includes/StudentCard";
import style from "./style.css";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import EditModal from "./includes/EditModal";
import FilterBox from "./includes/FilterBox";
import loadingIcon from "../../assets/lottie/loading.gif";
import profileIcon from "../../assets/images/profile.png";
import { Popover } from "@mui/material";
import MainPagination from "../../components/includes/MainPagination";
import searchIcon from "../../assets/images/search-exe.svg";
import downIcon from "../../assets/images/filter-down-arrow.svg";
import unSelectedRadioIcon from "../../assets/images/radio-n-s2.svg";
import selectedRadioIcon from "../../assets/images/radio-s2.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";



function FinanceExecutiveSingle() {
    const { state, dispatch } = useContext(Context);
    const { id } = useParams();
    const history = useHistory();

    const [isStudentsView, setIsStudentsView] = useState(false);
    const [studentsData, setAssignedStudentsData] = useState([]);
    const [isStudentsDataLoading, setIsStudentsDataLoading] = useState(false);
    const [paginationData, setPaginationData] = useState({});
    const [itemsCount, setItemsCount] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedProgram, setSelectedProgram] = useState("");
    const [selectedUniversity, setSelectedUniversity] = useState("");
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [selectedRemark, setSelectedRemark] = useState("");
    const [search, setSearch] = useState();
    const [universities, setUniversities] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [programFilterAnchorEl, setProgramFilterAnchorEl] = useState(null);
    const programButtonRef = useRef(null);
    const handleClickProgramFilter = () => {
        setProgramFilterAnchorEl(programButtonRef.current);
    };
    const handleCloseProgramFilter = () => {
        setProgramFilterAnchorEl(null);
    };
    const openProgramFilter = Boolean(programFilterAnchorEl);
    const programFilterPopoverStyle = {
        position: "absolute",
        top: 45,
        left: -183,
    };
    const [selectedUniversityFilterOption, setSelectedUniversityFilterOption] =
    useState("university");
    const [selectedFinanceFilter, setSelectedFinanceFilter] =
        useState("Monthly");
    const filterValues = ["Monthly", "Yearly"];
    const [isLoading, setIsLoading] = useState(false);

    const [dashboardData, setDashboardData] = useState();
    const [monthlyData, setMonthlyData] = useState();
    const [yearlyData, setYearlyData] = useState();
    // const [financeData, setFinanceData] = useState(monthlyData);
    const [editmodal, setEditModal] = useState(false);

    const [basicDetails, setBasicDetails] = useState({});
    const [executiveData, setExecutiveData] = useState({});
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const handleFinanceFilter = (event) => {
        setSelectedFinanceFilter(event.target.value);
    };

    // mui Extra Course popover on hover
    const [courseAnchorEl, setcourseAnchorEl] = React.useState(null);
    const handlePopoverOpen = (event, courses) => {
        setcourseAnchorEl(event.currentTarget);
        setSelectedCourses(courses);
    };
    const handlePopoverClose = () => {
        setcourseAnchorEl(null);
    };
    const courseOpen = Boolean(courseAnchorEl);
    // end of extra course popover

    // mui Detailed remark popover on hover
    const [remarkAnchorEl, setRemarkAnchorEl] = React.useState(null);
    const handleRemarkPopoverOpen = (event, remark) => {
        setRemarkAnchorEl(event.currentTarget);
        setSelectedRemark(remark);
    };
    const handleRemarkPopoverClose = () => {
        setRemarkAnchorEl(null);
    };
    const remarkOpen = Boolean(remarkAnchorEl);
    // end of Detailed remark course popover
    const [alertStatus, setAlertStatus] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [isAlert, setIsAlert] = useState(false);
    const handleClose = () => {
        setIsAlert(false);
        setAlertStatus("");
    };

    const getBasicDetails = () => {
        setIsLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/finance/finance-executive-details/${id}`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })

            .then((response) => {
                const { StatusCode, data } = response.data;
                // console.log("response",data);
                if (StatusCode === 6000) {
                    setBasicDetails(data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    alert("Some error occured.Please try again later");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                alert(error);
            });
    };

    const getExecutiveData = () => {
        setIsLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/finance/finance-executive-data/${id}`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    from_date: fromDate,
                    to_date: toDate,
                },
            })

            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setExecutiveData(data);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    alert("Some error occured.Please try again later");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                alert(error);
            });
    };
    const getStudentsData = async () => {
        setIsStudentsDataLoading(true);
        let accessToken = state.user_details.access_token;

        try {
            const response = await baseConfig.get(
                "/finance/assigned-students/",
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                    params: {
                        search: search,
                        university: selectedUniversity,
                        course: selectedProgram,
                        page: currentPage,
                        items: 30,
                        finance_executive:id,
                        // items: itemsCount,
                        // status: selectedOption,
                    },
                }
            );

            const { StatusCode, data } = response.data;
            if (StatusCode === 6000) {
                setAssignedStudentsData(data.data);
                setPaginationData(data.paginator);
            } else {
                alert("Some error occurred. Please try again later");
            }
        } catch (error) {
            alert(error);
        } finally {
            setIsStudentsDataLoading(false);
        }
    };

    // useEffect(() => {
    //     getDashboardData();
    // }, []);

    useEffect(() => {
        getBasicDetails();
        getExecutiveData();
    }, [fromDate, toDate]);

    useEffect(() => {
        getStudentsData();
    }, [currentPage,search]);
    const getUniversities = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/university-list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setUniversities(data);
                } else {
                    alert(
                        "Some error occured while loading categories.Please try again later"
                    );
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    const getPrograms = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/course-list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    university: selectedUniversity,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setPrograms(data);
                } else {
                    alert(
                        "Some error occured while loading categories.Please try again later"
                    );
                }
            })
            .catch((error) => {
                alert(error);
            });
    };
    useEffect(() => {
        getUniversities();
    }, []);
    useEffect(() => {
        if (selectedUniversity) {
            getPrograms();
        }
    }, [selectedUniversity])

    return (
        <>
            <Wrapper>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    {/* <Titles href="/finance-manager/executives/">
                        Finance Executive/
                    </Titles> */}
                    <GreyText to="/finance-manager/executives/">
                        Finance Executives /{" "}
                    </GreyText>
                    <Title
                        fontSize="16px"
                        color="#000"
                        style={{ marginLeft: "4px" }}
                    >
                        {basicDetails?.name}
                    </Title>
                </div>
                <MainDiv>
                    <TopDiv>
                        <LeftDiv>
                            <img
                                // src={
                                //     require("../../assets/images/profile-exe.svg")
                                //         .default
                                // }
                                src={basicDetails?.image}
                                alt="Image"
                            />
                        </LeftDiv>
                        <RightDiv>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Title fontSize="18px" color="#000">
                                    {basicDetails?.name}
                                </Title>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginTop: "10px",
                                }}
                            >
                                <TitleBig>Employee ID</TitleBig>
                                <span>:</span>
                                <TitleTexts>
                                    {basicDetails?.employee_id}
                                </TitleTexts>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginTop: "10px",
                                }}
                            >
                                <TitleBig>Email ID</TitleBig>
                                <span>:</span>
                                <TitleTexts>{basicDetails.email}</TitleTexts>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginTop: "10px",
                                }}
                            >
                                <TitleBig>Phone</TitleBig>
                                <span>:</span>
                                <TitleTexts>{basicDetails.phone}</TitleTexts>
                            </div>
                        </RightDiv>
                    </TopDiv>

                    {!isLoading ? (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    gap: "10px",
                                    padding: "10px 15px",
                                }}
                            >
                                <AssignButton
                                    onClick={() => {
                                        setIsStudentsView(!isStudentsView);
                                    }}
                                    // disabled={
                                    //     selectedStudents.length == 0 ? true : false
                                    // }
                                >
                                    {isStudentsView
                                        ? `View Finance Data`
                                        : `View Assigned Students`}
                                </AssignButton>
                            </div>
                            {!isStudentsView && (
                                <BottomDiv>
                                    <FinanceSection>
                                        <TitleBox>
                                            <TitleText>Finance</TitleText>
                                            <FilterBox
                                                setFromDate={setFromDate}
                                                setToDate={setToDate}
                                            />
                                        </TitleBox>

                                        <CardsWrapper>
                                            <FinanceCard
                                                type="receivables"
                                                data={
                                                    executiveData.outstanding_data
                                                }
                                            />
                                            <FinanceCard
                                                type="fee received"
                                                data={
                                                    executiveData.recieved_data
                                                }
                                            />
                                            <FinanceCard
                                                type="balance fee"
                                                data={
                                                    executiveData.balance_data
                                                }
                                            />
                                            <FinanceCard
                                                type="pending due"
                                                data={executiveData?.due_amount}
                                            />

                                            {/* <FinanceCard
                                        type="received"
                                        data={
                                            selectedFinanceFilter === "Monthly"
                                                ? monthlyData?.recieved_data
                                                : yearlyData?.recieved_data
                                        }
                                    /> */}
                                        </CardsWrapper>
                                    </FinanceSection>

                                    <StudentsSecttion>
                                        <TitleBox>
                                            <TitleText>Student Info</TitleText>
                                        </TitleBox>
                                        <CardsWrapper
                                            style={{
                                                gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                                            }}
                                        >
                                            <StudentCard
                                                type="active"
                                                data={executiveData?.active_students}
                                                barChartData={executiveData?.active_students?.data.slice(
                                                    0,
                                                    4
                                                )}
                                            />
                                            <StudentCard
                                                type="On Break"
                                                data={executiveData?.onbreak_students}
                                                barChartData={executiveData?.dropout_students?.data.slice(
                                                    0,
                                                    4
                                                )}
                                            />
                                            <StudentCard
                                                type="On Hold"
                                                data={executiveData?.onhold_students}
                                                barChartData={executiveData?.dropout_students?.data.slice(
                                                    0,
                                                    4
                                                )}
                                            />
                                            <StudentCard
                                                type="completed"
                                                data={executiveData?.completed_students}
                                                barChartData={executiveData?.completed_students?.data.slice(
                                                    0,
                                                    4
                                                )}
                                            />
                                            <StudentCard
                                                type="dropout"
                                                data={executiveData?.dropout_students}
                                                barChartData={executiveData?.dropout_students?.data.slice(
                                                    0,
                                                    4
                                                )}
                                            />
                                            
                                        </CardsWrapper>
                                    </StudentsSecttion>
                                </BottomDiv>
                            )}
                        </>
                    ) : (
                        <LoaderContainer>
                            <LoadingGif src={loadingIcon} alt="icon" />
                        </LoaderContainer>
                    )}

                    {/* Students table starts here */}
                    {isStudentsView &&
                        (!isStudentsDataLoading ? (
                            <>
                            <div style={{
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"space-between",
                                margin:"auto 15px",
                            }}>
                            <TitleBox>
                                <TitleText>Assigned Students</TitleText>
                            </TitleBox>
                            <SearchDiv>
                                <Form className="search-container">
                                    <SearchIcon
                                        src={searchIcon}
                                    />
                                    <SearchField
                                        className="input-box"
                                        type="text"
                                        name="search"
                                        placeholder="Search for something"
                                        onChange={(e) => setSearch(e.target.value)}
                                        value={search}
                                    />
                                </Form>
                            </SearchDiv>
                            </div>
                            <DetailedSection>
                                <TableWrapper>
                                    <Table className="table">
                                        <Thead>
                                            <THR>
                                                <TH>
                                                    <HeaderDataContainer>
                                                        <THText>Ad. No</THText>
                                                    </HeaderDataContainer>
                                                </TH>
                                                <TH>
                                                    <HeaderDataContainer>
                                                        <THText>
                                                            Student Name
                                                        </THText>
                                                    </HeaderDataContainer>
                                                </TH>
                                                <TH>
                                                    <HeaderDataContainer>
                                                        <THText>Course</THText>
                                                         <DownArrow
                                                                src={downIcon}
                                                                alt="icon"
                                                                ref={
                                                                    programButtonRef
                                                                }
                                                                onClick={
                                                                    handleClickProgramFilter
                                                                }
                                                            />
                                                            <Popover
                                                                style={
                                                                    programFilterPopoverStyle
                                                                }
                                                                open={
                                                                    openProgramFilter
                                                                }
                                                                anchorEl={
                                                                    programFilterAnchorEl
                                                                }
                                                                onClose={
                                                                    handleCloseProgramFilter
                                                                }
                                                            >
                                                                <MainBox>
                                                                    <PopoverSelectDiv>
                                                                        <PopoverSelectItem
                                                                            key={
                                                                                "university"
                                                                            }
                                                                            selected={
                                                                                selectedUniversityFilterOption ===
                                                                                "university"
                                                                            }
                                                                            onClick={() => {
                                                                                setSelectedUniversityFilterOption(
                                                                                    "university"
                                                                                );
                                                                            }}
                                                                        >
                                                                            Universities
                                                                        </PopoverSelectItem>
                                                                        <PopoverSelectItem
                                                                            key={
                                                                                "Program"
                                                                            }
                                                                            selected={
                                                                                selectedUniversityFilterOption ===
                                                                                "Program"
                                                                            }
                                                                            disabled={
                                                                                selectedUniversity !==
                                                                                ""
                                                                                    ? false
                                                                                    : true
                                                                            }
                                                                            onClick={() => {
                                                                                if (
                                                                                    selectedUniversity !==
                                                                                    ""
                                                                                ) {
                                                                                    setSelectedUniversityFilterOption(
                                                                                        "Program"
                                                                                    );
                                                                                } else {
                                                                                    setAlertStatus(
                                                                                        "error"
                                                                                    );
                                                                                    setIsAlert(
                                                                                        true
                                                                                    );
                                                                                    setAlertMessage(
                                                                                        "Select a university"
                                                                                    );
                                                                                }
                                                                            }}
                                                                        >
                                                                            Courses
                                                                        </PopoverSelectItem>
                                                                    </PopoverSelectDiv>
                                                                    {selectedUniversityFilterOption ==
                                                                        "university" && (
                                                                        <ProgramCards>
                                                                            {universities?.map(
                                                                                (
                                                                                    singleUniversity
                                                                                ) => (
                                                                                    <ProgramCard
                                                                                        key={
                                                                                            singleUniversity.id
                                                                                        }
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                selectedUniversity ==
                                                                                                singleUniversity.id
                                                                                            ) {
                                                                                                setSelectedUniversity(
                                                                                                    ""
                                                                                                ); // Deselect the current program
                                                                                            } else {
                                                                                                setSelectedUniversity(
                                                                                                    singleUniversity.id
                                                                                                ); // Select the clicked program
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <RadioButtonIcon
                                                                                            src={
                                                                                                selectedUniversity ==
                                                                                                singleUniversity.id
                                                                                                    ? selectedRadioIcon
                                                                                                    : unSelectedRadioIcon
                                                                                            }
                                                                                            alt="icon"
                                                                                        />
                                                                                        <ProgramName>
                                                                                            {
                                                                                                singleUniversity?.name
                                                                                            }
                                                                                        </ProgramName>
                                                                                    </ProgramCard>
                                                                                )
                                                                            )}
                                                                        </ProgramCards>
                                                                    )}

                                                                    {selectedUniversityFilterOption ==
                                                                        "Program" && (
                                                                        <ProgramCards>
                                                                            {programs?.map(
                                                                                (
                                                                                    singleProgram
                                                                                ) => (
                                                                                    <ProgramCard
                                                                                        key={
                                                                                            singleProgram.id
                                                                                        }
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                selectedProgram ==
                                                                                                singleProgram.id
                                                                                            ) {
                                                                                                setSelectedProgram(
                                                                                                    ""
                                                                                                ); // Deselect the current program
                                                                                            } else {
                                                                                                setSelectedProgram(
                                                                                                    singleProgram.id
                                                                                                ); // Select the clicked program
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <RadioButtonIcon
                                                                                            src={
                                                                                                selectedProgram ==
                                                                                                singleProgram.id
                                                                                                    ? selectedRadioIcon
                                                                                                    : unSelectedRadioIcon
                                                                                            }
                                                                                            alt="icon"
                                                                                        />
                                                                                        <ProgramName>
                                                                                            {
                                                                                                singleProgram?.name
                                                                                            }
                                                                                        </ProgramName>
                                                                                    </ProgramCard>
                                                                                )
                                                                            )}
                                                                        </ProgramCards>
                                                                    )}
                                                                    <ButtonsContainer>
                                                                        <CancelPopoverButton
                                                                            onClick={() => {
                                                                                handleCloseProgramFilter();
                                                                            }}
                                                                        >
                                                                            Cancel
                                                                        </CancelPopoverButton>
                                                                        <ApplyPopoverButton
                                                                            onClick={() => {
                                                                                getStudentsData();
                                                                                handleCloseProgramFilter();
                                                                            }}
                                                                        >
                                                                            Apply
                                                                        </ApplyPopoverButton>
                                                                    </ButtonsContainer>
                                                                </MainBox>
                                                            </Popover> 
                                                    </HeaderDataContainer>
                                                </TH>
                                                <TH>
                                                    <HeaderDataContainer>
                                                        <THText>
                                                            {" "}
                                                            Consultant
                                                        </THText>
                                                    </HeaderDataContainer>
                                                </TH>
                                                <TH>
                                                    <HeaderDataContainer>
                                                        <THText>
                                                            {" "}
                                                            Total Fee
                                                        </THText>
                                                    </HeaderDataContainer>
                                                </TH>
                                                <TH>
                                                    <HeaderDataContainer>
                                                        <THText>
                                                            Fee Paid
                                                        </THText>
                                                    </HeaderDataContainer>
                                                </TH>
                                                <TH>
                                                    <HeaderDataContainer>
                                                        <THText>Overdue</THText>
                                                    </HeaderDataContainer>
                                                </TH>
                                                <TH>
                                                    <HeaderDataContainer>
                                                        <THText>Balance</THText>
                                                    </HeaderDataContainer>
                                                </TH>
                                                <TH>
                                                    <HeaderDataContainer>
                                                        <THText>
                                                            Due Date
                                                        </THText>
                                                    </HeaderDataContainer>
                                                </TH>
                                                <TH>
                                                    <HeaderDataContainer>
                                                        <THText>
                                                            Followup Date
                                                        </THText>
                                                    </HeaderDataContainer>
                                                </TH>
                                                {/* <TH>
                                                <HeaderDataContainer>
                                                    <THText>Paid Date</THText>
                                                </HeaderDataContainer>
                                            </TH> */}
                                                {/* <TH>
                                        <HeaderDataContainer>
                                            <THText>Next Due Date</THText>
                                        </HeaderDataContainer>
                                    </TH> */}
                                                <TH>
                                                    <HeaderDataContainer>
                                                        <THText>
                                                            EMI Amount
                                                        </THText>
                                                    </HeaderDataContainer>
                                                </TH>
                                                {/* <TH>
                                                <HeaderDataContainer>
                                                    <THText>Executive</THText>
                                                </HeaderDataContainer>
                                            </TH> */}
                                                <TH>
                                                    <HeaderDataContainer>
                                                        <THText>Remarks</THText>
                                                    </HeaderDataContainer>
                                                </TH>
                                                {/* <TH></TH> */}
                                            </THR>
                                        </Thead>
                                        {studentsData?.length > 0 ? (
                                            <Tbody>
                                                {studentsData?.map(
                                                    (student) => (
                                                        <TDR key={student.id}>
                                                            <TD>
                                                                <BodyDataContainer>
                                                                    <TDText
                                                                     onClick={() => {
                                                                        history.push(
                                                                            `/finance-manager/assigned-student/${student.id}`
                                                                        );
                                                                    }}
                                                                     className="link-td-text"
                                                                    >
                                                                        {
                                                                            student.admission_number
                                                                        }
                                                                    </TDText>
                                                                </BodyDataContainer>
                                                            </TD>
                                                            <TD>
                                                                <BodyDataContainer>
                                                                    <ProfilePic
                                                                        src={
                                                                            student.image
                                                                                ? student.image
                                                                                : profileIcon
                                                                        }
                                                                        alt="icon"
                                                                    />
                                                                    <NameContainer>
                                                                        <TDText>
                                                                            {
                                                                                student.name
                                                                            }
                                                                        </TDText>
                                                                        {/* <TDText
                                                            style={{
                                                                marginTop:
                                                                    "4px",
                                                            }}
                                                        >
                                                            {student.phone}
                                                        </TDText> */}
                                                                    </NameContainer>
                                                                </BodyDataContainer>
                                                            </TD>
                                                            {/* <TD>
                                                <TDText>SSM</TDText>
                                            </TD> */}
                                                            <TD>
                                                                <TDText>
                                                                    {
                                                                        student
                                                                            .courses[0]
                                                                    }
                                                                    {student
                                                                        .courses
                                                                        ?.length >
                                                                        1 && (
                                                                        <ExtraCourse
                                                                            onMouseEnter={(
                                                                                e
                                                                            ) => {
                                                                                handlePopoverOpen(
                                                                                    e,
                                                                                    student.courses
                                                                                );
                                                                            }}
                                                                            onMouseLeave={
                                                                                handlePopoverClose
                                                                            }
                                                                        >
                                                                            (+
                                                                            {student
                                                                                .courses
                                                                                .length -
                                                                                1}
                                                                            )
                                                                        </ExtraCourse>
                                                                    )}

                                                                    <Popover
                                                                        id="mouse-over-popover"
                                                                        // sx={{
                                                                        //     pointerEvents: "none",
                                                                        // }}
                                                                        open={
                                                                            courseOpen
                                                                        }
                                                                        anchorEl={
                                                                            courseAnchorEl
                                                                        }
                                                                        anchorOrigin={{
                                                                            vertical:
                                                                                "bottom",
                                                                            horizontal:
                                                                                "left",
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical:
                                                                                "top",
                                                                            horizontal:
                                                                                "left",
                                                                        }}
                                                                        sx={{
                                                                            pointerEvents:
                                                                                "none",

                                                                            "&& .MuiPopover-paper":
                                                                                {
                                                                                    boxShadow:
                                                                                        "rgba(0, 0, 0, 0.03) 0px 2px 4px",
                                                                                    // boxShadow:
                                                                                    //     "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                                                                                    // boxShadow:
                                                                                    //     "none",
                                                                                },
                                                                        }}
                                                                        onClose={
                                                                            handlePopoverClose
                                                                        }
                                                                        disableRestoreFocus
                                                                    >
                                                                        <div>
                                                                            {selectedCourses.map(
                                                                                (
                                                                                    item,
                                                                                    index
                                                                                ) => (
                                                                                    <DetailLine
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                    >
                                                                                        <DetailText>
                                                                                            {
                                                                                                item
                                                                                            }
                                                                                        </DetailText>
                                                                                        {/* <VisibleIcon src={eyeIcon} alt="icon" /> */}
                                                                                    </DetailLine>
                                                                                )
                                                                            )}
                                                                        </div>
                                                                    </Popover>
                                                                </TDText>
                                                            </TD>
                                                            <TD>
                                                                <TDText>
                                                                    {" "}
                                                                    {student.consulatnt
                                                                        ? student.consultant
                                                                        : `---`}
                                                                </TDText>
                                                            </TD>
                                                            <TD>
                                                                <TDText>
                                                                    {new Intl.NumberFormat(
                                                                        "en-US",
                                                                        {
                                                                            style: "currency",
                                                                            currency:
                                                                                "AED",
                                                                        }
                                                                    ).format(
                                                                        student
                                                                            .emi_details
                                                                            .total_course_fees
                                                                    )}
                                                                    {/* {
                                                        student.emi_details
                                                            .total_course_fees
                                                    } */}
                                                                </TDText>
                                                            </TD>
                                                            <TD>
                                                                <TDText>
                                                                    {new Intl.NumberFormat(
                                                                        "en-US",
                                                                        {
                                                                            style: "currency",
                                                                            currency:
                                                                                "AED",
                                                                        }
                                                                    ).format(
                                                                        student
                                                                            .emi_details
                                                                            .total_fee_paid
                                                                    )}
                                                                    {/* {
                                                        student.emi_details
                                                            .total_fee_paid
                                                    } */}
                                                                </TDText>
                                                            </TD>
                                                            <TD>
                                                                <TDText>
                                                                    {new Intl.NumberFormat(
                                                                        "en-US",
                                                                        {
                                                                            style: "currency",
                                                                            currency:
                                                                                "AED",
                                                                        }
                                                                    ).format(
                                                                        student
                                                                            .emi_details
                                                                            .total_due_amount
                                                                    )}
                                                                    {/* {
                                                        student.emi_details
                                                            .total_due_amount
                                                    } */}
                                                                </TDText>
                                                            </TD>
                                                            <TD>
                                                                <TDText>
                                                                    {new Intl.NumberFormat(
                                                                        "en-US",
                                                                        {
                                                                            style: "currency",
                                                                            currency:
                                                                                "AED",
                                                                        }
                                                                    ).format(
                                                                        student
                                                                            .emi_details
                                                                            .total_balance_amount
                                                                    )}
                                                                    {/* {
                                                        student.emi_details
                                                            .total_balance_amount
                                                    } */}
                                                                </TDText>
                                                            </TD>
                                                            <TD>
                                                                <TDText>
                                                                    {" "}
                                                                    {
                                                                        student
                                                                            .emi_details
                                                                            .due_date
                                                                    }
                                                                </TDText>
                                                            </TD>
                                                            <TD>
                                                                <TDText>
                                                                    {student.next_followup
                                                                        ? student.next_followup
                                                                        : "---"}
                                                                </TDText>
                                                            </TD>
                                                            {/* <TD>
                                                        <TDText>
                                                            Last Pay Date
                                                        </TDText>
                                                    </TD> */}
                                                            {/* <TD>
                                                <TDText>
                                                    Next Due Date
                                                </TDText>
                                            </TD> */}
                                                            <TD>
                                                                <TDText>
                                                                    {/* {student.executive} */}
                                                                    {new Intl.NumberFormat(
                                                                        "en-US",
                                                                        {
                                                                            style: "currency",
                                                                            currency:
                                                                                "AED",
                                                                        }
                                                                    ).format(
                                                                        student
                                                                            .emi_details
                                                                            .emi_amount
                                                                    )}
                                                                    {/* {
                                                        student.emi_details
                                                            .emi_amount
                                                    } */}
                                                                </TDText>
                                                            </TD>

                                                            <TD
                                                                style={{
                                                                    maxWidth:
                                                                        "130px",
                                                                }}
                                                            >
                                                                <TDText
                                                                    style={{
                                                                        maxWidth:
                                                                            "130px",
                                                                        overflow:
                                                                            "hidden",
                                                                        cursor: "context-menu",
                                                                        textOverflow:
                                                                            "ellipsis",
                                                                    }}
                                                                    onMouseEnter={(
                                                                        e
                                                                    ) => {
                                                                        handleRemarkPopoverOpen(
                                                                            e,
                                                                            student.remark
                                                                        );
                                                                    }}
                                                                    onMouseLeave={
                                                                        handleRemarkPopoverClose
                                                                    }
                                                                >
                                                                    {
                                                                        student.remark
                                                                    }
                                                                </TDText>
                                                                <Popover
                                                                    id="mouse-over-remark-popover"
                                                                    open={
                                                                        remarkOpen
                                                                    }
                                                                    anchorEl={
                                                                        remarkAnchorEl
                                                                    }
                                                                    anchorOrigin={{
                                                                        vertical:
                                                                            "bottom",
                                                                        horizontal:
                                                                            "left",
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical:
                                                                            "top",
                                                                        horizontal:
                                                                            "left",
                                                                    }}
                                                                    sx={{
                                                                        pointerEvents:
                                                                            "none",
                                                                        "&& .MuiPopover-paper":
                                                                            {
                                                                                boxShadow:
                                                                                    "rgba(0, 0, 0, 0.05) 0px 3px 8px",
                                                                                // boxShadow: "none",
                                                                            },
                                                                    }}
                                                                    onClose={
                                                                        handleRemarkPopoverClose
                                                                    }
                                                                    disableRestoreFocus
                                                                >
                                                                    <DetailedRemarkDiv>
                                                                        <DetailedRemarkText>
                                                                            {
                                                                                selectedRemark
                                                                            }
                                                                        </DetailedRemarkText>
                                                                    </DetailedRemarkDiv>
                                                                </Popover>
                                                            </TD>
                                                        </TDR>
                                                    )
                                                )}
                                            </Tbody>
                                        ) : (
                                            // No data found
                                            <Tbody>
                                                <TDR>
                                                    <TD
                                                        className="no-data-cell"
                                                        colSpan="13"
                                                        // colSpan={
                                                        //     selectedTab == "Evaluated"
                                                        //         ? "10"
                                                        //         : "9"
                                                        // }
                                                    >
                                                        No data found
                                                    </TD>
                                                </TDR>
                                            </Tbody>
                                        )}
                                    </Table>
                                </TableWrapper>
                                <div style={{ marginTop: "20px" }}>
                                    <MainPagination
                                        paginationData={paginationData}
                                        setCurrentPage={setCurrentPage}
                                        currentPage={currentPage}
                                        marginRight="0"
                                        // isCountSelector={true}
                                        itemsCount={itemsCount}
                                        setItemsCount={setItemsCount}
                                        currentItemsCount={studentsData?.length}
                                    />
                                </div>
                            </DetailedSection>
                            </>
                        ) : (
                            <LoaderContainer>
                                <LoadingGif src={loadingIcon} alt="icon" />
                            </LoaderContainer>
                        ))}
                    {/* Students table ends here */}

                    <EditModal
                        setEditModal={setEditModal}
                        editModal={editmodal}
                    />
                </MainDiv>
            </Wrapper>
        </>
    );
}

export default FinanceExecutiveSingle;
const MainDiv = styled.div`
    margin-top: 20px;
    border: 1px solid #d7d7d7;
    border-radius: 12px;
`;
const TopDiv = styled.div`
    display: flex;
    align-items: center;
    padding: 15px;
`;
const LeftDiv = styled.div`
    width: 140px;
    margin-right: 30px;
    img {
        display: block;
        width: 100%;
    }
`;
const RightDiv = styled.div`
    width: 30%;
    span {
        width: 10%;
        display: inline-block;
        text-align: center;
    }
`;
const TitleTexts = styled.p`
    font-size: 14px;
    color: #000;
    width: 30%;
`;
const TitleBig = styled.p`
    width: 40%;
    font-size: 14px;
    color: #939393;
`;
const BottomDiv = styled.div`
    background-color: #edf5fe;
    height: 80%;
    padding: 10px 15px;
`;
const Search = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
const ShowTextDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;
`;
const Text = styled.p`
    font-size: 14px;
    color: ${(props) => (props.color === "filter" ? "#8561C6" : "#000")};
    margin-right: 10px;
`;
const CountDiv = styled.div`
    display: flex;
    position: relative;
    border: 1px solid #d7d7d7;
    border-radius: 8px;
    padding: 12px;
`;
const Up = styled.div`
    width: 14px;
    img {
        display: block;
        width: 100%;
    }
`;
const Down = styled.div`
    width: 14px;
    position: absolute;
    top: 25px;
    left: 37.5px;
    img {
        display: block;
        width: 100%;
        transform: rotate(180deg);
    }
`;
const SearchDiv = styled.div`
    display: flex;
    align-items: center;
    width:308px;
`;
const Form = styled.form`
    // font-size: 15px;
    // box-sizing: border-box;
    // background: #f5f5f5;
    // border-radius: 8px;
    // border: 1px solid #f5f5f5;
    // display: flex;
    // align-items: center;
    // padding: 10px;
    // width: 80%;
     font-size: 15px;
    box-sizing: border-box;
    background: #f5f7fa;
    border-radius: 8px;
    width: 308px !important;
    border: 1px solid #aea7fd;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
`;
const SearchIcon = styled.img`
    margin-right: 10px;
`;
const SearchField = styled.input`
     width: 100%;
    height: 90%;
    &:focus-visible {
        outline: none;
        background: #f5f7fa;
        border: 0px solid #2d60ff;
    }
`;
const Filter = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #8561c6;
    padding: 10px;
    border-radius: 8px;
`;

const FinanceSection = styled.div`
    margin-bottom: 15px;
`;
const StudentsSecttion = styled.div``;
const TitleBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* align-items: flex-end; */
    margin-bottom: 10px;
`;
const TitleText = styled.h6`
    /* font-size: 22px; */
    font-weight: 600;
    font-size: var(--title);
    line-height: 29px;
    /* identical to box height */

    /* dark/black */

    color: #000000;
`;
const DropdownDiv = styled.div`
    display: flex;
    align-items: center;
`;
const FilterText = styled.p`
    /* font-weight: 500; */
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-transform: capitalize;

    /* Dark/500 */

    color: #010101;
`;
const CardsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 16px;
`;
const LoaderContainer = styled.div`
    height: calc(100vh - 250px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LoadingGif = styled.img`
    width: 60px;
    display: block;
    object-fit: cover;
`;
const ActiveDiv = styled.div`
    color: #79a358;
    font-size: 16px;
    background-color: #79a3581a;
    padding: 10px;
    border-radius: 20px;
    margin-left: 20px;
`;
const Titles = styled.a`
    font-size: 14px;
    color: #939393;
    cursor: pointer;
`;
const GreyText = styled(Link)`
    color: #9d9d9d;
    font-size: 15px;
`;
const DetailedSection = styled.div`
    margin: 20px 0 0 0;
    padding: 10px 15px;
`;

// Additional styles for text within cells
const HeaderText = styled.span`
    display: inline-block;
    color: #9d9d9d;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
`;

const DataText = styled.span`
    display: inline-block;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
`;

const HeaderDataContainer = styled.div`
    display: flex;
    align-items: center;
`;
const BodyDataContainer = styled.div`
    display: flex;
    align-items: center;
`;

const CheckBoxIcon = styled.img`
    width: 17px;
    cursor: pointer;
    /* display: block; */
    margin-right: 8px;
    margin-top: 2px;
`;

const TableWrapper = styled.div`
    border: 1px solid #e7e7e750;
    border-right: none;
    border-bottom: none;
    border-radius: 12px;
    overflow: auto;
    max-height: calc(100vh - 230px);
    scrollbar-width: thin;
    /* Scrollbar styles for WebKit-based browsers (Chrome, Safari) */
    /* Scrollbar styles for WebKit-based browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
        display: block !important;
        border-radius: 12px;
        background: #fbfbfb;
        border: 1px solid #e7e7e750;
        /* overflow: hidden; */
    }

    &::-webkit-scrollbar:vertical {
        width: 10px;
        border-top-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
        border-bottom: none;
    }
    &::-webkit-scrollbar:horizontal {
        height: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top: none;
        border-right: none;
    }

    &::-webkit-scrollbar-corner {
        /* display: none; */
        background-color: #fbfbfb;
        border-bottom-right-radius: 16px;
        border: 1px solid #e7e7e750;
        border-top: none;
        border-left: none;
    }

    &::-webkit-scrollbar-track:horizontal {
        border: none;
        width: 100%;
    }
    &::-webkit-scrollbar-track:vertical {
        border: none;
        height: 100%;
    }

    &::-webkit-scrollbar-thumb {
        background: #e7e7e7;
        border-radius: 12px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    &::-webkit-scrollbar-button {
        display: none !important;
        background-color: #f00;
    }

    /* .container::-webkit-scrollbar-button:vertical:decrement {
        display: none;
    }

    .container::-webkit-scrollbar-button:vertical:increment {
        display: none;
    } */

    /* Define the button style when being hovered over */
    /* &::-webkit-scrollbar-button:hover {
    } */

    /* &::-webkit-scrollbar-track-piece:vertical:start {
    }

    &::-webkit-scrollbar-track-piece:vertical:end {
    } */
`;
const Table = styled.table`
    min-width: 100%;
    width: auto;
    background: rgb(237, 245, 254);
    /* table-layout: fixed; */
    padding: 0;
    margin: 0;
    border-spacing: 0;
    /* border-radius: 16px; */
    text-transform: capitalize;

    th {
        color: #9d9d9d;
        font-weight: 500;
        font-size: 15px;
        text-transform: capitalize;

        padding: 18px 16px;
        text-align: left;
        max-width: 300px !important;
        /* min-width: 250px !important; */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    td {
        /* font-size: 15px; */
        font-size: 14.5px;
        font-weight: 500;
        padding: 18px 16px;
        text-align: left;
        max-width: 300px !important;
        /* min-width: 250px !important; */

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    td,
    th {
        vertical-align: middle;
    }
    .no-data-cell {
        color: #404040;
        font-size: 22px;
        /* font-weight: 500; */
        text-align: center; /* Center-align text horizontally */
        vertical-align: middle; /* Center-align text vertically */
        height: calc(
            100vh - 405px
        ); /* Set a fixed height to vertically center the content if needed */
    }
    .pagination-cell {
        text-align: right;
    }
`;
const Thead = styled.thead`
    position: sticky;
    top: 0;
    z-index: 1;
`;
const THR = styled.tr`
    background: var(--primary-color);
`;
const TH = styled.th`
    // background-color: #fff;
    box-shadow: 0 0 15px rgb(0 0 0 / 3%);
    clip-path: inset(0px 0px -15px 0px);

    &:first-child {
        position: sticky;
        left: 0;
        z-index: 10;
        padding-right: 24px;
        box-shadow: 0 0 15px rgb(0 0 0 / 3%);
        clip-path: inset(0px -15px -15px 0px);
    }

    /* &:last-child {
        position: sticky;
        right: 0;
        z-index: 1;
    } */
`;
const Tbody = styled.tbody`
    background: #fbfbfb;
    position: sticky;
`;
const TDR = styled.tr`
    &:nth-child(odd) > td {
        /* background: #ffffff; */
    }
    &:nth-child(even) > td {
        background: #fff;
    }

    &:last-child > td {
        &:first-child {
        }
        &:last-child {
        }
    }
`;
const TD = styled.td`
    background: #fbfbfb;
    text-transform: ${(props) =>
        props.isEmailField ? "lowercase" : "capitalize"};

    font-family: "Inter", sans-serif;
    /* &:nth-child(even){
    background: #fbfbfb;

    } */

    &:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
        padding-right: 24px;
        box-shadow: 0 0 15px rgb(0 0 0 / 5%);
        clip-path: inset(0px -15px 0px 0px);
    }

    /* &:last-child {
        position: sticky;
        right: 0;
        z-index: 1;
    } */
    .link-td-text {
        color: #8561c6;
        cursor: pointer;
        &:hover {
            /* color: blue; */
            text-decoration: underline;
        }
    }
`;
const TDText = styled.span`
    font-family: "Inter", sans-serif;
    font-size: 15px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
const THText = styled.span`
    display: inline-block;
`;
const ProfilePic = styled.img`
    display: block;
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 6px;
`;
const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const AddEmiButton = styled.button`
    display: flex;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 4px;
    border: 1px solid #917afd;
    background: #fff;
    color: #917afd;
    font-size: 13px;
`;
const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: right;
`;
const ViewIcon = styled.img`
    width: 18px;
    display: block;
    object-fit: cover;
    cursor: pointer;
`;
const AssignButton = styled.button`
    background: #917afd;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    height: 42px;
    box-sizing: border-box;
    padding: 8px 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin-left: 15px;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    opacity: ${(props) => (props.disabled ? "0.7" : "1")};
`;
const PopoverSelectDiv = styled.div`
    height: 42px;
    box-sizing: border-box;
    padding: 4px;
    display: flex;
    align-items: center;
    width: 100%;
    background: #ffffff;

    border: 1px solid #d7d7d7;
    border-radius: 12px;
`;
const PopoverSelectItem = styled.div`
    width: 50%;
    padding: 8px 10px;
    background: ${(props) => (props.selected ? "#EAEFF5" : "#fff")};
    border-radius: 8px;
    font-size: 15px;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    opacity: ${(props) => (props.disabled ? "0.3" : "1")};
`;
const CancelPopoverButton = styled.button`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #010101;
    letter-spacing: 0.04em;
    font-size: 14px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid #917afd;
    border-radius: 8px;
    cursor: pointer;
`;
const ApplyPopoverButton = styled.button`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #010101;
    letter-spacing: 0.04em;
    font-size: 14px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid #917afd;
    border-radius: 8px;
    background: #917afd;
    color: #fff;
    cursor: pointer;
`;
const ClearAllFiltersButton = styled.div`
    height: 44px;
    background: #fff;
    padding: 6px;
    height: 44px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #d7d7d7;
    margin-left: 10px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
const MainBox = styled.div`
    width: 250px;
    padding: 10px;
    max-height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
`;
const DownArrow = styled.img`
    cursor: pointer;
    width: 13px;
    margin-left: 20px;
    /* margin-right: 15px; */
`;
const RadioButtonIcon = styled.img`
    width: 21px;
    cursor: pointer;
    display: block;
    margin-right: 8px;
`;
const ProgramCards = styled.div`
    margin-top: 8px;
`;
const ProgramCard = styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 6px;
    cursor: pointer;
    &:hover {
        background: #f5f5f5;
    }
`;
const ProgramName = styled.div`
    text-transform: capitalize;
    font-size: 14.6px;
    font-weight: 400;
    color: #010101;
    margin-top: 4px;
`;
const ButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    margin-top: 10px;
`;
const ExtraCourse = styled.span`
    display: inline-block;
    cursor: pointer;
    margin-left: 2px;
    color: #9c9c9c;
    font-size: 16px;
`;
const DetailLine = styled.div`
    padding: 6px 10px;
    min-width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0px;
    }
`;
const DetailText = styled.span`
    font-weight: 400;
    font-size: 14px;
    /* line-height: 17px; */
    text-align: right;

    color: #000000;
`;
const DetailedRemarkDiv = styled.div`
    max-width: 260px;
    padding: 6px;
`;
const DetailedRemarkText = styled.p`
    /* max-width: 240px; */
    padding: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    box-sizing: border-box;
    /* or 150% */

    /* dark/black */

    color: #000000;
    /* margin-top: 10px; */
`;
const ExecutiveModalContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 680px;
    max-height: 85vh;
    /* min-height: 40vh; */
    overflow-y: scroll;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 12px;
    box-shadow: 24px;
    padding: 26px;
`;
const FormInput = styled.div``;
const FormInputTitle = styled.div`
    font-size: 18px;
    margin-bottom: 12px;
`;
const ModalTitle = styled.h6`
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
`;
const Href = styled.div`
    width: 100%;
    height: 1px;
    margin: 20px 0;
    /* border-color: #e7e7e7 !important ; */
    background-color: #e7e7e7;
`;
const LightContainer = styled.div`
    border-radius: 8px;
    border: 1px solid #e7e7e750;
    background: #f8fafc;
    padding: 20px;
`;
const CancelButton = styled.button`
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f61f1f;
    letter-spacing: 0.1em;
    font-size: 16px;
    height: 40px;
    padding: 10px 16px;
    box-sizing: border-box;
    background: none;
    border: none;
    /* border: 1px solid #917afd; */
    border-radius: 8px;
    cursor: pointer;
`;
const ApplyButton = styled.button`
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #010101;
    letter-spacing: 0.08em;
    font-size: 16px;
    height: 44px;
    padding: 10px 20px;
    box-sizing: border-box;
    border: 1px solid #917afd;
    border-radius: 8px;
    background: #917afd;
    color: #fff;
    cursor: pointer;
`;
const ButtonsWrapper = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
