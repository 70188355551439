import React, { useEffect, useState, useContext } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Button from "../../components/Button";
import Text from "../../components/Text";
import addbutton from "../../assets/images/addbutton.svg";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import { AddLeadsService } from "../../service/leads";
import { useHistory, useParams } from "react-router-dom";
import { AddSourceService, ListSourceService } from "../../service/sources";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import styled from "styled-components";
import ExcelFileModal from "../../components/ExcelFileModal";
import { capitalizeString } from "./../../helpers/Functions";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import CountryCodeModal from "../../components/CountryCodeModal";
import ExcelDuplicateModal from "../../components/includes/ExcelDuplicateModal";

function AddNewLead() {
    const history = useHistory();
    const [sources, setSources] = useState([]);
    const [university, setUniversity] = useState([]);
    const [course, setCourse] = useState([]);
    const [form, setFormValues] = useState({});
    const { state } = useContext(Context);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [fieldName, setFieldName] = useState("");
    const [excelModal, setExcelModal] = useState(false);
    const [ExcelFile, setExcelFile] = useState(null);
    const { leadID } = useParams();
    const [alertStatus, setAlertStatus] = useState("");
    const [isExcelDuplicateModal, setExcelDuplicateModal] = useState(false);
    const [excelDuplicateData, setExcelDuplicateData] = useState({});
    const [alertMessage, setAlertMessage] = useState("");
    // Field states
    const [leadName, setLeadName] = useState("");
    const [selectedUniversity, setSelectedUniversity] =
        useState("Not Specified");
    const [selectedCourse, setSelectedCourse] = useState("Not Specified");
    const [country, setCountry] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [secondaryPhone, setsecondaryPhone] = useState("");
    const [address, setAddress] = useState("");
    const [selectedSource, setSelectedSource] = useState("");
    const [education, setEducation] = useState("");
    const [description, setDescription] = useState("");
    const [emailValidation, setEmailValidation] = useState(false);
    // name validation
    const [leadNameValidation, setLeadNameValidation] = useState(false);

    const [gender, setGender] = useState("");
    const [isAlert, setIsAlert] = useState(false);
    const [feeAmount, setFeeAmount] = useState("");
    const [dateOfEnquiry, setDateOfEnquiry] = useState(
        new Date().toLocaleDateString("en-CA")
    );

    const [countrySearch, setCountrySearch] = useState("");
    const [countryCode, setCountryCode] = useState([]);
    const [isCountryModal, setCountryModal] = useState(false);
    const [selectedNationalityCode, setSelectedNationalityCode] = useState(231);
    const [selectedCountryCode, setSelectedCountryCode] = useState(231);

    const handleChangeValue = (key, value) => {
        setFormValues({ ...form, [key]: value });
    };

    const genderOption = [
        {
            id: 1,
            gender: "Male",
        },
        {
            id: 2,
            gender: "Female",
        },
        {
            id: 3,
            gender: "Other",
        },
    ];

    // useEffect(() => {
    // 	if (leadName.length === 0) {
    // 		setTimeout(() => {
    // 			setUserName("");
    // 			setPassword("");
    // 		}, 200);
    // 	}
    // 	setUserName(`${leadName.replace(/\s+/g, "")}@LMS`);
    // 	// setPassword(`${leadName.replace(/\s+/g, "")}${id}@LMS`);
    // }, [leadName]);
    //country

    //get country code api
    const getCountryCode = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/countries/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: countrySearch,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    // setPrograms(data.data);
                    setCountryCode(data.data);
                } else {
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        getCountryCode();
    }, [countrySearch]);

    const validate = (text) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(text) === false) {
            setEmailValidation(false);
            setEmail(text);
            return false;
        } else {
            setEmail(text);
            setEmailValidation(true);
        }
    };
    // name validation
    const leadnamevalidate = (text) => {
        let reg = /^[a-zA-Z0-9]+$/;
        if (reg.test(text) === false) {
            setLeadNameValidation(false);
            setLeadName(text);

            return false;
        } else {
            setLeadName(text);
            setLeadNameValidation(true);
        }
    };

    // const logAll = () => {

    const handleSubmit = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        // if (ExcelFile !== null && typeof ExcelFile === "object") {
        // 	formdata.append("leads_excel_file", ExcelFile[0]);
        // }

        formdata.append("name", leadName);
        if (
            selectedUniversity !== "Not Specified" &&
            selectedUniversity !== null
        ) {
            formdata.append("university", selectedUniversity);
        }
        if (selectedCourse !== "Not Specified" && selectedCourse !== null) {
            formdata.append("course", selectedCourse);
        }
        if (
            selectedNationalityCode !== "" &&
            selectedNationalityCode !== null &&
            selectedNationalityCode !== "Select Nationality"
        ) {
            formdata.append("nationality", selectedNationalityCode);
        }
        if (
            selectedCountryCode !== "" &&
            selectedCountryCode !== null &&
            selectedCountryCode !== "Select Country"
        ) {
            formdata.append("country", selectedCountryCode);
        }
        // if (email !== "") {
        formdata.append("email", email);
        // }
        if (phone !== "" && phone !== null) {
            formdata.append("phone", phone);
        }
        if (secondaryPhone !== "") {
            formdata.append("phone_2", secondaryPhone);
        }
        if (!leadID) {
            formdata.append("enquiry_date", dateOfEnquiry);
            formdata.append("lead_source", selectedSource);
        }

        // formdata.append("lead_source", selectedSource);
        // formdata.append("qualification", education);
        if (education !== "" && education !== null) {
            formdata.append("lead_campaign", education);
        }

        if (feeAmount !== "") {
            formdata.append("fee_amount", feeAmount);
        }
        // formdata.append("enquiry_date", dateOfEnquiry);
        if (description !== "" && description !== null) {
            formdata.append("description", description);
        }
        // formdata.append("address_line", address);
        // formdata.append("gender", gender);
        // if (selectedUniversity) {
        //     formdata.append("university", selectedUniversity);
        // }
        // if (selectedCourse) {
        //     formdata.append("course", selectedCourse);
        // }

        var method = leadID ? baseConfig.put : baseConfig.post;
        var URL = leadID ? `/leads/lead/${leadID}/` : `/leads/lead/`;

        method(URL, formdata, {
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
            },
        })
            // baseConfig
            // 	.post(`/leads/lead/`, formdata, {
            // 		headers: {
            // 			Authorization: "Bearer " + accessToken,
            // 			"Content-Type": "multipart/form-data",
            // 		},
            // 	})
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    if (data.duplicate_lead) {
                        setAlertStatus("warning");
                        setIsAlert(true);
                        setAlertMessage("Duplicate entries found");
                        setTimeout(() => {
                            history.push("/leadgenerator/duplicate-leads");
                        }, 1000);
                    } else {
                        setAlertStatus("success");
                        setIsAlert(true);
                        if (leadID) {
                            setAlertMessage("Lead succesfully edited");
                        } else {
                            setAlertMessage("Lead added successfully");
                        }
                        setTimeout(() => {
                            history.push("/leadgenerator/list-leads");
                        }, 1000);
                    }
                } else {
                    setAlertStatus("error");
                    setIsAlert(true);
                    setAlertMessage("Lead added failed");
                }
            })
            .catch((error) => {
                const { data } = error.response.data;
                setAlertStatus("error");
                setIsAlert(true);
                setAlertMessage(data.message);
            });
    };

    // useEffect(() => {
    // 	if (isAlert) {
    // 		setTimeout(() => {
    // 			setIsAlert(false);
    // 		}, 2000);
    // 	}
    // }, [isAlert]);

    const handleClose = () => {
        setIsAlert(false);
        setAlertStatus("");
    };

    // excel upload
    const handleExcelFile = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        if (ExcelFile !== null && typeof ExcelFile === "object") {
            formdata.append("leads_excel_file", ExcelFile[0]);
        }
        baseConfig
            .post(`/leads/excel-upload-leads/`, formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    if (data.total_failed_leads > 0) {
                        setExcelModal(false);
                        setExcelDuplicateModal(true);
                        setExcelDuplicateData(data);
                    } else {
                        setExcelModal(false);
                        setAlertStatus("success");
                        setIsAlert(true);
                        setAlertMessage("Lead added success");
                    }

                    // setIsAlert(true);
                } else {
                    setIsAlert(true);
                    setAlertStatus("error");
                    setAlertMessage("Lead added failed");
                }
            })
            .catch((error) => {
                setIsAlert(true);
                setAlertStatus("error");
                setAlertMessage("Lead added failed");
            });
    };

    const getData = async () => {
        let data = await ListSourceService(state);
        if (data.StatusCode === 6000) {
            setSources(data?.data?.data);
        } else {
            alert(data.data.message);
        }
    };

    const getLeadSource = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/university/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setUniversity(data.data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const getCourses = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/select-courses/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    university: selectedUniversity,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setCourse(data);
                } else {
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        getData();
        getLeadSource();
    }, []);

    useEffect(() => {
        getCourses();
    }, [selectedUniversity]);

    // single Leads
    const getSingleLead = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/leads/lead/${leadID}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    // setLeadName(data.data.name);
                    leadnamevalidate(data.data.name);

                    setSelectedUniversity(data.data.university);
                    setSelectedCourse(data.data.course);
                    setCountry(data.data.country_name);
                    validate(data.data.email);
                    setsecondaryPhone(data.data.phone_2);
                    setPhone(data.data.phone);
                    setSelectedSource(data.data.lead_source);
                    setSelectedNationalityCode(data.data.nationality);
                    setEducation(data.data.lead_campaign);
                    setDescription(data.data.description);
                    setFeeAmount(data.data.fee_amount);
                    setDateOfEnquiry(data.data.enquiry_date);
                    setSelectedCountryCode(data.data.country);
                } else {
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        if (leadID) {
            getSingleLead();
        }
    }, [leadID]);

    const AlertBox = ({ status }) => {
        return (
            <Stack spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                    open={isAlert}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    {/* {status === "success" ? (
						<Alert
							onClose={handleClose}
							severity={status}
							sx={{ width: "100%" }}
						>
							Lead added success — <strong>check it out!</strong>
						</Alert>
					) : (
						<Alert
							onClose={handleClose}
							severity={status}
							sx={{ width: "100%" }}
						>
							Lead added failed — <strong>check it out!</strong>
						</Alert>
					)} */}
                    <Alert
                        onClose={handleClose}
                        severity={status}
                        sx={{ width: "100%" }}
                    >
                        {alertMessage} — <strong>check it out!</strong>
                    </Alert>
                </Snackbar>
            </Stack>
        );
    };

    return (
        <Wrapper>
            {/* {isAlert && <AlertBox />} */}
            <AlertBox status={alertStatus} />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                }}
            >
                <div style={{ display: "flex", margin: "0 12px" }}>
                    {leadID ? (
                        <Title fontSize="35x">Edit Lead</Title>
                    ) : (
                        <Title fontSize="35x">Add Leads</Title>
                    )}
                </div>
                <RightDiv>
                    <DownloadButton
                        href="https://backend.learnersuae.com/static/sheets/lms-lead-sample-sheet.xlsx"
                        target="_blank"
                    >
                        <Icon
                            src={
                                require("./../../assets/images/download.svg")
                                    .default
                            }
                        />
                        <UploadText>Sample Sheet</UploadText>
                    </DownloadButton>
                    <UploadButton
                        onClick={() => {
                            setExcelModal(true);
                        }}
                    >
                        <Icon
                            src={
                                require("./../../assets/images/sheet.svg")
                                    .default
                            }
                        />
                        <UploadText>Upload Sheet</UploadText>
                    </UploadButton>
                </RightDiv>
            </div>
            {/* <div style={{ display: "flex", margin: "0 12px" }}>
                <Text color="#A3A3A3" fontSize="16px">
                    ID No :{" "}
                </Text>
                <Text style={{ margin: "0 6px" }} color="black" fontSize="19px">
                    #0021{" "}
                </Text>
            </div> */}
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Container style={{ flexWrap: "wrap " }} width="100%">
                    <form id="form">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Input
                                onChange={(value) => {
                                    setDateOfEnquiry(value);
                                    setError(false);
                                }}
                                type="date"
                                // placeholder="+33 l 7 01 02 03 04"
                                name="Date Of Enquiry"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                // onWheel={true}
                                value={dateOfEnquiry}
                                disabled={leadID ? true : false}
                            />
                            <Input
                                onChange={(value) => {
                                    leadnamevalidate(capitalizeString(value));
                                    // setLeadName(capitalizeString(value));
                                    setError(false);
                                }}
                                type="name"
                                name="Name"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={leadName}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <SelectInput
                                name="University"
                                type="text"
                                placeholder="Select University"
                                onChange={(value) => {
                                    setSelectedUniversity(value);
                                    setError(false);
                                }}
                                defaultValue="Not Specified"
                                options={university}
                                selectValue={"id"}
                                selectName={"name"}
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={selectedUniversity}
                            />
                            <SelectInput
                                name="Program"
                                type="text"
                                placeholder="Choose Course"
                                onChange={(value) => {
                                    setSelectedCourse(value);
                                    setError(false);
                                }}
                                defaultValue={"Choose Course"}
                                options={course}
                                selectValue={"id"}
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={selectedCourse}
                            />

                            {/* <Input
                                onChange={(value) => {
                                    setCountry(capitalizeString(value));
                                    setError(false);
                                }}
                                type="name"
                                name="Nationality"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={country}
                            /> */}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Input
                                onChange={(value) => {
                                    validate(value);
                                    setError(false);
                                }}
                                type="email"
                                // placeholder="example@example.com"
                                name="Email"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={email}
                            />
                            <Input
                                onChange={(value) => {
                                    setPhone(value);
                                    setError(false);
                                }}
                                type="Number"
                                // placeholder="+33 l 7 01 02 03 04"
                                name="Phone Number (Primary)"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                onWheel={true}
                                value={phone}
                            />
                            {/* <div style={{ width: "49%" }}>
								<Text
									color="#7B6294"
									fontSize="15px"
									style={{ marginBottom: "12px" }}
								>
									Phone
								</Text>
								<PhoneDiv>
									<CountryDiv
										color={
											isError && fieldName === "Phone"
												? "red"
												: "#dfeaf2"
										}
									>
										<FlagSelectDiv
											onClick={() => {
												setCountryModal(true);
												setCountrySearch("");
											}}
										>
											<Flag>
												{selectedCountryCode.flag}
											</Flag>
											<Arrow
												type="arrow"
												src={
													require("../../assets/images/arrow.svg")
														.default
												}
												alt="arrow"
											/>
										</FlagSelectDiv>
										<Code>
											{selectedCountryCode.phone_code}
										</Code>
										<PhoneInput
											type="number"
											onChange={(e) => {
												setPhone(e.target.value);
												setError(false);
											}}
											value={phone}
										/>
									</CountryDiv>
									{isError === true &&
										fieldName === "Phone" && (
											<ErrorText>
												{errorMessage}
											</ErrorText>
										)}
								</PhoneDiv>
							</div> */}
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            {/* <Input
								onChange={(value) => {
									setAddress(value);
									setError(false);
								}}
								type="name"
								name="Address"
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
							/> */}
                            <Input
                                onChange={(value) => {
                                    setsecondaryPhone(value);
                                    setError(false);
                                }}
                                type="number"
                                // placeholder="+33 l 7 01 02 03 04"
                                name="Phone Number (Secondary)"
                                isError={isError}
                                errorMessage={errorMessage}
                                // fieldName={fieldName}
                                value={secondaryPhone}
                                disabled={phone ? false : true}
                            />
                            <SelectInput
                                name="Nationality"
                                type="text"
                                placeholder="Select Nationality"
                                onChange={(value) => {
                                    setSelectedNationalityCode(value);
                                    setError(false);
                                }}
                                defaultValue="Select Nationality"
                                options={countryCode}
                                selectValue={"id"}
                                selectName={"name"}
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={selectedNationalityCode}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <SelectInput
                                name="Country"
                                type="text"
                                placeholder="Select Country"
                                onChange={(value) => {
                                    setSelectedCountryCode(value);
                                    setError(false);
                                }}
                                defaultValue="Select Country"
                                options={countryCode}
                                selectValue={"id"}
                                selectName={"name"}
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={selectedCountryCode}
                            />
                            <SelectInput
                                name="Lead Source"
                                type="text"
                                options={sources}
                                selectName={"name"}
                                selectValue={"id"}
                                defaultValue="Choose Source"
                                onChange={(value) => {
                                    setSelectedSource(value);
                                    setError(false);
                                }}
                                disabled={leadID ? true : false}
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={selectedSource}
                            />

                            {/* <Input
								onChange={(value) => {
									setFeeAmount(value);
									setError(false);
								}}
								type="text"
								name="Fee Amount"
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
								value={feeAmount}
							/> */}
                            {/* <SelectInput
								name="Gender"
								type="text"
								options={genderOption}
								selectName={"gender"}
								selectValue={"gender"}
								defaultValue="Select gender"
								// onChange={(value) => {
								// 	setSelectedSource(value);
								// 	setError(false);
								// }}
								onChange={(value) => {
									setGender(value);
									setError(false);
								}}
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
							/> */}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Input
                                onChange={(value) => {
                                    setEducation(value);
                                    setError(false);
                                }}
                                type="Name"
                                name="Campaign Name"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={education}
                            />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Input
                                onChange={(value) => {
                                    setDescription(value);
                                    setError(false);
                                }}
                                padding="20px 15px 90px 20px"
                                type="text"
                                placeholder="Write your description here..."
                                name="Description"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={description}
                            />
                        </div>
                        {/* <div style={{ display: "flex", marginTop: "20px" }}>
							<Input
								onChange={(value) => {
									setDescription(value);
									setError(false);
								}}
								padding="20px 15px 90px 20px"
								type="text"
								placeholder="Write your description here..."
								name="Description"
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
							/>
						</div> */}
                        <div style={{ display: "flex", marginTop: "21px" }}>
                            <Button
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                bacerkgroundColor="#5A2D8F"
                                fontSize="15px"
                                padding="21px 70px"
                                onClick={(e) => {
                                    // if (leadName === "") {
                                    //     setError(true);
                                    //     setFieldName("Name");
                                    //     setErrorMessage("Enter lead name");
                                    // }
                                    if (leadName == "") {
                                        setError(true);
                                        setFieldName("Name");
                                        setErrorMessage(
                                            "Please enter lead name"
                                        );
                                    }
                                    //  else if (
                                    // 	selectedNationalityCode === "" ||
                                    // 	selectedNationalityCode ===
                                    // 		"Select Nationality"
                                    // ) {
                                    // 	setError(true);
                                    // 	setFieldName("Nationality");
                                    // 	setErrorMessage(
                                    // 		"Please select a valid Country"
                                    // 	);
                                    // }
                                    //  else if (
                                    // 	selectedCountryCode === "" ||
                                    // 	selectedCountryCode === "Select Country"
                                    // ) {
                                    // 	setError(true);
                                    // 	setFieldName("Country");
                                    // 	setErrorMessage(
                                    // 		"Please select a valid Country"
                                    // 	);
                                    // }
                                    else if (phone === "" && email === "") {
                                        setError(true);
                                        setFieldName("Email");
                                        setErrorMessage(
                                            "Please enter a valid  email or phone"
                                        );
                                    } else if (
                                        email !== "" &&
                                        emailValidation === false
                                    ) {
                                        setError(true);
                                        setFieldName("Email");
                                        setErrorMessage(
                                            "Please enter a valid email"
                                        );
                                    } else if (
                                        selectedSource === "" ||
                                        selectedSource === "Choose Source"
                                    ) {
                                        setError(true);
                                        setFieldName("Lead Source");
                                        setErrorMessage(
                                            "Please select a valid source"
                                        );
                                    }
                                    // else if (address === "") {
                                    // 	setError(true);
                                    // 	setFieldName("Address");
                                    // 	setErrorMessage("Enter your address");
                                    // }
                                    // else if (
                                    // 	phone.length > 0 &&
                                    // 	(phone.length >
                                    // 		selectedCountryCode.max_number_length ||
                                    // 		phone.length <
                                    // 			selectedCountryCode.min_number_length)
                                    // ) {
                                    // 	setError(true);
                                    // 	setFieldName("Phone");
                                    // 	setErrorMessage(
                                    // 		"Please enter a valid phone number"
                                    // 	);
                                    // }

                                    // else if (address === "") {
                                    // 	setError(true);
                                    // 	setFieldName("Address");
                                    // 	setErrorMessage("Enter your address");
                                    // }
                                    // else if (
                                    //     selectedSource === "" ||
                                    //     selectedSource === "Choose Source"
                                    // ) {
                                    //     setError(true);
                                    //     setFieldName("Lead Source");
                                    //     setErrorMessage("Select your source");
                                    // }
                                    // else if (gender === "Select gender") {
                                    // 	setError(true);
                                    // 	setFieldName("Gender");
                                    // 	setErrorMessage("Select your gender");
                                    // }
                                    // else if (education === "") {
                                    // 	setError(true);
                                    // 	setFieldName("Campaign Name");
                                    // 	setErrorMessage("Enter your Campaign");
                                    // 	// } else if (description === "") {
                                    // 	// 	setError(true);
                                    // 	// 	setFieldName("Description");
                                    // 	// 	setErrorMessage(
                                    // 	// 		"Enter your description"
                                    // 	// 	);
                                    // }
                                    else {
                                        e.preventDefault();
                                        handleSubmit();
                                        // logAll();
                                    }
                                }}
                            >
                                <Text color="white" fontSize="15px">
                                    Submit
                                </Text>
                            </Button>

                            <Button
                                // href="/leadgenerator/list-leads/"
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.push("/leadgenerator/list-leads/");
                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 22px",
                                }}
                                backgroundColor="#F4F4F4"
                                fontSize="15px"
                                padding="21px 70px"
                            >
                                <Text color=" #522e92" fontSize="15px">
                                    Cancel
                                </Text>
                            </Button>
                        </div>
                    </form>
                </Container>
            </div>
            {excelModal && (
                <ExcelFileModal
                    modalClose={setExcelModal}
                    setExcelFile={setExcelFile}
                    handleExcelFile={handleExcelFile}
                />
            )}

            {isCountryModal && (
                <CountryCodeModal
                    setCountryModal={setCountryModal}
                    countryCode={countryCode}
                    setSelectedCountryCode={setSelectedCountryCode}
                    setCountrySearch={setCountrySearch}
                />
            )}
            {isExcelDuplicateModal && (
                <ExcelDuplicateModal
                    setModal={setExcelDuplicateModal}
                    excelDuplicateData={excelDuplicateData}
                />
            )}
        </Wrapper>
    );
}

export default AddNewLead;
const RightDiv = styled.div`
    display: flex;
    align-items: center;
`;
const UploadButton = styled.div`
    background: #79a358;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 15px;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 10px;
    last-child {
        margin-right: 0;
    }
`;
const Icon = styled.img`
    margin-right: 5px;
    height: 20px;
    width: 20px;
`;
const UploadText = styled.p`
    color: #fff;
`;
const DownloadButton = styled.a`
    background: #79a358;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    /* border: 1px solid #917afd; */
    border-radius: 8px;
    cursor: pointer;
    margin-right: 10px;
`;

const CountryDiv = styled.div`
    /* background: red; */
    /* height: 30px; */
    /* width: 30px; */
    /* position: absolute; */
    /* margin-bottom: 5px; */
    display: flex;
    align-items: center;
    border: 1px solid #dfeaf2;
    border-color: ${(props) => (props.color ? props.color : "#dfeaf2")};
    border-radius: 15px;
    margin-top: 12px;
    padding: 0 15px 0 20px;
    height: 60px;
    /* background-color: red; */
`;
const FlagSelectDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Flag = styled.h3`
    cursor: pointer;
    font-size: 24px;
    margin-bottom: -5px;
`;
const Code = styled.h5`
    margin-left: 3px;
    font-weight: 600;
    font-size: 15px;
`;
const Arrow = styled.img`
    transform: rotate(-90deg);
    height: 10px;
    margin: ${(props) => (props.type === "arrow" ? "2px 8px 0px 4px" : "0")};
    width: 10px;
`;
const PhoneDiv = styled.div`
    /* position: relative; */
    height: 50px;
    /* display: flex;
	align-items: center; */
    width: 100%;
    /* margin-bottom: 5px; */
`;
const PhoneInput = styled.input`
    width: 100%;
    font-family: "Inter";
    font-size: 15px;
    font-weight: 400;
    box-sizing: border-box;
    border: none;
    height: 60px;
    margin-left: 5px;
    &:focus {
        outline: none;
        border: 0px solid !important;
    }
`;
const ErrorText = styled.p`
    color: red;
`;
