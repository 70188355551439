import React, { useContext, useState } from 'react'
import styled from 'styled-components';
import { Context } from '../../../contexts/Store';
import { baseConfig } from '../../../utils/config';
import SnackbarComponent from './Snackbar';

function EmiPreviewModal({PreviewCloseModal, data, getEmiData, handleResponseModal, isDeleted, setDeleted}) {

    const { state, dispatch } = useContext(Context);
    const [alert, setAlert] = useState("");
    const [itemChangeImpact, setItemChangeImpact] = useState(true)

    const options = [
        {
            id: 1,
            value: true,
            name: "Yes"
        },
        {
            id: 2,
            value: false,
            name: "No"
        }
    ] 

    const handleAlert = (message) => {
        setAlert(message);
        setTimeout(() => {
            setAlert("");
        }, 3000);
    };

    const handleEditEmi = () => {
		let accessToken = state.user_details.access_token;
		var formdata = new FormData();
		formdata.append("instalment", data.instalment);
		formdata.append("due_date", data.due_date);
		formdata.append("amount", data.amount);
		formdata.append("vat", data.vat);
		formdata.append("total_amount", data.total_amount);
        formdata.append("discount_amount", data.discount_amount)
        data.discount_type && formdata.append("discount_type", data.discount_type ? data.discount_type : "Normal")
        formdata.append("status", data.status)
        formdata.append("paid_amount", data.paid_amount)
        data.dop && formdata.append("dop", data.dop)
        data.mop && formdata.append("mop", data.mop)
        formdata.append("item_change_impact", itemChangeImpact)

		baseConfig
			.put(`/students/student-emi/${data.id}/`, formdata, {
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-Type": "application/json",
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					getEmiData();
                    handleResponseModal();
                    setDeleted(false)
				} else {
					handleAlert(data.message);
				}
			})
			.catch((error, response) => {
                if (error.response.status === 400) {
                    handleAlert(error?.response?.data?.data?.message);
                }
            });
	};

    function convertDateForInput(dateStr) {
        if (!dateStr) {
            return '';
        }
    
        const [day, month, year] = dateStr.split('-');
        const months = {
            'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05', 'Jun': '06',
            'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10', 'Nov': '11', 'Dec': '12'
        };
    
        const formattedMonth = months[month];
    
        if (!formattedMonth) {
            return '';
        }
    
        return `${year}-${formattedMonth}-${day.padStart(2, '0')}`;
    }

  return (
    <>
    <MainContainer>
        <Title>Preview</Title>
        {alert && (
            <AlertContainer>
                <SnackbarComponent message={alert} onClose={() => setAlert("")} />
            </AlertContainer>
        )}
        <ContentContainer>
            {/* <InfoSection> */}
                <InfoLine>
                    <GreyInfoText>Instalment</GreyInfoText>
                    <span>: </span>
                    <BlackInfoText>{data.instalment}</BlackInfoText>
                </InfoLine>
                <InfoLine>
                    <GreyInfoText>Due date</GreyInfoText>
                    <span>: </span>
                    <BlackInfoText>{data.due_date}</BlackInfoText>
                </InfoLine>
                <InfoLine>
                    <GreyInfoText>Discount Type</GreyInfoText>
                    <span>: </span>
                    <BlackInfoText>{data.discount_type ? data.discount_type : "--"}</BlackInfoText>
                </InfoLine>
                <InfoLine>
                    <GreyInfoText>Status</GreyInfoText>
                    <span>: </span>
                    <BlackInfoText>{data.status ? data.status : "--"}</BlackInfoText>
                </InfoLine>
                <InfoLine>
                    <GreyInfoText>DOP</GreyInfoText>
                    <span>: </span>
                    <BlackInfoText>{data.dop ? data.dop : "--"}</BlackInfoText>
                </InfoLine>
                <InfoLine>
                    <GreyInfoText>MOP</GreyInfoText>
                    <span>: </span>
                    <BlackInfoText>{data.mop ? data.mop : "--"}</BlackInfoText>
                </InfoLine>
                <InfoLine>
                    <GreyInfoText>Instalment Amount</GreyInfoText>
                    <span>: </span>
                    <BlackInfoText>{data.amount}</BlackInfoText>
                </InfoLine>
                <InfoLine>
                    <GreyInfoText>Prev Balance</GreyInfoText>
                    <span>: </span>
                    <BlackInfoText>{data.previous_balance}</BlackInfoText>
                </InfoLine>
                <InfoLine>
                    <GreyInfoText>VAT</GreyInfoText>
                    <span>: </span>
                    <BlackInfoText>{data.vat}</BlackInfoText>
                </InfoLine>
                <InfoLine>
                    <GreyInfoText>Discount</GreyInfoText>
                    <span>: </span>
                    <BlackInfoText>{data.discount_amount}</BlackInfoText>
                </InfoLine>
                <InfoLine>
                    <GreyInfoText>Total Amount</GreyInfoText>
                    <span>: </span>
                    <BlackInfoText>{data.total_amount}</BlackInfoText>
                </InfoLine>
                <InfoLine>
                    <GreyInfoText>Paid Amount</GreyInfoText>
                    <span>: </span>
                    <BlackInfoText>{data.paid_amount}</BlackInfoText>
                </InfoLine>
                <InfoLine>
                    <GreyInfoText>Balance Amount</GreyInfoText>
                    <span>: </span>
                    <BlackInfoText>{data.balance_amount}</BlackInfoText>
                </InfoLine>
            {/* </InfoSection>
            <AmountSection> */}
            {/* </AmountSection> */}
        </ContentContainer>
        <ConfirmationContainer>
            <TextArea>
                This change may affect the upcoming instalments. Are you sure?
                <div style={{display:'flex', alignItems:'center'}}>
                    {options.map((item) => (
                        <div key={item.id} style={{display:"flex", alignItems:"center", marginTop:"5px", marginLeft:"5px"}}>
                            <CheckboxContainer 
                                selected={itemChangeImpact === item.value} 
                                onClick={() => setItemChangeImpact(item.value)}
                            />
                            <p>{item.name}</p>
                        </div>
                    ))}
                </div>
            </TextArea>
        </ConfirmationContainer>
        <Buttons>
            <Cancel onClick={PreviewCloseModal}>Cancel</Cancel>
            <Submit onClick={handleEditEmi}>Save</Submit>
        </Buttons>
    </MainContainer>
    </>
  )
}
export default EmiPreviewModal

const MainContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    max-width: 90%;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 12px;
    padding: 20px;
    overflow-y: auto;
    max-height: 90vh;
`;

const Title = styled.p`
    font-size: 18px;
    border-bottom: 1px solid #E7E7E7;
    padding-bottom: 20px;
    text-align: center;
`;

const AlertContainer = styled.div`
    margin-top: 10px;
`;

const ContentContainer = styled.div`
    // display: flex;
    // justify-content: space-between;
    margin-top: 20px;
    border-radius: 8px;
    border: 1px solid #E7E7E7;
    position: relative;
    padding: 12px;
    line-height: 1.5rem;
    height: 317px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

const InfoSection = styled.div`
    width: 65%;
`;

const AmountSection = styled.div`
    width: 30%;
`;

const InfoLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;
    &:last-child {
        margin-bottom: 0px;
    }
`;

const GreyInfoText = styled.span`
    color: #000000;
    font-weight: 400;
    opacity: 0.5;
    font-size: 14px;
    display: block;
    width: 49%;
`;

const BlackInfoText = styled.span`
    display: block;
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    white-space: nowrap;
    letter-spacing: 0.1px;
    text-transform: capitalize;
    width: 49%;
`;

const ConfirmationContainer = styled.div`
    background: #F8FAFC;
    // width: 100%;
    margin-top: 20px;
    border-radius: 8px;
    border: 1px solid #E7E7E7;
    padding: 20px;
`;

const TextArea = styled.div`
    font-size: 14px;
`;

const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

const Cancel = styled.button`
    color: #F61F1F;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 16px;
`;

const Submit = styled.button`
    background: #917AFD;
    padding: 12px 24px;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    font-size: 16px;
`;

const CheckboxContainer = styled.div`
    border: ${(props) => (props.selected ? "5px solid #522E92" : "1px solid #9D9D9D")};
    border-radius: 50%;
    width: ${(props) => (props.selected ? "10px" : "15px")};
    height: ${(props) => (props.selected ? "10px" : "15px")};
    margin-right: 5px;
    cursor: pointer;
`;
