import React from "react";
import Container from "../../components/Container";
import Image from "../../components/Image";
import archiveIcon from "../../assets/images/archive.svg";
import placeholderIcon from "../../assets/images/placeholder.svg";
import mailIcon from "../../assets/images/mail.svg";
import phoneIcon from "../../assets/images/phone-call.svg";
import eye from "../../assets/images/action-eye.svg";
import Text from "../Text";
import Button from "../Button";
import styled from "styled-components";
import "./styled.css";

function ListCampusItem({
	image,
	id,
	name,
	address,
	email,
	phone,
	onClickView,
	onClickDelete,
	onClickUpdateButton,
	archieve,
}) {
	return (
		<CampusItemBox>
			<CampusItemTop>
				<TopLeft>
					<CampusImageContainer>
						<CampusImage src={image} alt="Campus" />
					</CampusImageContainer>
				</TopLeft>
				<TopRight>
					<TopRightTop>
						<ItemId>#{id}</ItemId>
						<DeleteImage
							src={archiveIcon}
							alt="delete"
							onClick={onClickDelete}
						/>
					</TopRightTop>
					<TopRightBottom>
						<UpdateButton onClick={onClickUpdateButton}>
							<UpdateText>Update</UpdateText>
						</UpdateButton>
					</TopRightBottom>
				</TopRight>
			</CampusItemTop>
			<CampusItemBottom>
				<CampusDetailWrapper>
					{/* <CampusDetailIconBox>
						<CampusDetailIcon src={placeholderIcon} alt="Icon" />
					</CampusDetailIconBox> */}
					<CampusName>{name}</CampusName>
				</CampusDetailWrapper>
				<CampusDetailWrapper>
					<CampusDetailIconBox>
						<CampusDetailIcon src={placeholderIcon} alt="Icon" />
					</CampusDetailIconBox>
					<CampusDetailText>{address}</CampusDetailText>
				</CampusDetailWrapper>
				<CampusDetailWrapper>
					<CampusDetailIconBox>
						<CampusDetailIcon src={mailIcon} alt="Icon" />
					</CampusDetailIconBox>
					<CampusDetailText>{email}</CampusDetailText>
				</CampusDetailWrapper>
				<CampusDetailWrapper>
					<CampusDetailIconBox>
						<CampusDetailIcon src={phoneIcon} alt="Icon" />
					</CampusDetailIconBox>
					<CampusDetailText>{phone}</CampusDetailText>
				</CampusDetailWrapper>
			</CampusItemBottom>
		</CampusItemBox>
	);
}

export default ListCampusItem;

const CampusItemBox = styled.div`
	background: #fff;
	/* height: 400px; */
	width: auto;
	padding: 30px;
	margin-right: 10px;
	margin-bottom: 10px;
	border-radius: 10px;
	box-sizing: border-box;
	@media only screen and (max-width: 1280px) {
		padding: 20px;
	}
`;
const CampusItemTop = styled.div`
	display: flex;
	justify-content: space-between;
`;
const TopLeft = styled.div``;
const CampusImageContainer = styled.div`
	width: 150px;
	height: 150px;
	/* @media only screen and (max-width: 1280px) {
        width: 140px;
        height: 140px;
    } */
`;
const CampusImage = styled.img`
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 4px;
`;
const TopRight = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: end;
`;
const TopRightTop = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100px;
`;
const ItemId = styled.h5`
	font-family: Inter;
	font-size: 1rem;
	color: #aa99ca;
`;
const DeleteImage = styled.img`
	cursor: pointer;
`;
const TopRightBottom = styled.div``;
const UpdateButton = styled.div`
	padding: 12px 24px;
	background: #522e92;
	color: #ffffff;
	border-radius: 4px;
	cursor: pointer;
`;
const UpdateText = styled.p`
	font-size: 1.1rem;
`;
const CampusItemBottom = styled.div`
	margin-top: 30px;
`;
const CampusName = styled.h3`
	color: #000;
	font-weight: 600;
	line-height: 17px;
`;
const CampusDetailWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	&:last-child {
		margin-bottom: 0;
	}
`;
const CampusDetailIconBox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 35px;
	height: 35px;
	background: rgba(82, 46, 146, 0.49);
	border-radius: 50%;
`;
const CampusDetailIcon = styled.img``;
const CampusDetailText = styled.div`
	/* font-family: Inter; */
	margin-left: 15px;
	font-size: 1rem;
	color: #8c8888;
	font-style: normal;
	font-weight: 500;
	line-height: 17px;
`;
