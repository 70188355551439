import React, { useEffect, useState, useContext } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import visible from "../../assets/images/visible.svg";

import { useHistory, useLocation, useParams } from "react-router-dom";
import {
    UpdateLeadManagerService,
    GetLeadManagerService,
} from "../../service/leadManager";
import { baseConfig } from "./../../utils/config";
import { validatePassword, capitalizeString } from "./../../helpers/Functions";
import { Context } from "./../../contexts/Store";
import ProfilePicture from "../../components/ProfilePicture";

import openedEye from "../../assets/images/eye-open.svg";
import closedEye from "../../assets/images/eye-close.svg";
import AlertBox from "../../components/includes/AlertBox";
import SweetAlert from "../../components/includes/SweetAlert";

function UpdateLeadManagerAdmin() {
    const history = useHistory();
    const location = useLocation();
    const [data, setData] = useState(location?.state);
    const { params } = useParams();

    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [fieldName, setFieldName] = useState("");
    const [profile, setProfile] = useState(null);
    const [fetchedImage, setFetchedImage] = useState(null);
    const [target, setTarget] = useState("");

    const [id, setId] = useState();
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [userName, setUserName] = useState();
    const [employeeID, setEmployeeID] = useState("");
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [emailValidation, setEmailValidation] = useState(false);
    const [passwordValidate, setPasswordValidate] = useState({});
    const [pk, setPk] = useState();
    const { state } = useContext(Context);

    // sweetalert states and functions
    const [isSweetAlert, setIsSweetAlert] = useState(false);
    const [sweetAlertContent, setSweetAlertContent] = useState({
        title: "",
        message: "",
        icon: "",
        confirmButtonText: "",
        isCancelButton: false,
    });
    const handleSweetAlertConfirm = () => {
        history.push("/superadmin/sales-manager/");
    };
    // end of sweetalert

    // alert mui message
    const [alertMessage, setAlertMessage] = useState(null); // Track the message state

    const [form, setFormValues] = useState({});

    const criteria = passwordValidate.is_strong_password;

    const validate = (text) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(text) === false) {
            setEmailValidation(false);
            setEmail(text);
            return false;
        } else {
            setEmail(text);
            setEmailValidation(true);
        }
    };

    useEffect(() => {
        getInitialData();
    }, []);

    const getInitialData = async () => {
        let data = await GetLeadManagerService(params, state);
        if (data.StatusCode === 6000) {
            setFormValues(data.data.data);
            // setProfile(data.data.image);
            let details = data.data.data;
            setId(details.admin_id);
            setName(details.name);
            validate(details.user_data.email);
            setUserName(details.user_data.username);
            setPassword(details.password);
            setConfirmPassword(details.password);
            setFetchedImage(details.image);
            setEmployeeID(details.employee_id);
            setTarget(details.target);
        } else {
            setAlertMessage(data.data.message);
        }
    };

    // useEffect(() => {
    // 	setPasswordValidate(validatePassword(password));
    // }, [password]);

    const handleChangeValue = (key, value) => {
        setFormValues({ ...form, [key]: value });
    };

    // const handleSubmit = async () => {
    // 	let body = { id: form.id };
    // 	body.username = form?.username
    // 		? form?.username
    // 		: form?.user_data?.username;
    // 	body.email = form?.email ? form?.email : form?.user_data?.email;
    // 	body.name = form?.name ? form?.name : form?.user_data?.name;

    // 	if (
    // 		form["confirmPassword"] &&
    // 		form["password"] !== form["confirmPassword"]
    // 	) {
    // 		return alert("Incorrect password");
    // 	} else {
    // 		body.password = form.password;
    // 	}

    // 	let data = await UpdateLeadManagerService(body);
    // 	if (data.StatusCode === 6000) {
    // 		history.push("/superadmin/lead-manager");
    // 	} else {
    // 		alert(data.data.message);
    // 	}
    // };

    const handleUpdateForm = () => {
        let accessToken = state.user_details.access_token;

        var formdata = new FormData();
        formdata.append("name", name);
        formdata.append("email", email);
        formdata.append("username", userName);
        formdata.append("password", password);
        formdata.append("employee_id", employeeID);
        formdata.append("target", target);
        if (profile !== null) {
            formdata.append("image", profile);
        }

        baseConfig
            .put(`/leads/lead-manager/${params}/`, formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode == 6000) {
                    // history.push("/superadmin/sales-manager/");
                    setSweetAlertContent({
                        title: "Success",
                        message: "Sales Manager edited successfully",
                        icon: "success",
                        confirmButtonText: "Ok",
                        isCancelButton: false,
                    });
                    setIsSweetAlert(true);
                } else {
                    setAlertMessage(data.data.message);
                }
            })
            .catch((error) => {
                setAlertMessage(error);
            });
    };

    return (
        <Wrapper>
            <AlertBox
                message={alertMessage}
                setMessage={setAlertMessage}
                severity={
                    alertMessage && alertMessage.includes("successful")
                        ? "success"
                        : "error"
                }
                autoClose={true}
                onClose={() => setAlertMessage(null)}
            />
            <SweetAlert
                sweetAlertContent={sweetAlertContent}
                setSweetAlertContent={setSweetAlertContent}
                isSweetAlert={isSweetAlert}
                setIsSweetAlert={setIsSweetAlert}
                onConfirm={handleSweetAlertConfirm}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                }}
            >
                <div>
                    <Title fontSize="35x">Update Sales Manager</Title>
                    {/* <Text
                        style={{
                            margin: "12px 0",
                            fontFamily: "Inter",
                            fontWeight: "500",
                        }}
                        color="grey"
                        fontSize="18px"
                    >
                        Admins / Update Sales Manager
                    </Text> */}
                </div>

                {/* <div>
                    <Button
                        href="#"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0 22px",
                        }}
                        backgroundColor="white"
                        fontSize="15px"
                        padding="18px 57px"
                        onClick={(e) => {
                            e.preventDefault();
                            const form = document.getElementById("form");
                            form.reset();
                            setFormValues({});
                            setName("");
                            setEmail("");
                            setUserName("");
                            setPassword("");
                            setConfirmPassword("");
                        }}
                    >
                        <Text color="  #EE1451" fontSize="15px">
                            Clear Form
                        </Text>
                    </Button>
                </div> */}
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Container style={{ flexWrap: "wrap " }} width="100%">
                    <form id="form">
                        <div
                            style={{
                                marginBottom: "20px",
                            }}
                        >
                            <ProfilePicture
                                setFile={setProfile}
                                file={profile}
                                fetchedImage={fetchedImage}
                                setFetchedImage={setFetchedImage}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Input
                                value={id}
                                onChange={(value) => {
                                    setId(value);
                                    setError(false);
                                }}
                                name="Admin ID  (auto generated)"
                                // value="#0021"
                                disabled
                            />
                            <Input
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={name}
                                onChange={(value) => {
                                    setName(capitalizeString(value));
                                    setError(false);
                                }}
                                type="text"
                                name="Name"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Input
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={email}
                                onChange={(value) => {
                                    setEmail(value);
                                    setError(false);
                                }}
                                type="name"
                                name="Email"
                            />

                            <Input
                                onChange={(value) => {
                                    setEmployeeID(value);
                                    setError(false);
                                }}
                                type="name"
                                name="Employee ID"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={employeeID}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "20px",
                                justifyContent: "space-between ",
                            }}
                        >
                            <Input
                                onChange={(value) => {
                                    setTarget(value);
                                    setError(false);
                                }}
                                type="text"
                                name="Target (UAE)"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={target}
                            />
                            <Input
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={userName}
                                onChange={(value) => {
                                    setUserName(value);
                                    setError(false);
                                }}
                                type="type"
                                name="Username"
                            />

                            {/* <Input
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
								onChange={(value) => {
									setConfirmPassword(value);
									setError(false);
								}}
								onClickVisible={() =>
									handleChangeValue(
										"confirmPasswordShow",
										!form?.confirmPasswordShow
									)
								}
								// type="password"
								name="Confirm Password"
								value={confirmPassword}
								type={
									form?.confirmPasswordShow
										? "text"
										: "password"
								}
							/> */}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "20px",
                                justifyContent: "space-between ",
                            }}
                        >
                            {/* <Input
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
								value={userName}
								onChange={(value) => {
									setUserName(value);
									setError(false);
								}}
								type="type"
								name="Username"
							/> */}
                            <Input
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                onChange={(value) => {
                                    setPassword(value);
                                    setError(false);
                                }}
                                onClickVisible={() =>
                                    handleChangeValue(
                                        "passwordShow",
                                        !form?.passwordShow
                                    )
                                }
                                // type="password"
                                name="Password"
                                value={password}
                                icon={
                                    form?.passwordShow ? openedEye : closedEye
                                }
                                type={form?.passwordShow ? "text" : "password"}
                            />

                            {/* <Input
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
								onChange={(value) => {
									setConfirmPassword(value);
									setError(false);
								}}
								onClickVisible={() =>
									handleChangeValue(
										"confirmPasswordShow",
										!form?.confirmPasswordShow
									)
								}
								// type="password"
								name="Confirm Password"
								value={confirmPassword}
								type={
									form?.confirmPasswordShow
										? "text"
										: "password"
								}
							/> */}
                        </div>
                        <div style={{ display: "flex", marginTop: "21px" }}>
                            <Button
                                href="#"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                bacerkgroundColor="#5A2D8F"
                                fontSize="15px"
                                padding="21px 70px"
                                onClick={(e) => {
                                    if (name === "") {
                                        setError(true);
                                        setFieldName("Name");
                                        setErrorMessage("Enter name");
                                    } else if (email === "") {
                                        setError(true);
                                        setFieldName("Email");
                                        setErrorMessage(
                                            "Please enter a valid email"
                                        );
                                    } else if (userName === "") {
                                        setError(true);
                                        setFieldName("Username");
                                        setErrorMessage(
                                            "Please enter username"
                                        );
                                    } else if (password.length < 8) {
                                        setError(true);
                                        setFieldName("Password");
                                        setErrorMessage(
                                            "Be a minimum of 8 characters"
                                        );
                                    } else if (criteria === false) {
                                        setError(true);
                                        setFieldName("Password");
                                        setErrorMessage(
                                            "Strong passwords consist of a combination of uppercase and lowercase letters, numbers and special symbols"
                                        );
                                    }
                                    // else if (password !== confirmPassword) {
                                    // 	setError(true);
                                    // 	setFieldName("Confirm Password");
                                    // 	setErrorMessage(
                                    // 		"Both password must be same"
                                    // 	);
                                    // }
                                    else {
                                        e.preventDefault();
                                        handleUpdateForm();
                                    }
                                }}
                            >
                                <Text color="white" fontSize="15px">
                                    Submit
                                </Text>
                            </Button>

                            <Button
                                onClick={() => {
                                    history.push("/superadmin/sales-manager/");
                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 22px",
                                }}
                                backgroundColor="#F4F4F4"
                                fontSize="15px"
                                padding="21px 70px"
                            >
                                <Text color=" #522e92" fontSize="15px">
                                    Cancel
                                </Text>
                            </Button>
                        </div>
                    </form>
                </Container>
            </div>
        </Wrapper>
    );
}

export default UpdateLeadManagerAdmin;
