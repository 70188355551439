import React, { useEffect, useState, useContext } from "react";
import Button from "../../components/Button";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import addbutton from "../../assets/images/addbutton.svg";
import Text from "../../components/Text";
import ListCampusItem from "../../components/superAdmin/ListCampusItem";
import campusImage from "../../assets/images/list-campus-image.png";
import { DeleteCampusService, ListCampusService } from "../../service/campus";
import { useHistory } from "react-router-dom";
import search from "./../../assets/images/header-search.svg";
import "./style.css";
import styled from "styled-components";
import { Context } from "../../contexts/Store";
import DeleteModal from "./../../components/includes/DeleteModal";
import { baseConfig } from "./../../utils/config";
import ArchivedCampusItem from "../../components/superAdmin/ArchivedCampusItem";

function ArchivedCampus() {
    const [campuses, setCampuses] = useState([]);
    const history = useHistory();
    const [searchItem, setSearchItem] = useState("");
    const [filterItem, setFilterItem] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const { state } = useContext(Context);
    const [isModal, setModal] = useState(false);
    const [archiveId, setArchiveId] = useState("");

    const getArchiveCampus = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/campus-archive/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setCampuses(data?.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        getArchiveCampus();
    }, []);

    const handleArchive = (pk) => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .put(
                `campus/campus-details/${pk}/`,
                {
                    is_deleted: false,
                },
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                }
            )
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode == 6000) {
                    getArchiveCampus();
                } else if (StatusCode == 6001) {
                    alert(data.data.message);
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        if (campuses.length != 0) {
            setFilterItem(
                campuses.filter((items) => {
                    return items?.name
                        .toLowerCase()
                        .includes(searchItem.toLowerCase());
                })
            );
        }
    }, [searchItem, campuses]);

    return (
        <>
            <Wrapper>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "2.5%",
                    }}
                    className="top-title-wrapper"
                >
                    <Title className="top-title" fontSize="35px">
                        Archived Campus
                    </Title>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                        className="top-title-right"
                    >
                        <div className="top-title-search-div">
                            <form className="search-container">
                                <img src={search} alt="search" />
                                <input
                                    className="input-box"
                                    type="text"
                                    name="search"
                                    placeholder="Search for something"
                                    onChange={(e) =>
                                        setSearchItem(e.target.value)
                                    }
                                />
                            </form>
                        </div>
                        {/* <Button
							style={{
								display: "flex",
								alignItems: "center",
							}}
							bacerkgroundColor="#5A2D8F"
							fontSize="15px"
							// href="/superadmin/add-campus/"
							onClick={() =>
								history.push("/superadmin/add-campus/")
							}
							padding="10px 20px"
						>
							<img src={addbutton} alt="Logo" />
							<Text
								style={{ marginLeft: "12px" }}
								color="white"
								fontSize="15px"
							>
								Add Campus
							</Text>
						</Button> */}
                    </div>
                </div>
                {isLoading === false ? (
                    <>
                        {filterItem.length !== 0 ? (
                            <CampusListWrapper>
                                {filterItem?.map((campus) => {
                                    return (
                                        <ArchivedCampusItem
                                            key={campus.id}
                                            pk={campus.id}
                                            image={campus.image}
                                            id={campus.campus_id}
                                            name={campus.name}
                                            address={campus.address_line_1}
                                            email={campus.email}
                                            phone={campus.phone}
                                            setArchiveId={setArchiveId}
                                            handleArchive={handleArchive}
                                        />
                                    );
                                })}
                            </CampusListWrapper>
                        ) : (
                            <div className="no-result">
                                <text className="no-result-text">
                                    No results found{" "}
                                </text>
                            </div>
                        )}
                    </>
                ) : (
                    <Div>
                        <LoadingGif
                            src={
                                require("./../../assets/images/loading-buffering.gif")
                                    .default
                            }
                            alt="Loading"
                        />
                    </Div>
                )}
            </Wrapper>
            {/* {isModal && (
				<DeleteModal
					isModal={isModal}
					setModal={setModal}
					deleteId={deleteId}
					handleDeleteData={handleDeleteData}
				/>
			)} */}
        </>
    );
}

const CampusListWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    @media (max-width: 1080px) {
        grid-template-columns: 1fr 1fr;
    }
`;
const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
`;
const LoadingGif = styled.img`
    display: inline-block;
    width: 50px;
    @media (max-width: 980px) {
        width: 90px;
    }
`;

export default ArchivedCampus;
