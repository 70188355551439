import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import "./style.css";
import CampusImage from "../../assets/images/dashboard-campus.svg";
import SchoolImage from "../../assets/images/dashboard-school.svg";
import InstitutionsImage from "../../assets/images/dashboard-institutions.svg";
import DepartmentsImage from "../../assets/images/dashboard-departments.svg";
import SubDepartmentsImage from "../../assets/images/dashboard-subdepartment.svg";
import StaffImage from "../../assets/images/dashboard-staff.svg";
import DesignationImage from "../../assets/images/dashboard-designation.svg";
import StudentImage from "../../assets/images/dashboard-student.svg";
import DashboardItem from "../../components/superAdmin/DashboardItem";
import DashboardListItem from "../../components/superAdmin/DashboardListItem";
import DashboardListData from "../../components/superAdmin/DashboardListData";
import React, { useEffect, useState, useContext } from "react";
import { GetSuperAdminDashboardService } from "../../service/dashboard";
import { ListLeadsService } from "../../service/leads";
import { Context } from "./../../contexts/Store";
import { Pie, Line } from "react-chartjs-2";
import styled from "styled-components";
import Container from "../../components/Container";
import Text from "../../components/Text";
import Progress_bar from "../../components/progressBar/progress";
import Profile from "../../assets/images/profile.svg";

function MainDashboard() {
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [lineChart, setLineChart] = useState();

    const [dashboardData, setDashboardData] = useState();
    const [studentList, setStudentDetails] = useState([]);
    const [studentPagination, setStudentDetails1] = useState([]);

    const { state } = useContext(Context);

    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = DashboardListData.slice(
        indexOfFirstPost,
        indexOfLastPost
    );

    // Change page
    const paginate = (pageNumber) => {
        if (
            pageNumber > 0 &&
            pageNumber <= Math.ceil(DashboardListData.length / postsPerPage)
        ) {
            setCurrentPage(pageNumber);
        }
    };

    const getDasboardData = async () => {
        let data = await GetSuperAdminDashboardService(state);
        if (data.StatusCode === 6000) {
            setDashboardData(data.data?.super_admin_data);
        } else {
            alert(data.data.message);
        }
    };

    const getStudentDetails = async () => {
        let data = await ListLeadsService(state, "status=Converted");
        if (data.StatusCode === 6000) {
            setStudentDetails1(data.data?.paginator);
            setStudentDetails(data.data?.data);
        } else {
            alert(data.data.message);
        }
    };

    useEffect(() => {
        getDasboardData();
        getStudentDetails();
    }, []);

    const pieData = (canvas) => {
        const ctx = canvas.getContext("2d");
        const g1 = ctx.createLinearGradient(0, 0, 0, 400);
        g1.addColorStop(1, "rgba(199, 206, 255, 1)");
        g1.addColorStop(0, "rgba(199, 206, 255, 0)");
        const g2 = ctx.createLinearGradient(0, 0, 0, 400);
        g2.addColorStop(1, "rgba(90, 106, 207, 1)");
        g2.addColorStop(0, "rgba(90, 106, 207, 0)");
        return {
            datasets: [
                {
                    label: "# of Contribution",
                    data: [10, 15],
                    backgroundColor: [g1, g2],
                    border: "none",
                },
            ],
        };
    };

    const lineData = () => {
        setLineChart({
            labels: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
            datasets: [
                {
                    label: "Income",
                    data: [33, 53, 85, 41, 44, 65, 35, 51, 54, 7, 41, 44],
                    borderColor: "#5F2C8E",
                    borderWidth: 4,
                    lineTension: 0.3,
                },
                {
                    label: "Expense",
                    data: [33, 25, 35, 51, 54, 7, 20, 53, 85, 41, 44],
                    borderColor: "#808080",
                    borderWidth: 4,
                    lineTension: 0.3,
                },
            ],
        });
    };

    useEffect(() => {
        lineData();
    }, []);

    const options = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
        },
    };

    return (
        <Wrapper>
            <Title fontSize="35px">Dashboard</Title>
            <MainDiv
                style={
                    {
                        // // display: "grid",
                        // // gridTemplateColumns: "1fr 1fr 1fr",
                        // marginTop: "15px",
                        // display: "flex",
                        // justifyContent: "space-between",
                        // flexWrap: "wrap ",
                    }
                }
            >
                <DashboardItem
                    images={CampusImage}
                    title="Campus"
                    num={dashboardData?.campuses_count}
                    type="main-dashboard-admins"
                />
                <DashboardItem
                    images={SchoolImage}
                    title="Universities"
                    num={dashboardData?.universities_count}
                />
                <DashboardItem
                    images={InstitutionsImage}
                    title="Institutions"
                    num={dashboardData?.campuses_count}
                />
                <DashboardItem
                    images={DepartmentsImage}
                    title="Departments"
                    num={dashboardData?.departments_count}
                />
                <DashboardItem
                    images={SubDepartmentsImage}
                    title="Sub Departments"
                    num={dashboardData?.sub_departments_count}
                />
                <DashboardItem
                    images={StaffImage}
                    title="Staff"
                    num={dashboardData?.staffs_count}
                />
                <DashboardItem
                    images={DesignationImage}
                    title="Designation"
                    num={dashboardData?.designations_count}
                />
                <DashboardItem
                    images={StudentImage}
                    title="Students"
                    num={dashboardData?.students_count}
                />
            </MainDiv>
            <Container width="100%" margin="30px 0">
                <Title fontSize="25px" color="#000" margin="0 0 20px">
                    University incomes
                </Title>
                <Division>
                    <SubDiv>
                        <Sub background="#5F2C8E" />
                        <SubText>Income </SubText>
                    </SubDiv>
                    <SubDiv>
                        <Sub background="#808080" />
                        <SubText>Expense</SubText>
                    </SubDiv>
                </Division>
                <div style={{ width: "100%" }}>
                    <div style={{ width: "100%", height: "100%" }}>
                        <Line
                            data={lineChart}
                            options={options}
                            height="100%"
                        />
                    </div>
                </div>
            </Container>
            <MiddleContent>
                <Container width="48.5%" margin="0">
                    <SubTitle>Fees and Donations</SubTitle>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div style={{ height: "50%", width: "50%" }}>
                            <Pie
                                data={pieData}
                                options={{
                                    responsive: true,
                                    aspectRatio: 1,
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                alignSelf: "flex-end",
                                padding: "50px 0",
                                width: "30%",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    marginBottom: "20px",
                                }}
                            >
                                <div
                                    style={{
                                        height: "20px",
                                        width: "20px",
                                        backgroundColor:
                                            "rgba(90, 106, 207, 1)",
                                        marginRight: "10px",
                                    }}
                                ></div>
                                <Text fontSize="15px">Donation</Text>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                }}
                            >
                                <div
                                    style={{
                                        height: "20px",
                                        width: "20px",
                                        backgroundColor:
                                            "rgba(199, 206, 255, 1)",
                                        marginRight: "10px",
                                    }}
                                ></div>
                                <Text fontSize="15px">Fees</Text>
                            </div>
                        </div>
                    </div>
                </Container>
                <Container
                    width="50%"
                    margin="0"
                    // style={{ height: "70vh", overflow: "scroll" }}
                >
                    <SubTitle>Sales Person’s Performance</SubTitle>
                    <>
                        <Container
                            style={{
                                flexWrap: "wrap",
                                borderRadius: "23px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                            width="100%"
                            background="#F9F9F9"
                            margin="0 0 20px"
                            padding="15px"
                        >
                            <div
                                style={{
                                    flexWrap: "wrap",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <ProfileImage
                                    src={Profile}
                                    alt="Logo"
                                    style={{}}
                                />
                                <div>
                                    <Text
                                        className="progress-bar-text"
                                        color="black"
                                        fontSize="16px"
                                    >
                                        #11
                                    </Text>
                                    <Text
                                        className="progress-bar-text"
                                        color="black"
                                        fontSize="14px"
                                    >
                                        john
                                    </Text>
                                </div>
                            </div>
                            <div style={{ width: "55%" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginBottom: "20px",
                                    }}
                                >
                                    <Text
                                        className="progress-bar-text"
                                        style={{ width: "100px" }}
                                        fontSize="15px"
                                        color="black"
                                    >
                                        Success Rate
                                    </Text>
                                    <Text
                                        className="progress-bar-text"
                                        fontSize="15px"
                                        color="black"
                                    >
                                        20%
                                    </Text>
                                </div>
                                <Progress_bar
                                    bgcolor=" #522E92"
                                    progress={20}
                                    height={10}
                                    width="100%"
                                />
                            </div>
                        </Container>
                    </>
                </Container>
            </MiddleContent>
        </Wrapper>
    );
}

export default MainDashboard;

const MainDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    margin-top: 15px;
    @media only screen and (max-width: 1280px) {
        grid-gap: 15px;
    }
    @media only screen and (max-width: 900px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
    }
`;
const MiddleContent = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
`;
const PieDiv = styled.div`
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    width: 45%;
`;
const SubHeading = styled.h4`
    color: #000;
    font-size: 20px;
    font-weight: 600;
`;
const Division = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;
const SubDiv = styled.div`
    display: flex;
    align-items: center;
    margin-right: 30px;
`;
const Sub = styled.div`
    height: 20px;
    width: 20px;
    background: ${(props) => (props.background ? props.background : "#fff")};
    margin-right: 5px;
`;

const SubText = styled.h5`
    color: rgba(0, 0, 0, 0.7);
    font-weight: 500;
    font-size: 14px;
`;
const ProfileImage = styled.img`
    margin-right: 5px;
    @media only screen and (max-width: 900px) {
        width: 40px;
        height: 40px;
        object-fit: contain;
    }
`;
const SubTitle = styled.h3`
    font-size: 25px;
    color: #000;
    margin: 0 0 60px;
    font-weight: 600;
    @media only screen and (max-width: 900px) {
        font-size: 22px;
    }
`;
