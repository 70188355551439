import React, { useState, useEffect, useContext } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import { Context } from "../../contexts/Store";
import styled from "styled-components";
import Input from "../../components/Input/input";
import SelectTeamLeaderModal from "./SelectTeamLeaderModal";
import SelectTeamMemberModal from "./SelectTeamMemberModal";
import { baseConfig } from "./../../utils/config";
import moment from "moment";
import TD from "../../components/TD";
import { useHistory, useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import ProfilePicture from "../../components/includes/ProfilePicture";
import loadingIcon from "../../assets/lottie/loading.gif";

import profileImage from "./../../assets/images/upload-image.svg";
// import editIcon from "./../../assets/images/edit-icon.svg";
import editIcon from "./../../assets/images/edit-icon1.svg";
import deleteIcon from "../../assets/images/delete.svg";

function AddSalesTeam() {
    const { state } = useContext(Context);
    const [teamName, setTeamName] = useState("");
    const [remark, setRemark] = useState("");
    const [selectLeaderModal, setSelectedLeaderModal] = useState(false);
    const [selectMemberModal, setSelectedMemberModal] = useState(false);
    const [salesPerson, setSalesPerson] = useState([]);
    const [teamID, setTeamID] = useState("");
    const [date, setDate] = useState(moment(new Date()).format("yyyy-MM-DD"));
    const [leader, setLeader] = useState(null);
    const [leaderName, setLeaderName] = useState();
    const [members, setMembers] = useState([]);
    const history = useHistory();
    const [fetchedImage, setFetchedImage] = useState(null);
    const [file, setFile] = useState(null);
    const [teamDetail, setTeamDetail] = useState();
    const [isLoading, setIsLoading] = useState(false);
    // const [search, setSearch] = useState(null);
    //crop image
    const [cropImage, setCropImage] = useState(null);
    const { edit_id } = useParams();

    // alert box
    const [alertStatus, setAlertStatus] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [isAlert, setIsAlert] = useState(false);

    const onImageChange = (e) => {
        setFile(e.target.files[0]);

        if (e.target.files && e.target.files[0]) {
            setFetchedImage(URL.createObjectURL(e.target.files[0]));
        }
        // editPic();
    };

    // api submit sales team
    const createSalesTeam = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        // formdata.append("image", file);
        formdata.append("name", teamName);
        formdata.append("leader", leader?.id);
        formdata.append("date", date);
        formdata.append("remark", remark);

        const data = members.map((item) => {
            return item.id;
        });

        // formdata.append("members", JSON.stringify(data));

        var method = edit_id ? baseConfig.put : baseConfig.post;
        var URL = edit_id ? `/sales-persons/team/${edit_id}/` : `/sales-persons/team/`;

        method(URL, formdata, {
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
            },
        })
            // baseConfig
            //     .post("/sales-persons/team/", formdata, {
            //         headers: {
            //             Authorization: "Bearer " + accessToken,
            //         },
            //     })
            .then((response) => {
                let { StatusCode, message, data } = response.data;
                if (StatusCode == 6000) {
                    history.push("/salesmanager/salesteams/");
                } else {
                    setAlertStatus("error");
                    setIsAlert(true);
                    setAlertMessage("Form submition failed");
                }
            })
            .catch((error) => {
                let { StatusCode, data } = error.response.data;
                setAlertStatus("error");
                setIsAlert(true);
                setAlertMessage(data.message);
            });
    };

    //list of sales Persons
    const getSalesPerson = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/sales-persons/sales-persons-list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    choice: "team_build",
                    // search: search,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setSalesPerson(data.data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const update = (val) => {
        setMembers([...members, val]);
        return members;
    };

    const getTeamDetails = () => {
        setIsLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/sales-persons/team/${edit_id}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                // params: {
                //     choice: "team_build",
                //     search: search,
                // },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setTeamID(data.team_id);
                    setTeamName(data.name);
                    setLeader(data.leader_data);
                    setRemark(data.remark);
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 200);

                    // setMembers(data.members_data);

                    // const arr = data.members_data.map((data) => {
                    //     return data.id;
                    // });
                    // setMembers(arr);
                } else {
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                alert(error);
                setIsLoading(false);
            });
    };

    // api for team id
    const getTeamID = () => {
        // setIsLoading(true);

        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/sales-persons/get-team-id/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setTeamID(data.team_id);
                    setTimeout(() => {
                        // setIsLoading(false);
                    }, 200);
                } else {
                    // setIsLoading(false);
                }
            })
            .catch((error) => {
                // setIsLoading(false);
                alert(error);
            });
    };

    useEffect(() => {
        getSalesPerson();
        getTeamID();
        if (edit_id) {
            getTeamDetails();
        }
    }, []);
    // useEffect(() => {
    //     getSalesPerson();
    // }, [search]);

    // close alert box
    const handleClose = () => {
        setIsAlert(false);
        setAlertStatus("");
    };

    const AlertBox = ({ status }) => {
        return (
            <Stack spacing={2} sx={{ width: "100%" }}>
                <Snackbar
                    open={isAlert}
                    autoHideDuration={5000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    {/* {status === "success" ? (
						<Alert
							onClose={handleClose}
							severity={status}
							sx={{ width: "100%" }}
						>
							Lead added success — <strong>check it out!</strong>
						</Alert>
					) : (
						<Alert
							onClose={handleClose}
							severity={status}
							sx={{ width: "100%" }}
						>
							Lead added failed — <strong>check it out!</strong>
						</Alert>
					)} */}
                    <Alert onClose={handleClose} severity={status} sx={{ width: "100%" }}>
                        {alertMessage} — <strong>check it out!</strong>
                    </Alert>
                </Snackbar>
            </Stack>
        );
    };

    return (
        <>
            {!isLoading ? (
                <Wrapper>
                    <AlertBox status={alertStatus} />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "30px",
                        }}
                    >
                        <Title fontSize="35px">{`${edit_id ? "Edit Sales Team" : "Create Sales Team"}`}</Title>
                    </div>
                    <FormContainer>
                        {/* <PhotoContainer>
                    <ImageContainer>
                        {fetchedImage !== null ? (
                            <ProfileImage src={fetchedImage} alt="Profile" />
                        ) : (
                            <ProfileImageDefault
                                src={profileImage}
                                alt="image"
                            />
                        )}

                        <EditIconContainer htmlFor="image-file">
                            <EditIcon src={editIcon} alt="icon" />
                        </EditIconContainer>
                        <input
                            type="file"
                            id="image-file"
                            accept="image/png, image/gif, image/jpeg"
                            style={{ display: "none" }}
                            onChange={onImageChange}
                            onClick={(e) => {
                                e.target.value = null;
                            }}
                        />
                    </ImageContainer>
                </PhotoContainer> */}
                        <SubContainer>
                            <Container>
                                <Heading for="teamID">Team ID</Heading>
                                <Input forValue="teamID" width="100%" value={teamID} disabled />
                            </Container>
                            <Container>
                                <Heading for="date">Date of creation</Heading>
                                <Input
                                    // forValue="date"
                                    width="100%"
                                    // type="date"
                                    // onChange={setDate}
                                    disabled
                                    value={date}
                                />
                            </Container>
                        </SubContainer>
                        <SubContainer>
                            <Container>
                                <Heading for="teamName">Team Name</Heading>
                                <Input forValue="teamName" width="100%" onChange={setTeamName} value={teamName} />
                            </Container>
                            {/* {leader ? (
                        <h1>Selected</h1>
                    ) : ( */}
                            <Container>
                                <Heading for="leader">Choose Team Leader</Heading>
                                <CustomInputDiv>
                                    <CustomInput
                                        disabled
                                        // onChange={(e) => setLeader(e.target.value)}
                                        id="leader"
                                        value={leader?.name}
                                    />
                                    <ChooseText
                                        onClick={() => {
                                            setSelectedLeaderModal(true);
                                        }}
                                    >
                                        Choose
                                    </ChooseText>
                                </CustomInputDiv>
                            </Container>
                            {/* )} */}
                        </SubContainer>
                        <SubContainer>
                            <Container width="100%">
                                <Heading for="remark">Remark</Heading>
                                <TextArea id="remark" onChange={(e) => setRemark(e.target.value)} value={remark} />
                            </Container>
                        </SubContainer>
                        {/* <MemberDiv>
                    <Heading>Team Members</Heading>
                    {members.length === 0 && (
                        <IndicatorText color="#8561C6">
                            No members added to the team
                        </IndicatorText>
                    )}
                </MemberDiv>
                <SalesDiv>
                    {
                        // salesPerson.map((data) =>
                        members.map((item) => (
                            // item === data.id &&
                            <SalesBox>
                                <Heading color="#8561C6">{item.name}</Heading>
                                <RemoveDiv
                                    onClick={() => {
                                        setMembers(
                                            members.filter(
                                                (memb) => memb.id !== item.id
                                            )
                                        );
                                    }}
                                    bordeColor="#fff"
                                    background="#fff"
                                >
                                    <ButtonText color="#FF0048">
                                        Remove
                                    </ButtonText>
                                </RemoveDiv>
                            </SalesBox>
                        ))
                        // )
                    }
                </SalesDiv> */}

                        {/* {members.length > 0 && (
                    <AddedMembersBox>
                        {members.map((member, index) => (
                            <EachMember>
                                <MemberName>
                                    {index + 1}. {member.name}
                                </MemberName>
                                <DeleteImg src={deleteIcon} alt="icon" />
                            </EachMember>
                        ))}
                    </AddedMembersBox>
                )} */}

                        {/* <ButtonDiv
                    bordeColor="#EEEAF4"
                    onClick={() => {
                        setSelectedMemberModal(true);
                    }}
                >
                    <ButtonIcon
                        src={
                            require("./../../assets/images/Button.svg").default
                        }
                        alt="icon"
                    />
                    <ButtonText color="#522F91">Add Members</ButtonText>
                </ButtonDiv> */}

                        <ActionDiv>
                            <ButtonDiv
                                bordeColor="#fff"
                                background="#fff"
                                onClick={() => {
                                    history.push("/salesmanager/salesteams/");
                                }}
                            >
                                <ButtonText color="#FF0048">Cancel</ButtonText>
                            </ButtonDiv>
                            <ButtonDiv
                                onClick={() => {
                                    createSalesTeam();
                                }}
                                bordeColor="#522E92"
                                background="#522E92"
                            >
                                <ButtonText color="#fff">Save</ButtonText>
                            </ButtonDiv>
                        </ActionDiv>
                    </FormContainer>

                    {selectLeaderModal && (
                        <SelectTeamLeaderModal
                            setSelectedModal={setSelectedLeaderModal}
                            salesPersons={salesPerson}
                            setLeader={setLeader}
                            leader={leader}
                        />
                    )}
                    {selectMemberModal && (
                        <SelectTeamMemberModal
                            setSelectedModal={setSelectedMemberModal}
                            setMembers={setMembers}
                            members={members}
                            salesPerson={salesPerson}
                        />
                    )}
                </Wrapper>
            ) : (
                <LoaderContainer>
                    <LoadingGif src={loadingIcon} alt="icon" />
                </LoaderContainer>
            )}
        </>
    );
}

export default AddSalesTeam;

const FormContainer = styled.div`
    /* height: 90vh; */
    /* width: 100%; */
    background: #fff;
    border-radius: 8px;
    padding: 20px;
`;
const SubContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    :last-child {
        margin-bottom: 0;
    }
`;
const Container = styled.div`
    /* width: 49%; */
    width: ${(props) => (props.width ? props.width : "49%")};
`;
const Heading = styled.label`
    color: ${(props) => (props.color ? props.color : "#808080")};
    font-size: 16px;
    cursor: pointer;
    user-select: none;
    display: block;
`;
const CustomInputDiv = styled.div`
    position: relative;
`;

const CustomInput = styled.input`
    width: 100%;
    /* height: 100px; */
    resize: vertical;
    border-radius: 15px;
    border: 1px solid #dfeaf2;
    margin-top: 12px;
    padding: 20px 15px 20px 20px;
    box-sizing: border-box;
    font-family: "Inter";
    font-size: 16px;
    :focus-visible {
        outline: none;
        background: #fbfbfb;
        border: 1px solid #2d60ff;
    }
`;

const TextArea = styled.textarea`
    width: 100%;
    height: 100px;
    resize: vertical;
    border-radius: 15px;
    border: 1px solid #dfeaf2;
    margin-top: 12px;
    padding: 12px;
    box-sizing: border-box;
    font-family: "Inter";
    font-size: 16px;
    :focus-visible {
        outline: none;
        background: #fbfbfb;
        border: 1px solid #2d60ff;
    }
`;
const ChooseText = styled.span`
    color: #8561c6;
    font-size: 18px;
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
`;
const MemberDiv = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`;
const SalesDiv = styled.div`
    margin-bottom: 20px;
    padding: 0 20px;
    /* background-color: red; */
    width: 30%;
`;
const SalesBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
`;
const IndicatorText = styled(Heading)`
    margin-left: 15px;
`;

const ButtonDiv = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid;
    border-color: ${(props) => (props.bordeColor ? props.bordeColor : "#fff")};
    border-radius: 5px;
    height: 40px;
    padding: 0 15px;
    margin-right: 15px;
    cursor: pointer;
    user-select: none;
    width: fit-content;
    background-color: ${(props) => (props.background ? props.background : "#eeeaf4")};

    &:last-child {
        margin-right: 0;
    }
`;
const ButtonIcon = styled.img`
    margin-right: 4px;
`;
const ButtonText = styled.span`
    color: ${(props) => (props.color ? props.color : "#fff")};
`;
const ActionDiv = styled.div`
    display: flex;
    align-items: center;
    margin-top: 40px;
`;
const RemoveDiv = styled(ButtonDiv)`
    height: 30px;
`;
const PhotoContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 20px 0 0 20px;
    margin-bottom: 15px;
`;
const ImageContainer = styled.div`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: relative;
    @media (max-width: 1080px) {
        width: 125px;
        height: 125px;
    }
    @media (max-width: 980px) {
        margin: 0 auto;
    }
`;
const ProfileImage = styled.img`
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 50%;
    object-fit: cover;
`;
const ProfileImageDefault = styled.img`
    width: 95%;
    height: 95%;
    display: block;
    border-radius: 50%;
    object-fit: cover;
`;

const EditIcon = styled.img`
    display: block;
    width: 50%;
    height: 50%;
    transition: 1.5s;
    /* filter: invert(0.1); */
    /* animation: wiggle 2s linear infinite; */
`;
const EditIconContainer = styled.label`
    position: absolute;
    top: 10%;
    right: 3%;
    background: #ffffff;
    box-shadow: 1px -1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 1s;
    /* @media (max-width: 1080px) {
        width: 27px;
        height: 27px;
    } */
    &:hover {
        ${EditIcon} {
            animation: none;
        }
    }
`;
const AddedMembersBox = styled.div``;
const EachMember = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 14px;
`;
const MemberName = styled.h6`
    font-size: 16px;
`;
const DeleteImg = styled.img`
    width: 21px;
    display: block;
    object-fit: cover;
    cursor: pointer;
    margin-left: 20px;
`;
const LoaderContainer = styled.div`
    height: calc(100vh - 120px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LoadingGif = styled.img`
    width: 60px;
    display: block;
    object-fit: cover;
`;
// const EditIcon = styled.img``;
// const EditIcon = styled.img``;
// const EditIcon = styled.img``;
