import dashboard from "../../assets/images/dashboard-menu.svg";
import campus from "../../assets/images/campus-menu.svg";
import admin from "../../assets/images/admin-menu.svg";
import university from "../../assets/images/university.svg";
import webinar from "../../assets/images/webinar.svg";
import notices from "../../assets/images/notices.svg";
// import notices from "../../assets/images/help.png";

const navBarData = [
    {
        img: dashboard,
        text: "Dashboard",
        link: "/superadmin/",
    },
    {
        img: university,
        text: "University",
        inner: [
            {
                text: "University",
                link: "/superadmin/list-university/",
                add: true,
                addLink: "/superadmin/add-university/",
            },
            // {
            // 	text: "Archived Campus",
            // 	link: "/superadmin/archived-campus/",
            // 	// add: true,
            // 	// addLink: "/superadmin/add-campus",
            // },
        ],
    },
    // {
    //     img: campus,
    //     text: "Campus",
    //     inner: [
    //         {
    //             text: "Campus",
    //             link: "/superadmin/list-campus/",
    //             add: true,
    //             addLink: "/superadmin/add-campus/",
    //         },
    //         {
    //             text: "Archived Campus",
    //             link: "/superadmin/archived-campus/",
    //             // add: true,
    //             // addLink: "/superadmin/add-campus",
    //         },
    //     ],
    // },
    {
        img: admin,
        text: "Admins",
        inner: [
            {
                text: "University Admin",
                link: "/superadmin/university-admin/",
                add: true,
                addLink: "/superadmin/add-university-admin/",
            },
            // {
            //     text: "Campus Admins",
            //     link: "/superadmin/campus-admins/",
            //     add: true,
            //     addLink: "/superadmin/add-campus-admin/",
            // },
            {
                text: "Sales Manager",
                link: "/superadmin/sales-manager/",
                add: true,
                addLink: "/superadmin/add-sales-manager/",
            },
            {
                text: "Lead Generator",
                link: "/superadmin/lead-generator/",
                add: true,
                addLink: "/superadmin/add-lead-generator/",
            },
            {
                text: "Admission Consultant",
                link: "/superadmin/admission-consultant/",
                add: true,
                addLink: "/superadmin/add-admission-consultant/",
            },
            {
                text: "Finance Manager",
                link: "/superadmin/finance-manager/",
                add: true,
                addLink: "/superadmin/add-finance-manager/",
            },
            {
                text: "Finance Executive",
                link: "/superadmin/finance-executives/",
                add: true,
                addLink: "/superadmin/add-finance-executive/",
            },
        ],
    },
    // {
    // 	img: student,
    // 	text: "Students",
    // 	inner: [
    // 		{
    // 			text: "Students",
    // 			link: "/superadmin/list-students/",
    // 			add: true,
    // 			addLink: "/superadmin/add-student/",
    // 		},
    // 	],
    // },
    {
        img: webinar,
        text: "Webinar",
        link: "/superadmin/webinar/",
    },
    {
        img: notices,
        text: "FAQ's",
        link: "/superadmin/faq/",
    },
];
export default navBarData;
