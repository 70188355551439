import React, { useEffect, useState, useContext } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import visible from "../../assets/images/visible.svg";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
    UpdateLeadGeneratorService,
    GetLeadGeneratorService,
} from "../../service/leadGenerator";
import openedEye from "../../assets/images/eye-open.svg";
import closedEye from "../../assets/images/eye-close.svg";
import { baseConfig } from "./../../utils/config";
import { validatePassword, capitalizeString } from "./../../helpers/Functions";
import { Context } from "./../../contexts/Store";
import ProfilePicture from "../../components/ProfilePicture";
import AlertBox from "../../components/includes/AlertBox";
import SweetAlert from "../../components/includes/SweetAlert";

function UpdateLeadGeneratorAdmin() {
    const history = useHistory();
    const location = useLocation();
    const [data, setData] = useState(location?.state);
    const [form, setFormValues] = useState({});
    const [profile, setProfile] = useState(null);
    const [fetchedImage, setFetchedImage] = useState(null);

    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [fieldName, setFieldName] = useState("");

    const [id, setId] = useState();
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [employeeID, setEmployeeID] = useState("");
    const [userName, setUserName] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [emailValidation, setEmailValidation] = useState(false);
    const [passwordValidate, setPasswordValidate] = useState({});
    const [pk, setPk] = useState();
    const { params } = useParams();
    const [salesManagerName, setSalesManagerName] = useState("");
    const [salesManagers, setSalesManagers] = useState([]);

    const { state } = useContext(Context);
    // sweetalert states and functions
    const [isSweetAlert, setIsSweetAlert] = useState(false);
    const [sweetAlertContent, setSweetAlertContent] = useState({
        title: "",
        message: "",
        icon: "",
        confirmButtonText: "",
        isCancelButton: false,
    });
    const handleSweetAlertConfirm = () => {
        history.push("/superadmin/lead-generator");
    };
    // end of sweetalert

    // alert mui message
    const [alertMessage, setAlertMessage] = useState(null); // Track the message state

    const validate = (text) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(text) === false) {
            setEmailValidation(false);
            setEmail(text);
            return false;
        } else {
            setEmail(text);
            setEmailValidation(true);
        }
    };

    const criteria = passwordValidate.is_strong_password;

    const getLeadManagerList = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/leads/lead-manager/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode === 6000) {
                    setSalesManagers(data.data);
                } else {
                    alert(message);
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        getInitialData();
        getLeadManagerList();
    }, []);

    const getInitialData = async () => {
        let data = await GetLeadGeneratorService(params, state);
        if (data.StatusCode === 6000) {
            setFormValues(data.data.data);
            let details = data.data.data;
            setId(details.admin_id);
            setName(details.name);
            validate(details.user_data.email);
            setUserName(details.user_data.username);
            setPassword(details.password);
            setConfirmPassword(details.password);
            setFetchedImage(details.image);
            setEmployeeID(details.employee_id);
            setSalesManagerName(details.lead_manager);
        } else {
            alert(data.data.message);
        }
    };

    const handleChangeValue = (key, value) => {
        setFormValues({ ...form, [key]: value });
    };

    // useEffect(() => {
    // 	setPasswordValidate(validatePassword(password));
    // }, [password]);

    const handleUpdateForm = () => {
        let accessToken = state.user_details.access_token;

        var formdata = new FormData();
        formdata.append("name", name);
        formdata.append("email", email);
        formdata.append("username", userName);
        formdata.append("password", password);
        formdata.append("lead_manager", salesManagerName);
        formdata.append("employee_id", employeeID);
        if (profile !== null) {
            formdata.append("image", profile);
        }

        baseConfig
            .put(`/leads/lead-generator/${params}/`, formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode == 6000) {
                    setSweetAlertContent({
                        title: "Success",
                        message: "Lead Generator edited successfully",
                        icon: "success",
                        confirmButtonText: "Ok",
                        isCancelButton: false,
                    });
                    setIsSweetAlert(true);
                } else {
                    setAlertMessage(data.data.message);
                }
            })
            .catch((error) => {
                setAlertMessage(error.response.data.data.message);
            });
    };
    return (
        <Wrapper>
            <AlertBox
                message={alertMessage}
                setMessage={setAlertMessage}
                severity={
                    alertMessage && alertMessage.includes("successful")
                        ? "success"
                        : "error"
                }
                autoClose={true}
                onClose={() => setAlertMessage(null)}
            />
            <SweetAlert
                sweetAlertContent={sweetAlertContent}
                setSweetAlertContent={setSweetAlertContent}
                isSweetAlert={isSweetAlert}
                setIsSweetAlert={setIsSweetAlert}
                onConfirm={handleSweetAlertConfirm}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                }}
            >
                <div>
                    <Title fontSize="35x">Update Lead Generator</Title>
                    {/* <Text
                        style={{
                            margin: "12px 0px",
                            fontFamily: "Inter",
                            fontWeight: "500",
                        }}
                        color="grey"
                        fontSize="18px"
                    >
                        Admins / Update Lead Generator
                    </Text> */}
                </div>

                {/* <div>
                    <Button
                        href="#"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0 22px",
                        }}
                        backgroundColor="white"
                        fontSize="15px"
                        padding="18px 57px"
                        onClick={(e) => {
                            e.preventDefault();
                            const form = document.getElementById("form");
                            form.reset();
                            setFormValues({});
                            setName("");
                            setEmail("");
                            setUserName("");
                            setPassword("");
                            setConfirmPassword("");
                        }}
                    >
                        <Text color="  #EE1451" fontSize="15px">
                            Clear Form
                        </Text>
                    </Button>
                </div> */}
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Container style={{ flexWrap: "wrap " }} width="100%">
                    <form id="form">
                        <div
                            style={{
                                marginBottom: "20px",
                            }}
                        >
                            <ProfilePicture
                                setFile={setProfile}
                                file={profile}
                                fetchedImage={fetchedImage}
                                setFetchedImage={setFetchedImage}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Input
                                value={id}
                                onChange={(value) => {
                                    setId(value);
                                    setError(false);
                                }}
                                type="text"
                                name="Admin ID  (auto generated)"
                                // value="#0021"
                                disabled
                            />
                            {/* <Input
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
								value={name}
								onChange={(value) => {
									setName(capitalizeString(value));
									setError(false);
								}}
								type="text"
								name="Name"
							/> */}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            {/* <Input
								value={id}
								onChange={(value) => {
									setId(value);
									setError(false);
								}}
								type="text"
								name="Admin ID  (auto generated)"
								// value="#0021"
								disabled
							/> */}
                            <Input
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={name}
                                onChange={(value) => {
                                    setName(capitalizeString(value));
                                    setError(false);
                                }}
                                type="text"
                                name="Name"
                            />
                            <SelectInput
                                name="Choose sales manager"
                                type="text"
                                placeholder="Choose sales manager"
                                onChange={(value) => {
                                    setSalesManagerName(value);
                                    setError(false);
                                }}
                                options={salesManagers ? salesManagers : []}
                                selectName={"name"}
                                selectValue={"id"}
                                value={salesManagerName}
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                defaultValue="Select a sales manager"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Input
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={email}
                                onChange={(value) => {
                                    setEmail(value);
                                    setError(false);
                                }}
                                type="name"
                                name="Email"
                            />
                            <Input
                                onChange={(value) => {
                                    setEmployeeID(value);
                                    setError(false);
                                }}
                                type="name"
                                name="Employee ID"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={employeeID}
                            />
                            {/* <Input
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
								value={userName}
								onChange={(value) => {
									setUserName(value);
									setError(false);
								}}
								type="type"
								name="Username"
							/> */}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "20px",
                                justifyContent: "space-between ",
                            }}
                        >
                            <Input
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={userName}
                                onChange={(value) => {
                                    setUserName(value);
                                    setError(false);
                                }}
                                type="type"
                                name="Username"
                            />
                            <Input
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                onChange={(value) => {
                                    setPassword(value);
                                    setError(false);
                                }}
                                name="Password"
                                onClickVisible={() =>
                                    handleChangeValue(
                                        "passwordShow",
                                        !form?.passwordShow
                                    )
                                }
                                value={password}
                                icon={
                                    form?.passwordShow ? openedEye : closedEye
                                }
                                type={form?.passwordShow ? "text" : "password"}
                            />

                            {/* <Input
								value={confirmPassword}
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
								onChange={(value) => {
									setConfirmPassword(value);
									setError(false);
								}}
								name="Confirm Password"
								type={
									form?.confirmPasswordShow
										? "text"
										: "password"
								}
								onClickVisible={() =>
									handleChangeValue(
										"confirmPasswordShow",
										!form?.confirmPasswordShow
									)
								}
							/> */}
                        </div>
                        <div style={{ display: "flex", marginTop: "21px" }}>
                            <Button
                                href="#"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                bacerkgroundColor="#5A2D8F"
                                fontSize="15px"
                                padding="21px 70px"
                                onClick={(e) => {
                                    if (name === "") {
                                        setError(true);
                                        setFieldName("Name");
                                        setErrorMessage("Enter name");
                                    } else if (email === "") {
                                        setError(true);
                                        setFieldName("Email");
                                        setErrorMessage(
                                            "Please enter a valid email"
                                        );
                                    } else if (userName === "") {
                                        setError(true);
                                        setFieldName("Username");
                                        setErrorMessage(
                                            "Please enter username"
                                        );
                                    } else if (password.length < 8) {
                                        setError(true);
                                        setFieldName("Password");
                                        setErrorMessage(
                                            "Be a minimum of 8 characters"
                                        );
                                    } else if (criteria === false) {
                                        setError(true);
                                        setFieldName("Password");
                                        setErrorMessage(
                                            "Strong passwords consist of a combination of uppercase and lowercase letters, numbers and special symbols"
                                        );
                                    }
                                    // else if (password !== confirmPassword) {
                                    // 	setError(true);
                                    // 	setFieldName("Confirm Password");
                                    // 	setErrorMessage(
                                    // 		"Both password must be same"
                                    // 	);
                                    // }
                                    else {
                                        e.preventDefault();
                                        handleUpdateForm();
                                    }
                                }}
                            >
                                <Text color="white" fontSize="15px">
                                    Submit
                                </Text>
                            </Button>

                            <Button
                                onClick={() => {
                                    history.push("/superadmin/lead-generator/");
                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 22px",
                                }}
                                backgroundColor="#F4F4F4"
                                fontSize="15px"
                                padding="21px 70px"
                            >
                                <Text color=" #522e92" fontSize="15px">
                                    Cancel
                                </Text>
                            </Button>
                        </div>
                    </form>
                </Container>
            </div>
        </Wrapper>
    );
}

export default UpdateLeadGeneratorAdmin;
