import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import React, { useEffect, useState, useContext } from "react";
import { Context } from "./../../contexts/Store";
import Text from "../../components/Text";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

//svg
import Add from "./../../assets/images/addbutton.svg";
import Delete from "./../../assets/images/action-delete.svg";

function AllSubDepartment() {
	const history = useHistory();

	const [subDepartmentList, setSubDepartmentList] = useState([
		{
			id: 1,
			name: "Department of Computer Science",
		},
		{
			id: 2,
			name: "Department of Computer Science",
		},
		{
			id: 3,
			name: "Department of Computer Science",
		},
	]);

	return (
		<Wrapper>
			<Top>
				<TopLeft>
					<Title fontSize="35px">Sub-departments</Title>
					<Text
						fontSize="20px"
						fontWeight="400"
						color="#000"
						style={{ marginTop: "10px" }}
					>
						Departments / All Sub Departments
					</Text>
				</TopLeft>
				<TopRight
					onClick={() => {
						history.push("/campus-admin/add-sub-department/");
					}}
				>
					<img src={Add} alt="icon" />
					<Text
						fontSize="16px"
						fontWeight="400"
						color="#fff"
						style={{ marginLeft: "5px" }}
					>
						Add Sub-department
					</Text>
				</TopRight>
			</Top>
			<Div>
				<TH
					color="#808080"
					fontSize="15px"
					height="60px"
					fontWeight="600"
					width="10%"
					style={{ marginLeft: "20px" }}
				>
					ID No.
				</TH>
				<TH
					color="#808080"
					fontSize="15px"
					height="60px"
					fontWeight="600"
					width="30%"
					// style={{ background: "red" }}
				>
					Sub-department Name
				</TH>
				<TH
					color="#808080"
					fontSize="15px"
					height="60px"
					fontWeight="600"
					width="15%"
					// style={{ background: "red" }}
				>
					Short Name
				</TH>
				<TH
					color="#808080"
					fontSize="15px"
					height="60px"
					fontWeight="600"
					width="30%"
					// style={{ background: "red" }}
				>
					Department under
				</TH>
				<TH
					color="#808080"
					fontSize="15px"
					height="60px"
					fontWeight="600"
					width="15%"
					// style={{ background: "red" }}
				>
					Action
				</TH>
			</Div>
			{subDepartmentList.map((item) => (
				<Body>
					<TH
						color="#7B6294"
						fontSize="15px"
						height="60px"
						fontWeight="500"
						width="10%"
						style={{ marginLeft: "20px" }}
					>
						001
					</TH>
					<TH
						color="#7B6294"
						fontSize="15px"
						height="60px"
						fontWeight="500"
						width="30%"
						// style={{ background: "red" }}
					>
						Batchelor of Computer Science
					</TH>
					<TH
						color="#7B6294"
						fontSize="15px"
						height="60px"
						fontWeight="500"
						width="15%"
						// style={{ background: "red" }}
					>
						BSc CS
					</TH>
					<TH
						color="#7B6294"
						fontSize="15px"
						height="60px"
						fontWeight="500"
						width="30%"
						// style={{ background: "red" }}
					>
						Department of Computer Science
					</TH>
					<TH
						color="#7B6294"
						fontSize="15px"
						height="60px"
						fontWeight="500"
						width="15%"
						// style={{ background: "red" }}
					>
						<img src={Delete} alt="Delete" />
					</TH>
				</Body>
			))}
		</Wrapper>
	);
}

export default AllSubDepartment;

const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
`;
const TopLeft = styled.div``;
const TopRight = styled.div`
	display: flex;
	align-items: center;
	background-color: #522e92;
	height: 50px;
	border-radius: 3px;
	padding: 0 20px;
	cursor: pointer;
`;
const Div = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #f1f8fe;
	width: ${(props) => (props.width ? props.width : "100%")};
	border-bottom: 1px solid #cce7ff;
	border-top: 1px solid #e7e7e7;
`;
const TH = styled.div`
	font-family: "Inter";
	font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
	color: ${(props) => (props.color ? props.color : "#505887")};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "600")};
	text-align: left;
	width: ${(props) => (props.width ? props.width : "15%")};
	// padding: 10px 0;
	height: ${(props) => (props.height ? props.height : "60px")};
	display: flex;
	align-items: center;
`;
const Body = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #fff;
	width: ${(props) => (props.width ? props.width : "100%")};
	border-bottom: 1px solid #e7e7e7;
`;
