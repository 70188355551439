import dashboard from "../../assets/images/dashboard-menu.svg";
import campus from "../../assets/images/campus-menu.svg";
import institution from "../../assets/images/institution.svg";
import university from "../../assets/images/university.svg";
import department from "../../assets/images/department.svg";
import staff from "../../assets/images/staff.svg";
import designation from "../../assets/images/designation.svg";

const navBarData = [
	{
		img: dashboard,
		text: "Dashboard",
		link: "/campus-admin/",
	},
	{
		img: university,
		text: "University",
		inner: [
			{
				text: "All Universities",
				link: "/campus-admin/all-university/",
				add: true,
				addLink: "/campus-admin/add-university/",
			},
		],
	},
	// {
	// 	img: institution,
	// 	text: "Institutions",
	// 	inner: [
	// 		{
	// 			text: "All Institutions",
	// 			link: "/campus-admin/all-institution/",
	// 			add: true,
	// 			addLink: "/campus-admin/add-institution/",
	// 		},
	// 	],
	// },
	{
		img: department,
		text: "Departments",
		inner: [
			{
				text: "All Departments",
				link: "/campus-admin/all-department/",
				add: true,
				addLink: "/campus-admin/add-department/",
			},
			// {
			// 	text: "All Sub Departments",
			// 	link: "/campus-admin/all-sub-department/",
			// 	add: true,
			// 	addLink: "/campus-admin/add-sub-department/",
			// },
		],
	},
	{
		img: designation,
		text: "Designations",
		inner: [
			{
				text: "All Designations",
				link: "/campus-admin/all-designation/",
				add: true,
				addLink: "/campus-admin/add-designation/",
			},
		],
	},
	{
		img: staff,
		text: "Staff",
		inner: [
			{
				text: "All Staff",
				link: "/campus-admin/all-staff/",
				add: true,
				addLink: "/campus-admin/add-staff/",
			},
		],
	},
];
export default navBarData;
