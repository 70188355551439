import ApiService from "../utils/fetch";

export const ListSourceService = async (data) => {
	let response = await ApiService.get(`/leads/lead-source/`, data);
	return response;
};

export const GetSourceService = async (id) => {
	let response = await ApiService.get(`/leads/lead-source/${id}/`);
	return response;
};

export const AddSourceService = async (data) => {
	var formdata = new FormData();
	formdata.append("name", data.name);
	formdata.append("image", data.image);

	let response = await ApiService.post(`/leads/lead-source/`, {
		body: formdata,
	});
	return response;
};

export const UpdateSourceService = async (data) => {
	var formdata = new FormData();
	formdata.append("name", data.name);
	if (data.image) {
		formdata.append("image", data.image);
	}
	let response = await ApiService.put(`/leads/lead-source/${data.id}/`, {
		body: formdata,
	});
	return response;
};

export const DeleteSourceService = async (id) => {
	let response = await ApiService.delete(`/leads/lead-source/${id}/`);
	return response;
};
