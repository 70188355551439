import React from "react";
import styled from "styled-components";
import downArrow from "../../assets/images/select-arrow.svg";

export default function Select({
    handleClick,
    field,
    selectedCriteria,
    selectedTeam,
    selectedConsultants,
    selectedLeadSources,
    selectedLeadGenerator,
    selectedLeadStages,
    selectedCountry,
    selectedNationality,
    selectedUniversity,
    selectedCourse,
    selectedSpecialization,
}) {
    return (
        <SelectBox
            onClick={(event) => {
                handleClick(event, field);
            }}
        >
            <LeftPart>
                {field.value === "team"
                    ? selectedTeam && selectedTeam.name
                    : field.value === "consultant"
                    ? selectedConsultants.length > 0 &&
                      `${selectedConsultants.length} Consultants selected`
                    : field.value === "lead-source"
                    ? selectedLeadSources.length > 0 &&
                      `${selectedLeadSources.length} Sources selected`
                    : field.value === "lead-generator"
                    ? selectedLeadGenerator && selectedLeadGenerator.name
                    : field.value === "lead-stage"
                    ? selectedLeadStages.length > 0 &&
                      `${selectedLeadStages.length} Stages selected`
                    : field.value === "country"
                    ? selectedCountry && selectedCountry.name
                    : field.value === "nationality"
                    ? selectedNationality && selectedNationality.name
                    : field.value === "university"
                    ? selectedUniversity && selectedUniversity.name
                    : field.value === "course"
                    ? selectedCourse && selectedCourse.name
                    : field.value === "specialization"
                    ? selectedSpecialization && selectedSpecialization.name
                    : null}
            </LeftPart>
            <DownIcon src={downArrow} alt="Icon" />
        </SelectBox>
    );
}

const SelectBox = styled.div`
    background: #ffffff;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 14px 16px;
    height: 46px;
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
        border: 1px solid #4c4c4c;
    }
`;
const LeftPart = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
`;
const DownIcon = styled.img``;
