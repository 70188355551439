import { useState, useContext, useEffect, React } from "react";
import { Route, Switch } from "react-router-dom";

import { Context } from "../../contexts/Store";
import { baseConfig } from "../../utils/config";

import "./style.css";
import Header from "../../components/header/header";
import NavBar from "../../components/navBar/navBar";
import MainDashboard from "./mainDashboard";

//icon
import dashboard from "../../assets/images/dashboard-menu.svg";
import executiveIcon from "../../assets/images/executive-nav-icon.svg";
import reportIcon from "../../assets/images/report-nav-icon.svg";

import studentsIcon from "../../assets/images/students-icon.svg";
import NotAssignedStudents from "./NotAssignedStudents";
import AssignedStudents from "./AssignedStudents";
import FinanceExecutives from "./FinanceExecutives";
import FinanceExecutiveSingle from "./FinanceExecutiveSingle";
import Reports from "./Reports";
import AssignedSinglePage from "./AssignedSinglePage";
import ReportPage from "./ReportPage";

export default function FinanceManagerIndex() {
    const [openNav, setOpenNav] = useState(false);
    const navBarData = [
        {
            img: dashboard,
            text: "Dashboard",
            link: "/finance-manager/",
        },
        {
            img: executiveIcon,
            text: "Finance Executives",
            link: "/finance-manager/executives",
        },
        {
            img: studentsIcon,
            text: "Students",

            // inner: innerData,
            inner: [
                {
                    text: "Not Assigned",
                    link: "/finance-manager/not-assigned-students/",
                },
                {
                    text: "Assigned",
                    link: "/finance-manager/assigned-students/",
                },
                // {
                // 	text: "OTHM",
                // 	link: "/finance/students/othm",
                // },
            ],
        },
        {
            img: reportIcon,
            text: "Reports",
            link: "/finance-manager/reports",
        },
    ];
    return (
        <div className="body">
            <NavBar
                openNav={openNav}
                data={navBarData}
                setOpenNav={setOpenNav}
            />
            <div className="body-container">
                <Header setOpenNav={setOpenNav} />
                <Switch>
                    <Route
                        path="/finance-manager/"
                        exact
                        component={MainDashboard}
                    />
                    {/* <Route
                        path="/students/:university/"
                        exact
                        component={Students}
                    /> */}
                    {/* <Route
						path="/finance/students/:university_name/:university"
						// exact
						component={Students}
					/>
					<Route
						path="/finance/student/:id"
						// exact
						component={StudentSinglePage}
					/> */}
                    <Route
                        path="/finance-manager/executive/:id"
                        component={FinanceExecutiveSingle}
                    />
                    <Route
                        path="/finance-manager/executives/"
                        component={FinanceExecutives}
                    />

                    <Route
                        path="/finance-manager/not-assigned-students/"
                        component={NotAssignedStudents}
                    />

                    <Route
                        path="/finance-manager/assigned-students/"
                        component={AssignedStudents}
                    />
                    <Route
                        path="/finance-manager/assigned-student/:id"
                        component={AssignedSinglePage}
                    />
                    <Route
                        path="/finance-manager/reports/"
                        component={ReportPage}
                    />
                </Switch>
            </div>
        </div>
    );
}
