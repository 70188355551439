import React, { useEffect } from "react";
import styled from "styled-components";
import selectedIcon from "../../assets/images/checkbox-selected.svg";
import unSelectedIcon from "../../assets/images/checkbox-unselected.svg";
import searchIcon from "../../assets/images/finance-search-icon.svg";
export default function DropDownPopOver({
    type,
    setAnchorEl,
    anchorEl,
    popoverWidth,
    search,
    setSearch,
    selectedCriteria,
    sectionName,
    allItems,
    selectedItem,
    setSelectedItem,
    selectedItems,
    setSelectedItems,
}) {
    return (
        <MainBox>
            <>
                {type === "multiple" && selectedItems.length > 0 && (
                    <SelectedItemsContainer>
                        {selectedItems.map((item, index) => (
                            <SelectedItem
                                key={index}
                                onClick={() => {
                                    setSelectedItems(
                                        selectedItems.filter((p) => p !== item)
                                    );
                                }}
                            >
                                {item.name}
                                <CloseSymbol>&#x2715;</CloseSymbol>
                            </SelectedItem>
                        ))}
                    </SelectedItemsContainer>
                )}
                {type !== "multiple" && Object.keys(selectedItem).length > 0 && (
                    <SelectedItemsContainer>
                        <SelectedItem
                            onClick={() => {
                                setSelectedItem({});
                            }}
                        >
                            {selectedItem.name}
                            <CloseSymbol>&#x2715;</CloseSymbol>
                        </SelectedItem>
                    </SelectedItemsContainer>
                )}
                {sectionName !== "Status" && (
                    <SearchBoxDiv>
                        <label htmlFor="search">
                            <SearchIcon
                                id="search"
                                src={searchIcon}
                                alt="icon"
                            />
                        </label>
                        <SearchInput
                            type="text"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </SearchBoxDiv>
                )}

                <SubTitle>
                    {allItems.length} {sectionName}
                </SubTitle>
                {type === "multiple" ? (
                    <ProgramCards>
                        {allItems?.map((singleItem, i) => (
                            <ProgramCard
                                key={singleItem?.id}
                                onClick={() => {
                                    const itemExists = selectedItems.some(
                                        (item) => item.id === singleItem.id
                                    );
                                    if (itemExists) {
                                        setSelectedItems(
                                            selectedItems.filter(
                                                (item) =>
                                                    item.id !== singleItem.id
                                            )
                                        );
                                    } else {
                                        setSelectedItems([
                                            ...selectedItems,
                                            singleItem,
                                        ]);
                                    }
                                }}
                            >
                                <CheckBoxIcon
                                    src={
                                        selectedItems.some(
                                            (item) => item.id === singleItem.id
                                        )
                                            ? selectedIcon
                                            : unSelectedIcon
                                    }
                                    alt="icon"
                                />
                                <ProgramName>{singleItem?.name}</ProgramName>
                            </ProgramCard>
                        ))}
                    </ProgramCards>
                ) : (
                    <ProgramCards>
                        {allItems?.map((singleItem, i) => (
                            <ProgramCard
                                key={singleItem?.id}
                                onClick={() => {
                                    if (selectedItem == singleItem) {
                                        setSelectedItem({});
                                    } else setSelectedItem(singleItem);
                                }}
                            >
                                <Round
                                    className={
                                        singleItem?.name ===
                                        "Cochin University of Science and Technology"
                                            ? "cochin"
                                            : ""
                                    }
                                >
                                    <InnerRound
                                        selected={
                                            selectedItem.id === singleItem.id
                                                ? true
                                                : false
                                        }
                                    ></InnerRound>{" "}
                                </Round>
                                <ProgramName>{singleItem?.name}</ProgramName>
                            </ProgramCard>
                        ))}
                    </ProgramCards>
                )}

                <ButtonsContainer>
                    <CancelButton
                        onClick={() => {
                            setAnchorEl(null);
                            setSelectedItem({});
                            if (search) {
                                setSearch("");
                            }
                        }}
                    >
                        Cancel
                    </CancelButton>
                    <ApplyButton
                        onClick={() => {
                            setAnchorEl(null);
                            if (search) {
                                setSearch("");
                            }
                        }}
                    >
                        Submit
                    </ApplyButton>
                </ButtonsContainer>
            </>
        </MainBox>
    );
}
const MainBox = styled.div`
    background: #ffffff;
    width: calc(100% - 20px);
    padding: 10px;
    /* max-height: 60vh;
    overflow-y: scroll; */
    overflow-x: hidden;
    position: relative;
`;
const SearchBoxDiv = styled.div`
    /* width: calc(100% - 20px); */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 8px;
    height: 36px;
    /* background: #f5f5f5; */
    background: #fff;
    border-radius: 8px;
    /* border: 1px solid #f5f5f5; */
    border: 1px solid #d7d7d7;

    input[type="text"] {
        border: none; /* Remove default borders */
        padding: 4px 8px;
        border-radius: 12px; /* Sadly outline does not round! therefore...*/
    }
    input[type="text"]:focus {
        background: #fff;
        outline: none; /* Remove default outline and use border or box-shadow */
        /* box-shadow: 0 0 0 2px orange; */
        /* Full freedom. (works also with border-radius) */
    }
`;
const SearchIcon = styled.img`
    width: 18px;
    display: block;
    object-fit: cover;
    margin-right: 0px;
`;
const SearchInput = styled.input`
    display: inline-block;
    /* width: calc(210px - 18px); */
    width: 100%;
    height: 34px;
    box-sizing: border-box;
    /* padding: 5px 0; */
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    border: none;
    color: #404040;

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #b6b6b6;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #b6b6b6;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #b6b6b6;
    }
`;
const SubTitle = styled.span`
    margin-top: 10px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: #404040;
    text-transform: capitalize;
`;
const ProgramCards = styled.div`
    overflow: scroll;
    /* width: calc(100% - 20px); */
    height: 150px;
`;
const ProgramCard = styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 6px;
    cursor: pointer;
    &:hover {
        background: #f5f5f5;
    }
`;

const CheckBoxIcon = styled.img`
    width: 21px;
    cursor: pointer;
    display: block;
    margin-right: 8px;
`;
const ProgramName = styled.div`
    text-transform: capitalize;
    font-size: 14.6px;
    font-weight: 400;
    color: #010101;
    margin-top: 4px;
`;
const FilterSection = styled.div``;
const FilterItem = styled.div`
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 6px;
    cursor: pointer;
    &:hover {
        background: #f5f5f5;
    }
`;
const Round = styled.div`
    width: 19px;
    height: 19px;
    box-sizing: border-box;
    padding: 2px;
    margin-right: 8px;
    border-radius: 50%;
    border: 1px solid #9d9d9d;
    /* &.cochin {
        width: 33px;
    } */
`;
const InnerRound = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: ${(props) => (props.selected ? "#917AFD" : "#fff")};
`;
const ButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    margin-top: 10px;
    /* position: fixed;
    top: 200px; */
`;
const CancelButton = styled.button`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #010101;
    letter-spacing: 0.04em;
    font-size: 14px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid #917afd;
    border-radius: 8px;
    cursor: pointer;
`;
const ApplyButton = styled.button`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #010101;
    letter-spacing: 0.04em;
    font-size: 14px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid #917afd;
    border-radius: 8px;
    background: #917afd;
    color: #fff;
    cursor: pointer;
`;
const SelectedItemsContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 12px;
`;
const SelectedItem = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
    background: #f5f5f5;
    padding: 6px 12px;
    gap: 8px;
    border-radius: 24px;
    color: #010101;
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
`;
const CloseSymbol = styled.span`
    display: inline-block;
    color: #c1c1c1;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
`;
// const Round = styled.div`
//     width: 19px;
//     height: 19px;
//     box-sizing: border-box;
//     padding: 2px;
//     margin-right: 8px;
//     border-radius: 50%;
//     border: 1px solid #9d9d9d;
// `;
// const InnerRound = styled.div`
//     width: 100%;
//     height: 100%;
//     border-radius: 50%;
//     background: ${(props) => (props.selected ? "#917AFD" : "#fff")};
// `;
