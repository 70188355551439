import React from 'react';
import styled, { keyframes } from 'styled-components';

// Define the keyframes for the fade-in-up animation
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`;

// Styled Snackbar component
const Snackbar = styled.div`
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px 20px;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;
  max-width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: ${fadeInUp} 0.5s ease-in-out;
  z-index: 1000;
`;

const CloseButton = styled.span`
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  margin-left: 10px;
`;

// Snackbar Component
const SnackbarComponent = ({ message, onClose }) => {
  return (
    <Snackbar>
      <span>{message}</span>
      <CloseButton onClick={onClose}>&times;</CloseButton>
    </Snackbar>
  );
};

export default SnackbarComponent;
