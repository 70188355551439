import React, { useEffect, useState, useContext } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import visible from "../../assets/images/visible.svg";
import openedEye from "../../assets/images/eye-open.svg";
import closedEye from "../../assets/images/eye-close.svg";
import { useHistory } from "react-router-dom";
import { AddLeadManagerService } from "../../service/leadManager";
import { validatePassword, capitalizeString } from "./../../helpers/Functions";
import { baseConfig } from "./../../utils/config";
import { Context } from "../../contexts/Store";
import ProfilePicture from "../../components/ProfilePicture";
import AlertBox from "../../components/includes/AlertBox";
import SweetAlert from "../../components/includes/SweetAlert";

function AddLeadManagerAdmin() {
    const history = useHistory();
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [fieldName, setFieldName] = useState("");
    const [id, setId] = useState("");
    const [profile, setProfile] = useState(null);
    const [fetchedImage, setFetchedImage] = useState(null);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [emailValidation, setEmailValidation] = useState(false);
    const [passwordValidate, setPasswordValidate] = useState({});
    const [employeeID, setEmployeeID] = useState("");
    const [target, setTarget] = useState("");

    // sweetalert states and functions
    const [isSweetAlert, setIsSweetAlert] = useState(false);
    const [sweetAlertContent, setSweetAlertContent] = useState({
        title: "",
        message: "",
        icon: "",
        confirmButtonText: "",
        isCancelButton: false,
    });
    const handleSweetAlertConfirm = () => {
        history.push("/superadmin/sales-manager/");
    };
    // end of sweetalert

    // alert mui message
    const [alertMessage, setAlertMessage] = useState(null); // Track the message state

    const { state } = useContext(Context);

    useEffect(() => {
        setPasswordValidate(validatePassword(password));
    }, [password]);
    const criteria = passwordValidate.is_strong_password;

    const validate = (text) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(text) === false) {
            setEmailValidation(false);
            setEmail(text);
            return false;
        } else {
            setEmail(text);
            setEmailValidation(true);
        }
    };

    const [form, setFormValues] = useState({});

    const handleChangeValue = (key, value) => {
        setFormValues({ ...form, [key]: value });
    };

    const handleSubmit = async () => {
        if (form["password"] !== form["confirmPassword"]) {
            alert("Incorrect password");
        }
        let data = await AddLeadManagerService({
            name,
            email,
            userName,
            password,
            state,
            profile,
            employeeID,
            target,
        });
        if (data.StatusCode === 6000) {
            // history.push("/superadmin/sales-manager");
            setSweetAlertContent({
                title: "Success",
                message: "Sales Manager added successfully",
                icon: "success",
                confirmButtonText: "Ok",
                isCancelButton: false,
            });
            setIsSweetAlert(true);
        } else if (data.StatusCode === 6001) {
            setAlertMessage(data.data.message);
        }
    };

    const getAdminId = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/leads/get-lead-manager-id/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setId(data.lead_manager_id);
                } else {
                    setAlertMessage(data.data.message);
                }
            })
            .catch((error) => {
                setAlertMessage(error);
            });
    };

    useEffect(() => {
        getAdminId();
    }, []);

    useEffect(() => {
        if (name.length === 0) {
            setTimeout(() => {
                setUserName("");
                setPassword("");
            }, 200);
        }
        setUserName(`${name.replace(/\s+/g, "")}@LMS`);
        setPassword(`${name.replace(/\s+/g, "")}${id}@LMS`);
    }, [name]);

    return (
        <Wrapper>
            <AlertBox
                message={alertMessage}
                setMessage={setAlertMessage}
                severity={
                    alertMessage && alertMessage.includes("successful")
                        ? "success"
                        : "error"
                }
                autoClose={true}
                onClose={() => setAlertMessage(null)}
            />
            <SweetAlert
                sweetAlertContent={sweetAlertContent}
                setSweetAlertContent={setSweetAlertContent}
                isSweetAlert={isSweetAlert}
                setIsSweetAlert={setIsSweetAlert}
                onConfirm={handleSweetAlertConfirm}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "0 12px",
                    }}
                >
                    <Title fontSize="35x">Add Sales Manager</Title>
                    {/* <Text
						style={{
							marginTop: "5px",
							fontFamily: "Inter",
							fontWeight: "500",
						}}
						color="grey"
						fontSize="18px"
					>
						Admins / Add Sales Manager
					</Text> */}
                </div>

                <div>
                    <Button
                        href="#"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0 22px",
                        }}
                        backgroundColor="white"
                        fontSize="15px"
                        padding="18px 57px"
                        onClick={(e) => {
                            e.preventDefault();
                            const form = document.getElementById("form");
                            form.reset();
                            setFormValues({});
                            setEmail("");
                            setName("");
                            setPassword("");
                            // setConfirmPassword("");
                            setUserName("");
                        }}
                    >
                        <Text color="#EE1451" fontSize="15px">
                            Clear Form
                        </Text>
                    </Button>
                </div>
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Container style={{ flexWrap: "wrap " }} width="100%">
                    <form id="form">
                        <div>
                            <ProfilePicture
                                setFile={setProfile}
                                file={profile}
                                fetchedImage={fetchedImage}
                                setFetchedImage={setFetchedImage}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Input
                                name="Admin ID (auto generated)"
                                value={id}
                                disabled
                            />
                            <Input
                                onChange={(value) => {
                                    setName(capitalizeString(value));
                                    setError(false);
                                }}
                                type="text"
                                name="Name"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={name}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Input
                                onChange={(value) =>
                                    // handleChangeValue("email", value)
                                    {
                                        validate(value);
                                        setError(false);
                                    }
                                }
                                type="email"
                                name="Email"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                            />

                            <Input
                                onChange={(value) => {
                                    setEmployeeID(value);
                                    setError(false);
                                }}
                                type="text"
                                name="Employee ID"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={employeeID}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "20px",
                                justifyContent: "space-between ",
                            }}
                        >
                            <Input
                                onChange={(value) => {
                                    setTarget(value);
                                    setError(false);
                                }}
                                type="text"
                                name="Target (UAE)"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={target}
                            />
                            <Input
                                onChange={(value) => {
                                    setUserName(value);
                                    setError(false);
                                }}
                                type="text"
                                name="Username"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={userName}
                            />

                            {/* <Input
								onClickVisible={() =>
									handleChangeValue(
										"passwordShow",
										!form?.passwordShow
									)
								}
								onChange={(value) =>
									// handleChangeValue("password", value)
									{
										setPassword(value);
										setError(false);
									}
								}
								type={form?.passwordShow ? "text" : "password"}
								name="Password"
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
								value={password}
							/> */}
                            {/* <Input
								onClickVisible={() =>
									handleChangeValue(
										"confirmPasswordShow",
										!form?.confirmPasswordShow
									)
								}
								onChange={(value) =>
									// handleChangeValue("confirmPassword", value)
									{
										setConfirmPassword(value);
										setError(false);
									}
								}
								type={
									form?.confirmPasswordShow
										? "text"
										: "password"
								}
								name="Confirm Password"
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
							/> */}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "20px",
                                justifyContent: "space-between ",
                            }}
                        >
                            {/* <Input
								onChange={(value) => {
									setUserName(value);
									setError(false);
								}}
								type="text"
								name="Username"
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
								value={userName}
							/> */}

                            <Input
                                onClickVisible={() =>
                                    handleChangeValue(
                                        "passwordShow",
                                        !form?.passwordShow
                                    )
                                }
                                onChange={(value) =>
                                    // handleChangeValue("password", value)
                                    {
                                        setPassword(value);
                                        setError(false);
                                    }
                                }
                                type={form?.passwordShow ? "text" : "password"}
                                icon={
                                    form?.passwordShow ? openedEye : closedEye
                                }
                                name="Password"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={password}
                            />
                        </div>
                        <div style={{ display: "flex", marginTop: "21px" }}>
                            <Button
                                href="#"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                bacerkgroundColor="#5A2D8F"
                                fontSize="15px"
                                padding="21px 70px"
                                onClick={(e) => {
                                    if (name === "") {
                                        setError(true);
                                        setFieldName("Name");
                                        setErrorMessage(
                                            "Enter a lead manager name"
                                        );
                                    } else if (emailValidation === false) {
                                        setError(true);
                                        setFieldName("Email");
                                        setErrorMessage(
                                            "Please enter a valid email"
                                        );
                                    } else if (userName === "") {
                                        setError(true);
                                        setFieldName("Username");
                                        setErrorMessage(
                                            "Please enter username"
                                        );
                                    } else if (password.length < 8) {
                                        setError(true);
                                        setFieldName("Password");
                                        setErrorMessage(
                                            "Be a minimum of 8 characters"
                                        );
                                    } else if (criteria === false) {
                                        setError(true);
                                        setFieldName("Password");
                                        setErrorMessage(
                                            "Strong passwords consist of a combination of uppercase and lowercase letters, numbers and special symbols"
                                        );
                                    }
                                    // else if (password !== confirmPassword) {
                                    // 	setError(true);
                                    // 	setFieldName("Confirm Password");
                                    // 	setErrorMessage(
                                    // 		"Both password must be same"
                                    // 	);
                                    // }
                                    else {
                                        e.preventDefault();
                                        handleSubmit();
                                    }
                                }}
                            >
                                <Text color="white" fontSize="15px">
                                    Submit
                                </Text>
                            </Button>

                            <Button
                                onClick={() => {
                                    history.push("/superadmin/sales-manager/");
                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 22px",
                                }}
                                backgroundColor="#F4F4F4"
                                fontSize="15px"
                                padding="21px 70px"
                            >
                                <Text color=" #522e92" fontSize="15px">
                                    Cancel
                                </Text>
                            </Button>
                        </div>
                    </form>
                </Container>
            </div>
        </Wrapper>
    );
}

export default AddLeadManagerAdmin;
