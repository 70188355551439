import styled from "styled-components";

export default styled.div`
    padding: 20px 2.5%;
    background: ${(props) => (props.background ? props.background : "#f5f7fa")};
    height: ${(props) => (props.isScrollBar ? "calc(100vh - 120px)" : "100%")};
    overflow: auto;
    margin-top: 80px;
    @media (max-width: 1080px) {
        padding: 2.5% 2%;
    }

    /* Scrollbar styles for WebKit-based browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
        /* display: block !important; */
        display: ${(props) => (props.isScrollBar ? "block" : "none")};

        width: 6px !important;
        margin-right: 3px;
        /* padding: 0 10px; */
    }

    &::-webkit-scrollbar-track {
        background: #f5f7fa; /* Track color (change as needed) */
        /* width: 6px; */
    }

    &::-webkit-scrollbar-thumb {
        background: #888; /* Thumb color (change as needed) */
        border-radius: 36px !important;
        width: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Thumb color on hover (change as needed) */
    }
`;
