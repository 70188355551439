import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import "./style.css";
import DashboardItem from "../../components/superAdmin/DashboardItem";
import DashboardListItem from "../../components/superAdmin/DashboardListItem";
import DashboardListData from "../../components/superAdmin/DashboardListData";
import React, { useEffect, useState, useContext } from "react";
import Pagination from "../../components/pagination/pagination";
import Table from "../../components/Table";
import TD from "../../components/TD";
import { GetSuperAdminDashboardService } from "../../service/dashboard";
import { ListLeadsService } from "../../service/leads";
import { Context } from "./../../contexts/Store";
import styled from "styled-components";

//svg
import LeadGenerator from "./../../assets/images/lead-generator.svg";
import SalesManager from "./../../assets/images/sales-manager.svg";
import AdmissionConsultanat from "./../../assets/images/admission-cunsultant.svg";
import CampusImage from "../../assets/images/dashboard-campus.svg";
import SchoolImage from "../../assets/images/dashboard-school.svg";
import InstitutionsImage from "../../assets/images/dashboard-institutions.svg";
import DepartmentsImage from "../../assets/images/dashboard-departments.svg";
import SubDepartmentsImage from "../../assets/images/dashboard-subdepartment.svg";
import StaffImage from "../../assets/images/dashboard-staff.svg";
import DesignationImage from "../../assets/images/dashboard-designation.svg";
import StudentImage from "../../assets/images/dashboard-student.svg";
import Welcome from "../../components/includes/Welcome";

function MainDashboard() {
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [dashboardData, setDashboardData] = useState();
    const [studentList, setStudentDetails] = useState([]);
    const [studentPagination, setStudentDetails1] = useState([]);
    const { state } = useContext(Context);

    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = DashboardListData.slice(
        indexOfFirstPost,
        indexOfLastPost
    );

    // Change page
    const paginate = (pageNumber) => {
        if (
            pageNumber > 0 &&
            pageNumber <= Math.ceil(DashboardListData.length / postsPerPage)
        ) {
            setCurrentPage(pageNumber);
        }
    };

    const getDasboardData = async () => {
        let data = await GetSuperAdminDashboardService(state);
        if (data.StatusCode === 6000) {
            setDashboardData(data.data?.super_admin_data);
        } else {
            alert(data?.data?.message);
        }
    };

    const getStudentDetails = async () => {
        let data = await ListLeadsService(state, "status=Converted");
        if (data.StatusCode === 6000) {
            setStudentDetails1(data.data?.paginator);
            setStudentDetails(data.data?.data);
        } else {
            alert(data.data.message);
        }
    };

    useEffect(() => {
        getDasboardData();
        // getStudentDetails();
    }, []);

    return (
        <Wrapper>
            {/* <Title
                fontSize="35px"
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                }}
            >
                <Welcome />, {dashboardData?.admin_name}
            </Title> */}
            <MainDiv style={{}}>
                {/* <DashboardItem
                    images={CampusImage}
                    title="Campus"
                    num={dashboardData?.campuses_count}
                /> */}
                <DashboardItem
                    images={SchoolImage}
                    title="Universities"
                    num={dashboardData?.universities_count}
                />
                {/* <DashboardItem
					images={InstitutionsImage}
					title="Institutions"
					num={dashboardData?.campuses_count}
				/> */}
                <DashboardItem
                    images={DepartmentsImage}
                    title="Sales Team"
                    num={dashboardData?.departments_count}
                />
                {/* <DashboardItem
					images={SubDepartmentsImage}
					title="Sub Departments"
					num={dashboardData?.sub_departments_count}
				/> */}
                <DashboardItem
                    images={StaffImage}
                    title=" Faculties"
                    num={dashboardData?.staffs_count}
                />
                {/* <DashboardItem
                    images={DesignationImage}
                    title="Designation"
                    num={dashboardData?.designations_count}
                /> */}
                <DashboardItem
                    images={StudentImage}
                    title="Students"
                    num={dashboardData?.students_count}
                />
                <DashboardItem
                    images={LeadGenerator}
                    title="Lead Generators"
                    num={dashboardData?.lead_generators_count}
                />
                <DashboardItem
                    images={SalesManager}
                    title="Lead Manager"
                    num={dashboardData?.sales_managers_count}
                />
                <DashboardItem
                    images={AdmissionConsultanat}
                    title="Admission Consultant"
                    num={dashboardData?.sales_persons_count}
                />
                <DashboardItem
                    images={AdmissionConsultanat}
                    title="Finance Managers"
                    num={dashboardData?.finance_managers_count}
                />
                <DashboardItem
                    images={AdmissionConsultanat}
                    title="Finance Executives"
                    num={dashboardData?.finance_executives_count}
                />
            </MainDiv>
            {/* <BottomDiv>
                <Title fontSize="30px">New Students</Title>
                <Table margin="15px 0">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px solid #E6EFF5",
                            paddingBottom: "20px",
                            marginBottom: "20px",
                        }}
                        className="table-head"
                    >
                        <TD fontSize="20px" width="10%">
                            Student ID
                        </TD>
                        <TD fontSize="20px">Date</TD>
                        <TD fontSize="20px">Name</TD>
                        <TD fontSize="20px">Course</TD>
                        <TD fontSize="20px">Qualification</TD>
                        <TD fontSize="20px">Phone</TD>
                        <LastColumn></LastColumn>
                    </div>
                    <div className="table-body">
                        {studentList?.map((item) => {
                            return (
                                <DashboardListItem
                                    // studentId={item.lead_id}
                                    // date={item.date}
                                    // name={item.name}
                                    // course={item.course}
                                    // specialisation={item.qualification}
                                    // batch={item.batch}
                                    // key={item.lead_id}
                                    data={item}
                                />
                            );
                        })}
                    </div>
                </Table>
                <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts={studentList?.length}
                    paginate={paginate}
                    currentPage={currentPage}
                    currentPosts={studentList}
                    hasNext={studentPagination?.hasNext}
                />
            </BottomDiv> */}
        </Wrapper>
    );
}

export default MainDashboard;

const MainDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    margin-top: 15px;
    /* @media only screen and (max-width: 1280px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        grid-gap: 0px;
        grid-template-columns: none;
    } */
    @media only screen and (max-width: 1280px) {
        grid-gap: 15px;
    }
`;
const BottomDiv = styled.div`
    margin-top: 40px;
    @media only screen and (max-width: 1280px) {
        margin-top: 25px;
    }
`;
const LastColumn = styled.div`
    width: 5%;
    @media only screen and (max-width: 810px) {
        display: none;
    }
`;
