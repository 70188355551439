import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";

import Wrapper from "../../components/Wrapper";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import loadingIcon from "../../assets/lottie/loading.gif";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/action-delete.svg";

// mui
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";
import { TextField } from "@mui/material";
import DeleteModal from "../../components/includes/DeleteModal";

export default function Faq() {
    const { state } = useContext(Context);

    const [faqs, setFaqs] = useState([]);
    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("");
    const [loading, setLoading] = useState(false);
    const [faqLoading, setFaqLoading] = useState(false);
    const [isDeleteModal, setDeleteModal] = useState(false);

    const [editId, setEditId] = useState("");
    const [deleteId, setDeleteId] = useState("");

    // mui modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const getFaqs = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/general/faq/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {},
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setFaqs(data);
                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };
    const getSingleFaq = () => {
        setFaqLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/general/faq/${editId}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {},
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setQuestion(data.data.question);
                    setAnswer(data.data.answer);
                    setTimeout(() => {
                        setFaqLoading(false);
                    }, 200);
                } else {
                    setFaqLoading(false);
                }
            })
            .catch((error) => {});
    };

    const handleSubmit = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        formdata.append("question", question);
        formdata.append("answer", answer);

        var method = editId ? baseConfig.put : baseConfig.post;
        var URL = editId ? `/general/faq/${editId}/` : `/general/faq/`;

        method(URL, formdata, {
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode == 6000) {
                    getFaqs();
                    handleClose();
                } else if (6001) {
                    alert(data.data.message);
                }
            })
            .catch((error) => {
                alert(error.response.data.data.message);
            });
    };
    const handleDelete = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .delete(`/general/faq/${deleteId}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode } = response.data;
                if (StatusCode === 6000) {
                    getFaqs();
                    setDeleteModal(false);
                    setDeleteId("");
                } else {
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        getFaqs();
    }, []);
    useEffect(() => {
        if (editId) {
            getSingleFaq();
        }
    }, [editId]);

    return (
        <Wrapper>
            <TitleWrapper>
                <Title>Frequently Asked Question's</Title>
                <AddButtonContainer>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            if (editId) {
                                setEditId("");
                            }
                            if (question) {
                                setQuestion("");
                            }
                            if (answer) {
                                setAnswer("");
                            }
                            handleOpen();
                        }}
                    >
                        Add FAQ
                    </Button>
                </AddButtonContainer>
            </TitleWrapper>
            {!loading ? (
                faqs.length > 0 ? (
                    <ListContainer>
                        {faqs.map((item, index) => (
                            <FaqItem key={index}>
                                <QuestionWrapper>
                                    <Question>
                                        {index + 1}. {item.question}
                                    </Question>
                                    <IconsWrapper>
                                        <Icon
                                            src={editIcon}
                                            alt="icon"
                                            onClick={() => {
                                                setEditId(item.id);
                                                handleOpen();
                                            }}
                                        />
                                        <Icon
                                            src={deleteIcon}
                                            alt="icon"
                                            onClick={() => {
                                                setDeleteId(item.id);
                                                setDeleteModal(true);
                                                // handleOpen();
                                            }}
                                        />
                                    </IconsWrapper>
                                </QuestionWrapper>
                                <Answer>{item.answer}</Answer>
                            </FaqItem>
                        ))}
                    </ListContainer>
                ) : (
                    <LoaderContainer>
                        <h4>No Faq's found </h4>
                    </LoaderContainer>
                )
            ) : (
                <LoaderContainer>
                    <LoadingGif src={loadingIcon} alt="icon" />
                </LoaderContainer>
            )}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {/* <Box sx={{ ...style, width: 780 }}>
                    <h2 id="child-modal-title">Text in a child modal</h2>
                    <p id="child-modal-description">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit.
                    </p>
                    <Button onClick={handleClose}>Close Child Modal</Button>
                </Box> */}
                <AddFaqWrapper>
                    {!faqLoading ? (
                        <>
                            {" "}
                            <ModalTitle>Add FAQ</ModalTitle>
                            <Box
                                sx={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    mb: 3,
                                }}
                            >
                                <TextField
                                    fullWidth
                                    label="Question"
                                    id="fullWidth"
                                    multiline
                                    placeholder="Enter question "
                                    value={question}
                                    onChange={(e) => {
                                        setQuestion(e.target.value);
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    width: "100%",
                                    maxWidth: "100%",
                                }}
                            >
                                <TextField
                                    placeholder="Enter answer "
                                    label="Answer"
                                    multiline
                                    minRows={2}
                                    maxRows={8}
                                    // style={{ width: 100 }}
                                    value={answer}
                                    fullWidth
                                    onChange={(e) => {
                                        setAnswer(e.target.value);
                                    }}
                                />
                            </Box>
                            <ButtonsWrapper>
                                <Button
                                    onClick={() => {
                                        handleClose();
                                    }}
                                    variant="outlined"
                                    color="error"
                                >
                                    Close
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{ ml: 2 }}
                                    onClick={() => {
                                        if (question === "" || answer === "") {
                                            alert(
                                                "Question and Answer field cannot be empty"
                                            );
                                        } else {
                                            handleSubmit();
                                        }
                                    }}
                                >
                                    Save
                                </Button>
                            </ButtonsWrapper>
                        </>
                    ) : (
                        <FaqLoaderContainer>
                            <LoadingGif src={loadingIcon} alt="icon" />
                        </FaqLoaderContainer>
                    )}
                </AddFaqWrapper>
            </Modal>
            {isDeleteModal && (
                <DeleteModal
                    isModal={isDeleteModal}
                    setModal={setDeleteModal}
                    deleteId={deleteId}
                    handleDeleteData={handleDelete}
                />
            )}
        </Wrapper>
    );
}

const MainContainer = styled.div`
    background: #ffffff;
    padding: 20px 15px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 10px;
    height: calc(100vh - 110px);
    /* &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #d6dee1;
        border-radius: 20px;
    } */
`;
const IconsWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const Icon = styled.img`
    width: 16px;
    display: block;
    object-fit: cover;
    cursor: pointer;
    margin-right: 16px;
    &:last-child {
        margin-right: 0;
    }
`;
const AccordionContainer = styled.div`
    margin-top: 30px;
    width: 100%;
`;
const Heading = styled.h6`
    font-size: 1.1rem;
    font-weight: 500;
    color: #404040;
`;
const Content = styled.p`
    font-size: 0.9rem;
    font-weight: 500;
    color: #404040;
`;
const Title = styled.h6`
    font-size: 1.2rem;
    font-weight: 500;

    /* width: 980px; */
`;
const EmptyContainer = styled.div`
    height: calc(100vh - 295px);
    overflow-y: scroll;
`;
const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const AddButtonContainer = styled.div``;
const AddButton = styled.div``;
const ListContainer = styled.div`
    margin-top: 30px;
`;
const FaqItem = styled.div`
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const QuestionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Question = styled.div`
    font-size: 1.05rem;
    /* font-weight: 500; */
    font-weight: 400;
    color: #404040;
`;
const Answer = styled.p`
    font-size: 1rem;
    /* font-weight: 400; */
    font-weight: 300;
    color: #404040;
    margin-top: 10px;
    line-height: 1.4em;
    @media screen and (max-width: 1080px) {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2; /* Change the number of lines as needed */
        text-overflow: ellipsis;
    }
`;
const AddFaqWrapper = styled.div`
    box-sizing: border-box;
    min-height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 980px;
    background: #fff;
    box-shadow: 24px;
    border-radius: 12px;
    padding: 20px;
    max-height: 80vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    @media screen and (max-width: 1180px) {
        width: 840px;
    }
    @media screen and (max-width: 980px) {
        width: 740px;
        max-width: 90%;
    }
`;
const ModalTitle = styled.h6`
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 20px;

    /* width: 980px; */
`;
const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 20px;
`;
const LoaderContainer = styled.div`
    height: calc(100vh - 120px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
const FaqLoaderContainer = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LoadingGif = styled.img`
    width: 60px;
    display: block;
    object-fit: cover;
`;
