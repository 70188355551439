import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { baseConfig } from '../../../utils/config';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Context } from '../../../contexts/Store';

function AddRemarkModal({remark,setRemark,getEmiData,handleCloseModal,handleClosedueDate,editedRemark,setEditedRemark,getFollowups}) {
    const [fupDate, setFupDate] = useState("");
    const { id } = useParams();
    const { state, dispatch } = useContext(Context);

    const handleChange = (type) => {
        let accessToken = state.user_details.access_token;

        var formdata = new FormData();
        // formdata.append("remark", editedRemark);
        if (type === "fupDate") {
            formdata.append("next_follow_up", fupDate);
        } else if (type === "remark") {
            formdata.append("remark", editedRemark);
        }

        baseConfig
            .post(`/finance/student-finance-update/${id}/`, formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    // getStudentData();
                    getEmiData();
                    if (type === "remark") {
                        handleCloseModal();
                        setEditedRemark("");
                        getFollowups()
                    }
                    if (type === "fupDate") {
                        handleClosedueDate();
                        setFupDate("");
                    }
                } else {
                    alert("Some error occured");
                }
            })
            .catch((error, response) => {
                alert(error);
            });
    };
  return (
        <MainContainer>
            <Title>
                Add Remark
            </Title>
            <TeaxtAreaContainer>
                <TextArea
                placeholder='Enter Remark'
                onChange={(e) => {
                    setEditedRemark(
                        e.target.value
                    );
                }}
                value={editedRemark}s
                >
                </TextArea>
            </TeaxtAreaContainer>
            <Buttons>
                <Cancel
                onClick={()=>{
                    handleCloseModal()
                }}
                >Cancel</Cancel>
                <Submit
                 onClick={() => {
                    if (
                        editedRemark === ""
                    ) {
                        alert(
                            "Remark field cannot be empty"
                        );
                    } else {
                        handleChange(
                            "remark"
                        );
                    }
                }}
                >Save</Submit>
            </Buttons>
        </MainContainer>
  )
}

export default AddRemarkModal

const MainContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 540px;
    overflow-y: scroll;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 12px;
    padding: 35px;
    height:320px;
`;
const Title=styled.p`
    font-size:18px;
    border-bottom : 1px solid #E7E7E7;
    padding-bottom:20px;
`;
const TeaxtAreaContainer =styled.div`
    background:#F8FAFC;
    width:540px;
    height:190px;
    margin-top:20px;
    border-radius:8px;
    border:1px solid #E7E7E7;
    position:relative;

`;
const TextArea=styled.textarea`
    resize: inherit;
    width:440px;
    height:110px;
    border 1px solid #E7E7E7;
    padding: 12px 18px;
    position:absolute;
    top:26px;
    left:30px;
`;
const Buttons = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin:20px 0;
`;
const Cancel = styled.button`
    color:#F61F1F;
`;
const Submit = styled.button`
    background:#917AFD;
    padding:12px 24px;
    color:#fff;
    border-radius:8px;
`;