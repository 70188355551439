import React, { useState } from "react";
import Delete from "../../assets/images/delete.svg";
import Edit from "../../assets/images/edit.svg";
import Image from "../../components/Image";
import TD from "../../components/TD";
import Text from "../../components/Text";
import "./style.css";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

import downArrow from "../../assets/images/down-arrow.svg";
import FollowUpModal from "../../components/salesPerson/FollowUpModal";

function AssignedLeadListItem({
    ID,
    name,

    phone,

    selectedLead,
    setSelectedLead,
    item,
    getData1,
    type,
}) {
    const [isModal, setIsModal] = useState(false);

    const history = useHistory();

    return (
        <>
            <MainDiv className="item" selected={selectedLead === item.id ? true : false} key={item.id}>
                <TD
                    color="#7B6294"
                    fontSize="14px"
                    fontWeight="500"
                    // width="105px"
                    width="10%"
                >
                    #{ID}
                </TD>
                <TD
                    color="#7B6294"
                    fontSize="14px"
                    fontWeight="500"
                    // width="175px"
                    width="15%"
                >
                    {name}
                </TD>
                <TD
                    color="#7B6294"
                    fontSize="14px"
                    fontWeight="500"
                    // width="150px"
                    width="15%"
                >
                    {phone}
                </TD>
                {/* <TD
          color="#7B6294"
          fontSize="14px"
          fontWeight="500"
          // width="175px"
          width="20%"
        >
          {item.email}
        </TD> */}
                {/* <TD color="#7B6294" fontSize="14px" fontWeight="500">
				{country}
			</TD> */}
                {/* <TD color="#7B6294" fontSize="14px" fontWeight="500" width="175px">
          country_name
        </TD>
        <TD color="#7B6294" fontSize="14px" fontWeight="500" width="150px">
          {source ? (
            <Text
              color="#7B6294"
              fontSize="14px"
              fontWeight="500"
              // style={{ marginLeft: "20px" }}
            >
              {source}
            </Text>
          ) : (
            <img
              src={sourceImage}
              style={{ width: 35, height: 35, borderRadius: 10 }}
              alt="source"
            />
          )}
        </TD> */}
                <TD
                    color="#7b6294"
                    fontWeight="500"
                    fontSize="14px"
                    // width="150px"
                    width="14%"
                >
                    {item?.enquiry_date}
                </TD>
                <TD
                    color="#7b6294"
                    fontWeight="500"
                    fontSize="14px"
                    // width="150px"
                    width="12%"
                >
                    {item.lead_age.total_days} days
                </TD>
                <TD
                    color="#7B6294"
                    fontSize="14px"
                    fontWeight="500"
                    // width="175px"
                    width="17%"
                    // style={{
                    //     textOverflow: "ellipsis",
                    //     whiteSpace: "nowrap",
                    //     overflow: "hidden",
                    // }}
                >
                    {item?.university}
                </TD>
                <TD
                    color="#7B6294"
                    fontSize="14px"
                    fontWeight="500"
                    // width="175px"
                    width="17%"
                >
                    {item?.course_short_name}
                </TD>
                {/* <TD
					// width="50px"
					style={{
						alignItems: "center",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<DownIcon
						onClick={() => {
							if (selectedLead === item.id) {
								setSelectedLead("");
							} else {
								setSelectedLead(item.id);
							}
						}}
						src={downArrow}
						selected={selectedLead === item.id ? true : false}
						alt="icon"
					/>
				</TD> */}
                {/* <TD
          color="#7b6294"
          fontWeight="500"
          fontSize="14px"
          // width="150px"
          width="15%"
        >
          {item.country_name}
        </TD> */}

                <TD
                    color="#7B6294"
                    fontSize="14px"
                    fontWeight="500"
                    // width="175px"
                    width="15%"
                >
                    {item.lead_source}
                </TD>
                {item.followup_exist ? (
                    <TD
                        color="#7B6294"
                        fontSize="14px"
                        fontWeight="500"
                        // width="175px"
                        width="10%"
                        style={{ textAlign: "center" }}
                    >
                        {/* &#10004; */}
                        &#9989;
                    </TD>
                ) : (
                    <TD
                        color="#7B6294"
                        fontSize="12px"
                        fontWeight="500"
                        // width="175px"
                        width="10%"
                        style={{ textAlign: "center" }}
                    >
                        &#10060;
                    </TD>
                )}

                <TLLD>
                    <FollowUpButton
                        onClick={() => {
                            setIsModal(true);
                            setSelectedLead(item.id);
                        }}
                    >
                        Follow Up
                    </FollowUpButton>
                    <IconContainer
                        onClick={(e) => {
                            e.preventDefault();

                            history.push(`/admission-consultant/edit-lead/${item.id}/`);
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        <Icon src={Edit} alt="Icon" />
                    </IconContainer>
                </TLLD>
            </MainDiv>
            {isModal && (
                <FollowUpModal
                    isModal={isModal}
                    setIsModal={setIsModal}
                    selectedLead={selectedLead}
                    getData1={getData1}
                />
            )}
        </>
    );
}

export default AssignedLeadListItem;

const MainDiv = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: ${(props) => (props.selected ? null : "1px solid #E6EFF5")};
    padding: 10px 20px;
    align-items: center;
`;

const DownIcon = styled.img`
    transform: ${(props) => (props.selected ? "rotate(180deg)" : null)};
    cursor: pointer;
    height: 10px;
`;
const TLLD = styled.div`
    display: flex;
    width: 153px;
    align-items: center;
`;
const ExtraBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 30px 20px 30px;
    border-bottom: 1px solid #e6eff5;
`;
const DetailBox = styled.div`
    display: flex;
    align-items: center;
`;
const DetailTitle = styled.div`
    color: #808080;
    font-size: 17px;
    font-weight: 500;
`;
const DetailText = styled.div`
    color: #7b6294;
    font-size: 16px;
    margin-left: 4px;
    font-weight: 500;
`;
const IconContainer = styled.div`
    width: 22px;
    height: 16px;
    margin-right: 8px;
    &:last-child {
        margin-right: 0;
    }
`;
const Icon = styled.img`
    width: 80%;
    display: block;
    object-fit: cover;
`;
const FollowUpButton = styled.div`
    width: 85px;
    margin-right: 10px;
    height: 37px;
    color: rgb(255, 255, 255);
    text-transform: none;
    background-color: rgb(82, 46, 146);
    border-radius: 10px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
