import React, { useState, useContext } from "react";
import styled from "styled-components";

import paidIcon from "../../../assets/images/status-paid-icon.svg";
import dueIcon from "../../../assets/images/status-due-icon.svg";
import onHoldIcon from "../../../assets/images/status-onhold-icon.svg";
import pendingIcon from "../../../assets/images/status-pending-icon.svg";
import downArrow from "../../../assets/images/down-arrow.svg";
import infoSymbol from "../../../assets/images/info.svg";
import editIcon from "../../../assets/images/finance-edit-icon.svg";
import { Context } from "../../../contexts/Store";
import { baseConfig } from "../../../utils/config";
import {
    Input,
} from "@material-ui/core";
import moment from "moment";
import Popover from "@mui/material/Popover";
import { FormControl, InputLabel, Modal, Select, MenuItem } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import EmiPreviewModal from "./EmiPreviewModal";
import EmiEditModal from "./EmiEditModal";

export default function StudentFeeTable({
    setFeePaid,
    feePaid,
    selectedEmiId,
    getEmiData,
    setSelectedEmiId,
    setSelectedStatusEmiId,
    setSelectedEmiStatus,
    setWarningModal,
    setDeleteWarning,
    setDeleteEmiId,
    handleResponseModal,
    isDeleted,
    setDeleted,
    selectedEmiData,
    allowedReferenceDiscount
}) {
    const { state } = useContext(Context);
    const [selectedEmiName, setSelectedEmiName] = useState("");
    const [selectedEmiAmount, setSelectedEmiAmount] = useState(0);

    const [isAlert, setIsAlert] = useState(false);
    const [alertStatus, setAlertStatus] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    // status dropdown related
    const dropdownStatuses = [
        {
            id: 1,
            name: "On Hold",
        },
        {
            id: 2,
            name: "On Break",
        },
    ];
    const [openedStatusEmiId, setOpenedStatusEmiId] = useState("");

    // related to duedate edit
    const [dueDate, setDueDate] = useState("");

    // Type & amount of selected discount to show in info popOver
    const [selectedDT, setSelectedDT] = useState("");
    const [selectedAMT, setSelectedAMT] = useState("");
    const [previewModal,setPreviewModal] = useState(false)
    const PreviewCloseModal = () => setPreviewModal(false)
    const [editModal,setEditModal] = useState(false)
    const EditCloseModal = () => setEditModal(false)
    const [editEmiData, setEditEmiData]= useState([])
    // related to fee payment
    const paymentModes = [
        {
            name: "Payment Link",
            value: "Payment Link",
        },
        {
            name: "Bank Transfer",
            value: "Bank Transfer",
        },
        {
            name: "Cash",
            value: "Cash",
        },
        {
            name: "POS",
            value: "POS",
        },
        {
            name: "Bank Remittance",
            value: "Bank Remittance",
        },
        {
            name: "Cheque",
            value: "Cheque",
        },
        {
            name: "Money Exchange",
            value: "Money Exchange",
        },
    ];
    const [selectedMOP, setSelectedMOP] = useState(paymentModes[0].value);
    const [dop, setDop] = useState(moment(Date.now()).format("YYYY-MM-DD"));

    // related to discount
    const discountTypes = [
        { name: "Normal", value: "Normal" }
    ];
    if (allowedReferenceDiscount > 0) {
        discountTypes.push({
            name: "Reference",
            value: "Reference",
        });
    }
    const [selectedDiscountType, setSelectedDiscountType] = useState(discountTypes[0].value);
    const [discountAmount, setDiscountAmount] = useState();

    // due-date edit popover onClick
    const [dueDateEditanchorEl, setdueDateEditAnchorEl] = React.useState(null);
    const handleClickdueDateEdit = (event, id) => {
        setdueDateEditAnchorEl(event.currentTarget);
        setSelectedEmiId(id);
    };
    const handleClosedueDateEdit = () => {
        setdueDateEditAnchorEl(null);
    };
    const opendueDateEdit = Boolean(dueDateEditanchorEl);
    const dueDateEditId = opendueDateEdit ? "simple-popover" : undefined;

    // fee pay popover onClick
    const [payFeeanchorEl, setpayFeeAnchorEl] = React.useState(null);
    const handleClickpayFee = (event) => {
        setpayFeeAnchorEl(event.currentTarget);
    };
    const handleClosepayFee = () => {
        setpayFeeAnchorEl(null);
        setFeePaid("");
    };
    const handlePayClick = (event, item) => {
        handleClickpayFee(event);
        setSelectedEmiId(item.id);
        setFeePaid(item.paid_amount);
        setDop(moment(item.dop).format("YYYY-MM-DD"));
        setSelectedEmiName(item.instalment);
    };
    const openpayFee = Boolean(payFeeanchorEl);
    const payFeeId = openpayFee ? "simple-popover" : undefined;

    //  edit paid fee popover onClick
    const [editPaidFeeanchorEl, setEditPaidFeeAnchorEl] = React.useState(null);

    const handleCloseEditPaidFee = () => {
        setEditPaidFeeAnchorEl(null);
        setFeePaid("");
    };
    const openEditPaid = Boolean(editPaidFeeanchorEl);
    const editPaidFeeId = openEditPaid ? "simple-popover" : undefined;

    // discount info mui popover onClick (discount information)
    const [discountIfoAnchorEl, setDiscountInfoAnchorEl] = React.useState(null);
    const handleClickDiscountInfo = (event, emiId, dType, dAmt) => {
        setDiscountInfoAnchorEl(event.currentTarget);
        setSelectedEmiId(emiId);
        setSelectedDT(dType);
        setSelectedAMT(dAmt);
    };
    const handleCloseDiscountInfo = () => {
        setDiscountInfoAnchorEl(null);
    };
    const openDiscountInfo = Boolean(discountIfoAnchorEl);
    const discountInfoId = openDiscountInfo ? "simple-popover" : undefined;

    // discount mui popover onClick (to submit discount details)
    const [discountAnchorEl, setDiscountAnchorEl] = React.useState(null);
    const handleClickDiscount = (event, id, name) => {
        setDiscountAnchorEl(event.currentTarget);
        setSelectedEmiId(id);
        setSelectedEmiName(name);
    };
    const handleCloseDiscount = () => {
        setDiscountAnchorEl(null);
    };
    const openDiscount = Boolean(discountAnchorEl);
    const discountId = openDiscount ? "simple-popover" : undefined;

    // To edit fee paid
    const handleEditPayment = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        formdata.append("dop", dop);
        formdata.append("mop", selectedMOP);
        if (feePaid) {
            formdata.append("paid_amount", feePaid);
        }
        baseConfig
            .post(`/finance/update-paid-emi/${selectedEmiId}/`, formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    getEmiData();
                    handleCloseEditPaidFee();
                    handleResponseModal()
                    setDeleted(false)
                } else {
                }
            })
            .catch((error, response) => {});
    };

    // To submit discount
    const handleDiscountSubmit = () => {
        if (
            discountAmount === "" ||
            discountAmount === null ||
            discountAmount === undefined ||
            selectedDiscountType === null ||
            discountAmount > selectedEmiData?.fee_payable ||
            (selectedDiscountType === "Reference" && discountAmount > allowedReferenceDiscount)
        ) {
            // setAlertMessage("Enter Correct Amount");
            // setAlertStatus("error");
            // setIsAlert(true);
            // setAlertSection("discount")
            alert(
                `Please enter a valid discount amount. The maximum allowed discount is AED${selectedEmiData?.fee_payable}, and the maximum allowed reference discount is AED${allowedReferenceDiscount}.`
            )
            return; // Exit early to prevent the API call
        }

        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        formdata.append("discount_amount", discountAmount);
        formdata.append("discount_type", selectedDiscountType);

        baseConfig
            .post(`/finance/add-emi-discount/${selectedEmiId}/`, formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                const { StatusCode } = response.data;
                if (StatusCode === 6000) {
                    getEmiData();
                    handleCloseDiscount();
                }
            })
            .catch((error) => {
                setAlertMessage("Failed to submit discount");
                setAlertStatus("error");
                setIsAlert(true);
            });
    };

    // to handle duedate edit,(edit emi api)
    const handleEditEmi = () => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        formdata.append("due_date", dueDate);

        baseConfig
            .put(`/students/student-emi/${selectedEmiId}/`, formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    // getStudentData();
                    getEmiData();
                    handleClosedueDateEdit();
                } else {
                }
            })
            .catch((error, response) => {});
    };

    const handleCloseAlert = () => {
        setIsAlert(false);
        setAlertStatus("");
    };

    const AlertBox = ({ status }) => (
        <Snackbar
          open={isAlert}
          autoHideDuration={30000}
          onClose={handleCloseAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          sx={{
            position: "fixed",
            bottom: "100px", // Adjust as needed
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
            maxWidth: "400px", // Optional: limit the max width
            zIndex: 1300, // Ensure it's above other content
          }}
        >
          <Alert onClose={handleCloseAlert} severity={status} sx={{ width: "100%" }}>
            {alertMessage}
          </Alert>
        </Snackbar>
      );

    return (
        <>
            <AlertBox status={alertStatus} />
            <MainDiv>
                <TableHeader>
                    <TH width="2%">No</TH>
                    <TH width="10%">Installment</TH>
                    <TH width="8%">Due Date</TH>
                    <TH width="9%">Inst. Amt</TH>
                    <TH width="8%">VAT ({selectedEmiData.vat}%)</TH>
                    <TH width="110px">Discount</TH>
                    <TH width="8%">Total Amt</TH>
                    <TH width="8%">DOP</TH>
                    <TH width="8%">MOP</TH>
                    <TH width="8%">Balance Amt</TH>
                    <TH width="110px">Status</TH>
                    <TH width="90px">
                        Fee Paid
                    </TH>
                    <TH width="50px" style={{ textAlign: "right" }}>Action</TH>
                </TableHeader>
                {selectedEmiData.emis?.map((item, index) => (
                    <>
                    <TR key={item.id}>
                        <TD className="td-common-styles" width="2%">
                            {index + 1}
                        </TD>
                        <TD className="td-common-styles" width="10%">
                            {item.instalment}
                        </TD>
                        <TDD
                            className="td-common-styles due-date-td"
                            width="8%"
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            {item.due_date
                                ? moment(item.due_date).format("DD-MM-YY")
                                : null}

                            {item.status === "Pending" && (
                                <EditIconDueDate
                                    src={editIcon}
                                    style={{ marginLeft: "8px" }}
                                    onClick={(event) => {
                                        handleClickdueDateEdit(event, item.id);
                                    }}
                                />
                            )}

                            {selectedEmiId === item.id && (
                                <Popover
                                    id={dueDateEditId}
                                    open={opendueDateEdit}
                                    anchorEl={dueDateEditanchorEl}
                                    onClose={handleClosedueDateEdit}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    sx={{
                                        "&& .MuiPopover-paper": {
                                            boxShadow:
                                                "rgba(0, 0, 0, 0.05) 0px 3px 8px",
                                        },
                                    }}
                                >
                                    <DetailedDateInputDiv>
                                        <DateInput
                                            type="date"
                                            value={dueDate}
                                            onChange={(e) => {
                                                setDueDate(e.target.value);
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <SubmitButton
                                                onClick={() => {
                                                    if (dueDate === "") {
                                                        alert("Select date");
                                                    } else {
                                                        handleEditEmi();
                                                    }
                                                }}
                                                style={{ marginTop: 0 }}
                                            >
                                                Submit
                                            </SubmitButton>
                                        </div>
                                    </DetailedDateInputDiv>
                                </Popover>
                            )}

                        </TDD>
                        <TD className="td-common-styles" width="9%">
                            {item.amount}
                        </TD>
                        <TD className="td-common-styles" width="8%">
                            {item.vat}
                        </TD>
                        <TD className="td-common-styles" width="110px">
                            {!item.discount_added && (
                                <>
                                    {item.status === "paid" ||
                                    item.status === "Paid" ||
                                    item.status === "On Hold" ||
                                    item.status === "On Break" ? (
                                        <DisabledPaidButton>
                                            + Discount
                                        </DisabledPaidButton>
                                    ) : (
                                        <PaidButton
                                            onClick={(event) =>
                                                handleClickDiscount(
                                                    event,
                                                    item.id,
                                                    item.instalment
                                                )
                                            }
                                        >
                                            + Discount
                                        </PaidButton>
                                    )}
                                </>
                            )}

                            {selectedEmiId === item.id && (
                                <Popover
                                    id={discountId}
                                    open={openDiscount}
                                    anchorEl={discountAnchorEl}
                                    onClose={handleCloseDiscount}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    sx={{
                                        "&& .MuiPopover-paper": {
                                            boxShadow:
                                                "rgba(0, 0, 0, 0.05) 0px 3px 8px",
                                        },
                                    }}
                                >
                                    <DetailedDiscountInputDiv>
                                        <PopOverTitleText>
                                            Discount Here - {selectedEmiName}
                                        </PopOverTitleText>
                                        <TopLineOne>
                                            <FormControl
                                                sx={{ mb: 1, width: "100%" }}
                                                size="small"
                                            >
                                                <InputLabel id="demo-select-small">
                                                    Type
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-select-small"
                                                    id="demo-select-small"
                                                    value={selectedDiscountType}
                                                    label="Discount Type"
                                                    style={{
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                        paddingLeft: "4px",
                                                    }}
                                                    onChange={(e) => {
                                                        setSelectedDiscountType(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    {discountTypes.map(
                                                        (item, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={
                                                                    item.value
                                                                }
                                                                style={{
                                                                    fontSize:
                                                                        "14px",
                                                                    fontWeight:
                                                                        "500",
                                                                }}
                                                            >
                                                                {item.name}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>

                                            <FeePaidInput
                                                type="number"
                                                placeholder="Discount Amount"
                                                onChange={(e) => {
                                                    setDiscountAmount(
                                                        parseInt(e.target.value)
                                                    );
                                                }}
                                            />
                                        </TopLineOne>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <SubmitButton
                                                onClick={() => {
                                                    handleDiscountSubmit();
                                                }}
                                            >
                                                Submit
                                            </SubmitButton>
                                        </div>
                                    </DetailedDiscountInputDiv>
                                </Popover>
                            )}

                            {item.discount_added && (
                                <DiscountAmountWrapper>
                                    <DiscountAmount>
                                        {item.discount_amount}
                                    </DiscountAmount>
                                    <InfoIcon
                                        src={infoSymbol}
                                        alt="icon"
                                        onClick={(event) =>
                                            handleClickDiscountInfo(
                                                event,
                                                item.id,
                                                item.discount_type,
                                                item.discount_amount
                                            )
                                        }
                                    />
                                    {selectedEmiId === item.id && (
                                        <Popover
                                            id={discountInfoId}
                                            open={openDiscountInfo}
                                            anchorEl={discountIfoAnchorEl}
                                            onClose={handleCloseDiscountInfo}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                            }}
                                            sx={{
                                                "&& .MuiPopover-paper": {
                                                    boxShadow:
                                                        "rgba(0, 0, 0, 0.05) 0px 3px 8px",
                                                },
                                            }}
                                        >
                                            <DiscountInfoCard>
                                                <DiscountTypeText>
                                                    Discount Type
                                                </DiscountTypeText>
                                                <DiscountLine>
                                                    <DiscountText>
                                                        {selectedDT}
                                                    </DiscountText>
                                                    <DiscountText>
                                                        {selectedAMT}
                                                    </DiscountText>
                                                </DiscountLine>
                                            </DiscountInfoCard>
                                        </Popover>
                                    )}
                                </DiscountAmountWrapper>
                            )}
                        </TD>
                        <TD className="td-common-styles" width="8%">
                            {item.total_amount}
                        </TD>
                        <TD className="td-common-styles" width="8%">
                            {item.dop
                                ? moment(item.dop).format("DD-MM-YYYY")
                                : "-- -- --"}
                        </TD>
                        <TD className="td-common-styles" width="8%">
                            {item.mop ? item.mop : "----"}
                        </TD>
                        <TD className="td-common-styles" width="8%">
                            {item.balance_amount}
                        </TD>
                        <TSD className="td-common-styles" width="110px">
                            <StatusButtonWrapper
                                onClick={() => {
                                    if (
                                        item.status !== "paid" &&
                                        item.status !== "Paid" &&
                                        item.status !== "On Hold" &&
                                        item.status !== "On Break"
                                    ) {
                                        if (openedStatusEmiId !== item.id) {
                                            setOpenedStatusEmiId(item.id);
                                        } else {
                                            setOpenedStatusEmiId("");
                                        }
                                    }
                                }}
                                opened={
                                    openedStatusEmiId !== item.id ? false : true
                                }
                            >
                                <StatusButton>
                                    <StatusIcon
                                        src={
                                            item.status === "Pending"
                                                ? pendingIcon
                                                : item.status === "paid"
                                                ? paidIcon
                                                : item.status === "Paid"
                                                ? paidIcon
                                                : item.status === "On Due"
                                                ? dueIcon
                                                : item.status === "On Hold"
                                                ? onHoldIcon
                                                : item.status === "On Break"
                                                ? onHoldIcon
                                                : null
                                        }
                                        alt="icon"
                                    />
                                    <StatusText>{item.status}</StatusText>
                                    {item.status !== "paid" &&
                                        item.status !== "Paid" &&
                                        item.status !== "On Hold" &&
                                        item.status !== "On Break" && (
                                            <DropDownIcon
                                                hidden={
                                                    openedStatusEmiId ===
                                                    item.id
                                                        ? true
                                                        : false
                                                }
                                                src={downArrow}
                                                alt="icon"
                                            />
                                        )}
                                </StatusButton>
                                {openedStatusEmiId === item.id && (
                                    <StatusDropDown
                                        opened={
                                            openedStatusEmiId !== item.id
                                                ? false
                                                : true
                                        }
                                    >
                                        {dropdownStatuses.map(
                                            (dropDownStatus, index) => (
                                                <StatusDropDownItem
                                                    key={index}
                                                    onClick={() => {
                                                        setSelectedEmiStatus(
                                                            dropDownStatus.name
                                                        );
                                                        setSelectedStatusEmiId(
                                                            item.id
                                                        );
                                                        setWarningModal(true);
                                                    }}
                                                >
                                                    {dropDownStatus.name}
                                                </StatusDropDownItem>
                                            )
                                        )}
                                    </StatusDropDown>
                                )}
                            </StatusButtonWrapper>
                        </TSD>
                        <TBD className="td-common-styles" width="90px">
                            {item.status !== "paid" &&
                            item.status !== "Paid" &&
                            item.status !== "On Hold" &&
                            item.status !== "On Break" ? (
                                "----"
                            ) : (
                                <PaidTextWrapper>
                                    <PaidText>{item.paid_amount}</PaidText>
                                    {selectedEmiId === item.id && (
                                        <Popover
                                            id={editPaidFeeId}
                                            open={openEditPaid}
                                            anchorEl={editPaidFeeanchorEl}
                                            onClose={handleCloseEditPaidFee}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                            }}
                                            sx={{
                                                "&& .MuiPopover-paper": {
                                                    boxShadow:
                                                        "rgba(0, 0, 0, 0.05) 0px 3px 8px",
                                                },
                                            }}
                                        >
                                            <DetailedRemarkInputDiv>
                                                <PopOverTitleText>
                                                    Fee Payment -{" "}
                                                    {selectedEmiName}
                                                </PopOverTitleText>
                                                <TopLine>
                                                    <Input
                                                        type="date"
                                                        value={dop}
                                                        onChange={(e) =>
                                                            setDop(
                                                                e.target.value
                                                            )
                                                        }
                                                        max={new Date()}
                                                        style={{
                                                            fontSize: "14px",
                                                            fontWeight: "500",
                                                        }}
                                                    />
                                                    <FormControl
                                                        sx={{
                                                            m: 1,
                                                            width: "50%",
                                                        }}
                                                        size="small"
                                                    >
                                                        <InputLabel id="demo-select-small">
                                                            Mode
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-select-small"
                                                            id="demo-select-small"
                                                            value={selectedMOP}
                                                            label="MOP"
                                                            style={{
                                                                fontSize:
                                                                    "14px",
                                                                fontWeight:
                                                                    "500",
                                                                paddingLeft:
                                                                    "4px",
                                                            }}
                                                            onChange={(e) => {
                                                                setSelectedMOP(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        >
                                                            {paymentModes.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <MenuItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            item.value
                                                                        }
                                                                        style={{
                                                                            fontSize:
                                                                                "14px",
                                                                            fontWeight:
                                                                                "500",
                                                                        }}
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </TopLine>
                                                <FeePaidInput
                                                    type="number"
                                                    placeholder="Amount Paid"
                                                    onChange={(e) => {
                                                        setFeePaid(
                                                            parseInt(
                                                                e.target.value
                                                            )
                                                        );
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <SubmitButton
                                                        onClick={() => {
                                                            if (dop === "") {
                                                                alert(
                                                                    "Select date"
                                                                );
                                                            } else if (
                                                                feePaid ===
                                                                    "" ||
                                                                feePaid ===
                                                                    null ||
                                                                feePaid ===
                                                                    undefined
                                                            ) {
                                                                alert(
                                                                    "Enter Amount"
                                                                );
                                                            } else {
                                                                handleEditPayment();
                                                            }
                                                        }}
                                                    >
                                                        Submit
                                                    </SubmitButton>
                                                </div>
                                            </DetailedRemarkInputDiv>
                                        </Popover>
                                    )}
                                </PaidTextWrapper>
                            )}
                            {selectedEmiId === item.id && (
                                <Popover
                                    id={payFeeId}
                                    open={openpayFee}
                                    anchorEl={payFeeanchorEl}
                                    onClose={handleClosepayFee}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    sx={{
                                        '& .MuiPaper-root': {
                                            border: '2px solid #D7D7D7',  
                                            padding: '16px',           
                                            borderRadius: '8px', 
                                            width:"300px"   
                                        },
                                    }}
                                >
                                        <PopOverTitleText>
                                            Fee Payment - {selectedEmiName}
                                        </PopOverTitleText>
                                        <TopLine>
                                        <FormControl
                                                sx={{  width: "100%",marginBottom:"20px" }}
                                                size="small"
                                            >
                                            <p style={{
                                                marginBottom:"5px"
                                                }}>Date</p>
                                            <Input
                                                type="date"
                                                value={dop}
                                                onChange={(e) =>
                                                    setDop(e.target.value)
                                                }
                                                max={new Date()}
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "500",
                                                }}
                                            />
                                        </FormControl>
                                            <FormControl
                                                sx={{  width: "100%",marginBottom:"20px" }}
                                                size="small"
                                            >
                                                <InputLabel id="demo-select-small">
                                                    Mode
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-select-small"
                                                    id="demo-select-small"
                                                    value={selectedMOP}
                                                    label="MOP"
                                                    style={{
                                                        fontSize:
                                                        "14px",
                                                    fontWeight:
                                                        "500",
                                                    padding:"8px",
                                                    // border:"1px solid #D7D7D7",
                                                    borderRadius:"6px",
                                                    background:"#fff",
                                                    }}
                                                    // onChange={handleMopChange}
                                                    onChange={(e) => {
                                                        setSelectedMOP(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    {paymentModes.map(
                                                        (item, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={
                                                                    item.value
                                                                }
                                                                style={{
                                                                    fontSize:
                                                                        "14px",
                                                                    fontWeight:
                                                                        "500",
                                                                }}
                                                            >
                                                                {item.name}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                            <p style={{
                                            marginBottom:"5px"
                                            }}>Amount</p>
                                        <FeePaidInput
                                        style={{
                                            fontSize:"14px",
                                            fontWeight:"500",
                                            padding:"22px 8px",
                                            border:"1px solid #D7D7D7",
                                            borderRadius:"6px",
                                                }}
                                            type="number"
                                            defaultValue={
                                                feePaid
                                            }
                                            placeholder="Amount Paid"
                                            onChange={(e) => {
                                                setFeePaid(
                                                    parseInt(e.target.value)
                                                );
                                            }}
                                        />
                                        <WarningText>These changes may affect other Instalments</WarningText>

                                        </TopLine>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <SubmitButton
                                                onClick={() => {
                                                    if (dop === "") {
                                                        alert("Select date");
                                                    } else if (
                                                        feePaid === "" ||
                                                        feePaid === null ||
                                                        feePaid === undefined
                                                    ) {
                                                        alert("Enter Amount");
                                                    } else {
                                                        handleEditPayment();
                                                    }
                                                }}
                                            >
                                                Submit
                                            </SubmitButton>
                                        </div>
                                </Popover>
                            )}
                        </TBD>
                        <TD className="td-common-styles" width="50px" style={{
                            display:"flex",
                            justifyContent:"space-between"
                        }}>
                            {
                                item.status === "On Hold" || item.status === "On Break" ? "" : 
                                <Icondiv onClick={(event)=>{
                                    // item.status === "Paid" ? 
                                    // handlePayClick(event, item) :
                                    setEditModal(true)
                                    setEditEmiData(item)
                                    setSelectedEmiAmount(item.amount)
                                }}>
                                <img src={require("../../../assets/images/edit.svg").default} alt="Icon"/>
                                </Icondiv>
                             }
                       
                            {/* {
                                item.status === "Paid" ? "" :   */
                                <DeleteIcon onClick={()=>{
                                    setDeleteWarning(true)
                                    setDeleteEmiId(item.id)
                                }}>
                                <img src={require("../../../assets/images/delete.svg").default} alt="Icon"/>
                                </DeleteIcon>
                            }
                        </TD>
                    </TR>
                  </>
                ))}

            </MainDiv>
                    <Modal
                        open={editModal}
                        onClose={EditCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        >
                      <EmiEditModal
                         setPreviewModal={setPreviewModal}
                         setEditModal={setEditModal}
                         data={editEmiData}
                         setData={setEditEmiData}
                         vatPercentage={selectedEmiData?.vat}
                         pendingInstalmentAmount={
                            (selectedEmiData?.instalment_pending_amount ? 
                                parseFloat(selectedEmiData?.instalment_pending_amount) : 
                                0) + parseFloat(selectedEmiAmount)}
                         feePayable={selectedEmiData?.fee_payable ? parseFloat(selectedEmiData?.fee_payable) : 0 }
                         allowedReferenceDiscount={allowedReferenceDiscount}
                      />
                  </Modal>

            <Modal
                open={previewModal}
                onClose={PreviewCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <EmiPreviewModal
                   PreviewCloseModal={PreviewCloseModal}
                   data={editEmiData}
                   setData={setEditEmiData}
                   getEmiData={getEmiData}
                   handleResponseModal={handleResponseModal}
                   isDeleted={isDeleted}
                   setDeleted={setDeleted}
                />
            </Modal>
        </>
    );
}

const MainDiv = styled.div`
    /* background: #ffffff;

    border: 1px solid #d7d7d7;
    border-radius: 12px;
    .data-row:nth-child(even) {
        background: #fbfbfb;
    } */
    background: #ffffff;
    padding: 6px;

    border: 1px solid #e7e7e7;
    border-radius: 16px;
    margin-top: 20px;
    transition-duration: 0.5s;
    .td-common-styles {
        margin-right: 2px;
        &:last-child {
            margin-right: 0;
        }
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 150% */

        /* dark/black */
        /* white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;*/

        color: #000000;
    }
`;
const TableHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 8px;
`;
const TH = styled.div`
    width: ${(props) => (props.width ? props.width : "8%")};
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #9c9c9c;
    margin-right: 3px;
    &:last-child {
        margin-right: 0;
    }
`;
const PopOverTitleText = styled.h6`
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #9c9c9c;
    margin-bottom: 12px;
    text-transform: capitalize;
`;
const TR = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 8px;
    &:nth-child(even) {
        background: #fbfbfb;
    }
`;
const TD = styled.div`
    text-transform: capitalize;
    width: ${(props) => (props.width ? props.width : "8%")};
`;
const Icondiv = styled.div`
    width:15px;
    display:flex;
    justify-content:flex-end;
    cursor:pointer;
    img{
        display:block;
        width:100%;
    }
`;
const EditIconDueDate = styled.img`
    width: 12px;
    object-fit: cover;
    display: block;
    margin-left: 2px;
    cursor: pointer;
    visibility: hidden;
`;
const TDD = styled.div`
    text-transform: capitalize;
    width: ${(props) => (props.width ? props.width : "8%")};
    &:hover ${EditIconDueDate} {
        visibility: visible;
    }
`;
const TBD = styled.div`
    width: ${(props) => (props.width ? props.width : "8%")};
    display: flex;
    // justify-content: flex-end;
    align-items: center;

    &.MuiPopover-root {
        box-shadow: 0 4px 12px rgb(23 23 23 / 90%) !important;
    }
    &.MuiPopover-paper {
        box-shadow: 0 4px 12px rgb(23 23 23 / 90%) !important;
    }
`;
const TSD = styled.div`
    width: ${(props) => (props.width ? props.width : "8%")};
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
const PaidButton = styled.div`
    width: 80px;
    background: #917afd;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    box-sizing: border-box;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    user-select: none;
    &:hover {
        opacity: 0.9;
    }
`;
const DisabledPaidButton = styled.div`
    width: 80px;
    background: #917afd;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    box-sizing: border-box;
    font-size: 12px;
    color: #fff;
    cursor: not-allowed;
    user-select: none;
    opacity: 0.25;
`;
const StatusButtonWrapper = styled.div`
    /* border: 1px solid #c1c1c1; */
    border: 1px solid #e7e7e7;
    border-bottom: ${(props) => (props.opened ? "none" : "1px solid #e7e7e7")};
    height: 32px;
    width: 90px;
    box-sizing: border-box;
    border-radius: ${(props) => (props.opened ? "12px 12px 0 0" : "24px")};
    position: relative;
    background: ${(props) => (props.opened ? "#fff" : null)};
`;
const StatusButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
`;
const StatusIcon = styled.img`
    width: 12px;
    display: block;
    object-fit: cover;
    margin-right: 6px;
`;
const StatusText = styled.p`
    color: #404040;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
    overflow: hidden;
    user-select: none;
    /* margin-right: 6px; */
`;
const StatusDropDown = styled.div`
    background: #fff;
    position: absolute;
    left: 50%;
    top: 31px;
    transform: translateX(-50%);
    padding: 0px 4px 4px 4px;
    z-index: 10;
    border-radius: ${(props) => (props.opened ? "0px 0px 12px 12px" : "")};
    width: 90px;
    box-sizing: border-box;
    border: 1px solid #e7e7e7;
    border-top: none;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const StatusDropDownItem = styled.div`
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    text-transform: capitalize;
    width: 100%;
    &:hover {
        background: #fbfbfb;
    }
`;
const DropDownIcon = styled.img`
    width: 10px;
    display: block;
    object-fit: cover;
    filter: invert(0.8);
    margin-left: 6px;
    transform: ${(props) => (!props.hidden ? null : "rotate(180deg)")};
    transition-duration: 0.3s;
`;
const TopLine = styled.div`
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // flex-direction: column; 
    border:1px solid #D7D7D7;
    border-radius:8px;
    background:#F8FAFC;
    height:225px;
    padding:20px;

    /* margin-bottom: 10px; */
`;
const FeePaidInput = styled.input`
    display: inline-block;
    width: 100%;
    height: 35px;
    /* margin-bottom: 6px; */
    font-size: 14px;
    font-weight: 500;
    padding: 6px;
    box-sizing: border-box;
    border: 1px solid #d8d8d8 !important;
`;
const SubmitButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 13px;
    padding: 4px;
    background: #917afd;
    width: 70px;
    height: 30px;
    box-sizing: border-box;
    margin-top: 8px;
    box-sizing: border-box;
    color: #fff;
    border-radius: 6px;
`;
const DetailedRemarkInputDiv = styled.div`
    width: 260px;
    padding: 6px;

    .textarea-label {
        display: block;
        border: 1px solid #d8d8d8;
        padding: 4px;
        /* border, padding, and other styles go here,
  don't set the height, and don't use floats or positioning */
    }
    .textarea-label:focus-within {
        border: 1px solid blue;
    }
`;
const DetailedDiscountInputDiv = styled.div`
    width: 200px;
    padding: 10px;

    .textarea-label {
        display: block;
        border: 1px solid #d8d8d8;
        padding: 4px;
        /* border, padding, and other styles go here,
  don't set the height, and don't use floats or positioning */
    }
    .textarea-label:focus-within {
        border: 1px solid blue;
    }
`;
const DiscountAmountWrapper = styled.div`
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
`;
const DiscountAmount = styled.div``;
const InfoIcon = styled.img`
    margin-left: 8px;
    width: 18px;
    object-fit: cover;
    cursor: pointer;
`;
const DiscountInfoCard = styled.div`
    padding: 15px;
`;
const DiscountTypeText = styled.span`
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #9c9c9c;
    margin-bottom: 10px;
`;
const DiscountLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const DiscountText = styled.div`
    font-size: 14px;
    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
`;
const DetailedDateInputDiv = styled.div`
    width: 220px;
    font-size: 13px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
`;
const DateInput = styled.input`
    display: inline-block;
    width: 100%;
    height: 30px;
    font-size: 13px;
    padding: 6px;
    box-sizing: border-box;
    border: 1px solid #d8d8d8 !important;
    margin-right: 6px;
`;
const PaidText = styled.h6`
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /* dark/black */
    /* white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;*/

    color: #000000;
`;
const EditIconPaid = styled.img`
    width: 12px;
    object-fit: cover;
    display: block;
    cursor: pointer;
    visibility: hidden;
`;
const PaidTextWrapper = styled.div`
    display: flex;
    align-items: center;
    &:hover ${EditIconPaid} {
        visibility: visible;
    }
`;
const DeleteIcon = styled.div`
    width:17px;
    img{
        display:block;
        width:100%;
    }
`;
const WarningText = styled.p`
    color:#9c9c9c;
    font-size:12px;
    margin-top:12px;
`;
const TopLineOne = styled.div`
    border:1px solid #D7D7D7;
    border-radius:8px;
    background:#F8FAFC;
    height:95px;
    padding:20px;
`;