import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import React, { useEffect, useState, useContext } from "react";
import { Context } from "./../../contexts/Store";
import Text from "../../components/Text";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { baseConfig } from "./../../utils/config";
import DeleteModal from "./../../components/includes/DeleteModal";

//svg
import Add from "./../../assets/images/addbutton.svg";
import Delete from "./../../assets/images/action-delete.svg";

function AllStaff() {
	const history = useHistory();
	const { state } = useContext(Context);

	const [subDepartmentList, setSubDepartmentList] = useState([]);
	const [deleteId, setDeleteId] = useState("");
	const [isModal, setModal] = useState(false);

	const getStaffs = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/campus/staffs/", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setSubDepartmentList(data.data);
				} else {
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		getStaffs();
	}, []);

	const handleDeleteData = () => {
		let accessToken = state.user_details.access_token;

		baseConfig
			.delete(`/campus/staff/${deleteId}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				let { StatusCode, data, message } = response.data;
				if (StatusCode == 6000) {
					setModal(false);
					getStaffs();
				} else if (StatusCode == 6001) {
					alert(data.data.message);
					getStaffs();
				}
			})
			.catch((error) => {});
	};

	return (
		<>
			<Wrapper>
				<Top>
					<TopLeft>
						<Title fontSize="35px">Staffs</Title>
						<Text
							fontSize="20px"
							fontWeight="400"
							color="#000"
							style={{ marginTop: "10px" }}
						>
							Staffs / All Staffs
						</Text>
					</TopLeft>
					<TopRight
						onClick={() => {
							history.push("/campus-admin/add-staff/");
						}}
					>
						<img src={Add} alt="icon" />
						<Text
							fontSize="16px"
							fontWeight="400"
							color="#fff"
							style={{ marginLeft: "5px" }}
						>
							Add Staff
						</Text>
					</TopRight>
				</Top>
				<Div>
					<TH
						color="#808080"
						fontSize="15px"
						height="60px"
						fontWeight="600"
						width="5%"
						style={{ marginLeft: "20px" }}
					>
						ID No.
					</TH>
					<TH
						color="#808080"
						fontSize="15px"
						height="60px"
						fontWeight="600"
						width="15%"
					>
						Name
					</TH>
					<TH
						color="#808080"
						fontSize="15px"
						height="60px"
						fontWeight="600"
						width="20%"
					>
						Email
					</TH>
					<TH
						color="#808080"
						fontSize="15px"
						height="60px"
						fontWeight="600"
						width="15%"
					>
						Phone
					</TH>
					<TH
						color="#808080"
						fontSize="15px"
						height="60px"
						fontWeight="600"
						width="15%"
					>
						Username
					</TH>
					<TH
						color="#808080"
						fontSize="15px"
						height="60px"
						fontWeight="600"
						width="15%"
					>
						Department
					</TH>
					<TH
						color="#808080"
						fontSize="15px"
						height="60px"
						fontWeight="600"
						width="15%"
					>
						Action
					</TH>
				</Div>
				{subDepartmentList.map((item) => (
					<Body>
						<TH
							color="#7B6294"
							fontSize="15px"
							height="60px"
							fontWeight="500"
							width="5%"
							style={{ marginLeft: "20px" }}
						>
							{item.staff_id}
						</TH>
						<TH
							color="#7B6294"
							fontSize="15px"
							height="60px"
							fontWeight="500"
							width="15%"
						>
							{item.name}
						</TH>
						<TH
							color="#7B6294"
							fontSize="15px"
							height="60px"
							fontWeight="500"
							width="20%"
						>
							{item.user_data.email}
						</TH>
						<TH
							color="#7B6294"
							fontSize="15px"
							height="60px"
							fontWeight="500"
							width="15%"
						>
							{item.phone}
						</TH>
						<TH
							color="#7B6294"
							fontSize="15px"
							height="60px"
							fontWeight="500"
							width="15%"
						>
							{item.user_data.username}
						</TH>
						<TH
							color="#7B6294"
							fontSize="15px"
							height="60px"
							fontWeight="500"
							width="15%"
						>
							{item.department_name}
						</TH>
						<TH
							color="#7B6294"
							fontSize="15px"
							height="60px"
							fontWeight="500"
							width="15%"
						>
							<img
								src={Delete}
								alt="Delete"
								style={{ cursor: "pointer" }}
								onClick={() => {
									setModal(true);
									setDeleteId(item?.id);
								}}
							/>
						</TH>
					</Body>
				))}
			</Wrapper>
			{isModal && (
				<DeleteModal
					isModal={isModal}
					setModal={setModal}
					deleteId={deleteId}
					handleDeleteData={handleDeleteData}
				/>
			)}
		</>
	);
}

export default AllStaff;

const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
`;
const TopLeft = styled.div``;
const TopRight = styled.div`
	display: flex;
	align-items: center;
	background-color: #522e92;
	height: 50px;
	border-radius: 3px;
	padding: 0 20px;
	cursor: pointer;
`;
const Div = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #f1f8fe;
	width: ${(props) => (props.width ? props.width : "100%")};
	border-bottom: 1px solid #cce7ff;
	border-top: 1px solid #e7e7e7;
`;
const TH = styled.div`
	font-family: "Inter";
	font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
	color: ${(props) => (props.color ? props.color : "#505887")};
	font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "600")};
	text-align: left;
	width: ${(props) => (props.width ? props.width : "15%")};
	// padding: 10px 0;
	height: ${(props) => (props.height ? props.height : "60px")};
	display: flex;
	align-items: center;
`;
const Body = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #fff;
	width: ${(props) => (props.width ? props.width : "100%")};
	border-bottom: 1px solid #e7e7e7;
`;
