import React, { useEffect, useState, useContext } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import visible from "../../assets/images/visible.svg";
import { useHistory, useLocation } from "react-router-dom";
import {
    UpdateSalesPersonService,
    GetSalesPersonService,
} from "../../service/salesPerson";
import { baseConfig } from "./../../utils/config";
import { validatePassword, capitalizeString } from "./../../helpers/Functions";
import { Context } from "./../../contexts/Store";
import ProfilePicture from "../../components/ProfilePicture";
import openedEye from "../../assets/images/eye-open.svg";
import closedEye from "../../assets/images/eye-close.svg";
import AlertBox from "../../components/includes/AlertBox";
import SweetAlert from "../../components/includes/SweetAlert";
function UpdateSalesPersonAdmin() {
    const history = useHistory();
    const location = useLocation();
    const [data, setData] = useState(location?.state);

    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [fieldName, setFieldName] = useState("");
    const [profile, setProfile] = useState(null);
    const [fetchedImage, setFetchedImage] = useState(null);
    const [employeeID, setEmployeeID] = useState("");

    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("");
    const [target, setTarget] = useState("");
    const [salesManagers, setSalesManagers] = useState([]);

    const [salesManagerName, setSalesManagerName] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [emailValidation, setEmailValidation] = useState(false);
    const [passwordValidate, setPasswordValidate] = useState({});
    const [pk, setPk] = useState(data.id);

    // sweetalert states and functions
    const [isSweetAlert, setIsSweetAlert] = useState(false);
    const [sweetAlertContent, setSweetAlertContent] = useState({
        title: "",
        message: "",
        icon: "",
        confirmButtonText: "",
        isCancelButton: false,
    });
    const handleSweetAlertConfirm = () => {
        history.push("/superadmin/admission-consultant/");
    };
    // end of sweetalert

    // alert mui message
    const [alertMessage, setAlertMessage] = useState(null);
    // Track the message state

    const [form, setFormValues] = useState({});
    const { state } = useContext(Context);

    useEffect(() => {
        setPasswordValidate(validatePassword(password));
    }, [password]);

    const criteria = passwordValidate.is_strong_password;

    const getLeadManagerList = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/leads/lead-manager/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                // params: {
                // 	search: searchItem,
                // },
            })
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode === 6000) {
                    setSalesManagers(data.data);
                    // setLoading(false);
                } else {
                    // setLoading(false);
                    alert(message);
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        getInitialData();
        getLeadManagerList();
    }, []);

    const getInitialData = async () => {
        let data = await GetSalesPersonService(location.state?.id, state);
        if (data.StatusCode === 6000) {
            setId(data.data.data.admin_id);
            setName(data.data.data.name);
            setEmail(data.data.data.user_data.email);
            setUserName(data.data.data.user_data.username);
            setCountry(data.data.data.country);
            setPhone(data.data.data.phone);
            setPassword(data.data.data.password);
            setTarget(data.data.data.target);
            setSalesManagerName(data.data.data.lead_manager);
            // setConfirmPassword(data.data.data.password);
            setFetchedImage(data.data.data.image);
            setEmployeeID(data.data.data.employee_id);
        } else {
            alert(data.data.message);
        }
    };

    const handleChangeValue = (key, value) => {
        setFormValues({ ...form, [key]: value });
    };

    const handleSubmit = async () => {
        let body = { id: form.id };
        body.username = form?.username
            ? form?.username
            : form?.user_data?.username;
        body.email = form?.email ? form?.email : form?.user_data?.email;
        body.name = form?.name ? form?.name : form?.user_data?.name;

        if (
            form["confirmPassword"] &&
            form["password"] !== form["confirmPassword"]
        ) {
            return alert("Incorrect password");
        } else {
            body.password = form.password;
        }

        let data = await UpdateSalesPersonService(body);
        if (data.StatusCode === 6000) {
            history.push("/superadmin/admission-consultant/");
        } else {
            alert(data.data.message);
        }
    };

    const handleUpdateForm = () => {
        let accessToken = state.user_details.access_token;

        var formdata = new FormData();
        formdata.append("name", name);
        formdata.append("email", email);
        formdata.append("username", userName);
        formdata.append("password", password);
        formdata.append("country", country);
        formdata.append("phone", phone);
        formdata.append("employee_id", employeeID);
        formdata.append("target", target);
        formdata.append("lead_manager", salesManagerName);
        if (profile !== null) {
            formdata.append("image", profile);
        }

        baseConfig
            .put(`/sales-persons/sales-person/${pk}/`, formdata, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode === 6000) {
                    // history.push("/superadmin/admission-consultant/");
                    setSweetAlertContent({
                        title: "Success",
                        message: "Admission Consultant edited successfully",
                        icon: "success",
                        confirmButtonText: "Ok",
                        isCancelButton: false,
                    });
                    setIsSweetAlert(true);
                } else {
                    setAlertMessage(data.data.message);
                }
            })
            .catch((error) => {
                setAlertMessage(error);
            });
    };
    return (
        <Wrapper>
            <AlertBox
                message={alertMessage}
                setMessage={setAlertMessage}
                severity={
                    alertMessage && alertMessage.includes("successful")
                        ? "success"
                        : "error"
                }
                autoClose={true}
                onClose={() => setAlertMessage(null)}
            />
            <SweetAlert
                sweetAlertContent={sweetAlertContent}
                setSweetAlertContent={setSweetAlertContent}
                isSweetAlert={isSweetAlert}
                setIsSweetAlert={setIsSweetAlert}
                onConfirm={handleSweetAlertConfirm}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                }}
            >
                <div>
                    <Title fontSize="35x">Update Admission Consultant</Title>
                    {/* <Text
						style={{
							// margin: "12px 23px",
							fontFamily: "Inter",
							fontWeight: "500",
						}}
						color="grey"
						fontSize="18px"
					>
						Admins / Update Admission Consultant
					</Text> */}
                </div>

                {/* <div>
                    <Button
                        href="#"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0 22px",
                        }}
                        backgroundColor="white"
                        fontSize="15px"
                        padding="18px 57px"
                        onClick={(e) => {
                            e.preventDefault();
                            const form = document.getElementById("form");
                            form.reset();
                            setFormValues({});
                            setName("");
                            setEmail("");
                            setUserName("");
                            setPassword("");
                            setPhone("");
                            setCountry("");
                            setTarget("");
                            setSalesManagerName("");
                            // setConfirmPassword("");
                        }}
                    >
                        <Text color="  #EE1451" fontSize="15px">
                            Clear Form
                        </Text>
                    </Button>
                </div> */}
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Container
                    style={{ flexWrap: "wrap " }}
                    width="100%"
                    margin="0"
                >
                    <form id="form">
                        <div
                            style={{
                                marginBottom: "20px",
                            }}
                        >
                            <ProfilePicture
                                setFile={setProfile}
                                file={profile}
                                fetchedImage={fetchedImage}
                                setFetchedImage={setFetchedImage}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Input
                                value={id}
                                onChange={(value) =>
                                    // handleChangeValue("id", value)
                                    {
                                        setId(value);
                                        setError(false);
                                    }
                                }
                                type="email"
                                name="Admin ID  (auto generated)"
                                // value="#0021"
                                disabled
                            />
                            <Input
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={name}
                                onChange={(value) => {
                                    setName(capitalizeString(value));
                                    setError(false);
                                }}
                                type="email"
                                name="Name"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Input
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={email}
                                onChange={(value) => {
                                    setEmail(value);
                                    setError(false);
                                }}
                                type="name"
                                name="Email"
                            />

                            <Input
                                onChange={(value) => {
                                    setEmployeeID(value);
                                    setError(false);
                                }}
                                type="name"
                                name="Employee ID"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={employeeID}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "20px",
                                justifyContent: "space-between ",
                            }}
                        >
                            <Input
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={country}
                                onChange={(value) => {
                                    setCountry(value);
                                    setError(false);
                                }}
                                type="text"
                                name="Country"
                            />
                            <Input
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={phone}
                                onChange={(value) => {
                                    setPhone(value);
                                    setError(false);
                                }}
                                type="number"
                                name="Phone"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "20px",
                                justifyContent: "space-between ",
                            }}
                        >
                            <Input
                                onChange={(value) => {
                                    setTarget(value);
                                    setError(false);
                                }}
                                type="number"
                                name="Target (UAE)"
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={target}
                            />
                            <SelectInput
                                name="Choose sales manager"
                                type="text"
                                placeholder="Choose sales manager"
                                onChange={(value) => {
                                    setSalesManagerName(value);
                                    setError(false);
                                }}
                                options={salesManagers ? salesManagers : []}
                                selectName={"name"}
                                selectValue={"id"}
                                // value={form?.salesManager}
                                value={salesManagerName}
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                defaultValue="Select a sales manager"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "20px",
                                justifyContent: "space-between ",
                            }}
                        >
                            <Input
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                value={userName}
                                onChange={(value) => {
                                    setUserName(value);
                                    setError(false);
                                }}
                                type="type"
                                name="Username"
                            />
                            <Input
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                                onChange={(value) => {
                                    setPassword(value);
                                    setError(false);
                                }}
                                icon={
                                    form?.passwordShow ? openedEye : closedEye
                                }
                                type={form?.passwordShow ? "text" : "password"}
                                name="Password"
                                value={password}
                                onClickVisible={() =>
                                    handleChangeValue(
                                        "passwordShow",
                                        !form?.passwordShow
                                    )
                                }
                            />

                            {/* <Input
								isError={isError}
								errorMessage={errorMessage}
								fieldName={fieldName}
								onChange={(value) => {
									setConfirmPassword(value);
									setError(false);
								}}
								type={
									form?.confirmPasswordShow
										? "text"
										: "password"
								}
								name="Confirm Password"
								value={confirmPassword}
								onClickVisible={() =>
									handleChangeValue(
										"confirmPasswordShow",
										!form?.confirmPasswordShow
									)
								}
							/> */}
                        </div>
                        <div style={{ display: "flex", marginTop: "21px" }}>
                            <Button
                                href="#"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                bacerkgroundColor="#5A2D8F"
                                fontSize="15px"
                                padding="21px 70px"
                                onClick={(e) => {
                                    if (name === "") {
                                        setError(true);
                                        setFieldName("Name");
                                        setErrorMessage("Enter name");
                                    } else if (email === "") {
                                        setError(true);
                                        setFieldName("Email");
                                        setErrorMessage(
                                            "Please enter a valid email"
                                        );
                                    } else if (userName === "") {
                                        setError(true);
                                        setFieldName("Username");
                                        setErrorMessage(
                                            "Please enter username"
                                        );
                                    } else if (country === "") {
                                        setError(true);
                                        setFieldName("Country");
                                        setErrorMessage("Please enter country");
                                    } else if (phone === "") {
                                        setError(true);
                                        setFieldName("Phone");
                                        setErrorMessage("Please enter phone");
                                    } else if (password.length < 8) {
                                        setError(true);
                                        setFieldName("Password");
                                        setErrorMessage(
                                            "Be a minimum of 8 characters"
                                        );
                                    } else if (criteria === false) {
                                        setError(true);
                                        setFieldName("Password");
                                        setErrorMessage(
                                            "Strong passwords consist of a combination of uppercase and lowercase letters, numbers and special symbols"
                                        );
                                    }
                                    // else if (password !== confirmPassword) {
                                    // 	setError(true);
                                    // 	setFieldName("Confirm Password");
                                    // 	setErrorMessage(
                                    // 		"Both password must be same"
                                    // 	);
                                    // }
                                    else {
                                        e.preventDefault();
                                        handleUpdateForm();
                                    }
                                }}
                            >
                                <Text color="white" fontSize="15px">
                                    Submit
                                </Text>
                            </Button>

                            <Button
                                onClick={() => {
                                    history.push(
                                        "/superadmin/admission-consultant/"
                                    );
                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 22px",
                                }}
                                backgroundColor="#F4F4F4"
                                fontSize="15px"
                                padding="21px 70px"
                            >
                                <Text color=" #522e92" fontSize="15px">
                                    Cancel
                                </Text>
                            </Button>
                        </div>
                    </form>
                </Container>
            </div>
        </Wrapper>
    );
}

export default UpdateSalesPersonAdmin;
