import { useEffect, useState } from "react";
import "./App.css";
import "./assets/css/global.css"
import SuperAdmin from "./pages/superAdmin/index";
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from "react-router-dom";
import LeadGeneratorAdmin from "./pages/leadGeneratorAdmin/index";
import LeadManagerAdmin from "./pages/leadManagerAdmin/index";
import SalesPerson from "./pages/salesPerson/index";
import Login from "./pages/login";
import Store from "./contexts/Store";

import MainRouter from "./routing/routers/MainRouter";

function App() {
	// useEffect(() => {
	// 	const initialValue = document.body.style.zoom;

	// 	// Change zoom level on mount
	// 	document.body.style.zoom = "80%";

	// 	return () => {
	// 		// Restore default value
	// 		document.body.style.zoom = initialValue;
	// 	};
	// }, []);

	return (
		<Store>
			<MainRouter />
		</Store>
	);
}

export default App;
