import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";

// import Icon from "../../assets/images/list-icon.svg";
import TD from "../TD";
import Line from "../../assets/images/Line.svg";
import CustomizedDialogs from "./popup";
import styled from "styled-components";
import FollowUpModal from "./FollowUpModal";
import Edit from "../../assets/images/editfollowup.svg";
import favorite from "../../assets/images/favorite.svg";
import nonfavorite from "../../assets/images/notfavorite.svg";
import FollowImg from "../../assets/images/FollowUpAdd.svg";
import { Popover } from "@mui/material";
import moment from "moment";
import { baseConfig } from "../../utils/config";

export default function AssignedListItem({
    status,
    statusType,
    item,
    state,
    apply,
    getData1,
    // statusValue,
    setselectedLead,
    selectedLead,
    selection,
    // setSelectedStatus,
    // setRemarkText,
    // submitFollowUp,
}) {
    const history = useHistory();

    const [statusLabel, setStatusLabel] = useState("Not Intrested");
    const [statusBackground, setStatusBackground] = useState("#FF5B5B26");
    const [statusColor, setStatusColor] = useState("#FF5B5B");
    const [favColor, setFavColor] = useState(false);

    const [isModal, setIsModal] = useState(false);

    const favoritepost = (e) => {
        let accessToken = state.user_details.access_token;
        var formdata = new FormData();
        if(item.is_favorite === true){
        formdata.append("is_favorite" , false);
        }else{
            formdata.append("is_favorite" , true);
        }
            var method = baseConfig.post;
        var URL =  `/leads/update-lead-favorite-status/${item.id}/`;

        method(URL, formdata,{
            headers:{
                Authorization: "Bearer " + accessToken,
                "Content-Type" : "multipart/formdata",
                Accept: "application/json",
            },
        })
        // baseConfig
        //     .post(
        //         `/leads/update-lead-favorite-status/${item.id}/`,
        //         {
        //            is_favorite: isClick,
        //         },
        //         {
        //             headers: {
        //                 Authorization: "Bearer " + accessToken,
        //             },
        //         }
    .then((response)=>{
                let { StatusCode, message, data } = response.data;
                if (StatusCode == 6000) {
                    getData1();
                } else {
                }
            }).catch((error)=>{

            }) }
            // const getData1 = () => {
            //     let accessToken = state.user_details.access_token;
            //     baseConfig
            //         .get(`/leads/followup-leads/`, {
            //             headers: {
            //                 Authorization: "Bearer " + accessToken,
            //             },
            //             params: {
                            
            //             },
            //         })
            //         .then((response) => {
            //             const { StatusCode, data } = response.data;
            //             if (StatusCode === 6000) {
            //                 setStatusColor(data.data)
            //             } else {
            //             }
            //         })
            //         .catch((error) => {});
            // };        
    useEffect(() => {
        if (status === "Not interested") {
            setStatusLabel("Not Intrested");
            setStatusBackground("#FF5B5B26");
            setStatusColor("#FF5B5B");
        } else if (status === "Converted" || status === "Need Followup") {
            setStatusLabel(status);
            setStatusBackground("#FFBB5426");
            setStatusColor("#FFBB54");
        } else if (status === "Interested" || status === "Approved" || status === "Student") {
            setStatusLabel(status);
            setStatusBackground("#2ED6A326");
            setStatusColor("#00A389");
        } else {
            setStatusLabel(status);
            setStatusBackground("#FF5B5B26");
            setStatusColor("#FF5B5B");
        }
    }, [status]);

    // mui Detailed remark popover on hover
    const [remarkAnchorEl, setRemarkAnchorEl] = React.useState(null);
    const handleRemarkPopoverOpen = (event) => {
        setRemarkAnchorEl(event.currentTarget);
    };
    const handleRemarkPopoverClose = () => {
        setRemarkAnchorEl(null);
    };
    const remarkOpen = Boolean(remarkAnchorEl);

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #E6EFF5",
                    padding: "10px 20px 10px 20px",
                    alignItems: "center",
                }}
            >
                
                <TD style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                }} width="7.5%" color="#505887" fontSize="14px">
                <div onClick={()=> favoritepost()}
                width="3%"
                >
                    <img 
                        style={{
                            width: "18px"
                        }}
                        src={item.is_favorite === true ? favorite : nonfavorite} alt="icon"
                    />
                </div>
                <span style={{marginLeft: "10px",color: "#505887",fontSize: "14px"}}>{item.auto_id}</span>
                    
                </TD>

                <TD style={{}} width="11%" color="#505887" fontSize="14px">
                    {item.name}
                </TD>
                {apply.includes("university")  ?
                    <TD
                    width="11%"
                    fontSize="14px"
                    color="#505887"
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        // marginRight: "0.5%",
                    }}
                >
                    {item.university}
                </TD> 
                : ""
                }
                {apply.includes("course")  ?
                    <TD
                    width="8%"
                    fontSize="14px"
                    color="#505887"
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "left",
                    }}
                    >
                    {item.course}
                    </TD>
                : ""
                }
                {apply.includes("location") || apply.length === 0 ?
                    <TD
                    style={{ overflow: "hidden", textOverflow: "ellipsis", textAlign: "left" }}
                    width="10%"
                    color="#505887"
                    fontSize="14px"
                    
                >
                    {item.country} 
                </TD>
                : ""
                }
                {apply.includes("phone") || apply.length === 0 ?
                    <TD
                    width="11%"
                    color="#505887"
                    fontSize="14px"
                    style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                >
                    {item.phone}
                </TD>
                : ""
                }
                {apply.includes("enquiry_date") || apply.length === 0 ?
                    <TD
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    width="12%"
                    color="#505887"
                    fontSize="14px"
                >
                    {moment(item?.enquiry_date).format("DD-MMM-YY")}
                </TD>
                : ""
                }
                {apply.includes("lead_source")  ?
                    <TD
                    width="12%"
                    fontSize="14px"
                    // color="#505887"
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                >
                    {item.lead_source}
                </TD>
                : ""
                }
                {apply.includes("assign_to")  ?
                    <TD style={{}} width="11%" color="#505887" fontSize="14px">
                    {item.assign_to}
                </TD>
                : ""
                }
                {apply.includes("consultant_choice")  ?
                    <TD style={{}} width="18%" color="#505887" fontSize="14px">
                    {item.consultant_choice ? item.consultant_choice : "--"}
                </TD>
                : ""
                }
                {apply.includes("country")  ?
                    <TD style={{}} width="11%" color="#505887" fontSize="14px">
                    {item.country}
                    </TD>
                : ""
                }
                {apply.includes("lead_campaign")  ?
                     <TD style={{}} width="15%" color="#505887" fontSize="14px">
                     {item.lead_campaign}
                    </TD>
                : ""
                }
                {apply.includes("lead_stage")  ?
                    <TD style={{}} width="11%" color="#505887" fontSize="14px">
                    {item.lead_stage}
                </TD>
                : ""
                }
                {apply.includes("nationality")  ?
                    <TD style={{}} width="11%" color="#505887" fontSize="14px">
                    {item.nationality}
                </TD>
                : ""
                }
                {apply.includes("phone_2")  ?
                    <TD style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }} width="9%" color="#505887" fontSize="14px">
                    {item.phone_2}
                </TD>
                : ""
                }
                {apply.includes("email")  ?
                    <TD style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }} width="11%" color="#505887" fontSize="14px">
                    {item.email}
                </TD>
                : ""
                }
                {apply.includes("specialization")  ?
                    <TD style={{
                        
                    }} width="12%" color="#505887" fontSize="14px">
                    {item.specialization ? item.specialization : "--"}
                </TD>
                : ""
                }
                {apply.includes("lead_status") || apply.length === 0  ?
                    <TD
                    width="7%"
                    fontSize="14px"
                    // color="#505887"
                    color={
                        item.status === "hot"
                            ? "#FC6D66"
                            : item.status === "warm"
                            ? "#87C655"
                            : item.status === "cold"
                            ? "#888EFF"
                            : null
                    }
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                        height: "23px",
                        background:
                            item.status === "hot"
                                ? "#FFF0EF"
                                : item.status === "warm"
                                ? "#EEFFE1"
                                : item.status === "cold"
                                ? "#EFF0FF"
                                : null,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "24px",
                        marginRight: "1%",
                    }}
                >
                    {item.status}
                </TD>
                : ""
                }
                {apply.includes("nfd") || apply.length === 0 ?
                    <TD
                    width="9%"
                    fontSize="14px"
                    color="#505887"
                    style={{
                        marginLeft: "20px",
                    }}
                    // style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                    {item.nfd}
                </TD>
                : ""
                }
                {apply.includes("lfd") || apply.length === 0 ?
                    <TD
                    width="9%"
                    fontSize="14px"
                    color="#505887"
                    // style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                    {item.lfd}
                </TD>
                : ""
                }
                {apply.includes("remark") || apply.length === 0 ?
                    <TD
                    width="14%"
                    fontSize="14px"
                    color="#505887"
                    // style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    onMouseEnter={handleRemarkPopoverOpen}
                    onMouseLeave={handleRemarkPopoverClose}
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                >
                    {item.remark}
                    <Popover
                        id="mouse-over-popover"
                        sx={{
                            pointerEvents: "none",
                        }}
                        open={remarkOpen}
                        anchorEl={remarkAnchorEl}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        onClose={handleRemarkPopoverClose}
                        disableRestoreFocus
                    >
                        <DetailedRemarkDiv>
                            <DetailedRemarkText>{item.remark}</DetailedRemarkText>
                        </DetailedRemarkDiv>
                       
                    </Popover>
                </TD>

                 : ""
                }
                
                
                
                
                {/* <TD
				width="15%"
				color="#505887"
				fontSize="14px"
				style={{ textOverflow: "ellipsis", overflow: "hidden" }}
			>
				{course}
			</TD> */}
                {/* <TD
				width="15%"
				color="#505887"
				fontSize="14px"
				style={{ textOverflow: "ellipsis", overflow: "hidden" }}
			>
				{country}
			</TD> */}
                {/* <TD width="18%" color="#505887" fontSize="14px">
          {item.email}
        </TD> */}

                {/* <TD
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          width="16%"
          color="#505887"
          fontSize="14px"
        >
          {item.country_name}
        </TD> */}
                
                

                {/* <TD width="15%" fontSize="14px">
                    <StatusText
                        color={statusColor}
                        background={statusBackground}
                    >
                        {statusLabel}
                    </StatusText>
                </TD> */}
                
                
                
                
               
                
                
                
                
                
                {/* {item.status === "hot" && (
                    <TD
                        width="9%"
                        fontSize="14px"
                        color="#505887"
                        color={
                            item.status === "hot"
                                ? "#f00"
                                : item.status === "warm"
                                ? "#0f0"
                                : item.status === "cold"
                                ? "#00f"
                                : null
                        }
                        style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            textTransform: "capitalize",
                        }}
                    >
                        <BgBox>{item.status}</BgBox>
                    </TD>
                )} */}
                
                {/* <TD
                    width="7%"
                    fontSize="14px"
                    // color="#505887"
                    color={
                        item.status === "hot"
                            ? "#f00"
                            : item.status === "warm"
                            ? "#0f0"
                            : item.status === "cold"
                            ? "#00f"
                            : null
                    }
                >
                    {item.status}
                </TD> */}
                
                
                {/* {statusType === "followup" && ( */}
                
                
                

                <TLLD>
                    <FollowUpButton
                        onClick={() => {
                            setIsModal(true);
                            setselectedLead(item.id);
                        }}
                        src={FollowImg} alt="Icon"
                    />
                        {/* Follow Up
                    </FollowUpButton> */}
                    {item.lead_stage !== "Admission" && (
                        <IconContainer
                            onClick={(e) => {
                                e.preventDefault();

                                history.push(`/admission-consultant/edit-lead/${item.id}/`, {
                                    keyy: "follow",
                                });
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            <Icon src={Edit} alt="Icon" />
                        </IconContainer>
                    )}
                </TLLD>
            </div>
            {isModal && (
                <FollowUpModal
                    isModal={isModal}
                    setIsModal={setIsModal}
                    selectedLead={selectedLead}
                    getData1={getData1}
                />
            )}
        </>
    );
}

// export default AssignedListItem;

const StatusText = styled.p`
    color: ${(props) => (props.color ? props.color : "#000")};
    background: ${(props) => (props.background ? props.background : "#000")};
    width: 75%;
    border-radius: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const FollowUpButton = styled.img`
    cursor: pointer;
    margin-right: 30px;
    width: 26px;
`;
const TLLD = styled.div`
    display: flex;
    width: 100px;
    align-items: center;
    box-sizing: border-box;
`;
const IconContainer = styled.div`
    width: 22px;
    height: 16px;
    margin-right: 8px;
    &:last-child {
        margin-right: 0;
    }
`;
const Icon = styled.img`
    width: 4px;
    display: block;
    object-fit: cover;
`;
const DetailedRemarkDiv = styled.div`
    max-width: 320px;
    padding: 10px;
`;
const DetailedRemarkText = styled.p`
    /* max-width: 240px; */
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    box-sizing: border-box;
    /* or 150% */

    /* dark/black */

    color: #000000;
    /* margin-top: 10px; */
`;
const BgBox = styled.div`
    width: 50px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    border-radius: 8px;
    text-align: center;
`;
