import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Text from "../../components/Text";
// import { Bar } from "react-chartjs-2";
import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import Card from "./../../components/salesPerson/Card";
import style from "./style.css";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Legend, LabelList, Tooltip } from "recharts";
// import "chartjs-plugin-datalabels";

//svg
import Revenue from "./../../assets/images/revenue.svg";
import Network from "./../../assets/images/online-learning.svg";
import Like from "./../../assets/images/like.svg";
import Uneducated from "./../../assets/images/uneducated.svg";
import Pending from "./../../assets/images/pending.svg";
import Switch from "./../../assets/images/switch.svg";
import Followers from "./../../assets/images/followers.svg";
import Memory from "./../../assets/images/memory.svg";
import FollowupTable from "./FollowupTable";
import PendingFeeTable from "./PendingFeeTable";
import ProfilePic from "../../assets/images/profile-icon.svg";
import loadingIcon from "../../assets/lottie/loading.gif";

// mui imports
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

function PerformanceDashboard() {
    const [salesPersonData, setSalesPersonData] = useState([]);
    const { state } = useContext(Context);
    const [isLoading, setLoading] = useState(false);
    const [monthlyLead, setMonthlyLead] = useState([]);

    const months = [
        { id: 1, title: "January" },
        { id: 2, title: "February" },
        { id: 3, title: "March" },
        { id: 4, title: "April" },
        { id: 5, title: "May" },
        { id: 6, title: "June" },
        { id: 7, title: "July" },
        { id: 8, title: "August" },
        { id: 9, title: "September" },
        { id: 10, title: "October" },
        { id: 11, title: "November" },
        { id: 12, title: "December" },
    ];
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const handleSelectMonth = (event) => {
        setSelectedMonth(event.target.value);
    };
    const [lastYears, setLastYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const selectedMonthAndYear = `${selectedYear}-${selectedMonth}-01`;
    const handleSelectYear = (event) => {
        setSelectedYear(event.target.value);
    };
    useEffect(() => {
        let date = new Date(),
            years = [],
            year = date.getFullYear();

        for (var i = year; i > year - 10; i--) {
            if (i > 2020) {
                years.push(i);
                setLastYears(years);
            }
        }
    }, []);

    const getPerformanceData = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;

        baseConfig
            .get("/general/sales-person-performance-dashboard/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    date: selectedMonth && selectedYear && selectedMonthAndYear,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setMonthlyLead(data.leads);
                    setSalesPersonData(data.performance_data);
                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                } else {
                    alert("Some error occured,Try later ");
                    setLoading(false);
                }
            })
            .catch((error) => {
                alert(error);
                // setLoading(false);
                window.location.reload();
            });
    };

    useEffect(() => {
        getPerformanceData();
    }, [selectedMonth, selectedYear]);

    const data = (canvas) => {
        const ctx = canvas.getContext("2d");
        return {
            labels: salesPersonData.map((da) => {
                return da?.name;
            }),
            datasets: [
                {
                    label: "Success rate",
                    data: salesPersonData.map((da) => {
                        return da?.success_rate;
                    }),
                    backgroundColor: ["#aaa3f5"],
                    // barPercentage: 0.5,
                    // barThickness: 15,
                    // maxBarThickness: 13,
                    // minBarLength: 8,
                },
            ],
        };
    };

    const options = {
        maintainAspectRatio: false,
        indexAxis: "y",
        responsive: true,
        scales: {
            xAxes: {
                grid: {
                    display: true,
                    borderDash: [5, 10],
                    color: "#DADADA",
                },
                // max: 100,
                ticks: {
                    stepSize: 10,
                },
                max: 100,
            },
            yAxes: {
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
            // datalabels: {
            // 	display: true,
            // 	color: "red",
            // 	font: { size: "14" },
            // 	align: "end",
            // 	anchor: "end",
            // },
        },
    };

    const annualData = (annualCanvas) => {
        const ctx = annualCanvas.getContext("2d");
        const g = ctx.createLinearGradient(0, 0, 0, 400);
        g.addColorStop(1, "rgba(71,10,245,0)");
        g.addColorStop(0, "rgba(71,10,245,1)");
        return {
            // labels: [
            //     "Jan",
            //     "Feb",
            //     "Mar",
            //     "Apr",
            //     "May",
            //     "Jun",
            //     "Jul",
            //     "Aug",
            //     "Sep",
            //     "Oct",
            //     "Nov",
            //     "Dec",
            // ],
            datasets: [
                {
                    label: "Leads",
                    data: monthlyLead.map((da) => {
                        return da?.no_of_leads;
                    }),
                    backgroundColor: "#9F99E8",
                    border: "none",
                    width: "20px",
                },
                // {
                // 	label: "Target Achieved",
                // 	data: [10, 20, 30, 40],
                // 	backgroundColor: "#C5CCFF",
                // 	border: "none",
                // 	width: "20px",
                // },
            ],
        };
    };

    const annualOptions = {
        maintainAspectRatio: false,
        indexAxis: "x",
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                },
            },
            y: {
                grid: {
                    display: true,
                    color: "#ECEAF3",
                },
                title: {
                    display: true,
                    text: `No. Of Leads `,
                    color: "#522E92",
                    font: {
                        size: 18,
                    },
                },
            },
        },
    };

    const renderCustomizedLabel = (props) => {
        const { x, y, width, height, value } = props;
        const radius = 10;

        return (
            <g>
                <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
                <text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">
                    {/* {value.split(" ")[1]} */}9
                </text>
            </g>
        );
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${label} : ${payload[0]?.value}`}</p>
                    {/* <p className="intro">{getIntroOfPage(label)}</p> */}
                    <p className="desc">Anything you want can be displayed here.</p>
                </div>
            );
        }

        return null;
    };

    return (
        <MainContainer>
            {!isLoading ? (
                <>
                    <TopContainer>
                        <Left>
                            <LeftTitleContainer>
                                <Title color="#000" fontSize="18px" fontWeight="500" style={{ marginBottom: "10px" }}>
                                   Admission Consultant's Ranking
                                </Title>
                                <Text color="#808080" fontSize="13px" fontWeight="500">
                                    By Performance
                                </Text>
                            </LeftTitleContainer>
                            <RightSellerContainer>
                                {salesPersonData.map((item, i) => (
                                    <SalesCard>
                                        <SalesCardLeft>
                                            <SalesDp src={item.image ? item.image : ProfilePic} alt="image" />
                                        </SalesCardLeft>
                                        <SalesCradRight>
                                            <CardLeft>
                                                <CardText>{item.rank}</CardText>
                                            </CardLeft>
                                            <CardRight>
                                                <Text
                                                    color="#7B6294"
                                                    fontSize="13px"
                                                    fontWeight="500"
                                                    style={{
                                                        marginBottom: "5px",
                                                    }}
                                                >
                                                    {item.name}
                                                </Text>
                                                <Text color="#808080" fontSize="13px" fontWeight="500">
                                                    {item.target_achieved}% Target
                                                </Text>
                                            </CardRight>
                                        </SalesCradRight>
                                    </SalesCard>
                                ))}
                            </RightSellerContainer>
                        </Left>
                        <Right>
                            <div>
                                <FilterBoxesWrapper>
                                    <SelectBox>
                                        <FormControl sx={{ m: 1, minWidth: 140 }} size="small">
                                            <InputLabel id="demo-simple-select-label">Month</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedMonth}
                                                label="Admission Consultant"
                                                onChange={handleSelectMonth}
                                            >
                                                {months?.map((item) => (
                                                    <MenuItem value={item.id}>{item.title}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </SelectBox>
                                    <SelectBox>
                                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                            <InputLabel id="demo-simple-select-label">Year</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedYear}
                                                label="Source"
                                                onChange={handleSelectYear}
                                            >
                                                {lastYears?.map((item) => (
                                                    <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </SelectBox>
                                </FilterBoxesWrapper>
                                <ResponsiveContainer width="95%" height={400}>
                                    {/* <BarChart data={salesPersonData}>
										<XAxis
											dataKey="name"
											tick={{ fontSize: 11 }}
											interval={0}
										/>
										<YAxis
											dataKey="target_achieved"
											tick={{ fontSize: 12 }}
										/>
										<Legend />
										<Bar
											dataKey="target_achieved"
											fill="#794BD1"
											isAnimationActive={false}
											barSize={10}
											barGap={2}
											minPointSize={5}
											name="Target Achived"
										>
											<LabelList
												dataKey="no_of_leads"
												position="top"
												style={{
													fill: "#000",
													fontSize: 15,
												}}
											/>
										</Bar>
									</BarChart> */}
                                    <BarChart
                                        // width={600}
                                        // height={300}
                                        data={salesPersonData}
                                        layout="horizontal"
                                        margin={{
                                            top: 35,
                                            right: 0,
                                            left: 10,
                                            bottom: 80,
                                        }}
                                    >
                                        <YAxis type="number" tick={{ fontSize: 12 }} />
                                        <XAxis
                                            // type="category"
                                            barSize={10}
                                            // barGap={10}
                                            // minPointSize={5}
                                            dataKey="name"
                                            angle={-90}
                                            interval={0}
                                            // textAnchor="end"
                                            tick={{ fontSize: 12 }}
                                            textAnchor="end"
                                            // sclaeToFit="true"
                                            // verticalAnchor="start"
                                        />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip
                                        //  content={<CustomTooltip />}
                                        />
                                        {/* <Legend /> */}
                                        <Legend
                                            wrapperStyle={{
                                                top: 0,
                                                left: 25,
                                            }}
                                        />
                                        <Bar dataKey="target_achieved" fill="#8884d8" name="Target Achived">
                                            <LabelList
                                                dataKey={"target_achieved"}
                                                position="top"
                                                style={{
                                                    fill: "#000",
                                                    fontSize: 9,
                                                }}
                                                // content={renderCustomizedLabel}
                                            />
                                        </Bar>
                                        {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </Right>
                    </TopContainer>
                    <Middle>
                        <MiddleContainer>
                            <Title color="#000" fontSize="18px" fontWeight="500" style={{ marginBottom: "15px" }}>
                                Annual YTD
                            </Title>
                            <Division>
                                <SubDiv>
                                    <Sub background="#9F99E8" />
                                    <SubText>Leads</SubText>
                                </SubDiv>
                                {/* <SubDiv>
							<Sub background="#DAD7FF" />
							<SubText>Target Achieved</SubText>
						</SubDiv> */}
                            </Division>
                            <div>
                                {/* <Bar
                                    height={500}
                                    data={annualData}
                                    options={annualOptions}
                                /> */}
                                <ResponsiveContainer width="95%" height={280}>
                                    <BarChart data={monthlyLead}>
                                        <XAxis dataKey="month" tick={{ fontSize: 11 }} interval={0} />
                                        <YAxis
                                            dataKey="no_of_leads"
                                            tick={{ fontSize: 12 }}
                                            // domain={[0, 100]}
                                        />
                                        <Legend />
                                        <Bar
                                            dataKey="no_of_leads"
                                            fill="#794BD1"
                                            isAnimationActive={false}
                                            barSize={10}
                                            barGap={2}
                                            // barCategoryGap={2}
                                            minPointSize={5}
                                            name="No.of Leads"
                                        >
                                            <LabelList
                                                dataKey="no_of_leads"
                                                // dataKey={
                                                //     Math.round(
                                                //         dashboardDetails.target_achieved *
                                                //             100
                                                //     ) / 100
                                                // }
                                                position="top"
                                                style={{
                                                    fill: "#000",
                                                    fontSize: 15,
                                                }}
                                            />
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </MiddleContainer>
                    </Middle>
                </>
            ) : (
                <LoaderContainer>
                    <LoaderIcon src={loadingIcon} alt="icon" />
                </LoaderContainer>
            )}

            {/* <Title fontSize="35px">Revenue Report</Title>

			<Bottom>
				<BottomCard>
					<RevenueCard>
						<CardLeft>
							<Image src={Revenue} alt="icon" />
						</CardLeft>
						<CardRight>
							<Text
								color="#808080"
								fontSize="13px"
								fontWeight="400"
								style={{ marginBottom: "5px" }}
							>
								Revenue Generated this month
							</Text>
							<Text color="#000" fontSize="24px" fontWeight="600">
								60 AED
							</Text>
						</CardRight>
					</RevenueCard>
					<RevenueCard>
						<CardLeft>
							<Image src={Revenue} alt="icon" />
						</CardLeft>
						<CardRight>
							<Text
								color="#808080"
								fontSize="13px"
								fontWeight="400"
								style={{ marginBottom: "5px" }}
							>
								Monthly Increase in Revenue
							</Text>
							<Text color="#000" fontSize="24px" fontWeight="600">
								60 AED
							</Text>
						</CardRight>
					</RevenueCard>
					<RevenueCard>
						<CardLeft>
							<Image src={Revenue} alt="icon" />
						</CardLeft>
						<CardRight>
							<Text
								color="#808080"
								fontSize="13px"
								fontWeight="400"
								style={{ marginBottom: "5px" }}
							>
								Average revenue per day
							</Text>
							<Text color="#000" fontSize="24px" fontWeight="600">
								60 AED
							</Text>
						</CardRight>
					</RevenueCard>
				</BottomCard>
				<BottomTable>
					<Text color="#000" fontSize="20px" fontWeight="600">
						Registration fee pending
					</Text>
					<PendingFeeTable />
				</BottomTable>
			</Bottom> */}
        </MainContainer>
    );
}

export default PerformanceDashboard;

const MainContainer = styled.div`
    margin-top: 20px;
`;

const TopContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
`;
const Left = styled.div`
    width: 25%;
    height: 500px;
    overflow: scroll;
    @media only screen and (max-width: 1180px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: fit-content;
        margin-bottom: 40px;
        padding: 20px 30px;
        /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 4px;
        background-color: #f8f8ff;
    }
    @media only screen and (max-width: 880px) {
        flex-wrap: wrap;
    } ;
`;
const LeftTitleContainer = styled.div``;
const RightSellerContainer = styled.div`
    @media only screen and (max-width: 1180px) {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    @media only screen and (max-width: 880px) {
        width: 100%;
        margin-top: 15px;
    } ;
`;
const SalesCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-radius: 10px;
    /* height: 100px; */
    margin-top: 15px;
    padding: 15px 15px 15px 20px;
    @media only screen and (max-width: 1180px) {
        height: fit-content;
        padding: 20px;
        margin-top: 0;
    }
`;
const CardLeft = styled.div`
    margin-right: 15px;
`;
const CardText = styled.h4`
    color: #fff;
    background: #522e92;
    display: inline-block;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-weight: 500;
`;
const SalesCardLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const SalesCradRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 180px;
`;
const SalesDp = styled.img`
    width: 50px;
    height: 50px;
    display: block;
    object-fit: cover;
    border-radius: 50%;
`;
const CardRight = styled.div``;
const Right = styled.div`
    background: #fff;
    border-radius: 10px;
    width: 70%;
    padding: 20px;
    /* height: 500px; */
    @media only screen and (max-width: 1180px) {
        width: 100%;
    }
    @media only screen and (max-width: 1080px) {
        width: 95%;
    }
    @media only screen and (max-width: 880px) {
        width: 93%;
    } ;
`;
const Middle = styled.div`
    margin-top: 3%;
    margin-bottom: 3%;
`;
const MiddleContainer = styled.div`
    background: #fff;
    padding: 30px;
    border-radius: 10px;
`;
const Division = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;
const SubDiv = styled.div`
    display: flex;
    align-items: center;
    margin-right: 30px;
`;
const Sub = styled.div`
    height: 20px;
    width: 20px;
    background: ${(props) => (props.background ? props.background : "#fff")};
    margin-right: 5px;
`;

const SubText = styled.h5`
    color: rgba(0, 0, 0, 0.7);
    font-weight: 500;
    font-size: 14px;
`;
const Image = styled.img`
    height: 50px;
    width: 50px;
`;
const Bottom = styled.div``;
const BottomCard = styled.div`
    display: flex;
    justify-content: space-between;
`;
const RevenueCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 10px;
    height: 120px;
    margin-top: 15px;
    width: 30%;
`;

const BottomTable = styled.div`
    padding: 30px;
    background-color: #fff;
    margin-top: 30px;
    border-radius: 15px;
`;
const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 220px);
`;
const LoaderIcon = styled.img`
    width: 65px;
    display: block;
    object-fit: cover;
`;
const FilterBoxesWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
`;
const SelectBox = styled.div`
    /* width: 250px; */
    margin-right: 10px;
    &:last-child {
        margin-right: 0;
    }
`;
const TeamLoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const TeamLoadingIcon = styled.img`
    display: block;
    width: 50px;
    object-fit: cover;
`;
