import Facebook from "../../assets/images/facebook.svg";
import Instagram from "../../assets/images/instagram.svg";
import Glob from "../../assets/images/glob.svg";
import Education from "../../assets/images/Education.svg";
import Friends from "../../assets/images/friends.svg";


const leadListData = [
  {
    ID: "557",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    sourceImage: Facebook,
    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "672",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    sourceImage: Instagram,
    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "158",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    sourceImage: Facebook,
    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "000",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    sourceImage: Facebook,
    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "063",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    sourceImage: Facebook,
    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "300",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    sourceImage: Facebook,
    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "985",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    sourceImage: Facebook,
    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "423",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    sourceImage: Facebook,
    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "259",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    sourceImage: Facebook,
    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "213",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    sourceImage: Facebook,
    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "712",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    sourceImage: Facebook,
    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "913",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    sourceImage: Facebook,

    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "597",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    source: "College",
    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "091",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    source: "College",
    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "342",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    source: "College",
    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "838",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    source: "College",
    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "351",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    source: "College",
    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "717",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    source: "College",
    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "864",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    source: "College",
    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "241",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    source: "College",
    followup:"Follow up",
    qualitification: "BSc CS",
  },
  {
    ID: "597",
    name: "Jomana",
    uniersity: "Sngnya",
    course: "MSc",
    country: "Dubai",
    phone: "+96 979797 9797",
    source: "College",
    followup:"Follow up",
    qualitification: "BSc CS",
  },
];
export default leadListData;
