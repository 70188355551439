import React from "react";
import Container from "../../components/Container";
import Image from "../../components/Image";
import deleteIcon from "../../assets/images/archive.svg";
import placeholderIcon from "../../assets/images/placeholder.svg";
import mailIcon from "../../assets/images/mail.svg";
import phoneIcon from "../../assets/images/phone-call.svg";
import eye from "../../assets/images/action-eye.svg";
import Text from "../Text";
import Button from "../Button";
import styled from "styled-components";

function ArchivedCampusItem({
    image,
    id,
    name,
    address,
    email,
    phone,
    pk,
    setArchiveId,
    handleArchive,
}) {
    return (
        <CampusItemBox>
            <CampusItemTop>
                <TopLeft>
                    <CampusImageContainer>
                        <CampusImage src={image} alt="Campus" />
                    </CampusImageContainer>
                </TopLeft>
                <TopRight>
                    <TopRightTop>
                        <ItemId>#{id}</ItemId>
                        {/* <DeleteImage
                            src={archiveIcon}
                            alt="delete"
                            onClick={onClickDelete}
                        /> */}
                    </TopRightTop>
                    <TopRightBottom>
                        <UpdateButton onClick={() => handleArchive(pk)}>
                            <UpdateText>Restore</UpdateText>
                        </UpdateButton>
                    </TopRightBottom>
                </TopRight>
            </CampusItemTop>
            <CampusItemBottom>
                <CampusDetailWrapper>
                    <CampusDetailIconBox>
                        <CampusDetailIcon src={placeholderIcon} alt="Icon" />
                    </CampusDetailIconBox>
                    <CampusDetailText>{address}</CampusDetailText>
                </CampusDetailWrapper>
                <CampusDetailWrapper>
                    <CampusDetailIconBox>
                        <CampusDetailIcon src={mailIcon} alt="Icon" />
                    </CampusDetailIconBox>
                    <CampusDetailText>{email}</CampusDetailText>
                </CampusDetailWrapper>
                <CampusDetailWrapper>
                    <CampusDetailIconBox>
                        <CampusDetailIcon src={phoneIcon} alt="Icon" />
                    </CampusDetailIconBox>
                    <CampusDetailText>{phone}</CampusDetailText>
                </CampusDetailWrapper>
            </CampusItemBottom>
        </CampusItemBox>

        // <Container
        // 	width="48.5%"
        // 	style={{ display: "flex", height: "400px" }}
        // 	margin="0 0 2.5%"
        // 	padding="0"
        // >
        // 	<div className="image-container">
        // 		<Image
        // 			src={image}
        // 			alt="Campus"
        // 			style={{ borderRadius: "15px 0px 0px 15px" }}
        // 		/>
        // 	</div>
        // 	<div className="campus-details">
        // 		<Text color="#AA99CA" fontSize="20px">
        // 			#{id}
        // 		</Text>
        // 		<div className="campus-name-container">
        // 			<Text color="#000000" fontSize="18px">
        // 				{name}
        // 			</Text>
        // 		</div>
        // 		<div className="info-container">
        // 			<div className="info">
        // 				<div className="icon">
        // 					<Image src={placeholderIcon} alt="placeholder" />
        // 				</div>
        // 				<Text
        // 					style={{ width: "75%", lineHeight: "25px" }}
        // 					fontWeight="400"
        // 					fontSize="15px"
        // 					color="#8C8888"
        // 				>
        // 					{address}
        // 				</Text>
        // 			</div>
        // 			<div className="info">
        // 				<div className="icon">
        // 					<Image src={mailIcon} alt="mail" />
        // 				</div>
        // 				<Text
        // 					style={{ width: "75%", lineHeight: "25px" }}
        // 					fontWeight="400"
        // 					fontSize="15px"
        // 					color="#8C8888"
        // 				>
        // 					{email}
        // 				</Text>
        // 			</div>
        // 			<div className="info">
        // 				<div className="icon">
        // 					<Image src={phoneIcon} alt="phone" />
        // 				</div>
        // 				<Text
        // 					style={{ width: "75%", lineHeight: "25px" }}
        // 					fontWeight="400"
        // 					fontSize="15px"
        // 					color="#8C8888"
        // 				>
        // 					{phone}
        // 				</Text>
        // 			</div>
        // 		</div>
        // 		<Button
        // 			onClick={() => {
        // 				handleArchive(pk);
        // 			}}
        // 			style={{ width: "100%", boxSizing: "border-box" }}
        // 		>
        // 			<Text
        // 				color="#fff"
        // 				fontWeight="500"
        // 				fontSize="20px"
        // 				style={{ textAlign: "center" }}
        // 			>
        // 				Restore
        // 			</Text>
        // 		</Button>
        // 	</div>
        // </Container>
    );
}

export default ArchivedCampusItem;

const CampusItemBox = styled.div`
    background: #fff;
    /* height: 400px; */
    width: auto;
    padding: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-sizing: border-box;
    @media only screen and (max-width: 1280px) {
        padding: 20px;
    }
`;
const CampusItemTop = styled.div`
    display: flex;
    justify-content: space-between;
`;
const TopLeft = styled.div``;
const CampusImageContainer = styled.div`
    width: 150px;
    height: 150px;
    /* @media only screen and (max-width: 1280px) {
        width: 140px;
        height: 140px;
    } */
`;
const CampusImage = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 4px;
`;
const TopRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
`;
const TopRightTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
`;
const ItemId = styled.h5`
    font-family: Inter;
    font-size: 1rem;
    color: #aa99ca;
`;
const DeleteImage = styled.img``;
const TopRightBottom = styled.div``;
const UpdateButton = styled.div`
    padding: 12px 24px;
    background: #522e92;
    color: #ffffff;
    border-radius: 4px;
`;
const UpdateText = styled.p`
    font-size: 1.1rem;
`;
const CampusItemBottom = styled.div`
    margin-top: 30px;
`;
const CampusDetailWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const CampusDetailIconBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    background: rgba(82, 46, 146, 0.49);
    border-radius: 50%;
`;
const CampusDetailIcon = styled.img``;
const CampusDetailText = styled.div`
    /* font-family: Inter; */
    margin-left: 15px;
    font-size: 1rem;
    color: #8c8888;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
`;
