import "./style.css";
import profile from "../../assets/images/logout.svg";
import bell from "../../assets/images/notification.svg";
import settings from "../../assets/images/settings.svg";
import message from "../../assets/images/message.svg";
import search from "../../assets/images/header-search.svg";
import React, { useState, useContext, useEffect, useRef } from "react";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import LogOutModal from "../includes/LogOutModal";
import styled from "styled-components";
import Title from "../Title";
import WelcomeMessage from "../includes/WelcomeMessage";
import Welcome from "../includes/Welcome";
import { Context } from "./../../contexts/Store";
import { baseConfig } from "./../../utils/config";
import NotificationModal from "./NotificationModal";
import { Modal } from "@mui/material";

function Header() {
    const { state } = useContext(Context);

    const [notificationcount, setNotificationCount] = useState("");
    const [notifications, setNotifications] = useState();
    const [isNotificationModal, setNotificationModal] = useState(false);
    const [userName, setUserName] = useState("");
    const [userRole, setUserRole] = useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();
    const history = useHistory();
    const [isLoading, setLoading] = useState(false);
    const [isLogOutModal, setLogOutModal] = useState(false);
    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };
    const [openExecutiveModal, setOpenExecutiveModal] = useState(false);
    const handleOpenExecutiveModal = () => setOpenExecutiveModal(true);
    const handleCloseExecutiveModal = () => {
      setOpenExecutiveModal(false);
    };
    const getAdminData = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;

        baseConfig
            .get("/general/dashboard/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setUserName(data.name);
                    setNotificationCount(data.notifications);
                    setUserRole(data.user_role);
                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                } else {
                }
            })
            .catch((error) => {});
    };

    const getNotifications = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/general/notifications/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setNotifications(data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const toggleActiveBox = () => {
        setNotificationModal((prevState) => !prevState);
    };

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setNotificationModal(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, []);
    }
    function disableScrolling() {
        var x = window.scrollX;
        var y = window.scrollY;
        window.onscroll = function () {
            window.scrollTo(x, y);
        };
    }
    function enableScrolling() {
        window.onscroll = function () {};
    }

    useEffect(() => {
        if (isNotificationModal === true) {
            disableScrolling();
        }
        if (isNotificationModal === false) {
            enableScrolling();
        }
    }, [isNotificationModal]);

    useEffect(() => {
        getAdminData();
        getNotifications();
    }, []);

    return (
        <div className="header">
            {/* <Popper
				open={open}
				anchorEl={anchorEl}
				placement={placement}
				transition
			>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<Paper style={{ padding: 20 }}>
							<Button
								onClick={(e) => {
									// localStorage.removeItem("token");
									// history.replace("/login");
									// window.location.reload();
									e.preventDefault();
									localStorage.clear();
									window.location = "/";
								}}
								sx={{ p: 2 }}
							>
								Logout
							</Button>
						</Paper>
					</Fade>
				)}
			</Popper> */}
            <div>
                {!isLoading && userRole !== "superuser" && userRole !== "user" && (
                    <>
                        <TitleWrapper>
                            <Welcome />
                            <Title
                                fontSize="22px"
                                fontWeight="500"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    // marginBottom: "20px",
                                    marginLeft: "6px",
                                }}
                            >
                                {/* {dashboardDetails?.admin_name} */}
                                {userName}
                            </Title>
                        </TitleWrapper>

                        <WelcomeMessage />
                    </>
                )}
            </div>
            <div className="header-container">
                <div className="item form">
                    {/* <form>
						<img src={search} alt="search" />
						<input
							type="text"
							name="search"
							placeholder="Search for something"
						/>
					</form> */}
                </div>
                {/* <div className="item">
					<img src={message} alt="message" />
				</div>
				<div className="item">
					<img src={settings} alt="settings" />
				</div> */}
                {notificationcount > 0 ? (
                    <ItemDiv
                        className="item"
                        ref={wrapperRef}
                        onClick={() => {
                            toggleActiveBox();
                        }}
                    >
                        <img src={bell} alt="bell" />
                        <NotificationDiv>
                            <NotiCount
                                isSmallFont={
                                    notificationcount > 99 ? true : false
                                }
                            >
                                {notificationcount > 99
                                    ? `99+`
                                    : notificationcount}
                            </NotiCount>
                        </NotificationDiv>
                        {isNotificationModal && (
                            <NotificationModal notifications={notifications} />
                        )}
                    </ItemDiv>
                ) : // <ItemDiv
                //     className="item"

                // >
                //     <img src={bell} alt="bell" />
                // </ItemDiv>
                null}

                <div
                    className="item"
                    onClick={() => {
                    handleOpenExecutiveModal()
                    }}
                >
                    <img
                        src={profile}
                        alt="profile"
                        // onClick={handleClick("bottom")}
                    />
                </div>
            </div>
            {/* {isLogOutModal && ( */}
                <Modal
                open={openExecutiveModal}
                onClose={handleCloseExecutiveModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >

                <LogOutModal
                    isLogOutModal={isLogOutModal}
                    setLogOutModal={setLogOutModal}
                    handleCloseExecutiveModal={handleCloseExecutiveModal}
                    openExecutiveModal={openExecutiveModal}
                />
                </Modal>
            {/* )} */}
            {/* <NotificationModal /> */}
        </div>
    );
}

export default Header;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const ItemDiv = styled.div`
    position: relative;
`;
const NotificationDiv = styled.div`
    position: absolute;
    margin: 2px;
    background: #fff;
    top: -40%;
    right: -30%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    padding: 6px;
    border-radius: 50%;
    border: 2px solid #522e92;
    box-sizing: border-box;
    overflow: hidden;

    /* z-index: 110; ; */
`;
const NotiCount = styled.p`
    color: #ee1451;
    font-size: ${(props) => (props.isSmallFont ? "9px" : "11px")};
    font-weight: 600;
    /* width: 90%; */
    box-sizing: border-box;
`;
