import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import "./style.css";
import { Context } from "../../contexts/Store";
import DeleteModal from "../../components/includes/DeleteModal";
import { baseConfig } from "../../utils/config";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import DeleteImage from "../../assets/images/delete.svg";
import Button from "../../components/Button";
import addbutton from "../../assets/images/addbutton.svg";
import Text from "../../components/Text";
import edit from "../../assets/images/edit.svg";
import visible from "../../assets/images/archive.svg";
import search from "../../assets/images/header-search.svg";
import AdminsListContainer from "./AdminsListContainer";
import AdminListCard from "./AdminListCard";
import MainPagination from "../../components/includes/MainPagination";

function FinanceExecutives() {
    const [financeExecutives, setFinanceExecutives] = useState([]);
    const history = useHistory();
    const [searchItem, setSearchItem] = useState("");
    const [isLoading, setLoading] = useState(true);
    const { state } = useContext(Context);
    const [isModal, setModal] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [paginationData, setPaginationData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const getFinanceExecutives = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/finance/finance-executives/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: searchItem,
                },
            })
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode == 6000) {
                    setFinanceExecutives(data.data);
                    setLoading(false);
                    setPaginationData(data.paginator);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {});
    };

    const handleDeleteData = () => {
        let accessToken = state.user_details.access_token;

        baseConfig
            .delete(`/finance/finance-executive/${deleteId}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode == 6000) {
                    setModal(false);
                    getFinanceExecutives();
                } else if (StatusCode == 6001) {
                    alert(data.data.message);
                    // getLeadGeneratorList();
                }
            })
            .catch((error) => {});
    };
    useEffect(() => {
        getFinanceExecutives();
    }, [searchItem]);
    return (
        <>
            <>
                <Wrapper>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "30px",
                        }}
                        className="top-title-wrapper"
                    >
                        <div>
                            <Title className="top-title-large" fontSize="35px">
                                Finance Executive
                            </Title>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                            className="top-title-large-right"
                        >
                            <div
                                style={{
                                    marginRight: "32px",
                                }}
                                className="top-title-search-div"
                            >
                                <form className="search-container">
                                    <img src={search} alt="search" />
                                    <input
                                        className="input-box"
                                        type="text"
                                        name="search"
                                        placeholder="Search for something"
                                        onChange={(e) => {
                                            setSearchItem(e.target.value);
                                            // getFinanceExecutives();
                                        }}
                                    />
                                </form>
                            </div>

                            <Button
                                onClick={() => {
                                    history.push(
                                        "/superadmin/add-finance-executive/"
                                    );
                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                bacerkgroundColor="#5A2D8F"
                                fontSize="15px"
                            >
                                <img src={addbutton} alt="Logo" />
                                <Text
                                    style={{ marginLeft: "12px" }}
                                    color="white"
                                    fontSize="15px"
                                >
                                    Add Admin
                                </Text>
                            </Button>

                            <Button
                                className="refresh-button-large"
                                onClick={() => getFinanceExecutives()}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 22px",
                                    cursor: "pointer",
                                }}
                                backgroundColor="white"
                                fontSize="15px"
                                padding="10px 50px"
                            >
                                <Text color=" #522e92" fontSize="15px">
                                    Refresh
                                </Text>
                            </Button>
                        </div>
                    </div>
                    {isLoading === false ? (
                        <>
                            {financeExecutives?.length !== 0 ? (
                                <AdminsListContainer
                                // style={{
                                //     display: "flex",
                                //     justifyContent: "space-between",
                                //     flexWrap: "wrap",
                                // }}
                                // className="university-admin-container-wrapper"
                                >
                                    {financeExecutives?.map(
                                        (financeExecutive) => {
                                            return (
                                                <AdminListCard
                                                    key={financeExecutive.id}
                                                    // width="48%"
                                                    // className="university-admin-container"
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <div>
                                                            <Title fontSize="27px ">
                                                                {
                                                                    financeExecutive?.name
                                                                }
                                                            </Title>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            {/* {financeAdmin.is_disabled ? (
                                                        <Text
                                                            fontSize="15px"
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                setArchiveModal(
                                                                    true
                                                                );
                                                                setDeleteId(
                                                                    financeAdmin?.id
                                                                );
                                                                setSelectedData(
                                                                    financeAdmin
                                                                );
                                                            }}
                                                        >
                                                            Restore
                                                        </Text>
                                                    ) : (
                                                        <DeleteIcon
                                                            src={visible}
                                                            alt="Logo"
                                                            onClick={() => {
                                                                setArchiveModal(
                                                                    true
                                                                );
                                                                setDeleteId(
                                                                    financeAdmin?.id
                                                                );
                                                                setSelectedData(
                                                                    financeAdmin
                                                                );
                                                            }}
                                                        />
                                                    )} */}
                                                            <DeleteIcon
                                                                src={
                                                                    DeleteImage
                                                                }
                                                                alt="Logo"
                                                                onClick={() => {
                                                                    setModal(
                                                                        true
                                                                    );
                                                                    setDeleteId(
                                                                        financeExecutive?.id
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginTop: "20px",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                marginTop:
                                                                    "10px",
                                                            }}
                                                        >
                                                            <Text fontSize="15px">
                                                                Mail ID
                                                            </Text>
                                                            <h3
                                                                style={{
                                                                    fontSize:
                                                                        "14px",
                                                                    color: "grey",
                                                                    marginLeft:
                                                                        "10px",
                                                                    marginTop:
                                                                        "2px",
                                                                }}
                                                            >
                                                                {
                                                                    financeExecutive
                                                                        ?.user_data
                                                                        ?.email
                                                                }
                                                            </h3>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                marginTop:
                                                                    "10px",
                                                            }}
                                                        >
                                                            <Text fontSize="15px">
                                                                Username
                                                            </Text>
                                                            <h3
                                                                style={{
                                                                    fontSize:
                                                                        "14px",
                                                                    color: "grey",
                                                                    marginLeft:
                                                                        "10px",
                                                                    marginTop:
                                                                        "2px",
                                                                }}
                                                            >
                                                                {
                                                                    financeExecutive
                                                                        ?.user_data
                                                                        ?.username
                                                                }
                                                            </h3>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "space-between",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    marginTop:
                                                                        "10px",
                                                                }}
                                                            >
                                                                <Text fontSize="15px">
                                                                    Password
                                                                </Text>
                                                                <h3
                                                                    style={{
                                                                        fontSize:
                                                                            "14px",
                                                                        color: "grey",
                                                                        marginLeft:
                                                                            "10px",
                                                                        marginTop:
                                                                            "4px",
                                                                    }}
                                                                >
                                                                    {financeExecutive?.password
                                                                        ?.split(
                                                                            ""
                                                                        )
                                                                        ?.map(
                                                                            (
                                                                                e
                                                                            ) => {
                                                                                return "*";
                                                                            }
                                                                        )}
                                                                </h3>
                                                            </div>

                                                            <Button
                                                                onClick={() =>
                                                                    history.push(
                                                                        `/superadmin/edit-finance-executive/${financeExecutive.id}`
                                                                    )
                                                                }
                                                                backgroundColor="#5A2D8F"
                                                                fontSize="15px"
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                className="university-admin-update-button"
                                                            >
                                                                Update
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </AdminListCard>
                                            );
                                        }
                                    )}
                                </AdminsListContainer>
                            ) : (
                                <div className="no-result">
                                    <text className="no-result-text">
                                        No results found{" "}
                                    </text>
                                </div>
                            )}
                            <MainPagination
                                paginationData={paginationData}
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                            />
                        </>
                    ) : (
                        <Div>
                            <LoadingGif
                                src={
                                    require("./../../assets/images/loading-buffering.gif")
                                        .default
                                }
                                alt="Loading"
                            />
                        </Div>
                    )}
                </Wrapper>
                {isModal && (
                    <DeleteModal
                        isModal={isModal}
                        setModal={setModal}
                        deleteId={deleteId}
                        handleDeleteData={handleDeleteData}
                    />
                )}
            </>
        </>
    );
}

export default FinanceExecutives;
const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
`;
const LoadingGif = styled.img`
    display: inline-block;
    width: 50px;
    @media (max-width: 980px) {
        width: 90px;
    }
`;
const DeleteIcon = styled.img`
    height: 22px;
    width: 22px;
    cursor: pointer;
    margin-left: 15px;
    &:first-child {
        margin-left: 0px;
    }
`;
