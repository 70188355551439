const assignedListData = [
	{
		studentId: "001",
		name: "Jomana Ah",
		email: "jomanaahmad33@gmail.com",
		phone: "+96 979797 9797",
		source: "Facebook",
		status: "not_intrested",
		remark: "Need to follow up by whatsapp",
	},
	{
		studentId: "001",
		name: "Jomana Ah",
		email: "jomanaahmad33@gmail.com",
		phone: "+96 979797 9797",
		source: "Facebook",
		status: "converted",
		remark: "Need to follow up by whatsapp",
	},
	{
		studentId: "001",
		name: "Jomana Ah",
		email: "jomanaahmad33@gmail.com",
		phone: "+96 979797 9797",
		source: "Facebook",
		status: "interested",
		remark: "Need to follow up by whatsapp",
	},
	{
		studentId: "001",
		name: "Jomana Ah",
		email: "jomanaahmad33@gmail.com",
		phone: "+96 979797 9797",
		source: "Facebook",
		status: "converted",
		remark: "Need to follow up by whatsapp",
	},
	{
		studentId: "001",
		name: "Jomana Ah",
		email: "jomanaahmad33@gmail.com",
		phone: "+96 979797 9797",
		source: "Facebook",
		status: "interested",
		remark: "Need to follow up by whatsapp",
	},
	{
		studentId: "001",
		name: "Jomana Ah",
		email: "jomanaahmad33@gmail.com",
		phone: "+96 979797 9797",
		source: "Facebook",
		status: "converted",
		remark: "Need to follow up by whatsapp",
	},
	{
		studentId: "001",
		name: "Jomana Ah",
		email: "jomanaahmad33@gmail.com",
		phone: "+96 979797 9797",
		source: "Facebook",
		status: "interested",
		remark: "Need to follow up by whatsapp",
	},
	{
		studentId: "001",
		name: "Jomana Ah",
		email: "jomanaahmad33@gmail.com",
		phone: "+96 979797 9797",
		source: "Facebook",
		status: "converted",
		remark: "Need to follow up by whatsapp",
	},
	{
		studentId: "001",
		name: "Jomana Ah",
		email: "jomanaahmad33@gmail.com",
		phone: "+96 979797 9797",
		source: "Facebook",
		status: "interested",
		remark: "Need to follow up by whatsapp",
	},
];
export default assignedListData;
