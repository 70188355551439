import styled from "styled-components";

export default styled.div`
    background: #fff;
    width: 100%;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;

    /* padding: ${(props) => (props.padding ? props.padding : "30px")};
    background: ${(props) => (props.background ? props.background : "#fff")};
    width: ${(props) => (props.width ? props.width : "25%")};
    border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : "10px"};
    margin: ${(props) => (props.margin ? props.margin : "10px")};
    box-sizing: border-box;

    @media all and (max-width: 1280px) {
        ${(props) => props.type === "dashboard" && `padding: 15px;`}
    } */

    @media all and (max-width: 980px) {
        /* grid-template-columns: 1fr; */
    }

    /* @media only screen and (max-width: 1440px) {
    width: 30%;
    margin-right: 5%;
    :nth-child(3n) {
      margin-right: 0;
    }
  }
  @media only screen and (max-width: 1200px) {
    padding: 15px;
  }
  @media only screen and (max-width: 980px) {
    width: 48%;
    margin-right: 2%;
    :nth-child(2n) {
      margin-right: 0;
    }
    :nth-child(3n) {
      margin-right: 2%;
    }
  }
  @media only screen and (max-width: 580px) {
    width: 100%;
    margin-right: 0%;
  } */
`;
