import React, { useEffect, useState, useContext } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import visible from "../../assets/images/visible.svg";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
    GetCampusAdminService,
    UpdateCampusAdminService,
} from "../../service/campusAdmin";
import { ListCampusService } from "../../service/campus";
import { baseConfig } from "./../../utils/config";
import { validatePassword } from "./../../helpers/Functions";
import { Context } from "./../../contexts/Store";

function UpdateCampusAdmin() {
    const history = useHistory();
    const location = useLocation();
    const [campuses, setCampuses] = useState([]);
    const { params } = useParams();
    const [data, setData] = useState(location?.state);

    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [fieldName, setFieldName] = useState("");

    const [id, setId] = useState("");
    const [campusName, setCampusName] = useState();
    const [email, setEmail] = useState();
    const [userName, setUserName] = useState(data.campus_data?.name);
    const [password, setPassword] = useState(data.password);
    const [confirmPassword, setConfirmPassword] = useState(data.password);
    const [emailValidation, setEmailValidation] = useState(false);
    const [passwordValidate, setPasswordValidate] = useState({});
    const [pk, setPk] = useState(data.id);

    const { state } = useContext(Context);

    const validate = (text) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(text) === false) {
            setEmailValidation(false);
            setEmail(text);
            return false;
        } else {
            setEmail(text);
            setEmailValidation(true);
        }
    };

    useEffect(() => {
        setPasswordValidate(validatePassword(password));
    }, [password]);

    const criteria = passwordValidate.is_strong_password;

    const [form, setFormValues] = useState({});

    const getCampusList = async () => {
        let data = await ListCampusService(state);
        if (data.StatusCode === 6000) {
            setCampuses(data.data?.data);
        } else {
            alert(data.data.message);
        }
    };

    const getInitialData = async () => {
        let data = await GetCampusAdminService(params, state);
        if (data.StatusCode === 6000) {
            let details = data.data.data;
            setFormValues(data.data.data);
            setId(details.admin_id);
            setCampusName(details.campus_data?.id);
            setEmail(details.campus_data?.email);
            setUserName(details.user_data?.username);
            setPassword(details.password);
            setConfirmPassword(details.password);
        } else {
            alert(data.data.message);
        }
    };
    useEffect(() => {
        getInitialData();
    }, []);

    useEffect(() => {
        getCampusList();
    }, []);

    const handleChangeValue = (key, value) => {
        setFormValues({ ...form, [key]: value });
    };

    // const handleSubmit = async () => {
    // 	let body = { id: form.id };
    // 	body.username = form?.username
    // 		? form?.username
    // 		: form?.user_data?.username;
    // 	body.email = form?.email ? form?.email : form?.user_data?.email;
    // 	body.campus = form?.campus ? form?.campus : form?.campus_data?.id;

    // 	if (
    // 		form["confirmPassword"] &&
    // 		form["password"] !== form["confirmPassword"]
    // 	) {
    // 		return alert("Incorrect password");
    // 	} else {
    // 		body.password = form.password;
    // 	}
    // 	let data = await UpdateCampusAdminService(body);
    // 	if (data.StatusCode === 6000) {
    // 		history.push("/superadmin/campus-admins");
    // 	} else {
    // 		alert(data.data.message);
    // 	}
    // };

    const handleUpdateForm = () => {
        let accessToken = state.user_details.access_token;

        baseConfig
            .put(
                `/campus/campus-admin-details/${pk}/`,
                {
                    campus: campusName,
                    email: email,
                    username: userName,
                    password: password,
                },
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                }
            )
            .then((response) => {
                let { StatusCode, data, message } = response.data;
                if (StatusCode == 6000) {
                    history.push("/superadmin/campus-admins");
                } else {
                    alert(data.data.message);
                }
            })
            .catch((error) => {});
    };

    return (
        <Wrapper>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                }}
            >
                <div>
                    <Title fontSize="35x">Update Campus Admin</Title>
                    {/* <Text
						style={{
							margin: "12px 0",
							fontFamily: "Inter",
							fontWeight: "500",
						}}
						color="grey"
						fontSize="18px"
					>
						Admins / Update campus admin
					</Text> */}
                </div>

                <div>
                    <Button
                        href="#"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0 22px",
                        }}
                        backgroundColor="white"
                        fontSize="15px"
                        padding="18px 57px"
                        onClick={(e) => {
                            e.preventDefault();
                            const form = document.getElementById("form");
                            form.reset();
                            setFormValues({});
                            setCampusName("");
                            setEmail("");
                            setUserName("");
                            setPassword("");
                            setConfirmPassword("");
                        }}
                    >
                        <Text color="  #EE1451" fontSize="15px">
                            Clear Form
                        </Text>
                    </Button>
                </div>
            </div>

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Container style={{ flexWrap: "wrap " }} width="100%">
                    <form id="form">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Input
                                onChange={(value) =>
                                    // handleChangeValue("id", value)
                                    setId(value)
                                }
                                type="email"
                                name="Admin ID  (auto generated)"
                                value={id}
                                disabled
                            />
                            <SelectInput
                                name="Choose Campus"
                                type="text"
                                placeholder="Choose Campus"
                                onChange={(value) => {
                                    {
                                        setCampusName(value);
                                        setError(false);
                                    }
                                }}
                                options={campuses ? campuses : []}
                                selectName={"name"}
                                selectValue={"id"}
                                // value={
                                // 	form?.campus
                                // 		? form?.campus
                                // 		: form?.campus_data?.id
                                // }
                                value={campusName}
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "20px",
                            }}
                        >
                            <Input
                                onChange={(value) =>
                                    // handleChangeValue("email", value)
                                    {
                                        setEmail(value);
                                        setError(false);
                                    }
                                }
                                type="name"
                                name="Admin Mail ID"
                                value={email}
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                            />

                            <Input
                                value={form?.user_data?.username}
                                onChange={(value) =>
                                    // handleChangeValue("username", value)
                                    {
                                        setUserName(value);
                                        setError(false);
                                    }
                                }
                                type="type"
                                name="Username"
                                value={userName}
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginTop: "20px",
                                justifyContent: "space-between ",
                            }}
                        >
                            <Input
                                onChange={(value) =>
                                    // handleChangeValue("password", value)
                                    {
                                        setPassword(value);
                                        setError(false);
                                    }
                                }
                                onClickVisible={() =>
                                    handleChangeValue(
                                        "passwordShow",
                                        !form?.passwordShow
                                    )
                                }
                                type={form?.passwordShow ? "text" : "password"}
                                name="Admin Password"
                                value={password}
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                            />

                            <Input
                                onChange={(value) =>
                                    // handleChangeValue("confirmPassword", value)
                                    {
                                        setConfirmPassword(value);
                                        setError(false);
                                    }
                                }
                                name="Confirm Admin Password"
                                type={
                                    form?.confirmPasswordShow
                                        ? "text"
                                        : "password"
                                }
                                onClickVisible={() =>
                                    handleChangeValue(
                                        "confirmPasswordShow",
                                        !form?.confirmPasswordShow
                                    )
                                }
                                value={confirmPassword}
                                isError={isError}
                                errorMessage={errorMessage}
                                fieldName={fieldName}
                            />
                        </div>
                        <div style={{ display: "flex", marginTop: "21px" }}>
                            <Button
                                href="#"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                bacerkgroundColor="#5A2D8F"
                                fontSize="15px"
                                padding="21px 70px"
                                onClick={(e) => {
                                    if (
                                        campusName === "" ||
                                        campusName === "Select a campus"
                                    ) {
                                        setError(true);
                                        setFieldName("Choose Campus");
                                        setErrorMessage("Choose a campus");
                                    } else if (email === "") {
                                        setError(true);
                                        setFieldName("Admin Mail ID");
                                        setErrorMessage(
                                            "Please enter a valid email"
                                        );
                                    } else if (userName === "") {
                                        setError(true);
                                        setFieldName("Username");
                                        setErrorMessage(
                                            "Please enter username"
                                        );
                                    } else if (password.length < 8) {
                                        setError(true);
                                        setFieldName("Admin Password");
                                        setErrorMessage(
                                            "Be a minimum of 8 characters"
                                        );
                                    } else if (criteria === false) {
                                        setError(true);
                                        setFieldName("Admin Password");
                                        setErrorMessage(
                                            "Strong passwords consist of a combination of uppercase and lowercase letters, numbers and special symbols"
                                        );
                                    } else if (password !== confirmPassword) {
                                        setError(true);
                                        setFieldName("Confirm Admin Password");
                                        setErrorMessage(
                                            "Both password must be same"
                                        );
                                    } else {
                                        e.preventDefault();
                                        // handleSubmit();
                                        handleUpdateForm();
                                    }
                                }}
                            >
                                <Text color="white" fontSize="15px">
                                    Submit
                                </Text>
                            </Button>

                            <Button
                                onClick={() => {
                                    history.push("/superadmin/campus-admins/");
                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "0 22px",
                                }}
                                backgroundColor="#F4F4F4"
                                fontSize="15px"
                                padding="21px 70px"
                            >
                                <Text color=" #522e92" fontSize="15px">
                                    Cancel
                                </Text>
                            </Button>
                        </div>
                    </form>
                </Container>
            </div>
        </Wrapper>
    );
}

export default UpdateCampusAdmin;
