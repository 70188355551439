import React, { useEffect, useState, useContext, useRef } from "react";
import styled from "styled-components";
import Wrapper from "../../components/Wrapper";
import { Context } from "../../contexts/Store";
import { baseConfig } from "../../utils/config";
import loadingIcon from "../../assets/lottie/loading.gif";

// react-slick-slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// mui imports
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FinanceCard from "../financeExecutive/includes/FinanceCard";
import StudentCard from "../financeExecutive/includes/StudentCard";
import FilterBox from "./includes/FilterBox";

export default function MainDashboard() {
    const { state, dispatch } = useContext(Context);

    const [selectedFinanceFilter, setSelectedFinanceFilter] =
        useState("Monthly");
    const filterValues = ["Monthly", "Yearly"];
    const [isLoading, setIsLoading] = useState(false);

    const [dashboardData, setDashboardData] = useState();
    const [monthlyData, setMonthlyData] = useState();
    const [yearlyData, setYearlyData] = useState();
    const [outStanding, setOutStanding] = useState({});
    const [receivedData, setReceivedData] = useState({});
    const [balanceData, setBalanceData] = useState({});
    const [pendingDue,setPendingDue] = useState({})
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const handleFinanceFilter = (event) => {
        setSelectedFinanceFilter(event.target.value);
    };

    // slicker settings
    const financeSliderSettings = {
        dots: true, // Show slide indicators (dots)
        infinite: true,
        speed: 300,
        cssEase: "linear",
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true, // Show navigation arrows
        autoplay: true, // Enable autoplay
        autoplaySpeed: 3000, // Set autoplay speed in milliseconds (2 seconds in this example)
        centerMode: true, // Enable center mode to create space between elements
        // centerPadding: "30px", // Adjust the space between elements (customize as needed)
    };
    // const sliderRef = useRef(null);

    const studentSliderSettings = {
        dots: true, // Show slide indicators (dots)
        infinite: true,
        focusOnSelect: true,
        speed: 300,
        cssEase: "linear",
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true, // Show navigation arrows
        autoplaySpeed: 3000,

        // autoplay: true, // Enable autoplay

        // swipeToSlide: false,

        // centerMode: true, // Enable center mode to create space between elements
        // centerPadding: "0px", // Adjust the space between elements (customize as needed)
    };

    // const handleBeforeChange = (oldIndex, newIndex) => {
    //     const totalSlides = sliderRef.current.innerSlider.state.slideCount;
    //     if (newIndex === totalSlides - 1) {
    //         // If the last slide is reached, reset the slider to the first slide
    //         sliderRef.current.slickGoTo(0);
    //     }
    // };

    const getDashboardData = () => {
        setIsLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/finance/finance-manager-dashboard/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    from_date: fromDate,
                    to_date: toDate,
                },
            })

            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setDashboardData(data);
                    setOutStanding(data.outstanding_data);
                    setReceivedData(data.recieved_data);
                    setBalanceData(data.balance_data);
                    setPendingDue(data.due_amount)
                    setMonthlyData(data.monthly);
                    setYearlyData(data.yearly);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    alert("Some error occured.Please try again later");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                alert(error);
            });
    };

    useEffect(() => {
        getDashboardData();
    }, [fromDate, toDate]);

    return (
        <>
            {!isLoading ? (
                <Wrapper
                // background="#fff"
                >
                    <FinanceSection>
                        <TitleBox>
                            <div>
                            <TitleText>Finance</TitleText>
                            <p
                            style={{
                                fontSize:"14px",
                                color:"#9c9c9c"
                            }}
                            >All Figures in AED</p>
                            </div>
                            <FilterBox
                                setFromDate={setFromDate}
                                setToDate={setToDate}
                            />
                        </TitleBox>
                        <CardsWrapper>
                            {/* <Slider
                            // ref={sliderRef}
                            {...studentSliderSettings}
                            // beforeChange={handleBeforeChange}
                        > */}
                            <FinanceCard
                                type="receivables"
                                data={outStanding}
                            />
                            <FinanceCard
                                type="fee received"
                                data={receivedData}
                            />
                            <FinanceCard
                                type="balance fee"
                                data={balanceData}
                            />
                             <FinanceCard
                                type="pending due"
                                data={pendingDue}
                            />
                            {/* <FinanceCard
                                type="outstanding"
                                data={outStanding}
                            /> */}
                            {/* </Slider> */}
                        </CardsWrapper>
                    </FinanceSection>
                    <StudentsSecttion>
                        <TitleBox>
                            <TitleText>Student Info</TitleText>
                            {/* <DropdownDiv>
                        <FilterText>Filter By:</FilterText>
                        <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
                            <InputLabel id="demo-simple-select-label"></InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                style={{ fontSize: "14px", fontWeight: "500" }}
                                value={selectedFinanceFilter}
                                label=""
                                variant="outlined"
                                onChange={handleFinanceFilter}
                            >
                                {filterValues?.map((item) => (
                                    <MenuItem
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                        }}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DropdownDiv> */}
                        </TitleBox>
                        <CardsWrapper
                        style={{
                            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                        }}
                        >
                            {/* <Slider {...studentSliderSettings}> */}
                            <StudentCard
                                type="active"
                                data={dashboardData?.active_students}
                            />
                             <StudentCard
                                type="on break"
                                data={dashboardData?.onbreak_students}
                            />
                            <StudentCard
                                type="on hold"
                                data={dashboardData?.onhold_students}
                            />
                            <StudentCard
                                type="completed"
                                data={dashboardData?.completed_students}
                            />
                            <StudentCard
                                type="dropout"
                                data={dashboardData?.dropout_students}
                            />
                            
                           
                            {/* </Slider> */}
                        </CardsWrapper>
                    </StudentsSecttion>
                </Wrapper>
            ) : (
                <LoaderContainer>
                    <LoadingGif src={loadingIcon} alt="icon" />
                </LoaderContainer>
            )}
        </>
    );
}

const FinanceSection = styled.div`
    margin-bottom: 15px;
    .slick-arrow::before {
        /* background: #000; */
        color: #8561c6;
        font-size: 22px;
    }
    /* .slick-track {
        gap: 10px;
    } */
    .slick-track {
        display: flex;
        gap: 1rem;
    }
    /* .slick-slide {
        margin: 0 10px; 
    } */
`;
const StudentsSecttion = styled.div`
    margin-bottom: 20px;
    .slick-arrow::before {
        /* background: #000; */
        color: #8561c6;
        font-size: 22px;
    }
    .slick-track {
        display: flex;
        gap: 1rem;
    }
`;
const TitleBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* align-items: flex-end; */
    margin-bottom: 10px;
`;
const TitleText = styled.h6`
    /* font-size: 22px; */
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    /* identical to box height */

    /* dark/black */

    color: #000000;
`;
const DropdownDiv = styled.div`
    display: flex;
    align-items: center;
`;
const FilterText = styled.p`
    /* font-weight: 500; */
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-transform: capitalize;

    /* Dark/500 */

    color: #010101;
`;
const CardsWrapper = styled.div`
    /* overflow-x: scroll; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 16px;
`;
const LoaderContainer = styled.div`
    height: calc(100vh - 120px);
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LoadingGif = styled.img`
    width: 60px;
    display: block;
    object-fit: cover;
`;
