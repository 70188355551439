import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import moment from "moment";

import Icon from "../../../assets/images/calender.svg";

const FilterBox = ({ setFromDate, setToDate }) => {
    const [showMenuBox, setShowMenuBox] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isFromDatePickerOpen, setIsFromDatePickerOpen] = useState(false);
    const [isToDatePickerOpen, setIsToDatePickerOpen] = useState(false);

    const handleFromDateChange = (date) => {
        setStartDate(date);
        setIsFromDatePickerOpen(false);
    };

    const handleToDateChange = (date) => {
        setEndDate(date);
        setIsToDatePickerOpen(false);
    };

    const handleSubmit = () => {
        if (startDate && endDate) {
            setFromDate(moment(startDate).format("YYYY-MM-DD"));
            setToDate(moment(endDate).format("YYYY-MM-DD"));
        }
        setShowMenuBox(false);
    };

    const menuRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                // Clicked outside the menu, so close it
                setShowMenuBox(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    // document
    //     .getElementById("react-datepicker-ignore-onclickoutside")
    //     .setAttribute("readonly", "true");

    return (
        <Container>
            <TitleBox
                onClick={() => {
                    if (!showMenuBox) {
                        setShowMenuBox(true);
                    }
                }}
            >
                <FilterImage>
                    <img
                        src={
                            require("../../../assets/images/filter-exe.svg")
                                .default
                        }
                        alt="filter"
                    />
                </FilterImage>
                <FilterText>Filter</FilterText>
            </TitleBox>
            <MenuBox ref={menuRef} className={showMenuBox ? "active" : ""}>
                <InputBox className="date-picker">
                    {
                        startDate ? "" 
                        :
                        <label>From</label>
                    }
                    <DatePicker
                        selected={startDate}
                        onChange={handleFromDateChange}
                        // selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={endDate}
                        open={isFromDatePickerOpen}
                        dateFormat="dd-MM-yyyy"
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    />
                    <button
                        onClick={() => {
                            setIsFromDatePickerOpen(true);
                            setIsToDatePickerOpen(false);
                        }}
                    >
                        <img src={Icon} alt="calender" />
                    </button>
                </InputBox>
                <InputBox className="date-picker">
                        {
                        endDate ? "" 
                        :
                        <label>To</label>
                        }
                    <DatePicker
                        selected={endDate}
                        onChange={handleToDateChange}
                        // selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        open={isToDatePickerOpen}
                        dateFormat="dd-MM-yyyy"
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    />
                    <button
                        onClick={() => {
                            setIsToDatePickerOpen(true);
                            setIsFromDatePickerOpen(false);
                        }}
                    >
                        <img src={Icon} alt="calender" />
                    </button>
                </InputBox>
                <Button onClick={handleSubmit}>Submit</Button>
            </MenuBox>
        </Container>
    );
};

export default FilterBox;

// Rest of your styled components...

const Container = styled.div`
    position: relative;
`;
const TitleBox = styled.div`
    border-radius: 8px;
    border: 1px solid #8561c6;
    padding: 8px 20px;
    gap: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    /* margin-bottom: 6px; */
`;
const FilterText = styled.p`
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #8561c6;
`;
const MenuBox = styled.div`
    position: absolute;
    right: 5px;
    width: 249px;
    // height: 226px;
    border-radius: 8px;
    border: 1px solid #e7e7e7;
    background: #f8fafc;
    padding: 16px;
    gap: 16px;
    display: none;
    box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.1);

    &.active {
        display: block;
        z-index: 1;
    }
`;
const InputBox = styled.div`
    /* height: 48px; */
    /* cursor: not-allowed; */
    border-radius: 8px;
    border: 1px solid #e7e7e7;
    padding: 4px 12px;
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    .react-datepicker-wrapper :read-only input[type="text"] {
        border: none;
        background: transparent;
        outline: none;
        width: 70%;
        /* cursor: not-allowed; */
    }
`;
const Button = styled.button`
    // width: 109px;
    /* height: 50px; */
    border-radius: 8px;
    background: #917afd;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-left: auto;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
`;
const FilterImage = styled.div`
    width: 16px;
    img {
        display: block;
        width: 100%;
    }
`;
