import styled from "styled-components";

export default styled.h1`
    font-family: "Inter";
    font-size: ${(props) => (props.fontSize ? props.fontSize : "25px")};
    color: ${(props) => (props.color ? props.color : "#522e92")};
    margin: ${(props) => (props.margin ? props.margin : "0")};
    /* font-weight: 600; */
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};

    text-align: left;
    text-transform: capitalize;
    /* @media only screen and (max-width: 1200px) {
    font-size: 35px;
  } */
`;
