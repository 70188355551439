import styled from "styled-components";

export default styled.div`
	background-color: ${(props) =>
		props.backgroundColor ? props.backgroundColor : "#522e92"};
	font-size: ${(props) => (props.fontSize ? props.fontSize : "25px")};
	color: ${(props) => (props.color ? props.color : "white")};
	// padding: ${(props) => (props.padding ? props.padding : "15px 40px")};
	border: none;
	border-radius: 8px;
	text-align: center;
	display: inline-block;

	/* height: 36px; */
	/* height: 50px; */
	display: flex;
	align-items: center;
	justify-content: center;
	/* width: 200px; */
	/* width: 100px; */
	cursor: pointer;
	// preventDefault;

	padding: 12px 30px;
`;
