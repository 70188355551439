import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import React, { useEffect, useState, useContext } from "react";
import { Context } from "./../../contexts/Store";
import Text from "../../components/Text";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

//svg
import Add from "./../../assets/images/addbutton.svg";
import University from "./../../assets/images/dashboard-school.svg";

function AllInstitution() {
	const history = useHistory();

	const [universityList, setUniversityList] = useState([
		{
			id: 1,
			name: "Engineering and Technogy",
			mail: "college@mail.com",
			mobile: "+96 989898 9898",
			image: University,
			address: "39 Cleopatra Street, Opposite Maidan Salahuddin",
		},
		{
			id: 2,
			name: "Engineering and Technogy",
			mail: "college@mail.com",
			mobile: "+96 989898 9898",
			image: University,
			address: "39 Cleopatra Street, Opposite Maidan Salahuddin",
		},
		{
			id: 3,
			name: "Engineering and Technogy",
			mail: "college@mail.com",
			mobile: "+96 989898 9898",
			image: University,
			address: "39 Cleopatra Street, Opposite Maidan Salahuddin",
		},
	]);

	return (
		<Wrapper>
			<Top>
				<TopLeft>
					<Title fontSize="35px">Institutions</Title>
					<Text
						fontSize="20px"
						fontWeight="400"
						color="#000"
						style={{ marginTop: "10px" }}
					>
						Institutions / All Institutions
					</Text>
				</TopLeft>
				<TopRight
					onClick={() => {
						history.push("/campus-admin/add-institution/");
					}}
				>
					<img src={Add} alt="icon" />
					<Text
						fontSize="16px"
						fontWeight="400"
						color="#fff"
						style={{ marginLeft: "5px" }}
					>
						Add Institution
					</Text>
				</TopRight>
			</Top>
			<CardContainer>
				{universityList.map((item) => (
					<Card>
						<CardTop>
							<CardLeft>
								<img
									src={item.image}
									alt="icon"
									style={{ height: "100%", width: "100%" }}
								/>
							</CardLeft>
							<CardRight>
								<Option>
									<img
										src={
											require("./../../assets/images/action-delete.svg")
												.default
										}
										alt="icon"
									/>
								</Option>
								<TopRight
									onClick={() => {
										history.push(
											"/campus-admin/add-university/"
										);
									}}
								>
									<Text
										fontSize="16px"
										fontWeight="400"
										color="#fff"
										style={{ marginLeft: "5px" }}
									>
										Update
									</Text>
								</TopRight>
							</CardRight>
						</CardTop>
						<CardBottom>
							<SubDiv>
								<SubText>#{item.id}</SubText>
							</SubDiv>
							<SubDiv>
								<MainText>{item.name}</MainText>
							</SubDiv>
							<SubDiv>
								<SubText>{item.address}</SubText>
							</SubDiv>
							<SubDiv>
								<MainText>Mail ID :</MainText>
								<SubText style={{ marginLeft: "10px" }}>
									{item.mail}
								</SubText>
							</SubDiv>
							<SubDiv>
								<MainText>Mobile No :</MainText>
								<SubText style={{ marginLeft: "10px" }}>
									{item.mobile}
								</SubText>
							</SubDiv>
						</CardBottom>
					</Card>
				))}
			</CardContainer>
		</Wrapper>
	);
}

export default AllInstitution;

const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const TopLeft = styled.div``;
const TopRight = styled.div`
	display: flex;
	align-items: center;
	background-color: #522e92;
	height: 50px;
	border-radius: 3px;
	padding: 0 20px;
	cursor: pointer;
`;
const CardContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 30px;
	margin-top: 20px;
`;
const Card = styled.div`
	background-color: #fff;
	padding: 30px;
	border-radius: 8px;
`;
const CardTop = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const CardLeft = styled.div`
	height: 150px;
	width: 150px;
`;
const CardRight = styled.div`
	display: flex;
	flex-direction: column;
	height: 150px;
	justify-content: space-between;
	align-items: end;
`;
const Option = styled.div``;
const CardBottom = styled.div``;
const SubDiv = styled.h5`
	margin-top: 20px;
	display: flex;
	align-items: center;
`;
const MainText = styled.h4`
	color: #522e92;
	font-weight: 600;
	font-size: 18px;
`;
const SubText = styled.h5`
	color: #808080;
	font-size: 15px;
`;
