import React, { useEffect, useState } from "react";
import styled from "styled-components";
import outstandingIcon from "../../../assets/images/outstanding-icon.svg";
import balanceIcon from "../../../assets/images/balance-icon.svg";
import receivedIcon from "../../../assets/images/received-icon.svg";
import PieChartComponent from "./PieChartComponent";

export default function FinanceCard({ type, data, pieChartData }) {
    // const data = [
    //     { name: "SSM", value: 400 },
    //     { name: "Singhania", value: 800 },
    //     { name: "OTHM", value: 300 },
    // ];
    // const [pieChartData, setPieChartData] = useState();

    // useEffect(() => {
    //     setPieChartData(data.data);
    // }, []);

    const colors = ["#C2A5FF", "#9161FE", " #661DFF", "#5117cc"];
    return (
        <MainContainer>
            <TotalBox>
                <TotalLeft>
                    <TitleText>Total {type} (AED)</TitleText>
                    {/* <AmountText>AED {data?.total}</AmountText> */}
                    <AmountText>
                        {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "AED",
                        }).format(data?.total).replace("AED", "")}
                    </AmountText>
                </TotalLeft>
                {/* <TotalRight>
                    {type && (
                        <CardIcon
                            src={
                                type === "receivables"
                                    ? outstandingIcon
                                    : type === "fee received"
                                    ? receivedIcon
                                    : type === "balance fee"
                                    ? balanceIcon
                                    : null
                            }
                        />
                    )}
                </TotalRight> */}
            </TotalBox>
            {data?.data?.map((item) => (
                <DetailLine>
                    <DetailTitle>{item.university}</DetailTitle>
                    <DetailText>
                        {/* AED */}
                        {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "AED",
                        }).format(item.amount).replace("AED", "")}
                        {/* {item.amount} */}
                    </DetailText>
                </DetailLine>
            ))}

            {/* <DetailLine>
                <DetailTitle>Synghania</DetailTitle>
                <DetailText>AED 452,850</DetailText>
            </DetailLine>
            <DetailLine>
                <DetailTitle>OTHM</DetailTitle>
                <DetailText>AED 452,850</DetailText>
            </DetailLine> */}
            {/* <GraphContainer>
                <GraphLeft>
                    <PieChartComponent
                        colors={colors}
                        data={pieChartData?.slice(0, 4)}
                    />
                </GraphLeft>
                <GraphRight>
                    {pieChartData?.slice(0, 4).map((item, index) => (
                        <GraphDetailLine key={index}>
                            <DetailLeft>
                                <Round bg={colors[index]}></Round>
                            </DetailLeft>
                            <DetailRight>
                                <GreyText>
                                    {item.university} {item.percentage}%
                                </GreyText>
                            </DetailRight>
                        </GraphDetailLine>
                    ))}
                </GraphRight>
            </GraphContainer> */}
        </MainContainer>
    );
}
const TotalBox = styled.div`
    background: #eff3ff;
    border: 1px solid #ffffff;
    box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    transition: all 0.2s;
`;
const MainContainer = styled.div`
    background: #ffffff;
    border: 1px solid #c8ecf1;
    border-radius: 16px;
    padding: 15px;
    /* margin-left: 10px; */
    transition: all 0.2s;
    transform: scale(1);

    &:hover {
        /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
        background: #fefcff;
        /* transform: scale(1.01);
        overflow: visible; */
        /* position: absolute; */
    }
    &:hover ${TotalBox} {
        /* background: #ffffff; */
        opacity: 0.9;
    }
`;

const TotalLeft = styled.div``;
const TitleText = styled.h6`
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    text-transform: uppercase;
    color: #404040;
    margin-bottom: 04px;
`;
const AmountText = styled.p`
    font-weight: 600;
    font-size: 24px;
    line-height: 39px;
    /* identical to box height */

    color: #000000;
`;
const TotalRight = styled.div``;
const CardIcon = styled.img`
    width: 58px;
    object-fit: cover;
`;
const DetailLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0px;
        /* border-bottom: 1px solid #c8ecf1; */
    }
`;
const DetailTitle = styled.span`
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    font-style: normal;
    color: #404040;
`;
const DetailText = styled.span`
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: right;

    color: #000000;
`;
const GraphContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* grid-gap: 10px; */
`;
const GraphLeft = styled.div`
    padding-right: 10%;
`;
const GraphRight = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 25px 0%;
    margin-left: 10px;
`;
const GraphDetailLine = styled.div`
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
`;
const DetailLeft = styled.div`
    display: flex;
    align-items: center;
    margin-right: 12px;
`;
const Round = styled.div`
    width: 14px;
    height: 14px;
    border-radius: 50%;
    /* margin-right: 8px; */
    background: ${(props) => (props.bg ? props.bg : null)};
`;
const GreyText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height, or 119% */

    /* Dark/400 */

    /* color: #404040; */
    color: #9d9d9d;
`;
const DetailRight = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: right;

    /* color: #000000; */
`;

// const MainContainer=styled.div``;
// const MainContainer=styled.div``;
// const MainContainer=styled.div``;
