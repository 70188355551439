import React, { useEffect, useState, useContext } from "react";
import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import Container from "../../components/Container";
import DeleteImage from "../../assets/images/delete.svg";
import Button from "../../components/Button";
import addbutton from "../../assets/images/addbutton.svg";
import Text from "../../components/Text";
import edit from "../../assets/images/edit.svg";
import visible from "../../assets/images/visible.svg";
import search from "./../../assets/images/header-search.svg";
import "./style.css";
import styled from "styled-components";
import {
	DeleteCampusAdminService,
	ListCampusAdminService,
} from "../../service/campusAdmin";
import { useHistory } from "react-router-dom";
import { Context } from "../../contexts/Store";
import DeleteModal from "./../../components/includes/DeleteModal";
import { baseConfig } from "./../../utils/config";

function CampusAdmin() {
	const [campusAdmins, setCampusAdmins] = useState([]);
	const history = useHistory();
	const [searchItem, setSearchItem] = useState("");
	const [isLoading, setLoading] = useState(true);
	const { state } = useContext(Context);
	const [isModal, setModal] = useState(false);
	const [deleteId, setDeleteId] = useState("");

	const getCampusAdminList = () => {
		setLoading(true);
		let accessToken = state.user_details.access_token;
		baseConfig
			.get(`/campus/campus-admin-list/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				params: {
					search: searchItem,
				},
			})
			.then((response) => {
				let { StatusCode, data, message } = response.data;
				if (StatusCode == 6000) {
					setLoading(false);
					setCampusAdmins(data.data);
				} else if (StatusCode == 6001) {
					setLoading(false);
				}
			})
			.catch((error) => {});
	};

	const handleDeleteData = () => {
		let accessToken = state.user_details.access_token;

		baseConfig
			.delete(`/campus/campus-admin-details/${deleteId}/`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				let { StatusCode, data, message } = response.data;
				if (StatusCode == 6000) {
					setModal(false);
					getCampusAdminList();
				} else if (StatusCode == 6001) {
					alert(data.data.message);
					getCampusAdminList();
				}
			})
			.catch((error) => {});
	};

	useEffect(() => {
		getCampusAdminList();
	}, [searchItem]);

	return (
		<>
			<Wrapper>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						marginBottom: "30px",
					}}
					className="top-title-wrapper"
				>
					<div>
						<Title className="top-title-large" fontSize="35px">
							Campus Admins
						</Title>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
						className="top-title-large-right"
					>
						<div
							style={{
								marginRight: "32px",
							}}
							top-title-search-div
						>
							<form className="search-container">
								<img src={search} alt="search" />
								<input
									className="input-box"
									type="text"
									name="search"
									placeholder="Search for something"
									onChange={(value) =>
										setSearchItem(value.target.value)
									}
								/>
							</form>
						</div>

						<Button
							// href="/superadmin/add-campus-admin/"
							onClick={() =>
								history.push("/superadmin/add-campus-admin/")
							}
							style={{
								display: "flex",
								alignItems: "center",
							}}
							backgroundColor="#5A2D8F"
							fontSize="15px"
						>
							<img src={addbutton} alt="Logo" />
							<Text
								style={{ marginLeft: "12px" }}
								color="white"
								fontSize="15px"
							>
								Add Admin
							</Text>
						</Button>

						<Button
							className="refresh-button-large"
							onClick={() => getCampusAdminList()}
							style={{
								display: "flex",
								alignItems: "center",
								margin: "0 22px",
								cursor: "pointer",
							}}
							backgroundColor="white"
							fontSize="15px"
						>
							<Text color=" #522e92" fontSize="15px">
								Refresh
							</Text>
						</Button>
					</div>
				</div>

				{isLoading === false ? (
					<>
						{campusAdmins.length !== 0 ? (
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									flexWrap: "wrap",
								}}
								className="university-admin-container-wrapper"
							>
								{campusAdmins?.map((admin) => {
									return (
										<Container
											width="48%"
											className="university-admin-container"
										>
											<div
												style={{
													display: "flex",
													justifyContent:
														"space-between",
												}}
											>
												<div>
													<Title fontSize="27px ">
														{
															admin?.campus_data
																?.name
														}
													</Title>
												</div>
												<div
													style={{
														justifyContent:
															"space-between",
													}}
												>
													<img
														style={{
															width: "100%",
															cursor: "pointer",
														}}
														src={DeleteImage}
														alt="Logo"
														onClick={() => {
															setModal(true);
															setDeleteId(
																admin?.id
															);
														}}
													/>
												</div>
											</div>
											<div style={{ marginTop: "20px" }}>
												<div
													style={{
														display: "flex",
														marginTop: "10px",
													}}
												>
													<Text fontSize="15px">
														Mail ID
													</Text>
													<h3
														style={{
															fontSize: "14px",
															color: "grey",
															marginLeft: "10px",
															marginTop: "2px",
														}}
													>
														{
															admin?.campus_data
																?.email
														}
													</h3>
												</div>
												<div
													style={{
														display: "flex",
														marginTop: "10px",
													}}
												>
													<Text fontSize="15px">
														Username
													</Text>
													<h3
														style={{
															fontSize: "14px",
															color: "grey",
															marginLeft: "10px",
															marginTop: "2px",
														}}
													>
														{
															admin?.campus_data
																?.name
														}
													</h3>
												</div>
												<div
													style={{
														display: "flex",
														justifyContent:
															"space-between",
													}}
												>
													<div
														style={{
															display: "flex",
															marginTop: "10px",
														}}
													>
														<Text fontSize="15px">
															Password
														</Text>
														<h3
															style={{
																fontSize:
																	"14px",
																color: "grey",
																marginLeft:
																	"10px",
																marginTop:
																	"4px",
															}}
														>
															{(admin?.password)
																.split("")
																?.map((e) => {
																	return "*";
																})}
														</h3>
													</div>

													<Button
														onClick={() =>
															history.push(
																`/superadmin/edit-campus-admin/${admin.id}`,
																admin
															)
														}
														backgroundColor="#5A2D8F"
														fontSize="15px"
														className="university-admin-update-button"
													>
														Update
													</Button>
												</div>
											</div>
										</Container>
									);
								})}
							</div>
						) : (
							<div className="no-result">
								<text className="no-result-text">
									No results found{" "}
								</text>
							</div>
						)}
					</>
				) : (
					<Div>
						<LoadingGif
							src={
								require("./../../assets/images/loading-buffering.gif")
									.default
							}
							alt="Loading"
						/>
					</Div>
				)}
			</Wrapper>
			{isModal && (
				<DeleteModal
					isModal={isModal}
					setModal={setModal}
					deleteId={deleteId}
					handleDeleteData={handleDeleteData}
				/>
			)}
		</>
	);
}

const Div = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 200px;
`;
const LoadingGif = styled.img`
	display: inline-block;
	width: 50px;
	@media (max-width: 980px) {
		width: 90px;
	}
`;
export default CampusAdmin;
