import React, { useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import Title from "../../components/Title";
import styled from "styled-components";
import "./style.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseConfig } from "../../utils/config";
import { useContext } from "react";
import { Context } from "../../contexts/Store";
import searchIcon from "../../assets/images/finance-search-icon.svg";
import MainPagination from "../../components/includes/MainPagination";
import profileIcon from "../../assets/images/profile.png";
import loadingIcon from "../../assets/lottie/loading.gif";
import viewIcon from "../../assets/images/view-icon.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function FinanceExecutives() {
    const history = useHistory();
    const { state } = useContext(Context);
    const [executivesData, setExecutiveData] = useState([]);
    const [search, setSearch] = useState("");
    const [itemsCount, setItemsCount] = useState(20);
    const [isLoading, setLoading] = useState(false);
    const [paginationData, setPaginationData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);

    const executiveList = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get(`/finance/finance-executives-data/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: search,
                    page: currentPage,
                    items: itemsCount,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setExecutiveData(data.data);
                    setPaginationData(data.paginator);
                    setTimeout(() => {
                        setLoading(false);
                    }, 100);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => {
        executiveList();
    }, [search, itemsCount, currentPage]);
    // console.log(executivesData, "LLLL");
    return (
        <Wrapper>
            <Title color="#000">Finance Executives</Title>
            {/* <Title color="#939393" fontSize="14px" fontWeight="500">
                Finance Executives
            </Title> */}
            <TopDiv>
                <SearchBoxDiv>
                    <label htmlFor="search">
                        <SearchIcon id="search" src={searchIcon} alt="icon" />
                    </label>
                    <SearchInput
                        type="text"
                        placeholder="Search"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </SearchBoxDiv>

                {/* <div>
                    <form className="search-container">
                        <img
                            src={
                                require("./../../assets/images/search-exe.svg")
                                    .default
                            }
                            alt="search"
                        />
                        <input
                            className="input-box"
                            type="text"
                            name="search"
                            placeholder="Search for something"
                            // onChange={(e) => {
                            //     setSearchItem(e.target.value);
                            // }}
                        />
                    </form>
                </div> */}
            </TopDiv>

            {/* <MainDIv>
                <TitlesDiv>
                    <MainTitles width="ad_no" style={{ textAlign: "left" }}>
                        Sl.No
                    </MainTitles>
                    <MainTitles width="stu_no">Name</MainTitles>
                    <MainTitles width="stu_no">Employee ID</MainTitles>
                    <MainTitles width="stu_no">Mobile No</MainTitles>
                    <MainTitles width="stu_no">Mail Id</MainTitles>
                    <MainTitles width="stu_no">Number of Students</MainTitles>
                    <EyeIcon></EyeIcon>
                </TitlesDiv>
                {isLoading === false ? (
                    executivesData !== 0 ? (
                        <div>
                            {executivesData?.data.map((item, index) => (
                                <DataDiv key={item.id}>
                                    <DataTitles
                                        width="ad_no"
                                        style={{ textAlign: "left" }}
                                    >
                                        {(currentPage - 1) * itemsCount +
                                            index +
                                            1}
                                    </DataTitles>
                                    <NameTitles width="stu_no">
                                        <Profile>
                                            <img src={item.image} alt="Image" />
                                        </Profile>
                                        <Name>{item.name}</Name>
                                    </NameTitles>
                                    <DataTitles width="stu_no">
                                        {item.employee_id}
                                    </DataTitles>
                                    <DataTitles width="stu_no">
                                        {item.phone}
                                    </DataTitles>
                                    <DataTitles width="stu_no">
                                        {item.email}
                                    </DataTitles>
                                    <DataTitles width="stu_no">
                                        {item.total_students}
                                    </DataTitles>
                                    <EyeIcon
                                        to={`/finance-manager/executive/${item.id}`}
                                    >
                                        <img
                                            src={
                                                require("../../assets/images/eyeblueicon.svg")
                                                    .default
                                            }
                                            alt="Image"
                                        />
                                    </EyeIcon>
                                </DataDiv>
                            ))}
                        </div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",

                                height: "400px",
                            }}
                        >
                            <text
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                No results found{" "}
                            </text>
                        </div>
                    )
                ) : (
                    <Div>
                        <LoadingGif
                            src={
                                require("./../../assets/images/loading-buffering.gif")
                                    .default
                            }
                            alt="Loading"
                        />
                    </Div>
                )}
            </MainDIv> */}

            {!isLoading ? (
                <DetailedSection>
                    <TableWrapper>
                        <Table className="table">
                            <Thead>
                                <THR>
                                    <TH>
                                        <HeaderDataContainer>
                                            <THText>Si. No</THText>
                                        </HeaderDataContainer>
                                    </TH>
                                    <TH>
                                        <HeaderDataContainer>
                                            <THText>Name</THText>
                                        </HeaderDataContainer>
                                    </TH>
                                    <TH>
                                        <HeaderDataContainer>
                                            <THText>Employee Id</THText>
                                        </HeaderDataContainer>
                                    </TH>
                                    <TH>
                                        <HeaderDataContainer>
                                            <THText>Mobile No</THText>
                                        </HeaderDataContainer>
                                    </TH>
                                    <TH>
                                        <HeaderDataContainer>
                                            <THText>Mail Id</THText>
                                        </HeaderDataContainer>
                                    </TH>
                                    <TH style={{ textAlign: "center" }}>
                                        <HeaderDataContainer>
                                            <THText>No. of Students</THText>
                                        </HeaderDataContainer>
                                    </TH>
                                    <TH></TH>
                                </THR>
                            </Thead>
                            {executivesData?.length > 0 ? (
                                <Tbody>
                                    {executivesData?.map((executive, index) => (
                                        <TDR key={executive.id}>
                                            <TD>
                                                <TDText>
                                                    {(currentPage - 1) *
                                                        itemsCount +
                                                        index +
                                                        1}
                                                </TDText>
                                            </TD>
                                            <TD>
                                                <BodyDataContainer>
                                                    <ProfilePic
                                                        src={
                                                            executive.image
                                                                ? executive.image
                                                                : profileIcon
                                                        }
                                                        alt="icon"
                                                    />
                                                    <NameContainer>
                                                        <TDTextName
                                                         onClick={() => {
                                                            history.push(
                                                                `/finance-manager/executive/${executive.id}`
                                                            );
                                                        }}
                                                        >
                                                            {executive.name}
                                                        </TDTextName>
                                                    </NameContainer>
                                                </BodyDataContainer>
                                            </TD>
                                            <TD>
                                                <TDText>
                                                    {executive.employee_id}
                                                </TDText>
                                            </TD>
                                            <TD>
                                                <TDText>
                                                    {executive.phone}
                                                </TDText>
                                            </TD>
                                            <TD>
                                                <TDText>
                                                    {executive.email}
                                                </TDText>
                                            </TD>
                                            <TD>
                                                <TDText>
                                                    {executive.total_students}
                                                </TDText>
                                            </TD>

                                            <TD>
                                                <ViewIcon
                                                    src={viewIcon}
                                                    onClick={() => {
                                                        history.push(
                                                            `/finance-manager/executive/${executive.id}`
                                                        );
                                                    }}
                                                    alt="icon"
                                                />
                                            </TD>
                                        </TDR>
                                    ))}
                                </Tbody>
                            ) : (
                                <Tbody>
                                    <TDR>
                                        <TD
                                            className="no-data-cell"
                                            colSpan="6"
                                            // colSpan={
                                            //     selectedTab == "Evaluated"
                                            //         ? "10"
                                            //         : "9"
                                            // }
                                        >
                                            No data found
                                        </TD>
                                    </TDR>
                                </Tbody>
                            )}
                        </Table>
                    </TableWrapper>
                    <MainPagination
                        paginationData={paginationData}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        marginRight="0"
                        isCountSelector={true}
                        setItemsCount={setItemsCount}
                        currentItemsCount={executivesData.length}
                    />
                </DetailedSection>
            ) : (
                <LoaderContainer>
                    <LoadingGif src={loadingIcon} alt="icon" />
                </LoaderContainer>
            )}

            {/* <ShowTextDiv>
                <Text>Show</Text>
                <CountDiv>
                    <Text>{count}</Text>
                    <Up>
                        <img
                            src={require("../../assets/images/up.svg").default}
                            alt="Image"
                        />
                    </Up>
                    <Down>
                        <img
                            src={require("../../assets/images/up.svg").default}
                            alt="Image"
                        />
                    </Down>
                </CountDiv>
                <Text>Result</Text>
            </ShowTextDiv> */}
        </Wrapper>
    );
}
const LoaderContainer = styled.div`
    height: calc(100vh - 210px);
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #0f0; */
`;
const ViewIcon = styled.img`
    width: 18px;
    display: block;
    object-fit: cover;
    cursor: pointer;
`;
const MainDIv = styled.div`
    margin-top: 10px;
    /* height: max-content; */
    height: calc(100vh - 250px);
    overflow: auto;
    border: 1px solid #d7d7d7;
    padding: 10px 35px;
    border-radius: 12px;
    background-color: #fff;
    /* overflow: auto; */
`;
const TitlesDiv = styled.div`
    display: flex;
    color: #9c9c9c;
    padding: 10px 0;
    margin-bottom: 10px;
`;
const MainTitles = styled.h3`
    font-size: 14px;
    width: ${(props) =>
        props.width === "ad_no"
            ? "8%"
            : props.width === "stu_no"
            ? "20%"
            : "15%"};
    /* flex: 1; */
    text-align: left;
    /* &:first-child {
        text-align: left;
    } */
    &:last-child {
        text-align: right;
    }
`;
const EyeIcon = styled(Link)`
    width: 16px;
    /* align-items: right; */
    img {
        display: block;
        width: 100%;
    }
`;
const DataDiv = styled.div`
    display: flex;
    color: #9c9c9c;
    background: #fbfbfb;
    padding: 20px 0;
    :hover {
        text-decoration: none;
    }
    &:first-child {
        text-align: left;
    }
`;
const DataTitles = styled.h3`
    font-size: 14px;
    color: #000;
    width: ${(props) =>
        props.width === "ad_no"
            ? "8%"
            : props.width === "stu_no"
            ? "20%"
            : "15%"};
    text-align: left;

    &:last-child {
        text-align: right;
    }
    &:nth-child(6) {
        text-align: center;
    }
`;

const NameTitles = styled.div`
    text-align: center;
    display: flex;
    justify-content: left;
    width: ${(props) =>
        props.width === "ad_no"
            ? "5%"
            : props.width === "stu_no"
            ? "20%"
            : "15%"};
`;
const Name = styled.h3`
    font-size: 14px;
    color: #000;
`;
const TopDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
const ShowTextDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    justify-content: left;
`;
const Text = styled.p`
    font-size: 14px;
    color: ${(props) => (props.color === "filter" ? "#8561C6" : "#000")};
    margin-left: 10px;
    margin-right: 10px;
    width: 30px;
    min-width: 30px;
    max-width: 30px;
`;
const CountDiv = styled.div`
    display: flex;
    position: relative;
    border: 1px solid #d7d7d7;
    border-radius: 8px;
    padding: 12px;
`;
const Up = styled.div`
    width: 14px;
    img {
        display: block;
        width: 100%;
    }
`;
const Down = styled.div`
    width: 14px;
    position: absolute;
    top: 25px;
    left: 62px;
    img {
        display: block;
        width: 100%;
        transform: rotate(180deg);
    }
`;
const SearchDiv = styled.div`
    display: flex;
    align-items: center;
`;
const Form = styled.form`
    font-size: 15px;
    box-sizing: border-box;
    background: #f5f5f5;
    border-radius: 8px;
    border: 1px solid #f5f5f5;
    display: flex;
    align-items: center;
    padding: 10px;
    width: 100%;
`;
const SearchIcon = styled.img`
    margin-right: 10px;
`;
const SearchField = styled.input`
    background: #f5f5f5;
    width: 400px;
    outline: none;
    border: 1px solid #f5f5f5;
    &:focus-visible {
        background: #f5f5f5;
    }
    ::placeholder {
        color: #b6b6b6;
    }
`;
const Filter = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #8561c6;
    padding: 10px;
    border-radius: 8px;
`;
const Profile = styled.div`
    width: 20px;
    margin-right: 10px;
    /* margin-left: 60px; */
    img {
        display: block;
        width: 100%;
        border-radius: 50%;
    }
`;
const SearchContainer = styled.div`
    width: 520px;
    height: 300px;
    position: absolute;
    display: none;
    background-color: #ffff;
    padding: 20px;
    border-radius: 6px;
    right: 30px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    &.search {
        display: block;
    }
`;
const SearchBoxDiv = styled.div`
    width: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 6px;
    /* height: 42px; */

    /* background: #f5f5f5; */
    background: #fff;
    border-radius: 8px;
    /* border: 1px solid #f5f5f5; */
    border: 1px solid #d7d7d7;

    input[type="text"] {
        border: none; /* Remove default borders */
        padding: 4px 8px;
        border-radius: 12px; /* Sadly outline does not round! therefore...*/
    }
    input[type="text"]:focus {
        outline: none; /* Remove default outline and use border or box-shadow */
        /* box-shadow: 0 0 0 2px orange; */
        /* Full freedom. (works also with border-radius) */
    }
`;
const SearchInput = styled.input`
    display: inline-block;
    width: calc(350px - 18px);
    height: 100%;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    border: none;
    color: #404040;

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #b6b6b6;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #b6b6b6;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #b6b6b6;
    }
`;
const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
`;
const LoadingGif = styled.img`
    display: inline-block;
    width: 50px;
    @media (max-width: 980px) {
        width: 90px;
    }
`;

// New table styles
const DetailedSection = styled.div`
    margin: 20px 0 0 0;
`;
const HeaderDataContainer = styled.div`
    display: flex;
    align-items: center;
`;
const BodyDataContainer = styled.div`
    display: flex;
    align-items: center;
`;
const TableWrapper = styled.div`
    border: 1px solid #e7e7e7;
    border-radius: 16px;
    overflow: auto;
`;
const Table = styled.table`
    min-width: 100%;
    width: auto;
    background: rgb(237, 245, 254);
    /* table-layout: fixed; */
    padding: 0;
    margin: 0;
    border-spacing: 0;
    /* border-radius: 16px; */
    text-transform: capitalize;

    th {
        color: #9d9d9d;
        font-weight: 500;
        font-size: 15px;
        text-transform: capitalize;

        padding: 18px 12px;
        text-align: left;
        /* max-width: 300px !important; */
        /* min-width: 250px !important; */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    td {
        /* font-size: 15px; */
        font-size: 14.5px;
        font-weight: 500;
        padding: 18px 12px;
        text-align: left;
        /* max-width: 300px !important; */
        /* min-width: 250px !important; */

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    td,
    th {
        vertical-align: middle;
    }
    .no-data-cell {
        color: #404040;
        font-size: 22px;
        /* font-weight: 500; */
        text-align: center; /* Center-align text horizontally */
        vertical-align: middle; /* Center-align text vertically */
        height: calc(
            100vh - 290px
        ); /* Set a fixed height to vertically center the content if needed */
    }
    .pagination-cell {
        text-align: right;
    }
`;
const Thead = styled.thead`
    /* background-color: #edf5fe; */
`;
const THR = styled.tr``;
const TH = styled.th`
    /* background-color: #edf5fe; */

    &:first-child {
        /* position: sticky;
    left: 0;
    z-index: 1; */
    }

    &:last-child {
    }
`;
const Tbody = styled.tbody`
    background: #fbfbfb;
`;
const TDR = styled.tr`
    &:nth-child(odd) > td {
        /* background: #ffffff; */
    }
    &:nth-child(even) > td {
        background: #ffffff80;
    }

    &:last-child > td {
        &:first-child {
        }
        &:last-child {
        }
    }
`;
const TD = styled.td`
    text-transform: ${(props) =>
        props.isEmailField ? "lowercase" : "capitalize"};

    font-family: "Inter", sans-serif;

    &:first-child {
        /* position: sticky;
    left: 0;
    z-index: 1; */
    }

    &:last-child {
    }
`;
const TDText = styled.span`
    font-family: "Inter", sans-serif;
    font-size: 15px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
const THText = styled.span`
    display: inline-block;
`;
const ProfilePic = styled.img`
    display: block;
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 6px;
`;
const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const TDTextName = styled.div`
    color:var(--link-color);
    cursor:pointer;
`;
