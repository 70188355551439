import React, { useContext, useEffect, useRef, useState } from "react";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import styled from "styled-components";
import Title from "../../components/Title";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as CalendarIcon } from "../../assets/images/custom-calendar-icon.svg";
import { Context } from "../../contexts/Store";
import calendarIcon from "../../assets/images/custom-calendar-icon.svg";
import Select from "./includes/Select";
import { Popover } from "@mui/material";
import DropDownPopOver from "../../components/includes/DropDownPopOver";
import { baseConfig } from "../../utils/config";
import downloadIcon from "../../assets/images/download-icon.svg";
import MainPagination from "../../components/includes/MainPagination";
import ReportPageTable from "./includes/ReportPageTable";
import moment from "moment";
import loadingGif from "../../assets/lottie/loading.gif";

export default function ReportPage() {
    const { state } = useContext(Context);
    let userRole = state.user_details.role;
    const [search, setSearch] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [isDownloadLoading, setDownloadLoading] = useState(false);
    const [paginationData, setPaginationData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsCount, setItemsCount] = useState(40);
    const [generatedStudents, setGeneratedStudents] = useState([]);
    const [downloadLink, setDownloadLink] = useState("");
    const [downloadLinkUrl, setDownloadLinkUrl] = useState("");

    const [selectedCriteria, setSelectedCriteria] = useState("");

    // all items states
    const [countries, setCountries] = useState([]);
    const [universities, setUniversities] = useState([]);
    const [courses, setCourses] = useState([]);
    const [consultants, setConsultants] = useState([]);
    const [executives, setExecutives] = useState([]);
    const [leadSources, setLeadSources] = useState([]);
    const statuses = [
        // {
        //     id: 1,
        //     name: "All Students",
        //     value: "",
        // },
        {
            id: 2,
            name: "Active",
            // value: "",
        },
        {
            id: 3,
            name: "On Hold",
            // value: "On Hold",
        },
        {
            id: 4,
            name: "On Break",
            // value: "On Break",
        },
        {
            id: 5,
            name: "Dropout",
            // value: "Dropout",
        },
        {
            id: 6,
            name: "Completed",
            // value: "Completed",
        },
    ];

    // selected item states
    const [selectedCountry, setSelectedCountry] = useState({});
    const [selectedNationality, setSelectedNationality] = useState({});
    const [selectedUniversity, setSelectedUniversity] = useState({});
    const [selectedCourse, setSelectedCourse] = useState({});
    const [selectedExecutive, setSelectedExecutive] = useState({});
    const [selectedStatus, setSelectedStatus] = useState({});

    // Select category items
    const selectFields = [
        {
            id: 1,
            name: "Finance Executive",
            value: "executive",
            type: "single",
        },
        {
            id: 2,
            name: "Country",
            value: "country",
            type: "single",
        },
        {
            id: 3,
            name: "Nationality",
            value: "nationality",
            type: "single",
        },

        {
            id: 4,
            name: "University",
            value: "university",
            type: "single",
        },
        {
            id: 5,
            name: "Course",
            value: "course",
            type: "single",
        },
        {
            id: 6,
            name: "Status",
            value: "status",
            type: "single",
        },
        // {
        //     id: 7,
        //     name: "Source",
        //     value: "source",
        //     type: "single",
        // },
        // {
        //     id: 8,
        //     name: "Consultant",
        //     value: "consultant",
        //     type: "single",
        // },
        // {
        //     id: 9,
        //     name: "Course",
        //     value: "course",
        //     type: "single",
        // },
        // {
        //     id: 10,
        //     name: "Specialization",
        //     value: "specialization",
        //     type: "single",
        // },
    ];
    // related to fields selection
    const alwaysSelectedFields = [
        "admission_number",
        "name",
        "email",
        "total_course_fees",
        "total_fee_paid",
        "total_balance_amount",
    ];
    const defaultFields = [
        "admission_number",
        "name",
        // "phone",
        "email",
        "total_course_fees",
        "total_fee_paid",
        "total_balance_amount",
    ];
    const checkItems = [
        // {
        //     id: 1,
        //     name: "Id no",
        //     value: "student_id",
        // },
        {
            id: 11,
            name: "Admission number",
            value: "admission_number",
        },
        {
            id: 2,
            name: "Name",
            value: "name",
        },
        {
            id: 3,
            name: "Phone",
            value: "phone",
        },
        {
            id: 4,
            name: "Email",
            value: "email",
        },
        {
            id: 5,
            name: "country",
            value: "country",
        },
        {
            id: 6,
            name: "Nationality",
            value: "nationality",
        },
        {
            id: 8,
            name: "University",
            value: "university",
        },
        {
            id: 9,
            name: "Course",
            value: "course",
        },

        {
            id: 10,
            name: "Source",
            value: "source",
        },

        {
            id: 12,
            name: "Join date",
            value: "join_date",
        },
        {
            id: 13,
            name: "Status",
            value: "status",
        },
        {
            id: 14,
            name: "Consultant",
            value: "consultant",
        },
        {
            id: 15,
            name: "Executive",
            value: "executive",
        },
        {
            id: 17,
            name: "Total Course Fees",
            value: "total_course_fees",
        },
        {
            id: 18,
            name: "Total Fee Paid",
            value: "total_fee_paid",
        },
        {
            id: 19,
            name: "Total balance Amount",
            value: "total_balance_amount",
        },
        {
            id: 20,
            name: "Total Due Amount",
            value: "total_due_amount",
        },
        {
            id: 21,
            name: "Due date",
            value: "due_date",
        },

        {
            id: 23,
            name: "Last Payment",
            value: "last_payment_date",
        },
        {
            id: 16,
            name: "Next Followup",
            value: "next_followup",
        },
        {
            id: 22,
            name: "Emi amount",
            value: "emi_amount",
        },
        {
            id: 24,
            name: "VAT",
            value: "total_vat_amount"
        },
        {
            id: 7,
            name: "Remark",
            value: "remark",
        },
    ];
    const [selectedFields, setSelectedFields] = useState(defaultFields);
    const [isAllFieldsSelected, setIsAllFieldsSelected] = useState(false);
    const [isDefaultFieldsSelected, setIsDefaultFieldsSelected] =
        useState(false);

    const handleFieldSelection = (field) => {
        if (alwaysSelectedFields.includes(field)) {
            // Field is always selected, do not remove it
            if (!selectedFields.includes(field)) {
                setSelectedFields([...selectedFields, field]);
            }
        } else {
            // Field is not always selected, toggle its selection
            if (selectedFields.includes(field)) {
                setSelectedFields(selectedFields.filter((f) => f !== field));
            } else {
                setSelectedFields([...selectedFields, field]);
            }
        }
    };
    const checkDefaultFieldsSelected = () => {
        const defaultFieldsSelected =
            selectedFields.length === defaultFields.length &&
            selectedFields.every((selectedItem) =>
                defaultFields.includes(selectedItem)
            );

        if (defaultFieldsSelected) {
            setIsDefaultFieldsSelected(true);
        } else {
            setIsDefaultFieldsSelected(false);
        }
    };
    const checkAllFieldsSelected = () => {
        let itemsToCheck = checkItems;
        if (userRole !== "lead_manager") {
            itemsToCheck = itemsToCheck.filter(
                (item) => item.value !== "executive"
            );
        }

        const allFieldsSelected = itemsToCheck.every((checkItem) =>
            selectedFields.includes(checkItem.value)
        );

        if (allFieldsSelected && itemsToCheck.length > 0) {
            setIsAllFieldsSelected(true);
        } else {
            setIsAllFieldsSelected(false);
        }
    };
    const handleDefaultFieldsSelection = () => {
        if (isDefaultFieldsSelected) {
            removeDefaultFieldsFromSelectedFields();
        } else {
            setSelectedFields(
                checkItems
                    .filter((item) => defaultFields.includes(item.value))
                    .map((item) => item.value)
            );
        }
    };
    function removeDefaultFieldsFromSelectedFields() {
        const filteredFields = selectedFields.filter(
            (field) =>
                !defaultFields.includes(field) ||
                alwaysSelectedFields.includes(field)
        );
        setSelectedFields(filteredFields);
    }
    useEffect(() => {
        checkAllFieldsSelected();
    }, [selectedFields, checkItems]);
    useEffect(() => {
        checkDefaultFieldsSelected();
    }, [selectedFields, checkItems]);
    // end of fields selection

    // calendar related
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const fromDatePickerRef = useRef();
    const handleFromCalendarIconClick = () => {
        fromDatePickerRef.current.setOpen(true);
    };
    const toDatePickerRef = useRef();
    const handleToCalendarIconClick = () => {
        toDatePickerRef.current.setOpen(true);
    };
    const todayClass = {
        backgroundColor: "#917afd !important",
        color: "black",
        fontWeight: "bold",
    };
    // end of claendar related

    // popover filter select boxes
    const [anchorEl, setAnchorEl] = useState(null);
    const popoverStyle = {
        position: "absolute",
        top: 55,
        // left: -128,
    };
    const handleClick = (event, fieldValue) => {
        setAnchorEl(event.currentTarget);
        setSelectedCriteria(fieldValue);
        const maxWidth = Array.from(parentRef.current.children).reduce(
            (acc, child) => {
                const childWidth = child.getBoundingClientRect().width;
                return childWidth > acc ? childWidth : acc;
            },
            0
        );
        setPopoverWidth(maxWidth);

        // Get the distance of the click from the top of the viewport
        const distanceFromTop = event.clientY;
        // Calculate the height of the Paper component based on the click position
        const height = `calc(90vh - ${distanceFromTop}px)`;
        setPopoverHeight(height);
    };
    const parentRef = useRef();
    const [popoverWidth, setPopoverWidth] = useState(0);
    const [popoverHeight, setPopoverHeight] = useState(0);
    // end of popover filter select boxes

    // api's

    const getUniversities = () => {
        let accessToken = state.user_details.access_token;

        baseConfig
            .get("/campus/university-list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: search,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setUniversities(data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const getConsultants = () => {
        let accessToken = state.user_details.access_token;

        baseConfig
            .get("/sales-persons/list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: search,
                    // team: userRole === "lead_manager" ? selectedTeam.id : null,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setConsultants(data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const getCourses = () => {
        let accessToken = state.user_details.access_token;

        baseConfig
            .get("/campus/course-list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: search,
                    university: selectedUniversity.id,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setCourses(data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const getCountries = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/countries/list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: search,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setCountries(data);
                } else {
                }
            })
            .catch((error) => {});
    };
    const getExecutives = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/finance/finance-executive-list/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    search: search,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setExecutives(data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const generateStudentsData = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/finance/finance-student-report/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    page: currentPage,
                    items: itemsCount,
                    fields: JSON.stringify(selectedFields),

                    start_date: fromDate
                        ? moment(fromDate).format("YYYY-MM-DD")
                        : "",

                    end_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",

                    country: selectedCountry.id,
                    nationality: selectedNationality.id,
                    university: selectedUniversity.id,
                    course: selectedCourse.id,
                    finance_executive: selectedExecutive.id,
                    status: selectedStatus.name,
                    // stages: JSON.stringify(
                    //     selectedLeadStages.map((stage) => stage.id)
                    // ),
                    // sources: JSON.stringify(
                    //     selectedLeadSources.map((source) => source.id)
                    // ),
                    // consultants: JSON.stringify(
                    //     selectedConsultants.map((consultant) => consultant.id)
                    // ),
                    // fields:JSON.stringify(
                    //     selectedFields.map((field) => consultant.id)
                    // ),
                },
            })

            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setGeneratedStudents(data.data);
                    setDownloadLink(data.download_link);
                    setPaginationData(data.paginator);
                    setTimeout(() => {
                        setLoading(false);
                    }, 50);
                } else {
                    alert("Some error occured,please try again later");
                    setLoading(false);
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    const downloadStudentsData = () => {
        setLoading(true);
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/finance/finance-report-excel/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
                params: {
                    page: currentPage,
                    items: itemsCount,
                    fields: JSON.stringify(selectedFields),

                    start_date: fromDate
                        ? moment(fromDate).format("YYYY-MM-DD")
                        : "",

                    end_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",

                    country: selectedCountry.id,
                    nationality: selectedNationality.id,
                    university: selectedUniversity.id,
                    course: selectedCourse.id,
                    finance_executive: selectedExecutive.id,
                    status: selectedStatus.name,
                },
            })

            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setDownloadLinkUrl(data);
                    setTimeout(() => {
                        setLoading(false);
                    }, 50);
                } else {
                    alert("Some error occured,please try again later");
                    setLoading(false);
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    useEffect(() => {
        if (userRole === "finance_manager") {
            getExecutives();
        }
        getCountries();
        getConsultants();
        getUniversities();
        if (selectedUniversity) {
            getCourses();
        }
    }, []);

    useEffect(() => {
        generateStudentsData();
    }, [itemsCount, currentPage]);

    useEffect(() => {
        // if (search !== "") {
        if (selectedCriteria.value === "university") {
            getUniversities();
        } else if (selectedCriteria.value === "course") {
            if (selectedUniversity) {
                getCourses();
            }
        } else if (selectedCriteria.value === "country") {
            getCountries();
        } else if (selectedCriteria.value === "nationality") {
            getCountries();
        } else if (selectedCriteria.value === "executive") {
            if (userRole === "finance_manager") {
                getExecutives();
            }
        }
        // }
        //  else if (selectedCriteria.value === "consultant") {
        //     if (userRole === "lead_manager") {
        //         if (Object.keys(selectedTeam).length > 0) {
        //             getConsultants();
        //         }
        //     } else {
        //         getConsultants();
        //     }
        // }
    }, [search, selectedCriteria]);


    // Function needed for download data excel - dont remove
    const handleDownload = () => {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(generatedStudents);

        // Calculate column widths
        const columnWidths = [];
        generatedStudents.forEach(row => {
            Object.keys(row).forEach((key, index) => {
                const value = row[key];
                const length = value ? value.toString().length : 0;
                if (columnWidths[index] === undefined || length > columnWidths[index]) {
                    columnWidths[index] = length;
                }
            });
        });

        // Set column widths (minimum width of 10)
        worksheet['!cols'] = columnWidths.map(width => ({ wpx: Math.max(width * 3, 100) }));

        // Make headers bold
        const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
        for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
            const cellAddress = { c: col, r: headerRange.s.r };
            const cellRef = XLSX.utils.encode_cell(cellAddress);
            if (!worksheet[cellRef]) worksheet[cellRef] = {}; // Ensure the cell exists
            if (!worksheet[cellRef].s) worksheet[cellRef].s = {};
            worksheet[cellRef].s.font = { bold: true }; // Apply bold font
        }

        // Generate today's date
        const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
        const fileName = `student-finance-report-${today}.xls`;

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Convert the workbook to a binary string
        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Save the file using file-saver
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), fileName);
      };

    return (
        <MainContainer>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                    // background: "#FFFFFF",
                    // boxShadow: "0px 5px 14px rgba(243, 243, 243, 0.15)",
                }}
            >
                <Title
                    fontSize="30px"
                    fontWeight="700"
                    style={{
                        marginLeft: "18px",
                    }}
                >
                    Outstanding Report
                </Title>
            </div>
            <SkyBlueContainer>
                <WhiteBox>
                    <SectionTitleContainer>
                        <SectionTitle>Select Search Criteria</SectionTitle>
                        <DateInputsWrapper>
                            {/* <div className="datepicker-wrapper">
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="From"
                                    selected={
                                        fromDate ? new Date(fromDate) : null
                                    }
                                    onChange={(date) => setFromDate(date)}
                                    className="custom-datepicker"
                                    calendarClassName="datepicker-calendar"
                                    ref={fromDatePickerRef}
                                    todayClassName="custom-today"
                                    todayButton="Today"
                                    highlightDates={[new Date()]}
                                    dayClassName={(date) =>
                                        date.getTime() === new Date().getTime()
                                            ? "today"
                                            : ""
                                    }
                                    showTimeSelect={false}
                                    timeFormat={undefined}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    // showYearDropdown={true}
                                />
                                <span className="datepicker-icon">
                                    <CalendarIcon
                                        style={{ width: "18px" }}
                                        onClick={handleFromCalendarIconClick}
                                    />
                                </span>
                            </div> */}
                            <div
                                className="datepicker-wrapper"
                                style={{ marginLeft: "12px" }}
                            >
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="As on Date"
                                    selected={toDate}
                                    onChange={(date) => setToDate(date)}
                                    className="custom-datepicker"
                                    calendarClassName="datepicker-calendar"
                                    ref={toDatePickerRef}
                                    todayClassName="custom-today"
                                    todayButton="Today"
                                    highlightDates={[new Date()]}
                                    dayClassName={(date) =>
                                        date.getTime() === new Date().getTime()
                                            ? "today"
                                            : ""
                                    }
                                    todayButtonProps={{
                                        className: "custom-today-button",
                                        style: todayClass,
                                    }}
                                    showTimeSelect={false}
                                    // showYearDropdown={true}
                                    timeFormat={undefined}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    // disabled={fromDate ? false : true}
                                />
                                <span className="datepicker-icon">
                                    <CalendarIcon
                                        style={{ width: "18px" }}
                                        onClick={handleToCalendarIconClick}
                                    />
                                </span>
                            </div>
                            {/* <DatePicker
                                label="Select Date"
                                format="MM/dd/yyyy"
                                value={fromDate}
                                // onChange={handleDateChange}
                            /> */}
                            {/* <DateInput
                                type="date"
                                value={toDate}
                                placeholder="From"
                                className="custom-date-input"
                            /> */}
                        </DateInputsWrapper>
                    </SectionTitleContainer>
                    <InnerSkyBlueContainer style={{ margin: "12px 0 20px 0" }}>
                        <SelectBoxesContainer ref={parentRef}>
                            {selectFields.map((selectField) => {
                                if (
                                    userRole !== "finance_manager" &&
                                    selectField.value === "executive"
                                ) {
                                    return null;
                                }

                                return (
                                    <SelectBoxContainer key={selectField.id}>
                                        <SelectTitle>
                                            {selectField.name}
                                        </SelectTitle>
                                        <Select
                                            handleClick={handleClick}
                                            field={selectField}
                                            selectedCriteria={selectedCriteria}
                                            selectedExecutive={
                                                selectedExecutive
                                            }
                                            selectedCountry={selectedCountry}
                                            selectedNationality={
                                                selectedNationality
                                            }
                                            selectedUniversity={
                                                selectedUniversity
                                            }
                                            selectedCourse={selectedCourse}
                                            selectedStatus={selectedStatus}
                                        />
                                    </SelectBoxContainer>
                                );
                            })}
                        </SelectBoxesContainer>
                        <Popover
                            style={popoverStyle}
                            PaperProps={{
                                style: {
                                    backgroundColor: "transparent",
                                    boxShadow: "none",
                                    borderRadius: "8px",
                                    background: "#fff",
                                    border: "1px solid #e7e7e7",
                                    borderRadius: "12px",
                                    boxShadow:
                                        "8px 16px 44px rgba(0, 0, 0, 0.25)",
                                },
                            }}
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={() => {
                                setAnchorEl(null);
                                if(selectedCriteria.value=="country"){
                                    setSelectedCountry("")
                                }else if(selectedCriteria.value=="status"){
                                    setSelectedStatus("")
                                }else if(selectedCriteria.value=="nationality"){
                                    setSelectedNationality("")
                                }else if(selectedCriteria.value=="university"){
                                    setSelectedUniversity("")
                                }else if(selectedCriteria.value=="course"){
                                    setSelectedCourse("")
                                }else if(selectedCriteria.value=="executive"){
                                    setSelectedExecutive("")
                                }
                                if (search) {
                                    setSearch("");
                                }
                            }}
                        >
                            <PopOverContainer
                                style={{
                                    width: popoverWidth,
                                    maxHeight: popoverHeight,
                                    minHeight: "100px",
                                }}
                            >
                                {selectedCriteria.value === "country" ? (
                                    <DropDownPopOver
                                        setAnchorEl={setAnchorEl}
                                        selectedCriteria={selectedCriteria}
                                        search={search}
                                        setSearch={setSearch}
                                        sectionName="Countries"
                                        allItems={countries}
                                        selectedItem={selectedCountry}
                                        setSelectedItem={setSelectedCountry}
                                    />
                                ) : selectedCriteria.value === "status" ? (
                                    <DropDownPopOver
                                        setAnchorEl={setAnchorEl}
                                        selectedCriteria={selectedCriteria}
                                        search={search}
                                        setSearch={setSearch}
                                        sectionName="Status"
                                        allItems={statuses}
                                        selectedItem={selectedStatus}
                                        setSelectedItem={setSelectedStatus}
                                    />
                                ) : selectedCriteria.value === "nationality" ? (
                                    <DropDownPopOver
                                        setAnchorEl={setAnchorEl}
                                        anchorEl={anchorEl}
                                        selectedCriteria={selectedCriteria}
                                        search={search}
                                        setSearch={setSearch}
                                        sectionName="Nationalities"
                                        allItems={countries}
                                        selectedItem={selectedNationality}
                                        setSelectedItem={setSelectedNationality}
                                    />
                                ) : selectedCriteria.value === "university" ? (
                                    <DropDownPopOver
                                        setAnchorEl={setAnchorEl}
                                        selectedCriteria={selectedCriteria}
                                        search={search}
                                        setSearch={setSearch}
                                        sectionName="Universities"
                                        allItems={universities}
                                        selectedItem={selectedUniversity}
                                        setSelectedItem={setSelectedUniversity}
                                    />
                                ) : selectedCriteria.value === "course" ? (
                                    <DropDownPopOver
                                        setAnchorEl={setAnchorEl}
                                        selectedCriteria={selectedCriteria}
                                        search={search}
                                        setSearch={setSearch}
                                        sectionName="Courses"
                                        allItems={courses}
                                        selectedItem={selectedCourse}
                                        setSelectedItem={setSelectedCourse}
                                    />
                                ) : selectedCriteria.value === "executive" ? (
                                    <DropDownPopOver
                                        setAnchorEl={setAnchorEl}
                                        selectedCriteria={selectedCriteria}
                                        search={search}
                                        setSearch={setSearch}
                                        sectionName="Finance Executive"
                                        allItems={executives}
                                        selectedItem={selectedExecutive}
                                        setSelectedItem={setSelectedExecutive}
                                    />
                                ) : null}
                            </PopOverContainer>
                        </Popover>
                    </InnerSkyBlueContainer>
                    <SectionTitleContainer>
                        <SectionTitle>Select Display Field</SectionTitle>
                    </SectionTitleContainer>
                    <InnerSkyBlueContainer style={{ margin: "20px 0 0 0" }}>
                        <CheckAllSection>
                            <CheckAllItem>
                                <CheckBox
                                    onClick={() => {
                                        // if (isAllFieldsSelected) {
                                        //     const selected =
                                        //         selectedFields.filter((field) =>
                                        //             alwaysSelectedFields.includes(
                                        //                 field
                                        //             )
                                        //         );
                                        //     setSelectedFields(selected);
                                        // } else {
                                        //     setSelectedFields(
                                        //         checkItems.map(
                                        //             (checkItem) =>
                                        //                 checkItem.value
                                        //         )
                                        //     );
                                        // }
                                        if (isAllFieldsSelected) {
                                            const selected =
                                                selectedFields.filter((field) =>
                                                    alwaysSelectedFields.includes(
                                                        field
                                                    )
                                                );
                                            setSelectedFields(selected);
                                        } else {
                                            // setSelectedFields(
                                            //     checkItems.map(
                                            //         (checkItem) =>
                                            //             checkItem.value
                                            //     )
                                            // );
                                            if (
                                                userRole !== "finance_manager"
                                            ) {
                                                setSelectedFields(
                                                    checkItems
                                                        .filter(
                                                            (item) =>
                                                                item.value !==
                                                                "executive"
                                                        )
                                                        .map(
                                                            (checkItem) =>
                                                                checkItem.value
                                                        )
                                                );
                                            } else {
                                                setSelectedFields(
                                                    checkItems.map(
                                                        (checkItem) =>
                                                            checkItem.value
                                                    )
                                                );
                                            }
                                        }
                                    }}
                                >
                                    {isAllFieldsSelected && "\u2713"}
                                </CheckBox>
                                <CheckItemText>Check all</CheckItemText>
                            </CheckAllItem>
                            <CheckAllItem>
                                <CheckBox
                                    onClick={() => {
                                        handleDefaultFieldsSelection();
                                    }}
                                >
                                    {isDefaultFieldsSelected && "\u2713"}
                                </CheckBox>
                                <CheckItemText>Check Default</CheckItemText>
                            </CheckAllItem>
                        </CheckAllSection>
                        <CheckItemsContainer>
                            {checkItems.map((item) => {
                                if (
                                    userRole !== "finance_manager" &&
                                    item.value === "executive"
                                ) {
                                    return null; // exclude item from rendering
                                }
                                return (
                                    <CheckItem key={item.id}>
                                        <CheckBox
                                            onClick={() => {
                                                handleFieldSelection(
                                                    item.value
                                                );
                                            }}
                                            noAction={
                                                alwaysSelectedFields.includes(
                                                    item.value
                                                )
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {selectedFields.includes(
                                                item.value
                                            ) && "\u2713"}
                                        </CheckBox>
                                        <CheckItemText>
                                            {item.name}
                                        </CheckItemText>
                                    </CheckItem>
                                );
                            })}
                        </CheckItemsContainer>
                    </InnerSkyBlueContainer>
                </WhiteBox>
                <GenerateButton
                    onClick={() => {
                        generateStudentsData();
                    }}
                >
                    Generate
                </GenerateButton>
            </SkyBlueContainer>
            <SkyBlueContainer style={{ marginTop: "40px" }}>
                {!isLoading ? (
                    <>
                        {generatedStudents.length > 0 ? (
                            <>
                                <ReportTitleBox>
                                    <SectionTitle>Report</SectionTitle>
                                    <DownloadButton
                                        // onClick={downloadStudentsData}
                                    >
                                        <a
                                            href={downloadLink}
                                            download
                                            style={{ textDecoration: 'none', display: 'inline-flex', alignItems: 'center' }}
                                        >
                                            <DownloadIcon
                                                src={downloadIcon}
                                                alt="icon"
                                            />
                                            <DownloadText>Download</DownloadText>
                                        </a>
                                    </DownloadButton>
                                </ReportTitleBox>
                                <TableContainer>
                                    <ReportPageTable
                                        data={generatedStudents}
                                        selectedFields={selectedFields}
                                    />
                                </TableContainer>
                                <div style={{ marginTop: "20px" }}>
                                    <MainPagination
                                        marginRight="0px"
                                        paginationData={paginationData}
                                        setCurrentPage={setCurrentPage}
                                        currentPage={currentPage}
                                        isCountSelector={true}
                                        itemsCount={itemsCount}
                                        setItemsCount={setItemsCount}
                                        currentItemsCount={
                                            generatedStudents.length
                                        }
                                    />
                                </div>
                            </>
                        ) : (
                            <LoaderContainer>
                                <NoResultsFoundText>
                                    No Results Found
                                </NoResultsFoundText>
                            </LoaderContainer>
                        )}
                    </>
                ) : (
                    <LoaderContainer>
                        <LoaderIcon src={loadingGif} alt="icon" />
                    </LoaderContainer>
                )}
            </SkyBlueContainer>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    padding: 20px;
    background: ${(props) => (props.background ? props.background : "#f5f7fa")};
    /* background: #fff; */
    height: 100%;
    overflow: auto;
    margin-top: 80px;
    @media (max-width: 1080px) {
        padding: 20px;
    }
`;
const LoaderIcon = styled.img`
    width: 45px;
    display: block;
    object-fit: cover;
`;
const SkyBlueContainer = styled.div`
    background: #ecf8ff;
    border-radius: 16px;
    padding: 24px;
`;
const WhiteBox = styled.div`
    padding: 20px;
    background: #ffffff;
    border-radius: 16px;
`;
const SectionTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const SectionTitle = styled.span`
    /* font-weight: 400; */
    display: block;
    font-size: 18px;
    text-transform: capitalize;
    color: #000000;
`;
const DateInputsWrapper = styled.div`
    display: flex;
    align-items: center;
    .custom-date-input::-webkit-calendar-picker-indicator {
        background-image: url(${calendarIcon});
        background-size: contain;
        background-repeat: no-repeat;
        width: 1.5em;
        height: 1.5em;
        margin-left: 0.5em;
        cursor: pointer;
    }
    .datepicker-wrapper {
        position: relative;
        display: inline-block;
    }
    .custom-datepicker {
        background: #ffffff;
        /* Dark/100 */
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        border: 1px solid #e7e7e7;
        border-radius: 8px;
        padding: 4px 8px;
        width: 201px;
        height: 40px;
        box-sizing: border-box;
    }
    .datepicker-icon {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .datepicker-calendar {
        margin-top: 10px;
    }

    .react-datepicker__day--highlighted,
    .react-datepicker__month-text--highlighted,
    .react-datepicker__quarter-text--highlighted,
    .react-datepicker__year-text--highlighted {
        background-color: #917afd !important;
    }
`;
const DateInput = styled.input`
    background: #ffffff;
    /* Dark/100 */
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    padding: 4px 6px;
    width: 201px;
    height: 40px;
    box-sizing: border-box;
    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
`;
const InnerSkyBlueContainer = styled.div`
    background: #ecf8ff;
    border-radius: 16px;
    padding: 16px;
    /* margin: 12px 0; */
`;

const SelectBoxesContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
`;
const SelectBoxContainer = styled.div``;
const SelectTitle = styled.span`
    display: block;
    font-weight: 400;
    font-size: 15px;
    text-transform: capitalize;
    color: #010101;
    margin-bottom: 6px;
`;
const PopOverContainer = styled.div`
    /* max-height: 60vh; */
    box-sizing: border-box;
    border-radius: 8px;
    /* overflow-y: scroll; */
    background-color: transparent;
    /* margin-bottom: 20px; */
    /* margin-bottom: 40px; */
`;
const CheckAllSection = styled.div`
    background: #ffffff;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 20px;
`;
const CheckAllItem = styled.div`
    display: flex;
    align-items: center;
    &:last-child {
        margin-right: 0;
    }
`;
const CheckItem = styled.div`
    display: flex;
    align-items: center;
    width: 11%;
    &:last-child {
        margin-right: 0;
    }
    @media only screen and (max-width: 1800px) {
        width: 12.5%;
    }
    @media only screen and (max-width: 1380px) {
        width: 15%;
    }
    @media only screen and (max-width: 1280px) {
        width: 17.5%;
    }
    @media only screen and (max-width: 1080px) {
        width: fit-content;
    }
`;
const CheckBox = styled.div`
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #917afd;
    font-size: 16px;
    cursor: pointer;
    cursor: ${(props) => (props.noAction ? "not-allowed" : "pointer")};
    user-select: none;
`;
const CheckItemText = styled.span`
    display: block;
    color: #010101;
    font-size: 15px;
    text-transform: capitalize;
    margin-left: 8px;
`;
const CheckItemsContainer = styled.div`
    margin-left: 4px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    gap: 20px 10px;
`;
const GenerateButton = styled.span`
    display: block;
    background: #917afd;
    border-radius: 8px;
    padding: 12px 24px;
    width: fit-content;
    height: 46px;
    box-sizing: border-box;
    letter-spacing: 0.04em;
    color: #ffffff;
    font-size: 16px;
    margin-top: 24px;
    cursor: pointer;
`;
const ReportTitleBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border-radius: 8px;
    padding: 12px 16px;
`;
const DownloadButton = styled.div`
    background: #fff;
    display: flex;
    align-items: center;
    height: 38px;
    padding: 8px 12px;
    width: fit-content;
    box-sizing: border-box;
    border: 1px solid #917afd;
    border-radius: 8px;
    cursor: pointer;
`;
const DownloadIcon = styled.img`
    display: block;
    width: 16px;
    margin-right: 10px;
`;
const DownloadText = styled.span`
    display: block;
    color: #917afd;
    font-size: 15px;
`;
const TableContainer = styled.div`
    margin-top: 20px;
    overflow-x: auto;

    /* .table {
        min-width: 100%;
        width: auto;
        table-layout: fixed;
        padding: 0;
        margin: 0;
        border-spacing: 0;
    } */
`;
const LoaderContainer = styled.div`
    width: 100%;
    /* background: #000; */
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const NoResultsFoundText = styled.span`
    display: inline-block;
    font-size: 24px;
    /* color: #aaaaaa; */
    color: #696969;
`;
