import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "./../../components/Button";
import Text from "./../../components/Text";
import Arrow from "./../../assets/images/down-arrow.svg";

function AssignModal({
    option,
    setAssignModal,
    salesPerson,
    setOption,
    assignLead,
    selectedLead,
}) {
    return (
        <BackContainer>
            <Overlay
                onClick={() => {
                    setAssignModal(false);
                }}
            ></Overlay>
            <Modal>
                <Container>
                    <Title>Assign {selectedLead.name} to</Title>
                    <div className="totalLeads" style={{ margin: "10px 0" }}>
                        <Select
                            class="Select"
                            name="option"
                            onChange={(e) => {
                                setOption(e.target.value);
                            }}
                        >
                            <option value="0">Select a person</option>
                            {salesPerson.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <Div>
                        {/* <Button
							onClick={() => {
								setAssignModal(false);
							}}
							style={{
								display: "flex",
								alignItems: "center",
								// margin: "0 22px",
							}}
							backgroundColor="#F4F4F4"
							fontSize="15px"
							padding="21px 70px"
						>
							<Text color=" #522e92" fontSize="15px">
								Cancel
							</Text>
                            
						</Button> */}

                        <Button
                            onClick={(e) => {
                                // e.preventDefault();
                                // localStorage.clear();
                                // window.location = "/";
                                if (option === "0") {
                                    alert("Choose a person");
                                } else {
                                    assignLead();
                                }
                            }}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "20px",
                            }}
                            backgroundColor="#5A2D8F"
                            fontSize="15px"
                            // padding="21px 70px"
                        >
                            <Text color=" white" fontSize="15px">
                                Submit
                            </Text>
                        </Button>
                    </Div>
                </Container>
            </Modal>
        </BackContainer>
    );
}
export default AssignModal;

const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.2);
    // backdrop-filter: blur(2px);
`;
const Overlay = styled.div`
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    cursor: pointer;
    height: 100vh;
`;
const Modal = styled.div`
    margin: 0 auto;
    background: #fff;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 23px;
    transition: 0.5s;
    z-index: 101;
`;
const Container = styled.div`
    // height: 0px;
    width: 500px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const Title = styled.h4`
    // text-align: center;
    font-size: 18px;
`;
const Div = styled.div`
    display: flex;
    justify-content: end;
`;
const Select = styled.select`
    width: 100%;
    border: none;
    padding: 20px;
    background: url(${Arrow}) no-repeat right #f9f9f9;
    -webkit-appearance: none;
    background-position-x: 470px;
`;
