import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import React, { useEffect, useState, useContext } from "react";
import { Context } from "./../../contexts/Store";
import Text from "../../components/Text";
import Container from "../../components/Container";
import styled from "styled-components";
import Input from "../../components/Input/input";
import SelectInput from "../../components/selectInput/Selectinput";
import UploadInput from "../../components/UploadInput/UploadInput";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import Add from "./../../assets/images/addbutton.svg";
import { baseConfig } from "./../../utils/config";

function AddDepartment() {
	const [file, setFile] = useState(null);
	const history = useHistory();
	const { state } = useContext(Context);

	const [id, setId] = useState("");
	const [isError, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [fieldName, setFieldName] = useState("");

	const getDepartmentId = () => {
		let accessToken = state.user_details.access_token;
		baseConfig
			.get("/campus/get-department-id/", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setId(data.department_id);
				} else {
				}
			})
			.catch((error) => {});
	};

	const handleSubmitForm = () => {
		let accessToken = state.user_details.access_token;

		baseConfig
			.post(
				"/campus/departments/",
				{
					name: name,
					description: description,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((response) => {
				let { StatusCode, message } = response.data;
				if (StatusCode == 6000) {
					getDepartmentId();
					setName("");
					setDescription("");
				} else {
				}
			})
			.catch((error) => {
				alert(error);
			});
	};

	useEffect(() => {
		getDepartmentId();
	}, []);

	return (
		<Wrapper>
			<Top>
				<TopLeft>
					<Title fontSize="35px">Add Departments</Title>
					<Text
						fontSize="20px"
						fontWeight="400"
						color="#000"
						style={{ marginTop: "10px" }}
					>
						Departments / Add Departments
					</Text>
				</TopLeft>
				<TopRight
					onClick={() => {
						getDepartmentId();
						setName("");
						setDescription("");
					}}
				>
					<Text
						fontSize="16px"
						fontWeight="400"
						color="red"
						style={{ marginLeft: "5px" }}
					>
						Clear Form
					</Text>
				</TopRight>
			</Top>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<Container style={{ flexWrap: "wrap " }} width="100%">
					<form id="form">
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<Input
								type="text"
								name="Department ID (auto generated)"
								value={id}
								disabled
							/>
							<Input
								fieldName={fieldName}
								onChange={(value) => {
									setName(value);
									setError(false);
								}}
								type="text"
								name="Department Name"
								isError={isError}
								errorMessage={errorMessage}
								value={name}
							/>
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: "20px",
							}}
						>
							<Input
								fieldName={fieldName}
								isError={isError}
								errorMessage={errorMessage}
								onChange={(value) => {
									setDescription(value);
									setError(false);
								}}
								type="text"
								name="Description"
								width="100%"
								value={description}
							/>
						</div>
						{/* <div
							style={{
								display: "flex",
								marginTop: "20px",
								alignItems: "end",
							}}
						>
							<Input
								fieldName={fieldName}
								isError={isError}
								errorMessage={errorMessage}
								onChange={(value) => {
									setSecondaryPhone(value);
									setError(false);
								}}
								type="text"
								name="Choose Sub-department"
							/>
							<AddRight
								onClick={() => {
									// history.push(
									// 	"/campus-admin/add-university/"
									// );
								}}
							>
								<img src={Add} alt="icon" />
								<Text
									fontSize="16px"
									fontWeight="400"
									color="#fff"
									style={{ marginLeft: "5px" }}
								>
									Add Sub-department
								</Text>
							</AddRight>
						</div> */}

						<div
							style={{
								display: "flex",
								marginTop: "30px",
							}}
						>
							<Button
								href="#"
								bacerkgroundColor="#5A2D8F"
								fontSize="15px"
								padding="21px 90px"
								fontWeight="500"
								onClick={(e) => {
									if (name === "") {
										setError(true);
										setFieldName("Department Name");
										setErrorMessage(
											"This field is required"
										);
									} else if (description === "") {
										setError(true);
										setFieldName("Description");
										setErrorMessage("Add descriptions");
									} else {
										setError(false);
										e.preventDefault();
										handleSubmitForm();
									}
								}}
							>
								<Text color="white" fontSize="20px">
									Add
								</Text>
							</Button>
							<Button
								backgroundColor="#F4F4F4"
								style={{ marginLeft: "15px" }}
								onClick={() => {
									history.push(
										"/campus-admin/all-department/"
									);
								}}
							>
								<Text color="#522e92" fontSize="20px">
									Cancel
								</Text>
							</Button>
						</div>
					</form>
				</Container>
			</div>
		</Wrapper>
	);
}

export default AddDepartment;

const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const TopLeft = styled.div``;
const TopRight = styled.div`
	display: flex;
	align-items: center;
	background-color: #fff;
	height: 50px;
	border-radius: 3px;
	padding: 0 20px;
	cursor: pointer;
`;
const AddRight = styled.div`
	display: flex;
	align-items: center;
	background-color: #522e92;
	height: 50px;
	border-radius: 3px;
	padding: 0 20px;
	cursor: pointer;
	margin-left: 2%;
	margin-bottom: 5px;
`;
