import Title from "../../components/Title";
import Wrapper from "../../components/Wrapper";
import React, { useEffect, useState, useContext } from "react";
import { Context } from "./../../contexts/Store";
import Text from "../../components/Text";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { baseConfig } from "./../../utils/config";
import DeleteModal from "./../../components/includes/DeleteModal";

//svg
import Add from "./../../assets/images/addbutton.svg";
import University from "./../../assets/images/dashboard-school.svg";
import Pagination from "../../components/pagination/pagination";

function AllUniversity() {
    const history = useHistory();
    const { state } = useContext(Context);
    const [universityList, setUniversityList] = useState([]);
    const [isModal, setModal] = useState(false);
    const [deleteId, setDeleteId] = useState("");

    const getUniversity = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .get("/campus/university/", {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setUniversityList(data.data);
                } else {
                }
            })
            .catch((error) => {});
    };

    const handleDeleteData = () => {
        let accessToken = state.user_details.access_token;
        baseConfig
            .delete(`/campus/university/${deleteId}/`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
            .then((response) => {
                let { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    // alert("University deleted successfully");
                    setModal(false);
                    getUniversity();
                } else if (StatusCode == 6001) {
                    alert(data.data.message);
                    getUniversity();
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        getUniversity();
    }, []);

    return (
        <>
            <Wrapper>
                <Top>
                    <TopLeft>
                        <Title fontSize="35px">Universitiessss</Title>
                        <Text
                            fontSize="20px"
                            fontWeight="400"
                            color="#000"
                            style={{ marginTop: "10px" }}
                        >
                            University / All Universities
                        </Text>
                    </TopLeft>
                    <TopRight
                        onClick={() => {
                            history.push("/campus-admin/add-university/");
                        }}
                    >
                        <img src={Add} alt="icon" />
                        <Text
                            fontSize="16px"
                            fontWeight="400"
                            color="#fff"
                            style={{ marginLeft: "5px" }}
                        >
                            Add University
                        </Text>
                    </TopRight>
                </Top>
                <CardContainer>
                    {universityList.map((item) => (
                        <Card>
                            <CardTop>
                                <CardLeft>
                                    <img
                                        src={item.logo}
                                        alt="icon"
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                        }}
                                    />
                                </CardLeft>
                                <CardRight>
                                    <Option>
                                        <img
                                            src={
                                                require("./../../assets/images/action-delete.svg")
                                                    .default
                                            }
                                            alt="icon"
                                            onClick={() => {
                                                setModal(true);
                                                setDeleteId(item.id);
                                            }}
                                        />
                                    </Option>
                                    <TopRight
                                        onClick={() => {
                                            history.push(
                                                "/campus-admin/add-university/"
                                            );
                                        }}
                                    >
                                        <Text
                                            fontSize="16px"
                                            fontWeight="400"
                                            color="#fff"
                                            style={{ marginLeft: "5px" }}
                                        >
                                            Update
                                        </Text>
                                    </TopRight>
                                </CardRight>
                            </CardTop>
                            <CardBottom>
                                <SubDiv>
                                    <SubText>#{item.university_id}</SubText>
                                </SubDiv>
                                <SubDiv>
                                    <MainText>{item.name}</MainText>
                                </SubDiv>
                                <SubDiv>
                                    <SubText>{item.address}</SubText>
                                </SubDiv>
                                <SubDiv>
                                    <MainText>Mail ID :</MainText>
                                    <SubText style={{ marginLeft: "10px" }}>
                                        {item.email}
                                    </SubText>
                                </SubDiv>
                                <SubDiv>
                                    <MainText>Mobile No :</MainText>
                                    <SubText style={{ marginLeft: "10px" }}>
                                        {item.phone}
                                    </SubText>
                                </SubDiv>
                            </CardBottom>
                        </Card>
                    ))}
                </CardContainer>
            </Wrapper>
            {isModal && (
                <DeleteModal
                    isModal={isModal}
                    setModal={setModal}
                    deleteId={deleteId}
                    handleDeleteData={handleDeleteData}
                />
            )}
        </>
    );
}

export default AllUniversity;

const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const TopLeft = styled.div``;
const TopRight = styled.div`
    display: flex;
    align-items: center;
    background-color: #522e92;
    height: 50px;
    border-radius: 3px;
    padding: 0 20px;
    cursor: pointer;
`;
const CardContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    margin-top: 20px;
    @media (max-width: 1180px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }
    @media (max-width: 840px) {
        grid-gap: 15px;
    }
`;
const Card = styled.div`
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    @media (max-width: 840px) {
        padding: 20px;
    }
`;
const CardTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const CardLeft = styled.div`
    height: 150px;
    width: 150px;
`;
const CardRight = styled.div`
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: space-between;
    align-items: end;
`;
const Option = styled.div`
    cursor: pointer;
`;
const CardBottom = styled.div``;
const SubDiv = styled.h5`
    margin-top: 20px;
    display: flex;
    align-items: center;
`;
const MainText = styled.h4`
    color: #522e92;
    font-weight: 600;
    font-size: 18px;
`;
const SubText = styled.h5`
    color: #808080;
    font-size: 15px;
`;
