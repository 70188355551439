const unassignedListData = [

  {
    studentId: "001",
    name: "Jomana Ah",
    university: "Sngnya",
    phone: "+96 979797 9797",
    course: "MSc",
    country: "Dubai",
    sourcesocial: "Facebook",
    date: "12-5-21"

  },
  {
    studentId: "001",
    name: "Jomana Ah",
    university: "Sngnya",
    phone: "+96 979797 9797",
    course: "MSc",
    country: "Dubai",
    sourcesocial: "Facebook",
    date: "12-5-21"

  },
  {
    studentId: "001",
    name: "Jomana Ah",
    university: "Sngnya",
    phone: "+96 979797 9797",
    course: "MSc",
    country: "Dubai",
    sourcesocial: "Facebook",
    date: "12-5-21"

  },
  {
    studentId: "001",
    name: "Jomana Ah",
    university: "Sngnya",
    phone: "+96 979797 9797",
    course: "MSc",
    country: "Dubai",
    sourcesocial: "Facebook",
    date: "12-5-21"

  },

  {
    studentId: "001",
    name: "Jomana Ah",
    university: "Sngnya",
    phone: "+96 979797 9797",
    course: "MSc",
    country: "Dubai",
    sourcesocial: "Facebook",
    date: "12-5-21"

  },
  {
    studentId: "001",
    name: "Jomana Ah",
    university: "Sngnya",
    phone: "+96 979797 9797",
    course: "MSc",
    country: "Dubai",
    sourcesocial: "Facebook",
    date: "12-5-21"

  },
  {
    studentId: "001",
    name: "Jomana Ah",
    university: "Sngnya",
    phone: "+96 979797 9797",
    course: "MSc",
    country: "Dubai",
    sourcesocial: "Facebook",
    date: "12-5-21"

  },
  {
    studentId: "001",
    name: "Jomana Ah",
    university: "Sngnya",
    phone: "+96 979797 9797",
    course: "MSc",
    country: "Dubai",
    sourcesocial: "Facebook",
    date: "12-5-21"

  },
  {
    studentId: "001",
    name: "Jomana Ah",
    university: "Sngnya",
    phone: "+96 979797 9797",
    course: "MSc",
    country: "Dubai",
    sourcesocial: "Facebook",
    date: "12-5-21"

  },

  {
    studentId: "001",
    name: "Jomana Ah",
    university: "Sngnya",
    phone: "+96 979797 9797",
    course: "MSc",
    country: "Dubai",
    sourcesocial: "Facebook",
    date: "12-5-21"

  },







];
export default unassignedListData;
