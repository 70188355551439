import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SnackbarComponent from './Snackbar';

function EmiEditModal({ 
    setPreviewModal, 
    setEditModal, 
    data, 
    setData, 
    vatPercentage,  
    pendingInstalmentAmount,
    feePayable,
    allowedReferenceDiscount
}) {
    const [alert, setAlert] = useState("");

    useEffect(() => {
        setData(prevData => ({
            ...prevData,
            "due_date": convertDateForInput(data.due_date),
            "dop": convertDateForInput(data.dop)
        }));
    }, []);

    const handleAlert = (message) => {
        setAlert(message);
        setTimeout(() => {
            setAlert("");
        }, 3000);
    };

    const recalculateAmounts = (amount, discount, paid_amount) => {
        let discount_value = discount;
        let amount_value = amount;
        let paid_amount_value = paid_amount;

        amount = amount ? parseFloat(amount) : 0;
        discount = discount ? parseFloat(discount) : 0;
        paid_amount = paid_amount ? parseFloat(paid_amount) : 0;

        const previousBalance = parseFloat(data.previous_balance);
        const vatAmount = (amount * parseFloat(vatPercentage)) / 100;
        const subTotal = amount + previousBalance + vatAmount;
        const total = subTotal - discount;
        const balance = paid_amount - total;

        const roundedVatAmount = vatAmount.toFixed(2);
        const roundedTotal = total.toFixed(2);
        const roundedBalance = balance.toFixed(2);

        setData(prevData => ({
            ...prevData,
            "discount_amount": discount_value,
            "amount": amount_value,
            "vat": roundedVatAmount,
            "total_amount": roundedTotal,
            "balance_amount": roundedBalance,
            "paid_amount": paid_amount_value,
        }));
    };

    const updateInstallmentData = (value, field) => {
       if (field === "amount") {
            if (value && parseFloat(value) > parseFloat(pendingInstalmentAmount)) {
                handleAlert(`Error: Please add a correct amount (maximum allowed amount is ${pendingInstalmentAmount}`);
            } else {
                recalculateAmounts(value, data.discount_amount, data.paid_amount);
            }
        } else if (field === "discount") {
            if (!data.discount_type) {
                handleAlert("Please choose discount type")
            }
            else if (value && (parseFloat(value) < 0 || parseFloat(value) > feePayable)) {
                handleAlert(`Error: Please add a correct discount amount (0-${feePayable})`);
            } else if (value && data.discount_type === "Reference" && (parseFloat(value) > allowedReferenceDiscount)) {
                handleAlert(`Error: Please add a correct reference discount amount (max-${allowedReferenceDiscount})`);
            } else {
                recalculateAmounts(data.amount, value, data.paid_amount);
            }
        } else if (field === "paid_amount") {
            if (value && parseFloat(value) < 0) {
                handleAlert("Error: Please add a correct amount paid");
            } else {
                recalculateAmounts(data.amount, data.discount_amount, value);
            }
        } else if (field === "due_date" || field === "dop") {
            if (!value) {
                handleAlert(`Error: ${field.replace('_', ' ').toUpperCase()} is required`);
            } else {
                setData(prevData => ({
                    ...prevData,
                    [field]: value
                }));
            }
        } else if (field === "discount_type"){
            if (value && value === "Reference" && (parseFloat(data.discount_amount) > allowedReferenceDiscount)) {
                recalculateAmounts(data.amount, allowedReferenceDiscount, data.paid_amount);
                handleAlert(`Error: Wrong reference discount amount. It changed to maximum discount amount-${allowedReferenceDiscount})`);
            }
            setData(prevData => ({
                ...prevData,
                [field]: value
            }));
        } else if (field === "status") {
            if (!value) {
                handleAlert("Error: Status is required");
            } else {
                setData(prevData => ({
                    ...prevData,
                    [field]: value
                }));
            }
        } else {
            setData(prevData => ({
                ...prevData,
                [field]: value
            }));
        }
    };

    const convertDateForInput = (dateStr) => {
        if (!dateStr) return '';

        const [day, month, year] = dateStr.split('-');
        const months = {
            'Jan': '01', 'Feb': '02', 'Mar': '03', 'Apr': '04', 'May': '05', 'Jun': '06',
            'Jul': '07', 'Aug': '08', 'Sep': '09', 'Oct': '10', 'Nov': '11', 'Dec': '12'
        };

        const formattedMonth = months[month];
        if (!formattedMonth) return '';

        return `${year}-${formattedMonth}-${day.padStart(2, '0')}`;
    };

    const validateFields = () => {
        if (!data.instalment || data.instalment.length > 100) {
            handleAlert("Error: Installment name is required and must be less than 100 characters");
            return false;
        }
        if (!data.due_date) {
            handleAlert("Error: Due date is required");
            return false;
        }
        if (data.amount === undefined || data.amount === "" || parseFloat(data.amount) > 99999) {
            handleAlert("Error: Installment amount is required and must be less than or equal to 99999");
            return false;
        }
        if (data.discount_amount && parseFloat(data.discount_amount) > 99999) {
            handleAlert("Error: Discount amount must be less than or equal to 99999");
            return false;
        }
        if (!data.status) {
            handleAlert("Error: Status is required");
            return false;
        }
        if (data.paid_amount > 0 && (!data.dop || !data.mop)) {
            handleAlert("Error: DOP and MOP are required when Paid Amount is added");
            return false;
        }
        return true;
    };

    const handlePreviewButton = () => {
        if (validateFields()) {
            setPreviewModal(true);
            setEditModal(false);
        }
    };

    return (
        <MainContainer>
            <Title>Edit</Title>
            {alert && (
                <SnackbarComponent message={alert} onClose={() => setAlert("")} />
            )}
            <ContentContainer>
                <TextAreaContainer>
                    <InputGroup>
                        <InputText>Installment Name<span style={{ color: 'red' }}>*</span></InputText>
                        <InputData
                            placeholder='Installment'
                            value={data.instalment}
                            onChange={(e) => updateInstallmentData(e.target.value, "instalment")}
                        />
                    </InputGroup>
                    <InputGroup>
                        <InputText>Due Date<span style={{ color: 'red' }}>*</span></InputText>
                        <InputData
                            type="date"
                            value={data.due_date}
                            onChange={(e) => updateInstallmentData(e.target.value, "due_date")}
                        />
                    </InputGroup>
                    <InputGroup>
                        <InputText>Installment Amount<span style={{ color: 'red' }}>*</span></InputText>
                        <InputData
                            type="number"
                            value={data.amount}
                            onChange={(e) => updateInstallmentData(parseFloat(e.target.value), "amount")}
                        />
                    </InputGroup>
                    <InputGroup>
                        <InputText>Discount Type</InputText>
                        <InputDataSelect
                            value={data.discount_type}
                            onChange={(e) => updateInstallmentData(e.target.value, "discount_type")}
                        >
                            <TypeOptions value="">Select</TypeOptions>
                            <TypeOptions value="Normal">Normal</TypeOptions>
                            {allowedReferenceDiscount > 0 && 
                                <TypeOptions value="Reference">Reference</TypeOptions>
                            }
                        </InputDataSelect>
                    </InputGroup>
                    <InputGroup>
                        <InputText>Discount</InputText>
                        <InputData
                            type="number"
                            value={data.discount_amount}
                            onChange={(e) => updateInstallmentData(parseFloat(e.target.value), "discount")}
                        />
                    </InputGroup>
                    <InputGroup>
                        <InputText>Status<span style={{ color: 'red' }}>*</span></InputText>
                        <InputDataSelect value={data.status} 
                        onChange={(e) => updateInstallmentData(e.target.value, "status")}
                        >
                            <TypeOptions value="">Select</TypeOptions>
                            <TypeOptions value="Pending">Pending</TypeOptions>
                            <TypeOptions value="Paid">Paid</TypeOptions>
                            <TypeOptions value="On Due">On Due</TypeOptions>
                            <TypeOptions value="On Break">On Break</TypeOptions>
                            <TypeOptions value="On Hold">On Hold</TypeOptions>
                        </InputDataSelect>
                    </InputGroup>
                    <InputGroup>
                        <InputText>Paid Amount</InputText>
                        <InputData
                            type="number"
                            value={data.paid_amount}
                            onChange={(e) => updateInstallmentData(parseFloat(e.target.value), "paid_amount")}
                        />
                    </InputGroup>
                    <InputGroup>
                        <InputText>DOP<span style={{ color: 'red' }}>*</span></InputText>
                        <InputData
                            type="date"
                            value={data.dop}
                            onChange={(e) => updateInstallmentData(e.target.value, "dop")}
                        />
                    </InputGroup>
                    <InputGroup>
                        <InputText>MOP<span style={{ color: 'red' }}>*</span></InputText>
                        <InputDataSelect
                            value={data.mop}
                            onChange={(e) => updateInstallmentData(e.target.value, "mop")}
                        >
                            <TypeOptions value="">Select</TypeOptions>
                            <TypeOptions value="Bank Transfer">Bank Transfer</TypeOptions>
                            <TypeOptions value="Cash">Cash</TypeOptions>
                            <TypeOptions value="POS">POS</TypeOptions>
                            <TypeOptions value="Cheque">Cheque</TypeOptions>
                            <TypeOptions value="Money Exchange">Money Exchange</TypeOptions>
                            <TypeOptions value="Payment Link">Payment Link</TypeOptions>
                            <TypeOptions value="Bank Remittance">Bank Remittance</TypeOptions>
                        </InputDataSelect>
                    </InputGroup>
                    <InfoLine>
                        <GreyInfoText>Previous Balance</GreyInfoText>
                        <span>:</span>
                        <BlackInfoText>{data.previous_balance}</BlackInfoText>
                    </InfoLine>
                    <InfoLine>
                        <GreyInfoText>VAT({vatPercentage}%)</GreyInfoText>
                        <span>:</span>
                        <BlackInfoText>{typeof data.vat === 'number' ? data.vat.toFixed(2) : data.vat}</BlackInfoText>
                    </InfoLine>
                    <InfoLine>
                        <GreyInfoText>Total Amount</GreyInfoText>
                        <span>:</span>
                        <BlackInfoText>{data.total_amount}</BlackInfoText>
                    </InfoLine>
                    <InfoLine>
                        <GreyInfoText>Paid Amount</GreyInfoText>
                        <span>:</span>
                        <BlackInfoText>{data.paid_amount ? data.paid_amount : 0.00}</BlackInfoText>
                    </InfoLine>
                    <InfoLine>
                        <GreyInfoText>Balance Amount</GreyInfoText>
                        <span>:</span>
                        <BlackInfoText>{data.balance_amount}</BlackInfoText>
                    </InfoLine>
                </TextAreaContainer>
                <Buttons>
                    <Cancel onClick={() => setEditModal(false)}>Cancel</Cancel>
                    <Submit onClick={handlePreviewButton}>Preview</Submit>
                </Buttons>
            </ContentContainer>
        </MainContainer>
    );
}

export default EmiEditModal;

const MainContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 540px;
    background: #fff;
    border: 1px solid #fff;
    border-radius: 12px;
    padding: 35px 25px;
    height: 90%;
    max-height: 635px;
`;

const ContentContainer = styled.div`
    position: relative;
    width: 100%;
    height: calc(100% - 50px);
    overflow-y: auto;
`;

const Title = styled.p`
    font-size: 18px;
    border-bottom: 1px solid #E7E7E7;
    padding-bottom: 20px;
`;

const TextAreaContainer = styled.div`
    background: #F8FAFC;
    width: 100%;
    margin-top: 20px;
    border-radius: 8px;
    border: 1px solid #E7E7E7;
    padding: 20px;
    box-sizing: border-box;
`;

const InputGroup = styled.div`
    margin-bottom: 15px;
`;

const InputText = styled.p`
    margin-bottom: 10px;
`;

const InputData = styled.input`
    border: 1px solid #E7E7E7;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
`;

const InputDataSelect = styled.select`
    border: 1px solid #E7E7E7;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
`;

const TypeOptions = styled.option``;

const InfoLine = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 13px;
    justify-content: space-between;
    &:last-child {
        margin-bottom: 0px;
    }
`;

const GreyInfoText = styled.span`
    color: #000000;
    font-weight: 400;
    opacity: 0.5;
    font-size: 14px;
    display: block;
    white-space: nowrap;
    letter-spacing: 0.1px;
    width: 50%;
`;

const BlackInfoText = styled.span`
    display: block;
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    white-space: nowrap;
    letter-spacing: 0.1px;
    text-transform: capitalize;
    width: 50%;
    text-align: end;
`;

const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
`;

const Cancel = styled.button`
    color: #F61F1F;
    cursor: pointer;
`;

const Submit = styled.button`
    background: #917AFD;
    padding: 12px 24px;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
`;
